import { backendClasses } from './backend-classes.model';
import { ConfigItemTextInputConfigurationDtoModel } from './config-item-text-input-configuration-dto.model';

export class ConfigItemLedgerAccountConfigurationDtoModel extends ConfigItemTextInputConfigurationDtoModel {
  static readonly CLASSNAME: string = backendClasses.ledgerAccountConfiguration;
  static TYPENAME = 'typeLedgerAccount';
  '@class' = ConfigItemLedgerAccountConfigurationDtoModel.CLASSNAME;
  $$typeName = ConfigItemLedgerAccountConfigurationDtoModel.TYPENAME;

  type: ConfigLedgerAcountType = ConfigLedgerAcountType.SELECTION;
}

export enum ConfigLedgerAcountType {
  SAP_SEARCH = 'SAP_SEARCH',
  SELECTION = 'SELECTION',
  PREDEFINED = 'PREDEFINED',
}
