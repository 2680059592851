import { ConfigurationItems } from '../../../core-lib/models/config-dto.model';
import { ConfigItemAccountingConfigurationDtoModel } from '../../../core-lib/models/config-item-accounting-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../../core-lib/models/config-item-arranger-list-configuration-dto.model';
import { ConfigItemCheckboxConfigurationDtoModel } from '../../../core-lib/models/config-item-checkbox-configuration-dto.model';
import { ConfigItemConfigurationCategoryModel } from '../../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemFileUploadConfigurationDtoModel } from '../../../core-lib/models/config-item-file-upload-configuration-dto.model';
import { ConfigItemLedgerAccountConfigurationDtoModel } from '../../../core-lib/models/config-item-ledger-account-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../../../core-lib/models/config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../../../core-lib/models/config-item-text-input-configuration-dto.model';
import { EqualKeyNames } from '../../../forms-lib/models/equal-key-names';
import { PayeeDtoModel } from '../../all-forms/models/payee-dto.model';
import { FormK1DtoModel } from './form-k1-dto.model';

export const EasyFormK1Steps = {
  step1: <ConfigItemConfigurationCategoryModel>{
    id: 'step1',
    displayName: 'Gesellschaft',
  },
  step2: <ConfigItemConfigurationCategoryModel>{
    id: 'step2',
    displayName: 'Lieferant/Auftragnehmer',
  },
  step3: <ConfigItemConfigurationCategoryModel>{
    id: 'step3',
    displayName: 'Detailangaben',
  },
};

export class EasyFormK1ConfigItems extends ConfigurationItems implements EqualKeyNames<FormK1DtoModel>, EqualKeyNames<PayeeDtoModel> {
  // Kreditor
  payeeNumber = new ConfigItemTextInputConfigurationDtoModel('payeeNumber').withCategory(EasyFormK1Steps.step2);
  sendMailToPayee = new ConfigItemCheckboxConfigurationDtoModel('sendMailToPayee').withCategory(EasyFormK1Steps.step2);

  // PayeeDtoModel
  salutation = new ConfigItemTextInputConfigurationDtoModel('salutation').withCategory(EasyFormK1Steps.step2);
  name = new ConfigItemTextInputConfigurationDtoModel('name').withCategory(EasyFormK1Steps.step2);
  nameAddition = new ConfigItemTextInputConfigurationDtoModel('nameAddition').withCategory(EasyFormK1Steps.step2);
  street = new ConfigItemTextInputConfigurationDtoModel('street').withCategory(EasyFormK1Steps.step2);
  zipCode = new ConfigItemTextInputConfigurationDtoModel('zipCode').withCategory(EasyFormK1Steps.step2);
  city = new ConfigItemTextInputConfigurationDtoModel('city').withCategory(EasyFormK1Steps.step2);
  country = new ConfigItemTextInputConfigurationDtoModel('country').withCategory(EasyFormK1Steps.step2);
  email = new ConfigItemTextInputConfigurationDtoModel('email').withCategory(EasyFormK1Steps.step2);
  iban = new ConfigItemTextInputConfigurationDtoModel('iban').withCategory(EasyFormK1Steps.step2);
  bic = new ConfigItemTextInputConfigurationDtoModel('bic').withCategory(EasyFormK1Steps.step2);

  /** step3 **/

    // Übernahme meine Daten in Step3
  contactPerson = new ConfigItemTextInputConfigurationDtoModel('contactPerson').withCategory(EasyFormK1Steps.step3);
  department = new ConfigItemTextInputConfigurationDtoModel('department').withCategory(EasyFormK1Steps.step3);
  telephone = new ConfigItemTextInputConfigurationDtoModel('telephone').withCategory(EasyFormK1Steps.step3);
  emailMyData = new ConfigItemTextInputConfigurationDtoModel('emailMyData').withCategory(EasyFormK1Steps.step3);
  account = new ConfigItemAccountingConfigurationDtoModel('account').withCategory(EasyFormK1Steps.step3);

  useCase = new ConfigItemTextInputConfigurationDtoModel('useCase').withCategory(EasyFormK1Steps.step3);
  netCost = new ConfigItemNumberConfigurationDtoModel('netCost').withCategory(EasyFormK1Steps.step3);
  ledgerAccount = new ConfigItemLedgerAccountConfigurationDtoModel('ledgerAccount').withCategory(EasyFormK1Steps.step3);
  description = new ConfigItemTextInputConfigurationDtoModel('description').withCategory(EasyFormK1Steps.step3);
  productGroup = new ConfigItemTextInputConfigurationDtoModel('productGroup').withCategory(EasyFormK1Steps.step3);

  // Abweichende Lieferadresse (DA - Delivery Adress)
  differentDeliveryAddress = new ConfigItemCheckboxConfigurationDtoModel('differentDeliveryAddress').withCategory(EasyFormK1Steps.step3);
  nameDA = new ConfigItemTextInputConfigurationDtoModel('nameDA').withCategory(EasyFormK1Steps.step3);
  nameAdditionDA = new ConfigItemTextInputConfigurationDtoModel('nameAdditionDA').withCategory(EasyFormK1Steps.step3);
  streetDA = new ConfigItemTextInputConfigurationDtoModel('streetDA').withCategory(EasyFormK1Steps.step3);
  zipCodeDA = new ConfigItemTextInputConfigurationDtoModel('zipCodeDA').withCategory(EasyFormK1Steps.step3);
  cityDA = new ConfigItemTextInputConfigurationDtoModel('cityDA').withCategory(EasyFormK1Steps.step3);
  phoneDA = new ConfigItemTextInputConfigurationDtoModel('phoneDA').withCategory(EasyFormK1Steps.step3);
  contactPersonDA = new ConfigItemTextInputConfigurationDtoModel('contactPersonDA').withCategory(EasyFormK1Steps.step3);

  // Genehmigungsstufen
  approver = new ConfigItemArrangerListConfigurationDtoModel('approver').withCategory(EasyFormK1Steps.step3);
  inspector = new ConfigItemArrangerListConfigurationDtoModel('inspector').withCategory(EasyFormK1Steps.step3);
  commentForApprover = new ConfigItemTextInputConfigurationDtoModel('commentForApprover').withCategory(EasyFormK1Steps.step3);

  // Dateianhang
  file = new ConfigItemFileUploadConfigurationDtoModel('file').withCategory(EasyFormK1Steps.step3);
}
