<lib-common-input-property-wrapper
  [header]="attributeName"
  icon="dialpad"
  [typeNameI18n]="typeName"
  [readonly]="readonly"
  [ngrxOverwrite]="ngrxOverwrite"
  (changeReadonly)="onChangeReadonly($event)"
>
  <div>
    <section
      *ngIf="ngrxMin"
    >
      <h2 class="mat-h2">Minimum / Maximum</h2>
      <mat-form-field>
        <input
          matInput
          placeholder="Minimum"
          [ngrxFormControlState]="ngrxMin"
          type="number"
        >
      </mat-form-field>
    </section>

    <section
      *ngIf="ngrxMax"
    >
      <mat-form-field>
        <input
          matInput
          placeholder="Maximum"
          [ngrxFormControlState]="ngrxMax"
          type="number"
        >
      </mat-form-field>
    </section>

    <section
      *ngIf="ngrxDefaultValue">
      <mat-form-field>
        <input
          matInput
          placeholder="{{'defaultValue' | i18n | async}}"
          [ngrxFormControlState]="ngrxDefaultValue"
        >
      </mat-form-field>
    </section>

    <section>
      <mat-form-field *ngIf="ngrxRegex">
        <input
          matInput
          placeholder="Regular Expression"
          [ngrxFormControlState]="ngrxRegex"
        >
      </mat-form-field>
    </section>

    <section>
      <mat-form-field *ngIf="ngrxDecimalPoints">
        <input
          matInput
          type="number"
          step="1"
          [placeholder]="'decimalPlacesPlaceholder' | i18n | async"
          [ngrxFormControlState]="ngrxDecimalPoints"
          pattern="^\d*$"
        >
      </mat-form-field>
    </section>

    <section
      *ngIf="ngrxMandatory || ngrxEnabled || ngrxVisible || ngrxChangeableByInspector || ngrxChangeableByApprover"
    >
      <lib-common-input-property-defaults
        [ngrxMandatory]="ngrxMandatory"
        [ngrxEnabled]="ngrxEnabled"
        [ngrxVisible]="ngrxVisible"
        [ngrxChangeableByInspector]="ngrxChangeableByInspector"
        [ngrxChangeableByApprover]="ngrxChangeableByApprover"
      ></lib-common-input-property-defaults>
    </section>

    <lib-common-input-property-lang-wrapper
      [translations]="translations"
      (labelResetClick)="labelResetClick.emit($event)"
      [undoVisible]="labelResetClick && labelResetClick.observers && labelResetClick.observers.length > 0"
    ></lib-common-input-property-lang-wrapper>
  </div>
</lib-common-input-property-wrapper>
