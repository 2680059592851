import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { ApiService } from '../../../core-lib/services/api.service';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { rxCatchApiError } from '../../../core-lib/utils/reducer-utils';
import { CoreActionCategoriesLoaded } from '../actions/core-config.actions';
import { CoreActionInit } from '../actions/core.actions';

@Injectable()
export class CoreCategoryEffects {

  constructor(private actions$: Actions, private api: ApiService) {
  }

  loadCategories$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActionInit.TYPE),
    switchMap(() => this.api.getAllCategories().pipe(
      map((categories) => new CoreActionCategoriesLoaded([
        ...categories,
      ])),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

}
