import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControlState } from 'ngrx-forms';
import { RouteConfigFormPropertyComponent } from '../../../core/components/route-config-form-property/route-config-form-property.component';
import { CoreFeatureState } from '../../../core/ngrx/reducers/core.store';
import { AppConfigService } from '../../../core/services/app-config.service';
import { AccountingType } from '../../models/accounting-type.model';
import { ConfigAccountInputType } from '../../models/config-accounting-input-type.model';
import { ConfigItemAccountingConfigurationDtoModel } from '../../models/config-item-accounting-configuration-dto.model';
import { SetValueTraceableAction } from '../../models/set-value-traceable-action';
import { InputPropertyTypeComponent } from '../input-property-type/input-property-type.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-input-property-type-account',
  templateUrl: './input-property-type-accounting.component.html',
  styleUrls: ['./input-property-type-accounting.component.scss'],
})
export class InputPropertyTypeAccountingComponent extends InputPropertyTypeComponent {

  typeName = ConfigItemAccountingConfigurationDtoModel.TYPENAME;

  @Input() ngrxType: FormControlState<ConfigAccountInputType>;
  @Input() ngrxPredefinedValue: FormControlState<string>;
  @Input() ngrxAccountingType: FormControlState<AccountingType>;

  type: ConfigAccountInputType;

  inputTypes = [ConfigAccountInputType.PREDEFINED, ConfigAccountInputType.SAP_SEARCH];
  accountingTypes = this.configService.config.supportedAccountingTypes;

  constructor(store$: Store<CoreFeatureState>, util: RouteConfigFormPropertyComponent, public configService: AppConfigService) {
    super(store$, util);
  }

  internalReloadValues(lastConfigItemValue) {
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxType.id, lastConfigItemValue.type));
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxAccountingType.id, lastConfigItemValue.accountingType));
  }
}
