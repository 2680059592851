import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { LangItemModel } from '../../../core-lib/components/language-selector/language-selector.component';
import TranslationStoreModel from '../../../core-lib/models/translation-store.model';
import { CoreI18nSetLanguage } from '../../../core/ngrx/actions/core-config.actions';
import { getI18nSelectedLanguage, getI18nTranslations } from '../../../core/ngrx/reducers/core.store';
import { AppConfigService } from '../../../core/services/app-config.service';
import { SessionActionLoad } from '../../actions/session.actions';
import { getSessionIsLoggedIn, getSessionUser, SessionState } from '../../reducers/session.reducer';
import { UrlsService } from '../../services/urls.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-session-display',
  templateUrl: './session-display.component.html',
  styleUrls: ['./session-display.component.scss'],
})
export class SessionDisplayComponent implements OnInit {
  @Input() loginLabel = '';
  @Input() logoutLabel = '';

  isLoggedIn$ = this.store$.select(getSessionIsLoggedIn);
  user$ = this.store$.select(getSessionUser);

  languages$ = this.store$.select(getI18nTranslations).pipe(
    map((languages: TranslationStoreModel) => Object.keys(languages).map(this.languageValueMapper)),
  );
  selectedLanguage$ = this.store$.select(getI18nSelectedLanguage).pipe(
    map((selectedLanguage) => this.languageValueMapper(selectedLanguage)),
  );

  faUser = faUser;
  faEnvelope = faEnvelope;
  faSignOutAlt = faSignOutAlt;
  faSignInAlt = faSignInAlt;

  constructor(
    private store$: Store<SessionState>,
    private appConfigService: AppConfigService,
    private urlsService: UrlsService,
  ) {
  }

  logoutUrl$ = this.urlsService.logoutUrl$;
  loginUrl$ = this.urlsService.loginUrl$;

  languageValueMapper = (l): LangItemModel => (
    {
      displayName: l,
      value: l,
    }
  );

  ngOnInit() {
    // Kick-Off der Anwendung ⚽🦶
    setTimeout(() => {
      this.store$.dispatch(new SessionActionLoad());
    });
  }

  changeLanguage($event: LangItemModel) {
    this.store$.dispatch(new CoreI18nSetLanguage($event.value as string));
  }
}
