import { backendClasses } from './backend-classes.model';
import { ConfigItemArrangerConfigurationDtoModel } from './config-item-arranger-configuration-dto.model';
import { ConfigItemListConfigurationDtoModel } from './config-item-list-configuration-dto.model';

export class ConfigItemArrangerListConfigurationDtoModel extends ConfigItemListConfigurationDtoModel {
  static readonly CLASSNAME: string = backendClasses.arrangerListConfiguration;
  static TYPENAME = 'typeArrangerList';
  '@class' = ConfigItemArrangerListConfigurationDtoModel.CLASSNAME;
  $$typeName = ConfigItemArrangerListConfigurationDtoModel.TYPENAME;
  constructor(
    attributeName: string,
    public values: ConfigItemArrangerConfigurationDtoModel[] = [],
  ) {
    super(attributeName);
  }
}
