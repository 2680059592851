<mat-card fxFlexFill>
  <mat-card-header *ngIf="header">
    <div
      mat-card-avatar
      class="title-icon"
    >
      <mat-icon color="primary">{{icon}}</mat-icon>
    </div>
    <mat-card-title>
      {{ header }} ({{ typeNameI18n | i18n | async }})
    </mat-card-title>
    <mat-card-subtitle>
      <div>
        <mat-checkbox
          *ngIf="readonly"
          [ngrxFormControlState]="ngrxOverwrite"
          (change)="onChangeReadonly($event)"
          class="checkbox-margin"
        >{{ 'overwrite' | i18n | async }}
        </mat-checkbox>
      </div>
    </mat-card-subtitle>
    <div class="margin-info">
      <button
        class="fkInfoIcon"
        type="button"
        mat-icon-button
        (click)="openDialog($event, header)"
        title="{{ 'infoIconText' | i18n | async }}"
      >
        <mat-icon class="svgInfo" svgIcon="innogy:info"></mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content class="card-content-margin">
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
