<div class="margin-content">
  <form (ngSubmit)="saveSalutation()">
    <lib-common-form-flex-container>
      <div *ngFor="let salutation of (salutationList$ | async); trackBy: getControlId">
        <mat-card>
          <mat-card-content>
            <lib-common-salutation
              (langLabelReset)="resetLangLabel(salutation, $event)"
              (removeClick)="removeSalutation(salutation)"
              *ngIf="salutation"
              [key]="salutation.controls.key"
              [langLabels]="salutation.controls.labels"
              [value]="salutation.controls.value"
            ></lib-common-salutation>
          </mat-card-content>
        </mat-card>
      </div>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="button"
          (click)="addSalutation()"
          mat-fab
          color="accent"
        >
          <mat-icon svgIcon="innogy:plus"></mat-icon>
        </button>
      </div>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="submit"
          [disabled]="!(formValid$ | async)"
          color="primary"
          mat-raised-button
        >
          <mat-icon
            class="svg-icon-floppy"
            svgIcon="innogy:floppy"
          ></mat-icon>
          {{ 'save' | i18n | async }}
        </button>
      </div>
    </lib-common-form-flex-container>
  </form>
</div>
