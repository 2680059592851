<lib-common-input-property-wrapper
  [header]="attributeName"
  icon="date_range"
  [typeNameI18n]="typeName"
  [readonly]="readonly"
  [ngrxOverwrite]="ngrxOverwrite"
  (changeReadonly)="onChangeReadonly($event)"
>
  <div>
    <section>
      <lib-common-input-property-defaults
        [ngrxMandatory]="ngrxMandatory"
        [ngrxEnabled]="ngrxEnabled"
        [ngrxVisible]="ngrxVisible"
        [ngrxChangeableByInspector]="ngrxChangeableByInspector"
        [ngrxChangeableByApprover]="ngrxChangeableByApprover"
      ></lib-common-input-property-defaults>
    </section>
    <section>
      <mat-form-field
        *ngIf="ngrxMinDate"
      >
        <input
          matInput
          [matDatepicker]="pickerMin"
          [placeholder]="'minDate' | i18n | async "
          [ngrxFormControlState]="ngrxMinDate"
          [ngrxValueConverter]="ngrxDateValueConverter"
        >
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerMin"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerMin></mat-datepicker>
      </mat-form-field>

      <mat-form-field
        *ngIf="ngrxMaxDate"
      >
        <input
          matInput
          [matDatepicker]="pickerMax"
          [placeholder]="'maxDate' | i18n | async "
          [ngrxFormControlState]="ngrxMaxDate"
          [ngrxValueConverter]="ngrxDateValueConverter"
        >
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerMax"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerMax></mat-datepicker>
      </mat-form-field>
    </section>

    <section
      *ngIf="ngrxWeekendsActive"
    >
      <mat-checkbox
        class="checkbox-margin"
        [ngrxFormControlState]="ngrxWeekendsActive"
      >
        {{ 'weekendsActive' | i18n | async }}
      </mat-checkbox>
    </section>
    <section
      *ngIf="ngrxMinDateCurrentDate || ngrxMaxDateCurrentDate"
    >
      <mat-checkbox
        *ngIf="ngrxMinDateCurrentDate"
        class="checkbox-margin"
        [ngrxFormControlState]="ngrxMinDateCurrentDate"
      >
        {{ 'minDateCurrentDate' | i18n | async }}
      </mat-checkbox>
      <mat-checkbox
        *ngIf="ngrxMaxDateCurrentDate"
        class="checkbox-margin"
        [ngrxFormControlState]="ngrxMaxDateCurrentDate"
      >
        {{ 'maxDateCurrentDate' | i18n | async }}
      </mat-checkbox>
      <mat-checkbox
        *ngIf="ngrxPredefineWithCurrentDate"
        class="checkbox-margin"
        [ngrxFormControlState]="ngrxPredefineWithCurrentDate"
      >
        {{ 'predefineWithCurrentDate' | i18n | async }}
      </mat-checkbox>
    </section>

    <lib-common-input-property-lang-wrapper
      [translations]="translations"
      (labelResetClick)="labelResetClick.emit($event)"
      [undoVisible]="labelResetClick && labelResetClick.observers && labelResetClick.observers.length > 0"
    ></lib-common-input-property-lang-wrapper>
  </div>
</lib-common-input-property-wrapper>
