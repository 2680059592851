<section class="section-margin"
  *ngIf="translations"
>
  <h2 class="mat-h2 description">{{headerI18n | i18n | async}}</h2>
  <lib-common-input-lang-string
    [translations]="translations"
    (undoLangClick)="labelResetClick.emit($event)"
    [undoVisible]="undoVisible"
  ></lib-common-input-lang-string>
</section>
