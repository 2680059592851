<div class="margin-content">
  <form (ngSubmit)="saveLedgerAccount()">
    <lib-common-form-flex-container>
      <div
        class="style-translation-formfield"
        *ngFor="let ledgerAccount of (ledgerAccountList$ | async); trackBy: getControlId"
      >
        <lib-common-ledger-account
          (langLabelReset)="resetLangLabel(ledgerAccount, $event)"
          (removeClick)="removeLedgerAccount(ledgerAccount)"
          *ngIf="ledgerAccount"
          [key]="ledgerAccount.controls.key"
          [langLabels]="ledgerAccount.controls.labels"
          [value]="ledgerAccount.controls.value"
          [categories]="ledgerAccount.controls.categories"
          [availableCategories]="availableCategories$ | async"
        ></lib-common-ledger-account>
      </div>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="button"
          (click)="addLedgerAccount()"
          color="accent"
          mat-fab
        >
          <mat-icon svgIcon="innogy:plus"></mat-icon>
        </button>
      </div>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="submit"
          [disabled]="!(formValid$ | async)"
          color="primary"
          mat-raised-button
        >
          <mat-icon
            class="svg-icon-floppy"
            svgIcon="innogy:floppy"
          ></mat-icon>
          {{ 'save' | i18n | async }}
        </button>
      </div>
    </lib-common-form-flex-container>
  </form>
</div>
