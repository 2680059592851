<lib-common-page-margin>
  <div class="margin-bottom-companies-card">
    <div
      class="card-container"
      fxLayout="row wrap"
      fxLayoutGap="10px grid"
      fxLayoutAlign="center stretch"
    >
      <div
          fxFlex.lt-md="0 1 100%"
          fxFlex="0 1 50%"
          *ngFor="let formConfig of easyFormsMapped$ | async"
      >
        <lib-common-form-companies-card
          [cardTitle]="formConfig.fullName"
          [cardSubTitle]="formConfig.category.displayName + ' - ' + formConfig.identifier"
          [childs]="formConfig.companies"
          [addOptions]="options$ | async"
          [submitI18n]="'editNewConfig' | i18n | async"
          [companyI18n]="'company' | i18n | async"
          [deleteSelectedI18n]="'deleteSelected' | i18n | async"
          [addI18n]="'add' | i18n | async"
          titleHeight="125px"
          (add)="addNewConfiguration(formConfig, $event)"
          (delete)="deleteConfigs($event)"
        ></lib-common-form-companies-card>
      </div>
      <div *ngIf="!(easyForms$ | async)" fxFlex="350px">
        {{ 'hasNoForms' | i18n | async }}
      </div>
    </div>
  </div>
</lib-common-page-margin>
