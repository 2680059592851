<div fxLayout="row"
     fxLayoutAlign="center">
  <button
    *ngIf="!noMatStepperPrevious && !noPrevious"
    type="button"
    class="stepper-button"
    mat-raised-button
    color="accent"
    matStepperPrevious
    (click)="previous.emit(); scrollToTop();"
  >
    {{ 'back' | i18n | async }}
  </button>
  <button
    *ngIf="noMatStepperPrevious && !noPrevious"
    type="button"
    class="stepper-button"
    mat-raised-button
    color="accent"
    (click)="previous.emit(); scrollToTop();"
  >
    {{ 'back' | i18n | async }}
  </button>
  <button
    *ngIf="!noSaveCache && !approveMode"
    type="button"
    class="stepper-button"
    mat-raised-button
    color="accent"
    (click)="saveCache.emit()"
  >
    {{ 'saveAndSendLater' | i18n | async }}
    <mat-icon
      class="svg-icon-floppy"
      svgIcon="innogy:floppy"
    ></mat-icon>
  </button>
  <button
    *ngIf="!noMatStepperNext && !noNext"
    type="button"
    class="stepper-button"
    mat-raised-button
    color="primary"
    matStepperNext
    (click)="next.emit(); scrollToTop();"
  >
    {{ 'next' | i18n | async }}
  </button>
  <button
    *ngIf="noMatStepperNext && !noNext"
    type="button"
    class="stepper-button"
    mat-raised-button
    color="primary"
    (click)="next.emit(); scrollToTop();"
  >
    {{ 'overviewPage' | i18n | async }}
  </button>
</div>
