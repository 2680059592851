export const countries = [
  {value: 'DE', label: 'Deutschland'},
  {value: 'AF', label: 'Afghanistan'},
  {value: 'EG', label: 'Ägypten'},
  {value: 'AL', label: 'Albanien'},
  {value: 'DZ', label: 'Algerien'},
  {value: 'VI', label: 'Amer.Jungferni.'},
  {value: 'AD', label: 'Andorra'},
  {value: 'AO', label: 'Angola'},
  {value: 'AI', label: 'Anguilla'},
  {value: 'AQ', label: 'Antarctica'},
  {value: 'AG', label: 'Antigua/Barbuda'},
  {value: 'GQ', label: 'Äquatorialguine'},
  {value: 'AR', label: 'Argentinien'},
  {value: 'AM', label: 'Armenien'},
  {value: 'AW', label: 'Aruba'},
  {value: 'AZ', label: 'Aserbaidschan'},
  {value: 'ET', label: 'Äthiopien'},
  {value: 'AU', label: 'Australien'},
  {value: 'BS', label: 'Bahamas'},
  {value: 'BH', label: 'Bahrain'},
  {value: 'BD', label: 'Bangladesh'},
  {value: 'BB', label: 'Barbados'},
  {value: 'BE', label: 'Belgien'},
  {value: 'BZ', label: 'Belize'},
  {value: 'BJ', label: 'Benin'},
  {value: 'BM', label: 'Bermuda'},
  {value: 'BT', label: 'Bhutan'},
  {value: 'BO', label: 'Bolivien'},
  {value: 'BA', label: 'Bosnien-Herz.'},
  {value: 'BW', label: 'Botsuana'},
  {value: 'BV', label: 'BouvetInseln'},
  {value: 'BR', label: 'Brasilien'},
  {value: 'IO', label: 'Brit.Geb.Ind.Oz'},
  {value: 'VG', label: 'Brit.Jungferni.'},
  {value: 'BN', label: 'BruneiDarussal'},
  {value: 'BG', label: 'Bulgarien'},
  {value: 'BF', label: 'Burkina-Faso'},
  {value: 'BI', label: 'Burundi'},
  {value: 'CL', label: 'Chile'},
  {value: 'CN', label: 'China'},
  {value: 'CK', label: 'Cookinseln'},
  {value: 'CR', label: 'CostaRica'},
  {value: 'DK', label: 'Dänemark'},
  {value: 'DM', label: 'Dominica'},
  {value: 'DO', label: 'Dominik.Rep.'},
  {value: 'DJ', label: 'Dschibuti'},
  {value: 'EC', label: 'Ecuador'},
  {value: 'CI', label: 'Elfenbeinküste'},
  {value: 'SV', label: 'ElSalvador'},
  {value: 'ER', label: 'Eritrea'},
  {value: 'EE', label: 'Estland'},
  {value: 'FK', label: 'Falklandinseln'},
  {value: 'FO', label: 'Färöer'},
  {value: 'FJ', label: 'Fidschi'},
  {value: 'FI', label: 'Finnland'},
  {value: 'FR', label: 'Frankreich'},
  {value: 'GF', label: 'Französ.Guinea'},
  {value: 'PF', label: 'FranzPolynesien'},
  {value: 'GA', label: 'Gabun'},
  {value: 'GM', label: 'Gambia'},
  {value: 'GE', label: 'Georgien'},
  {value: 'GH', label: 'Ghana'},
  {value: 'GI', label: 'Gibraltar'},
  {value: 'GD', label: 'Grenada'},
  {value: 'GR', label: 'Griechenland'},
  {value: 'GL', label: 'Grönland'},
  {value: 'GB', label: 'Großbritannien'},
  {value: 'GP', label: 'Guadeloupe'},
  {value: 'GU', label: 'Guam'},
  {value: 'GT', label: 'Guatemala'},
  {value: 'GN', label: 'Guinea'},
  {value: 'GW', label: 'Guinea-Bissau'},
  {value: 'GY', label: 'Guyana'},
  {value: 'HT', label: 'Haiti'},
  {value: 'HM', label: 'Heard/McDon.Ins'},
  {value: 'HN', label: 'Honduras'},
  {value: 'HK', label: 'Hongkong'},
  {value: 'IN', label: 'Indien'},
  {value: 'ID', label: 'Indonesien'},
  {value: 'IQ', label: 'Irak'},
  {value: 'IR', label: 'Iran'},
  {value: 'IE', label: 'Irland'},
  {value: 'IS', label: 'Island'},
  {value: 'IL', label: 'Israel'},
  {value: 'IT', label: 'Italien'},
  {value: 'JM', label: 'Jamaika'},
  {value: 'JP', label: 'Japan'},
  {value: 'YE', label: 'Jemen'},
  {value: 'JO', label: 'Jordanien'},
  {value: 'KY', label: 'Kaimaninseln'},
  {value: 'KH', label: 'Kambodscha'},
  {value: 'CM', label: 'Kamerun'},
  {value: 'CA', label: 'Kanada'},
  {value: 'CV', label: 'KapVerde'},
  {value: 'KZ', label: 'Kasachstan'},
  {value: 'KE', label: 'Kenia'},
  {value: 'KG', label: 'Kirgistan'},
  {value: 'KI', label: 'Kiribati'},
  {value: 'CC', label: 'Kokosinseln'},
  {value: 'CO', label: 'Kolumbien'},
  {value: 'KM', label: 'Komoren'},
  {value: 'CG', label: 'Kongo'},
  {value: 'XZ', label: 'Kosovo'},
  {value: 'HR', label: 'Kroatien'},
  {value: 'CU', label: 'Kuba'},
  {value: 'KW', label: 'Kuwait'},
  {value: 'LA', label: 'Laos'},
  {value: 'LS', label: 'Lesotho'},
  {value: 'LV', label: 'Lettland'},
  {value: 'LB', label: 'Libanon'},
  {value: 'LR', label: 'Liberia'},
  {value: 'LY', label: 'Libyen'},
  {value: 'LI', label: 'Liechtenstein'},
  {value: 'LT', label: 'Litauen'},
  {value: 'LU', label: 'Luxemburg'},
  {value: 'MO', label: 'Macau'},
  {value: 'MG', label: 'Madagaskar'},
  {value: 'MW', label: 'Malawi'},
  {value: 'MY', label: 'Malaysia'},
  {value: 'MV', label: 'Malediven'},
  {value: 'ML', label: 'Mali'},
  {value: 'MT', label: 'Malta'},
  {value: 'MA', label: 'Marokko'},
  {value: 'MH', label: 'Marshall-Insel'},
  {value: 'MQ', label: 'Martinique'},
  {value: 'MR', label: 'Mauretanien'},
  {value: 'MU', label: 'Mauritius'},
  {value: 'YT', label: 'Mayotte'},
  {value: 'MK', label: 'Mazedonien'},
  {value: 'MX', label: 'Mexiko'},
  {value: 'FM', label: 'Micronesien'},
  {value: 'UM', label: 'MinorOutl.Ins.'},
  {value: 'MD', label: 'Moldau'},
  {value: 'MC', label: 'Monaco'},
  {value: 'MN', label: 'Mongolei'},
  {value: 'ME', label: 'Montenegro'},
  {value: 'MS', label: 'Montserrat'},
  {value: 'MZ', label: 'Mosambik'},
  {value: 'MM', label: 'Myanmar'},
  {value: 'MP', label: 'N.MarianaInsel'},
  {value: 'NA', label: 'Namibia'},
  {value: 'NR', label: 'Nauru'},
  {value: 'NP', label: 'Nepal'},
  {value: 'NC', label: 'Neukaledonien'},
  {value: 'NZ', label: 'Neuseeland'},
  {value: 'NI', label: 'Nicaragua'},
  {value: 'AN', label: 'Niederl.Antill.'},
  {value: 'NL', label: 'Niederlande'},
  {value: 'NE', label: 'Niger'},
  {value: 'NG', label: 'Nigeria'},
  {value: 'NU', label: 'Niue-Inseln'},
  {value: 'KP', label: 'Nordkorea'},
  {value: 'NF', label: 'Norfolkinseln'},
  {value: 'NO', label: 'Norwegen'},
  {value: 'OM', label: 'Oman'},
  {value: 'AT', label: 'Österreich'},
  {value: 'TP', label: 'OstTimor'},
  {value: 'PK', label: 'Pakistan'},
  {value: 'PW', label: 'Palau'},
  {value: 'PA', label: 'Panama'},
  {value: 'PG', label: 'Papua-Neuguinea'},
  {value: 'PY', label: 'Paraguay'},
  {value: 'PE', label: 'Peru'},
  {value: 'PH', label: 'Philippinen'},
  {value: 'PN', label: 'PitcairnInseln'},
  {value: 'PL', label: 'Polen'},
  {value: 'PT', label: 'Portugal'},
  {value: 'PR', label: 'PuertoRico'},
  {value: 'QA', label: 'Qatar'},
  {value: 'RE', label: 'Reunion'},
  {value: 'RW', label: 'Ruanda'},
  {value: 'RO', label: 'Rumänien'},
  {value: 'RU', label: 'RussischeFoed.'},
  {value: 'ST', label: 'S.Tome'},
  {value: 'SB', label: 'Salomonen'},
  {value: 'ZM', label: 'Sambia'},
  {value: 'AS', label: 'Samoa'},
  {value: 'SM', label: 'SanMarino'},
  {value: 'SA', label: 'Saudi-Arabien'},
  {value: 'SE', label: 'Schweden'},
  {value: 'CH', label: 'Schweiz'},
  {value: 'SN', label: 'Senegal'},
  {value: 'RS', label: 'Serbien'},
  {value: 'SC', label: 'Seschellen'},
  {value: 'SL', label: 'SierraLeone'},
  {value: 'ZW', label: 'Simbabwe'},
  {value: 'SG', label: 'Singapur'},
  {value: 'SK', label: 'Slowakei'},
  {value: 'SI', label: 'Slowenien'},
  {value: 'SO', label: 'Somalia'},
  {value: 'ES', label: 'Spanien'},
  {value: 'LK', label: 'SriLanka'},
  {value: 'KN', label: 'St.Chr.'},
  {value: 'SH', label: 'St.Helena'},
  {value: 'LC', label: 'St.Lucia'},
  {value: 'VC', label: 'St.Vincent'},
  {value: 'STL', label: 'Staatenlos'},
  {value: 'PM', label: 'StPier.'},
  {value: 'ZA', label: 'Südafrika'},
  {value: 'SD', label: 'Sudan'},
  {value: 'KR', label: 'Sudkorea'},
  {value: 'SR', label: 'Suriname'},
  {value: 'SJ', label: 'Svalbard'},
  {value: 'SZ', label: 'Swasiland'},
  {value: 'SY', label: 'Syrien'},
  {value: 'TJ', label: 'Tadschikistan'},
  {value: 'TW', label: 'Taiwan'},
  {value: 'TZ', label: 'Tansania'},
  {value: 'TH', label: 'Thailand'},
  {value: 'TG', label: 'Togo'},
  {value: 'TK', label: 'Tokelau-Inseln'},
  {value: 'TO', label: 'Tonga'},
  {value: 'TT', label: 'Trinidad'},
  {value: 'TD', label: 'Tschad'},
  {value: 'CZ', label: 'TschechischeR.'},
  {value: 'TN', label: 'Tunesien'},
  {value: 'TR', label: 'Türkei'},
  {value: 'TM', label: 'Turkmenistan'},
  {value: 'TC', label: 'Turks-'},
  {value: 'TV', label: 'Tuvalu'},
  {value: 'UG', label: 'Uganda'},
  {value: 'UA', label: 'Ukraine'},
  {value: 'HU', label: 'Ungarn'},
  {value: 'CS', label: 'ungült.Serb/Mon'},
  {value: 'UY', label: 'Uruguay'},
  {value: 'US', label: 'USA'},
  {value: 'UZ', label: 'Usbekistan'},
  {value: 'VU', label: 'Vanuatu'},
  {value: 'VA', label: 'Vatikanstadt'},
  {value: 'VE', label: 'Venezuela'},
  {value: 'AE', label: 'Ver.Arab.Emir.'},
  {value: 'VN', label: 'Vietnam'},
  {value: 'WF', label: 'Wallis'},
  {value: 'CX', label: 'Weihnachtsinsel'},
  {value: 'BY', label: 'Weissrussland'},
  {value: 'WS', label: 'Westsamoa'},
  {value: 'ZR', label: 'Zaire'},
  {value: 'CF', label: 'Zentralaf.Rep.'},
  {value: 'CY', label: 'Zypern'},
];
