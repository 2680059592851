import { ConfigurationItems } from '../../core-lib/models/config-dto.model';
import { ConfigItemAccountingConfigurationDtoModel } from '../../core-lib/models/config-item-accounting-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../core-lib/models/config-item-arranger-list-configuration-dto.model';
import { ConfigItemConfigurationCategoryModel } from '../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemDaterangeConfigurationDtoModel } from '../../core-lib/models/config-item-daterange-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../../core-lib/models/config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../../core-lib/models/config-item-text-input-configuration-dto.model';
import { ConfigListDtoModel } from '../../core-lib/models/config-list-dto.model';
import { FormM1DtoModel } from '../../forms/form-m1/models/form-m1-dto.model';
import { EqualKeyNames } from './equal-key-names';

export const EasyFormMSteps = {
  step1: <ConfigItemConfigurationCategoryModel>{
    id: 'step1',
    displayName: 'Gesellschaft',
  },
  step2: <ConfigItemConfigurationCategoryModel>{
    id: 'step2',
    displayName: 'Bewirtungsdetails',
  },
};

export class EasyFormMConfigItems extends ConfigurationItems implements EqualKeyNames<FormM1DtoModel> {
  location = new ConfigItemTextInputConfigurationDtoModel('location').withCategory(EasyFormMSteps.step2);
  dateRange = new ConfigItemDaterangeConfigurationDtoModel('dateRange').withCategory(EasyFormMSteps.step2);
  useCase = new ConfigItemTextInputConfigurationDtoModel('useCase').withCategory(EasyFormMSteps.step2);
  account = new ConfigItemAccountingConfigurationDtoModel('account').withCategory(EasyFormMSteps.step2);
  participantCount = new ConfigItemNumberConfigurationDtoModel('participantCount').withCategory(EasyFormMSteps.step2);

  // Teilnehmerliste
  participants = new ConfigListDtoModel('participants').withCategory(EasyFormMSteps.step2);
  participantName = new ConfigItemTextInputConfigurationDtoModel('participantName').withCategory(EasyFormMSteps.step2);
  participantCompanyName = new ConfigItemTextInputConfigurationDtoModel('participantCompanyName').withCategory(EasyFormMSteps.step2);

  // Genehmigungsstufen
  approver = new ConfigItemArrangerListConfigurationDtoModel('approver').withCategory(EasyFormMSteps.step2);
  inspector = new ConfigItemArrangerListConfigurationDtoModel('inspector').withCategory(EasyFormMSteps.step2);
  commentForApprover = new ConfigItemTextInputConfigurationDtoModel('commentForApprover').withCategory(EasyFormMSteps.step2);

}
