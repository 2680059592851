import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { CoreFeatureState } from '../../ngrx/reducers/core.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-page-sidebar',
  templateUrl: './page-sidebar.component.html',
  styleUrls: ['./page-sidebar.component.scss'],
})
export class PageSidebarComponent implements OnInit, OnChanges, OnDestroy {

  @Input() showAdministration = true;
  @Input() showCatering = false;
  @Input() sidebarOpen = false;
  @Output() toggledSidebar = new EventEmitter<boolean>();

  @ViewChild('sidenav', {static: true}) sidenav: MatSidenav;

  items = () => ([
    {
      name: 'summary',
      link: '/forms',
      icon: 'innogy:home-small',
      show: true,
    },
    {
      name: 'process',
      link: '/list',
      icon: 'innogy:docs-text',
      show: true,
    },
    {
      name: 'approval',
      link: '/approvals',
      icon: 'innogy:docs-check',
      show: true,
    },
    {
      name: 'catering',
      link: '/catering',
      icon: 'innogy:cutlery',
      show: this.showCatering,
    },
    {
      name: 'admin',
      link: '/config',
      icon: 'innogy:double-gear',
      show: this.showAdministration,
    },
  ]);

  private subscription: Subscription;

  constructor(
    private store$: Store<CoreFeatureState>,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.subscription = this.sidenav.openedChange.subscribe((open) => this.toggledSidebar.emit(open));
  }

  navigateRelative(url) {
    // this.router.navigate([url], {relativeTo: this.route});
    // Workaround
    // realtive navigation broken, see: https://github.com/angular/angular/issues/13011
    if (url === './' || url === '.') {
      this.router.navigate([this.router.url]);
    } else {
      this.router.navigate([url], {relativeTo: this.route});
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.sidenav.opened = this.sidebarOpen;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
