<div class="proposal-table-margin">
  <mat-tab-group dynamicHeight>
    <mat-tab label="{{ 'myActions' | i18n | async }}">
      <ng-template matTabContent>
        <div class="datatabel-margin">
          <p class="text-center mat-h4">{{'90DayWarning' | i18n | async}}</p>
          <lib-common-proposal-table type="OWN"></lib-common-proposal-table>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'representedProcesses' | i18n | async }}">
      <ng-template matTabContent>
        <div class="datatabel-margin">
          <lib-common-proposal-table type="DEPUTY"></lib-common-proposal-table>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="isAdmin$| async"
      label="{{ 'allOperations' | i18n | async }}"
    >
      <ng-template matTabContent>
        <div class="datatabel-margin">
          <lib-common-proposal-table type="ALL"></lib-common-proposal-table>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
