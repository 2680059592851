<mat-form-field fxFlex="100%">
  <mat-label>{{ placeholder | i18n | async }}</mat-label>
  <input
    matInput
    [required]="isRequired()"
    [disabled]="isDisabled()"
    [errorStateMatcher]="errorStateMatcher"
    [matAutocomplete]="auto"
    [(ngModel)]="modelValue"
    (ngModelChange)="modelChange($event)"
    [ngModelOptions]="{standalone:true}"
    (blur)="onTouched$.emit()"
    #model="ngModel"
  >
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    panelWidth="auto"
  >
    <mat-option
      *ngFor="let option of filteredOptions"
      [value]="option"
    >
      {{displayFn(option)}}
    </mat-option>
  </mat-autocomplete>
  <mat-spinner
    *ngIf="showSpinner && !isDisabled()"
    matSuffix
    diameter="20"
  ></mat-spinner>
  <button
    *ngIf="!isDisabled() && showOpenButton"
    [disabled]="showSpinner"
    type="button"
    aria-label="search"
    mat-button
    mat-icon-button
    matSuffix
    (click)="openButtonClick.emit($event)"
  >
    <mat-icon class="icon-magnifier" svgIcon="innogy:magnifier"></mat-icon>
  </button>
  <mat-error *ngFor="let key of errorKeys">{{(key + "Error") | i18n: errors | async}}</mat-error>
</mat-form-field>
