<div
  [ngSwitch]="className"
  style="width: 100%; height: 100%"
>
  <div *ngSwitchDefault>
    No Meta Configuration Element for "{{formName}}" with type "{{className}}"!
  </div>
  <lib-common-input-property-type-arranger-list
    *ngSwitchCase="backendClasses.arrangerListConfiguration"
    [attributeName]="formName"
    [ngrxEnabled]="getConfigProp$(formName, 'enabled') | async"
    [ngrxMandatory]="getConfigProp$(formName, 'mandatory') | async"
    [ngrxVisible]="getConfigProp$(formName, 'visible') | async"
    [ngrxChangeableByInspector]="getConfigProp$(formName, 'changeableByInspector') | async"
    [ngrxChangeableByApprover]="getConfigProp$(formName, 'changeableByApprover') | async"
    [translations]="getConfigProp$(formName, 'translations') | async"
    [ngrxValues]="getConfigProp$(formName, 'values') | async"
    [ngrxOverwrite]="getConfigProp$(formName, 'overwrite') | async"
    [readonly]="readonly"
    (addClick)="addArranger($event)"
    (removeClick)="removeArranger($event.array, $event.index)"
  ></lib-common-input-property-type-arranger-list>
  <lib-common-input-property-type-checkbox
    *ngSwitchCase="backendClasses.checkboxConfiguration"
    [attributeName]="formName"
    [ngrxEnabled]="getConfigProp$(formName, 'enabled') | async"
    [ngrxMandatory]="getConfigProp$(formName, 'mandatory') | async"
    [ngrxVisible]="getConfigProp$(formName, 'visible') | async"
    [ngrxChangeableByInspector]="getConfigProp$(formName, 'changeableByInspector') | async"
    [ngrxChangeableByApprover]="getConfigProp$(formName, 'changeableByApprover') | async"
    [ngrxPreChecked]="getConfigProp$(formName, 'preChecked') | async"
    [translations]="getConfigProp$(formName, 'translations') | async"
    [ngrxOverwrite]="getConfigProp$(formName, 'overwrite') | async"
    [readonly]="readonly"
    (labelResetClick)="setFormControlValue($event, formName)"
  ></lib-common-input-property-type-checkbox>
  <lib-common-input-property-type-daterange
    *ngSwitchCase="backendClasses.daterangeConfiguration"
    [attributeName]="formName"
    [ngrxEnabled]="getConfigProp$(formName, 'enabled') | async"
    [ngrxMandatory]="getConfigProp$(formName, 'mandatory') | async"
    [ngrxVisible]="getConfigProp$(formName, 'visible') | async"
    [ngrxChangeableByInspector]="getConfigProp$(formName, 'changeableByInspector') | async"
    [ngrxChangeableByApprover]="getConfigProp$(formName, 'changeableByApprover') | async"
    [ngrxStartDateVisible]="getConfigProp$(formName, 'startDateVisible') | async"
    [ngrxStartTimeVisible]="getConfigProp$(formName, 'startTimeVisible') | async"
    [ngrxEndDateVisible]="getConfigProp$(formName, 'endDateVisible') | async"
    [ngrxEndTimeVisible]="getConfigProp$(formName, 'endTimeVisible') | async"
    [ngrxMaxRangeLength]="getConfigProp$(formName, 'maxRangeLength') | async"
    [ngrxMinRangeLength]="getConfigProp$(formName, 'minRangeLength') | async"
    [ngrxMinStartInFuture]="getConfigProp$(formName, 'minStartInFuture') | async"
    [ngrxMaxStartInFuture]="getConfigProp$(formName, 'maxStartInFuture') | async"
    [ngrxStartPredefinedFromNow]="getConfigProp$(formName, 'startPredefinedFromNow') | async"
    [ngrxEndPredefinedFromNow]="getConfigProp$(formName, 'endPredefinedFromNow') | async"
    [ngrxTimeMin]="getConfigProp$(formName, 'timeMin') | async"
    [ngrxTimeMax]="getConfigProp$(formName, 'timeMax') | async"
    [ngrxWeekendsActive]="getConfigProp$(formName, 'weekendsActive') | async"
    [ngrxPreChecked]="getConfigProp$(formName, 'preChecked') | async"
    [translations]="getConfigProp$(formName, 'translations') | async"
    (endDateTranslationsLabelResetClick)="setFormControlValue($event, formName)"
    (endTimeTranslationsLabelResetClick)="setFormControlValue($event, formName)"
    (startDateTranslationsLabelResetClick)="setFormControlValue($event, formName)"
    (startTimeTranslationsLabelResetClick)="setFormControlValue($event, formName)"
    [endDateTranslations]="getConfigProp$(formName, 'endDateTranslations') | async"
    [endTimeTranslations]="getConfigProp$(formName, 'endTimeTranslations') | async"
    [startDateTranslations]="getConfigProp$(formName, 'startDateTranslations') | async"
    [startTimeTranslations]="getConfigProp$(formName, 'startTimeTranslations') | async"
    [ngrxOverwrite]="getConfigProp$(formName, 'overwrite') | async"
    [readonly]="readonly"
    (labelResetClick)="setFormControlValue($event, formName)"
  ></lib-common-input-property-type-daterange>
  <lib-common-input-property-type-string
    *ngSwitchCase="backendClasses.textInputConfiguration"
    [attributeName]="formName"
    [ngrxEnabled]="getConfigProp$(formName, 'enabled') | async"
    [ngrxMandatory]="getConfigProp$(formName, 'mandatory') | async"
    [ngrxVisible]="getConfigProp$(formName, 'visible') | async"
    [ngrxChangeableByInspector]="getConfigProp$(formName, 'changeableByInspector') | async"
    [ngrxChangeableByApprover]="getConfigProp$(formName, 'changeableByApprover') | async"
    [ngrxMaxLength]="getConfigProp$(formName, 'maxLength') | async"
    [ngrxPredefinedValue]="getConfigProp$(formName, 'predefinedValue') | async"
    [ngrxRegex]="getConfigProp$(formName, 'regex') | async"
    [translations]="getConfigProp$(formName, 'translations') | async"
    [ngrxOverwrite]="getConfigProp$(formName, 'overwrite') | async"
    [readonly]="readonly"
    (labelResetClick)="setFormControlValue($event, formName)"
  ></lib-common-input-property-type-string>
  <lib-common-input-property-type-textarea
    *ngSwitchCase="backendClasses.textareaInputConfiguration"
    [attributeName]="formName"
    [ngrxEnabled]="getConfigProp$(formName, 'enabled') | async"
    [ngrxMandatory]="getConfigProp$(formName, 'mandatory') | async"
    [ngrxVisible]="getConfigProp$(formName, 'visible') | async"
    [ngrxChangeableByInspector]="getConfigProp$(formName, 'changeableByInspector') | async"
    [ngrxChangeableByApprover]="getConfigProp$(formName, 'changeableByApprover') | async"
    [ngrxMaxLength]="getConfigProp$(formName, 'maxLength') | async"
    [ngrxPredefinedValue]="getConfigProp$(formName, 'predefinedValue') | async"
    [ngrxRegex]="getConfigProp$(formName, 'regex') | async"
    [translations]="getConfigProp$(formName, 'translations') | async"
    [ngrxOverwrite]="getConfigProp$(formName, 'overwrite') | async"
    [readonly]="readonly"
    (labelResetClick)="setFormControlValue($event, formName)"
  ></lib-common-input-property-type-textarea>
  <lib-common-input-property-type-date
    *ngSwitchCase="backendClasses.dateConfiguration"
    [attributeName]="formName"
    [ngrxEnabled]="getConfigProp$(formName, 'enabled') | async"
    [ngrxMandatory]="getConfigProp$(formName, 'mandatory') | async"
    [ngrxVisible]="getConfigProp$(formName, 'visible') | async"
    [ngrxChangeableByInspector]="getConfigProp$(formName, 'changeableByInspector') | async"
    [ngrxChangeableByApprover]="getConfigProp$(formName, 'changeableByApprover') | async"
    [ngrxMaxDate]="getConfigProp$(formName, 'maxDate') | async"
    [ngrxMinDate]="getConfigProp$(formName, 'minDate') | async"
    [ngrxMaxDateCurrentDate]="getConfigProp$(formName, 'maxDateCurrentDate') | async"
    [ngrxMinDateCurrentDate]="getConfigProp$(formName, 'minDateCurrentDate') | async"
    [ngrxPredefineWithCurrentDate]="getConfigProp$(formName, 'predefineWithCurrentDate') | async"
    [ngrxWeekendsActive]="getConfigProp$(formName, 'weekendsActive') | async"
    [translations]="getConfigProp$(formName, 'translations') | async"
    [ngrxOverwrite]="getConfigProp$(formName, 'overwrite') | async"
    [readonly]="readonly"
    (labelResetClick)="setFormControlValue($event, formName)"
  ></lib-common-input-property-type-date>
  <lib-common-input-property-type-number
    *ngSwitchCase="backendClasses.numberConfiguration"
    [attributeName]="formName"
    [ngrxEnabled]="getConfigProp$(formName, 'enabled') | async"
    [ngrxMandatory]="getConfigProp$(formName, 'mandatory') | async"
    [ngrxVisible]="getConfigProp$(formName, 'visible') | async"
    [ngrxChangeableByInspector]="getConfigProp$(formName, 'changeableByInspector') | async"
    [ngrxChangeableByApprover]="getConfigProp$(formName, 'changeableByApprover') | async"
    [ngrxMax]="getConfigProp$(formName, 'max') | async"
    [ngrxMin]="getConfigProp$(formName, 'min') | async"
    [ngrxDecimalPoints]="getConfigProp$(formName, 'decimalPoints') | async"
    [ngrxDefaultValue]="getConfigProp$(formName, 'defaultValue') | async"
    [translations]="getConfigProp$(formName, 'translations') | async"
    [ngrxOverwrite]="getConfigProp$(formName, 'overwrite') | async"
    [readonly]="readonly"
    (labelResetClick)="setFormControlValue($event, formName)"
  ></lib-common-input-property-type-number>
  <lib-common-input-property-type-file
    *ngSwitchCase="backendClasses.fileUploadConfiguration"
    [attributeName]="formName"
    [ngrxEnabled]="getConfigProp$(formName, 'enabled') | async"
    [ngrxMandatory]="getConfigProp$(formName, 'mandatory') | async"
    [ngrxVisible]="getConfigProp$(formName, 'visible') | async"
    [ngrxChangeableByInspector]="getConfigProp$(formName, 'changeableByInspector') | async"
    [ngrxChangeableByApprover]="getConfigProp$(formName, 'changeableByApprover') | async"
    [ngrxMax]="getConfigProp$(formName, 'max') | async"
    [ngrxMin]="getConfigProp$(formName, 'min') | async"
    [ngrxRequiredCategories]="getConfigProp$(formName, 'requiredCategories') | async"
    [ngrxAvailableCategories]="getConfigProp$(formName, 'availableCategories') | async"
    [ngrxUploadType]="getConfigProp$(formName, 'uploadType') | async"
    [translations]="getConfigProp$(formName, 'translations') | async"
    [ngrxOverwrite]="getConfigProp$(formName, 'overwrite') | async"
    [readonly]="readonly"
    (labelResetClick)="setFormControlValue($event, formName)"
  ></lib-common-input-property-type-file>
  <lib-common-input-property-type-list
    *ngSwitchCase="backendClasses.listConfiguration"
    [attributeName]="formName"
    [ngrxPredefinedValue]="getConfigProp$(formName, 'predefinedValue') | async"
    [ngrxValues]="getGlobalProperty$() | async"
    [ngrxEnabled]="getConfigProp$(formName, 'enabled') | async"
    [ngrxMandatory]="getConfigProp$(formName, 'mandatory') | async"
    [ngrxVisible]="getConfigProp$(formName, 'visible') | async"
    [ngrxChangeableByInspector]="getConfigProp$(formName, 'changeableByInspector') | async"
    [ngrxChangeableByApprover]="getConfigProp$(formName, 'changeableByApprover') | async"
    [translations]="getConfigProp$(formName, 'translations') | async"
    [ngrxOverwrite]="getConfigProp$(formName, 'overwrite') | async"
    [readonly]="readonly"
    (labelResetClick)="setFormControlValue($event, formName)"
  ></lib-common-input-property-type-list>
  <lib-common-input-property-type-account
    *ngSwitchCase="backendClasses.accountingConfiguration"
    [attributeName]="formName"
    [ngrxType]="getConfigProp$(formName, 'type') | async"
    [ngrxPredefinedValue]="getConfigProp$(formName, 'predefinedValue') | async"
    [ngrxAccountingType]="getConfigProp$(formName, 'accountingType') | async"
    [ngrxEnabled]="getConfigProp$(formName, 'enabled') | async"
    [ngrxMandatory]="getConfigProp$(formName, 'mandatory') | async"
    [ngrxVisible]="getConfigProp$(formName, 'visible') | async"
    [ngrxChangeableByInspector]="getConfigProp$(formName, 'changeableByInspector') | async"
    [ngrxChangeableByApprover]="getConfigProp$(formName, 'changeableByApprover') | async"
    [translations]="getConfigProp$(formName, 'translations') | async"
    [ngrxOverwrite]="getConfigProp$(formName, 'overwrite') | async"
    [readonly]="readonly"
    (labelResetClick)="setFormControlValue($event, formName)"
  ></lib-common-input-property-type-account>
  <lib-common-input-property-type-ledger-account
    *ngSwitchCase="backendClasses.ledgerAccountConfiguration"
    [attributeName]="formName"
    [ngrxType]="getConfigProp$(formName, 'type') | async"
    [ngrxPredefinedValue]="getConfigProp$(formName, 'predefinedValue') | async"
    [ngrxEnabled]="getConfigProp$(formName, 'enabled') | async"
    [ngrxMandatory]="getConfigProp$(formName, 'mandatory') | async"
    [ngrxVisible]="getConfigProp$(formName, 'visible') | async"
    [ngrxChangeableByInspector]="getConfigProp$(formName, 'changeableByInspector') | async"
    [ngrxChangeableByApprover]="getConfigProp$(formName, 'changeableByApprover') | async"
    [translations]="getConfigProp$(formName, 'translations') | async"
    [ngrxOverwrite]="getConfigProp$(formName, 'overwrite') | async"
    [readonly]="readonly"
    (labelResetClick)="setFormControlValue($event, formName)"
  ></lib-common-input-property-type-ledger-account>
</div>
