import { NgModule } from '@angular/core';
import { RouterModule, ROUTES, Routes } from '@angular/router';
import { AppConfigGuard } from '../guards/app-config.guard';
import { CateringRoleBasedRedirectGuard } from '../guards/catering-role-based-redirect.guard';
import { LoginGuard } from '../guards/login.guard';
import { NavDisplayModel } from '../models/nav-display.model';
import { generateImports } from '../utils/generate-imports';
import { lazyFormKeys } from './lazy-form-keys';
import { staticRoutes } from './static-routes';


export function generateLoadingRoutes() {
  const lazyFormsMap = generateImports(lazyFormKeys);
  const routes: Routes = [];
  for (const formKey of lazyFormKeys) {
    const cateringRoleBasedRedirectGuardConfig = CateringRoleBasedRedirectGuard.createRouteDataConfig({
      formKey,
    });

    routes.push(
      {
        path: 'forms/:formsCategory/' + formKey,
        loadChildren: lazyFormsMap[formKey],
        data: {
          navDisplay: <NavDisplayModel[]>[
            {name: 'Übersicht', link: 'forms/:formsCategory'},
          ],
          ...cateringRoleBasedRedirectGuardConfig,
        },
        canActivate: [CateringRoleBasedRedirectGuard],
        canLoad: [LoginGuard],
      },
      {
        path: 'list/:formsCategory/' + formKey,
        loadChildren: lazyFormsMap[formKey],
        data: {
          navDisplay: [
            {name: 'Vorgänge', link: '/list'},
          ],
          ...cateringRoleBasedRedirectGuardConfig,
        },
        canActivate: [CateringRoleBasedRedirectGuard],
        canLoad: [LoginGuard],
      },
      {
        path: 'catering/:formsCategory/' + formKey,
        loadChildren: lazyFormsMap[formKey],
        data: {
          configProp: 'enableCatering',
          navDisplay: [
            {name: 'Bewirtung', link: '/catering'},
          ],
          ...cateringRoleBasedRedirectGuardConfig,
        },
        canActivate: [AppConfigGuard, CateringRoleBasedRedirectGuard],
        canLoad: [LoginGuard],
      },
      {
        path: 'approvals/:formsCategory/' + formKey,
        loadChildren: lazyFormsMap[formKey],
        data: {
          navDisplay: [
            {name: 'Freigabe', link: '/approvals'},
          ],
          ...cateringRoleBasedRedirectGuardConfig,
        },
        canActivate: [CateringRoleBasedRedirectGuard],
        canLoad: [LoginGuard],
      },
    );
  }
  return routes;
}



export const routesProvider = {
  provide: ROUTES,
  multi: true,
  deps: [],
  useValue: [], // Can't compile when not defined
  useFactory: generateLoadingRoutes
};

delete routesProvider.useValue;

@NgModule({
  imports: [
    RouterModule.forRoot(staticRoutes, {
    paramsInheritanceStrategy: 'always',
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule],
  providers: [
    routesProvider,
    {
      provide: RouterModule,
      useFactory: () => {
        return RouterModule.forRoot(generateLoadingRoutes(), {
    paramsInheritanceStrategy: 'always',
    relativeLinkResolution: 'legacy'
});
      }
    }
  ]
})
export class AppRoutingModule {
}
