<div *ngIf="!disabled">
  <ng-container *ngIf="maxFileCount != 1">
    <div *ngFor="let file of files; index as $index">
      <mat-list role="list">
        <mat-list-item role="listitem">
          <mat-label class="margin-form-field">{{file.fileName}} ({{(file.size) ? (file.size | fileSize)
            : file.id ? ('uploaded' | i18n | async) : ''}})
          </mat-label>
          <mat-form-field class="margin-category" *ngIf="availableCategories.length> 0">
            <!-- ngModel ist nicht strictActionImmutability kompatibel-->
            <mat-select
              (selectionChange)="onChangeInternal(files, true)"
              [(ngModel)]="file.category"
              [placeholder]="'category' | i18n |async"
              required
            >
              <mat-option
                *ngFor="let category of availableCategories"
                [value]="category"
              >
                {{ category | i18n | async }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container *ngIf="!sendToBeneficiaryFieldConfig && showSendToBeneficiaryCheckboxes">
            <mat-checkbox
              class="margin-checkbox"
              (ngModelChange)="file.sendToBeneficiary = $event; onChangeInternal(files, true)"
              [ngModel]="file.sendToBeneficiary"
            >
              {{ 'sendToBeneficiary' | i18n | async }}
            </mat-checkbox>
          </ng-container>
          <ng-container *ngIf="sendToBeneficiaryFieldConfig && showSendToBeneficiaryCheckboxes">
            <mat-checkbox
              #chb
              class="margin-checkbox"
              (ngModelChange)="file.sendToBeneficiary = $event; onChangeInternal(files, true)"
              [ngModel]="file.sendToBeneficiary"
              [ngrxEasyFieldConfig]="sendToBeneficiaryFieldConfig"
              (updatePlaceholder)="updateCheckboxPlaceholder(file, $event)"
              (updateDefaultValue)="updateCheckboxDefaultValue(chb, file, $event)"
              (updateMandatory)="updateCheckboxMandatory(file, $event); chb.required=$event"
              (updateEnabled)="updateCheckboxEnabled(chb, $event)"
              (updateVisible)="updateCheckboxVisible(file, $event);"
              [style.visibility]="getCheckboxVisible(file)? 'visible': 'hidden'"
              [class.color-error]="ngrxFormControlState?.errors?.sendToBeneficiary?.required"
              [color]="ngrxFormControlState?.errors?.sendToBeneficiary?.required ? 'warn' : 'accent'"
            >
              {{ getPlaceholder(file) | i18n | async }}{{ getCheckboxMandatory(file) ? ' *' : ''}}
            </mat-checkbox>
          </ng-container>
          <button
            type="button"
            mat-icon-button
            color="accent"
            (click)="removeFile(file, $index)"
            [attr.aria-label]="'fileDelete' | i18n | async"
          >
            <mat-icon
            >delete
            </mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </div>
  </ng-container>
  <div class="margin-error">
    <mat-error
      *ngIf="getMissingFileUploadCategories() | async"
    >
      {{ 'missingFileUploadCategory' | i18n:{categories: getMissingFileUploadCategories()} | async }}
    </mat-error>
    <mat-error
      *ngIf="getErrorCategory('wrongBeneficiaryMimeType')?.length > 0"
    >
      {{ 'wrongBeneficiaryMimeType' | i18n | async }}
    </mat-error>
    <mat-error
      *ngFor="let error of getErrorCategory('invalidMimeType')"
    >
      {{ 'invalidMimeType' | i18n:{type: error.type, allowed: error.allowed, category: error.category} | async }}
    </mat-error>
    <mat-error
      *ngIf="getErrorCategory('maxTotalUploadSize')"
    >
      {{ 'maxTotalUploadSize' | i18n:{maxSize: getErrorCategory('maxTotalUploadSize') | fileSize} | async }}
    </mat-error>
  </div>
  <button
    type="button"
    (click)="fileInput.click()"
    *ngIf="maxFileCount == undefined || maxFileCount <= 1 || files.length < maxFileCount"
    [disabled]="disabled"
    mat-raised-button
    [color]="getButtonColor()"
  >
    <mat-icon class="svg-icon-paperclip" svgIcon="innogy:paperclip"></mat-icon>
    {{ buttonLabel | i18n | async }}{{ isRequired() ? ' *' : '' }}
  </button>
  <input
    #fileInput
    (change)="changeFile($event)"
    [accept]="acceptedType"
    hidden
    type="file"
  />
</div>

<ng-container *ngIf="disabled">
  <div *ngFor="let file of (files)">
    <a
      *ngIf="file.id"
      href="/api/attachment/{{file.id}}"
    >
      {{file.fileName}}
    </a>
    <span
      *ngIf="!file.id"
    >
      {{file.fileName}} ({{file.size | fileSize}})
    </span>
    {{file.category ? '(' + (file.category | i18n | async) + ')' : ''}}
    <mat-checkbox *ngIf="showSendToBeneficiaryCheckboxes"
      #chb
      class="margin-checkbox"
      [disabled]="true"
      [ngModel]="file.sendToBeneficiary"
      [ngrxEasyFieldConfig]="sendToBeneficiaryFieldConfig"
      (updatePlaceholder)="updateCheckboxPlaceholder(file, $event)"
      (updateDefaultValue)="updateCheckboxDefaultValue(chb, file, $event)"
      (updateEnabled)="updateCheckboxEnabled(chb, $event)"
      (updateVisible)="updateCheckboxVisible(file, $event);"
      (updateMandatory)="updateCheckboxMandatory(file, $event); chb.required=$event"
      [style.visibility]="getCheckboxVisible(file)? 'visible': 'hidden'"
    >
      {{ getPlaceholder(file) | i18n | async }}
    </mat-checkbox>
  </div>
</ng-container>
