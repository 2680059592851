<div (keyup.escape)="close(event.ABORT)">
  <h1
    mat-dialog-title
    class="search-modal"
  >{{'ocrTitle' | i18n | async}}</h1>
  <div
    mat-dialog-content
    class="dialog-modal"
    [innerHTML]="'ocrGuidelines' | i18n | async | safe: 'html'"
  >
  </div>
  <div>
    <lib-common-form-entity-id-selector
      placeholderI18nKey="company"
      [options]="options$ | async"
      [ngrxSelectedId]="ocrFormCompanyIdState$ | async"
      [getDisplayText]="getDisplayText"
    ></lib-common-form-entity-id-selector>
  </div>
  <div *ngIf="data.enableUploadButton">
    <button
      type="button"
      (click)="fileInput.click()"
      mat-raised-button
    >
      <mat-icon
        class="svg-icon-paperclip"
        svgIcon="innogy:paperclip"
      ></mat-icon>
      {{fileName || ('ocrUploadButton' | i18n | async)}}

    </button>
    <input
      #fileInput
      hidden
      (change)="changeFile($event)"
      accept="image/*, application/pdf"
      type="file"
    />
  </div>

  <div mat-dialog-actions>
    <button
      color="accent"
      mat-raised-button
      (click)="close(event.ABORT)"
      cdkFocusInitial
    >
      {{'ocrAbortButton' | i18n |async}}
    </button>
    <button
      color="primary"
      mat-raised-button
      [disabled]="(data.enableUploadButton && !(file)) || !(ocrFormValid$ | async)"
      (click)="close(event.SCANNED)"
    >
      {{'ocrScanButton' | i18n | async}}
    </button>
  </div>
</div>
