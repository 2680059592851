import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-page-header-greeting',
  templateUrl: './page-header-greeting.component.html',
  styleUrls: ['./page-header-greeting.component.scss'],
})
export class PageHeaderGreetingComponent {
  @Input() fullHeight = false;
  efaLogo = this.appConfigService.config.efaLogo;

  constructor(private appConfigService: AppConfigService) { }
}
