<div class="margin-content">
  <form (ngSubmit)="save()">
    <lib-common-form-flex-container [maxWidth]="'1500px'">
      <mat-card-title>{{'serviceCenterMaintenance' | i18n | async}}</mat-card-title>
      <div class="service-center-card-width">
        <mat-accordion class="headers-align">
          <ng-container *ngFor="let netRegion of (netRegionArrayState$ | async); let rIndex = index; trackBy: getControlId">
            <mat-expansion-panel *ngIf="!netRegion.value.markedForDeletion">
              <mat-expansion-panel-header>
                <mat-panel-title><strong>{{(getCompany(netRegion.value.company) | async)?.name}}
                  - {{netRegion.value.name}}</strong>
                </mat-panel-title>
                <mat-panel-description>
                  <button type="button" mat-icon-button (click)="markRegionAsDeleted(rIndex)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-card-content>
                <div class="service-center-card-width">
                  <lib-common-form-flex-container>
                    <div
                      fxFlex.gt-md="49%"
                      fxFlex.lt-lg="100%"
                    >
                      <lib-common-select-search
                        [placeholderI18nKey]="'company'"
                        [options]="companies$ | async"
                        [selectState]="netRegion.controls.company"
                        flex="flex"
                        required
                      ></lib-common-select-search>
                    </div>
                    <mat-form-field
                      fxFlex.gt-md="49%"
                      fxFlex.lt-lg="100%"
                    >
                      <mat-label>{{'netRegion' | i18n | async}}</mat-label>
                      <input [ngrxFormControlState]="netRegion.controls.name" matInput required>
                    </mat-form-field>
                  </lib-common-form-flex-container>
                  <mat-accordion class="headers-align">
                    <mat-expansion-panel
                      *ngFor="let serviceCenter of getServiceCenterControls(netRegion); let scIndex = index; trackBy: getControlId"
                      #panel
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title><strong>{{serviceCenter.value.name}}</strong></mat-panel-title>
                        <mat-panel-description>
                          <button type="button" mat-icon-button (click)="removeEntry(netRegion.controls.serviceCenters.id, scIndex)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <lib-common-form-flex-container>
                        <mat-form-field
                          fxFlex.gt-md="49%"
                          fxFlex.lt-lg="100%"
                        >
                          <mat-label>{{'serviceCenter' | i18n | async}}</mat-label>
                          <input [ngrxFormControlState]="serviceCenter.controls.name" matInput required>
                        </mat-form-field>
                      </lib-common-form-flex-container>
                      <lib-common-form-flex-container
                        *ngFor="let accountMapping of getAccountMappings(serviceCenter); let amIndex = index; trackBy: getControlId"
                      >
                        <div
                          fxFlex.gt-md="30%"
                          fxFlex.lt-lg="100%"
                        >
                          <lib-common-select-search
                            [placeholderI18nKey]="'form'"
                            [options]="getFormOptions(serviceCenter.value, accountMapping.controls.formIdentifier.value) | async"
                            [selectState]="accountMapping.controls.formIdentifier"
                            [getElementId]="getFormId"
                            [trackById]="getFormId"
                            [getDisplayText]="getFormDisplayText"
                            flex="flex"
                          ></lib-common-select-search>
                        </div>
                        <div
                          fxFlex.gt-md="30%"
                          fxFlex.lt-lg="100%"
                        >
                          <lib-common-form-accounting-input
                            *ngIf="panel.expanded"
                            [selectedCompany]="getCompany(netRegion.value.company) | async"
                            [controlState]="accountMapping.controls.account"
                            [disabled]="false"
                            placeholder="{{ 'accounting' | i18n | async }}"
                          ></lib-common-form-accounting-input>
                        </div>
                        <div
                          fxFlex.gt-md="5%"
                          fxFlex.lt-lg="100%"
                        >
                          <button
                            type="button"
                            mat-icon-button
                            (click)="removeEntry(serviceCenter.controls.accountFormMappings.id, amIndex)"
                          >
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </lib-common-form-flex-container>
                      <lib-common-form-flex-container>
                        <div>
                          <button
                            type="button"
                            (click)="addFormAccountMapping(serviceCenter.controls.accountFormMappings.id)"
                            color="accent"
                            mat-fab
                          >
                            <mat-icon svgIcon="innogy:plus"></mat-icon>
                          </button>
                        </div>
                      </lib-common-form-flex-container>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <lib-common-form-flex-container>
                  <div>
                    <button
                      type="button"
                      (click)="addServiceCenter(netRegion.controls.serviceCenters.id)"
                      color="accent"
                      mat-fab
                    >
                      <mat-icon svgIcon="innogy:plus"></mat-icon>
                    </button>
                  </div>
                </lib-common-form-flex-container>
              </mat-card-content>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </div>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="button"
          (click)="addNetRegion(arrayId)"
          color="accent"
          mat-fab
        >
          <mat-icon svgIcon="innogy:plus"></mat-icon>
        </button>
      </div>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="submit"
          color="primary"
          mat-raised-button
          [disabled]="isFormInvalid$ | async"
        >
          <mat-icon
            class="svg-icon-floppy"
            svgIcon="innogy:floppy"
          ></mat-icon>
          {{ 'save' | i18n | async }}
        </button>
      </div>
    </lib-common-form-flex-container>
  </form>
</div>
