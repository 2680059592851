import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormCompaniesDialogDataAtomModel } from './form-companies-dialog-data-atom.model';
import { FormCompaniesDialogDataModel } from './form-companies-dialog-data.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-form-companies-dialog',
  templateUrl: './form-companies-dialog.component.html',
  styleUrls: ['./form-companies-dialog.component.scss'],
})
export class FormCompaniesDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  public companySelectCtrl: FormControl = new FormControl();

  public companyFilterCtrl: FormControl = new FormControl();

  public filteredCompanies: ReplaySubject<FormCompaniesDialogDataAtomModel[]> = new ReplaySubject<FormCompaniesDialogDataAtomModel[]>(1);

  protected _onDestroy = new Subject<void>();

  @ViewChild('singleSelect', {static: true}) singleSelect: MatSelect;

  constructor(
    public dialogRef: MatDialogRef<FormCompaniesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormCompaniesDialogDataModel,
  ) {}

  ngOnInit() {
    // set initial selection
    this.companySelectCtrl.setValue(this.data.options[0]);

    // load the initial companies list
    this.filteredCompanies.next(this.data.options.slice());

    // listen for search field value changes
    this.companyFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterElements();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Sets the initial value after the filteredElements are loaded initially
   */
  protected setInitialValue() {
    this.filteredCompanies
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredElements are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: FormCompaniesDialogDataAtomModel, b: FormCompaniesDialogDataAtomModel) =>
          a && b && a.displayName === b.displayName;
      });
  }

  protected filterElements() {
    if (!this.data.options) {
      return;
    }
    // get the search keyword
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filteredCompanies.next(this.data.options.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the elements
    this.filteredCompanies.next(
      this.data.options.filter(bank => bank.displayName.toLowerCase().indexOf(search) > -1),
    );
  }

  onSubmit() {
    this.dialogRef.close(this.companySelectCtrl.value);
  }
}
