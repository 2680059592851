import { Action } from '@ngrx/store';
import { FormDtoModel } from '../../../core-lib/models/form-dto.model';
import { TileModel } from '../../../core-lib/models/tile.model';
import { ApiErrorAction, ApiSuccessAction } from './core.actions';


export class FavoriteActionGet implements Action {
  static readonly TYPE = 'favorite/Get';
  readonly type = FavoriteActionGet.TYPE;
}

export class FavoriteActionGetFirst implements Action {
  static readonly TYPE = 'favorite/GetFirst';
  readonly type = FavoriteActionGetFirst.TYPE;
}

export class FavoriteActionGetSuccess implements Action {
  static readonly TYPE = 'favorite/GetSuccess';
  readonly type = FavoriteActionGetSuccess.TYPE;

  constructor(public favorites: FormDtoModel[]) {
  }
}

export class FavoriteActionGetFirstSuccess implements Action {
  static readonly TYPE = 'favorite/GetFirstSuccess';
  readonly type = FavoriteActionGetFirstSuccess.TYPE;

  constructor(public favorites: FormDtoModel[]) {
  }
}

export class FavoriteActionAdd implements Action {
  static readonly TYPE = 'favorite/Add';
  readonly type = FavoriteActionAdd.TYPE;

  constructor(public id: string) {
  }
}

export class FavoriteActionDelete implements Action {
  static readonly TYPE = 'favorite/Delete';
  readonly type = FavoriteActionDelete.TYPE;

  constructor(public identifier: string) {
  }
}

export class FavoriteActionSaveOrder implements Action {
  static readonly TYPE = 'favorite/order';
  readonly type = FavoriteActionSaveOrder.TYPE;

  constructor(public data: TileModel[]) {
  }
}

export class FavoriteActionSaveOrderSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/favorite/orderSuccess';
  messageKey = 'viewSaveSuccess';
  readonly type = FavoriteActionSaveOrderSuccess.TYPE;
  snackBarDuration = 5000;
}

export class FavoriteActionSaveSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/favorite/SaveSuccess';
  messageKey = 'favoriteActionSaveSuccess';
  readonly type = FavoriteActionSaveSuccess.TYPE;
  snackBarDuration = 5000;
}

export class FavoriteActionDeleteSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/favorite/DeleteSuccess';
  messageKey = 'favoriteActionDeleteSuccess';
  readonly type = FavoriteActionDeleteSuccess.TYPE;
  snackBarDuration = 5000;
}

export class FavoriteActionError extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/favorite/SaveError';
  readonly type = FavoriteActionError.TYPE;
}

export type FavoriteAction =
  FavoriteActionGet |
  FavoriteActionGetSuccess |
  FavoriteActionGetFirst |
  FavoriteActionGetFirstSuccess |
  FavoriteActionAdd |
  FavoriteActionDelete |
  FavoriteActionSaveSuccess |
  FavoriteActionDeleteSuccess |
  FavoriteActionError;






