/*
 * Public API Surface of common
 */

import { CoreLibModule } from './core-lib/core-lib.module';
import { CoreModule } from './core/core.module';
import { SessionModule } from './session/session.module';

export const allModules: any[] = [
  CoreLibModule,
  SessionModule,
  CoreModule,
  // uncomment to add _trace to actions
  // environment.production ? [] : TraceStoreModule,
];

export * from './core/core.module';
export * from './core-lib/core-lib.module';
export * from './forms-lib/forms-lib.module';
export * from './session/session.module';
