import { ConfigurationItems } from '../../core-lib/models/config-dto.model';
import { ConfigItemAccountingConfigurationDtoModel } from '../../core-lib/models/config-item-accounting-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../core-lib/models/config-item-arranger-list-configuration-dto.model';
import { ConfigItemConfigurationCategoryModel } from '../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemDaterangeConfigurationDtoModel } from '../../core-lib/models/config-item-daterange-configuration-dto.model';
import { ConfigItemListConfigurationDtoModel } from '../../core-lib/models/config-item-list-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../../core-lib/models/config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../../core-lib/models/config-item-text-input-configuration-dto.model';
import { ConfigListDtoModel } from '../../core-lib/models/config-list-dto.model';
import { FormIB1DtoModel } from '../../forms/form-ib1/models/form-ib1-dto.model';
import { EqualKeyNames } from './equal-key-names';

export const easyFormIbSteps = {
  step1: <ConfigItemConfigurationCategoryModel>{
    id: 'step1',
    displayName: 'Gesellschaft',
  },
  step2: <ConfigItemConfigurationCategoryModel>{
    id: 'step2',
    displayName: 'Bewirtungsdetails',
  },
};

export class EasyFormIbConfigItems extends ConfigurationItems implements EqualKeyNames<FormIB1DtoModel> {

  // Bewirtungsdetails
  // TODO: Fachlich kein Textinput, sondern eine Location-Selection, sollte daher auch eine eigene Config-Klasse haben
  location = new ConfigItemTextInputConfigurationDtoModel('location').withCategory(easyFormIbSteps.step2);
  dateRange = new ConfigItemDaterangeConfigurationDtoModel('dateRange').withCategory(easyFormIbSteps.step2);
  useCase = new ConfigItemTextInputConfigurationDtoModel('useCase').withCategory(easyFormIbSteps.step2);
  account = new ConfigItemAccountingConfigurationDtoModel('account').withCategory(easyFormIbSteps.step2);
  participantCount = new ConfigItemNumberConfigurationDtoModel('participantCount').withCategory(easyFormIbSteps.step2);
  // TODO: Fachlich kein Textinput, sondern eine Room-Selection, sollte daher auch eine eigene Config-Klasse haben
  room = new ConfigItemTextInputConfigurationDtoModel('room').withCategory(easyFormIbSteps.step2);
  deliveryType = new ConfigItemTextInputConfigurationDtoModel('deliveryType').withCategory(easyFormIbSteps.step2);
  description = new ConfigItemTextInputConfigurationDtoModel('description').withCategory(easyFormIbSteps.step2);

  articles = new ConfigListDtoModel('articles').withCategory(easyFormIbSteps.step2);
  articleName = new ConfigItemTextInputConfigurationDtoModel('articleName').withCategory(easyFormIbSteps.step2);
  amount = new ConfigItemNumberConfigurationDtoModel('amount').withCategory(easyFormIbSteps.step2);
  orderedAmount = new ConfigItemNumberConfigurationDtoModel('orderedAmount').withCategory(easyFormIbSteps.step2);
  deliveryAmount = new ConfigItemNumberConfigurationDtoModel('deliveryAmount').withCategory(easyFormIbSteps.step2);
  unit = new ConfigItemTextInputConfigurationDtoModel('unit').withCategory(easyFormIbSteps.step2);
  taxRate = new ConfigItemListConfigurationDtoModel('taxRate', 'taxrates').withCategory(easyFormIbSteps.step2);
  price = new ConfigItemNumberConfigurationDtoModel('price').withCategory(easyFormIbSteps.step2);
  sum = new ConfigItemNumberConfigurationDtoModel('sum').withCategory(easyFormIbSteps.step2);
  deliverySum = new ConfigItemNumberConfigurationDtoModel('deliverySum').withCategory(easyFormIbSteps.step2);
  orderedSum = new ConfigItemNumberConfigurationDtoModel('orderedSum').withCategory(easyFormIbSteps.step2);

  // Genehmigungsstufen
  approver = new ConfigItemArrangerListConfigurationDtoModel('approver').withCategory(easyFormIbSteps.step2);
  inspector = new ConfigItemArrangerListConfigurationDtoModel('inspector').withCategory(easyFormIbSteps.step2);
  commentForApprover = new ConfigItemTextInputConfigurationDtoModel('commentForApprover').withCategory(easyFormIbSteps.step2);
}
