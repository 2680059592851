import { Action } from '@ngrx/store';

export enum RouterActionTypes {
  RouterActionChangedIndex = 'router/changedIndex',
  RouterActionNavigate = 'router/navigate',
  RouterActionBack = 'router/back',
  RouterActionForward = 'router/forward',
}

export class RouterActionChangedIndex implements Action {
  readonly type = RouterActionTypes.RouterActionChangedIndex;
}

export class RouterActionNavigate implements Action {
  readonly type = RouterActionTypes.RouterActionNavigate;

  constructor(public payload: { params: any, path: string, data: any}) {
  }
}

export class RouterActionBack implements Action {
  readonly type = RouterActionTypes.RouterActionBack;
}

export class RouterActionForward implements Action {
  readonly type = RouterActionTypes.RouterActionForward;
}

export type RouterActions =
  RouterActionChangedIndex
  | RouterActionNavigate
  | RouterActionBack
  | RouterActionForward;

