import { Boxed } from 'ngrx-forms';
import { ConfigItemConfigurationDtoModel } from './config-item-configuration-dto.model';

export class ConfigDtoModel<T extends ConfigurationItems> {
  id: string;
  parent: Boxed<ConfigDtoModel<T>> = undefined;

  constructor(
    public configurationItems: T = <T>{},
    public form: string,
    public company: string,
  ) {
  }
}

export interface ConfigDtoRestModel<T extends ConfigurationItems> {
  id: string;
  parent?: ConfigDtoModel<T>;
  configurationItems: T;
  form: string;
  company: string;
}

export class ConfigurationItems {
  [key: string]: ConfigItemConfigurationDtoModel;
}
