<div class="search-modal">{{ 'searchUser' | i18n | async }}</div>

<div mat-dialog-content class="dialog-modal">
  <mat-form-field class="search-input">
    <input matInput [placeholder]="label | i18n | async" aria-label="user" #model="ngModel" [(ngModel)]="search">
  </mat-form-field>

  <mat-table [dataSource]="users$">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>{{ 'name' | i18n | async }}</mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="selectUser(element)" class="clickable">{{ element.name }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="mail">
      <mat-header-cell *matHeaderCellDef>{{ 'mailAdress' | i18n | async }}</mat-header-cell>
      <mat-cell *matCellDef="let element" (click)="selectUser(element)" class="clickable">{{ element.mail }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="['name','mail']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['name','mail'];"></mat-row>
  </mat-table>
  <div></div>

  <div *ngIf="(users$ | async)?.length==0 && search.length>=3" class="infoText">
    <mat-icon class="infoIcon-sptr" svgIcon="innogy:info"></mat-icon>
    <span class="sptr-infotext">{{ 'searchPersonToRegister1' | i18n | async }}
      <a [href]=" 'mailTo:?subject=' + ('registerAtEasy' | i18n | async)+ '&body='
      +('registerAtEasyText' | i18n | async)+getOrigin() ">
        {{ 'here' | i18n | async }}  </a>
      {{ 'searchPersonToRegister2' | i18n | async }}</span>
  </div>

</div>
