import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormControlState } from 'ngrx-forms';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { QueryPromptData } from '../../models/query-prompt-data.model';
import { InputLangStringsFormState } from '../input-lang-string/input-lang-string.component';
import { QueryPromptComponent } from '../query-prompt/query-prompt.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-salutation',
  templateUrl: './salutation.component.html',
  styleUrls: ['./salutation.component.scss'],
})
export class SalutationComponent implements OnDestroy {
  private componentDestroyed$ = new Subject<void>();

  @Input() key: FormControlState<string>;
  @Input() value: FormControlState<string>;
  @Input() langLabels: InputLangStringsFormState;
  @Output() langLabelReset = new EventEmitter<FormControlState<string>>();
  @Output() removeClick = new EventEmitter();

  constructor(public dialog: MatDialog) {}

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  emitLangLabelReset($event: FormControlState<string>) {
    this.langLabelReset.emit($event);
  }

  openPrompt() {
    const dialogRef = this.dialog.open(QueryPromptComponent, {
      data: <QueryPromptData>{
        title: 'deleteSalutationTitle',
        text: 'deleteSalutationText',
      },
    });

    dialogRef.afterClosed().pipe(
      takeUntil(this.componentDestroyed$),
      filter(event => event === 'accepted'),
    ).subscribe(() => this.removeClick.emit());
  }
}
