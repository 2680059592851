import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { box, FormGroupState, unbox } from 'ngrx-forms';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CompanyDataDtoModel } from '../../../core-lib/models/company-data-dto.model';
import { UserDataDtoModel } from '../../../core-lib/models/user-data-dto.model';
import { UserDataFormModel } from '../../../core-lib/models/user-data-form.model';
import { ApiService } from '../../../core-lib/services/api.service';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { ArrangerDtoModel } from '../../../forms/all-forms/models/arranger-dto.model';
import { SessionActionLoad, SessionActionLoadedUser } from '../../../session/actions/session.actions';
import {
  CoreAccountDeleteCostCenterAction,
  CoreAccountDeleteCostCenterError,
  CoreAccountDeleteCostCenterSuccess,
  CoreAccountDeleteDefaultArrangerAction,
  CoreAccountDeleteDefaultArrangerError,
  CoreAccountDeleteDefaultArrangerSuccess,
  CoreAccountFormActionLoadedUserData,
  CoreAccountFormActionSave,
  CoreAccountFormActionSaveError,
  CoreAccountFormActionSaveSuccess,
  coreAccountFormNetRegionsLoaded,
} from '../actions/core-account.actions';
import { CoreFeatureState, getAccountDefaultCompanyControlValue, getAccountFeatureState } from '../reducers/core.store';
import { DataTransformers } from './dataTransformers';

@Injectable()
export class CoreAccountEffects {
  accountFormState$ = this.store$.select(getAccountFeatureState);

  accountFormSave$ = createEffect(() => this.actions$.pipe(
    ofType(CoreAccountFormActionSave.TYPE),
    withLatestFrom(this.accountFormState$),
    map(([action, accountFormState]: [CoreAccountFormActionSave, FormGroupState<UserDataFormModel>]) => accountFormState),
    filter(accountFormState => accountFormState.isValid && accountFormState.isTouched),
    map((accountFormState: FormGroupState<UserDataFormModel>): UserDataDtoModel => ({
      ...accountFormState.value,
      salutation: DataTransformers.mapConfigListModelToDto(unbox(accountFormState.value.salutation)),
      defaultCompany: unbox(accountFormState.value.defaultCompany),
      defaultServiceCenter: accountFormState.value.defaultServiceCenter,
      defaultNetRegion: accountFormState.value.defaultNetRegion,
      costCenterToCompanies: accountFormState.value.costCenterToCompanies.filter((ctc) => !!ctc.company && !!ctc.costCenter),
      defaultArrangers: unbox(accountFormState.value.defaultArrangers)
        .filter((arranger: ArrangerDtoModel) => !!arranger.userId)
        .map((arranger: ArrangerDtoModel, index) => ({
          ...arranger,
          priority: index,
        })),
    })),
    switchMap((accountFormState: UserDataDtoModel) => this.api.postUserData(accountFormState).pipe(
        map((result: UserDataDtoModel): UserDataFormModel => ({
          ...result,
          firstName: accountFormState.firstName,
          lastName: accountFormState.lastName,
          email: accountFormState.email,
          salutation: box(DataTransformers.mapDtoToConfigListModel(result.salutation)),
          defaultCompany: box(result.defaultCompany),
          defaultServiceCenter: accountFormState.defaultServiceCenter,
          defaultNetRegion: accountFormState.defaultNetRegion,
          defaultNetRegionAccount: accountFormState.defaultNetRegionAccount,
        })),
        map((userData: UserDataFormModel) => new CoreAccountFormActionSaveSuccess(userData)),
        catchError((e) => of(new CoreAccountFormActionSaveError(e))),
      ),
    ),
  ), defaultEffectOptions());

  accountFormUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(SessionActionLoadedUser.TYPE),
    map((action: SessionActionLoadedUser) => ({
      ...action.user.data,
      firstName: action.user.firstName,
      lastName: action.user.lastName,
      email: action.user.email,
      salutation: box(DataTransformers.mapDtoToConfigListModel(action.user.data.salutation)),
      defaultCompany: box(action.user.data.defaultCompany),
    })),
    map((data: UserDataFormModel) => new CoreAccountFormActionLoadedUserData(data)),
  ), defaultEffectOptions());

  accountFormSaveSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CoreAccountFormActionSaveSuccess.TYPE),
    map(() => new SessionActionLoad()),
  ), defaultEffectOptions());

  deleteCostCenter = createEffect(() => this.actions$.pipe(
    ofType(CoreAccountDeleteCostCenterAction.TYPE),
    switchMap((action: CoreAccountDeleteCostCenterAction) => this.api.deleteCostCenter(action.costCenterId).pipe(
      map(() => new CoreAccountDeleteCostCenterSuccess()),
      catchError(e => of(new CoreAccountDeleteCostCenterError(e, e.message))),
    )),
  ), defaultEffectOptions());

  deleteDefaultArranger = createEffect(() => this.actions$.pipe(
    ofType(CoreAccountDeleteDefaultArrangerAction.TYPE),
    switchMap((action: CoreAccountDeleteDefaultArrangerAction) => this.api.deleteArranger(action.arrangerId).pipe(
      map(() => new CoreAccountDeleteDefaultArrangerSuccess()),
      catchError(e => of(new CoreAccountDeleteDefaultArrangerError(e, e.message))),
    )),
  ), defaultEffectOptions());

  loadNetRegions = createEffect(() => this.store$.select(getAccountDefaultCompanyControlValue).pipe(
    map(unbox),
    filter((defaultCompany: CompanyDataDtoModel) => !!defaultCompany?.id),
    switchMap((defaultCompany: CompanyDataDtoModel) =>
      this.api.getServiceCenterDataByCompany(defaultCompany)),
    map(data => coreAccountFormNetRegionsLoaded({data})),
  ));

  constructor(
    private actions$: Actions,
    private store$: Store<CoreFeatureState>,
    private api: ApiService,
  ) {
  }
}
