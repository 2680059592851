import { Dictionary } from '@ngrx/entity';
import { createReducer, createSelector, on } from '@ngrx/store';
import { FormBaseDtoModel } from '../../../forms/form-base/models/form-base-dto.model';
import { setTemplateDeputy } from '../actions/template-deputy.actions';

export interface TemplateDeputeState {
  deputyTemplate: Dictionary<FormBaseDtoModel[]>;
}

const initialTemplateState: TemplateDeputeState = {
  deputyTemplate: undefined,
};

export const templateDeputyReducer = createReducer(
  initialTemplateState,
  on(setTemplateDeputy, (s, {data}) => ({...s, deputyTemplate: data})),
);

export const getCoreTemplateDeputyFeaturesState = createSelector(
  (s) => s,
  (s: any): Dictionary<FormBaseDtoModel[]> => s.core.templateDeputy.deputyTemplate,
);

export const getDeputyTemplateSelected = <T extends FormBaseDtoModel = FormBaseDtoModel>() => createSelector(
  getCoreTemplateDeputyFeaturesState,
  (s: Dictionary<FormBaseDtoModel[]>, props: {
    templateId: string,
    formIdentifier: string
  }): T => {
    if (s === undefined) {
      return undefined;
    }

    const templates = Object.values(s).reduce((previousValue, currentValue) => previousValue.concat(currentValue), []);
    const templateId = props.templateId;
    const formIdentifier = props.formIdentifier;

    const formBaseDtoModel = templates && templates.find((t) => templateId
      && t.id === +templateId
      && formIdentifier
      && t.form.identifier === formIdentifier);

    return <T>formBaseDtoModel;
  },
);
