import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControlState } from 'ngrx-forms';
import { RouteConfigFormPropertyComponent } from '../../../core/components/route-config-form-property/route-config-form-property.component';
import { CoreFeatureState } from '../../../core/ngrx/reducers/core.store';
import { ConfigItemCheckboxConfigurationDtoModel } from '../../models/config-item-checkbox-configuration-dto.model';
import { SetValueTraceableAction } from '../../models/set-value-traceable-action';
import { InputPropertyTypeComponent } from '../input-property-type/input-property-type.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-input-property-type-checkbox',
  templateUrl: './input-property-type-checkbox.component.html',
  styleUrls: ['./input-property-type-checkbox.component.scss'],
})
export class InputPropertyTypeCheckboxComponent extends InputPropertyTypeComponent {

  typeName = ConfigItemCheckboxConfigurationDtoModel.TYPENAME;

  @Input() ngrxPreChecked: FormControlState<number>;

  constructor(store$: Store<CoreFeatureState>, util: RouteConfigFormPropertyComponent) {
    super(store$, util);
  }

  internalReloadValues(lastConfigItemValue) {
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxPreChecked.id, lastConfigItemValue.preChecked));
  }
}
