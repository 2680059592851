<div>
  <form (ngSubmit)="saveTaxRates()">
    <lib-common-form-flex-container>
      <div *ngFor="let taxrate of (taxRates$ | async); trackBy: getTaxRateControlId">
        <mat-card>
          <mat-card-content>
            <lib-common-tax-rate
              (langLabelReset)="resetLangLabel(taxrate, $event)"
              (removeClick)="removeTaxRate(taxrate)"
              *ngIf="taxrate"
              [key]="taxrate.controls.key"
              [langLabels]="taxrate.controls.labels"
              [value]="taxrate.controls.value"
            ></lib-common-tax-rate>
          </mat-card-content>
        </mat-card>
      </div>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="button"
          (click)="addTaxRate()"
          color="accent"
          mat-fab
        >
          <mat-icon svgIcon="innogy:plus"></mat-icon>
        </button>
      </div>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="submit"
          [disabled]="!(formValid$ | async)"
          color="primary"
          mat-raised-button
        >
          <mat-icon
            class="svg-icon-floppy"
            svgIcon="innogy:floppy"
          ></mat-icon>
          {{ 'save' | i18n | async }}
        </button>
      </div>
    </lib-common-form-flex-container>
  </form>
</div>
