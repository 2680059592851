<div class="proposal-table-margin">
  <mat-tab-group dynamicHeight>
    <mat-tab label="{{ 'myApprovals' | i18n | async }}">
      <ng-template matTabContent>
        <div class="datatabel-margin">
          <lib-common-proposal-table type="OWN_PENDING_APPROVALS"></lib-common-proposal-table>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'representedApprovals' | i18n | async }}">
      <ng-template matTabContent>
        <div class="datatabel-margin">
          <lib-common-proposal-table type="DEPUTY_PENDING_APPROVALS"></lib-common-proposal-table>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'finishedApprovals' | i18n | async }}">
      <ng-template matTabContent>
        <div class="datatabel-margin">
          <lib-common-proposal-table type="FINISHED_APPROVALS"></lib-common-proposal-table>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="isAdmin$| async"
      label="{{ 'allApprovals' | i18n | async }}"
    >
      <ng-template matTabContent>
        <div class="datatabel-margin">
          <lib-common-proposal-table type="ALL_PENDING_APPROVALS"></lib-common-proposal-table>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
