import { createEntityAdapter } from '@ngrx/entity';
import { FormDtoModel } from '../../../forms/all-forms/models/form-dto.model';
import { TemplateAction, TemplateActionGetSuccess, TemplateActionSetCollapsed } from '../actions/template.action';
import { TemplateState } from './core-template-store';

const templateStateEntityAdapter = createEntityAdapter<FormDtoModel>();

export const initialTemplateState: TemplateState = {
  collapsed: true,
  ...templateStateEntityAdapter.getInitialState(),
};

export function coreTemplateReducer(state = initialTemplateState, action: TemplateAction): TemplateState {
  switch (action.type) {
    case TemplateActionGetSuccess.TYPE:
      return {
        ...state,
        ...templateStateEntityAdapter.setAll(action.templates, initialTemplateState),
      };
    case TemplateActionSetCollapsed.TYPE:
      return {
        ...state,
        collapsed: action.collapsed,
      };
    default:
      return state;
  }
}

