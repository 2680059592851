import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppConfigService } from '../../core/services/app-config.service';

@Injectable()
export class UrlsService {
  constructor(
    protected appConfigService: AppConfigService,
    protected router: Router,
  ) {
  }

  backendBase$ = combineLatest([
    this.appConfigService.configObservable.pipe(
      map(config => config.backendBase),
    ),
    this.router.events.pipe(
      filter<NavigationEnd>((e) => e instanceof NavigationEnd),
    ),
  ]).pipe(
    map(([backendBase, routerEvent]) => ({
      backendBase,
      url: window.location.origin + routerEvent.urlAfterRedirects,
    })),
  );

  logoutUrl$ = this.backendBase$.pipe(
    map(({backendBase, url}) => `${backendBase}/api/session/logout?forward=${encodeURIComponent(url)}`),
  );

  loginUrl$ = this.backendBase$.pipe(
    map(({backendBase, url}) => `${backendBase}/api/session/login?forward=${encodeURIComponent(url)}`),
  );
}
