import { Directive, EventEmitter, Host, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDatepickerInput } from '@angular/material/datepicker';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorComponent } from '@kolkov/angular-editor';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { AbstractControlState, box, DisableAction, EnableAction, FormControlValueTypes, SetUserDefinedPropertyAction } from 'ngrx-forms';
import { TimepickerDirective } from 'ngx-material-timepicker';
import { Observable } from 'rxjs';
import { filter, map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CoreFeatureState, getI18nSelectedLanguage } from '../../core/ngrx/reducers/core.store';
import { ApproverListComponent } from '../../forms-lib/components/approver-list/approver-list.component';
import { FormAccountingInputComponent } from '../../forms-lib/components/form-accounting-input/form-accounting-input.component';
import { FormFieldCheckboxComponent } from '../../forms-lib/components/form-field-checkbox/form-field-checkbox.component';
import { FormSapAutocompleteInputComponent } from '../../forms-lib/components/form-sap-autocomplete/form-sap-autocomplete-input.component';
import { SapInfoModel } from '../../forms-lib/models/sap-info.model';
import { formBaseSelectors } from '../../forms/form-base/ngrx/selectors';
import { CurrencyInputComponent } from '../components/currency-input/currency-input.component';
import { FileUploadComponent } from '../components/file-upload/file-upload.component';
import { UserSearchInputComponent } from '../components/user-search-input/user-search-input.component';
import { backendClasses } from '../models/backend-classes.model';
import { ConfigurationItems } from '../models/config-dto.model';
import { ConfigItemAccountingConfigurationDtoModel } from '../models/config-item-accounting-configuration-dto.model';
import { ConfigItemCheckboxConfigurationDtoModel } from '../models/config-item-checkbox-configuration-dto.model';
import { ConfigItemConfigurationDtoModel } from '../models/config-item-configuration-dto.model';
import { ConfigItemDateConfigurationDtoModel } from '../models/config-item-date-configuration-dto.model';
import { ConfigItemDaterangeConfigurationDtoModel } from '../models/config-item-daterange-configuration-dto.model';
import { ConfigItemFileUploadConfigurationDtoModel } from '../models/config-item-file-upload-configuration-dto.model';
import {
  ConfigItemLedgerAccountConfigurationDtoModel,
  ConfigLedgerAcountType,
} from '../models/config-item-ledger-account-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../models/config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../models/config-item-text-input-configuration-dto.model';
import { fileUploadTypeModelToAcceptInfo } from '../models/file-upload-type.model';
import { SetValueTraceableAction } from '../models/set-value-traceable-action';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ngrxEasyFieldConfig]',
})
export class NgrxEasyFieldConfigDirective implements OnDestroy, OnInit {
  constructor(
    @Host() @Optional() private input: MatInput,
    @Host() @Optional() private momentInput: MatDatepickerInput<moment.Moment>,
    @Host() @Optional() private momentStringInput: MatDatepickerInput<string>,
    @Host() @Optional() private momentTimeInput: TimepickerDirective,
    @Host() @Optional() private select: MatSelect,
    @Host() @Optional() private currencyInput: CurrencyInputComponent,
    @Host() @Optional() private fileUpload: FileUploadComponent,
    @Host() @Optional() private userSearchInput: ApproverListComponent,
    @Host() @Optional() private accountingInfoInput: FormAccountingInputComponent,
    @Host() @Optional() private sapAutocompleteInput: FormSapAutocompleteInputComponent,
    @Host() @Optional() private checkBox: FormFieldCheckboxComponent,
    @Host() @Optional() private matCheckBox: MatCheckbox,
    @Host() @Optional() private userSearchInputComponent: UserSearchInputComponent,
    @Host() @Optional() private matFormField: MatFormField,
    @Host() @Optional() private angularEditor: AngularEditorComponent,
    private store$: Store<CoreFeatureState>,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  currentConfig = {};

  _configName: string;

  get configName() {
    return this._configName;
  }

  @Input() ngrxEasyFieldTranslationsKey = 'translations';

  @Input('ngrxEasyFieldConfig')
  set configName(name: string) {
    this._configName = name;
    if (this.ngrxFormControlState) {
      this.ngrxFormControlState = this.ngrxFormControlState;
    }
    if (this.getConfig()) {
      this.applyConfig(this.getConfig());
    }
  }

  @Input() ngrxEasyFieldConfigFeatureName = this.activatedRoute.snapshot.data.featureName;
  formIdentifier = this.activatedRoute.snapshot.data.formIdentifier;

  destroy$ = new EventEmitter<void>();
  config$ = this.store$.select(formBaseSelectors.getFormBaseConfigurationItemsState, { featureName: this.ngrxEasyFieldConfigFeatureName });
  updateConfigurationItemsEvent$ = new EventEmitter<void>();
  updateConfigurationItems$ = this.updateConfigurationItemsEvent$.pipe(
    withLatestFrom(this.config$),
    map(([_a, v1]) => v1),
  );

  @Output() updateConfig = new EventEmitter();
  /**
   * partially implemented! (currently only for matCheckbox in fileUpload)
   */
  @Output() updatePlaceholder = new EventEmitter<string>();
  @Output() updateDefaultValue = new EventEmitter<any>();
  @Output() updateEnabled = new EventEmitter<boolean>();
  @Output() updateMandatory = new EventEmitter<boolean>();
  @Output() updateVisible = new EventEmitter<boolean>();

  selectedLanguage$ = this.store$.select(getI18nSelectedLanguage);
  selectedLanguage: string;
  configurationItems: ConfigurationItems;
  element: MatInput
    | MatSelect
    | CurrencyInputComponent
    | FileUploadComponent
    | ApproverListComponent
    | UserSearchInputComponent
    | FormSapAutocompleteInputComponent
    | FormFieldCheckboxComponent;

  /**
   * Wir brauchen den State bei Komponenten die keine [ngrxFormControlState]-Direktive haben auch um den State richtig zu deaktivieren
   * @private
   */
  @Input()
  get controlState(): AbstractControlState<FormControlValueTypes | any[]> {
    return this.ngrxFormControlState;
  }

  set controlState(value: AbstractControlState<FormControlValueTypes | any[]>) {
    this.ngrxFormControlState = value;
  }

  /**
   * Wir borgen uns die [ngrxFormControlState]-Direktive um den State richtig zu deaktivieren
   * @private
   */
  private _ngrxFormControlState: AbstractControlState<FormControlValueTypes | any[]>;

  @Input()
  get ngrxFormControlState(): AbstractControlState<FormControlValueTypes | any[]> {
    if (this._ngrxFormControlState) {
      return this._ngrxFormControlState;
    }
    if (this.fileUpload) {
      return this.fileUpload.ngrxFormControlState;
    }
    if (this.checkBox) {
      return this.checkBox.ngrxFormControlStateValue;
    }
  }

  set ngrxFormControlState(value: AbstractControlState<FormControlValueTypes | any[]>) {
    this._ngrxFormControlState = value;
    if (!this.appliedConfig) {
      this.calculateEnableState(this.getConfig());
    }

    if (
      this._ngrxFormControlState
      && this._ngrxFormControlState.userDefinedProperties['easyConfigName'] !== this.configName
      && this.configName
    ) {
      this.updateConfigurationItemsEvent$.emit();
    }
  }

  @Input() disabledOverride: boolean;

  currentUserIsAuthorizedApprover = false;

  currentUserIsAuthorizedInspector = false;

  appliedConfig = false;

  setNgrxEnabled(state: AbstractControlState<any>, enabled: boolean) {
    if (enabled) {
      if (!state.isEnabled) {
        this.store$.dispatch(new EnableAction(state.id));
      }
    } else {
      if (state.isEnabled) {
        this.store$.dispatch(new DisableAction(state.id));
      }
    }
  }

  setEnabled(enabled: boolean) {
    setTimeout(() => {
      this.updateEnabled.next(enabled);
    });
    const formControlState = this.ngrxFormControlState;
    if (this.element) {
      if (formControlState) {
        this.setNgrxEnabled(formControlState, !!enabled);
      }

      this.element.disabled = !enabled;

      if (!enabled && this.element.required) {
        this.element.required = false;
      }
    } else if (this.angularEditor) {
      if (formControlState) {
        this.setNgrxEnabled(formControlState, !!enabled);
      }
      this.angularEditor.config.editable = enabled;
    }
  }

  setMandatory(mandatory: boolean) {
    setTimeout(() => {
      this.updateMandatory.next(mandatory);
    });
    if (this.element && !this.element.disabled) {
      this.element.required = mandatory;
    }
  }

  setPlaceholder(placeholder: string) {
    setTimeout(() => {
      this.updatePlaceholder.next(placeholder);
    });
    if (this.element) {
      this.element.placeholder = placeholder;
      /* material error workaround
         mat-form-field does not update placeholder if the containing child-input changes placeholder="" attribute.
         Workaround: call ngOnChanges() on mat-input to trigger this.stateChanges.next();
       */
      if (this.input || this.currencyInput) {
        (this.input || this.currencyInput).ngOnChanges();
      }
    } else if (this.angularEditor) {
      this.angularEditor.placeholder = placeholder;
    }
  }

  setTextInputConfig(config: ConfigItemConfigurationDtoModel) {
    const castConfig = <ConfigItemTextInputConfigurationDtoModel>config;
    const whitelist = [
      backendClasses.textInputConfiguration,
      backendClasses.textareaInputConfiguration,
      backendClasses.listConfiguration,
    ];
    if (
      (!whitelist.includes(config['@class']))
      || !castConfig.predefinedValue || !this._ngrxFormControlState) {
      return;
    }

    const e = this.input || this.select;
    if (((e || this.angularEditor) && !this._ngrxFormControlState.value && (!e?.value && !this.angularEditor?.html))
      || (this.currencyInput && this.currencyInput.viewValue === undefined)
      || castConfig.enabled === false) {
      if (castConfig.attributeName === 'termsOfPayment') {
        this.store$.dispatch(new SetValueTraceableAction(this._ngrxFormControlState.id, box({
          id: castConfig.predefinedValue,
          description: '',
        })));
      } else {
        this.store$.dispatch(new SetValueTraceableAction(this._ngrxFormControlState.id, castConfig.predefinedValue));
      }
    }
  }

  setCheckBoxInputConfig(config: ConfigItemConfigurationDtoModel) {
    const castConfig = <ConfigItemCheckboxConfigurationDtoModel>config;
    if (config['@class'] !== backendClasses.checkboxConfiguration) {
      return;
    }

    setTimeout(() => {
      this.updateDefaultValue.next(castConfig.preChecked);
      this.updateVisible.next(castConfig.visible);
    });

    if (!castConfig.preChecked || !this._ngrxFormControlState) {
      return;
    }

    if ((this._ngrxFormControlState?.value === undefined) || castConfig.enabled === false) {
      this.store$.dispatch(new SetValueTraceableAction(this._ngrxFormControlState.id, castConfig.preChecked));
    }
  }

  setNumberInputConfig(config: ConfigItemConfigurationDtoModel) {
    const castConfig = <ConfigItemNumberConfigurationDtoModel>config;
    if (config['@class'] !== backendClasses.numberConfiguration
      || !castConfig.defaultValue || !this._ngrxFormControlState) {
      return;
    }

    if (
      (this.input && !this._ngrxFormControlState.value && !this.input.value)
      || (
        this.currencyInput && !this._ngrxFormControlState.value
        && (this.currencyInput.viewValue === undefined || this.currencyInput.viewValue === '')
      )
      || castConfig.enabled === false
    ) {
      this.store$.dispatch(new SetValueTraceableAction(this._ngrxFormControlState.id, castConfig.defaultValue));
    }
  }

  setPredefinedAccountingConfig(config: ConfigItemConfigurationDtoModel) {
    if (config['@class'] !== backendClasses.accountingConfiguration || !this._ngrxFormControlState) {
      return;
    }
    const castConfig = <ConfigItemAccountingConfigurationDtoModel>config;
    const elementHasValue = !!this.sapAutocompleteInput?.model?.value;
    const ngrxHasValue = !!this._ngrxFormControlState.value;
    if ((!ngrxHasValue && !elementHasValue && !!castConfig.predefinedValue) || castConfig.enabled === false) {
      this.store$.dispatch(new SetValueTraceableAction(
        this._ngrxFormControlState.id,
        box<SapInfoModel>({id: castConfig.predefinedValue, description: '', type: castConfig.accountingType}),
      ));
    }
  }

  setPredefinedLedgerAccountConfig(config: ConfigItemConfigurationDtoModel) {
    if (config['@class'] !== backendClasses.ledgerAccountConfiguration || !this._ngrxFormControlState) {
      return;
    }
    const castConfig = <ConfigItemLedgerAccountConfigurationDtoModel>config;
    switch (castConfig.type) {
      case ConfigLedgerAcountType.PREDEFINED: {
        this.store$.dispatch(new SetValueTraceableAction(
          this._ngrxFormControlState.id,
          box({id: castConfig.predefinedValue, description: '', type: 'UNKNOWN'}),
        ));
        break;
      }
      case ConfigLedgerAcountType.SELECTION:
      case ConfigLedgerAcountType.SAP_SEARCH:
      default: {
        const elementHasValue = !!this.select?.value || !!this.sapAutocompleteInput?.model?.value;
        const ngrxHasValue = !!this._ngrxFormControlState.value;
        if ((!ngrxHasValue && !elementHasValue && !!castConfig.predefinedValue) || castConfig.enabled === false) {
          this.store$.dispatch(new SetValueTraceableAction(
            this._ngrxFormControlState.id,
            box({id: castConfig.predefinedValue, description: '', type: 'UNKNOWN'}),
          ));
        }
        break;
      }
    }
  }

  setPredefinedDateRangeConfig(config: ConfigItemConfigurationDtoModel) {
    if (config.attributeName !== 'dateRange') {
      return;
    }
    const castConfig = <ConfigItemDaterangeConfigurationDtoModel>config;
    const e = this.momentInput || this.momentStringInput || this.momentTimeInput;
    let secondOffset;
    const controlId = this._ngrxFormControlState?.id || '';
    if (controlId.toLocaleLowerCase().indexOf('.start') >= 0) {
      secondOffset = castConfig.startPredefinedFromNow;
    } else if (controlId.toLocaleLowerCase().indexOf('.end') >= 0) {
      secondOffset = castConfig.endPredefinedFromNow;
    } else {
      return;
    }
    if (((!this._ngrxFormControlState.value && !e.value) || castConfig.enabled === false)
      && secondOffset !== undefined && secondOffset !== null) {
      const predefinedMoment = moment().add(secondOffset, 'second');
      this.store$.dispatch(new SetValueTraceableAction(this._ngrxFormControlState.id, predefinedMoment.toISOString(false)));
    }
  }

  ngOnInit(): void {
    this.element =
      this.input
      || this.checkBox
      || this.select
      || this.currencyInput
      || this.userSearchInput
      || this.userSearchInputComponent
      || this.sapAutocompleteInput
      || (!this.matCheckBox && this.fileUpload); /* verschachtelte Elemente enthalten auch die Eltern als @Host inject.
                                                    Darum würde bei matCheckBox hier fileUpload als this.element falsche properties
                                                    erhalten. So lange wir keine Checkbox nutzen, die
                                                    ein FileUpload enthält, sollten das ok sein. */
    if (!this.element && !this.angularEditor) {
      console.warn(`NgrxEasyFieldConfigDirective: element named ${this.configName} is ${this.element}`);
    }

    this.subscribeUntilDestroy(this.selectedLanguage$, l => {
      this.selectedLanguage = l;
      this.setConfigurationItems(this.configurationItems);
    });

    this.subscribeUntilDestroy(this.updateConfigurationItems$.pipe(
      startWith(this.configurationItems),
      filter((c) => !!c),
    ), (e) => {
      this.updateConfig.emit(e?.[this._configName]);
    });

    this.subscribeUntilDestroy(this.store$.select(
      formBaseSelectors.getFormBaseCurrentUserIsAuthorizedApprover,
      {featureName: this.ngrxEasyFieldConfigFeatureName},
    ), value => {
      this.currentUserIsAuthorizedApprover = value;
      this.calculateEnableState(this.getConfig());
    });

    this.subscribeUntilDestroy(this.store$.select(
      formBaseSelectors.getFormBaseCurrentUserIsAuthorizedInspector,
      {featureName: this.ngrxEasyFieldConfigFeatureName},
    ), value => {
      this.currentUserIsAuthorizedInspector = value;
      this.calculateEnableState(this.getConfig());
    });

    this.subscribeUntilDestroy(this.config$, c => {
      this.appliedConfig = false;
      this.setConfigurationItems(c);
    });

    this.subscribeUntilDestroy(this.updateConfigurationItems$, (config) => {
      if (this._ngrxFormControlState?.id && this.configName) {
        const newConfig = config[this.configName];
        if (JSON.stringify(this.currentConfig) !== JSON.stringify(newConfig)) {
          this.currentConfig = newConfig;
          this.store$.dispatch(new SetUserDefinedPropertyAction(this._ngrxFormControlState.id, 'easyConfig', newConfig));
        }
      }
    });
  }

  subscribeUntilDestroy<T, R>(o: Observable<T>, f: (arg: T) => R) {
    return o.pipe(takeUntil(this.destroy$)).subscribe(f);
  }

  getConfig(): ConfigItemConfigurationDtoModel {
    return this.configurationItems?.[this.configName];
  }

  setConfigurationItems(c: ConfigurationItems) {
    this.configurationItems = c;
    this.updateConfigurationItemsEvent$.emit();
    this.applyConfig(this.getConfig());
  }

  applyConfig(config: ConfigItemConfigurationDtoModel) {
    if (config && !this.appliedConfig) {
      this.calculateEnableState(config);
      this.setMandatory(config.mandatory);
      if (config[this.ngrxEasyFieldTranslationsKey]?.[this.selectedLanguage]) {
        this.setPlaceholder(config[this.ngrxEasyFieldTranslationsKey][this.selectedLanguage].text);
      }
      this.setTextInputConfig(config);
      this.setCheckBoxInputConfig(config);
      this.setNumberInputConfig(config);
      this.setFileUploadConfig(config);
      this.setMomentDateConfig(config);
      this.setPredefinedAccountingConfig(config);
      this.setPredefinedLedgerAccountConfig(config);
      this.setPredefinedDateRangeConfig(config);
      this.appliedConfig = true;
    }
  }

  private calculateEnableState(config: ConfigItemConfigurationDtoModel) {
    const confirmMode = this.activatedRoute.snapshot.data.confirmMode;
    if (config && (!this.activatedRoute.snapshot.data.isCateringOnly
      || (this.activatedRoute.snapshot.data.isCateringOnly && confirmMode))) {
      const approveMode = this.activatedRoute.snapshot.data.approveMode;
      const locked = this.activatedRoute.snapshot.data.locked;

      const isDisabled = confirmMode
        || locked
        || (!approveMode && !config.enabled)
        || (approveMode && !(
          (this.currentUserIsAuthorizedInspector && config?.changeableByInspector)
          || (this.currentUserIsAuthorizedApprover && config?.changeableByApprover)
        ));

      this.setEnabled(!isDisabled && !this.disabledOverride);
    }
  }

  private setFileUploadConfig(config: ConfigItemConfigurationDtoModel) {
    const castConfig = <ConfigItemFileUploadConfigurationDtoModel>config;

    if (config['@class'] !== backendClasses.fileUploadConfiguration || !this.fileUpload) {
      return;
    }

    this.fileUpload.availableCategories = castConfig.availableCategories ? castConfig.availableCategories.value : [];
    this.fileUpload.maxFileCount = castConfig.max;
    this.fileUpload.required = castConfig.mandatory || castConfig.requiredCategories.value.length > 0;
    this.fileUpload.acceptedType = fileUploadTypeModelToAcceptInfo[castConfig.uploadType];
  }

  ngOnDestroy(): void {
    this.destroy$.emit();
    this.destroy$.complete();
  }

  private setMomentDateConfig(config: ConfigItemConfigurationDtoModel) {
    const castConfig = <ConfigItemDateConfigurationDtoModel>config;
    if (this.momentInput) {
      this.momentInput.min = castConfig.minDateCurrentDate ? moment() : moment(castConfig.minDate, 'YYYY-MM-DD');
      this.momentInput.max = castConfig.maxDateCurrentDate ? moment() : moment(castConfig.maxDate, 'YYYY-MM-DD');
      this.momentInput.dateFilter = (date: moment.Moment) => castConfig.weekendsActive || date?.isoWeekday() < 6;
      if (castConfig.predefineWithCurrentDate && !this._ngrxFormControlState.value) {
        this.store$.dispatch(new SetValueTraceableAction(this._ngrxFormControlState.id, moment().toISOString(false)));
      }
    }

  }
}
