<div>
  <mat-sidenav-container class="sidenav-background">
    <mat-sidenav #sidenav mode="over" class="app-sidenav">
      <mat-toolbar color="primary">
        <span class="toolbar-filler"></span>
        <button
          (click)="sidenav.toggle()"
          [hidden]="!sidenav.opened"
          class="md-icon-button sidenav-toggle-button"
          mat-icon-button
        >
          <mat-icon svgIcon="innogy:close"></mat-icon>
        </button>
      </mat-toolbar>
      <mat-nav-list>
        <ng-container *ngFor="let item of items()">
          <a
            *ngIf="item.show"
            mat-list-item
            [routerLink]="item.link"
            (click)="sidenav.toggle()"
          >
            <lib-common-page-navbar-item src="{{ item.link }}">
              <mat-icon [svgIcon]="item.icon"></mat-icon>
              {{item.name | i18n | async }}
            </lib-common-page-navbar-item>
          </a>
        </ng-container>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
