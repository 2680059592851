import { backendClasses } from './backend-classes.model';
import { ConfigItemConfigurationDtoModel } from './config-item-configuration-dto.model';

export class ConfigItemListConfigurationDtoModel extends ConfigItemConfigurationDtoModel {
  static readonly CLASSNAME: string = backendClasses.listConfiguration;
  static TYPENAME = 'typeList';
  '@class' = ConfigItemListConfigurationDtoModel.CLASSNAME;
  $$typeName = ConfigItemListConfigurationDtoModel.TYPENAME;
  public values: ConfigItemListItemConfigurationDtoModel[] = [];
  public predefinedValue?: string = undefined;
}

export class ConfigItemListItemConfigurationDtoModel extends ConfigItemConfigurationDtoModel {
  static readonly CLASSNAME: string = backendClasses.listItemConfiguration;
  static TYPENAME = 'typeListItem';
  '@class' = ConfigItemListItemConfigurationDtoModel.CLASSNAME;
  $$typeName = ConfigItemListItemConfigurationDtoModel.TYPENAME;
}
