import { Actions, FormGroupState, isNgrxFormsAction, updateGroup, validate } from 'ngrx-forms';
import { CompanyDataDtoModel } from '../../../core-lib/models/company-data-dto.model';
import { SetValueTraceableAction } from '../../../core-lib/models/set-value-traceable-action';
import { UserDataFormModel } from '../../../core-lib/models/user-data-form.model';
import { reduceForm, reduceForms } from '../../../core-lib/utils/reducer-utils';
import { ibanValidator } from '../../../forms-lib/validators/iban-validator';
import { AccountDeputiesFormModel } from '../../models/account-deputies-form.model';
import { NetRegionModel } from '../../models/net-region.model';
import {
  CoreAccountFormActionLoadedUserData,
  CoreAccountFormActionSaveSuccess,
  coreAccountFormNetRegionsLoaded,
} from '../actions/core-account.actions';
import { CoreCompanyFormActionLoadCompany } from '../actions/core-company-forms.actions';
import { CoreActionInit, CoreActions } from '../actions/core.actions';
import { accountDeputiesReducer, initialAccountDeputiesState } from './account-deputies.reducer';
import { initialAccountState } from './account.store';
import { initialCompanyFormState } from './company-form.store';
import { EditorFormStore, initialEditorFormState } from './editor-form.store';
import { easyFormsOcrReducer, initialOcrFormState, OcrFormModel, ocrFormValidator } from './ocr.store';

export interface FormsState {
  editorForm: FormGroupState<EditorFormStore>;
  accountForm: FormGroupState<UserDataFormModel>;
  accountDeputiesForm: FormGroupState<AccountDeputiesFormModel>;
  companyForm: FormGroupState<CompanyDataDtoModel>;
  netRegions: NetRegionModel<any>[];
  ocrForm: FormGroupState<OcrFormModel>;
}

const initialFormsState: FormsState = {
  editorForm: initialEditorFormState,
  accountForm: initialAccountState,
  accountDeputiesForm: initialAccountDeputiesState,
  companyForm: initialCompanyFormState,
  ocrForm: initialOcrFormState,
  netRegions: [],
};

const userAccountValidator = updateGroup({
  iban: validate(ibanValidator),
});

export function formsReducer(state = initialFormsState, action: CoreActions | Actions<any>): FormsState {
  let newState = state;
  newState = {
    ...newState,
    accountDeputiesForm: accountDeputiesReducer(newState.accountDeputiesForm, <Actions<any>>action),
    ocrForm: easyFormsOcrReducer(newState.ocrForm, <Actions<any>>action),
  };

  if (isNgrxFormsAction(<Actions<any>>action)) {
    newState = reduceForms(newState, [
      'editorForm',
      'companyForm',
    ], action);
    newState = reduceForm(newState, 'accountForm', action, userAccountValidator);
    newState = reduceForm(newState, 'ocrForm', action, ocrFormValidator);
    return newState;
  } else {
    switch (action.type) {
      case CoreActionInit.TYPE:
        return newState;
      case CoreCompanyFormActionLoadCompany.TYPE:
        const companyFormId = newState.companyForm.id;
        const company = action.company || initialCompanyFormState.value;
        return {
          ...newState,
          ...reduceForm(newState, 'companyForm', new SetValueTraceableAction<CompanyDataDtoModel>(companyFormId, (company))),
        };
      case CoreAccountFormActionLoadedUserData.TYPE:
      case CoreAccountFormActionSaveSuccess.TYPE:
        const accountFormId = newState.accountForm.id;
        const a = <CoreAccountFormActionLoadedUserData | CoreAccountFormActionSaveSuccess>action;
        return {
          ...newState,
          ...reduceForm(newState, 'accountForm', new SetValueTraceableAction<UserDataFormModel>(accountFormId, ({
            ...newState.accountForm.value,
            ...a.userData,
          })), userAccountValidator),
        };
      case coreAccountFormNetRegionsLoaded.type:
        return {
          ...newState,
          netRegions: action.data,
        };
      default:
        return newState;
    }
  }
}
