import { EntityState } from '@ngrx/entity';
import { createFormGroupState } from 'ngrx-forms';
import { ConfigDtoModel, ConfigurationItems } from '../../../core-lib/models/config-dto.model';

export class EasyFormConfigsAdditionalState<T extends ConfigurationItems = ConfigurationItems> {
  selected = createFormGroupState<ConfigDtoModel<T>>('selected', {
    id: undefined,
    company: undefined,
    configurationItems: <T>{},
    form: undefined,
    parent: undefined,
  });
}

export interface EasyFormConfigsState extends EntityState<ConfigDtoModel<any>>, EasyFormConfigsAdditionalState {
}
