<div class="btn-save-table">
  <button
    class="btn-margin"
    mat-raised-button
    color="primary"
    (click)="saveTableConfig()"
    *ngIf="saveButtonVisible"
  >{{'save' | i18n | async}}</button>

  <button
    mat-raised-button
    class="btn-margin"
    color="primary"
    (click)="removeFilterTableConfig()"
    *ngIf="filterButtonVisible"
  >
    {{'clearFilters' | i18n | async}}
    <mat-icon
      class="svg-icon-close"
      svgIcon="innogy:close"
    ></mat-icon>
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="exportTable()"
  >
    {{'exportWithFilter' | i18n | async}}
    <mat-icon
      class="svg-icon-close"
      svgIcon="innogy:c-arrow-down-02"
    ></mat-icon>
  </button>
</div>

<button mat-icon-button [matMenuTriggerFor]="menu" class="column-menu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <div fxLayout="row" *ngFor="let column of columns$ | async; trackBy: trackById" style="padding: 10px 20px">
    <mat-checkbox
      [checked]="column.visible"
      (change)="toggleColumnVisibility($event, column)"
    >
      {{column.columnName | i18n | async}}
    </mat-checkbox>
  </div>
</mat-menu>

<ngx-datatable
  #table
  class="material striped"
  [rows]="(content$ | async)"
  [columnMode]="columnMode"
  headerHeight="50"
  footerHeight="50"
  rowHeight="'auto'"
  summaryHeight="'auto'"
  summaryRow="true"
  externalPaging="true"
  externalSorting=true
  [loadingIndicator]="isLoading$ | async"
  [count]="(totalElements$ | async)"
  [offset]="(number$ | async)"
  [limit]="(size$ | async)"
  (page)='triggerPaging($event)'
  (sort)="triggerSorting($event)"
  (reorder)="saveButtonVisible = true;reorder($event)"
  (resize)="saveButtonVisible = true;resize($event)"
  [messages]="messages"
>
  <ng-container *ngFor="let column of columns$ | async; trackBy: trackById">
    <ngx-datatable-column
      *ngIf="column.visible"
      [name]="column.columnName | i18n | async"
      [summaryTemplate]="column.summaryTemplate"
      [cellTemplate]="column.cellTemplate"
      [prop]="column.prop"
      [width]="column.width"
    >
    </ngx-datatable-column>
  </ng-container>
</ngx-datatable>

<!-- Templates -->
<ng-template
  #filterTemplate
  let-column="column"
>
  <div fxLayout="row">
    <div fxFlex="100%">
      <mat-form-field class="fillwidth">
        <!-- ngModel ist nicht strictActionImmutability kompatibel-->
        <input
          matInput
          [(ngModel)]="sortFilterPageConfig.filter[column.prop]"
          (ngModelChange)="triggerFilter()"
        />
      </mat-form-field>
    </div>
  </div>
</ng-template>

<ng-template
  #stateFilterTemplate
  let-column="column"
>
  <mat-form-field class="fillwidth">
    <!-- ngModel ist nicht strictActionImmutability kompatibel-->
    <mat-select
      [(ngModel)]="sortFilterPageConfig.filter[column.prop]"
      (ngModelChange)="triggerFilter()"
    >
      <mat-option value=""></mat-option>
      <mat-option
        *ngFor="let state of states"
        [value]="state"
      >{{state  | i18n | async}}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template
  #dateFilterTemplate
  let-column="column"
>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start baseline"
  >
    <div fxFlex="1 0 100px">
      <mat-form-field class="fillwidth">
        <mat-label>{{'from' | i18n | async}}</mat-label>
        <!-- ngModel ist nicht strictActionImmutability kompatibel-->
        <input
          matInput
          [matDatepicker]="picker1"
          [(ngModel)]="sortFilterPageConfig.filter[column.prop+'From']"
          (ngModelChange)="triggerFilter()"
          [ngModelOptions]="{updateOn: 'blur'}"
          [max]="sortFilterPageConfig.filter[column.prop+'Until']"
        >
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        >
        </mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>
    <span>&nbsp;-&nbsp;</span>
    <div fxFlex="1 0 100px">
      <mat-form-field class="fillwidth">
        <mat-label>{{'until' | i18n | async}}</mat-label>
        <!-- ngModel ist nicht strictActionImmutability kompatibel-->
        <input
          matInput
          [matDatepicker]="picker2"
          [(ngModel)]="sortFilterPageConfig.filter[column.prop+'Until']"
          (ngModelChange)="triggerFilter()"
          [ngModelOptions]="{updateOn: 'blur'}"
          [min]="sortFilterPageConfig.filter[column.prop+'From']"
        >
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</ng-template>

<ng-template
  #deliveryFilterTemplate
  let-column="column"
>
  <mat-form-field class="fillwidth">
    <!-- ngModel ist nicht strictActionImmutability kompatibel-->
    <mat-select
      [(ngModel)]="sortFilterPageConfig.filter[column.prop]"
      (ngModelChange)="triggerFilter()"
    >
      <mat-option value=""></mat-option>
      <mat-option value="true">{{'collection' | i18n | async}}</mat-option>
      <mat-option value="false">{{'delivery' | i18n | async}}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template
  #dateCellTemplate
  let-row="row"
  let-column="column"
>
  <div>
    {{row[column.prop] | date :'dd.MM.yyyy'}}
  </div>
  <div>
    {{row[column.prop] | date :'HH:mm'}}
  </div>
</ng-template>

<ng-template
  #i18nTextCellTemplate
  let-row="row"
  let-column="column"
>
  {{row[column.prop] | i18n | async}}
</ng-template>
<ng-template
  #deliveryCellTemplate
  let-row="row"
  let-column="column"
>
  {{(row[column.prop] ? 'collection' : 'delivery') | i18n | async}}
</ng-template>

<ng-template
  #roomCellTemplate
  let-row="row"
  let-column="column"
>
  {{row.roomName ? row.roomName : row.alternativeRoomName}}
</ng-template>

<ng-template
  #actionsCellTemplate
  let-row="row"
  let-column="column"
>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start"
    fxLayoutGap="10px grid"
  >
    <div
      *ngIf="row.state !== 'CATERING_APPROVAL'"
      fxFlex.gt-md="50%"
      fxFlex.lt-lg="100%"
    >
      <button
        type="button"
        mat-icon-button
        class="proposal-check icon-padding"
        [title]="'view' | i18n | async"
        [routerLink]="[
          '/catering',
          row.category,
          getFormIdentifier(row.formIdentifier, row.state),
          'confirm',
          row[column.prop]
        ]"
      >
        <mat-icon svgIcon="innogy:magnifier"></mat-icon>
      </button>
    </div>
    <ng-container
      *ngIf="(isCaterer$ | async) && row.state === 'CATERING_IN_PROGRESS' || row.state === 'CATERING_COMPLAINED'"
    >
      <div
        fxFlex.gt-md="50%"
        fxFlex.lt-lg="100%"
      >
        <button
          type="button"
          mat-icon-button
          class="proposal-check icon-padding"
          [title]="'edit' | i18n | async"
          [routerLink]="['/catering/' + row.category + '/' + getFormIdentifier(row.formIdentifier, row.state) + '/' +
          row[column.prop]]"
        >
          <mat-icon svgIcon="innogy:pen"></mat-icon>
        </button>
      </div>
      <div
        fxFlex.gt-md="50%"
        fxFlex.lt-lg="100%"
      >
        <button
          type="button"
          mat-icon-button
          [title]="'cancelCatering' | i18n | async"
          (click)="cateringCancel(row[column.prop])"
        >
          <mat-icon svgIcon="innogy:close"></mat-icon>
        </button>
      </div>
    </ng-container>
    <div
      *ngIf="row.state === 'CATERING_APPROVAL'"
      fxFlex.gt-md="50%"
      fxFlex.lt-lg="100%"
    >
      <button
        type="button"
        mat-icon-button
        class="proposal-check icon-padding"
        [title]="'check' | i18n | async"
        [routerLink]="['/catering/' + row.category + '/' + row.formIdentifier + '/approve/confirm/' + row[column.prop]]"
      >
        <mat-icon svgIcon="innogy:magnifier"></mat-icon>
      </button>
    </div>
    <div
      fxFlex.gt-md="50%"
      fxFlex.lt-lg="100%"
      *ngIf="row.comment"
    >
      <button
        type="button"
        [title]="'showComment' | i18n | async"
        class="proposal-check icon-padding"
        mat-icon-button
        (click)="openDialogComment(row.comment)"
      >
        <mat-icon svgIcon="innogy:info"></mat-icon>
      </button>
    </div>
  </div>

  <div
    fxLayout="row wrap"
    fxLayoutAlign="start"
    fxLayoutGap="10px grid"
    *ngIf="(type === 'PENDING' || type === 'DEPUTY') && row.state === 'CATERING_APPROVAL'"
  >
    <div
      fxFlex.gt-md="50%"
      fxFlex.lt-lg="100%"
    >
      <button
        type="button"
        mat-icon-button
        class="proposal-approve icon-padding"
        [title]="'approve' | i18n | async"
        (click)="openDialogApprove(row[column.prop])"
      >
        <mat-icon svgIcon="innogy:c-check"></mat-icon>
      </button>
    </div>
    <div
      fxFlex.gt-md="50%"
      fxFlex.lt-lg="100%"
    >
      <button
        type="button"
        mat-icon-button
        class="proposal-reject icon-padding"
        [title]="'reject' | i18n | async"
        (click)="openDialogReject(row[column.prop])"
      >
        <mat-icon svgIcon="innogy:c-cross"></mat-icon>
      </button>
    </div>
  </div>
</ng-template>

