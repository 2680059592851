import { ListItemConfigurationDtoModel } from '../../forms/all-forms/models/list-item-configuration-dto.model';

export class Article {
  id: string = undefined;
  name: string = undefined;
  unit: string = undefined;
  price: number = undefined;
  taxRate: ListItemConfigurationDtoModel = new ListItemConfigurationDtoModel();
  quotaEqualsActual: boolean = undefined;
  approvalNeeded: boolean = undefined;
  addAutomatically: boolean = undefined;
  validFrom: Date = new Date();
  validUntil: Date = new Date();

  constructor(public locationInfo?: string) {
  }
}
