import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControlState } from 'ngrx-forms';
import { map } from 'rxjs/operators';
import { InputLangStrings } from '../../../core-lib/components/input-lang-string/input-lang-string.component';
import { ConfigListModel } from '../../../core/models/config-list.model';
import { CoreFeatureState, getI18nSelectedLanguage } from '../../../core/ngrx/reducers/core.store';
import { PropType } from '../../utils/prop-type';
import { SelectSearchComponent } from '../select-search/select-search.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-form-entity-id-selector',
  templateUrl: './form-entity-id-selector.component.html',
  styleUrls: ['./form-entity-id-selector.component.scss'],
})
export class FormEntityIdSelectorComponent {
  @Input() ngrxSelectedId: FormControlState<string>;
  @Input() placeholderI18nKey: string;
  @Input() options: { id: string, labels?: InputLangStrings }[];
  selectedLanguage$ = this.store$.select(getI18nSelectedLanguage);
  @Input() flex: string;
  @Input() configName: string;
  @Input() getDisplayText: (e: any) => any;
  @Input() required: boolean;

  constructor(private store$: Store<CoreFeatureState>) {
  }

  /**
   * setzt Displaytext jedes Elements (aus options) anhand der selectedLanguage$
   */
  getDisplayTextFn$ = this.selectedLanguage$.pipe(
    map(lang => (x: ConfigListModel) => (
      x && x.labels && x.labels[lang] && x.labels[lang].text
    ) || x.key),
  );

}
