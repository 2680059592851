import { Action } from '@ngrx/store';
import { CompanyDataDtoModel } from '../../../core-lib/models/company-data-dto.model';
import { ApiErrorAction, ApiSuccessAction } from './core.actions';

export class CoreCompanyFormActionLoadedCompanies implements Action {
  static readonly TYPE = 'core/companyForm/LoadedCompanies';
  readonly type = CoreCompanyFormActionLoadedCompanies.TYPE;

  constructor(public companies: CompanyDataDtoModel[]) {
  }
}

export class CoreCompanyFormActionLoadCompany implements Action {
  static readonly TYPE = 'core/companyForm/LoadedData';
  readonly type = CoreCompanyFormActionLoadCompany.TYPE;

  constructor(public company: CompanyDataDtoModel) {
  }
}

export class CoreCompanyFormActionSave implements Action {
  static readonly TYPE = 'core/companyForm/Save';
  readonly type = CoreCompanyFormActionSave.TYPE;
}

export class CoreCompanyFormActionDelete implements Action {
  static readonly TYPE = 'core/companyForm/Delete';
  readonly type = CoreCompanyFormActionDelete.TYPE;

  constructor(public company: CompanyDataDtoModel) {
  }
}

export class CoreCompanyFormActionSaveSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/core/companyForm/SaveSuccess';
  readonly type = CoreCompanyFormActionSaveSuccess.TYPE;
  messageKey = 'coreCompanyFormActionSaveSuccess';
  snackBarDuration = 5000;

  constructor(public company: CompanyDataDtoModel) {
    super();
  }
}

export class CoreCompanyFormActionSaveError extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/core/companyForm/SaveError';
  readonly type = CoreCompanyFormActionSaveError.TYPE;
}

export type CoreCompanyFormsActions =
  CoreCompanyFormActionLoadedCompanies |
  CoreCompanyFormActionSaveSuccess |
  CoreCompanyFormActionLoadCompany |
  CoreCompanyFormActionDelete |
  CoreCompanyFormActionSaveError;
