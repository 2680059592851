import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Moment } from 'moment';
import { AddArrayControlAction, FormGroupState, NgrxValueConverter, RemoveArrayControlAction } from 'ngrx-forms';
import { map, takeUntil } from 'rxjs/operators';
import { Article } from '../../models/article.model';
import { CoreConfigLocationArticleSave } from '../../ngrx/actions/core-config.actions';
import { getConfigArticleFormState, getConfigArticleFormControls, getConfigLocationNameById } from '../../ngrx/reducers/core.store';

@Component({
  selector: 'lib-common-route-config-article-list',
  templateUrl: './route-config-article-list.component.html',
  styleUrls: ['./route-config-article-list.component.scss'],
})
export class RouteConfigArticleListComponent implements OnInit, OnDestroy {

  @Input() readonly: boolean;

  @Output() changeReadonly = new EventEmitter<boolean>();

  articleArrayState$ = this.store$.select(getConfigArticleFormControls);
  locationName$ = this.store$.select(getConfigLocationNameById, {locationId: this.route.snapshot.params.id});
  isFormInvalid$ = this.store$.select(getConfigArticleFormState).pipe(
    map(form => form.isInvalid),
  );

  private arrayId: string;
  private componentDestroyed$ = new EventEmitter();
  ngrxDateValueConverter = <NgrxValueConverter<Moment, string>>{
    convertViewToStateValue: (value: moment.Moment): string => !!value ? value.format('YYYY-MM-DD') : '',
    convertStateToViewValue: (value: string): moment.Moment => !!value ? moment(value) : undefined,
  };

  getControlId = (i: number, t: FormGroupState<Article>) => t.id;


  constructor(private store$: Store<any>, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.store$.select(getConfigArticleFormState).pipe(takeUntil(this.componentDestroyed$)).subscribe(state => this.arrayId = state.id);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }


  onChangeReadonly(event) {
    this.changeReadonly.emit(event.checked);
  }

  removeArticle(index: number) {
    this.store$.dispatch(new RemoveArrayControlAction(this.arrayId, index));
  }

  addArticle() {
    this.store$.dispatch(new AddArrayControlAction(this.arrayId, new Article(this.route.snapshot.params.id)));
  }

  save() {
    this.store$.dispatch(new CoreConfigLocationArticleSave(this.route.snapshot.params.id));
  }
}
