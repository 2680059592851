import { backendClasses } from './backend-classes.model';
import { ConfigItemConfigurationDtoModel } from './config-item-configuration-dto.model';

export class ConfigItemTextareaInputConfigurationDtoModel extends ConfigItemConfigurationDtoModel {
  static readonly CLASSNAME: string = backendClasses.textareaInputConfiguration;
  static TYPENAME = 'typeTextarea';
  '@class' = ConfigItemTextareaInputConfigurationDtoModel.CLASSNAME;
  $$typeName = ConfigItemTextareaInputConfigurationDtoModel.TYPENAME;

  regex = '.*';
  maxLength = 0;
  predefinedValue: string = undefined;
}
