import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserRole } from '../../../core/models/user-roles.model';
import { AppConfigService } from '../../../core/services/app-config.service';
import { FormCategoryElementModel } from '../../models/form-category-element.model';
import { FormCategoryTreeElementModel } from '../../models/form-category-tree.model';
import { LinkDtoModel } from '../../models/link-dto.model';
import { ProposalHeadModel } from '../../models/proposal-head.model';

@Component({
  selector: 'lib-common-category-tree-element',
  templateUrl: './category-tree-element.component.html',
  styleUrls: ['./category-tree-element.component.scss']
})
export class CategoryTreeElementComponent {
  constructor(
    public appConfigService: AppConfigService,
  ) {
  }

  @Input() childForms: FormCategoryElementModel[] = [];
  @Input() childTrees: FormCategoryTreeElementModel[] = [];
  @Input() childLinks: LinkDtoModel[] = [];
  @Input() getRouterLink: (string) => string[];
  @Input() userRoles: UserRole[] = [];

  @Output() addFavorite = new EventEmitter<FormCategoryElementModel>();
  @Output() deleteFavorite = new EventEmitter<FormCategoryElementModel>();
  @Output() addTemplate = new EventEmitter<FormCategoryElementModel>();
  @Output() deleteTemplate = new EventEmitter<{ form: FormCategoryElementModel, templateName: ProposalHeadModel }>();
  @Output() openInfoDialog = new EventEmitter<{ $event: Event, tree: FormCategoryTreeElementModel }>();
  @Output() ocrScan = new EventEmitter<FormCategoryElementModel>();
  @Output() ocrCam = new EventEmitter<FormCategoryElementModel>();
  @Output() openTemplateDialog = new EventEmitter<FormCategoryElementModel>();

  @Input() showInfoHint: (FormCategoryTreeElementModel) => boolean = () => false;

  getChildTrees(tree: FormCategoryTreeElementModel) {
    return Object.values(tree.childTrees);
  }

  hasNeededRole(neededRole: string): boolean {
    return !neededRole || !!this.userRoles.find(role => role.includes(UserRole.ADMIN) || role.valueOf() === neededRole);
  }
}
