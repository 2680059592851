<lib-common-page-sidebar
  [sidebarOpen]="sidebarOpen" (toggledSidebar)="sidebarOpen = $event"
  [showAdministration]="isAdmin$ | async"
  [showCatering]="isCaterer$ | async"
>
  <div class="main-wrapper">
    <div class="flex-wrapper" fxLayout="column" fxLayoutAlign="stretch">
      <lib-common-page-header
        fxFlex="0 0 auto"
      ></lib-common-page-header>
      <div fxFlex="1 0 auto">
        <lib-common-page-navbar
          (toggleSidebar)="sidebarOpen = $event"
          [showAdministration]="isAdmin$ | async"
          [showCatering]="isCaterer$ | async"
        ></lib-common-page-navbar>
        <lib-common-page-bread-crumbs-nav
          (toggleSidebar)="sidebarOpen = $event"
        ></lib-common-page-bread-crumbs-nav>
        <div class="main-content">
          <div class="main-content-wrapper">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
      <div
        fxFlex="0 0 auto"
      >
        <lib-common-page-footer
          [showVersion]="true"
          (renderedElement)="setFooterProps($event)"
        ></lib-common-page-footer>
      </div>
    </div>
  </div>
</lib-common-page-sidebar>
