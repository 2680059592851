import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControlState } from 'ngrx-forms';

import { facts } from './facts';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-select-fact',
  templateUrl: './select-fact.component.html',
  styleUrls: ['./select-fact.component.scss']
})
export class SelectFactComponent {

  @Input() factState: FormControlState<string>;

  @Input() required = false;

  facts = facts;

}
