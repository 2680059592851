<div
  class="chart"
  echarts
  [initOpts]="{locale: 'DE'}"
  [options]="options$ | async"
></div>
<div fxLayoutAlign="center center" [style.display]="!showChartTypeSelector ? 'none' : ''">
  <div fxFlexAlign="center">
    <mat-button-toggle-group #group="matButtonToggleGroup" [ngModel]="typeSource$.value" (ngModelChange)="typeSource$.next($event)">
      <mat-button-toggle
        value="pie"
        aria-label="Pie-Chart"
        *ngIf="shouldShowChartType('pie')"
      >
        <mat-icon>pie_chart</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        value="radar"
        aria-label="Radar-Chart"
        *ngIf="shouldShowChartType('radar')"
      >
        <mat-icon>radar</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        value="line"
        aria-label="Line-Chart"
        *ngIf="shouldShowChartType('line')"
      >
        <mat-icon>chart-bell-curve-cumulative</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
