import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControlState } from 'ngrx-forms';
import { RouteConfigFormPropertyComponent } from '../../../core/components/route-config-form-property/route-config-form-property.component';
import { CoreFeatureState } from '../../../core/ngrx/reducers/core.store';
import {
  ConfigItemLedgerAccountConfigurationDtoModel,
  ConfigLedgerAcountType,
} from '../../models/config-item-ledger-account-configuration-dto.model';
import { SetValueTraceableAction } from '../../models/set-value-traceable-action';
import { InputPropertyTypeComponent } from '../input-property-type/input-property-type.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-input-property-type-ledger-account',
  templateUrl: './input-property-type-ledger-account.component.html',
  styleUrls: ['./input-property-type-ledger-account.component.scss'],
})
export class InputPropertyTypeLedgerAccountComponent extends InputPropertyTypeComponent {

  typeName = ConfigItemLedgerAccountConfigurationDtoModel.TYPENAME;

  @Input() ngrxType: FormControlState<string>;
  @Input() ngrxPredefinedValue: FormControlState<string>;

  type: string;

  inputTypes = Object.values(ConfigLedgerAcountType).filter(value => isNaN(Number(value)));

  constructor(store$: Store<CoreFeatureState>, util: RouteConfigFormPropertyComponent) {
    super(store$, util);
  }

  internalReloadValues(lastConfigItemValue) {
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxType.id, lastConfigItemValue.type));
  }

}
