import { ConfigItemAccountingConfigurationDtoModel } from './config-item-accounting-configuration-dto.model';
import { ConfigItemArrangerConfigurationDtoModel } from './config-item-arranger-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from './config-item-arranger-list-configuration-dto.model';
import { ConfigItemCheckboxConfigurationDtoModel } from './config-item-checkbox-configuration-dto.model';
import { ConfigItemDateConfigurationDtoModel } from './config-item-date-configuration-dto.model';
import { ConfigItemDaterangeConfigurationDtoModel } from './config-item-daterange-configuration-dto.model';
import { ConfigItemFileUploadConfigurationDtoModel } from './config-item-file-upload-configuration-dto.model';
import { ConfigItemLedgerAccountConfigurationDtoModel } from './config-item-ledger-account-configuration-dto.model';
import { ConfigItemListConfigurationDtoModel, ConfigItemListItemConfigurationDtoModel } from './config-item-list-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from './config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from './config-item-text-input-configuration-dto.model';

export class ConfigListDtoModel extends ConfigItemListConfigurationDtoModel {
  constructor(
    attributeName: string,
    public values: ConfigItemType[] = [],
  ) {
    super(attributeName);
  }
}

export function getInitialConfigListDtoModel(
  name: string,
  valuesType?: typeof ConfigItemListConfigurationDtoModel,
): ConfigListDtoModel {
  return new ConfigListDtoModel(name, valuesType ? [new valuesType(name)] : undefined);
}

export type ConfigItemType =
  ConfigItemListConfigurationDtoModel
  | ConfigItemListItemConfigurationDtoModel
  | ConfigItemCheckboxConfigurationDtoModel
  | ConfigItemDateConfigurationDtoModel
  | ConfigItemDaterangeConfigurationDtoModel
  | ConfigItemNumberConfigurationDtoModel
  | ConfigItemFileUploadConfigurationDtoModel
  | ConfigItemTextInputConfigurationDtoModel
  | ConfigItemArrangerConfigurationDtoModel
  | ConfigItemArrangerListConfigurationDtoModel
  | ConfigItemAccountingConfigurationDtoModel
  | ConfigItemLedgerAccountConfigurationDtoModel;

export type ConfigItemUnionType =
  ConfigItemListConfigurationDtoModel
  & ConfigItemListItemConfigurationDtoModel
  & ConfigItemCheckboxConfigurationDtoModel
  & ConfigItemDateConfigurationDtoModel
  & ConfigItemDaterangeConfigurationDtoModel
  & ConfigItemNumberConfigurationDtoModel
  & ConfigItemFileUploadConfigurationDtoModel
  & ConfigItemTextInputConfigurationDtoModel
  & ConfigItemArrangerConfigurationDtoModel
  & ConfigItemArrangerListConfigurationDtoModel
  & ConfigItemAccountingConfigurationDtoModel
  & ConfigItemLedgerAccountConfigurationDtoModel;

export const ConfigItemTypeArray = [
  ConfigItemListConfigurationDtoModel,
  ConfigItemListItemConfigurationDtoModel,
  ConfigItemCheckboxConfigurationDtoModel,
  ConfigItemDateConfigurationDtoModel,
  ConfigItemDaterangeConfigurationDtoModel,
  ConfigItemNumberConfigurationDtoModel,
  ConfigItemFileUploadConfigurationDtoModel,
  ConfigItemTextInputConfigurationDtoModel,
  ConfigItemArrangerConfigurationDtoModel,
  ConfigItemArrangerListConfigurationDtoModel,
  ConfigItemAccountingConfigurationDtoModel,
  ConfigItemLedgerAccountConfigurationDtoModel,
];

export function getConfigItemTypeByClass(className: string) {
  return ConfigItemTypeArray.find(c => c.CLASSNAME === className);
}
