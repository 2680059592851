import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormControlState, FormGroupState } from 'ngrx-forms';
import { SetValueTraceableAction } from '../../../core-lib/models/set-value-traceable-action';
import { ConfigListModel } from '../../models/config-list.model';
import { CoreConfigLedgerAccountsSave, DeleteConfigItemAction } from '../../ngrx/actions/core-config.actions';
import { initialConfigListValue, LEDGERACCOUNTS_FORM_ID } from '../../ngrx/reducers/config.reducer';
import {
  CoreFeatureState,
  getConfigCategoriesState,
  getConfigLedgerAccountsControlsArrayState,
  getConfigLedgerAccountsState,
} from '../../ngrx/reducers/core.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-route-config-ledger-account-list',
  templateUrl: './route-config-ledger-account-list.component.html',
  styleUrls: ['./route-config-ledger-account-list.component.scss'],
})
export class RouteConfigLedgerAccountListComponent {
  currentLedgerAccountList = undefined;

  ledgerAccountList$ = this.store$.select(getConfigLedgerAccountsControlsArrayState);
  availableCategories$ = this.store$.select(getConfigCategoriesState);
  ledgerAccountListForm$ = this.store$.select(getConfigLedgerAccountsState);
  formValid$ = this.ledgerAccountListForm$.pipe(
    select('isValid'),
  );

  constructor(private store$: Store<CoreFeatureState>) {
    this.ledgerAccountList$.subscribe(ledgerAccountList => this.currentLedgerAccountList = ledgerAccountList);
  }

  getControlId = (t: FormGroupState<ConfigListModel>) => t.id;

  resetLangLabel(ledgerAccount, $event: FormControlState<string>) {
    this.store$.dispatch(new SetValueTraceableAction($event.id, ledgerAccount.value.value));
  }

  addLedgerAccount() {
    if (this.currentLedgerAccountList) {
      const newValue = [
        ...this.currentLedgerAccountList.map(x => x.value),
        {...initialConfigListValue, categories: {}},
      ];
      this.store$.dispatch(new SetValueTraceableAction(LEDGERACCOUNTS_FORM_ID, newValue));
    }
  }

  removeLedgerAccount(ledgerAccount) {
    const newValue = [
      ...this.currentLedgerAccountList.filter(x => this.getControlId(x) !== this.getControlId(ledgerAccount)).map(x => x.value),
    ];
    this.store$.dispatch(new SetValueTraceableAction(LEDGERACCOUNTS_FORM_ID, newValue));
    this.store$.dispatch(new DeleteConfigItemAction(ledgerAccount.value.id));
  }

  saveLedgerAccount() {
    this.store$.dispatch(new CoreConfigLedgerAccountsSave());
  }
}
