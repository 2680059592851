import { ConfigItemCheckboxConfigurationDtoModel } from '../../../core-lib/models/config-item-checkbox-configuration-dto.model';
import { ConfigItemConfigurationCategoryModel } from '../../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemDateConfigurationDtoModel } from '../../../core-lib/models/config-item-date-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../../../core-lib/models/config-item-text-input-configuration-dto.model';
import { EasyFormAF16ConfigItems } from '../../form-af16/models/form-af16-config.models';

export const EasyFormAF14Steps = {
  step1: <ConfigItemConfigurationCategoryModel>{
    id: 'step1',
    displayName: 'Gesellschaft',
  },
  step2: <ConfigItemConfigurationCategoryModel>{
    id: 'step2',
    displayName: 'Zahlungsempfänger',
  },
  step3: <ConfigItemConfigurationCategoryModel>{
    id: 'step3',
    displayName: 'Rechnungsangaben',
  },
};

export class EasyFormAF14ConfigItems extends EasyFormAF16ConfigItems {
  // recurringAccountingDto
  recurringAccounting = new ConfigItemCheckboxConfigurationDtoModel('recurringAccounting').withCategory(EasyFormAF14Steps.step3);
  billingInterval = new ConfigItemTextInputConfigurationDtoModel('billingInterval').withCategory(EasyFormAF14Steps.step3);
  billingDate = new ConfigItemTextInputConfigurationDtoModel('billingDate').withCategory(EasyFormAF14Steps.step3);
  billingUntil = new ConfigItemDateConfigurationDtoModel('billingUntil').withCategory(EasyFormAF14Steps.step3);
  leaflet = new ConfigItemCheckboxConfigurationDtoModel('leaflet').withCategory(EasyFormAF14Steps.step3);
}
