import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { merge } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { rxRouteRequestTargetMatches } from '../../../core-lib/utils/reducer-utils';
import { SessionSpinnerClose, SessionSpinnerOpen } from '../../../session/actions/session.actions';
import { resetFormAction } from '../actions/core.actions';
import { RouterActionNavigate, RouterActionTypes } from '../actions/router.actions';

@Injectable()
export class RouterEffects {
  private SPINNER_LAZY_LOADING_KEY = 'lazyLoading';

  navigate$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActionTypes.RouterActionNavigate),
    filter((action: RouterActionNavigate) => !action.payload.params._dispatchedByRouterReducer),
    map((action: RouterActionNavigate) => action.payload),
    tap(({path, params}) => this.router.navigateByUrl(path, params)),
  ), {dispatch: false});

  navigateBack$ = createEffect(() => this.actions$.pipe(
    ofType('[Router] Back'),
    tap(() => this.location.back()),
  ), {dispatch: false});

  navigateForward$ = createEffect(() => this.actions$.pipe(
    ofType('[Router] Forward'),
    tap(() => this.location.forward()),
  ), {dispatch: false});

  resetFormsOnNavigation$ = createEffect(() => merge(
    this.actions$.pipe(rxRouteRequestTargetMatches((url) => (
      (url.endsWith('/') || url.endsWith('/forms') || url.endsWith('/list') || url.endsWith('/approvals') || url.endsWith('/catering'))
    ))),
    this.actions$.pipe(
      ofType(RouterActionTypes.RouterActionNavigate),
      filter((action) => this.activatedRoute.firstChild?.firstChild?.routeConfig.path === 'forms/:formsCategory'
        && !this.activatedRoute.firstChild?.firstChild?.firstChild,
      ),
    ),
  ).pipe(
    map(() => resetFormAction()),
  ));

  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location,
    private store$: Store<any>,
    private activatedRoute: ActivatedRoute,
  ) {
    this.listenToRouter();
  }

  private listenToRouter() {
    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd),
    ).subscribe((event: ActivationEnd) => this.store$.dispatch(
      new RouterActionNavigate({
        params: {
          ...event.snapshot.params,
          _dispatchedByRouterReducer: true,
        },
        data: {
          ...event.snapshot.data,
        },
        path: event.snapshot.routeConfig.path,
      }),
    ));
    this.router.events.pipe(
      filter(event => event instanceof RouteConfigLoadStart || event instanceof RouteConfigLoadEnd),
    ).subscribe((event: RouteConfigLoadStart | RouteConfigLoadEnd) => {
      if (event instanceof RouteConfigLoadStart) {
        this.store$.dispatch(new SessionSpinnerOpen(this.SPINNER_LAZY_LOADING_KEY));
      } else if (event instanceof RouteConfigLoadEnd) {
        setTimeout(() => {
          this.store$.dispatch(new SessionSpinnerClose(this.SPINNER_LAZY_LOADING_KEY));
        }, 200);
      }
    });
  }
}
