import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { BaseRouterStoreState, ROUTER_REQUEST, RouterRequestAction, RouterRequestPayload } from '@ngrx/router-store';
import { Action, FunctionWithParametersType, MemoizedSelector } from '@ngrx/store';
import { formArrayReducer, formGroupReducer, FormGroupState, SetAsyncErrorAction } from 'ngrx-forms';
import { Observable, of, OperatorFunction, pipe } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { ApiErrorAction } from '../../core/ngrx/actions/core.actions';
import { RouterActionNavigate, RouterActionTypes } from '../../core/ngrx/actions/router.actions';

/**
 * prevents exceptions á la "cannot access user of undefined"
 */

type Selector<InputType, ReturnType> = (MemoizedSelector<InputType, ReturnType> | ((n: InputType) => ReturnType));

export const ifDefined = <ReturnType, InputType>(
  statePartial: InputType,
  selector: Selector<InputType, ReturnType>, defaultValue?,
): ReturnType => {
  return statePartial ? selector(statePartial) : defaultValue;
};

export const ifDef = <ReturnType, InputType>(selector: Selector<InputType, ReturnType>, defaultValue?) =>
  (statePartial: InputType): ReturnType => ifDefined<ReturnType, InputType>(statePartial, selector, defaultValue);

// tslint:disable-next-line:no-console
export const rxLog = (...args) => <T>(s: Observable<T>): Observable<T> => tap<T>(console.debug.bind(undefined, ...args))(s);

const rxRouteGeneratePayload = (snapshot: ActivatedRouteSnapshot): RouterActionNavigate['payload'] => ({
  params: {
    ...snapshot.params,
    _dispatchedByRouterReducer: true,
  },
  data: {
    ...snapshot.data,
  },
  path: snapshot.routeConfig && snapshot.routeConfig.path,
});
const rxRouteFilterInternal = (
  payload: RouterActionNavigate['payload'],
  startsWithPath: string | string[] | ((payload: RouterActionNavigate['payload']) => boolean),
) => {
  if (typeof startsWithPath === 'string') {
    return payload.path.startsWith(startsWithPath);
  } else if (Array.isArray(startsWithPath)) {
    return startsWithPath.reduce((prev, curr) => prev || payload.path.startsWith(curr), false);
  } else if (typeof startsWithPath === 'function') {
    return startsWithPath(payload);
  }
  return false;
};

export const rxRoute = (startsWithPath: string | string[] | ((payload: RouterActionNavigate['payload']) => boolean)) => pipe(
  ofType(RouterActionTypes.RouterActionNavigate),
  map((action: RouterActionNavigate) => action.payload),
  filter((payload) => rxRouteFilterInternal(payload, startsWithPath)),
);

export const rxRouteFilter = (
  getRouteSnapshot: () => ActivatedRouteSnapshot,
  startsWithPath: string | string[] | ((payload: RouterActionNavigate['payload']) => boolean),
) => filter(() => {
  let snapshot = getRouteSnapshot().firstChild;
  if (!snapshot) {
    return false;
  }
  let filterValue = false;
  // match all childs (snapshot.firstChild)
  while (snapshot) {
    if (filterValue) {
      break;
    }
    filterValue = rxRouteFilterInternal(rxRouteGeneratePayload(snapshot), startsWithPath);

    snapshot = snapshot.firstChild;
  }

  return filterValue;
});

export const rxRouteRequestTargetNotStartWith = (startsNotWithPath: string) => rxRouteRequestTargetMatches(
  (url) => !url.startsWith(startsNotWithPath),
);

export const rxRouteRequestTargetMatches = (fn: (url: string) => boolean) => pipe(
  ofType(ROUTER_REQUEST),
  map((action: RouterRequestAction) => action.payload),
  filter((payload: RouterRequestPayload<BaseRouterStoreState>) => fn(payload.event.url)),
);

export const rxRouteRegex = (regex: RegExp | string) => pipe(
  ofType(RouterActionTypes.RouterActionNavigate),
  filter((n: RouterActionNavigate['payload']) => RegExp(regex).test(n.path)),
);

export const rxGetFormControl = <T>(formControlName) => map((s: FormGroupState<T>) => s.controls[formControlName]);
export const rxGetFormControls = pipe(map((s: FormGroupState<any>) => s.controls));
export const rxDefaultTo = (defaultValue) => map((value) => value === undefined ? defaultValue : value);

export interface RxCatchApiErrorInput {
  logMsg?: string;
  actionMsg?: string;
  useErrorMessage?: boolean;
  asyncKey?: string;
  controlId?: string;
  errorActionCreator?: FunctionWithParametersType<
    [{ e: Error; message?: string; }?],
    { e: Error; message?: string; } & { isError: boolean; form: string; } & Action
  >;
}

export function rxCatchApiError({
                                  logMsg,
                                  errorActionCreator,
                                  actionMsg,
                                  useErrorMessage = false,
                                  asyncKey,
                                  controlId
                                }: RxCatchApiErrorInput): OperatorFunction<any, Action> {
  return catchError<any, Observable<Action>>((e: Error): Observable<Action> => {

    let error$: Observable<Action>;
    let message = '';
    if (e instanceof HttpErrorResponse) {
      message = actionMsg ? actionMsg : useErrorMessage ? e.error.errorCode : '';
      if (e.status === 413) { // Request Size to Large
        error$ = of(new ApiErrorAction(e, 'RequestSizeToLargeError', 0));
      } else if (errorActionCreator) {
        error$ = of(errorActionCreator({ e, message }));
      } else {
        error$ = of(new ApiErrorAction(e, e.message));
      }
    } else {
      console.error(logMsg, e);
      error$ = of({ type: 'rxCatchApiErrorDummyAction' } as Action);
    }

    if (asyncKey) {
      return error$.pipe(mergeMap((a) => [a, new SetAsyncErrorAction(controlId, asyncKey, message)]));
    } else {
      return error$;
    }
  });
}

export function reduceForm(state, name: string, action, validation: Function = (x, s?) => x) {
  let form = formGroupReducer(state[name], action);
  form = validation(form, state);
  if (form !== state[name]) {
    return {
      ...state,
      [name]: form,
    };
  } else {
    return state;
  }
}

export function reduceFormArray(state, name: string, action, validation: Function = (x) => x) {
  let form = formArrayReducer(state[name], action);
  form = validation(form);
  if (form !== state[name]) {
    return {
      ...state,
      [name]: form,
    };
  } else {
    return state;
  }
}

export function reduceForms(state, names: string[], action) {
  return names.reduce((previousState, currentName) => reduceForm(previousState, currentName, action), state);
}

export function noValidation() {
  return undefined;
}
