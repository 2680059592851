import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-page-header-logo',
  templateUrl: './page-header-logo.component.html',
  styleUrls: ['./page-header-logo.component.scss'],
})
export class PageHeaderLogoComponent {
  logo = this.appConfigService.config.logo;

  constructor(private appConfigService: AppConfigService) { }
}
