import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { getSessionIsLoggedIn, getSessionUser, SessionState } from '../../reducers/session.reducer';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-session-greeting',
  templateUrl: './session-greeting.component.html',
  styleUrls: ['./session-greeting.component.scss']
})
export class SessionGreetingComponent {
  isLoggedIn$ = this.store$.select(getSessionIsLoggedIn);
  user$ = this.store$.select(getSessionUser);
  userFullName$ = this.user$.pipe(
    map(user => user ? `${user.firstName} ${user.lastName}` : undefined)
  );
  fullGreeting$ = this.userFullName$.pipe(
    map(userFullName => ({
      userFullName,
      greeting: [
        'Hallo ',
      ],
    })),
    map(({userFullName, greeting}) => userFullName ? `${greeting}${userFullName}` : userFullName)
  );

  constructor(private store$: Store<SessionState>) {}
}
