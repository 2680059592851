<div class="propertiesContainer">
  <div
    fxLayout="row wrap"
    fxLayoutAlign="space-evenly"
    fxLayoutGap="10px grid"
    fxAlignLastRowLeft
  >
    <div
      fxFlex="25%"
      fxFlex.lt-lg="50%"
      *ngFor="let element of elements; trackBy: trackByName"
    >
      <lib-common-route-config-form-property
        [className]="element.class"
        [formName]="element.name"
        [globalPropertyName]="element.metaConfig.globalPropertyName"
        [readonly]="readonly"
      ></lib-common-route-config-form-property>
    </div>
  </div>
</div>
