import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { toFaqTreeModel } from '../../../core-lib/models/faq-tree.model';
import { ApiService } from '../../../core-lib/services/api.service';
import { faqLoadFaqsAction, faqSetFaqsAction } from '../actions/faq.actions';

@Injectable()
export class FaqEffects {
  loadFaqData$ = createEffect(() => this.actions$.pipe(
    ofType(faqLoadFaqsAction),
    switchMap(() => this.api.getFaqItems()),
    map(toFaqTreeModel),
    map(faqTree => faqSetFaqsAction({data: faqTree})),
  ));

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {
  }
}
