import { InputLangStrings } from '../../../core-lib/components/input-lang-string/input-lang-string.component';
import { backendClasses } from '../../../core-lib/models/backend-classes.model';
import { ConfigItemListItemConfigurationDtoModel } from '../../../core-lib/models/config-item-list-configuration-dto.model';
import { ConfigListModel } from '../../models/config-list.model';

export class DataTransformers {
  static mapConfigListModelToDto(listItem: ConfigListModel): ConfigItemListItemConfigurationDtoModel {
    if (!listItem) {
      return undefined;
    }
    return ({
      id: listItem.id,
      attributeName: listItem.key,
      value: listItem.value,
      categories: listItem.categories,
      $$typeName: ConfigItemListItemConfigurationDtoModel.TYPENAME,
      translations: Object.keys(listItem.labels).map((name) => ({
        text: listItem.labels[name].text,
        id: listItem.labels[name].id,
        key: listItem.key,
        lang: name,
      })).reduce((acc, curr) => ({
        ...acc,
        [curr.lang]: curr,
      }), {}),
      '@class': backendClasses.listItemConfiguration,
    });
  }

  static mapDtoToConfigListModel(listItem: ConfigItemListItemConfigurationDtoModel): ConfigListModel {
    if (!listItem) {
      return undefined;
    }
    return {
      id: listItem.id,
      value: listItem.value,
      key: listItem.attributeName,
      categories: listItem.categories,
      labels: Object.keys(listItem.translations).reduce((accumulator, translationKey): InputLangStrings => ({
        ...accumulator,
        [translationKey]: {
          text: listItem.translations[translationKey].text,
          id: listItem.translations[translationKey].id,
        },
      }), {}),
    };
  }
}
