import {
  Actions,
  createFormArrayState,
  createFormGroupState,
  FormArrayState,
  FormGroupState,
  isNgrxFormsAction,
  setValue,
  updateArray,
  updateArrayWithFilter,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { InputLangStrings } from '../../../core-lib/components/input-lang-string/input-lang-string.component';
import AppConfigModel from '../../../core-lib/models/app-config.model';
import { CategoryDataDto } from '../../../core-lib/models/category-data-dto.model';
import { CategoryInfoModel } from '../../../core-lib/models/category-info.model';
import { FaqElementModel, FaqTreeModel } from '../../../core-lib/models/faq-tree.model';
import { GlobalConfigDtoModel } from '../../../core-lib/models/global-config-dto.model';
import { SetValueTraceableAction } from '../../../core-lib/models/set-value-traceable-action';
import { SystemMessage } from '../../../core-lib/models/system-message.model';
import TranslationStoreModel from '../../../core-lib/models/translation-store.model';
import { createEasyFormGroupState } from '../../../core-lib/utils/create-easy-form-group-state';
import { reduceForm, reduceFormArray } from '../../../core-lib/utils/reducer-utils';
import { checkUniqueInList } from '../../../forms-lib/utils/validator-utils';
import { ListItemConfigurationDtoModel } from '../../../forms/all-forms/models/list-item-configuration-dto.model';
import { AccountFormMappingModel } from '../../models/account-form-mapping.model';
import { Article } from '../../models/article.model';
import { CateringOrderDataState } from '../../models/catering-order-data-state.model';
import { CateringOrderData } from '../../models/catering-order-data.model';
import { ConfigListModel } from '../../models/config-list.model';
import { translations } from '../../models/i18n';
import { LocationInfo } from '../../models/location-info.model';
import { NetRegionModel } from '../../models/net-region.model';
import { Room } from '../../models/room.model';
import { ServiceCenterModel } from '../../models/service-center.model';
import { UserRole } from '../../models/user-roles.model';
import {
  CoreActionCategoriesLoaded,
  CoreConfigActions,
  coreConfigCategoryInfoLoadSuccess,
  coreConfigCategoryInfoMarkForDeletion,
  coreConfigCategoryInfoSaveSuccess,
  coreConfigCateringOrderDataLoadSuccess,
  coreConfigCateringOrderDataSaveSuccess,
  coreConfigFaqDataLoadSuccess,
  coreConfigFaqMarkElementForDeletion,
  coreConfigFaqMarkTreeForDeletion,
  coreConfigFaqSaveSuccess,
  CoreConfigLoadedGlobalConfig,
  CoreConfigLocationArticleSaveSuccess,
  CoreConfigLocationArticleSelected,
  CoreConfigLocationInfoLoadSuccess,
  CoreConfigLocationRoomSaveSuccess,
  CoreConfigLocationRoomSelected,
  coreConfigServiceCenterDataLoadSuccess,
  coreConfigServiceCenterDataSaveSuccess,
  coreConfigServiceCenterMarkForDeletion,
  CoreConfigSetSystemMessage,
  CoreConfigSystemMessageAdminLoadSuccess,
  CoreI18nSetLanguage,
} from '../actions/core-config.actions';

export const TAXRATES_FORM_ID = 'taxrates';
export const LEDGERACCOUNTS_FORM_ID = 'ledgerAccounts';
export const SALUTATIONS_FORM_ID = 'salutations';
export const SYSTEMMESSAGE_FROM_ID = 'systemMessage';
export const ROOM_FROM_ID = 'roomForm';
export const ARTICLE_FROM_ID = 'articleForm';
export const ORDER_DATA_FROM_ID = 'orderDataForm';
export const FAQ_FROM_ID = 'faqForm';
export const CATEGORY_INFO_FROM_ID = 'categoryInfoForm';
export const SERVICE_CENTER_DATA_FROM_ID = 'serviceCenterForm';

export interface AdminElement {
  name: string;
  icon: string;
  class: string;
  routerLink: string;
  active: (appconfig: AppConfigModel, roles: string[]) => boolean;
}

export class ConfigState {
  adminElements: AdminElement[];
  i18n: {
    translations: TranslationStoreModel,
    selectedLanguage: string,
    keys: string[],
  };
  taxrates: FormArrayState<ConfigListModel>;
  ledgerAccounts: FormArrayState<ConfigListModel>;
  salutations: FormArrayState<ConfigListModel>;
  systemMessage: FormGroupState<SystemMessage>;
  systemMessageSet: SystemMessage;
  globalConfig: GlobalConfigDtoModel;
  categories: CategoryDataDto[];
  locationInfos: LocationInfo[];
  roomForm: FormArrayState<Room>;
  articleForm: FormArrayState<Article>;
  orderDataForm: FormGroupState<CateringOrderDataState>;
  faqForm: FormArrayState<FaqTreeModel>;
  categoryInfoForm: FormArrayState<CategoryInfoModel>;
  serviceCenterForm: FormArrayState<NetRegionModel<AccountFormMappingModel>>;
}

export const initialConfigListValue = {
  key: '',
  value: '',
  labels: {
    de: {
      text: 'de',
    },
    en: {
      text: 'en',
    },
  },
};

export const initialConfigState: ConfigState = {
  adminElements: [
    {
      name: 'Gesellschaftsverwaltung',
      icon: '',
      class: 'efa-highlight-bg-',
      routerLink: '/config/companies',
      active: (appconfig: AppConfigModel, roles: string[]) => !!roles.find(s => s === UserRole.ADMIN),
    },
    {
      name: 'Steuersätze',
      icon: '',
      class: 'efa-highlight-bg-',
      routerLink: '/config/taxrates',
      active: (appconfig: AppConfigModel, roles: string[]) => !!roles.find(s => s === UserRole.ADMIN),
    },
    {
      name: 'Sachkonten',
      icon: '',
      class: 'efa-highlight-bg-',
      routerLink: '/config/ledgeraccounts',
      active: (appconfig: AppConfigModel, roles: string[]) => !!roles.find(s => s === UserRole.ADMIN),
    },
    {
      name: 'Anreden',
      icon: '',
      class: 'efa-highlight-bg-',
      routerLink: '/config/salutations',
      active: (appconfig: AppConfigModel, roles: string[]) => !!roles.find(s => s === UserRole.ADMIN),
    },
    {
      name: 'Formularkonfiguration',
      icon: '',
      class: 'efa-highlight-bg-',
      routerLink: '/config/forms',
      active: (appconfig: AppConfigModel, roles: string[]) => !!roles.find(s => s === UserRole.ADMIN),
    },
    {
      name: 'Vertreterregelung',
      icon: '',
      class: 'efa-highlight-bg-',
      routerLink: '/config/deputies',
      active: (appconfig: AppConfigModel, roles: string[]) => !!roles.find(s => s === UserRole.ADMIN),
    },
    {
      name: 'Systemmeldung',
      icon: '',
      class: 'efa-highlight-bg-',
      routerLink: '/config/systemMessage',
      active: (appconfig: AppConfigModel, roles: string[]) => !!roles.find(s => s === UserRole.ADMIN),
    },
    {
      name: 'Raumlisten',
      icon: '',
      class: 'efa-highlight-bg-',
      routerLink: '/config/rooms',
      active: (appconfig: AppConfigModel, roles: string[]) => appconfig.enableCatering && !!roles.find(s => s === UserRole.CATERER_ADMIN),
    },
    {
      name: 'Artikellisten',
      icon: '',
      class: 'efa-highlight-bg-',
      routerLink: '/config/articles',
      active: (appconfig: AppConfigModel, roles: string[]) => appconfig.enableCatering && !!roles.find(s => s === UserRole.CATERER_ADMIN),
    },
    {
      name: 'Catering Bestelldaten',
      icon: '',
      class: 'efa-highlight-bg-',
      routerLink: '/config/catering/data',
      active: (appconfig: AppConfigModel, roles: string[]) => appconfig.enableCatering && !!roles.find(s => s === UserRole.ADMIN),
    },
    {
      name: 'FAQ pflegen',
      icon: '',
      class: 'efa-highlight-bg-',
      routerLink: '/config/faq',
      active: (appconfig: AppConfigModel, roles: string[]) => !!roles.find(s => s === UserRole.ADMIN),
    },
    {
      name: 'Massendatenverarbeitung',
      icon: '',
      class: 'efa-highlight-bg-',
      routerLink: '/config/massDataProcessing',
      active: (appconfig: AppConfigModel, roles: string[]) => appconfig.enableMassData && !!roles.find(s => s === UserRole.MASSDATA),
    },
    {
      name: 'Service Center pflegen',
      icon: '',
      class: 'efa-highlight-bg-',
      routerLink: '/config/servicecenter',
      active: (appconfig: AppConfigModel, roles: string[]) => appconfig.enableNetRegion && !!roles.find(s => s === UserRole.ADMIN),
    },
    {
      name: 'Kategorieinformation pflegen',
      icon: '',
      class: 'efa-highlight-bg-',
      routerLink: '/config/categoryInfo',
      active: (appconfig: AppConfigModel, roles: string[]) => !!roles.find(s => s === UserRole.ADMIN),
    },
    {
      name: 'Dashboard',
      icon: '',
      class: 'efa-highlight-bg-',
      routerLink: '/config/dashboard',
      active: (appconfig: AppConfigModel, roles: string[]) => !!roles.find(s => s === UserRole.DASHBOARD),
    },
    {
      name: 'Fehlgeschlagene Vorgänge',
      icon: '',
      class: 'efa-highlight-bg-',
      routerLink: '/config/queue',
      active: (appconfig: AppConfigModel, roles: string[]) => !!roles.find(s => s === UserRole.ADMIN),
    },
  ],
  i18n: {
    translations: translations,
    selectedLanguage: 'de',
    keys: [],
  },
  taxrates: createFormArrayState<ConfigListModel>(TAXRATES_FORM_ID, []),
  ledgerAccounts: createFormArrayState<ConfigListModel>(LEDGERACCOUNTS_FORM_ID, []),
  salutations: createFormArrayState<ConfigListModel>(SALUTATIONS_FORM_ID, []),
  systemMessage: createEasyFormGroupState(SYSTEMMESSAGE_FROM_ID, SystemMessage),
  globalConfig: new GlobalConfigDtoModel(),
  categories: [],
  systemMessageSet: {
    active: undefined,
    activeUntil: undefined,
    content: undefined,
    id: undefined,
  },
  locationInfos: [],
  roomForm: createFormArrayState(ROOM_FROM_ID, []),
  articleForm: createFormArrayState(ARTICLE_FROM_ID, []),
  orderDataForm: createFormGroupState(ORDER_DATA_FROM_ID, {selectedCompany: '', orderData: []}),
  faqForm: createFormArrayState(FAQ_FROM_ID, []),
  categoryInfoForm: createFormArrayState(CATEGORY_INFO_FROM_ID, []),
  serviceCenterForm: createFormArrayState(SERVICE_CENTER_DATA_FROM_ID, []),
};

const validateConfigList = updateArray((formGroupState: FormGroupState<ConfigListModel>) => updateGroup({
  key: validate(required),
  value: validate(required),
  labels: updateGroup<InputLangStrings>(
    Object.keys(formGroupState.controls.labels.controls)
      .reduce((acc, k) => (
        {
          ...acc,
          [k]: updateGroup<{ id?: string, text?: string }>({
            text: validate(required),
          }),
        }
      ), {}),
  ),
})(formGroupState));

const validateArticleList = updateArray<Article>(updateGroup<Article>({
  name: validate(required),
  unit: validate(required),
  price: validate(required),
  validFrom: validate(required),
  validUntil: validate(required),
  taxRate: updateGroup<ListItemConfigurationDtoModel>({
    id: validate(required),
  }),
}));

const validateCateringOrderData = (currentState: FormGroupState<CateringOrderDataState>) => updateGroup<CateringOrderDataState>(
  currentState, {
    selectedCompany: validate(required),
    orderData: updateArray<CateringOrderData>(updateGroup<CateringOrderData>({
      locationInfo: validate(required, checkUniqueInList(currentState.value.orderData, (a, b) => a.locationInfo === b)),
      cateringPosition: validate(required),
      cateringOrder: validate(required),
      company: validate(required),
    })),
  });


const validateCategoryInfo = updateArray([
  updateGroup<NetRegionModel<AccountFormMappingModel>>({
    name: validate(required),
    company: validate(required),
    serviceCenters: updateArray([
      updateGroup<ServiceCenterModel<AccountFormMappingModel>>({
        name: validate(required)
      })
    ]),
  }),
]);

export function configReducer(state = initialConfigState, action: CoreConfigActions | Actions<any>): ConfigState {
  let newState = state;

  if (isNgrxFormsAction(action)) {
    newState = reduceFormArray(newState, TAXRATES_FORM_ID, action, validateConfigList);
    newState = reduceFormArray(newState, LEDGERACCOUNTS_FORM_ID, action, validateConfigList);
    newState = reduceFormArray(newState, SALUTATIONS_FORM_ID, action, validateConfigList);
    newState = reduceForm(newState, SYSTEMMESSAGE_FROM_ID, action);
    newState = reduceFormArray(newState, ROOM_FROM_ID, action);
    newState = reduceFormArray(newState, ARTICLE_FROM_ID, action, validateArticleList);
    newState = reduceForm(newState, ORDER_DATA_FROM_ID, action, validateCateringOrderData);
    newState = reduceFormArray(newState, FAQ_FROM_ID, action);
    newState = reduceFormArray(newState, SERVICE_CENTER_DATA_FROM_ID, action, validateCategoryInfo);
    newState = reduceFormArray(newState, CATEGORY_INFO_FROM_ID, action);
  } else {
    switch (action.type) {
      case SetValueTraceableAction.TYPE:
        // toUpperCase für "taxrates.0.key" usw.
        if (/^taxrates\.\d*\.key$/.test(action.controlId)) {
          return reduceFormArray(newState, TAXRATES_FORM_ID, {
            ...action,
            value: (
              action.value + ''
            ).toUpperCase(),
          });
        }
        // toUpperCase für "ledgerAccounts.0.key" usw.
        if (/^ledgerAccounts\.\d*\.key$/.test(action.controlId)) {
          return reduceFormArray(newState, LEDGERACCOUNTS_FORM_ID, {
            ...action,
            value: (
              action.value + ''
            ).toUpperCase(),
          });
        }
        // toUpperCase für "salutations.0.key" usw.
        if (/^salutations\.\d*\.key$/.test(action.controlId)) {
          return reduceFormArray(newState, SALUTATIONS_FORM_ID, {
            ...action,
            value: (
              action.value + ''
            ).toUpperCase(),
          });
        }
        return newState;
      case CoreConfigLoadedGlobalConfig.TYPE:
        return {
          ...newState,
          globalConfig: {
            ...(newState.globalConfig),
            ...action.config,
            configurationItems: {
              ...newState.globalConfig.configurationItems,
              ...action.config.configurationItems,
            },
          },
        };
      case CoreActionCategoriesLoaded.TYPE:
        return {
          ...newState,
          categories: [...action.categories],
        };
      case CoreI18nSetLanguage.TYPE:
        return {
          ...state,
          i18n: {
            ...state.i18n,
            selectedLanguage: action.langKey,
          },
        };
      case CoreConfigSetSystemMessage.TYPE:
        return {
          ...newState,
          systemMessageSet: action.systemMessageSet,
        };
      case CoreConfigSystemMessageAdminLoadSuccess.TYPE:
        return {
          ...newState,
          systemMessage: createFormGroupState(SYSTEMMESSAGE_FROM_ID, action.systemMessage),
        };
      case CoreConfigLocationInfoLoadSuccess.TYPE:
        return {
          ...newState,
          locationInfos: [...action.locations],
        };
      case CoreConfigLocationRoomSelected.TYPE:
      case CoreConfigLocationRoomSaveSuccess.TYPE:
        return {
          ...newState,
          roomForm: createFormArrayState(ROOM_FROM_ID, action.rooms),
        };
      case CoreConfigLocationArticleSelected.TYPE:
      case CoreConfigLocationArticleSaveSuccess.TYPE:
        return {
          ...newState,
          articleForm: createFormArrayState(ARTICLE_FROM_ID, action.articles),
        };
      case coreConfigCateringOrderDataLoadSuccess.type:
      case coreConfigCateringOrderDataSaveSuccess.type:
        return {
          ...newState,
          orderDataForm: updateGroup(state.orderDataForm, {
            orderData: setValue(action.data),
          }),
        };
      case coreConfigFaqDataLoadSuccess.type:
      case coreConfigFaqSaveSuccess.type:
        return {
          ...newState,
          faqForm: createFormArrayState(FAQ_FROM_ID, action.data),
        };
      case coreConfigFaqMarkTreeForDeletion.type:
        return {
          ...newState,
          faqForm: updateArrayWithFilter(
            state.faqForm,
            (tree) => tree.id === action.treeId,
            [
              updateGroup<FaqTreeModel>({
                items: updateArray(
                  [
                    updateGroup<FaqElementModel>({
                      markedForDeletion: setValue<boolean>(true),
                    }),
                  ],
                ),
              }),
            ],
          ),
        };
      case coreConfigFaqMarkElementForDeletion.type:
        return {
          ...newState,
          faqForm: updateArrayWithFilter(
            state.faqForm,
            (tree) => tree.id === action.treeId,
            [
              updateGroup<FaqTreeModel>({
                items: updateArrayWithFilter(
                  item => item.id === action.itemId,
                  [
                    updateGroup<FaqElementModel>({
                      markedForDeletion: setValue<boolean>(true),
                    }),
                  ],
                ),
              }),
            ],
          ),
        };
      case coreConfigServiceCenterDataSaveSuccess.type:
      case coreConfigServiceCenterDataLoadSuccess.type:
        return {
          ...newState,
          serviceCenterForm: createFormArrayState(SERVICE_CENTER_DATA_FROM_ID, action.data),
        };
      case coreConfigServiceCenterMarkForDeletion.type:
        return {
          ...newState,
          serviceCenterForm: updateArrayWithFilter(
            state.serviceCenterForm,
            (position, i) => i === action.index,
            [
              updateGroup<NetRegionModel<AccountFormMappingModel>>({
                markedForDeletion: setValue<boolean>(true),
              }),
            ],
          ),
        };
      case coreConfigCategoryInfoSaveSuccess.type:
      case coreConfigCategoryInfoLoadSuccess.type:
        return {
          ...newState,
          categoryInfoForm: createFormArrayState(SERVICE_CENTER_DATA_FROM_ID, action.data),
        };
      case coreConfigCategoryInfoMarkForDeletion.type:
        return {
          ...newState,
          categoryInfoForm: updateArrayWithFilter(
            state.categoryInfoForm,
            (position, i) => i === action.index,
            [
              updateGroup<CategoryInfoModel>({
                markedForDeletion: setValue<boolean>(true),
              }),
            ],
          ),
        };
      default:
        return newState;
    }
  }
  return newState;
}
