import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RouteInfoService } from '../../../core-lib/services/route-info.service';
import { getSessionUserIsOnlyCaterer } from '../../../session/reducers/session.reducer';
import { CoreFeatureState, getFormCategory } from '../../ngrx/reducers/core.store';
import { getEasyFormsArrayElementByIdentifier } from '../../ngrx/reducers/easy-forms.reducer';
import { getRouterNavDisplay, getRouterUrl } from '../../ngrx/reducers/router.reducer';
import { AppConfigService } from '../../services/app-config.service';
import { UrlTokenReplacerService } from '../../services/url-token-replacer.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-page-bread-crumbs-nav',
  templateUrl: './page-bread-crumbs-nav.component.html',
  styleUrls: ['./page-bread-crumbs-nav.component.scss'],
})
export class PageBreadCrumbsNavComponent {
  @Output() toggleSidebar = new EventEmitter<boolean>();

  isCaterer$ = this.store$.select(getSessionUserIsOnlyCaterer);

  faHome = faHome;

  forceMobile = false;
  navDisplay$ = this.store$.select(getRouterNavDisplay);
  url$ = this.store$.select(getRouterUrl);
  category$ = this.store$.select(getFormCategory);
  forceMobile$ = this.navDisplay$.pipe(
    map((navDisplay) => navDisplay && navDisplay.length > 0),
  );

  constructor(
    private store$: Store<CoreFeatureState>,
    private router: Router,
    private route: ActivatedRoute,
    private urlTokenReplacer: UrlTokenReplacerService,
    private appConfigService: AppConfigService,
    private routeInfo: RouteInfoService,
  ) {
    this.forceMobile$.subscribe(f => this.forceMobile = f);
  }

  parseDisplayName(navDisplayItem) {
    if (!navDisplayItem.name) {
      return this.category$.pipe(
        filter(value => !!value.categories),
        map(value => value.categories),
        map((categories) => {
          const formsCategory = this.routeInfo.getParam('formsCategory');
          return categories[formsCategory] && categories[formsCategory].displayName;
        }),
      );
    }

    if (navDisplayItem.shouldReplaceWithId) {
      let splittedUrl = [];
      return this.url$.pipe(map(value => {
        splittedUrl = value.split('/');
        return splittedUrl[splittedUrl.length - 1];
      }));
    }

    if (navDisplayItem.formIdentifier) {
      return this.store$.select(getEasyFormsArrayElementByIdentifier, { identifier: navDisplayItem.formIdentifier }).pipe(map(value => {
        return value?.name;
      }));
    }

    return of(navDisplayItem.name);
  }

  toggle() {
    this.toggleSidebar.emit(true);
  }

  navigateRelative(url) {
    // this.router.navigate([url], {relativeTo: this.route});
    // Workaround
    // realtive navigation broken, see: https://github.com/angular/angular/issues/13011
    const replacedUrl = this.urlTokenReplacer.replace(url);
    if (url === './' || url === '.') {
      this.router.navigate([this.router.url]);
    } else {
      this.router.navigate([replacedUrl], {relativeTo: this.route});
    }
  }

}
