import { createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EasyFormModel } from '../../../core-lib/models/easy-form.model';
import { ServiceCenterModel } from '../../models/service-center.model';
import { CoreEasyFormsActions, CoreEasyFormsLoadFormsSuccessAction } from '../actions/core-easy-forms.actions';
import { CoreFeatureState } from './core.store';
import { EasyFormsAdditionalState, EasyFormsState } from './easy-forms.store';

export const easyFormsAdapter = createEntityAdapter<EasyFormModel>();

export function easyFormsReducer(state = initialeasyFormsState, action: CoreEasyFormsActions): EasyFormsState {
  switch (action.type) {
    case CoreEasyFormsLoadFormsSuccessAction.TYPE:
      return easyFormsAdapter.setAll(action.forms, state);
    default:
      return state;
  }
}

export const initialeasyFormsState = easyFormsAdapter.getInitialState<EasyFormsAdditionalState>({
  selected: undefined,
});

const adapterSelectors = easyFormsAdapter.getSelectors();

export const getEasyFormsCoreFeatureState = createFeatureSelector<CoreFeatureState>('core');
export const getEasyFormsFeatureState = createSelector(
  getEasyFormsCoreFeatureState,
  (s) => s.easyForms,
);

export const getEasyFormsArrayState = createSelector(
  getEasyFormsFeatureState,
  adapterSelectors.selectAll,
);

export const getEasyFormsArrayElementByDynamicIdentifierState = createSelector(
  getEasyFormsArrayState,
  (array) => (identifier: string) => array.find(f => f.identifier === identifier),
);

export const getEasyFormsArrayElementByIdentifier = createSelector(
  getEasyFormsArrayState,
  (array: EasyFormModel[], props: { identifier: string }) => array.find(f => f.identifier === props.identifier),
);

export const getEasyFormsArrayElementByIdentifiers = createSelector(
  getEasyFormsArrayState,
  (
    array: EasyFormModel[],
    props: Partial<{ identifiers: string[] }>,
  ): EasyFormModel[] => array.filter(f => props.identifiers.includes(f.identifier)),
);

export const getEasyFormsEntitiesState = createSelector(
  getEasyFormsFeatureState,
  adapterSelectors.selectEntities,
);
export const getEasyFormsEntityValuesState = createSelector(
  getEasyFormsEntitiesState,
  s => Object.values(s),
);

export const getEasyFormsEntityValuesStateFiltered = createSelector(
  getEasyFormsEntityValuesState,
  (
    forms,
    {serviceCenter, currentSelectedFormIdentifier}: { serviceCenter: ServiceCenterModel<any>, currentSelectedFormIdentifier: string },
  ) => forms.filter(form => !serviceCenter.accountFormMappings.find(afm => afm.formIdentifier === form.identifier
    && afm.formIdentifier !== currentSelectedFormIdentifier)),
);
