import { createReducer, createSelector, on } from '@ngrx/store';
import { ALL_NGRX_FORMS_ACTION_TYPES, createFormGroupState, FormGroupState, formStateReducer } from 'ngrx-forms';
import { DashboardPayoutType, DashboardStateCountType, DashboardThroughputType } from '../../../core-lib/models/dashboard.model';
import {
  coreDashboardUpdatePayoutSuccessAction,
  coreDashboardUpdateStateCountSuccessAction,
  coreDashboardUpdateThroughputSuccessAction,
} from '../actions/dashboard.actions';
import { getEasyFormsCoreFeatureState } from './easy-forms.reducer';

export class DashboardFromGroupState {
  from: string = undefined;
  to: string = undefined;
}

export class StateCountFormGroupState extends DashboardFromGroupState {
  static readonly ID = 'stateCountForm';
}

export class ThroughputFormGroupState extends DashboardFromGroupState {
  static readonly ID = 'throughputForm';
}

export class PayoutFormGroupState extends DashboardFromGroupState {
  static readonly ID = 'payoutForm';
}

export interface DashboardState {
  stateCount: {
    data: DashboardStateCountType;
    form: FormGroupState<StateCountFormGroupState>,
  };
  throughput: {
    data: DashboardThroughputType;
    form: FormGroupState<ThroughputFormGroupState>,
  };
  payout: {
    data: DashboardPayoutType;
    form: FormGroupState<PayoutFormGroupState>,
  };
}

const initialDashboardState: DashboardState = { // https://youtu.be/8B4guKLlbVU
  stateCount: {
    data: {},
    form: createFormGroupState<StateCountFormGroupState>(StateCountFormGroupState.ID, {
      from: new Date(2022, 0, 1, 1).toISOString(),
      to: new Date().toISOString(),
    }),
  },
  throughput: {
    data: {},
    form: createFormGroupState<ThroughputFormGroupState>(ThroughputFormGroupState.ID, {
      from: new Date(2022, 0, 1, 1).toISOString(),
      to: new Date().toISOString(),
    }),
  },
  payout: {
    data: [],
    form: createFormGroupState<PayoutFormGroupState>(PayoutFormGroupState.ID, {
      from: new Date(2022, 0, 1, 1).toISOString(),
      to: new Date().toISOString(),
    }),
  },
};

export const dashboardReducer = createReducer(
  initialDashboardState,
  on(coreDashboardUpdateStateCountSuccessAction, (s, {statecount: stateCount}) => ({
    ...s,
    stateCount: {
      ...s.stateCount,
      data: stateCount,
    },
  })),
  on(coreDashboardUpdateThroughputSuccessAction, (s, {throughput}) => ({
    ...s,
    throughput: {
      ...s.throughput,
      data: throughput,
    },
  })),
  on(coreDashboardUpdatePayoutSuccessAction, (s, {payout}) => ({
    ...s,
    payout: {
      ...s.payout,
      data: payout.sort((a, b) => (new Date(b.lastModified)).valueOf() - (new Date(a.lastModified)).valueOf()).map((p) => {
        const mod = new Date(p.lastModified);
        return ({
          name: mod.toLocaleDateString(),
          value: [
            mod,
            Math.floor(p.cumulativeAmount),
          ],
        });
      }),
    },
  })),
  {
    types: ALL_NGRX_FORMS_ACTION_TYPES,
    reducer: (state, action) => {
      return {
        ...state,
        stateCount: {
          ...state.stateCount,
          form: formStateReducer<StateCountFormGroupState>(state.stateCount.form, action),
        },
        throughput: {
          ...state.throughput,
          form: formStateReducer<ThroughputFormGroupState>(state.throughput.form, action),
        },
        payout: {
          ...state.payout,
          form: formStateReducer<PayoutFormGroupState>(state.payout.form, action),
        },
      };
    },
  },
);


// selectors
export const getDashboardState = createSelector(
  getEasyFormsCoreFeatureState,
  (s) => s?.dashboard,
);

export const getDashboardStateCountState = createSelector(
  getDashboardState,
  (s) => s?.stateCount?.data,
);

export const getDashboardStateTotalCountState = createSelector(
  getDashboardStateCountState,
  (s) => Object.values(s).reduce((a, v) => a + v, 0),
);

export const getDashboardStateCountFormState = createSelector(
  getDashboardState,
  (s) => s?.stateCount?.form,
);
export const getDashboardStateCountFormFromState = createSelector(
  getDashboardStateCountFormState,
  (s) => s?.controls.from,
);
export const getDashboardStateCountFormToState = createSelector(
  getDashboardStateCountFormState,
  (s) => s?.controls.to,
);

export const getDashboardThroughputState = createSelector(
  getDashboardState,
  (s) => s?.throughput?.data,
);

export const getDashboardThroughputFormState = createSelector(
  getDashboardState,
  (s) => s?.throughput?.form,
);
export const getDashboardThroughputFormFromState = createSelector(
  getDashboardThroughputFormState,
  (s) => s?.controls.from,
);
export const getDashboardThroughputFormToState = createSelector(
  getDashboardThroughputFormState,
  (s) => s?.controls.to,
);


export const getDashboardPayoutState = createSelector(
  getDashboardState,
  (s) => s?.payout?.data,
);
export const getDashboardPayoutFormState = createSelector(
  getDashboardState,
  (s) => s?.payout?.form,
);
export const getDashboardPayoutFormFromState = createSelector(
  getDashboardPayoutFormState,
  (s) => s?.controls.from,
);
export const getDashboardPayoutFormToState = createSelector(
  getDashboardPayoutFormState,
  (s) => s?.controls.to,
);
