import { Action } from '@ngrx/store';
import { FormCategoryTreeElementModel, FormCategoryTreeModel } from '../../../core-lib/models/form-category-tree.model';

export class CoreFormCategorySetCategoriesAction implements Action {
  static readonly TYPE = 'core/formCategory/SetCategories';
  readonly type = CoreFormCategorySetCategoriesAction.TYPE;

  constructor(public categories: FormCategoryTreeModel) {
  }
}

export class CoreFormCategorySetSeelctedCategoryAction implements Action {
  static readonly TYPE = 'core/formCategory/SetSelectedCategory';
  readonly type = CoreFormCategorySetSeelctedCategoryAction.TYPE;

  constructor(public category: FormCategoryTreeElementModel) {
  }
}

export class CoreFormCategoryLoadCategoriesAction implements Action {
  static readonly TYPE = 'core/formCategory/LoadCategories';
  readonly type = CoreFormCategoryLoadCategoriesAction.TYPE;

  constructor(public categoryName: string) {
  }
}

export type CoreFormsCategoryActions =
  CoreFormCategorySetCategoriesAction
  | CoreFormCategoryLoadCategoriesAction;
