import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControlState } from 'ngrx-forms';
import { RouteConfigFormPropertyComponent } from '../../../core/components/route-config-form-property/route-config-form-property.component';
import { CoreFeatureState } from '../../../core/ngrx/reducers/core.store';
import { ConfigItemListConfigurationDtoModel } from '../../models/config-item-list-configuration-dto.model';
import { SetValueTraceableAction } from '../../models/set-value-traceable-action';
import { InputPropertyTypeComponent } from '../input-property-type/input-property-type.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-input-property-type-list',
  templateUrl: './input-property-type-list.component.html',
  styleUrls: ['./input-property-type-list.component.scss'],
})
export class InputPropertyTypeListComponent extends InputPropertyTypeComponent {

  typeName = ConfigItemListConfigurationDtoModel.TYPENAME;

  @Input() ngrxValues: ConfigItemListConfigurationDtoModel;
  @Input() ngrxPredefinedValue: FormControlState<string>;

  constructor(store$: Store<CoreFeatureState>, util: RouteConfigFormPropertyComponent) {
    super(store$, util);
  }

  internalReloadValues(lastConfigItemValue) {
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxPredefinedValue.id, lastConfigItemValue.ngrxPredefinedValue));
  }

}
