<div
  fxLayout="row wrap"
  fxLayoutAlign="space-between"
  fxLayoutGap="10px grid"
  *ngIf="getConfig().visible"
>
  <mat-form-field
    [fxFlex.gt-md]="getEqualSizePercent()"
    fxFlex.lt-lg="100%"
    *ngIf="getConfig().startDateVisible && formControlStateStartDate"
  >
    <input
      matInput
      [matDatepicker]="pickerStartDate"
      [placeholder]="startDateLabel"
      [ngrxFormControlState]="formControlStateStartDate"
      [ngrxValueConverter]="ngrxDateValueConverter(formControlStateStartDate)"
      [disabled]="!getConfig().enabled"
      [ngrxEasyFieldConfig]="getConfig().attributeName"
      [ngrxEasyFieldTranslationsKey]="'startDateTranslations'"
      [max]="getStartMaxDateTime()"
      [min]="getStartMinDateTime()"
    >
    <mat-datepicker-toggle
      matSuffix
      [for]="pickerStartDate"
    ></mat-datepicker-toggle>
    <mat-datepicker #pickerStartDate></mat-datepicker>
  </mat-form-field>
  <mat-form-field
    [fxFlex.gt-md]="getEqualSizePercent()"
    fxFlex.lt-lg="100%"
    *ngIf="getConfig().startTimeVisible && formControlStateStartDate"
  >
    <input
      matInput
      [ngxTimepicker]="pickerStartTime"
      placeholder="start time"
      [ngrxFormControlState]="formControlStateStartDate"
      [ngrxValueConverter]="ngrxTimeValueConverter(formControlStateStartDate)"
      [format]="24"
      [disableClick]="true"
      [disabled]="!getConfig().enabled"
      [ngrxEasyFieldConfig]="getConfig().attributeName"
      [ngrxEasyFieldTranslationsKey]="'startTimeTranslations'"
      [max]="getTimeString(getStartMoment(), getStartMaxDateTime(), '23:59')"
      [min]="getTimeString(getStartMoment(), getStartMinDateTime(), '00:00')"
    >
    <ngx-material-timepicker-toggle matSuffix [for]="pickerStartTime"></ngx-material-timepicker-toggle>
    <ngx-material-timepicker [theme]="clockTheme" #pickerStartTime></ngx-material-timepicker>
  </mat-form-field>
  <mat-form-field
    [fxFlex.gt-md]="getEqualSizePercent()"
    fxFlex.lt-lg="100%"
    *ngIf="getConfig().endDateVisible && formControlStateEndDate"
  >
    <input
      matInput
      [matDatepicker]="pickerEndDate"
      [disabled]="!getConfig().enabled"
      [ngrxFormControlState]="formControlStateEndDate"
      [ngrxValueConverter]="ngrxDateValueConverter(formControlStateEndDate)"
      [ngrxEasyFieldConfig]="getConfig().attributeName"
      [ngrxEasyFieldTranslationsKey]="'endDateTranslations'"
      [max]="getEndMaxDateTime()"
      [min]="getEndMinDateTime()"
      [placeholder]="endDateLabel"
    >
    <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
    <mat-datepicker #pickerEndDate></mat-datepicker>
  </mat-form-field>
  <mat-form-field
    [fxFlex.gt-md]="getEqualSizePercent()"
    fxFlex.lt-lg="100%"
    *ngIf="getConfig().endTimeVisible && formControlStateEndDate"
  >
    <input
      matInput
      [ngxTimepicker]="pickerEndTime"
      placeholder="end time"
      [ngrxFormControlState]="formControlStateEndDate"
      [ngrxValueConverter]="ngrxTimeValueConverter(formControlStateEndDate)"
      [format]="24"
      [disableClick]="true"
      [disabled]="!getConfig().enabled"
      [ngrxEasyFieldConfig]="getConfig().attributeName"
      [ngrxEasyFieldTranslationsKey]="'endTimeTranslations'"
      [max]="getTimeString(getEndMoment(), getEndMaxDateTime(), '23:59')"
      [min]="getTimeString(getEndMoment(), getEndMinDateTime(), '00:00')"
    >
    <ngx-material-timepicker-toggle matSuffix [for]="pickerEndTime"></ngx-material-timepicker-toggle>
    <ngx-material-timepicker [theme]="clockTheme" #pickerEndTime></ngx-material-timepicker>
  </mat-form-field>
  <div>
    <mat-error *ngFor="let e of startErrors()">
      {{ e.name | i18n | async }} {{ e.value }}
    </mat-error>
    <mat-error *ngFor="let e of endErrors()">
      {{ e.name | i18n | async }} {{ e.value }}
    </mat-error>
  </div>
</div>
