import { Injectable } from '@angular/core';
import { EasyFormA1ConfigItems } from '../../forms/form-a1/models/form-a1-config.model';
import { EasyFormA2ConfigItems } from '../../forms/form-a2/models/form-a2-config.model';
import { EasyFormA3ConfigItems } from '../../forms/form-a3/models/form-a3-config.model';
import { EasyFormA4ConfigItems } from '../../forms/form-a4/models/form-a4-config.model';
import { EasyFormA5ConfigItems } from '../../forms/form-a5/models/form-a5-config.model';
import { EasyFormAF01ConfigItems } from '../../forms/form-af01/models/form-af01-config.model';
import { EasyFormAF03ConfigItems } from '../../forms/form-af03/models/form-af03-config.model';
import { EasyFormAF04ConfigItems } from '../../forms/form-af04/models/form-af04-config.model';
import { EasyFormAF05ConfigItems } from '../../forms/form-af05/models/form-af05-config.model';
import { EasyFormAF06ConfigItems } from '../../forms/form-af06/models/form-af06-config.model';
import { EasyFormAF07ConfigItems } from '../../forms/form-af07/models/form-af07-config.model';
import { EasyFormAF08ConfigItems } from '../../forms/form-af08/models/route-af08-config.models';
import { EasyFormAF09ConfigItems } from '../../forms/form-af09/models/route-af09-config.model';
import { EasyFormAF10ConfigItems } from '../../forms/form-af10/models/route-af10-config.models';
import { EasyFormAF14ConfigItems } from '../../forms/form-af14/models/form-af14-config.models';
import { EasyFormAF15ConfigItems } from '../../forms/form-af15/models/form-af15-config.model';
import { EasyFormAF16ConfigItems } from '../../forms/form-af16/models/form-af16-config.models';
import { EasyFormAF17ConfigItems } from '../../forms/form-af17/models/form-af17-config.model';
import { EasyFormAF18ConfigItems } from '../../forms/form-af18/models/form-af18-config.model';
import { EasyFormAF19ConfigItems } from '../../forms/form-af19/models/form-af19-config.models';
import { EasyFormAF20ConfigItems } from '../../forms/form-af20/models/form-af20-config.model';
import { EasyFormAF21ConfigItems } from '../../forms/form-af21/models/form-af21-config.model';
import { EasyFormB1ConfigItems } from '../../forms/form-b1/models/form-b1-config.model';
import { EasyFormB2ConfigItems } from '../../forms/form-b2/models/form-b2-config.model';
import { EasyFormB3ConfigItems } from '../../forms/form-b3/models/form-b3-config.model';
import { EasyFormB4ConfigItems } from '../../forms/form-b4/models/form-b4-config.model';
import { EasyFormC01ConfigItems } from '../../forms/form-c01/models/form-c01-config.models';
import { EasyFormIb1ConfigItems } from '../../forms/form-ib1/models/form-ib1-config.model';
import { EasyFormK1ConfigItems } from '../../forms/form-k1/models/form-k1-config.model';
import { EasyFormK2ConfigItems } from '../../forms/form-k2/models/form-k2-config.model';
import { EasyFormK3ConfigItems } from '../../forms/form-k3/models/form-k3-config.model';
import { EasyFormM1ConfigItems } from '../../forms/form-m1/models/form-m1-config.model';
import { EasyFormS01ConfigItems } from '../../forms/form-s01/models/form-s01-config.models';
import { EasyFormS02ConfigItems } from '../../forms/form-s02/models/form-s02-config.models';
import { EasyFormU1ConfigItems } from '../../forms/form-u1/models/form-u1-config.model';
import { EasyFormW1ConfigItems } from '../../forms/form-w1/models/form-w1-config.model';
import { EasyFormW10ConfigItems } from '../../forms/form-w10/models/form-w10-config.model';
import { EasyFormW11ConfigItems } from '../../forms/form-w11/models/form-w11-config.model';
import { EasyFormW12ConfigItems } from '../../forms/form-w12/models/form-w12-config.model';
import { EasyFormW13ConfigItems } from '../../forms/form-w13/models/form-w13-config.model';
import { EasyFormW15ConfigItems } from '../../forms/form-w15/models/form-w15-config.model';
import { EasyFormW16ConfigItems } from '../../forms/form-w16/models/form-w16-config.model';
import { EasyFormW17ConfigItems } from '../../forms/form-w17/models/form-w17-config.model';
import { EasyFormW18ConfigItems } from '../../forms/form-w18/models/form-w18-config.model';
import { EasyFormW19ConfigItems } from '../../forms/form-w19/models/form-w19-config.model';
import { EasyFormW2ConfigItems } from '../../forms/form-w2/models/form-w2-config.model';
import { EasyFormW20ConfigItems } from '../../forms/form-w20/models/form-w20-config.model';
import { EasyFormW21ConfigItems } from '../../forms/form-w21/models/form-w21-config.model';
import { EasyFormW22ConfigItems } from '../../forms/form-w22/models/form-w22-config.model';
import { EasyFormW23ConfigItems } from '../../forms/form-w23/models/form-w23-config.model';
import { EasyFormW24ConfigItems } from '../../forms/form-w24/models/form-w24-config.model';
import { EasyFormW25ConfigItems } from '../../forms/form-w25/models/form-w25-config.model';
import { EasyFormW26ConfigItems } from '../../forms/form-w26/models/form-w26-config.model';
import { EasyFormW27ConfigItems } from '../../forms/form-w27/models/form-w27-config.model';
import { EasyFormW28ConfigItems } from '../../forms/form-w28/models/form-w28-config.model';
import { EasyFormW29ConfigItems } from '../../forms/form-w29/models/form-w29-config.model';
import { EasyFormW3ConfigItems } from '../../forms/form-w3/models/form-w3-config.model';
import { EasyFormW30ConfigItems } from '../../forms/form-w30/models/form-w30-config.model';
import { EasyFormW31ConfigItems } from '../../forms/form-w31/models/form-w31-config.model';
import { EasyFormW32ConfigItems } from '../../forms/form-w32/models/form-w32-config.model';
import { EasyFormW33ConfigItems } from '../../forms/form-w33/models/form-w33-config.model';
import { EasyFormW34ConfigItems } from '../../forms/form-w34/models/form-w34-config.model';
import { EasyFormW35ConfigItems } from '../../forms/form-w35/models/form-w35-config.model';
import { EasyFormW37ConfigItems } from '../../forms/form-w37/models/form-w37-config.model';
import { EasyFormW39ConfigItems } from '../../forms/form-w39/models/form-w39-config.model';
import { EasyFormW4ConfigItems } from '../../forms/form-w4/models/form-w4-config.model';
import { EasyFormW41ConfigItems } from '../../forms/form-w41/models/form-w41-config.model';
import { EasyFormW43ConfigItems } from '../../forms/form-w43/models/form-w43-config.model';
import { EasyFormW45ConfigItems } from '../../forms/form-w45/models/form-w45-config.model';
import { EasyFormW47ConfigItems } from '../../forms/form-w47/models/form-w47-config.model';
import { EasyFormW49ConfigItems } from '../../forms/form-w49/models/form-w49-config.model';
import { EasyFormW5ConfigItems } from '../../forms/form-w5/models/form-w5-config.model';
import { EasyFormW6ConfigItems } from '../../forms/form-w6/models/form-w6-config.model';
import { EasyFormW7ConfigItems } from '../../forms/form-w7/models/form-w7-config.model';
import { EasyFormW8ConfigItems } from '../../forms/form-w8/models/form-w8-config.model';
import { EasyFormW9ConfigItems } from '../../forms/form-w9/models/form-w9-config.model';
import { ConfigurationItems } from '../models/config-dto.model';

@Injectable({
  providedIn: 'root',
})
export class EasyFormConfigItemsFactoryService {
  private static readonly staticClassDictionary = {
    A1: EasyFormA1ConfigItems,
    A2: EasyFormA2ConfigItems,
    A3: EasyFormA3ConfigItems,
    A4: EasyFormA4ConfigItems,
    A5: EasyFormA5ConfigItems,
    B1: EasyFormB1ConfigItems,
    B2: EasyFormB2ConfigItems,
    B3: EasyFormB3ConfigItems,
    B4: EasyFormB4ConfigItems,
    IB1: EasyFormIb1ConfigItems,
    M1: EasyFormM1ConfigItems,
    AF01: EasyFormAF01ConfigItems,
    AF03: EasyFormAF03ConfigItems,
    AF04: EasyFormAF04ConfigItems,
    AF05: EasyFormAF05ConfigItems,
    AF06: EasyFormAF06ConfigItems,
    AF07: EasyFormAF07ConfigItems,
    AF08: EasyFormAF08ConfigItems,
    AF09: EasyFormAF09ConfigItems,
    AF10: EasyFormAF10ConfigItems,
    AF14: EasyFormAF14ConfigItems,
    AF15: EasyFormAF15ConfigItems,
    AF16: EasyFormAF16ConfigItems,
    AF17: EasyFormAF17ConfigItems,
    AF18: EasyFormAF18ConfigItems,
    AF19: EasyFormAF19ConfigItems,
    AF20: EasyFormAF20ConfigItems,
    AF21: EasyFormAF21ConfigItems,
    C01: EasyFormC01ConfigItems,
    K1: EasyFormK1ConfigItems,
    K3: EasyFormK3ConfigItems,
    S01: EasyFormS01ConfigItems,
    S02: EasyFormS02ConfigItems,
    K2: EasyFormK2ConfigItems,
    U1: EasyFormU1ConfigItems,
    W1: EasyFormW1ConfigItems,
    W2: EasyFormW2ConfigItems,
    W3: EasyFormW3ConfigItems,
    W4: EasyFormW4ConfigItems,
    W5: EasyFormW5ConfigItems,
    W6: EasyFormW6ConfigItems,
    W7: EasyFormW7ConfigItems,
    W8: EasyFormW8ConfigItems,
    W9: EasyFormW9ConfigItems,
    W10: EasyFormW10ConfigItems,
    W11: EasyFormW11ConfigItems,
    W12: EasyFormW12ConfigItems,
    W13: EasyFormW13ConfigItems,
    W15: EasyFormW15ConfigItems,
    W16: EasyFormW16ConfigItems,
    W17: EasyFormW17ConfigItems,
    W18: EasyFormW18ConfigItems,
    W19: EasyFormW19ConfigItems,
    W20: EasyFormW20ConfigItems,
    W21: EasyFormW21ConfigItems,
    W22: EasyFormW22ConfigItems,
    W23: EasyFormW23ConfigItems,
    W24: EasyFormW24ConfigItems,
    W25: EasyFormW25ConfigItems,
    W26: EasyFormW26ConfigItems,
    W27: EasyFormW27ConfigItems,
    W28: EasyFormW28ConfigItems,
    W29: EasyFormW29ConfigItems,
    W30: EasyFormW30ConfigItems,
    W31: EasyFormW31ConfigItems,
    W32: EasyFormW32ConfigItems,
    W33: EasyFormW33ConfigItems,
    W34: EasyFormW34ConfigItems,
    W35: EasyFormW35ConfigItems,
    W37: EasyFormW37ConfigItems,
    W39: EasyFormW39ConfigItems,
    W41: EasyFormW41ConfigItems,
    W43: EasyFormW43ConfigItems,
    W45: EasyFormW45ConfigItems,
    W47: EasyFormW47ConfigItems,
    W49: EasyFormW49ConfigItems,
  };

  /**
   * erstellt eine neue Formularkonfiguration anhand des Identifiers
   * @param identifier Formular-Identifier ('A1')
   */
  create(identifier: string): ConfigurationItems | {} {
    if (EasyFormConfigItemsFactoryService.staticClassDictionary[identifier]) {
      return new EasyFormConfigItemsFactoryService.staticClassDictionary[identifier]();
    }
    console.error(`EasyFormConfigItemsFactory.create(): identifier not found \'${identifier}\'`);
  }

  /**
   * löscht unpassende configurationItems (falsche @class)
   * @param identifier Form-Ifendifier
   * @param configurationItems Das zu erweiternde Objekt
   */
  clean(identifier: string, configurationItems: ConfigurationItems) {
    const defaultConfigItems = this.create(identifier);
    const items = {
      ...configurationItems,
    };
    for (const name of Object.keys(configurationItems)) {
      if (configurationItems[name]) {
        if (!defaultConfigItems[name] || configurationItems[name]['@class'] !== defaultConfigItems[name]['@class']) {
          console.warn(`@class misconfiguration detected in ${name}: ${items[name]} should be ${defaultConfigItems[name]} (fixed)`);
          delete items[name];
          continue;
        }
        for (const propName of Object.keys({...defaultConfigItems[name], ...configurationItems[name]})) {
          if (propName?.startsWith('$$')) {
            continue;
          }
          if (!(propName in configurationItems[name]) && defaultConfigItems[name][propName] !== undefined) {
            console.error(`missing prop detected: ${name}[${propName}] (fixed)`);
            items[name][propName] = defaultConfigItems[name][propName];
          }
        }
      }
    }
    return items;
  }

  /**
   * erweitert configurationItems um default werte
   * @param identifier Form-Ifendifier
   * @param configurationItems Das zu erweiternde Objekt
   */
  extend(identifier: string, configurationItems: ConfigurationItems) {
    const cleanedConfigurationItems = this.clean(identifier, configurationItems);
    const defaultConfigItems = this.create(identifier);
    const items = {
      ...cleanedConfigurationItems,
      ...defaultConfigItems,
    };
    for (const name of Object.keys(defaultConfigItems)) {
      if (cleanedConfigurationItems[name]) {
        items[name] = {
          ...items[name],
          ...cleanedConfigurationItems[name],
          translations: {
            ...items[name].translations,
            ...configurationItems[name].translations,
          },
        };
      }
    }
    return items;
  }
}
