<div (keyup.escape)="close()">
  <h1 mat-dialog-title class="search-modal">{{ 'templateDashboard' |i18n|async }}</h1>
  <div mat-dialog-content class="dialog-modal">
    <div>
      <mat-form-field  [style.width.%]="100">
        <input matInput
               [(ngModel)]="searchText"
        >
        <mat-icon
          matSuffix
        >search</mat-icon>
      </mat-form-field>
    </div>
    <mat-selection-list
      *ngIf="(hasTemplates$ | async); else noData"
      [multiple]="false"
    >
      <mat-list-option
        *ngFor="let template of (filteredTemplates$ | async)(searchText)"
        [routerLink]="getLink(template)"
        (click)="close()"
      >
        <mat-icon mat-list-icon>note</mat-icon>
        <div mat-line>
          {{ template.templateName }}
        </div>
      </mat-list-option>
    </mat-selection-list>
    <ng-template
      #noData
    >
      {{ 'noTemplatesForForm' | i18n | async }}
    </ng-template>
  </div>
  <div mat-dialog-actions class="actions" [align]="'center'">
    <button
      type="button"
      mat-raised-button
      (click)="close()"
    >
      {{ 'close' |i18n|async }}
    </button>
  </div>
</div>
