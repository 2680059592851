import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CategoryDataDto } from '../../../core-lib/models/category-data-dto.model';
import { FormCategoryTreeModel } from '../../../core-lib/models/form-category-tree.model';
import { ApiService } from '../../../core-lib/services/api.service';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { rxRoute } from '../../../core-lib/utils/reducer-utils';
import { CoreActionCategoriesLoaded } from '../actions/core-config.actions';
import { CoreFormCategorySetCategoriesAction } from '../actions/core-form-category.actions';
import { CoreFeatureState, getCompanyFormState, getI18nSelectedLanguage } from '../reducers/core.store';

@Injectable()
export class CoreFormCategoryEffect {
  selectedLanguage$ = this.store$.select(getI18nSelectedLanguage);
  companyFormState$ = this.store$.select(getCompanyFormState);

  deepMap(x: CategoryDataDto[]) {
    if (!x) {
      return undefined;
    }
    const retValue: FormCategoryTreeModel = {};
    for (const category of x) {
      retValue[category.name] = {
        isFavorite: undefined,
        ...category,
        childTrees: this.deepMap(category.childrenCategories),
        childForms: category.childrenForms?.map((c) => ({
          isTemplate: undefined,
          ...c,
        })),
      };
    }
    return retValue;
  }

  routeFormsCategory$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(
        rxRoute('forms'),
        filter(payload => !!payload.params.formsCategory),
        map((payload): string => payload.params.formsCategory)
      ),
      this.actions$.pipe(
        ofType<CoreActionCategoriesLoaded>(CoreActionCategoriesLoaded.TYPE),
        map((a) => a.categories),
      ),
    ]).pipe(
      map(([formsCategory, categories]) => ({
        formsCategory,
        categories,
      })),
    map(({formsCategory, categories}) => {
      const currentCategory = categories?.find((c) => c.name === formsCategory);
      return new CoreFormCategorySetCategoriesAction(this.deepMap([currentCategory]));
    }),
  ), defaultEffectOptions());

  constructor(
    private actions$: Actions,
    private store$: Store<CoreFeatureState>,
    private api: ApiService,
  ) {
  }
}
