<div *ngIf="!isHidden()">
  <h3>{{headline}}</h3>
  <lib-common-user-list
    [maxSize]="maxSize"
    [minSize]="neededCountOfApprovers"
    [layout]="getLayout()"
    [hasValueFn]="hasValueFn"
    [required]="isRequired()"
    [disabled]="isDisabled()"
    [removable]="removable"
    (removeClick)="removeClick.emit($event)"
    [valueConverter]="valueConverter"
    (addClick)="addClick.emit($event)"
    [addButtonColor]="addButtonColor"
    [asId]="asId"
    [asIdPropertyName]="asIdPropertyName"
    [enableable]="enableable"
    [enablePropertyName]="enablePropertyName"
    [enableTextI18n]="enableTextI18n"
    [approverControls]="approversControls"
    [configName]="configName"
    [placeholder]="getPlaceHolder()"
  ></lib-common-user-list>
</div>
