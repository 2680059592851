import { ConfigurationItems } from '../../../core-lib/models/config-dto.model';
import { ConfigItemConfigurationCategoryModel } from '../../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../../../core-lib/models/config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../../../core-lib/models/config-item-text-input-configuration-dto.model';
import { EqualKeyNames } from '../../../forms-lib/models/equal-key-names';
import { FormU1DtoModel } from './form-u1-dto.model';
import { ConfigItemDateConfigurationDtoModel } from '../../../core-lib/models/config-item-date-configuration-dto.model';
import { ConfigItemCheckboxConfigurationDtoModel } from '../../../core-lib/models/config-item-checkbox-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../../core-lib/models/config-item-arranger-list-configuration-dto.model';
import { ConfigItemFileUploadConfigurationDtoModel } from '../../../core-lib/models/config-item-file-upload-configuration-dto.model';
import { ConfigListDtoModel } from '../../../core-lib/models/config-list-dto.model';

export const EasyFormU1Steps = {
  step1: <ConfigItemConfigurationCategoryModel>{
    id: 'step1',
    displayName: 'Gesellschaft',
  },
  step2: <ConfigItemConfigurationCategoryModel>{
    id: 'step2',
    displayName: 'Belegauswahl',
  },
  step3: <ConfigItemConfigurationCategoryModel>{
    id: 'step3',
    displayName: 'Umbuchungsauftrag ',
  },
};

export class EasyFormU1ConfigItems extends ConfigurationItems implements EqualKeyNames<FormU1DtoModel> {

  /** step2 **/
  year = new ConfigItemNumberConfigurationDtoModel('year').withCategory(EasyFormU1Steps.step2);
  referenceNumber = new ConfigItemTextInputConfigurationDtoModel('referenceNumber').withCategory(EasyFormU1Steps.step2);

  /** step3 **/
  bookingDate = new ConfigItemDateConfigurationDtoModel('bookingDate').withCategory(EasyFormU1Steps.step3);
  description = new ConfigItemTextInputConfigurationDtoModel('transferText').withCategory(EasyFormU1Steps.step3);
  sendMail = new ConfigItemCheckboxConfigurationDtoModel('sendMail').withCategory(EasyFormU1Steps.step3);
  mailText = new ConfigItemTextInputConfigurationDtoModel('mailText').withCategory(EasyFormU1Steps.step3);

  // positions
  positionAccount = new ConfigListDtoModel('positions').withCategory(EasyFormU1Steps.step3);
  key = new ConfigItemNumberConfigurationDtoModel('key').withCategory(EasyFormU1Steps.step3);
  account = new ConfigItemTextInputConfigurationDtoModel('account').withCategory(EasyFormU1Steps.step3);
  netto = new ConfigItemNumberConfigurationDtoModel('netto').withCategory(EasyFormU1Steps.step3);
  type = new ConfigItemTextInputConfigurationDtoModel('type').withCategory(EasyFormU1Steps.step3);
  amount = new ConfigItemNumberConfigurationDtoModel('amount').withCategory(EasyFormU1Steps.step3);
  amountUnit = new ConfigItemTextInputConfigurationDtoModel('amountUnit').withCategory(EasyFormU1Steps.step3);
  tax = new ConfigItemTextInputConfigurationDtoModel('tax').withCategory(EasyFormU1Steps.step3);
  partnerId = new ConfigItemTextInputConfigurationDtoModel('partnerId').withCategory(EasyFormU1Steps.step3);
  costcenter = new ConfigItemTextInputConfigurationDtoModel('costcenter').withCategory(EasyFormU1Steps.step3);
  order = new ConfigItemTextInputConfigurationDtoModel('order').withCategory(EasyFormU1Steps.step3);
  psp = new ConfigItemTextInputConfigurationDtoModel('psp').withCategory(EasyFormU1Steps.step3);
  customerOrder = new ConfigItemTextInputConfigurationDtoModel('customerOrder').withCategory(EasyFormU1Steps.step3);
  customerSubOrderNr = new ConfigItemTextInputConfigurationDtoModel('customerSubOrderNr').withCategory(EasyFormU1Steps.step3);
  text = new ConfigItemTextInputConfigurationDtoModel('text').withCategory(EasyFormU1Steps.step3);

  // Genehmigungsstufen
  approver = new ConfigItemArrangerListConfigurationDtoModel('approver').withCategory(EasyFormU1Steps.step3);
  inspector = new ConfigItemArrangerListConfigurationDtoModel('inspector').withCategory(EasyFormU1Steps.step3);
  commentForApprover = new ConfigItemTextInputConfigurationDtoModel('commentForApprover').withCategory(EasyFormU1Steps.step3);

  file = new ConfigItemFileUploadConfigurationDtoModel('file').withCategory(EasyFormU1Steps.step3);

}
