import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { getRouterUrl } from '../../ngrx/reducers/router.reducer';
import { UrlTokenReplacerService } from '../../services/url-token-replacer.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-page-navbar-item',
  templateUrl: './page-navbar-item.component.html',
  styleUrls: ['./page-navbar-item.component.scss'],
})
export class PageNavbarItemComponent {
  @Input() src: string;
  @Input() highlightOnRouteMatch = true;
  @Input() showAsButton = false;

  @Input() darkmode = false;

  url$ = this.store$.select(getRouterUrl);
  isCurrentRoute$ = this.url$.pipe(
    map((url: string) => url && url.startsWith(this.src)),
  );

  constructor(
    private store$: Store<any>,
    private router: Router,
    private urlTokenReplacer: UrlTokenReplacerService,
  ) {
  }

  reject(event) {
    event.preventDefault();
  }

  linkClicked() {
    this.router.navigate([this.urlTokenReplacer.replace(this.src)]);
  }
}
