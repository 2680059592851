import { backendClasses } from './backend-classes.model';
import { ConfigItemListItemConfigurationDtoModel } from './config-item-list-configuration-dto.model';

export class ConfigItemArrangerConfigurationDtoModel extends ConfigItemListItemConfigurationDtoModel {
  static readonly CLASSNAME: string = backendClasses.arrangerConfiguration;
  static TYPENAME = 'typeArranger';
  '@class' = ConfigItemArrangerConfigurationDtoModel.CLASSNAME;
  $$typeName = ConfigItemArrangerConfigurationDtoModel.TYPENAME;

  $$ArrayId = Math.random();

  /** Beschreibung aus Backend
   * Art der Genehmiger / Prüfer konfiguration
   */
  type: 'ARBITRARY' | 'PRE_DEFINED' | 'GROUPED' = null;

  /** Beschreibung aus Backend
   * Wenn {@link ArrangerType#PRE_DEFINED} oder {@link ArrangerType#GROUPED} dann steht hier der Wert drinne
   */
  value: string = null;

  /** Beschreibung aus Backend
   * Ist diese Stufe nur aktive wenn kein bestimmter Schwellwert überschritten wurde
   */
  valueBased = false;

  /** Beschreibung aus Backend
   * Schwellwert
   */
  activationValue: number = null;

  /** Beschreibung aus Backend
   * Ist diese Stufe nur aktive wenn kein bestimmter Schwellwert überschritten wurde
   */
  countBased = false;

  /** Beschreibung aus Backend
   * Anzahl der Vorgänge
   */
  activationCount: number = null;

  /** Beschreibung aus Backend
   * Kann dieser Genehmiger die Daten im Vorgang bearbeiten
   */
  canEdit = false;

  constructor(
    attributeName: string,
    /** Beschreibung aus Backend
     * Property zur ermittelung der Reihenfolge-
     */
    public sortingNumber: number = null,
  ) {
    super(attributeName);

  }
}
