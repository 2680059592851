import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, TrackByFunction, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { AddArrayControlAction, FormControlState, FormGroupState, RemoveArrayControlAction } from 'ngrx-forms';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CompanyDataDtoModel } from '../../../core-lib/models/company-data-dto.model';
import { CateringOrderData } from '../../models/catering-order-data.model';
import { LocationInfo } from '../../models/location-info.model';
import { coreConfigCateringOrderDataLoad, coreConfigCateringOrderDataSave } from '../../ngrx/actions/core-config.actions';
import {
  getCompaniesState,
  getConfigLocationState,
  getConfigOrderDataFormOrderDataControls,
  getConfigOrderDataFormState,
} from '../../ngrx/reducers/core.store';

@Component({
  selector: 'lib-common-catering-order-data',
  templateUrl: './route-config-catering-order-data.component.html',
  styleUrls: ['./route-config-catering-order-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteConfigCateringOrderDataComponent implements OnInit, OnDestroy {
  companies$: Observable<CompanyDataDtoModel[]> = this.store$.select(getCompaniesState);

  locations$: Observable<LocationInfo[]> = this.store$.select(getConfigLocationState);

  formState$ = this.store$.select(getConfigOrderDataFormState);

  selectedCompany$: Observable<FormControlState<string>> = this.formState$.pipe(
    map(state => state.controls.selectedCompany),
  );

  @ViewChild('selectedCompany')
  selectedCompany: MatSelect;

  orderDataArrayState$: Observable<FormGroupState<CateringOrderData>[]> = this.store$.select(getConfigOrderDataFormOrderDataControls);

  private componentDestroyed$ = new EventEmitter();
  private arrayId: string;

  getControlId: TrackByFunction<FormGroupState<CateringOrderData>> = (i: number, t: FormGroupState<CateringOrderData>) => t.id;

  constructor(private store$: Store<any>) {
  }

  ngOnInit() {
    this.store$.select(getConfigOrderDataFormState)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(state => this.arrayId = state.controls.orderData.id);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  save() {
    this.store$.dispatch(coreConfigCateringOrderDataSave());
  }

  removeOrderData(index: number) {
    this.store$.dispatch(new RemoveArrayControlAction(this.arrayId, index));
  }

  addOrderData() {
    this.store$.dispatch(new AddArrayControlAction(this.arrayId, new CateringOrderData(this.selectedCompany.value)));
  }

  loadCateringOrderData() {
    this.store$.dispatch(coreConfigCateringOrderDataLoad());
  }
}
