import { Action, createAction } from '@ngrx/store';
import { CoreAccountDeputiesActionsType } from './core-account-deputies.actions';
import { CoreAccountActions } from './core-account.actions';
import { CoreCompanyFormsActions } from './core-company-forms.actions';
import { CoreConfigActions } from './core-config.actions';

export class CoreActionInit implements Action {
  static readonly TYPE = 'core/Init';
  readonly type = CoreActionInit.TYPE;
}

export class CoreActionReady implements Action {
  static readonly TYPE = 'core/Ready';
  readonly type = CoreActionReady.TYPE;
}

/**
 * Action für die Automatisch eine allgemeine Fehlermeldung angezeigt werden soll.
 * @Documented
 */
export class ApiErrorAction implements Action {
  static readonly TYPE: string = 'ApiErrorAction';
  type = ApiErrorAction.TYPE;
  isError = true;

  constructor(public e: Error & { [k: string]: any }, public message: string, public snackBarDuration = 5000) {
  }
}

/**
 * Action für die Automatisch eine Erfolgsmeldung angezeigt werden soll.
 * @Documented
 */
export class ApiSuccessAction implements Action {
  static readonly TYPE: string = 'ApiSuccessAction';
  type = ApiSuccessAction.TYPE;
  isSuccess = true;
  messageKey: string;
  snackBarDuration: number;
}

export class ConcreteApiSuccessAction extends ApiSuccessAction {
  constructor(public messageKey: string, public snackBarDuration = 5000) {
    super();
  }
}

/**
 * Action für die Automatisch eine Warnmeldung angezeigt werden soll.
 * @Documented
 */
export class ApiWarnAction implements Action {
  static readonly TYPE: string = 'ApiWarnAction';
  type = ApiWarnAction.TYPE;
  messageKey: string;
  snackBarDuration: number;
}

export class ConcreteApiWarnAction extends ApiWarnAction {
  constructor(public messageKey: string, public snackBarDuration = 5000) {
    super();
  }
}

export const resetFormAction = createAction('core/reset/forms');

export type CoreActions =
  CoreActionInit |
  CoreActionReady |
  CoreAccountActions |
  CoreCompanyFormsActions |
  CoreConfigActions |
  CoreAccountDeputiesActionsType;
