import { formatNumber } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { ConfigItemDaterangeConfigurationDtoModel } from '../../../core-lib/models/config-item-daterange-configuration-dto.model';
import {
  coreDashboardUpdatePayoutAction,
  coreDashboardUpdateStateCountAction,
  coreDashboardUpdateThroughputAction,
} from '../../ngrx/actions/dashboard.actions';
import { CoreFeatureState, getI18nSelectedLanguage } from '../../ngrx/reducers/core.store';
import {
  getDashboardPayoutFormFromState,
  getDashboardPayoutFormToState,
  getDashboardPayoutState,
  getDashboardStateCountFormFromState,
  getDashboardStateCountFormToState,
  getDashboardStateCountState,
  getDashboardStateTotalCountState,
  getDashboardThroughputFormFromState,
  getDashboardThroughputFormToState,
  getDashboardThroughputState,
} from '../../ngrx/reducers/dashboard.store';

@Component({
  selector: 'lib-common-route-config-dashboard',
  templateUrl: './route-config-dashboard.component.html',
  styleUrls: ['./route-config-dashboard.component.scss'],
})
export class RouteConfigDashboardComponent implements OnInit {
  dashboardStateCountState$ = this.store$.select(getDashboardStateCountState);
  dashboardStateTotalCountState$ = this.store$.select(getDashboardStateTotalCountState);
  dashboardStateCountFormFromState$ = this.store$.select(getDashboardStateCountFormFromState);
  dashboardStateCountFormToState$ = this.store$.select(getDashboardStateCountFormToState);

  dashboardThroughputState$ = this.store$.select(getDashboardThroughputState);
  dashboardThroughputFormFromState$ = this.store$.select(getDashboardThroughputFormFromState);
  dashboardThroughputFormToState$ = this.store$.select(getDashboardThroughputFormToState);

  dashboardPayoutState$ = this.store$.select(getDashboardPayoutState);
  getDashboardPayoutFormFromState$ = this.store$.select(getDashboardPayoutFormFromState);
  dashboardPayoutFormToState$ = this.store$.select(getDashboardPayoutFormToState);

  dashboardThroughputFinished$ = this.dashboardThroughputState$.pipe(
    map(data => data.FINISHED),
    map((data) => moment.duration(data?.meanTime, 'ms').locale('de').humanize({d: 7, w: 4})),
  );
  dashboardThroughputStateData$ = this.dashboardThroughputState$.pipe(
    map((data) => Object.entries(data)
      .filter(([key, value]) => key !== 'FINISHED')
      .map(([key, value]) => [key, value.meanTime])
      .reduce((prev, [key, value]) => ({...prev, [key]: value}), {})),
  );

  get tabIndex(): number {
    return this._tabIndex;
  }

  set tabIndex(value: number) {
    switch (value) {
      case 0: this.updateThroughput();  break;
      case 1: this.updateProposalCount(); break;
      case 2: this.updatePayout(); break;
    }
    this._tabIndex = value;
  }

  private _tabIndex: number;

  dashboardDateRangeConfig: ConfigItemDaterangeConfigurationDtoModel = {
    ...new ConfigItemDaterangeConfigurationDtoModel(''),
    endDateVisible: true,
    endTimeVisible: false,
    startTimeVisible: false,
    startDateTranslations: {
      de: {text: 'Startdatum'},
      en: {text: 'start date'},
    },
    endDateTranslations: {
      de: {text: 'Enddatum'},
      en: {text: 'end date'},
    },
  };
  dashboardStartDateLabel$ = this.store$.select(getI18nSelectedLanguage).pipe(
    map((lang) => this.dashboardDateRangeConfig.startDateTranslations[lang].text),
  );
  dashboardEndDateLabel$ = this.store$.select(getI18nSelectedLanguage).pipe(
    map((lang) => this.dashboardDateRangeConfig.endDateTranslations[lang].text),
  );

  constructor(
    private store$: Store<CoreFeatureState>,
  ) {}

  ngOnInit() {
    this.tabIndex = 0;
  }

  updateProposalCount() {
    this.store$.dispatch(coreDashboardUpdateStateCountAction());
  }

  updateThroughput() {
    this.store$.dispatch(coreDashboardUpdateThroughputAction());
  }

  updatePayout() {
    this.store$.dispatch(coreDashboardUpdatePayoutAction());
  }

  throughputValueFormatter = (value: number) => moment.duration(value).locale('de').humanize({s: 60, m: 60, h: 24, d: 31, M: 12});
  lineValueFormatter = (value: number) => formatNumber(value, 'de-DE', '1.0-2');
}
