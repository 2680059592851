import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { debounceTime, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from '../../../core-lib/services/api.service';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { rxCatchApiError } from '../../../core-lib/utils/reducer-utils';
import { proposalTableActions } from '../actions/core-overview-table.actions';
import { ConcreteApiSuccessAction } from '../actions/core.actions';
import { CoreFeatureState, getI18nSelectedLanguage } from '../reducers/core.store';
import { SessionSpinnerClose, SessionSpinnerOpen } from '../../../session/actions/session.actions';


@Injectable()
export class CoreProposalTableEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store$: Store<CoreFeatureState>,
  ) {
  }

  loadProposalPage$ = createEffect(() => this.actions$.pipe(
    ofType(proposalTableActions.tableLoad),
    debounceTime(200),
    switchMap((sortFilterPageConfig) => this.api.getTablePage(sortFilterPageConfig, 'process').pipe(
      map((page) => proposalTableActions.tableLoaded({...page, selection: sortFilterPageConfig.selection})),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  exportProposalPage$ = createEffect(() => this.actions$.pipe(
    ofType(proposalTableActions.tableExport),
    withLatestFrom(
      this.store$.select(getI18nSelectedLanguage),
      (sortFilterPageConfig, selectedLanguage) => ({sortFilterPageConfig, selectedLanguage}),
    ),
    switchMap(({sortFilterPageConfig, selectedLanguage}) => this.api.getTableExport(sortFilterPageConfig, selectedLanguage, 'process')),
  ), defaultEffectOptions({dispatch: false}));

  loadProposalTableColumnConfig$ = createEffect(() => this.actions$.pipe(
    ofType(proposalTableActions.tableConfigLoad),
    mergeMap((action) => this.api.getUserTableColumnConfig(action.selection).pipe(
      map((configs) => proposalTableActions.tableColumnConfigLoaded({data: configs, selection: action.selection})),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  loadProposalTableFilterConfig$ = createEffect(() => this.actions$.pipe(
    ofType(proposalTableActions.tableConfigLoad),
    mergeMap((action) => this.api.getUserTableFilterConfig(action.selection).pipe(
      map((configs) => proposalTableActions.tableFilterConfigLoaded({data: configs, selection: action.selection})),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());


  saveProposalColumnConfig$ = createEffect(() => this.actions$.pipe(
    ofType(proposalTableActions.tableConfigSave),
    switchMap((tableConfig) => this.api.postUserTableColumnConfig(tableConfig).pipe(
      tap((savedTableConfig) => this.store$.dispatch(proposalTableActions.tableColumnConfigLoaded({
        data: savedTableConfig,
        selection: tableConfig.tableName,
      }))),
      map(() => new ConcreteApiSuccessAction('tableConfigSaved')),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  saveProposalFilterConfig$ = createEffect(() => this.actions$.pipe(
    ofType(proposalTableActions.tableConfigSave),
    switchMap((tableConfig) => this.api.postUserTableFilterConfig(tableConfig).pipe(
      tap((savedTableConfig) => this.store$.dispatch(proposalTableActions.tableFilterConfigLoaded({
        data: savedTableConfig,
        selection: tableConfig.tableName,
      }))),
      map(() => new ConcreteApiSuccessAction('tableConfigSaved')),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  sendProposal$ = createEffect(() => this.actions$.pipe(
    ofType(proposalTableActions.tableSendAction),
    tap(() => this.store$.dispatch(new SessionSpinnerOpen('sendSpinner', Number.MAX_VALUE))),
    switchMap((action) => this.api.postProcessSend(action.publicId).pipe(
      switchMap(() => ([proposalTableActions.tableLoad(action.config), new ConcreteApiSuccessAction('formSendSuccess')])),
      rxCatchApiError({}),
    )),
    tap(() => this.store$.dispatch(new SessionSpinnerClose('sendSpinner'))),
  ), defaultEffectOptions());

  rejectProposal$ = createEffect(() => this.actions$.pipe(
    ofType(proposalTableActions.tableDeclineAction),
    switchMap((action) => this.api.postProcessReject(action.publicId, action.comment).pipe(
      switchMap(() => ([proposalTableActions.tableLoad(action.config), new ConcreteApiSuccessAction('formRejectSuccess')])),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  approveProposal$ = createEffect(() => this.actions$.pipe(
    ofType(proposalTableActions.tableApproveAction),
    tap(() => this.store$.dispatch(new SessionSpinnerOpen('approveSpinner', Number.MAX_VALUE))),
    switchMap((action) => this.api.postProcessApprove(action.publicId).pipe(
      switchMap(() => ([proposalTableActions.tableLoad(action.config), new ConcreteApiSuccessAction('formApprovedSuccess')])),
      rxCatchApiError({}),
    )),
    tap(() => this.store$.dispatch(new SessionSpinnerClose('approveSpinner'))),
  ), defaultEffectOptions());

  rejectMultipleProposal$ = createEffect(() => this.actions$.pipe(
    ofType(proposalTableActions.tableDeclineMultipleAction),
    tap(() => this.store$.dispatch(new SessionSpinnerOpen('rejectSpinner'))),
    mergeMap((action) => this.api.postProcessReject(action.publicId, action.comment).pipe(
      switchMap(() => ([proposalTableActions.tableLoad(action.config), new ConcreteApiSuccessAction('formRejectSuccess')])),
      rxCatchApiError({}),
    )),
    tap(() => this.store$.dispatch(new SessionSpinnerClose('rejectSpinner'))),
  ), defaultEffectOptions());

  approveMultipleProposal$ = createEffect(() => this.actions$.pipe(
    ofType(proposalTableActions.tableApproveMultipleAction),
    tap(() => this.store$.dispatch(new SessionSpinnerOpen('approveSpinner', Number.MAX_VALUE))),
    mergeMap((action) => this.api.postProcessApprove(action.publicId).pipe(
      switchMap(() => ([proposalTableActions.tableLoad(action.config), new ConcreteApiSuccessAction('formApprovedSuccess')])),
      rxCatchApiError({}),
    )),
    tap(() => this.store$.dispatch(new SessionSpinnerClose('approveSpinner'))),
  ), defaultEffectOptions());

  deleteProposal$ = createEffect(() => this.actions$.pipe(
    ofType(proposalTableActions.tableDeleteAction),
    switchMap((action) => this.api.deleteProcess(action.publicId).pipe(
      switchMap(() => ([proposalTableActions.tableLoad(action.config), new ConcreteApiSuccessAction('deleteProposalData')])),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  recallProposal$ = createEffect(() => this.actions$.pipe(
    ofType(proposalTableActions.tableRecallAction),
    switchMap((action) => this.api.postProcessRecall(action.publicId).pipe(
      switchMap(() => ([proposalTableActions.tableLoad(action.config), new ConcreteApiSuccessAction('recallProposalSuccess')])),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());
}
