import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { box, FormGroupState, MarkAsUnsubmittedAction } from 'ngrx-forms';
import { of } from 'rxjs';
import { catchError, filter, map, skipUntil, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CompanyDataDtoModel } from '../../../core-lib/models/company-data-dto.model';
import { UserDataFormModel } from '../../../core-lib/models/user-data-form.model';
import { ApiService } from '../../../core-lib/services/api.service';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { rxCatchApiError, rxRoute } from '../../../core-lib/utils/reducer-utils';
import { safeUnbox } from '../../../core-lib/utils/safe-unbox';
import { CoreAccountFormActionLoadedUserData } from '../actions/core-account.actions';
import {
  CoreCompanyFormActionDelete,
  CoreCompanyFormActionLoadCompany,
  CoreCompanyFormActionLoadedCompanies,
  CoreCompanyFormActionSave,
  CoreCompanyFormActionSaveError,
  CoreCompanyFormActionSaveSuccess,
} from '../actions/core-company-forms.actions';
import {
  CoreActionLoadAllTranslations,
  CoreActionLoadedAllTranslationsSuccess,
  DeleteConfigItemError,
  DeleteConfigItemSuccess,
} from '../actions/core-config.actions';
import { CoreActionInit, CoreActionReady } from '../actions/core.actions';
import { RouterActionNavigate } from '../actions/router.actions';
import {
  CoreFeatureState,
  getAccountFeatureState,
  getCompanyByIdState,
  getCompanyFormState,
  getI18nSelectedLanguage,
} from '../reducers/core.store';

@Injectable()
export class CoreCompanyEffects {
  selectedLanguage$ = this.store$.select(getI18nSelectedLanguage);
  companyFormState$ = this.store$.select(getCompanyFormState);

  companyFormUpdateCompanies$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActionInit.TYPE),
    switchMap(() => this.api.getAllCompanies().pipe(
      withLatestFrom(this.store$.select(getAccountFeatureState)),
      map(([companies, accountState]) => ({
        companies,
        accountState: accountState as FormGroupState<UserDataFormModel<string | CompanyDataDtoModel>>,
      })),
      tap(({companies, accountState}) => {
        if (accountState) {
          let id = safeUnbox(accountState.value.defaultCompany);
          if (typeof id !== 'string') {
            id = id?.id;
          }
          const defaultCompany = id ? companies.find((c) => c.id === id) : undefined;
          this.store$.dispatch(new CoreAccountFormActionLoadedUserData({
            ...accountState.value,
            defaultCompany: defaultCompany ? box(defaultCompany) : undefined,
          }));
        }
      }),
      map(({companies}) => new CoreCompanyFormActionLoadedCompanies(companies)),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  companyFormLoadNewCompany$ = createEffect(() => this.actions$.pipe(
    rxRoute('config/company'),
    filter(payload => !payload.params.id),
    map(() => new CoreCompanyFormActionLoadCompany(undefined)),
  ), defaultEffectOptions());

  initTranslations$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActionInit.TYPE),
    map(() => new CoreActionLoadAllTranslations()),
  ), defaultEffectOptions());

  loadAllTranslations$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActionInit.TYPE),
    withLatestFrom(this.selectedLanguage$),
    switchMap(([other, selectedLanguage]) => this.api.getTranslations(selectedLanguage).pipe(
      map(translations => new CoreActionLoadedAllTranslationsSuccess(translations, selectedLanguage)),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  companyFormLoadOnFirstNavigation$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActionReady.TYPE),
    withLatestFrom(this.actions$.pipe(
      rxRoute('config/company'),
      filter((payload: any) => payload.params.id),
    )),
    switchMap(([x, payload]) => this.store$.select(getCompanyByIdState, {id: payload.params.id})),
    map((company: CompanyDataDtoModel) => company ? new CoreCompanyFormActionLoadCompany(company) : new RouterActionNavigate({
      path: 'config/company',
      params: {},
      data: {},
    })),
  ), defaultEffectOptions());

  companyFormLoadCompany$ = createEffect(() => this.actions$.pipe(
    rxRoute('config/company'),
    skipUntil(this.actions$.pipe(
      ofType(CoreActionReady.TYPE),
    )),
    // :id is present
    filter((payload: any) => payload.params.id),
    // get company from store with payload.params.id == shortName
    switchMap(payload => this.store$.select(getCompanyByIdState, {id: payload.params.id})),
    // company exists: load company
    // company does not exist: forward to 'config/company' without :id
    map((company: CompanyDataDtoModel) => company ? new CoreCompanyFormActionLoadCompany(company) : new RouterActionNavigate({
      path: 'config/company',
      params: {},
      data: {},
    })),
  ), defaultEffectOptions());

  companyFormSave$ = createEffect(() => this.actions$.pipe(
    ofType(CoreCompanyFormActionSave.TYPE),
    withLatestFrom(this.companyFormState$),
    switchMap(([action, companyFormState]: [CoreCompanyFormActionSave, FormGroupState<CompanyDataDtoModel>]) => this.api.postCompany(
      companyFormState.value).pipe(
      map((company: CompanyDataDtoModel) => new CoreCompanyFormActionSaveSuccess(company)),
      catchError((e) => of(new CoreCompanyFormActionSaveError(e, e.message))),
    )),
  ), defaultEffectOptions());

  companyFormDelete$ = createEffect(() => this.actions$.pipe(
    ofType(CoreCompanyFormActionDelete.TYPE),
    switchMap((action: CoreCompanyFormActionDelete) => this.api.deleteCompany(action.company).pipe(
      map(() => new DeleteConfigItemSuccess()),
      catchError(e => of(new DeleteConfigItemError(e, e.message))),
    )),
  ), defaultEffectOptions());

  companyFormSaveError$ = createEffect(() => this.actions$.pipe(
    ofType(CoreCompanyFormActionSaveError.TYPE),
    withLatestFrom(this.companyFormState$),
    map(([
           action,
           companyFormState,
         ]: [
      CoreCompanyFormActionSaveError,
      FormGroupState<CompanyDataDtoModel>
    ]) => new MarkAsUnsubmittedAction(companyFormState.id)),
  ), defaultEffectOptions());

  companyFormSaveSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CoreCompanyFormActionSaveSuccess.TYPE),
    map((a: CoreCompanyFormActionSaveSuccess) => new RouterActionNavigate({
      params: {},
      data: {},
      path: 'config/companies',
    })),
  ), defaultEffectOptions({dispatch: false}));

  constructor(
    private actions$: Actions,
    private router: Router,
    private store$: Store<CoreFeatureState>,
    private api: ApiService,
  ) {
  }
}
