<div (keyup.control.enter)="close(eventTypes.ACCEPTED)" (keyup.escape)="close(eventTypes.DECLINED)">
  <h1 mat-dialog-title class="search-modal">{{ title |i18n|async }}</h1>
  <div mat-dialog-content class="dialog-modal" [innerHTML]="text | i18n:replacements | async | safe: 'html'">
  </div>
  <div>
    <mat-form-field class="reject-input">
      <textarea
        *ngIf="textarea"
        matInput
        [(ngModel)]=message
        maxlength="4000"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
      ></textarea> <input *ngIf="!textarea" matInput [(ngModel)]=message>
    </mat-form-field>
  </div>
  <div class="margin-infotext">
    <p>{{ info |i18n|async}}</p>
  </div>
  <div mat-dialog-actions>
    <button color="accent" mat-raised-button (click)="close(eventTypes.DECLINED)" cdkFocusInitial>Abbrechen</button>
    <button color="primary" mat-raised-button (click)="close(eventTypes.ACCEPTED)">Fertig</button>
  </div>
</div>
