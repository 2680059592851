import { createReducer, createSelector, on } from '@ngrx/store';
import { ProcessQueueModel } from '../../models/process-queue.model';
import { loadProcessQueueSuccessAction } from '../actions/queue.actions';
import { getEasyFormsCoreFeatureState } from './easy-forms.reducer';


export interface ProcessQueueState {
  queue: ProcessQueueModel[];
}

export const initialState: ProcessQueueState = {
  queue: [],
};

export const queueReducer = createReducer(
  initialState,
  on(loadProcessQueueSuccessAction, (s, {data}) => ({
    queue: data,
  })),
);


// selectors
export const getQueueState = createSelector(
  getEasyFormsCoreFeatureState,
  (s) => s?.queue,
);


export const getProcessQueueData = createSelector(
  getQueueState,
  s => s?.queue,
);
