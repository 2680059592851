import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, Injector } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, share, take, tap } from 'rxjs/operators';
import { defaultAppConfig } from '../../core-lib/config/default-app-config';
import AppConfigModel from '../../core-lib/models/app-config.model';

@Injectable()
export class AppConfigService {

  static PROVIDERS = [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigServiceFactory,
      multi: true,
      deps: [AppConfigService],
    },
  ];

  private appConfig: AppConfigModel;
  private subject = new BehaviorSubject<AppConfigModel>(defaultAppConfig);

  public configObservable = this.subject.asObservable();

  constructor(private injector: Injector) {
  }

  loadAppConfig() {
    const http = this.injector.get(HttpClient);

    const httpResponse = http.get<AppConfigModel>('/assets/app-config.json').pipe(
      share(),
      map(data => {
        return {
          ...defaultAppConfig,
          ...data,
        };
      }),
      catchError((e) => {
        console.warn('Error loading app-config.json, using default config instead', e);
        return of(defaultAppConfig);
      }),
      tap((data) => {
        this.appConfig = data;
      }),
    );

    httpResponse.subscribe((data) => this.subject.next(data));

    return httpResponse.pipe(
      take(1),
    ).toPromise();
  }

  get config() {
    return this.appConfig;
  }
}

export function appConfigServiceFactory(appConfig: AppConfigService) {
  return () => {
    return appConfig.loadAppConfig();
  };
}
