<lib-common-page-margin (keyup.control.shift.f)="showSearch = true">
  <mat-form-field
    *ngIf="showSearch"
  >
    <input
      type="text"
      matInput
      [(ngModel)]="searchQuery"
    >
    <mat-icon svgIcon="innogy:magnifier" matPrefix></mat-icon>
  </mat-form-field>
  <div class="category-margin-bottom">
    <div *ngIf="(childForms$ | async) === undefined">
      {{ 'emptyDashboardForm' | i18n | async }}
    </div>
    <lib-common-category-tree-element
      [childForms]="childForms$ | async"
      [childTrees]="childTrees$ | async"
      [childLinks]="childLinks$ | async"
      [userRoles]="userRoles$ | async"
      [getRouterLink]="getRouterLink"
      (addFavorite)="addFavorite($event)"
      (deleteFavorite)="deleteFavorite($event)"
      (addTemplate)="addTemplate($event)"
      (deleteTemplate)="deleteTemplate($event.form, $event.templateName)"
      (ocrScan)="ocrScan($event)"
      (ocrCam)="ocrCam($event)"
      (openInfoDialog)="openInfoDialog($event.$event, $event.tree)"
      (openTemplateDialog)="openTemplateDialog($event)"
      [showInfoHint]="showInfoHintFn$ | async"
    ></lib-common-category-tree-element>
  </div>
</lib-common-page-margin>
