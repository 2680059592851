import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-info-bar',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.scss']
})
export class InfoBarComponent {
  @ViewChild('htmlElement', {static: true}) htmlElement: ElementRef;
  @Input() header: string;
  @Input() set html(value: string) {
    if (value !== undefined) {
      this.htmlElement.nativeElement.innerHTML = value;
    }
  }
  get html() {
    return this.htmlElement.nativeElement.innerHTML;
  }
}
