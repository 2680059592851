import { Action } from '@ngrx/store';
import { TileModel } from '../../../core-lib/models/tile.model';
import { FormA1DtoModel } from '../../../forms/form-a1/models/form-a1-dto.model';
import { ApiErrorAction, ApiSuccessAction } from './core.actions';


export class TemplateActionGet implements Action {
  static readonly TYPE = 'template/Get';
  readonly type = TemplateActionGet.TYPE;
}

export class TemplateActionGetSuccess implements Action {
  static readonly TYPE = 'template/GetSuccess';
  readonly type = TemplateActionGetSuccess.TYPE;

  constructor(public templates: FormA1DtoModel[]) {
  }
}

export class TemplateActionSetCollapsed implements Action {
  static readonly TYPE = 'template/SetCollapsed';
  readonly type = TemplateActionSetCollapsed.TYPE;

  constructor(public collapsed: boolean) {
  }
}

export class TemplateActionAdd implements Action {
  static readonly TYPE = 'template/Add';
  readonly type = TemplateActionAdd.TYPE;

  constructor(public id: string) {
  }
}

export class TemplateActionDelete implements Action {
  static readonly TYPE = 'template/Delete';
  readonly type = TemplateActionDelete.TYPE;

  constructor(public identifier: string, public templateName: string) {
  }
}

export class TemplateActionSaveOrder implements Action {
  static readonly TYPE = 'template/order';
  readonly type = TemplateActionSaveOrder.TYPE;

  constructor(public data: TileModel[]) {
  }
}

export class TemplateActionSaveOrderSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/template/orderSuccess';
  messageKey = 'viewSaveSuccess';
  readonly type = TemplateActionSaveOrderSuccess.TYPE;
  snackBarDuration = 5000;
}

export class TemplateActionSaveSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/template/SaveSuccess';
  messageKey = 'templateActionSaveSuccess';
  readonly type = TemplateActionSaveSuccess.TYPE;
  snackBarDuration = 5000;
}

export class TemplateActionDeleteSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/template/DeleteSuccess';
  messageKey = 'templateActionDeleteSuccess';
  readonly type = TemplateActionDeleteSuccess.TYPE;
  snackBarDuration = 5000;
}

export class TemplateActionError extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/template/SaveError';
  readonly type = TemplateActionError.TYPE;
}

export type TemplateAction =
  TemplateActionGet |
  TemplateActionGetSuccess |
  TemplateActionAdd |
  TemplateActionDelete |
  TemplateActionSaveSuccess |
  TemplateActionDeleteSuccess |
  TemplateActionError |
  TemplateActionSetCollapsed;
