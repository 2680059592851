import { ConfigurationItems } from '../../../core-lib/models/config-dto.model';
import { ConfigItemAccountingConfigurationDtoModel } from '../../../core-lib/models/config-item-accounting-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../../core-lib/models/config-item-arranger-list-configuration-dto.model';
import { ConfigItemCheckboxConfigurationDtoModel } from '../../../core-lib/models/config-item-checkbox-configuration-dto.model';
import { ConfigItemConfigurationCategoryModel } from '../../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemDateConfigurationDtoModel } from '../../../core-lib/models/config-item-date-configuration-dto.model';
import { ConfigItemFileUploadConfigurationDtoModel } from '../../../core-lib/models/config-item-file-upload-configuration-dto.model';
import { ConfigItemListConfigurationDtoModel } from '../../../core-lib/models/config-item-list-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../../../core-lib/models/config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../../../core-lib/models/config-item-text-input-configuration-dto.model';
import { ConfigItemTextareaInputConfigurationDtoModel } from '../../../core-lib/models/config-item-textarea-input-configuration-dto.model';
import { EqualKeyNames } from '../../../forms-lib/models/equal-key-names';
import { PayeeDtoModel } from '../../all-forms/models/payee-dto.model';
import { FormW2DtoModel } from './form-w2-dto.model';

export const EasyFormW2Steps = {
  step1: <ConfigItemConfigurationCategoryModel>{
    id: 'step1',
    displayName: 'Gesellschaft',
  },
  step2: <ConfigItemConfigurationCategoryModel>{
    id: 'step2',
    displayName: 'Auftraggeber, -nehmer',
  },
  step3: <ConfigItemConfigurationCategoryModel>{
    id: 'step3',
    displayName: 'Detailangaben',
  },
};

export class EasyFormW2ConfigItems extends ConfigurationItems implements EqualKeyNames<FormW2DtoModel>, EqualKeyNames<PayeeDtoModel> {
  /** step2 **/
    // Netzregion
  netRegion = new ConfigItemTextInputConfigurationDtoModel('netRegion').withCategory(EasyFormW2Steps.step2);
  // Servicecenter
  serviceCenter = new ConfigItemTextInputConfigurationDtoModel('serviceCenter').withCategory(EasyFormW2Steps.step2);
  // Account zum Servicecenter
  serviceCenterAccount = new ConfigItemAccountingConfigurationDtoModel('serviceCenterAccount').withCategory(EasyFormW2Steps.step2);

  /** Auftraggeber **/
    // Steuernummer
  taxNumber = new ConfigItemTextInputConfigurationDtoModel('taxNumber').withCategory(EasyFormW2Steps.step2);
  // Ust-ID
  ustId = new ConfigItemTextInputConfigurationDtoModel('ustId').withCategory(EasyFormW2Steps.step2);
  // Debitor
  payeeNumber = new ConfigItemTextInputConfigurationDtoModel('payeeNumber').withCategory(EasyFormW2Steps.step2);

  // PayeeDtoModel
  salutation = new ConfigItemTextInputConfigurationDtoModel('salutation').withCategory(EasyFormW2Steps.step2);
  name = new ConfigItemTextInputConfigurationDtoModel('name').withCategory(EasyFormW2Steps.step2);
  nameAddition = new ConfigItemTextInputConfigurationDtoModel('nameAddition').withCategory(EasyFormW2Steps.step2);
  street = new ConfigItemTextInputConfigurationDtoModel('street').withCategory(EasyFormW2Steps.step2);
  zipCode = new ConfigItemTextInputConfigurationDtoModel('zipCode').withCategory(EasyFormW2Steps.step2);
  city = new ConfigItemTextInputConfigurationDtoModel('city').withCategory(EasyFormW2Steps.step2);
  country = new ConfigItemTextInputConfigurationDtoModel('country').withCategory(EasyFormW2Steps.step2);
  email = new ConfigItemTextInputConfigurationDtoModel('email').withCategory(EasyFormW2Steps.step2);
  phone = new ConfigItemTextInputConfigurationDtoModel('phone').withCategory(EasyFormW2Steps.step2);
  iban = new ConfigItemTextInputConfigurationDtoModel('iban').withCategory(EasyFormW2Steps.step2);
  bic = new ConfigItemTextInputConfigurationDtoModel('bic').withCategory(EasyFormW2Steps.step2);

  // Abweichende Rechnungsadresse (aus W2)
  differentBillingAddress = new ConfigItemCheckboxConfigurationDtoModel('differentBillingAddress').withCategory(EasyFormW2Steps.step2);
  nameBA = new ConfigItemTextInputConfigurationDtoModel('nameBA').withCategory(EasyFormW2Steps.step2);
  nameAdditionBA = new ConfigItemTextInputConfigurationDtoModel('nameAdditionBA').withCategory(EasyFormW2Steps.step2);
  streetBA = new ConfigItemTextInputConfigurationDtoModel('streetBA').withCategory(EasyFormW2Steps.step2);
  zipCodeBA = new ConfigItemTextInputConfigurationDtoModel('zipCodeBA').withCategory(EasyFormW2Steps.step2);
  cityBA = new ConfigItemTextInputConfigurationDtoModel('cityBA').withCategory(EasyFormW2Steps.step2);

  /** Auftragsort **/
    // Straße
  streetOA = new ConfigItemTextInputConfigurationDtoModel('streetOA').withCategory(EasyFormW2Steps.step2);
  // PLZ
  zipCodeOA = new ConfigItemTextInputConfigurationDtoModel('zipCodeOA').withCategory(EasyFormW2Steps.step2);
  // Ort
  cityOA = new ConfigItemTextInputConfigurationDtoModel('cityOA').withCategory(EasyFormW2Steps.step2);
  // Ortsteil
  districtOA = new ConfigItemTextInputConfigurationDtoModel('districtOA').withCategory(EasyFormW2Steps.step2);
  // gewünschter Zeitraum
  timeOA = new ConfigItemTextInputConfigurationDtoModel('timeOA').withCategory(EasyFormW2Steps.step2);

  /** step3 **/
    // Leistungsbeschreibung
  useCase = new ConfigItemTextInputConfigurationDtoModel('useCase').withCategory(EasyFormW2Steps.step3);
  // Auftragsgrund
  orderReason = new ConfigItemTextInputConfigurationDtoModel('orderReason').withCategory(EasyFormW2Steps.step3);
  netCost = new ConfigItemNumberConfigurationDtoModel('netCost').withCategory(EasyFormW2Steps.step3);
  taxRate = new ConfigItemListConfigurationDtoModel('taxRate', 'taxrates').withCategory(EasyFormW2Steps.step3);
  grossCost = new ConfigItemNumberConfigurationDtoModel('grossCost').withCategory(EasyFormW2Steps.step3);
  // AGB anhängen Checkbox
  addAgb = new ConfigItemCheckboxConfigurationDtoModel('addAgb').withCategory(EasyFormW2Steps.step3);
  // zusätzliche Anlage anhängen
  addOtherAttachment = new ConfigItemCheckboxConfigurationDtoModel('addOtherAttachment').withCategory(EasyFormW2Steps.step3);
  internalNote = new ConfigItemTextInputConfigurationDtoModel('internalNote').withCategory(EasyFormW2Steps.step3);
  additionalInfoOnInvoice = new ConfigItemTextInputConfigurationDtoModel('additionalInfoOnInvoice').withCategory(EasyFormW2Steps.step3);
  dienArticle = new ConfigItemTextInputConfigurationDtoModel('dienArticle').withCategory(EasyFormW2Steps.step3);
  completionDate = new ConfigItemDateConfigurationDtoModel('completionDate').withCategory(EasyFormW2Steps.step3);
  useEBilling = new ConfigItemCheckboxConfigurationDtoModel('useEBilling').withCategory(EasyFormW2Steps.step3);
  addCoverLetter = new ConfigItemCheckboxConfigurationDtoModel('addCoverLetter').withCategory(EasyFormW2Steps.step3);
  coverLetterText = new ConfigItemTextareaInputConfigurationDtoModel('coverLetterText').withCategory(EasyFormW2Steps.step3);

  // Genehmigungsstufen
  approver = new ConfigItemArrangerListConfigurationDtoModel('approver').withCategory(EasyFormW2Steps.step3);
  inspector = new ConfigItemArrangerListConfigurationDtoModel('inspector').withCategory(EasyFormW2Steps.step3);
  commentForApprover = new ConfigItemTextInputConfigurationDtoModel('commentForApprover').withCategory(EasyFormW2Steps.step3);

  file = new ConfigItemFileUploadConfigurationDtoModel('file').withCategory(EasyFormW2Steps.step3);
  sendToBeneficiary = new ConfigItemCheckboxConfigurationDtoModel('sendToBeneficiary').withCategory(EasyFormW2Steps.step3);
}
