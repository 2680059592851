import { ConfigurationItems } from '../../../core-lib/models/config-dto.model';
import { ConfigItemAccountingConfigurationDtoModel } from '../../../core-lib/models/config-item-accounting-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../../core-lib/models/config-item-arranger-list-configuration-dto.model';
import { ConfigItemCheckboxConfigurationDtoModel } from '../../../core-lib/models/config-item-checkbox-configuration-dto.model';
import { ConfigItemConfigurationCategoryModel } from '../../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemDateConfigurationDtoModel } from '../../../core-lib/models/config-item-date-configuration-dto.model';
import { ConfigItemFileUploadConfigurationDtoModel } from '../../../core-lib/models/config-item-file-upload-configuration-dto.model';
import { ConfigItemLedgerAccountConfigurationDtoModel } from '../../../core-lib/models/config-item-ledger-account-configuration-dto.model';
import { ConfigItemListConfigurationDtoModel } from '../../../core-lib/models/config-item-list-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../../../core-lib/models/config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../../../core-lib/models/config-item-text-input-configuration-dto.model';
import { ConfigListDtoModel } from '../../../core-lib/models/config-list-dto.model';
import { EqualKeyNames } from '../../../forms-lib/models/equal-key-names';
import { PayeeDtoModel } from '../../all-forms/models/payee-dto.model';
import { FormC01DtoModel } from './form-c01-dto.model';

export const EasyFormCSteps = {
  step1: {
    id: 'step1',
    displayName: 'Gesellschaft',
  } as ConfigItemConfigurationCategoryModel,
  step2: {
    id: 'step2',
    displayName: 'Zahlungsempfänger',
  } as ConfigItemConfigurationCategoryModel,
  step3: {
    id: 'step3',
    displayName: 'Rechnungsangaben',
  } as ConfigItemConfigurationCategoryModel,
};

export class EasyFormC01ConfigItems extends ConfigurationItems implements EqualKeyNames<FormC01DtoModel>, EqualKeyNames<PayeeDtoModel> {
  // PayeeDtoModel
  payeeNumber = new ConfigItemTextInputConfigurationDtoModel('payeeNumber').withCategory(EasyFormCSteps.step2);

  salutation = new ConfigItemTextInputConfigurationDtoModel('salutation').withCategory(EasyFormCSteps.step2);
  name = new ConfigItemTextInputConfigurationDtoModel('name').withCategory(EasyFormCSteps.step2);

  street = new ConfigItemTextInputConfigurationDtoModel('street').withCategory(EasyFormCSteps.step2);
  zipCode = new ConfigItemTextInputConfigurationDtoModel('zipCode').withCategory(EasyFormCSteps.step2);
  city = new ConfigItemTextInputConfigurationDtoModel('city').withCategory(EasyFormCSteps.step2);
  country = new ConfigItemTextInputConfigurationDtoModel('country').withCategory(EasyFormCSteps.step2);
  email = new ConfigItemTextInputConfigurationDtoModel('email').withCategory(EasyFormCSteps.step2);
  iban = new ConfigItemTextInputConfigurationDtoModel('iban').withCategory(EasyFormCSteps.step2);
  bic = new ConfigItemTextInputConfigurationDtoModel('bic').withCategory(EasyFormCSteps.step2);

  // Verwendungszweck
  date = new ConfigItemDateConfigurationDtoModel('date').withCategory(EasyFormCSteps.step3);
  invoiceDate = undefined;
  useCase = new ConfigItemTextInputConfigurationDtoModel('useCase').withCategory(EasyFormCSteps.step3);
  participatingDepartments = new ConfigItemTextInputConfigurationDtoModel('participatingDepartments').withCategory(EasyFormCSteps.step3);
  numberOfPersons = new ConfigItemNumberConfigurationDtoModel('numberOfPersons').withCategory(EasyFormCSteps.step3);
  description = new ConfigItemTextInputConfigurationDtoModel('description').withCategory(EasyFormCSteps.step3);
  bookedOn = undefined;
  assignment = undefined;

  // positions
  positions = new ConfigListDtoModel('positions').withCategory(EasyFormCSteps.step3);
  positionName = new ConfigItemTextInputConfigurationDtoModel('positionName').withCategory(EasyFormCSteps.step3);
  netCost = new ConfigItemNumberConfigurationDtoModel('netCost').withCategory(EasyFormCSteps.step3);
  taxRate = new ConfigItemListConfigurationDtoModel('taxRate', 'taxrates').withCategory(EasyFormCSteps.step3);
  grossCost = new ConfigItemNumberConfigurationDtoModel('grossCost').withCategory(EasyFormCSteps.step3);
  taxValue = new ConfigItemNumberConfigurationDtoModel('taxValue').withCategory(EasyFormCSteps.step3);
  account = new ConfigItemAccountingConfigurationDtoModel('account').withCategory(EasyFormCSteps.step3);
  ledgerAccount = new ConfigItemLedgerAccountConfigurationDtoModel('ledgerAccount').withCategory(EasyFormCSteps.step3);

  // payout
  payout = new ConfigItemNumberConfigurationDtoModel('payout').withCategory(EasyFormCSteps.step3);
  advancePayout = new ConfigItemNumberConfigurationDtoModel('advancePayout').withCategory(EasyFormCSteps.step3);

  leaflet = new ConfigItemCheckboxConfigurationDtoModel('leaflet').withCategory(EasyFormCSteps.step3);

  // Teilnehmerliste
  participants = new ConfigListDtoModel('participants').withCategory(EasyFormCSteps.step3);
  participantName = new ConfigItemTextInputConfigurationDtoModel('participantName').withCategory(EasyFormCSteps.step3);
  participantCompanyName = new ConfigItemTextInputConfigurationDtoModel('participantCompanyName').withCategory(EasyFormCSteps.step3);
  isEmployee = new ConfigItemCheckboxConfigurationDtoModel('isEmployee').withCategory(EasyFormCSteps.step3);
  employeeInfo = new ConfigItemTextInputConfigurationDtoModel('employeeInfo').withCategory(EasyFormCSteps.step3);
  participantCompany = new ConfigItemTextInputConfigurationDtoModel('participantCompany').withCategory(EasyFormCSteps.step3);
  participantUser = new ConfigItemTextInputConfigurationDtoModel('participantUser').withCategory(EasyFormCSteps.step3);

  // Genehmigungsstufen
  approver = new ConfigItemArrangerListConfigurationDtoModel('approver').withCategory(EasyFormCSteps.step3);
  inspector = new ConfigItemArrangerListConfigurationDtoModel('inspector').withCategory(EasyFormCSteps.step3);
  commentForApprover = new ConfigItemTextInputConfigurationDtoModel('commentForApprover').withCategory(EasyFormCSteps.step3);

  file = new ConfigItemFileUploadConfigurationDtoModel('file').withCategory(EasyFormCSteps.step3);

  maxPayoutPerPerson = new ConfigItemNumberConfigurationDtoModel('advancePayment').withCategory(EasyFormCSteps.step3);

  fuvNumber = undefined;
  location = undefined;
  nameAddition = undefined;

}
