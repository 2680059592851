import { Action, createAction, props } from '@ngrx/store';
import { CompanyDataDtoModel } from '../../../core-lib/models/company-data-dto.model';
import { UserDataFormModel } from '../../../core-lib/models/user-data-form.model';
import { NetRegionModel } from '../../models/net-region.model';
import { ApiErrorAction, ApiSuccessAction } from './core.actions';

export class CoreAccountFormActionSave implements Action {
  static readonly TYPE = 'core/accountForm/Save';
  readonly type = CoreAccountFormActionSave.TYPE;
}

export class CoreAccountFormActionSaveSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/core/accountForm/SaveSuccess';
  readonly type = CoreAccountFormActionSaveSuccess.TYPE;
  messageKey = 'coreAccountFormActionSaveSuccess';
  snackBarDuration = 5000;

  constructor(public userData: UserDataFormModel) {
    super();
  }
}

export class CoreAccountFormActionSaveError extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/core/accountForm/SaveError';
  readonly type = CoreAccountFormActionSaveError.TYPE;

  constructor(e) {
    super(e, 'coreAccountFormActionSaveError');
  }
}

export class CoreAccountDeleteCostCenterAction implements Action {
  static readonly TYPE = 'core/accountForm/delete/costcenter';
  readonly type = CoreAccountDeleteCostCenterAction.TYPE;

  constructor(public costCenterId: string) {
  }
}

export class CoreAccountDeleteCostCenterSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/core/accountForm/delete/costcenter/success';
  readonly type = CoreAccountDeleteCostCenterSuccess.TYPE;
  messageKey = 'coreAccountDeleteCostCenterSuccess';
  snackBarDuration = 5000;
}

export class CoreAccountDeleteCostCenterError extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/core/accountForm/delete/costcenter/error';
  readonly type = CoreAccountDeleteCostCenterError.TYPE;
}

export class CoreAccountDeleteDefaultArrangerAction implements Action {
  static readonly TYPE = 'core/accountForm/delete/defaultarranger';
  readonly type = CoreAccountDeleteDefaultArrangerAction.TYPE;

  constructor(public arrangerId: string) {
  }
}

export class CoreAccountDeleteDefaultArrangerSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/core/accountForm/delete/defaultarranger/success';
  readonly type = CoreAccountDeleteDefaultArrangerSuccess.TYPE;
  messageKey = 'coreAccountDeleteDefaultArrangerSuccess';
  snackBarDuration = 5000;
}

export class CoreAccountDeleteDefaultArrangerError extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/core/accountForm/delete/defaultarranger/error';
  readonly type = CoreAccountDeleteDefaultArrangerError.TYPE;
}

export class CoreAccountFormActionLoadedUserData implements Action {
  static readonly TYPE = 'core/accountForm/LoadedUserData';
  readonly type = CoreAccountFormActionLoadedUserData.TYPE;

  constructor(public userData: UserDataFormModel) {
  }
}

export const coreAccountFormNetRegionsLoaded = createAction('core/accountForm/NetRegionLoaded', props<{ data: NetRegionModel<any>[] }>());

export type CoreAccountActions =
  CoreAccountFormActionSave |
  CoreAccountFormActionSaveSuccess |
  CoreAccountFormActionSaveError |
  CoreAccountFormActionLoadedUserData |
  { type: 'core/accountForm/NetRegionLoad', company: CompanyDataDtoModel } |
  { type: 'core/accountForm/NetRegionLoaded', data: NetRegionModel<any>[] };
