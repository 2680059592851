import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { toFaqTreeModel, toFaqElementModel } from '../../../core-lib/models/faq-tree.model';
import { ApiService } from '../../../core-lib/services/api.service';
import {
  coreConfigFaqDataLoad,
  coreConfigFaqDataLoadSuccess,
  coreConfigFaqDataSave,
  coreConfigFaqSaveSuccess,
} from '../actions/core-config.actions';
import { ConcreteApiSuccessAction } from '../actions/core.actions';
import { CoreFeatureState, getConfigFaqFormState } from '../reducers/core.store';

@Injectable()
export class CoreConfigFaqEffects {

  configLoadFaqFormData$ = createEffect(() => this.actions$.pipe(
    ofType(coreConfigFaqDataLoad),
    switchMap(() => this.api.getFaqItems()),
    map(toFaqTreeModel),
    map(faqItems => coreConfigFaqDataLoadSuccess({data: faqItems})),
  ));

  configSaveFaqFormData$ = createEffect(() => this.actions$.pipe(
    ofType(coreConfigFaqDataSave),
    withLatestFrom(this.store$.select(getConfigFaqFormState), (action, formState) => formState),
    filter((formState) => formState.isValid),
    map(formState => toFaqElementModel(formState.value)),
    switchMap((value) => this.api.postFaqItems(value)),
    map(toFaqTreeModel),
    mergeMap(data => [
      coreConfigFaqSaveSuccess({data}),
      new ConcreteApiSuccessAction('coreConfigFaqSaveSuccess'),
    ]),
  ));

  constructor(
    private actions$: Actions,
    private store$: Store<CoreFeatureState>,
    private api: ApiService,
  ) {
  }

}
