import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { accountDeputiesActions } from '../../ngrx/actions/core-account-deputies.actions';
import { CoreFeatureState } from '../../ngrx/reducers/core.store';

@Component({
  selector: 'lib-common-route-config-deputies',
  templateUrl: './route-config-deputies.component.html',
  styleUrls: ['./route-config-deputies.component.scss'],
})
export class RouteConfigDeputiesComponent implements OnInit {
  selectedUserId: string;

  constructor(
    private store$: Store<CoreFeatureState>,
  ) {
  }

  ngOnInit() {
  }

  setSelectedUser(userId: string) {
    this.store$.dispatch(accountDeputiesActions.AccountDeputiesLoadAction({userId}));
  }
}
