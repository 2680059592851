import { CoreAccountDeputiesEffects } from './core-account-deputies.effects';
import { CoreAccountEffects } from './core-account.effects';
import { CoreCategoryEffects } from './core-category.effects';
import { CoreCateringTableEffects } from './core-catering-table.effects';
import { CoreCateringEffects } from './core-catering.effects';
import { CoreCompanyEffects } from './core-company.effects';
import { CoreConfigCategoryInfoEffects } from './core-config-category-info.effects';
import { CoreConfigFaqEffects } from './core-config-faq.effects';
import { CoreConfigServiceCenterEffects } from './core-config-service-center.effects';
import { CoreConfigEffects } from './core-config.effects';
import { CoreEasyFormsEffects } from './core-easy-forms.effects';
import { CoreFavoriteEffects } from './core-favorite.effects';
import { CoreFormCategoryEffect } from './core-form-category.effects';
import { CoreI18nEffects } from './core-i18n.effects';
import { CoreProposalTableEffects } from './core-proposal-table.effects';
import { CoreTemplateEffects } from './core-template.effects';
import { CoreEffects } from './core.effects';
import { DashboardEffects } from './dashboard.effects';
import { FaqEffects } from './faq.effects';
import { OcrEffects } from './ocr.effects';
import { ProcessQueueEffects } from './queue.effects';
import { ResponseHandlerEffects } from './response-handler.effects';
import { TemplateDeputyEffects } from './template-deputy.effects';

export const CoreEffectsBundle = [
  CoreEffects,
  CoreAccountEffects,
  CoreCompanyEffects,
  CoreCateringEffects,
  CoreConfigEffects,
  CoreConfigFaqEffects,
  CoreCategoryEffects,
  ResponseHandlerEffects,
  CoreFormCategoryEffect,
  CoreEasyFormsEffects,
  CoreFavoriteEffects,
  CoreProposalTableEffects,
  CoreCateringTableEffects,
  CoreI18nEffects,
  CoreAccountDeputiesEffects,
  CoreTemplateEffects,
  OcrEffects,
  FaqEffects,
  CoreConfigServiceCenterEffects,
  CoreConfigCategoryInfoEffects,
  TemplateDeputyEffects,
  DashboardEffects,
  ProcessQueueEffects,
];
