import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { CoreI18nSetLanguage } from '../actions/core-config.actions';
import { CoreFeatureState, getI18nSelectedLanguage } from '../reducers/core.store';

@Injectable()
export class CoreI18nEffects {
  selectedLanguage$ = this.store$.select(getI18nSelectedLanguage);

  changeDateLocale$ = createEffect(() => this.actions$.pipe(
    ofType<CoreI18nSetLanguage>(CoreI18nSetLanguage.TYPE),
    tap((action) => this.dateAdapter.setLocale(action.langKey)),
  ), defaultEffectOptions({dispatch: false}));

  constructor(
    private actions$: Actions,
    private store$: Store<CoreFeatureState>,
    private dateAdapter: DateAdapter<any>,
  ) {
  }
}
