/**
 * Komponente, die automatisch previous und next buttons anzeigt.
 */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScrollToTopService } from '../scroll-to-top/scroll-to-top.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-form-stepper-back-and-next',
  templateUrl: './form-stepper-back-and-next.component.html',
  styleUrls: ['./form-stepper-back-and-next.component.scss'],
})
export class FormStepperBackAndNextComponent {
  constructor(
     private stt: ScrollToTopService,
     private activatedRoute: ActivatedRoute,
  ) {}

  @Output() previous = new EventEmitter();
  @Output() next = new EventEmitter();
  @Output() saveCache = new EventEmitter();
  @Input() noMatStepperPrevious = false;
  @Input() noMatStepperNext = false;
  @Input() noSaveCache = false;
  @Input() noPrevious = false;
  @Input() noNext = false;

  @Input() approveMode: string = this.activatedRoute.snapshot.data.approveMode;

  scrollToTop() {
    this.stt.scrollToTop();
  }
}
