import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroupState } from 'ngrx-forms';
import { IdDtoModel } from '../../../forms/all-forms/models/id-dto.model';
import { AccountDeputiesApproverFormModel, AccountDeputiesEditorFormModel } from '../../models/account-deputies-form.model';

@Component({
  selector: 'lib-common-user-deputies',
  templateUrl: './user-deputies.component.html',
  styleUrls: ['./user-deputies.component.scss']
})
export class UserDeputiesComponent {
  @Output() addApproverDeputy = new EventEmitter();
  @Output() addEditorDeputy = new EventEmitter();
  @Output() removeApproverDeputy = new EventEmitter<FormGroupState<IdDtoModel>>();
  @Output() removeEditorDeputy = new EventEmitter<FormGroupState<IdDtoModel>>();
  @Input() approverDeputies: FormGroupState<AccountDeputiesApproverFormModel>[];
  @Input() editorDeputies: FormGroupState<AccountDeputiesEditorFormModel>[];
}
