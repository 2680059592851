export function almostElasticMatch(find: string, inText: string) {
  if (!inText || !find) {
    return true;
  }

  const lowerText = inText.toLowerCase();
  return find
     ?.split(/[ ,]/)
    .reduce(
      (previous, term) => previous && lowerText?.includes(term?.toLowerCase()),
      true,
    );
}
