<lib-common-input-property-wrapper
  [header]="attributeName"
  icon="done_all"
  [typeNameI18n]="typeName"
  [readonly]="readonly"
  [ngrxOverwrite]="ngrxOverwrite"
  (changeReadonly)="onChangeReadonly($event)"
>
  <div>
    <section>
      <lib-common-input-property-defaults
        [ngrxMandatory]="ngrxMandatory"
        [ngrxEnabled]="ngrxEnabled"
        [ngrxVisible]="ngrxVisible"
      ></lib-common-input-property-defaults>
    </section>
    <section *ngIf="ngrxValues">
      <div *ngFor="let control of ngrxValues.controls; trackBy: getId">
        <lib-common-input-property-type-arranger
          [ngrxValue]="control"
          (removeClick)="removeClick.emit({array: ngrxValues, index: $event})"
        ></lib-common-input-property-type-arranger>
      </div>
      <div>
        <button
          type="button"
          mat-mini-fab
          (click)="addClick.emit(ngrxValues)"
          [disabled]="readonly && !ngrxOverwrite.value"
        >
          <mat-icon>person_add</mat-icon>
        </button>
      </div>
    </section>
    <lib-common-input-property-lang-wrapper
      [translations]="translations"
      (labelResetClick)="labelResetClick.emit($event)"
      [undoVisible]="labelResetClick && labelResetClick.observers && labelResetClick.observers.length > 0"
    ></lib-common-input-property-lang-wrapper>
  </div>
</lib-common-input-property-wrapper>
