<div
  *ngIf="errorMessage"
  class="message-style"
>
  <mat-card class="card-background">
    <mat-card-title> {{ 'backendErrorMessageTitle' | i18n | async }}</mat-card-title>
    <mat-card-content>
      <span>
        {{ errorMessage }}
      </span>
    </mat-card-content>
  </mat-card>
</div>
