<div class="btn-save-table">
  <button
    class="btn-margin"
    mat-raised-button
    color="primary"
    (click)="saveTableConfig()"
    *ngIf="saveButtonVisible"
  >{{'save' | i18n | async}}</button>

  <button
    mat-raised-button
    class="btn-margin"
    color="primary"
    (click)="removeFilterTableConfig()"
    *ngIf="filterButtonVisible"
  >
    {{'clearFilters' | i18n | async}}
    <mat-icon
      class="svg-icon-close"
      svgIcon="innogy:close"
    ></mat-icon>
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="exportTable()"
  >
    {{'exportWithFilter' | i18n | async}}
    <mat-icon
      class="svg-icon-close"
      svgIcon="innogy:c-arrow-down-02"
    ></mat-icon>
  </button>
</div>

<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  class="column-menu"
>
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <div
    fxLayout="row"
    *ngFor="let column of hideableColumns$ | async; trackBy: trackById"
    style="padding: 10px 20px"
  >
    <mat-checkbox
      [checked]="column.visible"
      (change)="toggleColumnVisibility($event, column)"
    >
      {{column.columnName | i18n | async}}
    </mat-checkbox>
  </div>
</mat-menu>

<ngx-datatable
  #table
  class="material striped"
  [rows]="(content$ | async)"
  [columnMode]="columnMode"
  headerHeight="50"
  footerHeight="50"
  rowHeight="'auto'"
  summaryHeight="'auto'"
  summaryRow="true"
  externalPaging="true"
  externalSorting="true"
  [loadingIndicator]="isLoading$ | async"
  [count]="(totalElements$ | async)"
  [offset]="(number$ | async)"
  [limit]="(size$ | async)"
  (page)="triggerPaging($event)"
  (sort)="triggerSorting($event)"
  (reorder)="saveButtonVisible = true;reorder($event)"
  (resize)="saveButtonVisible = true;resize($event)"
  [messages]="messages"
  [selected]="selected"
  [selectionType]="selectionType"
  [selectCheck]="isSelectableFn()"
  [selectAllRowsOnPage]="false"
  (select)="onSelect($event)"
>
  <ng-container *ngFor="let column of columns$ | async; trackBy: trackById">
    <ngx-datatable-column
      *ngIf="column.visible"
      [name]="column.columnName | i18n | async"
      [headerTemplate]="column.headerTemplate"
      [summaryTemplate]="column.summaryTemplate"
      [cellTemplate]="column.cellTemplate"
      [prop]="column.prop"
      [width]="column.width"
      [maxWidth]="column.maxWidth"
      [sortable]="column.sortable"
      [headerCheckboxable]="column.headerCheckboxable"
      [checkboxable]="column.checkboxable"
      [draggable]="column.draggable"
      [resizeable]="column.resizable"
    >
    </ngx-datatable-column>
  </ng-container>
</ngx-datatable>

<div
  *ngIf="selected.length > 0"
  class="approve-reject-btn"
>
  <button
    class="btn-approve"
    mat-raised-button
    (click)="openDialogMultipleApprove()"
  >{{'approve' | i18n | async}}</button>
  <button
    class="btn-reject"
    mat-raised-button
    (click)="openDialogMultipleReject()"
  >{{'reject' | i18n | async}}</button>
</div>

<!-- Ab hier kommen nur noch Templates -->
<ng-template
  #checkboxHeaderTemplate
  ngx-datatable-header-template
  let-value="value"
  let-allRowsSelected="allRowsSelected"
  let-selectFn="selectFn"
>
  <input
    type="checkbox"
    [checked]="allRowsSelected"
    (change)="selectFn(!allRowsSelected)"
  />
</ng-template>
<ng-template
  #checkboxCell
  ngx-datatable-cell-template
  let-row="row"
  let-isSelected="isSelected"
  let-onCheckboxChangeFn="onCheckboxChangeFn"
>
  <input
    type="checkbox"
    [checked]="isSelected"
    (change)="onCheckboxChangeFn($event)"
    *ngIf="!shouldDisableSendAndConfirm(row.formIdentifier, row, (isAdmin$ | async))"
  />
</ng-template>
<ng-template
  #filterTemplate
  let-column="column"
>
  <div fxLayout="row">
    <div fxFlex="100%">
      <mat-form-field class="fillwidth">
        <!-- ngModel ist nicht strictActionImmutability kompatibel-->
        <input
          matInput
          [(ngModel)]="sortFilterPageConfig.filter[column.prop]"
          (ngModelChange)="triggerFilter()"
        />
      </mat-form-field>
    </div>
  </div>
</ng-template>

<ng-template
  #numberFilterTemplate
  let-column="column"
>
  <div fxLayout="row">
    <div fxFlex="100%">
      <mat-form-field class="fillwidth">
        <!-- ngModel ist nicht strictActionImmutability kompatibel-->
        <input
          matInput
          type="number"
          [(ngModel)]="sortFilterPageConfig.filter[column.prop]"
          (ngModelChange)="triggerFilter()"
        />
      </mat-form-field>
    </div>
  </div>
</ng-template>

<ng-template
  #stateFilterTemplate
  let-column="column"
>
  <mat-form-field class="fillwidth">
    <!-- ngModel ist nicht strictActionImmutability kompatibel-->
    <mat-select
      [(ngModel)]="sortFilterPageConfig.filter[column.prop]"
      (ngModelChange)="triggerFilter()"
    >
      <mat-option value=""></mat-option>
      <mat-option
        *ngFor="let state of states"
        [value]="state"
      >{{state  | i18n |async}}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template
  #dateFilterTemplate
  let-column="column"
>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start baseline"
  >
    <div fxFlex="1 0 100px">
      <mat-form-field class="fillwidth">
        <mat-label>{{'from' |i18n|async}}</mat-label>
        <!-- ngModel ist nicht strictActionImmutability kompatibel-->
        <input
          matInput
          [matDatepicker]="picker1"
          [(ngModel)]="sortFilterPageConfig.filter[column.prop+'From']"
          (ngModelChange)="triggerFilter()"
          [ngModelOptions]="{updateOn: 'blur'}"
          [max]="sortFilterPageConfig.filter[column.prop+'Until']"
        >
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        >
        </mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>
    <span>&nbsp;-&nbsp;</span>
    <div fxFlex="1 0 100px">
      <mat-form-field class="fillwidth">
        <mat-label>{{'until' |i18n|async}}</mat-label>
        <!-- ngModel ist nicht strictActionImmutability kompatibel-->
        <input
          matInput
          [matDatepicker]="picker2"
          [(ngModel)]="sortFilterPageConfig.filter[column.prop+'Until']"
          (ngModelChange)="triggerFilter()"
          [ngModelOptions]="{updateOn: 'blur'}"
          [min]="sortFilterPageConfig.filter[column.prop+'From']"
        >
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</ng-template>

<ng-template
  #authorFilterTemplate
  let-column="column"
>
  <div fxLayout="row">
    <div fxFlex="100%">
      <!-- ngModel ist nicht strictActionImmutability kompatibel-->
      <lib-common-user-search
        placeholder=""
        [(ngModel)]="autor"
        (ngModelChange)="triggerFilterAuthor($event)"
      ></lib-common-user-search>
    </div>
  </div>
</ng-template>

<ng-template
  #arrangerFilterTemplate
  let-column="column"
>
  <div fxLayout="row">
    <div fxFlex="100%">
      <!-- ngModel ist nicht strictActionImmutability kompatibel-->
      <lib-common-user-search
        placeholder=""
        [(ngModel)]="arranger"
        (ngModelChange)="triggerFilterArranger($event)"
      ></lib-common-user-search>
    </div>
  </div>
</ng-template>

<ng-template
  #dateCellTemplate
  let-row="row"
  let-column="column"
>
  <div>
    {{row[column.prop] | date :'dd.MM.yyyy'}}
  </div>
  <div>
    {{row[column.prop] | date :'HH:mm'}}
  </div>
</ng-template>

<ng-template
  #categoryCellTemplate
  let-row="row"
  let-column="column"
>
  <div *ngIf="!isArray(column.prop)">
    {{access(row, column.prop)}}
  </div>
  <div *ngIf="isArray(column.prop)">
    <div *ngFor="let p of column.prop; let i = index">
      <div *ngIf="i < 1">
        {{access(row, p)}} -
      </div>
      <div *ngIf="i >= 1">
        {{access(row, p)}}
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #i18nTextCellTemplate
  let-row="row"
  let-column="column"
>
  {{row[column.prop] | i18n | async}}
</ng-template>

<ng-template
  #amountCellTemplate
  let-row="row"
  let-column="column"
>
  {{showAmount(row, column.prop) | currency:'EUR':'symbol':'1.2-2':'de'}}
</ng-template>

<ng-template
  #approvalActionsCellTemplate
  let-row="row"
  let-column="column"
>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start"
    fxLayoutGap="10px grid"
  >
    <div
      fxFlex.gt-md="50%"
      fxFlex.lt-lg="100%"
    >
      <button
        type="button"
        mat-icon-button
        class="proposal-check icon-padding"
        [title]="'check' | i18n | async"
        [routerLink]="[
          '/approvals',
          row.categoryName,
          getFormIdentifier(row.formIdentifier, row.state),
          'approve','confirm',
          row[column.prop]
        ]"
      >
        <mat-icon svgIcon="innogy:magnifier"></mat-icon>
      </button>
    </div>

    <div
      *ngIf="row.comment"
      fxFlex.gt-md="50%"
      fxFlex.lt-lg="100%"
    >
      <button
        type="button"
        [title]="'showComment' | i18n | async"
        class="proposal-check icon-padding"
        mat-icon-button
        (click)="openDialogComment(row.comment)"
      >
        <mat-icon svgIcon="innogy:info"></mat-icon>
      </button>
    </div>
  </div>

  <div
    fxLayout="row wrap"
    fxLayoutAlign="start"
    fxLayoutGap="10px grid"
  >
    <div
      *ngIf="type !== 'FINISHED_APPROVALS'"
      fxFlex.gt-md="50%"
      fxFlex.lt-lg="100%"
    >
      <button
        type="button"
        mat-icon-button
        class="proposal-approve icon-padding"
        *ngIf="!shouldDisableSendAndConfirm(row.formIdentifier, row, isAdmin$ | async)"
        [title]="'approve' | i18n | async"
        (click)="openDialogApprove(row[column.prop])"
      >
        <mat-icon svgIcon="innogy:c-check"></mat-icon>
      </button>
    </div>
    <div
      fxFlex.gt-md="50%"
      fxFlex.lt-lg="100%"
      *ngIf="type !== 'FINISHED_APPROVALS'"
    >
      <button
        type="button"
        mat-icon-button
        class="proposal-reject icon-padding"
        *ngIf="!shouldDisableSendAndConfirm(row.formIdentifier, row, isAdmin$ | async)"
        [title]="'reject' | i18n | async"
        (click)="openDialogReject(row[column.prop])"
      >
        <mat-icon svgIcon="innogy:c-cross"></mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template
  #notApprovalActionsCellTemplate
  let-row="row"
  let-column="column"
>
  <div fxFlex="row wrap">
    <div>
      <ng-container
        *ngIf="row.state !== 'CACHED' || (!row.companyActive && !(isAdmin$ | async))"
      >
        <button
          type="button"
          mat-icon-button
          [title]="'view' | i18n | async"
          [routerLink]="[
          '/list',
          row.categoryName,
          getFormIdentifier(row.formIdentifier, row.state),
          'confirm',
          row[column.prop]]"
        >
          <mat-icon svgIcon="innogy:magnifier"></mat-icon>
        </button>
      </ng-container>
      <button
        *ngIf="(row.state === 'SAVED' || row.state === 'DECLINED' || row.state === 'SAP_ERROR' || row.state === 'APPROVED'
        || row.state === 'CATERING_COMPLETE' || row.state === 'CATERING_DECLINED') && !shouldDisableSendAndConfirm(row.formIdentifier,
        row, isAdmin$ | async)"
        type="button"
        mat-icon-button
        [title]="'send' | i18n | async"
        (click)="send(row[column.prop])"
      >
        <mat-icon
          svgIcon="innogy:envelope"
        ></mat-icon>
      </button>
    </div>

    <div>
      <button
        type="button"
        mat-icon-button
        *ngIf="isEditable(row, isAdmin$ | async)"
        [title]="'edit' | i18n | async"
        [routerLink]="['/list', row.categoryName, row.formIdentifier, row[column.prop]]"
      >
        <mat-icon svgIcon="innogy:pen"></mat-icon>
      </button>
      <button
        type="button"
        mat-icon-button
        *ngIf="row.state === 'WAITING_FOR_SIGNATURE' && (row.companyActive || (isAdmin$ | async))"
        [title]="'edit' | i18n | async"
        [routerLink]="[
          '/list',
          row.categoryName,
          getFormIdentifier(row.formIdentifier, row.state),
          row[column.prop]
        ]"
      >
        <mat-icon svgIcon="innogy:pen"></mat-icon>
      </button>
      <button
        type="button"
        mat-icon-button
        *ngIf="row.state === 'CACHED' || row.state === 'SAVED' || row.state === 'INITIAL' || row.state === 'DECLINED'
        || row.state === 'CATERING_CANCELED' || row.state === 'CATERING_DECLINED' || (row.state === 'SEND_FAILED' && isAdmin$ | async)"
        [title]="'delete' | i18n | async"
        (click)="openDeletePrompt(row[column.prop])"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <ng-container
        *ngIf="(row.state === 'CATERING_APPROVAL' || row.state === 'CATERING_IN_PROGRESS') && row.recallable && (row.companyActive || isAdmin$ | async)"
      >
        <button
          type="button"
          mat-icon-button
          [title]="'cancelCatering' | i18n | async"
          (click)="cateringCancel(row[column.prop])"
        >
          <mat-icon svgIcon="innogy:close"></mat-icon>
        </button>
      </ng-container>

    </div>
  </div>
</ng-template>
