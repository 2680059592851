<mat-form-field *ngIf="!asTextInput">
  <mat-select
    [ngrxFormControlState]="roomFormControlState"
    [ngrxEasyFieldConfig]="easyFieldConfig"
    [placeholder]="selectRoomI18n | i18n |async"
  >
    <mat-option
      *ngFor="let room of rooms"
      [value]="room.id"
    >
      {{ room.name + ' - ' + room.description}}
    </mat-option>
  </mat-select>
  <mat-error *ngFor="let key of roomErrorKeys">{{(key + "Error") | i18n: roomErrors | async}}</mat-error>
</mat-form-field>
<mat-form-field *ngIf="asTextInput">
  <input
    [ngrxFormControlState]="alternativeRoomNameFormControlState"
    [ngrxEasyFieldConfig]="easyFieldConfig"
    matInput
    [placeholder]="enterRoomI18n | i18n |async"
  >
  <mat-error *ngFor="let key of altRoomErrorKeys">{{(key + "Error") | i18n: altRoomErrors | async}}</mat-error>
</mat-form-field>
