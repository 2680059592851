<lib-common-page-margin>
  <div class="margin-content">
    <lib-common-tiles-container
      [tiles]="cards$ | async"
      (cardClicked)="cardClicked($event)"
      [collapsedDisplayCount]="undefined"
    >
    </lib-common-tiles-container>
  </div>
</lib-common-page-margin>
