<lib-common-info-bar
  *ngIf="systemMessage$ | async"
  [html]="systemMessage$ | async"
>
</lib-common-info-bar>
<div class="route-index">
  <lib-common-page-margin>
    <lib-common-tiles-container
      heading="{{ 'formDashboard' | i18n | async }}"
      [disableDrag]="true"
      [tiles]="categoryCards$ | async"
    >
    </lib-common-tiles-container>

    <lib-common-tiles-container
      heading="{{'favoriteDashboard' | i18n | async}}"
      [tiles]="favoriteCards$ | async"
      [closeButton]="true"
      (closeButtonClicked)="removeFavorite($event.identifier)"
      (saveViewButtonClicked)="saveFavoriteOrder($event)"
      [addCard]="false"
      [collapsedDisplayCount]="4"
    >
    </lib-common-tiles-container>

    <lib-common-tiles-container
      heading="{{'templateDashboard' | i18n | async}}"
      [tiles]="templateCards$ | async"
      [closeButton]="true"
      (closeButtonClicked)="removeTemplate($event)"
      (saveViewButtonClicked)="saveTemplateOrder($event)"
      [addCard]="false"
      [collapsedDisplayCount]="4"
    >
    </lib-common-tiles-container>
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <a
        fxFlex.gt-sm="23%"
        fxFlex.lt-md="100%"
        mat-raised-button
        class="btn-template-link"
        color="primary"
        routerLink="/template/deputy/"
      >
        <div>
          <mat-icon
            svgIcon="innogy:people-table"
            class="template-link-icon"
          >
          </mat-icon>
        </div>
        <div class="template-link-text">
          {{ 'deputyTemplate' | i18n | async }}
        </div>
      </a>
    </div>

  </lib-common-page-margin>
</div>
