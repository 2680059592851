import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { EasyFormModel } from '../../../core-lib/models/easy-form.model';
import { FileUploadModel } from '../../models/file-upload.model';
import { coreConfigMassDataDownload, coreConfigMassDataUpload } from '../../ngrx/actions/core-config.actions';
import { getCompaniesByDynamicFormIdentifierState } from '../../ngrx/reducers/core.store';
import { getEasyFormsArrayElementByIdentifiers } from '../../ngrx/reducers/easy-forms.reducer';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  selector: 'lib-common-route-config-mass-data-processing',
  templateUrl: './route-config-mass-data-processing.component.html',
  styleUrls: ['./route-config-mass-data-processing.component.scss'],
})
export class RouteConfigMassDataProcessingComponent implements OnInit {

  forms$ = of([]);
  downloadFormIdentifier: string;
  downloadCompanyIdentifier: string;
  count: number;
  uploadFormIdentifier: string;
  uploadCompanyIdentifier: string;
  uploadFile: FileUploadModel[];

  constructor(private store$: Store<any>, private appConfig: AppConfigService) {
  }

  ngOnInit(): void {
    this.forms$ = this.store$.select(getEasyFormsArrayElementByIdentifiers, {identifiers: this.appConfig.config.massDataForms});
  }


  getCompanies(formIdentifier: string) {
    return this.store$.select(getCompaniesByDynamicFormIdentifierState, {
      identifier: formIdentifier,
    });
  }

  download() {
    this.store$.dispatch(coreConfigMassDataDownload({formIdentifier: this.downloadFormIdentifier, companyIdentifier: this.downloadCompanyIdentifier, count: this.count}));
  }

  upload() {
    this.store$.dispatch(coreConfigMassDataUpload({formIdentifier: this.uploadFormIdentifier, companyIdentifier: this.uploadCompanyIdentifier, file: this.uploadFile[0]}));
  }
}
