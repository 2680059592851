<div
  *ngIf="!inline"
  fxFlex="100%"
  fxLayoutGap="10px"
  [class]="{'error-spacing': hasIncludesAuthorError() || hasIncludedInError() || hasIncludesRecipientError() || hasNotUniqueError()}"
>
  <ng-container *ngTemplateOutlet="tpl"></ng-container>
</div>
<span
  *ngIf="inline"
>
  <ng-container *ngTemplateOutlet="tpl"></ng-container>
</span>

<mat-autocomplete
  #auto="matAutocomplete"
  autoActiveFirstOption
>
  <mat-option
    *ngFor="let user of users$ | async; trackBy: trackById"
    [value]="getUserText(user)"
    (onSelectionChange)="change(user)"
  >
    <span>{{ user.name }}</span> (<small>{{ user.mail }}</small>)
  </mat-option>
</mat-autocomplete>

<ng-template #tpl>
  <mat-form-field
    [style.width]="inline ? '' : '100%'"
    [appearance]="appearance"
    [style.display]="!asLabel ? '' : 'none'"
  >
    <span
      *ngIf="isSelected"
      matPrefix
    >
      <mat-icon
        class="icon-usersearch"
        svgIcon="innogy:person"
      ></mat-icon>
    </span>
    <mat-label>{{placeholder}}</mat-label>
    <input
      matInput
      [matAutocomplete]="autoRef"
      [matAutocompleteDisabled]="disabled || readonly || asLabel"
      #model="ngModel"
      [(ngModel)]="search"
      [disabled]="disabled || disabledByCVA"
      [readonly]="readonly"
      [errorStateMatcher]="errorStateMatcher"
      [required]="required"
    >
    <button
      *ngIf="!disabled && !readonly && !asLabel"
      type="button"
      aria-label="search"
      mat-button
      mat-icon-button
      matSuffix
      (click)="openDialog($event)"
    >
      <mat-icon class="icon-magnifier" svgIcon="innogy:magnifier"></mat-icon>
    </button>
    <mat-error *ngIf="hasIncludesAuthorError()">
      {{'authorNotAllowed' | i18n | async}}
    </mat-error>
    <mat-error *ngIf="hasApproverNeededError()">
      {{'approverNeeded' | i18n | async}}
    </mat-error>
    <mat-error *ngIf="hasIncludedInError()">
      {{'notAllowedWhileIncludedIn' | i18n | async}} {{getIncludedInErrorMessage() | i18n | async}}
    </mat-error>
    <mat-error *ngIf="hasIncludesRecipientError()">
      {{'recipientNotAllowed' | i18n | async}}
    </mat-error>
    <mat-error *ngIf="hasNotUniqueError()">
      {{'userNotUnique' | i18n | async}}
    </mat-error>
    <mat-error *ngIf="hasNoParticipantError()">
      {{'needsToBeEmployeeInParticipantList' | i18n | async}}
    </mat-error>
    <mat-error *ngIf="hasMandatoryError()">
      {{'mandatoryError' | i18n | async}}
    </mat-error>
  </mat-form-field>
  <button
    fxFlex="0 0 auto"
    type="button"
    aria-label="remove"
    mat-button
    mat-mini-fab
    mat-icon-button
    *ngIf="(!disabled && !readonly && !asLabel) && removable"
    (click)="removeClick.emit($event)"
  >
    <mat-icon>delete</mat-icon>
  </button>
  <span
    *ngIf="asLabel"
    style="display: inline-block"
  >
    {{model.viewModel}}
    <mat-spinner
      style="display: inline-block"
      [value]="1"
      *ngIf="!(model.viewModel)"
      [diameter]="24"
    ></mat-spinner>
  </span>
</ng-template>
