import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormCategoryElementModel } from '../../models/form-category-element.model';

@Component({
  selector: 'lib-common-category-child-form',
  templateUrl: './category-child-form.component.html',
  styleUrls: ['./category-child-form.component.scss'],
})
export class CategoryChildFormComponent {
  @Input() name: string;
  @Input() href: string;
  @Input() hasOCRSupport: boolean;
  @Input() hasTemplateSupport: boolean;
  @Input() isFavorite: boolean;
  @Input() identifier: string;
  @Output() addFavoriteClicked = new EventEmitter<FormCategoryElementModel>();
  @Output() deleteFavoriteClicked = new EventEmitter<FormCategoryElementModel>();
  @Output() templateClicked = new EventEmitter<FormCategoryElementModel>();
  @Output() ocrScan = new EventEmitter<FormCategoryElementModel>();
  @Output() ocrCam = new EventEmitter<FormCategoryElementModel>();
  @Input() routerLinkTemplate: (formId: string) => string[] = x => [x];
}
