<mat-toolbar [fxHide.gt-sm]="!forceMobile" class="menu-style">
  <mat-toolbar-row>
    <button
      *ngIf="!(isCaterer$ | async)"
      (click)="toggle()"
      class="md-icon-button sidenav-toggle-button"
      mat-icon-button
    >
      <mat-icon class="svg-icon-navi-menu" svgIcon="innogy:three-points2"></mat-icon>
    </button>
    <span class="title-center"></span>
    <lib-common-page-navbar-item
      (click)="navigateRelative('/')"
      [highlightOnRouteMatch]="false"
      [darkmode]="true"
    >
          <span class="title-center">
            <mat-icon class="icon-small-menu" svgIcon="innogy:home-small"></mat-icon>
           </span>
    </lib-common-page-navbar-item>
    <div *ngFor="let navDisplayItem of (navDisplay$ | async); index as i">
      <lib-common-page-navbar-item
        (click)="navigateRelative(navDisplayItem.link)"
        [highlightOnRouteMatch]="false"
        [darkmode]="true"
      >
        <span *ngIf="i > -1" class="spacer">&gt;</span> <span class="title-center">
          {{ parseDisplayName(navDisplayItem) | async}}
        </span>
      </lib-common-page-navbar-item>
    </div>
    <span class="title-center"></span>
  </mat-toolbar-row>
</mat-toolbar>
