<div>
  <div class="header">

    <div fxLayoutAlign="center center" fxFlex.xs="40%" fxFlex.gt-xs="30%" fxFlex.gt-sm="14%">
      <lib-common-page-header-greeting></lib-common-page-header-greeting>
    </div>

    <div fxShow="false"
         fxShow.gt-sm="true"
         fxFlex.gt-xs="27%" fxFlex.gt-md="26%">
      <div class="nav-greeting" >
          <span class="nav-greeting-nowrap">
            <lib-common-session-greeting></lib-common-session-greeting>
          </span>
      </div>
    </div>

    <div fxLayoutAlign="center center" fxFlex.xs="40%" fxFlex.gt-xs="50%" fxFlex.gt-md="20%">
      <lib-common-page-header-logo></lib-common-page-header-logo>
    </div>

    <div fxLayoutAlign="end center" fxFlex.xs="10%" fxFlex.gt-xs="20%" fxFlex.gt-md="40%">
      <div fxShow="false" fxShow.lt-md="true">
        <div>
          <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon svgIcon="innogy:three-points2" class="icon-menu-mobile"></mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button type="button" mat-menu-item>
              <lib-common-page-header-quick-links></lib-common-page-header-quick-links>
            </button>
          </mat-menu>
        </div>
      </div>
      <div fxShow="false" fxShow.gt-sm="true">
        <lib-common-page-header-quick-links></lib-common-page-header-quick-links>
      </div>
    </div>

  </div>
</div>
