import { createFormGroupState, disable, updateGroup } from 'ngrx-forms';
import { UserDataFormModel } from '../../../core-lib/models/user-data-form.model';

export const FORM_ID = 'account';

const state = createFormGroupState<UserDataFormModel>(FORM_ID, {
  salutation: undefined,
  defaultCompany: undefined,
  defaultNetRegion: '',
  defaultServiceCenter: '',
  defaultNetRegionAccount: '',
  bic: '',
  iban: '',
  defaultArrangers: [],
  costCenter: '',
  department: '',
  location: '',
  postcode: '',
  street: '',
  telephone: '',
  costCenterToCompanies: [],
  firstName: '',
  lastName: '',
  email: '',
  country: '',
});


export const initialAccountState = updateGroup(state, {
  firstName: disable,
  lastName: disable,
  email: disable,
});
