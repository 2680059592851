import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { CoreConfigLocationInfoLoad } from '../../ngrx/actions/core-config.actions';
import { getConfigNoneLunchablesLocationState } from '../../ngrx/reducers/core.store';

@Component({
  selector: 'lib-common-route-config-location-selection',
  templateUrl: './route-config-location-selection.component.html',
  styleUrls: ['./route-config-location-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteConfigLocationSelectionComponent implements OnInit {

  locations$ = this.store$.select(getConfigNoneLunchablesLocationState);

  constructor(
    private store$: Store<any>,
    public dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.store$.dispatch(new CoreConfigLocationInfoLoad());
  }

  getPath() {
    return this.route.snapshot.routeConfig.path;
  }

}
