<lib-common-input-property-wrapper
  [header]="attributeName"
  icon="done_all"
  [typeNameI18n]="typeName"
>
  <div
    *ngIf="ngrxValue && ngrxValue.controls"
  >
    <section>
      <lib-common-user-search
        placeholder="user"
        [modalLabel]="'search'| i18n | async"
        [ngrxFormControlState]="ngrxValue.controls.value"
        [asId]="true"
        [required]="true"
      ></lib-common-user-search>
    </section>
    <section>
      <mat-checkbox
        class="checkbox-margin"
        [ngrxFormControlState]="ngrxValue.controls.valueBased"
      ></mat-checkbox>
      <mat-form-field>
        <mat-label>{{'activationValue' | i18n | async}}</mat-label>
        <input
          matInput
          [ngrxFormControlState]="ngrxValue.controls.activationValue"
          type="number"
        >
      </mat-form-field>
    </section>
    <section>
      <mat-checkbox
        class="checkbox-margin"
        [ngrxFormControlState]="ngrxValue.controls.countBased"
      ></mat-checkbox>
      <mat-form-field>
        <mat-label>{{'activationCount' | i18n | async}}</mat-label>
        <input
          matInput
          [ngrxFormControlState]="ngrxValue.controls.activationCount"
          type="number"
        >
      </mat-form-field>
    </section>
    <section>
      <mat-form-field>
        <mat-label>{{'sortingNumber' | i18n | async}}</mat-label>
        <input
          matInput
          [ngrxFormControlState]="ngrxValue.controls.sortingNumber"
          [disabled]="true"
          type="number"
        >
      </mat-form-field>
    </section>
    <section>
      <button
        type="button"
        mat-mini-fab
        (click)="removeClick.emit(index)"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </section>
  </div>
</lib-common-input-property-wrapper>
