import { createReducer, on } from '@ngrx/store';
import { FaqTreeModel } from '../../../core-lib/models/faq-tree.model';
import { faqSetFaqsAction } from '../actions/faq.actions';

export interface FaqState {
  faqs: FaqTreeModel[];
}

const initialFaqState: FaqState = {
  faqs: [],
};

export const formFaqReducer = createReducer(
  initialFaqState,
  on(faqSetFaqsAction, (s, {data}) => ({...s, faqs: data})),
);
