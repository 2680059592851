<div *ngIf="!flex">
  <ng-container *ngTemplateOutlet="select"></ng-container>
</div>
<div
  *ngIf="flex"
  [fxFlex]="flex"
>
  <ng-container *ngTemplateOutlet="select"></ng-container>
</div>

<ng-template #select>
  <mat-form-field
    class="flex-field"
    *ngIf="selectState && hasOptions()"
  >
    <mat-select
      *ngIf="configName"
      [ngrxFormControlState]="selectState"
      [ngrxValueConverter]="valueConverter"
      [ngrxEasyFieldConfig]="configName"
      [placeholder]="placeholderI18nKey | i18n | async"
      #singleSelect
      panelClass="select-wrapper-fix"
      [disabledOverride]="disabledOverride"
      [errorStateMatcher]="errorStateMatcher"
    >
      <mat-option>
        <ngx-mat-select-search
          placeholderLabel="{{ 'search' | i18n | async }}"
          [formControl]="elementFilterCtrl"
          [searching]="searching"
        ></ngx-mat-select-search>
      </mat-option>

      <mat-option
        *ngFor="let element of (filteredElements | async); trackBy: trackById"
        [value]="element"
      >
        {{ getDisplayText(element) }}
      </mat-option>
    </mat-select>

    <mat-select
      *ngIf="!configName"
      [ngrxFormControlState]="selectState"
      [ngrxValueConverter]="valueConverter"
      [required]="required"
      placeholder="{{ placeholderI18nKey | i18n | async }}"
      #singleSelect
      panelClass="select-wrapper-fix"
      [errorStateMatcher]="errorStateMatcher"
    >
      <mat-option>
        <ngx-mat-select-search
          placeholderLabel="{{ 'search' | i18n | async }}"
          [formControl]="elementFilterCtrl"
          [searching]="searching"
        ></ngx-mat-select-search>
      </mat-option>

      <mat-option
        *ngFor="let element of (filteredElements | async); trackBy: trackById"
        [value]="element"
      >
        {{ getDisplayText(element) }}
      </mat-option>
    </mat-select>
    <mat-error *ngFor="let error of errors">
      {{ error }}
    </mat-error>
    <mat-error *ngFor="let key of stateErrorKeys">{{(key + "Error") | i18n: stateErrors | async}}</mat-error>
  </mat-form-field>
</ng-template>
