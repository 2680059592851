import { createEntityAdapter } from '@ngrx/entity';
import { FormDtoModel } from '../../../core-lib/models/form-dto.model';
import { FavoriteAction, FavoriteActionGetFirstSuccess, FavoriteActionGetSuccess } from '../actions/favorite.actions';
import { FavoriteState } from './core-favorite.store';

const favoriteEntityAdapter = createEntityAdapter<FormDtoModel>();


export const initialFavoriteState: FavoriteState = {
  initial: true,
  ...favoriteEntityAdapter.getInitialState(),
};

export function coreFavoriteReducer(state = initialFavoriteState, action: FavoriteAction): FavoriteState {
  switch (action.type) {
    case FavoriteActionGetSuccess.TYPE:
      return {
        ...state,
        ...favoriteEntityAdapter.setAll(action.favorites, initialFavoriteState),
        initial: false,
      };
    case FavoriteActionGetFirstSuccess.TYPE:
      return {
        ...state,
        ...favoriteEntityAdapter.setAll(action.favorites, initialFavoriteState),
        initial: action.favorites.length > 4,
      };
    default:
      return state;
  }
}

