import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiService } from '../../../core-lib/services/api.service';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { rxCatchApiError } from '../../../core-lib/utils/reducer-utils';
import { CoreActionInit } from '../actions/core.actions';
import {
  TemplateActionAdd,
  TemplateActionDelete,
  TemplateActionDeleteSuccess,
  TemplateActionError,
  TemplateActionGet,
  TemplateActionGetSuccess, TemplateActionSaveOrder, TemplateActionSaveOrderSuccess,
  TemplateActionSaveSuccess,
} from '../actions/template.action';

@Injectable()
export class CoreTemplateEffects {

  loadTemplates$ = createEffect(() => this.actions$.pipe(
    ofType(TemplateActionGet.TYPE),
    switchMap((action: TemplateActionGet) => this.api.getAllTemplates().pipe(
      map(templates => new TemplateActionGetSuccess(templates)),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  addTemplate = createEffect(() => this.actions$.pipe(
    ofType(TemplateActionAdd.TYPE),
    switchMap((action: TemplateActionAdd) => this.api.postTemplate({id: action.id}).pipe(
      map(() => new TemplateActionSaveSuccess()),
      catchError(e => of(new TemplateActionError(e, e.message))),
    )),
  ), defaultEffectOptions());

  deleteTemplate = createEffect(() => this.actions$.pipe(
    ofType(TemplateActionDelete.TYPE),
    switchMap((action: TemplateActionDelete) => this.api.deleteTemplate(action.identifier, action.templateName).pipe(
      map(() => new TemplateActionDeleteSuccess()),
      catchError(e => of(new TemplateActionError(e, e.message))),
    )),
  ), defaultEffectOptions());

  saveTemplateOrder = createEffect(() => this.actions$.pipe(
    ofType(TemplateActionSaveOrder.TYPE),
    switchMap((action: TemplateActionSaveOrder) => this.api.saveTemplateOrder(action.data).pipe(
      map(() => new TemplateActionSaveOrderSuccess()),
      catchError(e => of(new TemplateActionError(e, e.message))),
    )),
  ), defaultEffectOptions());

  saveTemplateSuccess = createEffect(() => this.actions$.pipe(
    ofType(TemplateActionSaveSuccess.TYPE, TemplateActionDeleteSuccess.TYPE, CoreActionInit.TYPE),
    map(() => new TemplateActionGet()),
  ), defaultEffectOptions());

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {
  }
}
