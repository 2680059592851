import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Dictionary } from '@ngrx/entity';
import { FormControlState, ValidationErrors } from 'ngrx-forms';
import { merge, Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ConfigItemNumberConfigurationDtoModel } from '../../models/config-item-number-configuration-dto.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyInputComponent),
      multi: true,
    },
  ],
})
export class CurrencyInputComponent implements ControlValueAccessor, OnDestroy, OnChanges {
  static komma = true;
  static kommaChange$ = new EventEmitter();

  @ViewChild('model', {static: true})
  private model: NgModel;
  @ViewChild(MatInput, {static: true})
  private matInput: MatInput;

  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() required: boolean;
  @Input() appearance: MatFormFieldAppearance;
  @Input() floatLabel: FloatLabelType;
  @Input() placeholder: string;
  protected _ngrxFormControlState: FormControlState<number>;

  writeTimeoutRef: number;

  @Input()
  set ngrxFormControlState(value: FormControlState<number>) {
    this._ngrxFormControlState = value;
    this.easyConfig = this._ngrxFormControlState?.userDefinedProperties?.easyConfig;
    this.decimals = this.easyConfig?.decimalPoints >= 0 ? this.easyConfig.decimalPoints : 10;
    if (value) {
      this.errors = value.errors;
      this.errorKeys = Object.keys(this.errors);
    }
  }

  get ngrxFormControlState(): FormControlState<number> {
    return this._ngrxFormControlState;
  }

  @Input() currencySymbol = '€';

  @Input()
  errorStateMatcher: ErrorStateMatcher = {
    isErrorState: (): boolean => {
      return this._ngrxFormControlState && this._ngrxFormControlState.isInvalid && this._ngrxFormControlState.isTouched;
    },
  };

  easyConfig: ConfigItemNumberConfigurationDtoModel;
  decimals = 2;

  errors: Dictionary<string> = {};
  errorKeys: string[] = [];

  disabledByCVA = false;
  private _viewValue = '';
  destroy$ = new EventEmitter<void>();
  internalViewValueChange$ = new EventEmitter<string>();
  internalValueChange$ = new EventEmitter<number>();
  change$: Observable<number> = merge(
    this.internalViewValueChange$.pipe(
      tap((v) => {
        CurrencyInputComponent.komma = (('' + v).indexOf(',') >= 0) || /^\d*$/g.test('' + v);
        CurrencyInputComponent.kommaChange$.emit(CurrencyInputComponent.komma);
      }),
      map((v) => {
        if (typeof v === 'string') {
          const parsed = Number(this.roundValue(parseFloat(v.replace(/,/, '.'))));
          return (!Number.isNaN(parsed) && parsed !== undefined) ? parsed : undefined;
        } else {
          return v;
        }
      }),
    ),
    this.internalValueChange$,
  );
  touched$ = new EventEmitter<boolean>();

  constructor(
    private zone: NgZone,
    private changeRef: ChangeDetectorRef,
  ) {
    this.change$.pipe(
      takeUntil(this.destroy$),
    ).subscribe((c) => this.viewValue = '' + this.roundValue(c));
    CurrencyInputComponent.kommaChange$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(() => this.zone.run(() => {
      this.viewValue = '' + this.viewValue;
      this.changeRef.detectChanges();
    }));
  }

  get viewValue(): string {
    return this._viewValue || '';
  }

  set viewValue(value: string) {
    if (typeof value === 'string' && value !== 'undefined') {
      this._viewValue = this.normalizeKomma(value);
    } else {
      this._viewValue = '';
    }
  }

  ngOnChanges(changes?: SimpleChanges): void {
    if (this.matInput) {
      this.matInput.ngOnChanges();
    }
  }

  normalizeKomma(value: string): string {
    return value?.replace(/[,.]/, CurrencyInputComponent.komma ? ',' : '.');
  }

  roundValue(value: number): string {
    if (typeof value === 'number') {
      const a = parseFloat(Number.parseFloat('' + value).toFixed(Math.max(this.decimals, 0))) + '';
      if ((a.includes(',') && a.length - a.indexOf(',') > 2 || a.includes('.') && a.length - a.indexOf('.') > 2)) {
        return a;
      } else {
        return parseFloat(a).toFixed(2);
      }
    } else {
      return value;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.emit();
    this.destroy$.complete();
  }

  registerOnChange(fn: (value: number) => any): void {
    this.change$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(fn);
  }

  registerOnTouched(fn): void {
    this.touched$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabledByCVA = isDisabled;
  }

  writeValue(value: number): void {
    if (typeof value === 'number') {
      const roundedValue = this.roundValue(value);
      const roundedNumberValue = Number.parseFloat(roundedValue);
      if (this.normalizeKomma(roundedValue) === this.normalizeKomma(this.viewValue)) {
        return;
      }
      if (this.writeTimeoutRef !== undefined) {
        clearTimeout(this.writeTimeoutRef);
      }
      this.writeTimeoutRef = setTimeout(() => {
        this.viewValue = roundedValue;
        this.internalValueChange$.emit(roundedNumberValue);
        this.changeRef.detectChanges();
      });
    } else {
      if (!value) {
        this.writeTimeoutRef = setTimeout(() => {
          this.internalValueChange$.emit(value);
        });
      }
    }
  }

}
