import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export interface LangItemModel {
  displayName: string;
  value: unknown;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {
  @Input() langs: LangItemModel[] = [];
  @Output() select = new EventEmitter<LangItemModel>();
  selectedLanguage: LangItemModel;

  @Input() set initialSelected(initialSelected: LangItemModel) {
    this.selectedLanguage = this.langs.find(l => l.value === initialSelected.value);
  }
}
