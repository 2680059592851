<div fxLayout="row" fxLayoutAlign="space-between center">
  <div>
    {{ data | i18n | async }}
  </div>
  <div>
    <button type="button" (click)="closeSnackbar()" mat-button>
      <mat-icon svgIcon="innogy:close"></mat-icon>
    </button>
  </div>
</div>
