<div>
  <section>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between"
      fxLayoutGap="10px grid"
    >
      <mat-form-field
        fxFlex.gt-md="49%"
        fxFlex.lt-lg="100%">
        <input [ngrxFormControlState]="key" matInput placeholder="Anrede FORM_ID" required>
        <mat-error *ngIf="key.errors.required">nicht da</mat-error>
      </mat-form-field>
      <mat-form-field
        fxFlex.gt-md="49%"
        fxFlex.lt-lg="100%">
        <input [ngrxFormControlState]="value" matInput placeholder="Anrede">
        <mat-error *ngIf="value.errors.required">nicht da</mat-error>
      </mat-form-field>
    </div>
  </section>
  <lib-common-input-lang-string
    (undoLangClick)="emitLangLabelReset($event)"
    *ngIf="langLabels"
    [strings]="langLabels"
    [undoVisible]="true"
  ></lib-common-input-lang-string>
  <button type="button" (click)="openPrompt()" mat-button>
    <mat-icon>delete</mat-icon>
    {{ 'remove' | i18n | async }}
  </button>
</div>
