<lib-common-page-margin>
  <div class="infoText" *ngIf="isEmpty((deputyCards$ | async))">
    {{'noDeputyTemplate' | i18n | async}}
  </div>
  <div *ngFor="let deputyEntry of (deputyCards$ | async | keyvalue)">
    <lib-common-tiles-container
      heading="{{deputyEntry.key}}"
      [tiles]="mapTemplateEntries(deputyEntry.value)"
      [closeButton]="false"
      [disableDrag]="true"
      [addCard]="false"
      [collapsedDisplayCount]="4"
    >
    </lib-common-tiles-container>
  </div>
</lib-common-page-margin>
