import * as moment from 'moment';
import { ValidationErrors } from 'ngrx-forms';
import { ConfigItemDaterangeConfigurationDtoModel } from '../../core-lib/models/config-item-daterange-configuration-dto.model';
import { ErrorDateFormat } from '../../core-lib/utils/error-date-format';

export const DateInvalidSuffix = 'EasyInvalidTime';

export class DateRangeValidationErrors {
  minRangeLength?: string;
  maxRangeLength?: string;
  maxStartInFuture?: number;
  minStartInFuture?: number;
  timeMin?: string;
  timeMax?: string;
  weekendsActive?: boolean;
  startMandatory?: boolean;
  endMandatory?: boolean;
  endBeforeStart?: boolean;
}

const isNumber = (p) => typeof p === 'number';

export const endDateRangeValidator = (
  config: ConfigItemDaterangeConfigurationDtoModel,
  startDateTime: string,
) => (endDateTime: string) => {
  if (!config || (!config.endDateVisible && !config.endTimeVisible)) {
    return {};
  }
  const errors: DateRangeValidationErrors = {};
  const startDateTimeMoment = moment(startDateTime);
  const endDateTimeMoment = moment(endDateTime);

  if ((!endDateTime || endDateTime.endsWith(DateInvalidSuffix)) && config.mandatory) {
    errors.endMandatory = config.mandatory;
  }
  if (
    isNumber(config.minRangeLength)
    && endDateTimeMoment.isBefore(startDateTimeMoment.clone().add(config.minRangeLength, 'seconds'))
  ) {
    errors.minRangeLength = startDateTimeMoment.clone().add(config.minRangeLength, 'seconds').format(ErrorDateFormat);
  }
  if (endDateTimeMoment.isBefore(startDateTimeMoment)) {
    errors.endBeforeStart = true;
  }
  if (
    isNumber(config.maxRangeLength)
    && endDateTimeMoment.isAfter(startDateTimeMoment.clone().add(config.maxRangeLength, 'seconds'))
  ) {
    errors.maxRangeLength = startDateTimeMoment.clone().add(config.maxRangeLength, 'seconds').format(ErrorDateFormat);
  }
  if (
    config.weekendsActive === false
    && endDateTimeMoment?.isoWeekday() > 5
  ) {
    errors.weekendsActive = config.weekendsActive;
  }
  return errors as ValidationErrors;
};

export const startDateRangeValidator = (
  config: ConfigItemDaterangeConfigurationDtoModel,
  _endDateTime: string,
) => (startDateTime: string) => {
  if (!config || (!config.startDateVisible && !config.startTimeVisible)) {
    return {};
  }
  const errors: DateRangeValidationErrors = {};
  const startDateTimeMoment = moment(startDateTime);
  const startTimeMoment = moment(startDateTimeMoment.format('HH:mm'), 'HH:mm');
  const timeMinMoment = moment(config.timeMin, 'HH:mm');
  const timeMaxMoment = moment(config.timeMax, 'HH:mm');

  if ((!startDateTime || startDateTime.endsWith(DateInvalidSuffix)) && config.mandatory) {
    errors.startMandatory = config.mandatory;
  }
  if (isNumber(config.maxStartInFuture) && startDateTimeMoment.isAfter(moment().add(config.maxStartInFuture, 'seconds'))) {
    errors.maxStartInFuture = config.maxStartInFuture;
  }
  if (isNumber(config.minStartInFuture) && startDateTimeMoment.isBefore(moment().add(config.minStartInFuture, 'seconds'))) {
    errors.minStartInFuture = config.minStartInFuture;
  }
  if (config.timeMin && startTimeMoment.isBefore(timeMinMoment)) {
    errors.timeMin = config.timeMin;
  }
  if (config.timeMax && startTimeMoment.isAfter(timeMaxMoment)) {
    errors.timeMax = config.timeMax;
  }
  if (config.weekendsActive === false && startDateTimeMoment.isoWeekday() > 5) {
    errors.weekendsActive = config.weekendsActive;
  }
  return errors;
};
