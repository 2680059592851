<div>
  <div>
    <mat-form-field>
      <mat-select
        [formControl]="companySelectCtrl"
        [placeholder]="data.companyI18n"
        #singleSelect
      >
        <mat-option>
          <ngx-mat-select-search
            [formControl]="companyFilterCtrl"
          ></ngx-mat-select-search>
        </mat-option>

        <mat-option
          *ngFor="let company of filteredCompanies | async"
          [value]="company"
        >
          {{ company.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <button
      type="button"
      color="primary"
      mat-raised-button
      (click)="onSubmit()"
    >
      {{ data.submitI18n }}
    </button>
  </div>
</div>
