import { backendClasses } from './backend-classes.model';
import { ConfigItemConfigurationDtoModel } from './config-item-configuration-dto.model';

export class ConfigItemNumberConfigurationDtoModel extends ConfigItemConfigurationDtoModel {
  static readonly CLASSNAME: string = backendClasses.numberConfiguration;
  static TYPENAME = 'typeNumber';
  '@class' = ConfigItemNumberConfigurationDtoModel.CLASSNAME;
  $$typeName = ConfigItemNumberConfigurationDtoModel.TYPENAME;

  max: number = undefined;
  min: number = undefined;
  defaultValue: number = undefined;
  decimalPoints = -1; // int
}
