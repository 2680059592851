import { ConfigurationItems } from '../../core-lib/models/config-dto.model';
import { ConfigItemAccountingConfigurationDtoModel } from '../../core-lib/models/config-item-accounting-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../core-lib/models/config-item-arranger-list-configuration-dto.model';
import { ConfigItemCheckboxConfigurationDtoModel } from '../../core-lib/models/config-item-checkbox-configuration-dto.model';
import { ConfigItemConfigurationCategoryModel } from '../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemDateConfigurationDtoModel } from '../../core-lib/models/config-item-date-configuration-dto.model';
import { ConfigItemFileUploadConfigurationDtoModel } from '../../core-lib/models/config-item-file-upload-configuration-dto.model';
import { ConfigItemLedgerAccountConfigurationDtoModel } from '../../core-lib/models/config-item-ledger-account-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../../core-lib/models/config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../../core-lib/models/config-item-text-input-configuration-dto.model';
import { PayeeDtoModel } from '../../forms/all-forms/models/payee-dto.model';
import { EqualKeyNames } from './equal-key-names';
import { FormAF17DtoModel } from '../../forms/form-af17/models/form-af17-dto.model';


export const EasyFormAF17Steps = {
  step1: <ConfigItemConfigurationCategoryModel>{
    id: 'step1',
    displayName: 'Gesellschaft',
  },
  step2: <ConfigItemConfigurationCategoryModel>{
    id: 'step2',
    displayName: 'Zahlungsempfänger',
  },
  step3: <ConfigItemConfigurationCategoryModel>{
    id: 'step3',
    displayName: 'Rechnungsangaben',
  },
};

// ToDo: Check ob dieses Config-Model auch via FormA1Step1,FormA1Step2,FormA1Step3 bzw FormA1State erzeugt werden kann.
export class EasyFormAF17ConfigItemsModel extends ConfigurationItems implements EqualKeyNames<FormAF17DtoModel>, EqualKeyNames<PayeeDtoModel> {
  payeeNumber = new ConfigItemTextInputConfigurationDtoModel('payeeNumber').withCategory(EasyFormAF17Steps.step2);

  // PayeeDtoModel
  salutation = new ConfigItemTextInputConfigurationDtoModel('salutation').withCategory(EasyFormAF17Steps.step2);
  name = new ConfigItemTextInputConfigurationDtoModel('name').withCategory(EasyFormAF17Steps.step2);
  street = new ConfigItemTextInputConfigurationDtoModel('street').withCategory(EasyFormAF17Steps.step2);
  zipCode = new ConfigItemTextInputConfigurationDtoModel('zipCode').withCategory(EasyFormAF17Steps.step2);
  city = new ConfigItemTextInputConfigurationDtoModel('city').withCategory(EasyFormAF17Steps.step2);
  country = new ConfigItemTextInputConfigurationDtoModel('country').withCategory(EasyFormAF17Steps.step2);
  email = new ConfigItemTextInputConfigurationDtoModel('email').withCategory(EasyFormAF17Steps.step2);
  iban = new ConfigItemTextInputConfigurationDtoModel('iban').withCategory(EasyFormAF17Steps.step2);
  bic = new ConfigItemTextInputConfigurationDtoModel('bic').withCategory(EasyFormAF17Steps.step2);

  // Verwendungszweck
  useCase = new ConfigItemTextInputConfigurationDtoModel('useCase').withCategory(EasyFormAF17Steps.step3);
  participatingDepartments = new ConfigItemTextInputConfigurationDtoModel('participatingDepartments').withCategory(EasyFormAF17Steps.step3);
  account = new ConfigItemAccountingConfigurationDtoModel('account').withCategory(EasyFormAF17Steps.step3);
  ledgerAccount = new ConfigItemLedgerAccountConfigurationDtoModel('ledgerAccount').withCategory(EasyFormAF17Steps.step3);
  numberOfPersons = new ConfigItemNumberConfigurationDtoModel('numberOfPersons').withCategory(EasyFormAF17Steps.step3);
  advancePayment = new ConfigItemNumberConfigurationDtoModel('advancePayment').withCategory(EasyFormAF17Steps.step3);
  payout = new ConfigItemNumberConfigurationDtoModel('payout').withCategory(EasyFormAF17Steps.step3);
  date = new ConfigItemDateConfigurationDtoModel('date').withCategory(EasyFormAF17Steps.step3);

  // Genehmigungsstufen
  approver = new ConfigItemArrangerListConfigurationDtoModel('approver').withCategory(EasyFormAF17Steps.step3);
  inspector = new ConfigItemArrangerListConfigurationDtoModel('inspector').withCategory(EasyFormAF17Steps.step3);
  commentForApprover = new ConfigItemTextInputConfigurationDtoModel('commentForApprover').withCategory(EasyFormAF17Steps.step3);

  file = new ConfigItemFileUploadConfigurationDtoModel('file').withCategory(EasyFormAF17Steps.step3);
  leaflet = new ConfigItemCheckboxConfigurationDtoModel('leaflet').withCategory(EasyFormAF17Steps.step3);
}
