import { backendClasses } from '../../../core-lib/models/backend-classes.model';

export class ListItemConfigurationDtoModel {
  '@class' = backendClasses.listItemConfiguration;
  id: string = undefined;
  attributeName?: string;
  mandatory?: boolean;
  enabled?: boolean;
  visible?: boolean;
  changeableByInspector?: boolean;
  changeableByApprover?: boolean;
  overwrite?: boolean;
  translations?: {
    [key: string]: {
      id?: string,
      lang?: string,
      text?: string,
      key?: string
    },
  };
  value?: string;
}
