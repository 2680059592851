<lib-common-input-property-wrapper
  [header]="attributeName"
  icon="event"
  [typeNameI18n]="typeName"
  [readonly]="readonly"
  [ngrxOverwrite]="ngrxOverwrite"
  (changeReadonly)="onChangeReadonly($event)"
>
  <div>
    <section>
      <div>
        <lib-common-input-property-defaults
          [ngrxMandatory]="ngrxMandatory"
          [ngrxEnabled]="ngrxEnabled"
          [ngrxVisible]="ngrxVisible"
          [ngrxChangeableByInspector]="ngrxChangeableByInspector"
          [ngrxChangeableByApprover]="ngrxChangeableByApprover"
        ></lib-common-input-property-defaults>
        <mat-checkbox
          *ngIf="ngrxStartDateVisible"
          class="checkbox-margin"
          [ngrxFormControlState]="ngrxStartDateVisible"
        >{{ 'startDateVisible' | i18n | async }}?
        </mat-checkbox>
        <mat-checkbox
          *ngIf="ngrxStartTimeVisible"
          class="checkbox-margin"
          [ngrxFormControlState]="ngrxStartTimeVisible"
        >{{ 'startTimeVisible' | i18n | async }}?
        </mat-checkbox>
        <mat-checkbox
          *ngIf="ngrxEndDateVisible"
          class="checkbox-margin"
          [ngrxFormControlState]="ngrxEndDateVisible"
        >{{ 'endDateVisible' | i18n | async }}?
        </mat-checkbox>
        <mat-checkbox
          *ngIf="ngrxEndTimeVisible"
          class="checkbox-margin"
          [ngrxFormControlState]="ngrxEndTimeVisible"
        >{{ 'endTimeVisible' | i18n | async }}?
        </mat-checkbox>
      </div>
    </section>
    <section>
      <mat-form-field
        *ngIf="ngrxMinRangeLength"
        class="fullwidth"
      >
        <input
          matInput
          [placeholder]="'minRangeLength' | i18n | async"
          [title]="'minRangeLength' | i18n | async"
          type="number"
          [ngrxFormControlState]="ngrxMinRangeLength"
        >
      </mat-form-field>
    </section>
    <section>
      <mat-form-field
        *ngIf="ngrxMaxRangeLength"
        class="fullwidth"
      >
        <input
          matInput
          [placeholder]="'maxRangeLength' | i18n | async"
          [title]="'maxRangeLength' | i18n | async"
          type="number"
          [ngrxFormControlState]="ngrxMaxRangeLength"
        >
      </mat-form-field>
    </section>
    <section>
      <mat-form-field
        *ngIf="ngrxMinStartInFuture"
        class="fullwidth"
      >
        <input
          matInput
          [placeholder]="'minStartInFuture' | i18n | async"
          [title]="'minStartInFuture' | i18n | async"
          type="number"
          [ngrxFormControlState]="ngrxMinStartInFuture"
        >
      </mat-form-field>
    </section>
    <section>
      <mat-form-field
        *ngIf="ngrxMaxStartInFuture"
        class="fullwidth"
      >
        <input
          matInput
          [placeholder]="'maxStartInFuture' | i18n | async"
          [title]="'maxStartInFuture' | i18n | async"
          type="number"
          [ngrxFormControlState]="ngrxMaxStartInFuture"
        >
      </mat-form-field>
    </section>
    <section>
      <mat-form-field
        *ngIf="ngrxStartPredefinedFromNow"
        class="fullwidth"
      >
        <input
          matInput
          [placeholder]="'startPredefinedFromNow' | i18n | async"
          [title]="'startPredefinedFromNow' | i18n | async"
          type="number"
          [ngrxFormControlState]="ngrxStartPredefinedFromNow"
        >
      </mat-form-field>
    </section>
    <section>
      <mat-form-field
        *ngIf="ngrxEndPredefinedFromNow"
        class="fullwidth"
      >
        <input
          matInput
          [placeholder]="'endPredefinedFromNow' | i18n | async"
          [title]="'endPredefinedFromNow' | i18n | async"
          type="number"
          [ngrxFormControlState]="ngrxEndPredefinedFromNow"
        >
      </mat-form-field>
    </section>
    <section
      *ngIf="ngrxWeekendsActive"
    >
      <mat-checkbox
        class="checkbox-margin"
        [ngrxFormControlState]="ngrxWeekendsActive"
      >
        {{ 'weekendsActive' | i18n | async }}
      </mat-checkbox>
    </section>

    <section>
      <mat-form-field
        *ngIf="ngrxTimeMin"
        class="fullwidth"
      >
        <input
          matInput
          [placeholder]="'timeMin' | i18n | async"
          [title]="'timeMin' | i18n | async"
          type="text"
          [ngrxFormControlState]="ngrxTimeMin"
        >
      </mat-form-field>
    </section>

    <section>
      <mat-form-field
        *ngIf="ngrxTimeMax"
        class="fullwidth"
      >
        <input
          matInput
          [placeholder]="'timeMax' | i18n | async"
          [title]="'timeMax' | i18n | async"
          type="text"
          [ngrxFormControlState]="ngrxTimeMax"
        >
      </mat-form-field>
    </section>

    <lib-common-input-property-lang-wrapper
      (labelResetClick)="startDateTranslationsLabelResetClick.emit($event)"
      [headerI18n]="'startDateTranslations'"
      [translations]="startDateTranslations"
      [undoVisible]="isEventAttached(startDateTranslationsLabelResetClick)"
    ></lib-common-input-property-lang-wrapper>

    <lib-common-input-property-lang-wrapper
      (labelResetClick)="startTimeTranslationsLabelResetClick.emit($event)"
      [headerI18n]="'startTimeTranslations'"
      [translations]="startTimeTranslations"
      [undoVisible]="isEventAttached(startTimeTranslationsLabelResetClick)"
    ></lib-common-input-property-lang-wrapper>

    <lib-common-input-property-lang-wrapper
      (labelResetClick)="endDateTranslationsLabelResetClick.emit($event)"
      [headerI18n]="'endDateTranslations'"
      [translations]="endDateTranslations"
      [undoVisible]="isEventAttached(endDateTranslationsLabelResetClick)"
    ></lib-common-input-property-lang-wrapper>

    <lib-common-input-property-lang-wrapper
      (labelResetClick)="endTimeTranslationsLabelResetClick.emit($event)"
      [headerI18n]="'endTimeTranslations'"
      [translations]="endTimeTranslations"
      [undoVisible]="isEventAttached(endTimeTranslationsLabelResetClick)"
    ></lib-common-input-property-lang-wrapper>
  </div>
</lib-common-input-property-wrapper>
