<lib-common-input-property-wrapper
  [header]="attributeName"
  icon="text_fields"
  [typeNameI18n]="typeName"
  [readonly]="readonly"
  [ngrxOverwrite]="ngrxOverwrite"
  (changeReadonly)="onChangeReadonly($event)"
>
  <div class="card-margin">
    <section>
      <mat-form-field *ngIf="ngrxRegex">
        <input
          matInput
          placeholder="Regular Expression"
          [ngrxFormControlState]="ngrxRegex"
        >
      </mat-form-field>
    </section>

    <section>
      <mat-form-field *ngIf="ngrxMinLength">
        <input
          matInput
          placeholder="Min Length"
          type="number"
          [ngrxFormControlState]="ngrxMinLength"
        >
      </mat-form-field>
    </section>

    <section>
      <mat-form-field *ngIf="ngrxMaxLength">
        <input
          matInput
          placeholder="Max Length"
          type="number"
          [ngrxFormControlState]="ngrxMaxLength"
        >
      </mat-form-field>
    </section>

    <section class="card-checkbox-margin">
      <lib-common-input-property-defaults
        [ngrxMandatory]="ngrxMandatory"
        [ngrxEnabled]="ngrxEnabled"
        [ngrxVisible]="ngrxVisible"
        [ngrxChangeableByInspector]="ngrxChangeableByInspector"
        [ngrxChangeableByApprover]="ngrxChangeableByApprover"
      ></lib-common-input-property-defaults>
    </section>

    <section
      *ngIf="ngrxPredefinedValue"
      class="margin-editor"
    >
      <h2 class="mat-h2 description">{{'coverLetterText' | i18n | async}}</h2>
      <lib-common-angular-editor
        [controlState]="ngrxPredefinedValue"
        [config]="editorConfig"
      >
      </lib-common-angular-editor>
    </section>

    <section *ngIf="translations">
      <h2 class="mat-h2 description">{{'translations' | i18n | async}}</h2>
      <lib-common-input-lang-string
        [translations]="translations"
        (undoLangClick)="labelResetClick.emit($event)"
        [undoVisible]="labelResetClick && labelResetClick.observers && labelResetClick.observers.length > 0"
      ></lib-common-input-lang-string>
    </section>
  </div>
</lib-common-input-property-wrapper>
