<mat-accordion *ngFor="let childForm of childForms">
  <lib-common-category-child-form
    *ngIf="childForm && hasNeededRole(childForm.neededRole)"
    [hasOCRSupport]="childForm.hasOCRSupport"
    [hasTemplateSupport]="childForm.hasTemplateSupport"
    [identifier]="childForm.identifier"
    [isFavorite]="childForm.isFavorite"
    [name]="childForm.name"
    [routerLinkTemplate]="getRouterLink"
    (addFavoriteClicked)="addFavorite.emit(childForm)"
    (deleteFavoriteClicked)="deleteFavorite.emit(childForm)"
    (templateClicked)="openTemplateDialog.emit(childForm)"
    (ocrScan)="ocrScan.emit(childForm)"
    (ocrCam)="ocrCam.emit(childForm)"
  ></lib-common-category-child-form>
</mat-accordion>
<mat-accordion *ngFor="let childLink of childLinks">
  <lib-common-category-child-form
    *ngIf="childLink?.href"
    [name]="childLink.displayName"
    [href]="childLink.href"
    (addFavoriteClicked)="addFavorite.emit($event)"
    (deleteFavoriteClicked)="deleteFavorite.emit($event)"
  ></lib-common-category-child-form>
</mat-accordion>
<mat-accordion *ngFor="let tree of childTrees">
  <div *ngIf="tree.childForms.length > 0 || getChildTrees(tree).length > 0 || tree.childrenLinks.length > 0">
    <mat-expansion-panel
      [expanded]="appConfigService?.config?.expandedCategoryTree"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>{{ tree.displayName }}</h3>
          <button
            type="button"
            mat-button
            (click)="openInfoDialog.emit({$event: $event, tree: tree})"
            title="{{ 'infoIconText' | i18n | async }}"
            *ngIf="showInfoHint(tree)"
          >
            <mat-icon
              class="svgInfo"
              svgIcon="innogy:info"
            ></mat-icon>
          </button>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <lib-common-category-tree-element
        [childTrees]="getChildTrees(tree)"
        [childLinks]="tree.childrenLinks"
        [childForms]="tree.childForms"
        [userRoles]="userRoles"
        [getRouterLink]="getRouterLink"
        (addFavorite)="addFavorite.emit($event)"
        (deleteFavorite)="deleteFavorite.emit($event)"
        (addTemplate)="addTemplate.emit($event)"
        (deleteTemplate)="deleteTemplate.emit($event)"
        (ocrScan)="ocrScan.emit($event)"
        (ocrCam)="ocrCam.emit($event)"
        (openInfoDialog)="openInfoDialog.emit($event)"
        (openTemplateDialog)="openTemplateDialog.emit($event)"
        [showInfoHint]="showInfoHint"
      ></lib-common-category-tree-element>
    </mat-expansion-panel>
  </div>
</mat-accordion>
