import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroupState } from 'ngrx-forms';
import { ConfigItemArrangerConfigurationDtoModel } from '../../models/config-item-arranger-configuration-dto.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-input-property-type-arranger',
  templateUrl: './input-property-type-arranger.component.html',
  styleUrls: ['./input-property-type-arranger.component.scss']
})
export class InputPropertyTypeArrangerComponent {

  typeName = ConfigItemArrangerConfigurationDtoModel.TYPENAME;

  private _ngrxValue: FormGroupState<ConfigItemArrangerConfigurationDtoModel>;
  @Input() attributeName: string;
  @Output() removeClick = new EventEmitter<number>();

  get ngrxValue(): FormGroupState<ConfigItemArrangerConfigurationDtoModel> {
    return this._ngrxValue;
  }

  @Input()
  set ngrxValue(value: FormGroupState<ConfigItemArrangerConfigurationDtoModel>) {
    this._ngrxValue = value;
    const names = this._ngrxValue.id.split('.');
    this.index = parseInt(names[names.length - 1], 10);
  }

  index: number;
}
