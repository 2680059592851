export const facts = [
  {value: 'Netznutzungsabschlag', label: 'Netznutzungsabschlag'},
  {value: 'Sperrung', label: 'Sperrung'},
  {value: 'Sponsoring', label: 'Sponsoring'},
  {value: 'Straßenbeleuchtung', label: 'Straßenbeleuchtung'},
  {value: 'Stromsteuerfreier Betrag', label: 'Stromsteuerfreier Betrag'},
  {value: 'Trommelleihzeit', label: 'Trommelleihzeit'},
  {value: 'Zertifizierung', label: 'Zertifizierung'},
  {value: 'Zinsberechnung', label: 'Zinsberechnung'},
  {value: 'Sonstiges', label: 'Sonstiges'},
  {value: 'Schäden', label: 'Schäden'},
  {value: 'e-Portal', label: 'e-Portal'},
  {value: 'Fuhrpark', label: 'Fuhrpark'},
  {value: 'Personal', label: 'Personal'},
  {value: 'Porto', label: 'Porto'},
  {value: 'Störungsaufklärung', label: 'Störungsaufklärung'},
];
