import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-common-page-error-message',
  templateUrl: './page-error-message.component.html',
  styleUrls: ['./page-error-message.component.scss']
})
export class PageErrorMessageComponent {
  @Input() errorMessage: string;
}
