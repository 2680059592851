<mat-checkbox
  *ngIf="ngrxMandatory"
  class="checkbox-margin"
  [ngrxFormControlState]="ngrxMandatory"
>{{'required' | i18n | async}}?
</mat-checkbox>
<mat-checkbox
  *ngIf="ngrxEnabled"
  class="checkbox-margin"
  [ngrxFormControlState]="ngrxEnabled"
>{{'editable' | i18n | async}}?
</mat-checkbox>
<mat-checkbox
  *ngIf="ngrxVisible"
  class="checkbox-margin"
  [ngrxFormControlState]="ngrxVisible"
>{{'visible' | i18n | async}}?
</mat-checkbox>
<mat-checkbox
  *ngIf="ngrxChangeableByApprover"
  class="checkbox-margin"
  [ngrxFormControlState]="ngrxChangeableByApprover"
>{{'changeableByApprover' | i18n | async}}?
</mat-checkbox>
<mat-checkbox
  *ngIf="ngrxChangeableByInspector"
  class="checkbox-margin"
  [ngrxFormControlState]="ngrxChangeableByInspector"
>{{'changeableByInspector' | i18n | async}}?
</mat-checkbox>

