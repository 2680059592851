import { createAction, props } from '@ngrx/store';
import { AccountDeputyDtoModel } from '../../models/account-deputy-dto.model';
import { ActionCreatorsClass } from '../../models/action-creators.class';

export class CoreAccountDeputiesActions extends ActionCreatorsClass {
  AccountDeputiesSaveAction = createAction(
    'AccountDeputiesSaveAction',
    props<{
      userId: string,
    }>(),
  );
  AccountDeputiesLoadAction = createAction(
    'AccountDeputiesLoadAction',
    props<{
      userId: string,
    }>(),
  );
  AccountDeputiesLoadedAction = createAction(
    'AccountDeputiesLoadedAction',
    props<{
      result: AccountDeputyDtoModel[],
    }>(),
  );
  AccountDeputiesSaveSuccessAction = createAction(
    'AccountDeputiesSaveSuccessAction',
    props<{
      result: AccountDeputyDtoModel[],
    }>(),
  );
  AccountDeputiesSaveErrorAction = createAction(
    'AccountDeputiesSaveErrorAction',
  );
  AccountDeputiesAddEditorAction = createAction(
    'AccountDeputiesAddEditorAction',
  );
  AccountDeputiesAddApproverAction = createAction(
    'AccountDeputiesAddApproverAction',
  );
  AccountDeputiesRemoveEditorAction = createAction(
    'AccountDeputiesRemoveEditorAction',
    props<{
      formElementId: string,
    }>(),
  );
  AccountDeputiesRemoveApproverAction = createAction(
    'AccountDeputiesRemoveApproverAction',
    props<{
      formElementId: string,
    }>(),
  );
}

export const accountDeputiesActions = new CoreAccountDeputiesActions();
export type CoreAccountDeputiesActionsType = Record<string & keyof CoreAccountDeputiesActions, CoreAccountDeputiesActions>;
