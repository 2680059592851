export function generateImport(identifierOrOptions: string | {identifier: string, asIdentifier?: string}) {
  let identifier: string;
  let asIdentifier: string;
  if (typeof identifierOrOptions === 'string') {
    identifier = identifierOrOptions;
    asIdentifier = identifierOrOptions;
  } else {
    identifier = identifierOrOptions.identifier;
    asIdentifier = identifierOrOptions.asIdentifier || identifierOrOptions.identifier;
  }
  const lowerCaseIdentifier = identifier.toLocaleLowerCase();

  return {
    [asIdentifier]: () => import(`../../forms/form-${lowerCaseIdentifier}/form-${lowerCaseIdentifier}-with-routing.module`).then(
      (m) => m[`Form${identifier}WithRoutingModule`]
    ),
  };
}

export function generateImports(identifierOrOptions: (string | {identifier: string, asIdentifier?: string})[]) {
  let ret = {};
  for (const input of identifierOrOptions) {
    ret = {
      ...ret,
      ...generateImport(input),
    };
  }
  return ret;
}
