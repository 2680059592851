import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { box } from 'ngrx-forms';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ConfigDtoModel } from '../../../core-lib/models/config-dto.model';
import { ApiService } from '../../../core-lib/services/api.service';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { EasyFormConfigItemsFactoryService } from '../../../core-lib/utils/easy-form-config-factory';
import { rxCatchApiError } from '../../../core-lib/utils/reducer-utils';
import {
  CoreEasyFormsDeinheritFormConfigAction,
  CoreEasyFormsDeleteFormConfigsAction,
  CoreEasyFormsDeleteFormConfigsErrorAction,
  CoreEasyFormsDeleteFormConfigsSuccessAction,
  CoreEasyFormsEnableConfigAction,
  CoreEasyFormsInheritFormConfigAction,
  CoreEasyFormsInitAction,
  CoreEasyFormsLoadFormConfigAction,
  CoreEasyFormsLoadFormConfigErrorAction,
  CoreEasyFormsLoadFormConfigsByFormIdAction,
  CoreEasyFormsLoadFormConfigsByFormIdSuccessAction,
  CoreEasyFormsLoadFormConfigSuccessAction,
  CoreEasyFormsLoadFormsAction,
  CoreEasyFormsLoadFormsErrorAction,
  CoreEasyFormsLoadFormsSuccessAction,
  CoreEasyFormsReadyAction,
  CoreEasyFormsSaveFormConfigAction,
  CoreEasyFormsSaveFormConfigErrorAction,
  CoreEasyFormsSaveFormConfigSuccessAction,
} from '../actions/core-easy-forms.actions';
import { CoreActionInit } from '../actions/core.actions';
import { CoreFeatureState } from '../reducers/core.store';
import { getEasyFormConfigsSelectedValueState } from '../reducers/easy-form-configs.reducer';

@Injectable()
export class CoreEasyFormsEffects {
  static readonly CORE_SPINNER_HANDLE = 'CoreEasyFormsEffectsService';

  coreInit$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActionInit.TYPE),
    map(() => new CoreEasyFormsInitAction()),
  ), defaultEffectOptions());

  init$ = createEffect(() => this.actions$.pipe(
    ofType(CoreEasyFormsInitAction.TYPE),
    tap(() => this.store$.dispatch(new CoreEasyFormsLoadFormsAction())),
  ), defaultEffectOptions({dispatch: false}));

  ready$ = createEffect(() => this.actions$.pipe(
    ofType(CoreEasyFormsLoadFormsSuccessAction.TYPE),
    map(() => new CoreEasyFormsReadyAction()),
  ), defaultEffectOptions());

  loadForms$ = createEffect(() => this.actions$.pipe(
    ofType(CoreEasyFormsLoadFormsAction.TYPE),
    switchMap(() => this.api.getEasyForms().pipe(
      map((forms) => new CoreEasyFormsLoadFormsSuccessAction(forms)),
      catchError(e => of(new CoreEasyFormsLoadFormsErrorAction(e))),
    )),
  ), defaultEffectOptions());

  loadFormConfig$ = createEffect(() => this.actions$.pipe(
    ofType<CoreEasyFormsLoadFormConfigAction>(CoreEasyFormsLoadFormConfigAction.TYPE),
    switchMap((a) => {
      return this.api.getEasyFormConfig(a.formConfigIdentity.formIdentifier, a.formConfigIdentity.companyShort).pipe(
        map(config => {
          if (config) {
            return {
              ...config,
              configurationItems: this.metaConfigService.extend(
                a.formConfigIdentity.formIdentifier,
                config.configurationItems,
              ),
            };
          } else {
            return new ConfigDtoModel<any>(
              this.metaConfigService.create(a.formConfigIdentity.formIdentifier),
              a.formConfigIdentity.formId,
              a.formConfigIdentity.companyId,
            );
          }
        }),
        map((form: ConfigDtoModel<any>) => new CoreEasyFormsLoadFormConfigSuccessAction(form, a.formConfigIdentity)),
        catchError(e => of(new CoreEasyFormsLoadFormConfigErrorAction(e, e.message))),
      );
    }),
  ), defaultEffectOptions());

  initializeConfigFields$ = createEffect(() => this.actions$.pipe(
    ofType(CoreEasyFormsLoadFormConfigSuccessAction.TYPE),
    map(() => new CoreEasyFormsEnableConfigAction()),
  ), defaultEffectOptions());

  inheritFormConfig$ = createEffect(() => this.actions$.pipe(
    ofType<CoreEasyFormsInheritFormConfigAction>(CoreEasyFormsInheritFormConfigAction.TYPE),
    withLatestFrom(this.store$.select(getEasyFormConfigsSelectedValueState)),
    switchMap(([a, currentConfig]) => {
      return this.api.getEasyFormConfig(a.parentFormConfigIdentity.formIdentifier, a.parentFormConfigIdentity.companyShort).pipe(
        map(config => {
          for (const key of Object.keys(config.configurationItems)) {
            config.configurationItems[key].overwrite = false;
          }
          return {
            ...config,
            id: currentConfig.id,
            company: currentConfig.company,
            parent: box(config),
            configurationItems: this.metaConfigService.extend(
              a.parentFormConfigIdentity.formIdentifier,
              config.configurationItems,
            ),
          };
        }),
        map((form: ConfigDtoModel<any>) => new CoreEasyFormsLoadFormConfigSuccessAction(form, a.parentFormConfigIdentity)),
        catchError((e: Error) => of(new CoreEasyFormsLoadFormConfigErrorAction(e, e.message))),
      );
    }),
  ), defaultEffectOptions());

  deinheritFormConfig$ = createEffect(() => this.actions$.pipe(
    ofType<CoreEasyFormsDeinheritFormConfigAction>(CoreEasyFormsDeinheritFormConfigAction.TYPE),
    withLatestFrom(this.store$.select(getEasyFormConfigsSelectedValueState)),
    map(([a, selectedConfig]: [CoreEasyFormsDeinheritFormConfigAction, ConfigDtoModel<any>])
      : [CoreEasyFormsDeinheritFormConfigAction, ConfigDtoModel<any>] => ([
      a,
      {
        ...selectedConfig,
        parent: undefined,
        configurationItems: {
          ...this.metaConfigService.create(a.formConfigIdentity.formIdentifier),
        },
      },
    ])),
    map(([a, selectedConfig]) => new CoreEasyFormsLoadFormConfigSuccessAction(selectedConfig, a.formConfigIdentity)),
  ), defaultEffectOptions());

  deleteFormConfigs$ = createEffect(() => this.actions$.pipe(
    ofType(CoreEasyFormsDeleteFormConfigsAction.TYPE),
    map((a: CoreEasyFormsDeleteFormConfigsAction) => a.configs),
    switchMap((configs) =>
      this.api.deleteEasyFormConfigs(configs).pipe(
        mergeMap(() => [
          new CoreEasyFormsDeleteFormConfigsSuccessAction(),
          new CoreEasyFormsLoadFormsAction(),
        ]),
        catchError(e => of(new CoreEasyFormsDeleteFormConfigsErrorAction(e))),
      )),
  ), defaultEffectOptions());

  saveFormConfig$ = createEffect(() => this.actions$.pipe(
    ofType<CoreEasyFormsSaveFormConfigAction>(CoreEasyFormsSaveFormConfigAction.TYPE),
    withLatestFrom(this.store$.select(getEasyFormConfigsSelectedValueState)),
    switchMap(([_a, value]) => this.api.postEasyFormConfig(
      value,
    ).pipe(
      mergeMap(() => [
        new CoreEasyFormsSaveFormConfigSuccessAction(),
        new CoreEasyFormsLoadFormsAction(),
      ]),
      rxCatchApiError({}),
      catchError(e => of(new CoreEasyFormsSaveFormConfigErrorAction(e))),
    )),
  ), {
    dispatch: true,
    useEffectsErrorHandler: true,
  });

  loadFormConfigsByFormId$ = createEffect(() => this.actions$.pipe(
    ofType(CoreEasyFormsLoadFormConfigsByFormIdAction.TYPE),
    switchMap((a: CoreEasyFormsLoadFormConfigsByFormIdAction) => this.api.getEasyFormConfigs(
      a.configIds.formIdentifier,
    ).pipe(
      map((result) => new CoreEasyFormsLoadFormConfigsByFormIdSuccessAction(result, a.configIds.formIdentifier)),
      rxCatchApiError({}),
      catchError(e => of(new CoreEasyFormsSaveFormConfigErrorAction(e))),
    )),
  ), {
    dispatch: true,
    useEffectsErrorHandler: true,
  });

  constructor(
    private actions$: Actions,
    private store$: Store<CoreFeatureState>,
    private api: ApiService,
    private metaConfigService: EasyFormConfigItemsFactoryService,
  ) {
  }
}
