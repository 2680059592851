<div #footer>
  <div class="grey-footer">
    <div
      fxLayout="column wrap"
      fxLayoutAlign="flex-start"
      fxLayoutGap="10px grid"
    >
      <div>
        <button *ngIf="false" type="button" mat-button class="btn-footer" disabled>
          <mat-icon class="svgIcon-footer" svgIcon="innogy:c-arrow-right"></mat-icon>
          {{ 'glossary' | i18n | async }}
        </button>
        <a
          mat-raised-button
          color="primary"
          class="btn-footer-contact"
          [href]="mailTo"
        >
          <mat-icon svgIcon="innogy:headphone"></mat-icon>
          {{ 'contactSupport' | i18n | async }}
        </a>
      </div>
      <div>
        <a
          *ngIf="footerConfig.supportType === 'defaultMail'"
          mat-raised-button
          color="primary"
          class="btn-footer-contact"
          [href]="getSupportMailLink('mailReportFaultSubject' | i18n | async, 'mailReportFault' | i18n | async)"
        >
          <mat-icon svgIcon="innogy:blinking-light"></mat-icon>
          {{ 'reportFault' | i18n | async }}
        </a> <a
        *ngIf="footerConfig.supportType === 'link'"
        mat-raised-button
        color="primary"
        class="btn-footer-contact"
        target="_blank"
        [href]="footerConfig.supportLink"
      >
        <mat-icon svgIcon="innogy:blinking-light"></mat-icon>
        {{ 'reportFault' | i18n | async }}
      </a>
      </div>
    </div>
  </div>
  <div class="footer">
    <div
      fxLayout="row wrap"
      fxLayoutAlign="flex-end"
      fxLayoutGap="10px grid"
    >
      <div fxFlex.gt-md="1"></div>
      <div>
        <a *ngIf="footerConfig.faqType === 'link'" [href]="footerConfig.faqPath" target="_blank" mat-button>
          {{ 'faq' | i18n | async }}
        </a> <a *ngIf="footerConfig.faqType === 'route'" mat-button [routerLink]="'faq'">
        {{ 'faq' | i18n | async }}
      </a>
      </div>
      <div>
        <a *ngIf="footerConfig.dataProtectionType === 'link'" [href]="footerConfig.dataProtectionPath" target="_blank"
           mat-button>
          {{ 'dataProtection' | i18n | async }}
        </a> <a *ngIf="footerConfig.dataProtectionType === 'route'" mat-button [routerLink]="'dataprotection'">
        {{ 'dataProtection' | i18n | async }}
      </a>
      </div>
      <div>
        <a *ngIf="footerConfig.legalDisclosureType === 'route'" mat-button [routerLink]="'legaldisclosure'">
          {{ 'legalDisclosure' | i18n | async }}
        </a> <a *ngIf="footerConfig.legalDisclosureType === 'link'" [href]="footerConfig.legalDisclosurePath" target="_blank" mat-button>
        {{ 'legalDisclosure' | i18n | async }}
      </a>
      </div>
    </div>
  </div>
</div>
<lib-common-version *ngIf="showVersion"></lib-common-version>
