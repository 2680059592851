<section class="section-margin">
  <div
    fxLayout="row wrap"
    fxLayoutAlign="space-between"
    fxLayoutGap="10px grid"
  >
    <mat-form-field
      fxFlex.lt-lg="100%"
      *ngFor="let lang of stringsArray; trackBy:trackByFn"
      [ngClass]="['langInput', class]"
    >
      <input
        matInput
        [placeholder]="lang.placeholder"
        [required]="lang.required"
        [ngrxFormControlState]="lang.state"
      >
      <button
        type="button"
        (click)="undoLangEmit(lang.state)"
        *ngIf="undoVisible"
        [disabled]="lang.state.isDisabled"
        aria-label="Clear"
        mat-button
        mat-icon-button
        matSuffix
      >
        <mat-icon>undo</mat-icon>
      </button>
      <mat-error *ngIf="lang.state.errors.required">{{ 'translationPlease' | i18n | async }}</mat-error>
      <mat-error *ngIf="lang.state.errors['test']">{{ lang.state.errors['test'] }}</mat-error>
    </mat-form-field>
  </div>
</section>
