<mat-form-field
  [appearance]="appearance"
  [floatLabel]="floatLabel"
  style="width: 100%"
>
  <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
  <input
    #model="ngModel"
    matInput
    [disabled]="disabled || disabledByCVA"
    [readonly]="readonly"
    [required]="required"
    [ngModel]="viewValue"
    (ngModelChange)="internalViewValueChange$.emit($event)"
    [ngModelOptions]="{updateOn: 'blur'}"
    (blur)="touched$.emit(true)"
    [errorStateMatcher]="errorStateMatcher"
    autocomplete="off"
    type="text"
    step="any"
  > <span
  matSuffix
>
      {{ currencySymbol }}
    </span>
  <mat-error *ngFor="let key of errorKeys">{{(key + "Error") | i18n: errors | async}}</mat-error>
</mat-form-field>
