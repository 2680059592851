import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { AddArrayControlAction, FormGroupState, FormState } from 'ngrx-forms';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CategoryDataDto } from '../../../core-lib/models/category-data-dto.model';
import { CategoryInfoModel } from '../../../core-lib/models/category-info.model';
import { FaqElementModel } from '../../../core-lib/models/faq-tree.model';
import { SetValueTraceableAction } from '../../../core-lib/models/set-value-traceable-action';
import {
  coreConfigCategoryInfoLoad,
  coreConfigCategoryInfoMarkForDeletion,
  coreConfigCategoryInfoSave,
} from '../../ngrx/actions/core-config.actions';
import {
  getConfigCategoriesFlatState,
  getConfigCategoriesFlatStateFiltered,
  getConfigCategoryInfoFormControls,
  getConfigCategoryInfoFormState,
} from '../../ngrx/reducers/core.store';

@Component({
  selector: 'lib-common-category-info',
  templateUrl: './route-config-category-info.component.html',
  styleUrls: ['./route-config-category-info.component.scss'],
})
export class RouteConfigCategoryInfoComponent implements OnInit, OnDestroy {

  categories$ = this.store$.select(getConfigCategoriesFlatState);
  categoryInfos$: Observable<FormGroupState<CategoryInfoModel>[]> = this.store$.select(getConfigCategoryInfoFormControls);

  isFormInvalid$ = this.store$.select(getConfigCategoryInfoFormState).pipe(
    map(s => s.isInvalid),
  );

  componentDestroy$ = new Subject();

  categories: CategoryDataDto[];

  arrayId: string;
  categoryInfos: CategoryInfoModel[];

  constructor(private store$: Store<any>) {
  }

  ngOnInit(): void {
    this.store$.dispatch(coreConfigCategoryInfoLoad());
    this.categories$.pipe(takeUntil(this.componentDestroy$)).subscribe(s => this.categories = s);
    this.store$.select(getConfigCategoryInfoFormState).pipe(takeUntil(this.componentDestroy$)).subscribe(s => this.categoryInfos = s.value);
    this.store$.select(getConfigCategoryInfoFormState).pipe(takeUntil(this.componentDestroy$)).subscribe(s => this.arrayId = s.id);
  }

  ngOnDestroy(): void {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }


  getControlId = (i: number, t: FormGroupState<FaqElementModel>) => t.id;

  getDisplayText: (e: CategoryDataDto) => any = (e) => e ? e.displayName : '';

  getTitle(categoryId: string) {
    return this.categories.find(c => c.id === categoryId)?.displayName;
  }

  getOptions(category: string) {
    return this.store$.select(
      getConfigCategoriesFlatStateFiltered,
      {categoryInfos: this.categoryInfos, currentSelectedCategory: category},
    );
  }

  addCategory() {
    this.store$.dispatch(new AddArrayControlAction(
      this.arrayId,
      {language: 'de', category: undefined, infoText: '', markedForDeletion: false, open: true} as CategoryInfoModel,
    ));
  }

  save() {
    this.store$.dispatch(coreConfigCategoryInfoSave());
  }

  removeCategoryInfo(index: number) {
    this.store$.dispatch(coreConfigCategoryInfoMarkForDeletion({index}));
  }

  open(open: FormState<CategoryInfoModel['open']>, $event: boolean) {
    this.store$.dispatch(new SetValueTraceableAction(open.id, $event));
  }
}
