import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AddArrayControlAction, FormGroupState, RemoveArrayControlAction } from 'ngrx-forms';
import { takeUntil } from 'rxjs/operators';
import { Room } from '../../models/room.model';
import { CoreConfigLocationRoomSave } from '../../ngrx/actions/core-config.actions';
import { getConfigLocationNameById, getConfigRoomFormControls, getConfigRoomFormState } from '../../ngrx/reducers/core.store';

@Component({
  selector: 'lib-common-route-config-room',
  templateUrl: './route-config-room.component.html',
  styleUrls: ['./route-config-room.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteConfigRoomComponent implements OnInit, OnDestroy {

  roomArrayState$ = this.store$.select(getConfigRoomFormControls);
  locationName$ = this.store$.select(getConfigLocationNameById, {locationId: this.route.snapshot.params.id});
  private arrayId: string;
  private componentDestroyed$ = new EventEmitter();
  getControlId = (i: number, t: FormGroupState<Room>) => t.id;

  constructor(private store$: Store<any>, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.store$.select(getConfigRoomFormState).pipe(takeUntil(this.componentDestroyed$)).subscribe(state => this.arrayId = state.id);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  removeRoom(index: number) {
    this.store$.dispatch(new RemoveArrayControlAction(this.arrayId, index));
  }

  addRoom() {
    this.store$.dispatch(new AddArrayControlAction(this.arrayId, new Room(this.route.snapshot.params.id)));
  }

  save() {
    this.store$.dispatch(new CoreConfigLocationRoomSave(this.route.snapshot.params.id));
  }
}
