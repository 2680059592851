import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-main-screen',
  templateUrl: './main-screen.component.html',
  styleUrls: ['./main-screen.component.scss'],
})
export class MainScreenComponent {
  @Input() isAdmin$;
  @Input() isCaterer$;

  sidebarOpen = false;

  footerHeight: number;

  setFooterProps = ({height: hight}) => {
    setTimeout(() => this.footerHeight = (hight / 1.5));
  };

  getFooterPadding = () => this.footerHeight ? `${this.footerHeight}px` : '';
}
