import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ocrActions } from '../../../core/ngrx/actions/ocr.actions';
import { CoreFeatureState, getCompaniesByDynamicFormIdentifierState } from '../../../core/ngrx/reducers/core.store';
import { getOcrFormCompanyIdState, getOcrFormState } from '../../../core/ngrx/reducers/ocr.store';
import { CompanyDataDtoModel } from '../../models/company-data-dto.model';
import { OcrEventModel } from '../../models/ocr-event.model';
import { OcrPromptReturnModel } from '../../models/ocr-prompt-return.model';
import { OcrPromptModel } from '../../models/ocr-prompt.model';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'lib-common-ocr-prompt',
  templateUrl: './ocr-prompt.component.html',
  styleUrls: ['./ocr-prompt.component.scss'],
})
export class OcrPromptComponent implements OnDestroy {
  file: string;
  fileName: string;
  ocrFormCompanyShortName: string;
  event = OcrEventModel;

  destroy$ = new Subject();
  options$ = this.store$.select(getCompaniesByDynamicFormIdentifierState, {
    identifier: this.data.formIdentifier,
  });
  ocrFormCompanyIdState$ = this.store$.select(getOcrFormCompanyIdState);
  ocrFormValid$ = this.store$.select(getOcrFormState).pipe(
    map((s) => s.isValid && s.isTouched),
  );

  constructor(
    public dialogRef: MatDialogRef<OcrPromptComponent, OcrPromptReturnModel>,
    @Inject(MAT_DIALOG_DATA) public data: OcrPromptModel,
    protected store$: Store<CoreFeatureState>,
  ) {
    this.ocrFormCompanyIdState$.pipe(
      takeUntil(this.destroy$),
    ).subscribe((source) => this.ocrFormCompanyShortName = source.value);
  }

  ngOnDestroy(): void {
    this.store$.dispatch(ocrActions.OcrFormResetAction());
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDisplayText = (e) => e ? (e.name + ' - ' + e.companyId) : '';
  getElementId = (e: CompanyDataDtoModel) => e.shortName;

  changeFile(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.file = <string>reader.result;
        this.fileName = file.name;
      };
    }
  }

  close(event: OcrEventModel): void {
    this.dialogRef.close({
      file: this.file,
      event: event,
      companyShortName: this.ocrFormCompanyShortName,
    });
  }
}
