import { Directive, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { BehaviorSubject, combineLatest, Observable, of, pipe, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import {
  QueryPromptMessageActionsComponent
} from '../../core-lib/components/query-prompt-message-actions/query-prompt-message-actions.component';
import { QueryPromptComponent } from '../../core-lib/components/query-prompt/query-prompt.component';
import { PageableModel } from '../../core-lib/models/pageable.model';
import { ProposalHeadModel, ProposalState } from '../../core-lib/models/proposal-head.model';
import { QueryPromptData } from '../../core-lib/models/query-prompt-data.model';
import { QueryPromptMessageActionsModel } from '../../core-lib/models/query-prompt-message-actions.model';
import { SortFilterPageModel } from '../../core-lib/models/sort-filter-page.model';
import { TableColumnConfigModel } from '../../core-lib/models/table-column-config.model';
import { TableFilterConfigModel } from '../../core-lib/models/table-filter-config.model';
import { UserSearchModel } from '../../core-lib/models/user-search.model';
import { I18nPipe } from '../../core-lib/pipes/i18n.pipe';
import { getSessionIsLoggedIn, getSessionUserIsAdmin } from '../../session/reducers/session.reducer';
import { TableColumnDefModel } from '../models/table-column-def.model';
import { TableSelectionModel } from '../models/table-selection.model';
import { TableActionCreator } from '../ngrx/actions/core-overview-table.actions';
import {
  getOverviewTableColumnConfig,
  getOverviewTableFilterConfig,
  getOverviewTablePage,
  isOverviewTableLoading,
} from '../ngrx/reducers/core-overview-table.selector';

import { CoreFeatureState } from '../ngrx/reducers/core.store';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive()
// tslint:disable-next-line:directive-class-suffix
export class OverviewTableBase<T> implements OnDestroy, OnInit {
  message: string;
  info: string;
  saveButtonVisible: boolean;
  filterButtonVisible: boolean;

  messages = { emptyMessage: 'No data to display', totalMessage: 'total', selectedMessage: 'selected' };
  componentDestroyed$ = new Subject<void>();

  initSortFilterPageConfig: SortFilterPageModel;

  sortFilterPageConfig: SortFilterPageModel;

  tableColumnConfig: (TableColumnConfigModel & TableColumnDefModel<any>)[] = [];

  @Input()
  type: keyof TableSelectionModel<T> = 'OWN';

  isLoading$: Observable<boolean> = of(false);

  page$: Observable<PageableModel<T>> = of({} as PageableModel<T>);

  content$: Observable<PageableModel<T>['content']> = of([]);

  totalElements$: Observable<PageableModel<T>['totalElements']> = of(0);

  number$: Observable<PageableModel<T>['number']> = of(0);

  size$: Observable<PageableModel<T>['size']> = of(0);

  templates$ = new BehaviorSubject<Dictionary<Partial<TableColumnConfigModel> & TableColumnDefModel<any>>>(undefined);

  columns$: Observable<(TableColumnConfigModel & TableColumnDefModel<any>)[]> = of([]);
  hideableColumns$: Observable<(TableColumnConfigModel & TableColumnDefModel<any>)[]> = of([]);

  columnsPipe = pipe(
    map(([tableColumnConfig, templates]) => ({
      tableColumnConfig: tableColumnConfig,
      templates: <Dictionary<TableColumnConfigModel & TableColumnDefModel<any>>>templates,
    })),
    filter(({ tableColumnConfig, templates }) => !!templates && !!tableColumnConfig),
    map(
      ({ tableColumnConfig, templates }): Dictionary<TableColumnConfigModel & TableColumnDefModel<any>> => Object.entries(templates).reduce(
        (acc, [key, template]) => {
          const columnConfig = tableColumnConfig.find(c => c.columnName === key)
            || tableColumnConfig.find(c => c.columnName === template.columnName) || {} as TableColumnConfigModel;
          return (
            {
              ...acc,
              [key]: {
                ...template,
                position: columnConfig.position,
                visible: columnConfig.visible || template.alwaysVisible,
                id: columnConfig.id,
                width: columnConfig.width,
              },
            }
          );
        },
        {},
      ),
    ),
    map(tableColumnMap => Object.values(tableColumnMap).sort((a: any, b: any) => a.position - b.position)),
    tap((tableColumnConfig) => {
      this.tableColumnConfig = tableColumnConfig;
    }),
  );

  isAdmin$: Observable<boolean> = this.store$.select(getSessionUserIsAdmin);

  autor: UserSearchModel;
  arranger: UserSearchModel;

  @ViewChild('table', { static: true })
  table: DatatableComponent;
  private filters: TableFilterConfigModel[] = [];

  constructor(
    protected store$: Store<CoreFeatureState>,
    protected dialog: MatDialog,
    protected i18nPipe: I18nPipe,
    private actionCreator: TableActionCreator,
    private tableType: 'proposals' | 'catering',
  ) {
    this.i18nPipe.transform('noData').pipe(take(1)).subscribe((nodata) => this.messages.emptyMessage = nodata);
    this.i18nPipe.transform('totalCount').pipe(take(1)).subscribe((totalCount) => this.messages.totalMessage = totalCount);
    this.i18nPipe.transform('selected').pipe(take(1)).subscribe((selected) => this.messages.selectedMessage = selected);
  }

  dispatchTableLoad() {
    if (this.sortFilterPageConfig && !this.sortFilterPageConfig.selection) {
      this.sortFilterPageConfig.selection = 'OWN';
    }
    if (this.sortFilterPageConfig) {
      this.store$.dispatch(this.actionCreator.tableLoad(this.sortFilterPageConfig));
    }
  }

  ngOnInit() {
    this.initSortFilterPageConfig.selection = this.type;

    /*
     durch Effekt ersetzen -> Kann nicht durch einen Effekt ersetzt werden da "this.type" nur in der componente bekannt ist und auch
     erst nach / wärend dem ngOnInit den richtigen Wert hat
     */
    this.store$.select(getSessionIsLoggedIn).pipe(
      takeUntil(this.componentDestroyed$),
      filter(isLoggedIn => isLoggedIn),
    ).subscribe(() => this.store$.dispatch(this.actionCreator.tableConfigLoad({ selection: this.type })));

    this.columns$ = combineLatest([
      this.store$.select(
        getOverviewTableColumnConfig,
        { tableType: this.tableType, type: this.type },
      ),
      this.templates$,
    ]).pipe(this.columnsPipe);

    this.hideableColumns$ = this.columns$.pipe(
      map(cs => cs.filter(c => !c.alwaysVisible)),
    );

    this.isLoading$ = this.store$.select(isOverviewTableLoading, { tableType: this.tableType, type: this.type });

    this.page$ = this.store$.select(getOverviewTablePage, { tableType: this.tableType, type: this.type });

    this.content$ = this.page$.pipe(
      map(page => page?.content),
    );

    this.totalElements$ = this.page$.pipe(
      map(page => page?.totalElements),
    );

    this.number$ = this.page$.pipe(
      map(page => page?.number),
    );

    this.size$ = this.page$.pipe(
      map(page => page?.size),
    );

    this.store$.select(
      getOverviewTableFilterConfig,
      { tableType: this.tableType, type: this.type },
    ).pipe(takeUntil(this.componentDestroyed$)).subscribe(configFilters => {
      this.filters = configFilters;

      const predefFilter = configFilters
        .reduce((previousValue, currentValue) => ({ ...previousValue, [currentValue.filterName]: currentValue.filterValue }), {});

      this.sortFilterPageConfig = {
        ...this.initSortFilterPageConfig,
        filter: { ...this.initSortFilterPageConfig.filter, ...predefFilter },
        page: { ...this.initSortFilterPageConfig.page },
        sort: { ...this.initSortFilterPageConfig.sort },
      };

      if (this.sortFilterPageConfig.filter.authorId) {
        this.autor = {
          userId: this.sortFilterPageConfig.filter.authorId,
          username: '',
          mail: '',
          name: '',
        };

      }

      if (this.sortFilterPageConfig.filter.currentArrangerId) {
        this.arranger = {
          userId: this.sortFilterPageConfig.filter.currentArrangerId,
          username: '',
          mail: '',
          name: '',
        };
      }

      this.filterButtonVisible = !!Object.values(this.sortFilterPageConfig.filter).find(value => !!value);
      this.dispatchTableLoad();
    });
  }

  trackById(idx, column: TableColumn) {
    return column.$$id;
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  triggerFilter() {
    this.sortFilterPageConfig.page.number = 0;
    this.dispatchTableLoad();
    this.filterButtonVisible = true;
    this.saveButtonVisible = true;
  }

  triggerFilterAuthor(value) {
    this.sortFilterPageConfig.filter.authorId = value ? value.userId : undefined;
    this.triggerFilter();
  }

  triggerFilterArranger(value) {
    this.sortFilterPageConfig.filter.currentArrangerId = value ? value.userId : undefined;
    this.triggerFilter();
  }

  triggerPaging($event: any) {
    /* Index der Zuladen seite*/
    this.sortFilterPageConfig.page = {
      ...this.sortFilterPageConfig.page,
      number: $event.offset,
    };
    this.dispatchTableLoad();
  }

  exportTable() {
    this.store$.dispatch(this.actionCreator.tableExport(this.sortFilterPageConfig));
  }

  triggerSorting($event: { sorts: { prop: string, dir: 'asc' | 'desc' }[] }) {
    this.sortFilterPageConfig.sort = $event.sorts[0];
    this.dispatchTableLoad();
  }

  saveTableConfig() {
    this.saveButtonVisible = false;
    const columnConfig = this.tableColumnConfig.map(({ summaryTemplate, cellTemplate, ...ex }) => ex);

    const filterConfig = Object.entries(this.sortFilterPageConfig.filter)
      .map(([filterName, filterValue]: [string, string]) => ({
        filterName,
        filterValue,
        id: this.filters.find(f => f.filterName === filterName)?.id,
      }));

    this.store$.dispatch(this.actionCreator.tableConfigSave({
      columnConfig: columnConfig,
      filterConfig: filterConfig,
      tableName: this.type,
    }));
  }

  reorder($event: { column: TableColumn, newValue: number, prevValue: number }) {
    const column = this.tableColumnConfig.splice($event.prevValue, 1);
    this.tableColumnConfig.splice($event.newValue + 1, 0, column[0]);
  }

  removeFilterTableConfig() {
    this.autor = undefined;
    this.arranger = undefined;
    this.sortFilterPageConfig = {
      ...this.initSortFilterPageConfig,
      filter: { ...this.initSortFilterPageConfig.filter },
      page: { ...this.initSortFilterPageConfig.page },
      sort: { ...this.initSortFilterPageConfig.sort },
    };
    this.dispatchTableLoad();
    this.filterButtonVisible = false;
  }

  toggleColumnVisibility(event: MatCheckboxChange, column: TableColumnConfigModel) {
    this.saveButtonVisible = true;
    for (const c of this.tableColumnConfig) {
      if (c.columnName === column.columnName) {
        c.visible = event.checked;
        break;
      }
    }
  }

  resize($event: { column: TableColumn, newValue: number, prevValue: number }) {
    this.table._internalColumns.forEach((column, index) => this.tableColumnConfig[index].width = column.width);
  }

  send(publicId: string) {
    this.store$.dispatch(this.actionCreator.tableSendAction({ publicId: publicId, config: this.sortFilterPageConfig }));
  }

  openDeletePrompt(publicId: string) {
    const dialogRef = this.dialog.open(QueryPromptComponent, {
      data: <QueryPromptData>{
        title: 'deleteProposalTitle',
        text: 'deleteProposalText',
      },
    });
    dialogRef.afterClosed().pipe(
      filter(event => event === 'accepted'),
    ).subscribe(() => this.store$.dispatch(this.actionCreator.tableDeleteAction({
      publicId: publicId,
      config: this.sortFilterPageConfig,
    })));
  }

  reject(publicId: string) {
    this.store$.dispatch(
      this.actionCreator.tableDeclineAction({
        selection: this.type,
        publicId,
        comment: this.message,
        config: this.sortFilterPageConfig,
      }),
    );
  }

  approve(publicId: string) {
    this.store$.dispatch(this.actionCreator.tableApproveAction({
      selection: this.type,
      publicId,
      config: this.sortFilterPageConfig,
    }));
  }

  rejectMultiple(publicId: string) {
    this.store$.dispatch(
      this.actionCreator.tableDeclineMultipleAction({
        selection: this.type,
        publicId,
        comment: this.message,
        config: this.sortFilterPageConfig,
      }),
    );
  }

  approveMultiple(publicId: string) {
    this.store$.dispatch(this.actionCreator.tableApproveMultipleAction({
      selection: this.type,
      publicId,
      config: this.sortFilterPageConfig,
    }));
  }

  recall(publicId: string) {
    this.store$.dispatch(this.actionCreator.tableRecallAction({
      publicId,
      config: this.sortFilterPageConfig,
    }));
  }

  openDialogReject(publicId: string) {
    const dialogRef = this.dialog.open(QueryPromptMessageActionsComponent, {
      data: <QueryPromptMessageActionsModel>{
        title: 'rejectProcess',
        text: 'rejectProcessMessage',
        message: this.message,
        info: this.info,
        textarea: true,
      },
    });

    dialogRef.afterClosed().pipe(
      filter(result => result.event === 'accepted'),
    ).subscribe(result => {
      this.message = result.message;
      if (!this.message) {
        this.info = 'infoApproveNoRejectMessage';
        this.openDialogReject(publicId);
      } else {
        this.reject(publicId);
        this.message = '';
      }
    });
  }

  openDialogApprove(publicId: string) {
    const dialogRef = this.dialog.open(QueryPromptComponent, {
      data: <QueryPromptMessageActionsModel>{
        title: 'approveProcess',
        text: 'approveProcessMessage',
      },
    });

    dialogRef.afterClosed().pipe(
      takeUntil(this.componentDestroyed$),
      filter(event => event === 'accepted'),
    ).subscribe(() => {
      this.approve(publicId);
    });
  }

  openDialogComment(comment: string) {
    const dialogRef = this.dialog.open(QueryPromptComponent, {
        data: <QueryPromptData>{
          title: 'Kommentar',
          text: comment,
          declineLabel: 'back',
          hideAcceptLabel: true,
        },
      },
    );

    dialogRef.afterClosed().pipe(
      takeUntil(this.componentDestroyed$),
      filter(event => event === 'accepted'),
    ).subscribe(() => {
    });
  }

  cateringCancel(publicId: string) {
    const dialogRef = this.dialog.open(QueryPromptComponent, {
      data: <QueryPromptMessageActionsModel>{
        title: 'cateringCancelTitle',
        text: 'cateringCancelText',
      },
    });

    dialogRef.afterClosed().pipe(
      takeUntil(this.componentDestroyed$),
      filter(event => event === 'accepted'),
    ).subscribe(() => {
      this.recall(publicId);
    });
  }

  isEditable(row: ProposalHeadModel, isAdmin: boolean): boolean {
    let editableState = false;
    switch (row.state) {
      case ProposalState.CACHED:
      case ProposalState.SAVED:
      case ProposalState.INITIAL:
      case ProposalState.DECLINED:
      case ProposalState.CATERING_CANCELED:
      case ProposalState.CATERING_DECLINED:
      case ProposalState.RECALLED:
        editableState = true;
        break;
      case ProposalState.CATERING_APPROVAL:
      case ProposalState.CATERING_IN_PROGRESS:
        editableState = row.recallable;
        break;
      case ProposalState.SAP_ERROR:
        editableState = isAdmin;
        break;
    }

    return editableState && (row.companyActive || isAdmin);
  }

}
