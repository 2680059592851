import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-route-config-form-properties-container',
  templateUrl: './route-config-form-properties-container.component.html',
  styleUrls: ['./route-config-form-properties-container.component.scss'],
})
export class RouteConfigFormPropertiesContainerComponent {
  @Input() elements;
  @Input() readonly: boolean;

  trackByName(e) {
    return e.name;
  }
}
