import { NgrxFormControlId, SetValueAction } from 'ngrx-forms';
import { environment } from '../../environments/environment';

/*
 * Exakt wie SetValue von ngrx-forms, enthält aber ein _trace,
 * was den Ursprung der Action im Logger sichtbar macht.
 */
export class SetValueTraceableAction<TValue> extends SetValueAction<TValue> {
  _real_type = 'SetValueTraceableAction';
  _trace = 'untraceable';

  constructor(controlId: NgrxFormControlId, value: TValue) {
    super(controlId, value);
    if (!environment.production) {
      try {
        throw Error();
      } catch (e) {
        this._trace = e.stack;
      }
    }
  }
}
