import { TableColumnDefModel } from '../../core/models/table-column-def.model';

// FixMe evlt sollte man das Model in ProposalHeadModel und OverviewModel (für die übersichtsseiten) aufteilen. Ist Kompliziert
export interface ProposalHeadModel {
  publicId: string;
  templateName: string;
  templateId: string;
  duplicate: boolean;
  companyActive: boolean;
  createdAt: Date;
  state: ProposalState;
  amount: number;
  comment: string;
  recallable: boolean;
  useCase: string;
  formName: string;
  categoryName: string;
  formIdentifier: string;
  author: {
    mail: string;
    name: string;
    userId: string;
    username: string;
  };
  form: {
    identifier: string,
    category: {
      name: string,
      displayName: string,
      color: string,
      backgroundColor: string,
    }
  };
}

export type ProposalTemplateConfig = { [key in keyof (ProposalHeadModel & { actions: string })]: TableColumnDefModel<ProposalHeadModel> };

export enum ProposalState {
  'CACHED' = 'CACHED',
  'SAVED' = 'SAVED',
  'TEMPLATE' = 'TEMPLATE',
  'IN_APPROVAL' = 'IN_APPROVAL',
  'IN_INSPECT' = 'IN_INSPECT',
  'IN_FURTHER_PROCESSING' = 'IN_FURTHER_PROCESSING',
  'WAITING_FOR_DEDUCTION' = 'WAITING_FOR_DEDUCTION',
  'WAITING_FOR_SIGNATURE' = 'WAITING_FOR_SIGNATURE',
  'WAITING_FOR_BILLING' = 'WAITING_FOR_BILLING',
  'RECALLED' = 'RECALLED',
  'APPROVED' = 'APPROVED',
  'SEND_FAILED' = 'SEND_FAILED',
  'SAP_ERROR' = 'SAP_ERROR',
  'DECLINED' = 'DECLINED',
  'FINISHED' = 'FINISHED',
  'CATERING_APPROVAL' = 'CATERING_APPROVAL',
  'CATERING_IN_PROGRESS' = 'CATERING_IN_PROGRESS',
  'CATERING_DECLINED' = 'CATERING_DECLINED',
  'CATERING_COMPLAINED' = 'CATERING_COMPLAINED',
  'CATERING_APPROVAL_BY_AUTHOR' = 'CATERING_APPROVAL_BY_AUTHOR',
  'CATERING_COMPLETE' = 'CATERING_COMPLETE',
  'CATERING_CANCELED' = 'CATERING_CANCELED',
  'INITIAL' = 'INITIAL',
  'UNKNOWN' = 'UNKNOWN',
}

export function getProposalStates(): (keyof typeof ProposalState)[] {
  return [
    'CACHED',
    'SAVED',
    'IN_APPROVAL',
    'IN_INSPECT',
    'IN_FURTHER_PROCESSING',
    'WAITING_FOR_DEDUCTION',
    'WAITING_FOR_SIGNATURE',
    'WAITING_FOR_BILLING',
    'APPROVED',
    'SAP_ERROR',
    'SEND_FAILED',
    'DECLINED',
    'FINISHED',
    'INITIAL',
    'RECALLED',
    'CATERING_CANCELED',
    'CATERING_APPROVAL',
    'CATERING_IN_PROGRESS',
    'CATERING_DECLINED',
    'CATERING_COMPLAINED',
    'CATERING_APPROVAL_BY_AUTHOR',
    'CATERING_COMPLETE',
  ];
}
