import { box, Boxed, NgrxValueConverter, unbox } from 'ngrx-forms';
import { Observable } from 'rxjs';

/**
 * Sucht das Objekt selected in dem Array options über die Property id und gibt genau die Instanz zurück.
 * @param options das Array mit options
 */
export class SelectBoxedIdNgrxValueConverter<T extends { id: string }> implements NgrxValueConverter<T, Boxed<T>> {
  constructor(protected options: T[]) {}

  convertStateToViewValue(value: Boxed<T>): T {
    return this.options ? this.options.find((o) => value && o && unbox(value) && o.id === unbox(value).id) : undefined;
  }

  convertViewToStateValue(value: T): Boxed<T> {
    return box(value);
  }
}

/**
 * {@see SelectBoxedIdNgrxValueConverter}
 * @param options$ Unterschied: options$ ist ein Observable.
 */
export class SelectAsyncBoxedIdNgrxValueConverter<T extends { id: string }> extends SelectBoxedIdNgrxValueConverter<T> {
  constructor(protected options$: Observable<T[]>) {
    super(undefined);
    options$.subscribe(o => this.options = o);
  }
}
