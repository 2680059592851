import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { ValidationErrors } from 'ngrx-forms';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { getI18nCurrentValue, getI18nSelectedLanguage } from '../../core/ngrx/reducers/core.store';
import { objectFilter, objectMap } from '../utils/object-helper';
import { rxDefaultTo } from '../utils/reducer-utils';
import { isTokenParseFnType } from './i9e';

/**
 * Übersetzt den voranstehenden String (=Key) in die aktuelle Sprache,
 * gibt ein Observable zurück,
 * nicht vergessen ein " | async" bei der Verwendung anzuhängen!
 * Bsp: {{ 'abc' | i18n:{k: 'xyz'}:'bitte warten...' | async }}
 * mit Übersetzung abc: i9e`test ${'k'}`
 *  Ruft String mit Key 'test' aus store core.i18n.translations.test.de ab, wenn core.i18n.selectedLanguage = 'de' ist.
 *  Während des ladens (core.i18n.translations.test.de = undefined) wird 'bitte warten...' (placeholder) angezeigt.
 */
@Pipe({
  name: 'i18n',
})
export class I18nPipe implements PipeTransform, OnDestroy {
  private componentDestroyed$ = new Subject<void>();

  constructor(private store$: Store<any>) {
  }

  selectedLanguage$ = this.store$.select(getI18nSelectedLanguage);

  /**
   * Übersetzt translationKey mit den im Store hinterlegten Sprachen.
   *
   * Regeln nach unten höherwertig.
   * 1. Die Tokens (z.B. tokens.xyz) werden in die Template-Funktion übergeben, die value wird via `${'xyz'}` angezeigt.
   * 2. Es wird geschaut, ob in den Namen der i9n Variablen (also die Variablen `${'xyz'}` des Templates) etwas übersetzbares ist.
   *    Dies ist nützlich, um einfach andere Übersetzungen in den Übersetzungen zu nutzen.
   * 3. Wird in den übergebenen tokens ein gleichnamiger Eintrag gefunden, wie eine Variable aus dem Template heißst,
   *    dann wird in der Value des token-Eintrags nach etwas Übersetzbarem gesucht.
   * @param translationKey Key, der übersetzt werden soll, oder ein Observable auf den translationKey
   * @param tokens Objekt mit Key/Value-Paaren, was in dem Übersetzungs-Template genutzt werden kann
   * @param placeholder optionaler Platzhalter, der angezeigt wird, wenn translationKey nicht gefunden wurde
   *        (sonst wird der translationKey angezeigt)
   */
  transform(
    translationKey: string | Observable<string>,
    tokens: Dictionary<string | number | Observable<string>> = {}, placeholder?: string
  ): Observable<string> {
    const placeholderOrTranslationKey = placeholder || translationKey;
    const translationKey$ = this.toObservable(placeholderOrTranslationKey);
    // combine tokens observables and strings in one observable
    // wäre echt schön, wenn combineLatest genau wie forkJoin ein Objekt mit Observables genommen hätte :(
    const tokens$: Observable<Dictionary<string>> = (Object.keys(tokens).length > 0 ? combineLatest(
      Object.values(objectMap(
        tokens,
        (key, value) => this.toObservable(value, ((v) => v || ''))
      ))
    ) : of([])).pipe(
      takeUntil(this.componentDestroyed$),
      map(value => Object.keys(tokens).reduce((previousValue, currentValue, index) => ({
        ...previousValue,
        [currentValue]: value[index],
      }), {}))
    );

    // response observable
    return tokens$.pipe(
      mergeMap(
        (resolvedTokens) => translationKey$.pipe(
          map((resolvedTranslationKey) => ({
            resolvedTranslationKey,
            resolvedTokens,
          })),
        ),
      ),
      // actual translation
      mergeMap(({resolvedTokens, resolvedTranslationKey}) => this.store$.select(getI18nCurrentValue(resolvedTranslationKey)).pipe(
        map((translation) => ({
          rKey: resolvedTranslationKey,
          rTokens: resolvedTokens,
          translation,
        }),
      ))),
      takeUntil(this.componentDestroyed$),
      // error logging
      tap(({rKey, translation}) => {
        if (!translation) {
          // @ts-ignore
          if (!window.missingI18n) {
            console.warn(`missing i18n for key '${rKey}', using placeholder instead! `
              + `Check window.missingI18n for other missing keys.`);
          }
          // @ts-ignore
          window.missingI18n = {
            // @ts-ignore
            ...window.missingI18n,
            [rKey]: rKey,
          };
        }
      }),
      // recursive template parsing
      mergeMap(({translation, rTokens}) => {
        // is template?
        if (isTokenParseFnType(translation)) {
          // recursive tokens translation
          const observables: {} = Object.entries(rTokens).reduce((previousValue, [currenrKey, currentValue]) => ({
            ...previousValue,
            [currenrKey]: this.transform(currentValue, objectFilter(rTokens, ([key]) => key !== currenrKey)).pipe(
              take(1),
              rxDefaultTo(currentValue),
            ),
          }), {
            ...translation.i9n ? translation().reduce((previous, current) => ({
              ...previous,
              [current]: this.transform(current, objectFilter(rTokens, ([key]) => key !== current)).pipe(
                take(1),
                rxDefaultTo(current),
              ),
            }), {}) : {},
            // forkJoin braucht mindestens eine Observable, und alle Observables müssen completed sein
            __i18n: of('').pipe(take(1)),
          });
          return forkJoin(observables).pipe(
            map(translatedTokens => translation(translatedTokens)),
          );
        }
        return of(translation);
      }),
      rxDefaultTo(placeholderOrTranslationKey),
    );
  }

  private toObservable<T>(obj: Observable<T> | T, mapper: ((value: T) => T) = t => t): Observable<T> {
    return obj instanceof Observable ? obj.pipe(
      map(mapper)
    ) : of(obj);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}

