import { Action, Store } from '@ngrx/store';
import { Observable, OperatorFunction, pipe } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { rxCatchApiError } from '../../core-lib/utils/reducer-utils';
import { SessionSpinnerClose, SessionSpinnerOpen } from '../../session/actions/session.actions';
import { CoreFeatureState } from '../ngrx/reducers/core.store';

export const switchMapWithSpinner = <IncomingType, HttpReturnType, ReturnType>(
  switchMapValue: ((value: IncomingType, index: number) => Observable<HttpReturnType>),
  spinnerKey: string,
  innerPipe: OperatorFunction<HttpReturnType, ReturnType>,
  store$: Store<CoreFeatureState>,
  timeout?: number,
) => pipe<
  Observable<IncomingType>,
  Observable<IncomingType>,
  Observable<Action>,
  Observable<Action>
  >(
  tap(() => store$.dispatch(new SessionSpinnerOpen(spinnerKey, timeout))),
  switchMap((value: IncomingType, index: number) => switchMapValue(value, index).pipe(
    innerPipe || pipe(),
    rxCatchApiError({}),
  )),
  tap(() => store$.dispatch(new SessionSpinnerClose(spinnerKey))),
);
