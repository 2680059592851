import { backendClasses } from './backend-classes.model';
import { ConfigItemConfigurationDtoModel, ConfigItemTranslationsDtoModel } from './config-item-configuration-dto.model';

export class ConfigItemDaterangeConfigurationDtoModel extends ConfigItemConfigurationDtoModel {
  static readonly CLASSNAME: string = backendClasses.daterangeConfiguration;
  static TYPENAME = 'typeDaterange';
  '@class' = ConfigItemDaterangeConfigurationDtoModel.CLASSNAME;
  $$typeName = ConfigItemDaterangeConfigurationDtoModel.TYPENAME;
  startDateVisible = true;
  startTimeVisible = true;
  endDateVisible = false;
  endTimeVisible = true;

  // Definiert, wie weit die zwei DateTimes auseinander liegen dürfen (inklusive)
  maxRangeLength: number = undefined;
  minRangeLength: number = undefined;

  // Anzahl an Sekunden, die der Termin in der Zukunft liegen muss
  minStartInFuture: number = undefined;
  // Anzahl an Sekunden, die der Termin in der Zukunft liegen darf
  maxStartInFuture: number = undefined;
  // Vorbelegung "Jetzt +- Sekunden" für min/max
  // wird im Frontend gerundet auf nächste volle 30 Minuten
  startPredefinedFromNow: number = undefined;
  endPredefinedFromNow: number = undefined;

  weekendsActive = true;

  timeMin: string = undefined;
  timeMax: string = undefined;
  startDateTranslations: ConfigItemTranslationsDtoModel = new ConfigItemTranslationsDtoModel('start date');
  startTimeTranslations: ConfigItemTranslationsDtoModel = new ConfigItemTranslationsDtoModel('start time');
  endDateTranslations: ConfigItemTranslationsDtoModel = new ConfigItemTranslationsDtoModel('end date');
  endTimeTranslations: ConfigItemTranslationsDtoModel = new ConfigItemTranslationsDtoModel('end time');
}
