import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControlState, FormGroupState } from 'ngrx-forms';
import { ConfigItemTranslationsDtoModel } from '../../models/config-item-configuration-dto.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-input-lang-string',
  templateUrl: './input-lang-string.component.html',
  styleUrls: ['./input-lang-string.component.scss']
})
export class InputLangStringComponent {
  @Output() undoLangClick = new EventEmitter<FormControlState<string>>();
  @Input() undoVisible = false;
  @Input() class = '';
  @Input() placeholder: string;
  @Input() required = true;

  /**
   * Setzt this.stringsArray auf verträglichen Wert. Es kann folgendes sein:
   *  - Object (FormGroup<InputLangStrings>) (Object hat .controls)
   *  - Array von InputLangStringArrayFormState
   * @param strings die InputLangStringsFormState
   */
  @Input() set strings(strings: InputLangStringsFormState) {
    if (Array.isArray(strings)) {
      this.stringsArray = strings;
    } else if (typeof strings === 'object' && strings.controls) {
      this.stringsArray = Object.keys(strings.controls).map((key) => ({
        state: strings.controls[key].controls.text,
        placeholder: key,
        required: true,
        id: strings.controls[key].controls.id ? strings.controls[key].controls.id.value : undefined,
      }));
    } else if (strings) {
      console.error('InputLangStringComponent: @Input strings ist ein String Object! (deprecated, siehe stacktrace für Auslöser)');
      this.stringsArray = Object.keys(strings).map((key) => ({
        state: strings[key],
        placeholder: key,
        required: this.required,
        id: undefined,
      }));
    }
  }

  /**
   * Alternative zur doch recht komplizierten @Input strings kann auch translations auf die FormGroupState<ConfigItemTranslationsDtoModel>
   *   gesetzt werden.
   * @param translations FormGroupState<ConfigItemTranslationsDtoModel>
   */
  @Input() set translations(translations: FormGroupState<ConfigItemTranslationsDtoModel>) {
    this.stringsArray = Object.entries(translations.controls).reduce((acc, [key, curr]) => [
      ...acc,
      {
        state: curr.controls.text,
        placeholder: key,
        required: this.required,
        id: curr.value.id,
      },
    ], []);
  }

  stringsArray: InputLangStringArrayFormState = [];

  undoLangEmit(state: FormControlState<string>) {
    this.undoLangClick.emit(state);
  }

  trackByFn(index, item) {
    return item.placeholder;
  }
}

export type InputLangStringArrayFormState = {
  state: FormControlState<string>,
  placeholder: string,
  required: boolean,
  id: string,
}[];

export type InputLangStringsFormState = {
  [label: string]: FormControlState<string>,
} | InputLangStringArrayFormState;

export interface InputLangStrings {
  [label: string]: {
    text: string,
    id?: string,
  };
}
