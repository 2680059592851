import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-form-inner-margin',
  templateUrl: './form-inner-margin.component.html',
  styleUrls: ['./form-inner-margin.component.scss'],
})
export class FormInnerMarginComponent {
}
