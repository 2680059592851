import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from '../../../core-lib/services/api.service';
import {
  coreConfigCategoryInfoLoad,
  coreConfigCategoryInfoLoadSuccess,
  coreConfigCategoryInfoSave,
  coreConfigCategoryInfoSaveSuccess,
} from '../actions/core-config.actions';
import { ConcreteApiSuccessAction } from '../actions/core.actions';
import { getConfigCategoryInfoFormState } from '../reducers/core.store';

@Injectable()
export class CoreConfigCategoryInfoEffects {

  constructor(private actions$: Actions, private store$: Store<any>, private api: ApiService) {
  }

  loadCategoryInfo$ = createEffect(() => this.actions$.pipe(
    ofType(coreConfigCategoryInfoLoad),
    switchMap(() => this.api.getCategoryInfos()),
    tap(data => data.forEach(d => d.open = false)),
    map((data) => coreConfigCategoryInfoLoadSuccess({data})),
  ));

  saveCategoryInfo$ = createEffect(() => this.actions$.pipe(
    ofType(coreConfigCategoryInfoSave),
    withLatestFrom(this.store$.select(getConfigCategoryInfoFormState), (a, s) => s),
    filter(s => s.isValid),
    switchMap(s => this.api.postCategoryInfos(s.value.filter(c => !!c.category))),
    mergeMap((data) => [coreConfigCategoryInfoSaveSuccess({data}), new ConcreteApiSuccessAction('categoryInfoSaved')]),
  ));

}
