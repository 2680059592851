<div class="margin-content">
  <form (ngSubmit)="save()">
    <lib-common-form-flex-container [maxWidth]="'1500px'">
      <mat-card-title>{{locationName$ | async}}</mat-card-title>
      <div class="articel-card-width" *ngFor="let article of (articleArrayState$ |async); index as index; trackBy: getControlId">
        <mat-card>
          <mat-card-content>
            <lib-common-form-flex-container [maxWidth]="'1500px'">
              <div
                fxFlex.gt-md="14%"
                fxFlex.lt-lg="100%"
              >
                <mat-form-field class="input-width">
                  <input
                    matInput
                    required
                    placeholder="{{ 'itemName' | i18n | async }}"
                    [ngrxFormControlState]="article.controls.name"
                  >
                </mat-form-field>
              </div>
              <div
                fxFlex.gt-md="8%"
                fxFlex.lt-lg="100%"
              >
                <mat-form-field class="input-width">
                  <input
                    matInput
                    required
                    placeholder="{{ 'unit' | i18n | async }}"
                    [ngrxFormControlState]="article.controls.unit"
                  >
                </mat-form-field>
              </div>
              <div
                fxFlex.gt-md="8%"
                fxFlex.lt-lg="100%"
              >
                <lib-common-currency-input
                  class="input-width"
                  required
                  placeholder="{{ 'singlePrice' | i18n | async }}"
                  [ngrxFormControlState]="article.controls.price"
                ></lib-common-currency-input>
              </div>
              <div
                fxFlex.gt-md="10%"
                fxFlex.lt-lg="100%"
              >
                <lib-common-form-taxrate-selector
                  placeholderI18nKey="taxRate"
                  [ngrxSelectedId]="article.controls.taxRate.controls.id"
                  flex="100%"
                  required
                ></lib-common-form-taxrate-selector>
              </div>
              <div
                fxFlex.gt-md="12%"
                fxFlex.lt-lg="100%"
              >
                <mat-form-field class="input-width">
                  <input
                    matInput
                    required
                    [matDatepicker]="validFrom"
                    [ngrxFormControlState]="article.controls.validFrom"
                    [ngrxValueConverter]="ngrxDateValueConverter"
                    placeholder="{{ 'validFrom' | i18n | async }}"
                  >
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="validFrom"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #validFrom></mat-datepicker>
                </mat-form-field>
              </div>
              <div
                fxFlex.gt-md="12%"
                fxFlex.lt-lg="100%"
              >
                <mat-form-field
                  class="input-width"
                >
                  <input
                    matInput
                    required
                    [matDatepicker]="validUntil"
                    [ngrxFormControlState]="article.controls.validUntil"
                    [ngrxValueConverter]="ngrxDateValueConverter"
                    placeholder="{{ 'validUntil' | i18n | async }}"
                  >
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="validUntil"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #validUntil></mat-datepicker>
                </mat-form-field>
              </div>
              <div
                fxFlex.gt-md="8%"
                fxFlex.lt-lg="100%"
                class="margin-checkbox-article"
              >
                <mat-checkbox
                  [ngrxFormControlState]="article.controls.quotaEqualsActual"
                  (change)="onChangeReadonly($event)"
                >
                  {{ 'quotaEqualsActual' | i18n | async }}
                </mat-checkbox>
              </div>
              <div
                fxFlex.gt-md="12%"
                fxFlex.lt-lg="100%"
                class="margin-checkbox-article"
              >
                <mat-checkbox
                  [ngrxFormControlState]="article.controls.approvalNeeded"
                  (change)="onChangeReadonly($event)"
                >
                  {{ 'approvalNeeded' | i18n | async }}
                </mat-checkbox>
              </div>
              <div
                fxFlex.gt-md="13%"
                fxFlex.lt-lg="100%"
                class="margin-checkbox-article"
              >
                <mat-checkbox
                  [ngrxFormControlState]="article.controls.addAutomatically"
                  (change)="onChangeReadonly($event)"
                >
                  {{ 'addAutomatically' | i18n | async }}
                </mat-checkbox>
              </div>
              <div
                fxFlex.gt-md="3%"
                fxFlex.lt-lg="100%"
              >
                <button class="delete-button" type="button" (click)="removeArticle(index)" mat-icon-button>
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </lib-common-form-flex-container>
          </mat-card-content>
        </mat-card>
      </div>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="button"
          (click)="addArticle()"
          color="accent"
          mat-fab
        >
          <mat-icon svgIcon="innogy:plus"></mat-icon>
        </button>
      </div>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="submit"
          color="primary"
          mat-raised-button
          [disabled]="isFormInvalid$ | async"
        >
          <mat-icon
            class="svg-icon-floppy"
            svgIcon="innogy:floppy"
          ></mat-icon>
          {{ 'save' | i18n | async }}
        </button>
      </div>
    </lib-common-form-flex-container>
  </form>
</div>
