import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fromEvent, of } from 'rxjs';
import { catchError, concatMap, delay, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { UserDtoModel } from '../../core-lib/models/user-dto.model';
import { ApiService } from '../../core-lib/services/api.service';
import { defaultEffectOptions } from '../../core-lib/utils/default-effect-options';
import {
  SessionActionHttpError,
  SessionActionLoad,
  SessionActionLoadedIsLoggedIn,
  SessionActionLoadedUser,
  SessionSpinnerClose,
  SessionSpinnerCloseAllWithoutDelay,
  SessionSpinnerCloseWithoutDelay,
  SessionSpinnerOpen,
} from '../actions/session.actions';
import { SessionState } from '../reducers/session.reducer';

@Injectable()
export class SessionEffects {


  loadUser$ = createEffect(() => this.actions$.pipe(
    ofType(SessionActionLoadedIsLoggedIn.TYPE),
    filter((action: SessionActionLoadedIsLoggedIn) => action.isLoggedIn),
    switchMap(() => this.api.getCurrentUser().pipe(
      map((user: UserDtoModel) => new SessionActionLoadedUser(user)),
    )),
  ), defaultEffectOptions());


  loadLoginState$ = createEffect(() => this.actions$.pipe(
    ofType(SessionActionLoad.TYPE),
    switchMap(() => this.api.getLoginState().pipe(
      map((isLoggedIn: boolean) => new SessionActionLoadedIsLoggedIn(isLoggedIn)),
    )),
  ), defaultEffectOptions());

  // trigger getLoginState check on http error

  httpError$ = createEffect(() => this.actions$.pipe(
    ofType(SessionActionHttpError.TYPE),
    switchMap(() => this.api.getLoginState().pipe(
      catchError(() => of(false)),
      filter((isLoggedIn: boolean) => !isLoggedIn),
      map((isLoggedIn: boolean) => new SessionActionLoadedIsLoggedIn(isLoggedIn)),
    )),
  ), defaultEffectOptions());

  /**
   * Timeout für SessionSpinnerOpen. Wartet auf SessionSpinnerClose für gleiche FORM_ID.
   * Bei Timeout: SessionSpinnerClose auslösen.
   * (Hier kann man später evtl. neue action "SessionSpinnerTimeout" zur Unterscheidung nutzen)
   */

  spinnerHandleTimeout$ = createEffect(() => this.actions$.pipe(
    ofType<SessionSpinnerOpen>(SessionSpinnerOpen.TYPE),
    concatMap(openAction => of(new SessionSpinnerClose(openAction.id, true)).pipe(
      delay(openAction.timeout),
      takeUntil(this.actions$.pipe(
        ofType<SessionSpinnerCloseWithoutDelay>(SessionSpinnerCloseWithoutDelay.TYPE),
        filter((a) => a.id === openAction.id),
        take(1),
      )),
    )),
  ), defaultEffectOptions());


  spinnerHandleClose$ = createEffect(() => this.actions$.pipe(
    ofType<SessionSpinnerClose>(SessionSpinnerClose.TYPE),
    delay(200),
    map((a) => new SessionSpinnerCloseWithoutDelay(a.id, a.wasTimeout)),
  ), defaultEffectOptions());


  spinnerHandleEscClose$ = createEffect(() => fromEvent<KeyboardEvent>(document, 'keyup').pipe(
    filter((e) => e.code === 'Escape' && e.getModifierState('Shift')),
    map((a) => new SessionSpinnerCloseAllWithoutDelay()),
  ), defaultEffectOptions());

  constructor(
    private actions$: Actions,
    private store$: Store<SessionState>,
    private api: ApiService,
  ) {
  }
}
