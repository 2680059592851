import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { DisableAction, FormArrayState, FormGroupState } from 'ngrx-forms';
import { Observable, of } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../../core-lib/models/config-item-arranger-list-configuration-dto.model';
import { SetValueTraceableAction } from '../../../core-lib/models/set-value-traceable-action';
import { getI18nSelectedLanguage } from '../../../core/ngrx/reducers/core.store';
import { IdDtoModel } from '../../../forms/all-forms/models/id-dto.model';
import { formBaseSelectors } from '../../../forms/form-base/ngrx/selectors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-approver-list',
  templateUrl: './approver-list.component.html',
  styleUrls: ['./approver-list.component.scss'],
})
export class ApproverListComponent implements OnDestroy, OnInit {
  confirmMode = this.activatedRoute.snapshot.data.confirmMode;
  approveMode = this.activatedRoute.snapshot.data.approveMode;

  selectedLanguage$ = this.store$.select(getI18nSelectedLanguage);
  selectedLanguage: string;

  @Input() activationValue$: Observable<number> = of(0);
  @Input() approvers$: Observable<FormArrayState<IdDtoModel>>;
  @Input() valueConverter;
  @Input() removable = false;
  @Input() direction = 'row';
  @Input() configName: string;
  @Input() maxSize: number;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() enableable: false; // displays enable checkbox
  @Input() enableTextI18n = 'enableApprover';
  @Input() enablePropertyName: string; // used like .controls[enablePropertyName] if enableable is true
  @Input() asId = false; // just gives user id as string
  @Input() asIdPropertyName = 'id'; // if asId is true, use this control name like .controls[asIdPropertyName].
  @Input() addButtonColor: ThemePalette = 'accent';
  @Input() featureName: string = this.activatedRoute.snapshot.data.featureName;
  @Output() addClick = new EventEmitter();
  @Output() removeClick = new EventEmitter<FormGroupState<IdDtoModel>>();
  @Input() headline;
  @Input() placeholder;

  approversDisabled = false;
  approversControls: readonly FormGroupState<IdDtoModel>[] = [];

  destroy$ = new EventEmitter();

  previouslyAdded = [];

  neededCountOfApprovers = -1;

  isOpen$ = this.store$.select(formBaseSelectors.getFormBaseOpen, {featureName: this.featureName});

  config$ = this.store$.select(formBaseSelectors.getFormBaseConfigurationItemsState, {featureName: this.featureName}).pipe(
    filter(c => !!(c && [this.configName])),
    map(c => c[this.configName]),
    map((x) => <ConfigItemArrangerListConfigurationDtoModel>x),
  );
  config: ConfigItemArrangerListConfigurationDtoModel;

  @Input() hasValueFn = (approver) => this.getIdProperty(approver?.value);

  constructor(
    private store$: Store<any>,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.emit();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.activationValue$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.isOpen$, this.approvers$, (activationValue, isOpen, approvers) => ({activationValue, isOpen, approvers})),
      filter(({isOpen}) => isOpen),
    ).subscribe(({activationValue, approvers}) => {
      if (this.config) {
        this.addDynamicApprover(activationValue, approvers);
      }
    });

    this.config$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.activationValue$, this.approvers$),
    ).subscribe(([config, activationValue, approvers]) => this.setConfig(config, activationValue, approvers));

    this.approvers$.pipe(takeUntil(this.destroy$)).subscribe(a => {
      this.approversDisabled = a.isDisabled;
      this.approversControls = a.controls;
    });

    this.selectedLanguage$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(l => {
      this.selectedLanguage = l;
    });
  }

  setConfig(config: ConfigItemArrangerListConfigurationDtoModel, activationValue: number, approversState: FormArrayState<IdDtoModel>) {
    this.config = config;
    let value = this.config?.values
      ?.filter((a) => (!a.countBased && !a.valueBased) || (a.valueBased && a.activationValue <= activationValue))
      .map(a => ({id: a.value}));

    if (value?.length < 1 && this.config?.mandatory) {
      value = [{id: undefined}];
      this.neededCountOfApprovers = 1;
    } else if (this.config?.mandatory) {
      this.neededCountOfApprovers = value.length;
    }

    if (this.config && (approversState?.value?.length === 0 || !approversState?.value[0]?.id || this.config.enabled === false)) {
      this.store$.dispatch(new SetValueTraceableAction(approversState.id, value));
    }
  }

  isRequired() {
    return this.required || this.config?.mandatory;
  }

  isDisabled() {
    return this.disabled || this.config?.enabled === false || this.approversDisabled;
  }

  isHidden() {
    return this.config?.visible === false;
  }

  getLayout() {
    return `${this.direction} wrap`;
  }

  getIdProperty(o) {
    return o && o[this.asIdPropertyName];
  }

  getPlaceHolder() {
    if (this.config && this.config.translations[this.selectedLanguage]) {
      return this.config.translations[this.selectedLanguage].text;
    }
    return this.placeholder;
  }

  private addDynamicApprover(activationValue: number, approversState: FormArrayState<IdDtoModel>) {
    const nonConditionalApprovers = this.config.values.filter(value => !value.countBased && !value.valueBased).length;

    const conditionalApprovers = this.config.values.filter(value => value.valueBased && value.activationValue <= activationValue);

    this.neededCountOfApprovers = nonConditionalApprovers + conditionalApprovers.length;

    if (this.neededCountOfApprovers < 1 && this.config.mandatory) {
      this.neededCountOfApprovers = 1;
    }

    const approvers = approversState?.value.filter(a => !this.previouslyAdded.find(b => b.id === a.id));

    const namedApproversToAdd = conditionalApprovers.filter(a => !!a.value);

    const toAdd = [];

    namedApproversToAdd.forEach(namedA => {
      const notContained = approvers.find(a => a.id === namedA.value) === undefined;
      if (notContained) {
        toAdd.push({id: namedA.value});
      }
    });

    const newApprovers = this.fitApproversToNeededLength(toAdd.concat(approvers), this.neededCountOfApprovers);

    // TODO: Merge diese ganzen Actions in eine einzelne und Reduce diese im Store. Verhindert das wiederholte Emitten eines neuen States
    this.store$.dispatch(new SetValueTraceableAction(approversState.id, []));
    this.store$.dispatch(new SetValueTraceableAction(approversState.id, newApprovers));
    if (this.isDisabled()) {
      this.store$.dispatch(new DisableAction(approversState.id));
    }


    this.previouslyAdded = [...toAdd];
  }

  fitApproversToNeededLength(approvers: IdDtoModel[], neededCountOfApprovers: number) {
    let newApprovers;

    if (neededCountOfApprovers < approvers.length) {
      approvers = approvers.filter((a) => !!a.id);
    }

    if (approvers.length < neededCountOfApprovers) {
      const a = [];
      while (a.length < neededCountOfApprovers - approvers.length) {
        a.unshift({id: null});
      }
      newApprovers = approvers.concat(a);
    } else {
      newApprovers = [...approvers];
    }
    return newApprovers;
  }
}
