<div
  [class.top-margin]="topMargin"
  [class.side-margin]="siedeMargin"
>
  <div fxFlex.gt-lg="0 1 {{ getSideSizePercent() }}%" class="inner-page-margin"></div>
  <div
    fxFlex="0 0 100%"
    fxFlex.gt-lg="0 0 {{ contentSizePercent }}%"
  >
    <ng-content></ng-content>
  </div>
  <div fxFlex.gt-lg="0 1 {{ getSideSizePercent() }}%" class="inner-page-margin"></div>
</div>
