import { AccountingType } from './accounting-type.model';
import { backendClasses } from './backend-classes.model';
import { ConfigAccountInputType } from './config-accounting-input-type.model';
import { ConfigItemTextInputConfigurationDtoModel } from './config-item-text-input-configuration-dto.model';

export class ConfigItemAccountingConfigurationDtoModel extends ConfigItemTextInputConfigurationDtoModel {
  static readonly CLASSNAME: string = backendClasses.accountingConfiguration;
  static TYPENAME = 'typeAccounting';
  '@class' = ConfigItemAccountingConfigurationDtoModel.CLASSNAME;
  $$typeName = ConfigItemAccountingConfigurationDtoModel.TYPENAME;

  type: ConfigAccountInputType = ConfigAccountInputType.SAP_SEARCH;
  accountingType: AccountingType = AccountingType.UNKNOWN;
}
