import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CoreFeatureState } from '../../ngrx/reducers/core.store';
import { getRouterParams } from '../../ngrx/reducers/router.reducer';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-route-config-forms-add',
  templateUrl: './route-config-forms-add.component.html',
  styleUrls: ['./route-config-forms-add.component.scss'],
})
export class RouteConfigFormsAddComponent {
  routeParams$ = this.store$.select(getRouterParams);
  formId$ = this.routeParams$.pipe(
    select('formId'),
  );
  companyId$ = this.routeParams$.pipe(
    select('companyId'),
  );

  constructor(
    private store$: Store<CoreFeatureState>,
    private router: Router,
  ) {
  }
}
