import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { QueryPromptComponent } from '../../../core-lib/components/query-prompt/query-prompt.component';
import { QueryPromptData } from '../../../core-lib/models/query-prompt-data.model';
import { ConfigListModel } from '../../models/config-list.model';
import { CoreCompanyFormActionDelete, CoreCompanyFormActionLoadedCompanies } from '../../ngrx/actions/core-company-forms.actions';
import { CoreFeatureState, getCompaniesState } from '../../ngrx/reducers/core.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-route-config-companies',
  templateUrl: './route-config-companies.component.html',
  styleUrls: ['./route-config-companies.component.scss'],
})
export class RouteConfigCompaniesComponent implements OnDestroy {
  companyList = undefined;
  companies$ = this.store$.select(getCompaniesState);

  @Output() removeClick = new EventEmitter();

  private componentDestroyed$ = new Subject<void>();

  constructor(
    private store$: Store<CoreFeatureState>,
    public dialog: MatDialog,
  ) {
    this.companies$.subscribe(compnyList => this.companyList = compnyList);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  getControlId = (t: FormGroupState<ConfigListModel>) => t.id;

  removeCompany(company) {
    const newValue = [
      ...this.companyList.filter(x => this.getControlId(x) !== this.getControlId(company)),
    ];

    this.store$.dispatch(new CoreCompanyFormActionLoadedCompanies(newValue));
    this.store$.dispatch(new CoreCompanyFormActionDelete(company));
  }

  openPrompt(company) {
    const dialogRef = this.dialog.open(QueryPromptComponent, {
      data: <QueryPromptData>{
        title: 'deleteCompanyTitle',
        text: 'deleteCompanyText',
      },
    });

    dialogRef.afterClosed().pipe(
      takeUntil(this.componentDestroyed$),
      filter(event => event === 'accepted'),
    ).subscribe(() => this.removeCompany(company));
  }
}
