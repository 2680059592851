import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '../../../core-lib/services/api.service';
import { RouteInfoService } from '../../../core-lib/services/route-info.service';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { SessionSpinnerClose, SessionSpinnerOpen } from '../../../session/actions/session.actions';
import { ApiErrorAction, ConcreteApiSuccessAction } from '../actions/core.actions';
import { ocrActions } from '../actions/ocr.actions';

@Injectable()
export class OcrEffects {
  protected readonly ocrSpinnerKey = 'OcrSpinnerKey';

  scanFile$ = createEffect(() => this.actions$.pipe(
    ofType(ocrActions.OcrScanFileAction),
    tap(() => this.store$.dispatch(new SessionSpinnerOpen(this.ocrSpinnerKey, 35000))),
    switchMap((action) => this.api.postOcrFile(action.file, action.formIdentifier, action.selectedCompanyShortName).pipe(
      map((publicId) => {
        this.router.navigate(['/forms', this.routeInfo.getParam('formsCategory'), action.formIdentifier, publicId]);
        return new ConcreteApiSuccessAction('ocrScanSuccess', 0);
      }),
      catchError((error) => {
        this.router.navigate(['/forms', this.routeInfo.getParam('formsCategory'), action.formIdentifier, 'new']);
        return of(new ApiErrorAction(error, 'ocrScanFailed', 0));
      }),
    )),
    switchMap((actions: Action | Action[]) => from([
      new SessionSpinnerClose(this.ocrSpinnerKey),
      ...(Array.isArray(actions) ? actions : [actions]),
    ])),
  ), defaultEffectOptions());

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private router: Router,
    private routeInfo: RouteInfoService,
    private store$: Store,
  ) {
  }
}
