import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[fxAlignLastRowLeft]',
})
export class FxAlignLastRowLeftDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {
    const child = this.renderer.createElement('div');
    child.setAttribute('style', 'flex: auto');
    this.renderer.appendChild(this.el.nativeElement, child);
  }
}
