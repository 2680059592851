<lib-common-page-margin>
  <mat-tab-group [(selectedIndex)]="tabIndex">
    <mat-tab label="{{'throughputDashboard' | i18n | async}}">
      <ng-template matTabContent>
        <div fxLayoutAlign="center center" class="chart-container">
          <div fxFlexAlign="center">
            <lib-common-datetime-range-picker
              [formControlStateStartDate]="dashboardThroughputFormFromState$ | async"
              [formControlStateEndDate]="dashboardThroughputFormToState$ | async"
              [config]="dashboardDateRangeConfig"
              [startDateLabel]="dashboardStartDateLabel$ | async"
              [endDateLabel]="dashboardEndDateLabel$ | async"
            ></lib-common-datetime-range-picker>
            <p>Durchschnittliche Zeit bis zur Beendung: {{ dashboardThroughputFinished$ | async }}</p>
          </div>
        </div>
        <lib-common-circle-radar-chart
          [data]="dashboardThroughputStateData$ | async"
          [valueFormatter]="throughputValueFormatter"
          [showChartTypeSelector]="['pie', 'radar']"
        ></lib-common-circle-radar-chart>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{'proposalCountDashboard' | i18n | async}}">
      <ng-template matTabContent>
          <div fxLayoutAlign="center center" class="chart-container">
            <div fxFlexAlign="center">
              <lib-common-datetime-range-picker
                [formControlStateStartDate]="dashboardStateCountFormFromState$ | async"
                [formControlStateEndDate]="dashboardStateCountFormToState$ | async"
                [config]="dashboardDateRangeConfig"
                [startDateLabel]="dashboardStartDateLabel$ | async"
                [endDateLabel]="dashboardEndDateLabel$ | async"
              ></lib-common-datetime-range-picker>
              <p>Gesamt Anzahl von Vorgängen: {{ dashboardStateTotalCountState$ | async }}</p>
            </div>
          </div>
          <lib-common-circle-radar-chart
            [data]="dashboardStateCountState$ | async"
            [showChartTypeSelector]="['pie', 'radar']"
          ></lib-common-circle-radar-chart>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{'payoutDashboard' | i18n | async}}">
      <ng-template matTabContent>
        <div class="chart-container">
          <div fxLayoutAlign="center center">
            <div fxFlexAlign="center">
              <lib-common-datetime-range-picker
                [formControlStateStartDate]="getDashboardPayoutFormFromState$ | async"
                [formControlStateEndDate]="dashboardPayoutFormToState$ | async"
                [config]="dashboardDateRangeConfig"
                [startDateLabel]="dashboardStartDateLabel$ | async"
                [endDateLabel]="dashboardEndDateLabel$ | async"
              ></lib-common-datetime-range-picker>
            </div>
          </div>
          <lib-common-circle-radar-chart
            [data]="dashboardPayoutState$ | async"
            [showChartTypeSelector]="['bar']"
            [type]="'bar'"
            [valueFormatter]="lineValueFormatter"
          ></lib-common-circle-radar-chart>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</lib-common-page-margin>
