import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControlState } from 'ngrx-forms';
import { RouteConfigFormPropertyComponent } from '../../../core/components/route-config-form-property/route-config-form-property.component';
import { CoreFeatureState } from '../../../core/ngrx/reducers/core.store';
import { ConfigItemFileUploadConfigurationDtoModel } from '../../models/config-item-file-upload-configuration-dto.model';
import { FileUploadCategoryModel } from '../../models/file-upload-category.model';
import { FileUploadTypeModel } from '../../models/file-upload-type.model';
import { SetValueTraceableAction } from '../../models/set-value-traceable-action';
import { InputPropertyTypeComponent } from '../input-property-type/input-property-type.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-input-property-type-file',
  templateUrl: './input-property-type-file.component.html',
  styleUrls: ['./input-property-type-file.component.scss'],
})
export class InputPropertyTypeFileComponent extends InputPropertyTypeComponent {

  typeName = ConfigItemFileUploadConfigurationDtoModel.TYPENAME;

  @Input() ngrxRequiredCategories: FormControlState<string>;
  @Input() ngrxAvailableCategories: FormControlState<string>;
  @Input() ngrxMin: FormControlState<number>;
  @Input() ngrxMax: FormControlState<number>;
  @Input() ngrxUploadType: FormControlState<string>;

  possibleCategories = <(keyof FileUploadCategoryModel)[]>Object.keys(FileUploadCategoryModel).filter(value => value !== 'UNDEFINED');
  uploadTypes = <(keyof FileUploadTypeModel)[]>Object.keys(FileUploadTypeModel);

  constructor(store$: Store<CoreFeatureState>, util: RouteConfigFormPropertyComponent) {
    super(store$, util);
  }

  internalReloadValues(lastConfigItemValue) {
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxRequiredCategories.id, lastConfigItemValue.requiredCategories));
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxAvailableCategories.id, lastConfigItemValue.availableCategories));
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxMin.id, lastConfigItemValue.min));
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxMax.id, lastConfigItemValue.max));
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxUploadType.id, lastConfigItemValue.uploadType));
  }
}
