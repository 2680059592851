import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { faqLoadFaqsAction } from '../../../core/ngrx/actions/faq.actions';
import { getFaqFaqsState } from '../../../core/ngrx/reducers/core.store';
import { FaqElementModel } from '../../models/faq-tree.model';

@Component({
  selector: 'lib-common-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {

  faqTreeEntries$ = this.store$.select(getFaqFaqsState).pipe(
    map(faqTrees => faqTrees.filter(faqTree => faqTree.section)),
  );

  faqEntries$: Observable<FaqElementModel[]> = this.store$.select(getFaqFaqsState).pipe(
    map(faqTrees => faqTrees.filter(faqTree => !faqTree.section)),
    map(faqTrees => faqTrees.reduce((prev, curr) => prev.concat(curr.items), [] as FaqElementModel[])),
  );

  constructor(private store$: Store<any>) {
  }

  ngOnInit(): void {
    this.store$.dispatch(faqLoadFaqsAction());
  }

}
