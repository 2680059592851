import { CompanyDataDtoModel } from '../../../core-lib/models/company-data-dto.model';
import {
  CoreCompanyFormActionLoadedCompanies,
  CoreCompanyFormActionSaveSuccess,
  CoreCompanyFormsActions,
} from '../actions/core-company-forms.actions';

export type CompaniesStore = CompanyDataDtoModel[];

export const initialCompaniesState: CompaniesStore = [];

export function companiesReducer(state = initialCompaniesState, action: CoreCompanyFormsActions): CompaniesStore {
  switch (action.type) {
    case CoreCompanyFormActionLoadedCompanies.TYPE:
      return [
        ...action.companies,
      ];
    case CoreCompanyFormActionSaveSuccess.TYPE:
      const equalsSavedCompanyId = c => c.id === action.company.id;
      const exists = state.find(equalsSavedCompanyId);
      if (exists) {
        return [
          ...state.map(c => equalsSavedCompanyId(c) ? action.company : c),
        ];
      } else {
        return [
          ...state.filter(c => !equalsSavedCompanyId(c)),
          action.company,
        ];
      }
    default:
      return state;
  }
}
