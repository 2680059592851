import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { box, FormArrayState } from 'ngrx-forms';
import { filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from '../../../core-lib/services/api.service';
import { SapInfoModel } from '../../../forms-lib/models/sap-info.model';
import { AccountFormMappingDtoModel } from '../../models/account-form-mapping-dto.model';
import { AccountFormMappingModel } from '../../models/account-form-mapping.model';
import { NetRegionModel } from '../../models/net-region.model';
import {
  coreConfigServiceCenterDataLoad,
  coreConfigServiceCenterDataLoadSuccess,
  coreConfigServiceCenterDataSave,
  coreConfigServiceCenterDataSaveSuccess,
} from '../actions/core-config.actions';
import { ConcreteApiSuccessAction } from '../actions/core.actions';
import { getConfigServiceCenterFormState } from '../reducers/core.store';

@Injectable()
export class CoreConfigServiceCenterEffects {

  mapToBackend = map((state: FormArrayState<NetRegionModel<AccountFormMappingModel>>)
    : NetRegionModel<AccountFormMappingDtoModel>[] => state.value.map(region => ({
    ...region,
    serviceCenters: (region.serviceCenters.map(center => ({
      ...center,
      accountFormMappings: center.accountFormMappings.map(mapping => ({
        id: mapping.id,
        formIdentifier: mapping.formIdentifier,
        account: mapping.account?.value?.id,
        accountName: mapping.account?.value?.description,
        accountType: mapping.account?.value?.type,
      })),
    }))),
  })));

  mapToUi = map((netRegions: NetRegionModel<AccountFormMappingDtoModel>[]): NetRegionModel<AccountFormMappingModel>[] => netRegions.map(
    region => ({
      ...region,
      serviceCenters: (region.serviceCenters.map(center => ({
        ...center,
        accountFormMappings: center.accountFormMappings.map(mapping => ({
          id: mapping.id,
          formIdentifier: mapping.formIdentifier,
          account: box<SapInfoModel>({id: mapping.account, description: mapping.accountName, type: mapping.accountType}),
        })),
      }))),
    })));

  constructor(private actions$: Actions, private store$: Store<any>, private api: ApiService) {
  }

  loadServiceCenterData$ = createEffect(() => this.actions$.pipe(
    ofType(coreConfigServiceCenterDataLoad),
    switchMap(() => this.api.getServiceCenterData()),
    this.mapToUi,
    map((data) => coreConfigServiceCenterDataLoadSuccess({data})),
  ));

  saveServiceCenterData$ = createEffect(() => this.actions$.pipe(
    ofType(coreConfigServiceCenterDataSave),
    withLatestFrom(this.store$.select(getConfigServiceCenterFormState), (a, s) => s),
    filter(s => s.isValid),
    this.mapToBackend,
    switchMap(s => this.api.postServiceCenterData(s)),
    this.mapToUi,
    mergeMap((data) => [coreConfigServiceCenterDataSaveSuccess({data}), new ConcreteApiSuccessAction('serviceCenterSaved')]),
  ));

}
