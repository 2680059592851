import { Action } from '@ngrx/store';
import { ConfigDtoModel, ConfigurationItems } from '../../../core-lib/models/config-dto.model';
import { FormConfigIdentityModel } from '../../models/form-config-identity.model';
import { ApiErrorAction, ApiSuccessAction } from './core.actions';

export class CoreEasyFormsInitAction implements Action {
  static readonly TYPE = 'core/easyForms/Init';
  readonly type = CoreEasyFormsInitAction.TYPE;
}

export class CoreEasyFormsReadyAction implements Action {
  static readonly TYPE = 'core/easyForms/Ready';
  readonly type = CoreEasyFormsReadyAction.TYPE;
}

/**
 * Lädt alle Formularkonfigurationen
 */
export class CoreEasyFormsLoadFormsAction implements Action {
  static readonly TYPE = 'core/easyForms/LoadForms';
  readonly type = CoreEasyFormsLoadFormsAction.TYPE;
}

export class CoreEasyFormsLoadFormsSuccessAction implements Action {
  static readonly TYPE = 'core/easyForms/LoadedFormsSuccess';
  readonly type = CoreEasyFormsLoadFormsSuccessAction.TYPE;

  constructor(public forms: any) {
  }
}

export class CoreEasyFormsLoadFormsErrorAction implements Action {
  static readonly TYPE = 'core/easyForms/LoadedFormsError';
  readonly type = CoreEasyFormsLoadFormsErrorAction.TYPE;

  constructor(public e: any) {
  }
}

/**
 * Lädt eine (aktuelle) Formularkonfiguration, upserted diese in core.easyFormConfigs und setzt diese auf core.easyFormConfigs.selected
 */
export class CoreEasyFormsLoadFormConfigAction implements Action {
  static readonly TYPE = 'core/easyForms/LoadFormConfigs';
  readonly type = CoreEasyFormsLoadFormConfigAction.TYPE;

  constructor(public formConfigIdentity: FormConfigIdentityModel) {
  }
}

/**
 * Setzt Änderungen von CoreEasyFormsLoadFormConfigAction zurück
 */
export class CoreEasyFormsResetFormConfigAction implements Action {
  static readonly TYPE = 'core/easyForms/ResetFormConfigs';
  readonly type = CoreEasyFormsResetFormConfigAction.TYPE;
}

export class CoreEasyFormsInheritFormConfigAction implements Action {
  static readonly TYPE = 'core/easyForms/InheritFormConfigs';
  readonly type = CoreEasyFormsInheritFormConfigAction.TYPE;

  constructor(public parentFormConfigIdentity: FormConfigIdentityModel) {
  }
}

export class CoreEasyFormsDeinheritFormConfigAction implements Action {
  static readonly TYPE = 'core/easyForms/DeinheritFormConfigs';
  readonly type = CoreEasyFormsDeinheritFormConfigAction.TYPE;

  constructor(public formConfigIdentity: FormConfigIdentityModel) {
  }
}

export class CoreEasyFormsLoadFormConfigSuccessAction implements Action {
  static readonly TYPE = 'core/easyForms/LoadFormConfigSuccess';
  readonly type = CoreEasyFormsLoadFormConfigSuccessAction.TYPE;

  constructor(
    public config: ConfigDtoModel<any> | null,
    public formConfigIdentity: FormConfigIdentityModel,
  ) {
  }
}

export class CoreEasyFormsLoadFormConfigErrorAction extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/core/easyForms/LoadedFormConfigsError';
  readonly type = CoreEasyFormsLoadFormConfigErrorAction.TYPE;
}

export class CoreEasyFormsEnableConfigAction implements Action {
  static readonly TYPE = 'core/easyForms/EnableConfig';
  readonly type = CoreEasyFormsEnableConfigAction.TYPE;
}

/**
 * Speichert Formularkonfiguration (via select(getEasyFormConfigsSelectedValueState))
 */
export class CoreEasyFormsSaveFormConfigAction implements Action {
  static readonly TYPE = 'core/easyForms/SaveFormConfig';
  readonly type = CoreEasyFormsSaveFormConfigAction.TYPE;
}

export class CoreEasyFormsSaveFormConfigErrorAction implements Action {
  static readonly TYPE = 'core/easyForms/SaveFormConfigError';
  readonly type = CoreEasyFormsSaveFormConfigErrorAction.TYPE;

  constructor(public e: any) {
  }
}

export class CoreEasyFormsSaveFormConfigSuccessAction extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/core/easyForms/SaveFormConfigSuccess';
  readonly type = CoreEasyFormsSaveFormConfigSuccessAction.TYPE;
  messageKey = 'formConfigSaved';
  snackBarDuration = 5000;
}

/**
 * Löscht Entities aus core.easyFormConfigs anhand mehrerer mehrdeutigen FormConfigIdentityModel
 */
export class CoreEasyFormsDeleteFormConfigsAction implements Action {
  static readonly TYPE = 'core/easyForms/DeleteFormConfigs';
  readonly type = CoreEasyFormsDeleteFormConfigsAction.TYPE;

  constructor(public configs: Partial<FormConfigIdentityModel>[]) {
  }
}

export class CoreEasyFormsDeleteFormConfigsSuccessAction extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/core/easyForms/DeleteFormConfigsSuccess';
  readonly type = CoreEasyFormsDeleteFormConfigsSuccessAction.TYPE;
  messageKey = 'formConfigCompanyDeleteSuccess';
  snackBarDuration = 5000;
}

export class CoreEasyFormsDeleteFormConfigsErrorAction implements Action {
  static readonly TYPE = 'core/easyForms/DeleteFormConfigsError';
  readonly type = CoreEasyFormsDeleteFormConfigsErrorAction.TYPE;

  constructor(public e: any) {
  }
}

/**
 * Sucht mehrere Formularkonfigurationen anhand eines mehrdeutigen FormConfigIdentityModel.
 * Diese werden dann in core.easyFormConfigs(.entity) abgelegt.
 */
export class CoreEasyFormsLoadFormConfigsByFormIdAction implements Action {
  static readonly TYPE = 'core/easyForms/LoadFormConfigsByFormIdAction';
  readonly type = CoreEasyFormsLoadFormConfigsByFormIdAction.TYPE;

  constructor(public configIds: Partial<FormConfigIdentityModel>) {
  }
}

export class CoreEasyFormsLoadFormConfigsByFormIdSuccessAction implements Action {
  static readonly TYPE = 'core/easyForms/LoadFormConfigsByFormIdSuccess';
  readonly type = CoreEasyFormsLoadFormConfigsByFormIdSuccessAction.TYPE;

  constructor(
    public configs: ConfigDtoModel<ConfigurationItems>[] = [],
    public formIdentifier: string,
  ) {
  }
}

export class CoreEasyFormsLoadFormConfigsByFormIdErrorAction extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/core/easyForms/LoadFormConfigsByFormIdError';
  readonly type = CoreEasyFormsLoadFormConfigsByFormIdErrorAction.TYPE;
}

export type CoreEasyFormsActions
  = CoreEasyFormsInitAction
  | CoreEasyFormsReadyAction
  | CoreEasyFormsLoadFormsAction
  | CoreEasyFormsLoadFormsSuccessAction
  | CoreEasyFormsLoadFormsErrorAction
  | CoreEasyFormsLoadFormConfigAction
  | CoreEasyFormsLoadFormConfigSuccessAction
  | CoreEasyFormsLoadFormConfigErrorAction
  | CoreEasyFormsInheritFormConfigAction
  | CoreEasyFormsDeinheritFormConfigAction
  | CoreEasyFormsDeleteFormConfigsAction
  | CoreEasyFormsDeleteFormConfigsSuccessAction
  | CoreEasyFormsDeleteFormConfigsErrorAction
  | CoreEasyFormsSaveFormConfigAction
  | CoreEasyFormsSaveFormConfigSuccessAction
  | CoreEasyFormsSaveFormConfigErrorAction
  | CoreEasyFormsLoadFormConfigsByFormIdAction
  | CoreEasyFormsLoadFormConfigsByFormIdSuccessAction
  | CoreEasyFormsLoadFormConfigsByFormIdErrorAction
  | CoreEasyFormsResetFormConfigAction
  | CoreEasyFormsEnableConfigAction;
