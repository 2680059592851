import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-angular-editor',
  templateUrl: './angular-editor.component.html',
  styleUrls: ['./angular-editor.component.scss'],
})
export class AngularEditorComponent implements OnInit {
  @Input() controlState: any;
  @Input() configName: any;
  @Input() id: string;
  @Input() config: AngularEditorConfig;

  realConfig: AngularEditorConfig = {};

  defaultEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
  };

  ngOnInit() {
    this.realConfig = {
      ...this.defaultEditorConfig,
      ...this.config,
      editable: this.controlState.isEnabled,
      showToolbar: this.controlState.isEnabled,
    };
  }

  getConfig() {
    // Muss die gleiche Objekt instanz sein da sonst der Editor fehler bringt
    this.realConfig.editable = this.controlState.isEnabled;
    this.realConfig.showToolbar = this.controlState.isEnabled;

    return this.realConfig;
  }
}
