import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { FormArrayState, FormGroupState, MarkAsUnsubmittedAction, SetAsyncErrorAction } from 'ngrx-forms';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { backendClasses } from '../../../core-lib/models/backend-classes.model';
import { ConfigListDtoModel } from '../../../core-lib/models/config-list-dto.model';
import { GlobalConfigDtoModel } from '../../../core-lib/models/global-config-dto.model';
import { SetValueTraceableAction } from '../../../core-lib/models/set-value-traceable-action';
import { SystemMessage } from '../../../core-lib/models/system-message.model';
import { UserDataFormModel } from '../../../core-lib/models/user-data-form.model';
import { ApiService } from '../../../core-lib/services/api.service';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { rxCatchApiError } from '../../../core-lib/utils/reducer-utils';
import { ConfigListModel } from '../../models/config-list.model';
import { CoreAccountFormActionLoadedUserData } from '../actions/core-account.actions';
import {
  coreConfigCateringOrderDataLoad,
  coreConfigCateringOrderDataLoadSuccess,
  coreConfigCateringOrderDataSave,
  coreConfigCateringOrderDataSaveSuccess,
  CoreConfigInit,
  CoreConfigLedgerAccountsSave,
  CoreConfigLedgerAccountsSaveError,
  CoreConfigLedgerAccountsSaveSuccess,
  CoreConfigLoadedGlobalConfig,
  coreConfigMassDataDownload,
  coreConfigMassDataUpload,
  CoreConfigSalutationsSave,
  CoreConfigSalutationsSaveError,
  CoreConfigSalutationsSaveSuccess,
  CoreConfigSetSystemMessage,
  CoreConfigSystemMessageAdminLoad,
  CoreConfigSystemMessageAdminLoadError,
  CoreConfigSystemMessageAdminLoadSuccess,
  CoreConfigSystemMessageSave,
  CoreConfigSystemMessageSaveError,
  CoreConfigSystemMessageSaveSuccess,
  CoreConfigTaxratesSave,
  CoreConfigTaxRatesSaveError,
  CoreConfigTaxRatesSaveSuccess, coreMassDataUploadSuccess,
  DeleteConfigItemAction,
  DeleteConfigItemError,
  DeleteConfigItemSuccess,
} from '../actions/core-config.actions';
import { CoreEasyFormsResetFormConfigAction } from '../actions/core-easy-forms.actions';
import { ApiErrorAction, ConcreteApiSuccessAction, CoreActionInit, resetFormAction } from '../actions/core.actions';
import { LEDGERACCOUNTS_FORM_ID, SALUTATIONS_FORM_ID, SYSTEMMESSAGE_FROM_ID, TAXRATES_FORM_ID } from '../reducers/config.reducer';
import {
  CoreFeatureState,
  getAccountFeatureState,
  getConfigGlobalConfigState,
  getConfigLedgerAccountsState,
  getConfigOrderDataFormState,
  getConfigSalutationsState,
  getConfigSystemMessageState,
  getConfigTaxRatesState,
} from '../reducers/core.store';
import { DataTransformers } from './dataTransformers';

@Injectable()
export class CoreConfigEffects {
  taxRatesState$ = this.store$.select(getConfigTaxRatesState).pipe(
    select('value'),
    map(Object.values),
  );
  ledgerAccountsState$ = this.store$.select(getConfigLedgerAccountsState);
  ledgerAccountsStateValues$ = this.ledgerAccountsState$.pipe(
    select('value'),
    map(Object.values),
  );
  salutationsState$ = this.store$.select(getConfigSalutationsState);
  salutationsStateValues$ = this.salutationsState$.pipe(
    select('value'),
    map(Object.values),
  );
  configGlobalConfigState$ = this.store$.select(getConfigGlobalConfigState);

  systemMessageState$ = this.store$.select(getConfigSystemMessageState);
  systemMessageStateValues$ = this.systemMessageState$.pipe(
    select('value'),
    map(Object.values),
  );

  configInit$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActionInit.TYPE),
    map(() => new CoreConfigInit()),
  ), defaultEffectOptions());

  configInitGlobalConfig$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigInit.TYPE),
    withLatestFrom(this.configGlobalConfigState$),
    switchMap(() => this.api.getGlobalConfig().pipe(
      map((c: GlobalConfigDtoModel) => new CoreConfigLoadedGlobalConfig(c)),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  configInitSystemMessage$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigInit.TYPE),
    switchMap(() => this.api.getSystemMessage().pipe(
      map((systemMessage) => new CoreConfigSetSystemMessage(systemMessage)),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  configLoadSystemMessage$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigSystemMessageAdminLoad.TYPE),
    switchMap((action: CoreConfigSystemMessageAdminLoad) => this.api.getSystemMessageAdmin().pipe(
      map((message) => new CoreConfigSystemMessageAdminLoadSuccess(message)),
      catchError(e => of(new CoreConfigSystemMessageAdminLoadError(e, e.message))),
    )),
  ), defaultEffectOptions());

  configSaveSystemMessage$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigSystemMessageSave.TYPE),
    withLatestFrom(this.systemMessageState$),
    switchMap(([action, state]: [CoreConfigSystemMessageSave, FormGroupState<SystemMessage>]) => this.api.postSystemMessage(state.value)
      .pipe(
        map((message) => new CoreConfigSystemMessageSaveSuccess(message)),
        catchError(e => of(new CoreConfigSystemMessageSaveError(e, e.message))),
      )),
  ), defaultEffectOptions());

  configSaveSystemMessageSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigSystemMessageSaveSuccess.TYPE),
    tap(() => this.store$.dispatch(new MarkAsUnsubmittedAction(SYSTEMMESSAGE_FROM_ID))),
    map(() => new CoreConfigInit()),
  ), defaultEffectOptions());

  configSaveSystemMessageError$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigSystemMessageSaveError.TYPE),
    tap(() => this.store$.dispatch(new MarkAsUnsubmittedAction(SYSTEMMESSAGE_FROM_ID))),
    map(() => new CoreConfigInit()),
  ), defaultEffectOptions());

  configInitTaxRateForm$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigLoadedGlobalConfig.TYPE),
    select('config'),
    filter((config: GlobalConfigDtoModel) => !!(
      config &&
      config.configurationItems &&
      config.configurationItems.taxrates &&
      config.configurationItems.taxrates.values
    )),
    map((config: GlobalConfigDtoModel) => new SetValueTraceableAction(TAXRATES_FORM_ID, [
      ...config.configurationItems.taxrates.values.map(DataTransformers.mapDtoToConfigListModel),
    ])),
  ), defaultEffectOptions());

  resetEasyConfigs$ = createEffect(() => this.actions$.pipe(
    ofType(resetFormAction),
    map(() => new CoreEasyFormsResetFormConfigAction()),
  ), defaultEffectOptions());

  configSaveTaxrate$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigTaxratesSave.TYPE),
    withLatestFrom(this.taxRatesState$, this.configGlobalConfigState$),
    map(([action, taxrates, globalConfig]: [CoreConfigTaxratesSave, ConfigListModel[], GlobalConfigDtoModel]): GlobalConfigDtoModel => (
      {
        ...globalConfig,
        configurationItems: {
          ...globalConfig.configurationItems,
          taxrates: {
            ...globalConfig.configurationItems.taxrates,
            '@class': backendClasses.listConfiguration,
            attributeName: 'taxrates',
            values: taxrates.map(DataTransformers.mapConfigListModelToDto),
            translations: {
              ...globalConfig.configurationItems.taxrates.translations,
            },
          },
        },
      }
    )),
    switchMap((taxrates: GlobalConfigDtoModel) => this.api.postGlobalConfig(taxrates).pipe(
      map((config) => new CoreConfigTaxRatesSaveSuccess(config)),
      catchError(e => of(new CoreConfigTaxRatesSaveError(e, e.message))),
    )),
  ), defaultEffectOptions());

  configSaveTaxRatesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigTaxRatesSaveSuccess.TYPE),
    tap(() => this.store$.dispatch(new MarkAsUnsubmittedAction(TAXRATES_FORM_ID))),
    map(() => new CoreConfigInit()),
  ), defaultEffectOptions());

  configSaveTaxRatesError$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigTaxRatesSaveError.TYPE),
    tap(() => this.store$.dispatch(new MarkAsUnsubmittedAction(TAXRATES_FORM_ID))),
    map(() => new CoreConfigInit()),
  ), defaultEffectOptions());

  configInitLedgerAccounts$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigLoadedGlobalConfig.TYPE),
    select('config'),
    filter((config: GlobalConfigDtoModel) => !!(
      config &&
      config.configurationItems &&
      config.configurationItems.ledgerAccounts &&
      config.configurationItems.ledgerAccounts.values
    )),
    map((config: GlobalConfigDtoModel) => new SetValueTraceableAction(LEDGERACCOUNTS_FORM_ID, [
      ...[...config.configurationItems.ledgerAccounts.values]
        .sort((a, b) => a.attributeName.localeCompare(b.attributeName))
        .map(DataTransformers.mapDtoToConfigListModel),
    ])),
  ), defaultEffectOptions());

  configSaveLedgerAccounts$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigLedgerAccountsSave.TYPE),
    withLatestFrom(this.ledgerAccountsStateValues$, this.configGlobalConfigState$, this.ledgerAccountsState$),
    filter(([action, ledgerAccounts, globalConfig, ledgerState]) => ledgerState.isValid),
    map(([action, ledgerAccounts, globalConfig]:
           [CoreConfigTaxratesSave, ConfigListModel[], GlobalConfigDtoModel, FormArrayState<any>]): GlobalConfigDtoModel => (
      {
        ...globalConfig,
        configurationItems: {
          ...globalConfig.configurationItems,
          ledgerAccounts: {
            ...globalConfig.configurationItems.ledgerAccounts,
            '@class': backendClasses.listConfiguration,
            attributeName: 'ledgerAccounts',
            values: ledgerAccounts.map(DataTransformers.mapConfigListModelToDto),
            translations: {
              ...globalConfig.configurationItems.ledgerAccounts.translations,
            },
          },
        },
      }
    )),
    switchMap((globalConfig: GlobalConfigDtoModel) => this.api.postGlobalConfig(globalConfig).pipe(
      map((config) => new CoreConfigLedgerAccountsSaveSuccess(config)),
      catchError(e => of(new CoreConfigLedgerAccountsSaveError(e, e.message))),
    )),
  ), defaultEffectOptions());

  configSaveLedgerAccountsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigLedgerAccountsSaveSuccess.TYPE),
    tap(() => this.store$.dispatch(new MarkAsUnsubmittedAction(TAXRATES_FORM_ID))),
    map(() => new CoreConfigInit()),
  ), defaultEffectOptions());

  configSaveLedgerAccountsError$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigLedgerAccountsSaveError.TYPE),
    tap(() => this.store$.dispatch(new MarkAsUnsubmittedAction(TAXRATES_FORM_ID))),
    map(() => new CoreConfigInit()),
  ), defaultEffectOptions());

  configInitSalutations$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigLoadedGlobalConfig.TYPE),
    withLatestFrom(this.store$.select(getAccountFeatureState)),
    map(([a, accountState]: [CoreConfigLoadedGlobalConfig, FormGroupState<UserDataFormModel>]) => [a.config, accountState]),
    filter(([config, accountState]: [GlobalConfigDtoModel, FormGroupState<UserDataFormModel>]) => !!(
      config?.configurationItems?.salutations?.values
    )),
    map(([config, accountState]) => [
      new SetValueTraceableAction(SALUTATIONS_FORM_ID, [
        ...config.configurationItems.salutations.values.map(DataTransformers.mapDtoToConfigListModel),
      ]), accountState,
    ]),
    tap(([valueAction, accountState]: [any, FormGroupState<UserDataFormModel>]) => {
      this.store$.dispatch(new CoreAccountFormActionLoadedUserData({
        ...accountState.value,
        salutation: {
          ...accountState.value.salutation,
          value: valueAction.value.find((s) => (
            accountState.value?.salutation?.value?.id === s?.id
          )),
        },
      }));
    }),
    map(([valueAction]) => valueAction),
  ), defaultEffectOptions());

  // @ts-ignore
  configSaveSalutations$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigSalutationsSave.TYPE),
    withLatestFrom(this.salutationsStateValues$, this.configGlobalConfigState$, this.salutationsState$),
    filter(([action, salutations, globalConfig, salutationsState]) => salutationsState.isValid),
    map((
      [action, salutations, globalConfig]:
        [CoreConfigSalutationsSave, ConfigListModel[], GlobalConfigDtoModel, FormArrayState<any>],
    ): GlobalConfigDtoModel => (
      {
        ...globalConfig,
        configurationItems: {
          ...globalConfig.configurationItems,
          salutations: {
            ...(
              new ConfigListDtoModel('salutations')
            ),
            ...globalConfig.configurationItems.salutations,
            '@class': backendClasses.listConfiguration,
            attributeName: 'salutations',
            values: salutations.map(DataTransformers.mapConfigListModelToDto),
            translations: {
              ...globalConfig.configurationItems.salutations.translations,
            },
          },
        },
      }
    )),
    switchMap((globalConfig: GlobalConfigDtoModel) => this.api.postGlobalConfig(globalConfig).pipe(
      map((config) => new CoreConfigSalutationsSaveSuccess(config)),
      catchError(e => of(new CoreConfigSalutationsSaveError(e, e.message))),
    )),
  ), defaultEffectOptions());

  configSaveSalutationsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigSalutationsSaveSuccess.TYPE),
    tap(() => this.store$.dispatch(new MarkAsUnsubmittedAction(SALUTATIONS_FORM_ID))),
    map(() => new CoreConfigInit()),
  ), defaultEffectOptions());

  configSaveSalutationsError$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigSalutationsSaveError.TYPE),
    tap(() => this.store$.dispatch(new MarkAsUnsubmittedAction(SALUTATIONS_FORM_ID))),
    map(() => new SetAsyncErrorAction('salutations.0.labels.en.text', 'test', 'Async Val')),
  ), defaultEffectOptions());

  configDeleteConfigItem$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteConfigItemAction.TYPE),
    filter((action: DeleteConfigItemAction) => !!action.configId),
    switchMap((action: DeleteConfigItemAction) => this.api.deleteConfigItem(action.configId).pipe(
      map(() => new DeleteConfigItemSuccess()),
      catchError(e => of(new DeleteConfigItemError(e, e.message))),
    )),
  ), defaultEffectOptions());

  configCateringOrderDataLoad$ = createEffect(() => this.actions$.pipe(
    ofType(coreConfigCateringOrderDataLoad),
    withLatestFrom(this.store$.select(getConfigOrderDataFormState), (a, s) => s.value.selectedCompany),
    switchMap((selectedCompany) => this.api.getCateringOrderData(selectedCompany)),
    map(data => coreConfigCateringOrderDataLoadSuccess({data})),
    catchError(e => of(new ApiErrorAction(e, 'coreConfigCateringOrderDataLoadError'))),
  ), defaultEffectOptions());

  configCateringOrderDataSave$ = createEffect(() => this.actions$.pipe(
    ofType(coreConfigCateringOrderDataSave),
    withLatestFrom(this.store$.select(getConfigOrderDataFormState), (a, s) => s),
    filter((s) => s.isValid),
    switchMap((s) => this.api.postCateringOrderData(s.value.orderData, s.value.selectedCompany)),
    mergeMap(data => [
      coreConfigCateringOrderDataSaveSuccess({data}),
      new ConcreteApiSuccessAction('coreConfigCateringOrderDataSaveSuccess'),
    ]),
    catchError(e => of(new ApiErrorAction(e, 'coreConfigCateringOrderDataSaveError'))),
  ), defaultEffectOptions());

  downloadMassDataTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(coreConfigMassDataDownload),
    filter(value => !!(value.companyIdentifier && value.formIdentifier && value.count)),
    switchMap(({formIdentifier, companyIdentifier, count}) => this.api.downloadMassDataTemplate(formIdentifier, companyIdentifier, count)),
    catchError(e => of(new ApiErrorAction(e, 'massDataExcelError'))),
  ), defaultEffectOptions({dispatch: false}));

  uploadMassDataTemplate$ = createEffect(() => this.actions$.pipe(
    ofType(coreConfigMassDataUpload),
    switchMap(({formIdentifier, companyIdentifier, file}) => this.api.uploadMassDataTemplate(formIdentifier, companyIdentifier, file)),
    mergeMap(() => [ coreMassDataUploadSuccess(), new ConcreteApiSuccessAction('massDataSuccess')]),
    catchError(e => of(new ApiErrorAction(e, 'massDataError') )),
  ), defaultEffectOptions());

  constructor(
    private actions$: Actions,
    private store$: Store<CoreFeatureState>,
    private api: ApiService,
  ) {
  }
}
