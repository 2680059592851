export interface FaqElementModel {
  id?: number;
  question: string;
  answer: string;
  language: string;
  section: string;
  markedForDeletion: boolean;
}

export interface FaqTreeModel {
  section: string;
  items: FaqElementModel[];
}

export const toFaqTreeModel = (i: FaqElementModel[]): FaqTreeModel[] => Object.values(
  i.reduce((acc, curr) => ({
    ...acc,
    [curr.section]: {
      section: curr.section,
      items: [...((acc[curr.section] || []).items || []), curr],
    },
  }), {}),
);

export const toFaqElementModel = (elementModels: FaqTreeModel[]): FaqElementModel[] =>
  elementModels.reduce((acc, curr) => (acc.concat(curr.items.map(item => ({...item, section: curr.section})))), []);
