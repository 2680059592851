import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { FormControlState, ValidationErrors } from 'ngrx-forms';

import { countries } from './countries';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss'],
})
export class SelectCountryComponent {

  private _countryState: FormControlState<string>;

  get countryState(): FormControlState<string> {
    return this._countryState;
  }

  @Input()
  set countryState(value: FormControlState<string>) {
    this._countryState = value;
    if (value) {
      this.errors = value.errors;
      this.errorKeys = Object.keys(this.errors);
    }
  }


  @Input() required = false;

  countries = countries;
  errors: Dictionary<string>;
  errorKeys: string[];
}



