import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-route-catering-overview',
  templateUrl: './route-catering-overview.component.html',
  styleUrls: ['./route-catering-overview.component.scss'],
})
export class RouteCateringOverviewComponent {

}
