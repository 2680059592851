import { Component, OnInit } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { FormBaseDtoModel } from '../../../forms/form-base/models/form-base-dto.model';
import { loadTemplateDeputy } from '../../ngrx/actions/template-deputy.actions';
import { TemplateState } from '../../ngrx/reducers/core-template-store';
import { getDeputyTemplates } from '../../ngrx/reducers/core.store';

@Component({
  selector: 'lib-common-route-template-deputy',
  templateUrl: './route-template-deputy.component.html',
  styleUrls: ['./route-template-deputy.component.scss'],
})
export class RouteTemplateDeputyComponent implements OnInit {
  deputyCards$ = this.store$.select(getDeputyTemplates).pipe(map(entry => entry.deputyTemplate));

  mapTemplateEntries(entries: FormBaseDtoModel[]) {
    return entries.map((c) => ({
      data: {
        caption: c.templateName,
        subCaption: '',
        class: c.form.category.name,
        backgroundColor: c.form.category.backgroundColor,
        color: c.form.category.color,
        identifier: c.form.identifier,
        templateName: c.templateName,
        order: c.viewOrder,
        proposalId: c.id,
        routerLink: `/forms/${c.form.category.name}/${c.form.identifier}/template/deputy/${c.id}`,
      },
    }));

  }

  isEmpty(entry: Dictionary<FormBaseDtoModel[]>) {
    return !entry || Object.keys(entry).length === 0;
  }

  constructor(private store$: Store<any>) {
  }

  ngOnInit(): void {
    this.store$.dispatch(loadTemplateDeputy());
  }


}
