import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Moment } from 'moment';
import { NgrxValueConverter } from 'ngrx-forms';
import { Subject } from 'rxjs';
import { QueryPromptComponent } from '../../../core-lib/components/query-prompt/query-prompt.component';
import { QueryPromptData } from '../../../core-lib/models/query-prompt-data.model';
import { SystemMessage } from '../../../core-lib/models/system-message.model';
import { rxGetFormControl } from '../../../core-lib/utils/reducer-utils';
import { CoreConfigSystemMessageAdminLoad, CoreConfigSystemMessageSave } from '../../ngrx/actions/core-config.actions';
import { CoreFeatureState, getConfigSystemMessageState } from '../../ngrx/reducers/core.store';


@Component({
  selector: 'lib-common-route-config-system-message',
  templateUrl: './route-config-system-message.component.html',
  styleUrls: ['./route-config-system-message.component.scss'],
})
export class RouteConfigSystemMessageComponent implements OnInit, OnDestroy {

  private componentDestroyed$ = new Subject<void>();
  systemMessageForm$ = this.store$.select(getConfigSystemMessageState);

  ngrxLocalDateTimeValueConverter = <NgrxValueConverter<Moment, string>>{
    convertStateToViewValue: (value: string): Moment => value && moment(value),
    // TODO: als ISO String mit offset machen, dafür Anpassungen im Backend nötig (OffsetDateTime)
    convertViewToStateValue: (value: Moment): string => value ? value.toISOString(false) : 'noDate',
  };

  constructor(
    private store$: Store<CoreFeatureState>,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.store$.dispatch(new CoreConfigSystemMessageAdminLoad());
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  saveSystemMessage() {
    this.store$.dispatch(new CoreConfigSystemMessageSave());
  }

  getFormControlState$(name: keyof SystemMessage) {
    return this.systemMessageForm$.pipe(rxGetFormControl<SystemMessage>(name));
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(QueryPromptComponent, {
      data: <QueryPromptData>{
        title: 'systemMessage',
        text: 'systemMessageDate',
        declineLabel: 'back',
        hideAcceptLabel: true,
      },
    });
  }
}
