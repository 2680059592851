<div class="margin-content">
  <form (ngSubmit)="save()">
    <lib-common-form-flex-container>
      <div class="card-width">
        <mat-card>
          <mat-card-title class="text-center">{{'selectCompany' | i18n | async}}</mat-card-title>
          <mat-card-content>
            <div
              fxLayoutAlign="center"
              fxLayoutGap="10px grid"
            >
              <div
                fxFlex.gt-md="45%"
                fxFlex.lt-lg="100%"
              >
                <mat-form-field class="input-width">
                  <mat-select
                    [ngrxFormControlState]="selectedCompany$ | async"
                    [placeholder]="'selectedCompany' | i18n |async"
                    (selectionChange)="loadCateringOrderData()"
                    required
                    #selectedCompany
                  >
                    <mat-option
                      *ngFor="let company of companies$ | async"
                      [value]="company.id"
                    >
                      {{ company.name + ' - ' + company.companyId}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div *ngFor="let orderData of (orderDataArrayState$ |async); index as index; trackBy: getControlId" class="card-width">
        <mat-card>
          <mat-card-content>
            <div
              fxLayoutAlign="space-between"
              fxLayoutGap="10px grid"
            >
              <div
                fxFlex.gt-md="32%"
                fxFlex.lt-lg="100%"
              >
                <mat-form-field class="input-width">
                  <mat-select
                    [ngrxFormControlState]="orderData.controls.locationInfo"
                    [placeholder]="'location' | i18n |async"
                    required
                  >
                    <mat-option
                      *ngFor="let location of locations$ | async"
                      [value]="location.id"
                    >
                      {{ location.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div
                fxFlex.gt-md="32%"
                fxFlex.lt-lg="100%"
              >
                <mat-form-field class="input-width">
                  <input
                    [ngrxFormControlState]="orderData.controls.cateringOrder"
                    matInput
                    [placeholder]="'order' | i18n | async"
                    required
                  >
                </mat-form-field>
              </div>
              <div
                fxFlex.gt-md="32%"
                fxFlex.lt-lg="100%"
              >
                <mat-form-field class="input-width">
                  <input
                    [ngrxFormControlState]="orderData.controls.cateringPosition"
                    matInput
                    [placeholder]="'position' | i18n | async"
                    required
                  >
                </mat-form-field>
              </div>
              <div
                fxFlex.gt-md="5%"
                fxFlex.lt-lg="100%"
              >
                <button class="delete-button" type="button" (click)="removeOrderData(index)" mat-icon-button>
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="button"
          (click)="addOrderData()"
          color="accent"
          mat-fab
        >
          <mat-icon svgIcon="innogy:plus"></mat-icon>
        </button>
      </div>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="submit"
          color="primary"
          mat-raised-button
          [disabled]="(formState$ | async).isInvalid"
        >
          <mat-icon
            class="svg-icon-floppy"
            svgIcon="innogy:floppy"
          ></mat-icon>
          {{ 'save' | i18n | async }}
        </button>
      </div>
    </lib-common-form-flex-container>
  </form>
</div>
