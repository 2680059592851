import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  Actions,
  addArrayControl,
  createFormGroupState,
  FormArrayState,
  formGroupReducer,
  FormGroupState,
  isNgrxFormsAction,
  markAsTouched,
  setValue,
  updateGroup,
} from 'ngrx-forms';
import { UserDtoModel } from '../../../core-lib/models/user-dto.model';
import { getSessionUser } from '../../../session/reducers/session.reducer';
import {
  AccountDeputiesApproverFormModel,
  AccountDeputiesDeputyFormModel,
  AccountDeputiesEditorFormModel,
  AccountDeputiesFormModel,
} from '../../models/account-deputies-form.model';
import { AccountDeputyDtoModel } from '../../models/account-deputy-dto.model';
import { accountDeputiesActions, CoreAccountDeputiesActionsType } from '../actions/core-account-deputies.actions';
import { CoreFeatureState } from './core.store';

export const FORM_ID = 'accountDeputies';

export const initialAccountDeputiesState = createFormGroupState<AccountDeputiesFormModel>(FORM_ID, new AccountDeputiesFormModel());

export const accountDeputiesReducer = (
  _state: FormGroupState<AccountDeputiesFormModel>,
  action: CoreAccountDeputiesActionsType | Actions<any>,
) => {
  if (isNgrxFormsAction(<Action>action)) {
    return formGroupReducer(_state, <Actions<any>>action);
  }
  return reducer(_state, action as Action);
};

const reducer = createReducer<FormGroupState<AccountDeputiesFormModel>>(
  initialAccountDeputiesState,
  on(accountDeputiesActions.AccountDeputiesSaveSuccessAction, (state, {result}) => updateGroup<AccountDeputiesFormModel>(state, {
    approvers: setValue<readonly AccountDeputiesApproverFormModel[]>(
      result.filter((r) => r.type === 'APPROVAL').map((a): AccountDeputiesApproverFormModel => ({
        state: a.state === 'ACTIVE',
        type: 'APPROVAL',
        deputyUserId: a.deputyUserId,
        id: a.id,
      })),
    ),
    editors: setValue<readonly AccountDeputiesEditorFormModel[]>(
      result.filter((r) => r.type === 'EDIT').map((a): AccountDeputiesEditorFormModel => ({
        state: a.state === 'ACTIVE',
        type: 'EDIT',
        deputyUserId: a.deputyUserId,
        id: a.id,
      })),
    ),
  })),
  on(accountDeputiesActions.AccountDeputiesAddEditorAction, (state) => updateGroup<AccountDeputiesFormModel>(state, {
    editors: addArrayControl(new AccountDeputiesEditorFormModel()),
  })),
  on(accountDeputiesActions.AccountDeputiesAddApproverAction, (state) => updateGroup<AccountDeputiesFormModel>(state, {
    approvers: addArrayControl(new AccountDeputiesApproverFormModel()),
  })),
  on(accountDeputiesActions.AccountDeputiesRemoveEditorAction, (state, {formElementId}) => updateGroup<AccountDeputiesFormModel>(
    state,
    {
      editors: setValue<readonly AccountDeputiesEditorFormModel[]>([
        ...state.controls.editors.controls.filter((c) => c.id !== formElementId).map((c) => c.value),
      ]),
    },
  )),
  on(accountDeputiesActions.AccountDeputiesRemoveEditorAction, (state) => updateGroup<AccountDeputiesFormModel>(
    state,
    {
      editors: markAsTouched,
    },
  )),
  on(
    accountDeputiesActions.AccountDeputiesRemoveApproverAction,
    (state, {formElementId}) => updateGroup<AccountDeputiesFormModel>(state, {
      approvers: setValue<readonly AccountDeputiesApproverFormModel[]>([
        ...state.controls.approvers.controls.filter((c) => c.id !== formElementId).map((c) => c.value),
      ]),
    }),
  ),
  on(
    accountDeputiesActions.AccountDeputiesRemoveApproverAction,
    (state) => updateGroup<AccountDeputiesFormModel>(state, {
      approvers: markAsTouched,
    }),
  ),
  on(accountDeputiesActions.AccountDeputiesLoadAction, (state) => updateGroup<AccountDeputiesFormModel>(state, {
    approvers: setValue<readonly AccountDeputiesApproverFormModel[]>([]),
    editors: setValue<readonly AccountDeputiesEditorFormModel[]>([]),
  })),
  on(accountDeputiesActions.AccountDeputiesLoadedAction, (state, {result}) => updateGroup<AccountDeputiesFormModel>(
    state,
    {
      approvers: setValue<readonly AccountDeputiesApproverFormModel[]>(
        result.filter((r) => r.type === 'APPROVAL').map((a): AccountDeputiesApproverFormModel => ({
          state: a.state === 'ACTIVE',
          type: 'APPROVAL',
          deputyUserId: a.deputyUserId,
          id: a.id,
        })),
      ),
      editors: setValue<readonly AccountDeputiesEditorFormModel[]>(
        result.filter((r) => r.type === 'EDIT').map((a): AccountDeputiesEditorFormModel => ({
          state: a.state === 'ACTIVE',
          type: 'EDIT',
          deputyUserId: a.deputyUserId,
          id: a.id,
        })),
      ),
    },
  )),
);

export const getAccountDeputiesState = createSelector(
  createFeatureSelector<CoreFeatureState>('core'),
  s => s.forms.accountDeputiesForm,
);

export const getAccountDeputiesEditorsState = createSelector(
  getAccountDeputiesState,
  s => s.controls.editors,
);

export const getAccountDeputiesApproversState = createSelector(
  getAccountDeputiesState,
  s => s.controls.approvers,
);

function mapAccountDeputiesUI2Backend(deputies: readonly AccountDeputiesDeputyFormModel[], baseUserId: string): AccountDeputyDtoModel[] {
  return deputies.filter((a) => !!a.deputyUserId).map((a): AccountDeputyDtoModel => ({
    type: a.type,
    deputyUserId: a.deputyUserId,
    deputyName: null,
    baseUserId: baseUserId,
    baseUserName: null,
    id: a.id,
    state: a.state ? 'ACTIVE' : 'INACTIVE',
  }));
}

export const getAccountDeputiesAsDto = createSelector(
  [
    getAccountDeputiesApproversState,
    getAccountDeputiesEditorsState,
    getSessionUser,
  ],
  (
    approvers: FormArrayState<AccountDeputiesApproverFormModel>,
    editors: FormArrayState<AccountDeputiesEditorFormModel>,
    user: UserDtoModel,
  ) => (userId: string | undefined): AccountDeputyDtoModel[] => ([
    ...mapAccountDeputiesUI2Backend(approvers.value, userId || user.id),
    ...mapAccountDeputiesUI2Backend(editors.value, userId || user.id),
  ]),
);
