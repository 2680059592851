import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { SessionActionLoadedUser, SessionSpinnerClose, SessionSpinnerOpen } from '../../../session/actions/session.actions';
import { CoreCompanyFormActionLoadedCompanies } from '../actions/core-company-forms.actions';
import { CoreActionCategoriesLoaded } from '../actions/core-config.actions';
import { CoreEasyFormsReadyAction } from '../actions/core-easy-forms.actions';
import { CoreActionInit, CoreActionReady } from '../actions/core.actions';
import { CoreFeatureState, getCompaniesState, getConfigCategoriesState } from '../reducers/core.store';
import { getEasyFormsArrayState } from '../reducers/easy-forms.reducer';

@Injectable()
export class CoreEffects {
  static readonly CORE_SPINNER_HANDLE = 'CoreEffects';

  init$ = createEffect(() => this.actions$.pipe(
    ofType(SessionActionLoadedUser.TYPE),
    map(() => new CoreActionInit()),
  ), defaultEffectOptions());

  companies$ = this.store$.select(getCompaniesState);
  categories$ = this.store$.select(getConfigCategoriesState);
  easyForms$ = this.store$.select(getEasyFormsArrayState);

  /**
   * Ready Action werfen, wenn alle initialen Actions ein mal durch liefen
   */
  ready$ = createEffect(() => this.actions$.pipe(
    ofType(
      CoreCompanyFormActionLoadedCompanies.TYPE,
      CoreActionCategoriesLoaded.TYPE,
      CoreEasyFormsReadyAction.TYPE,
    ),
    withLatestFrom(this.companies$, this.categories$, this.easyForms$),
    filter(([a, companies, categories, easyForms]) => (
      Array.isArray(companies) &&
      Array.isArray(categories) &&
      Array.isArray(easyForms) &&
      companies.length > 0 &&
      categories.length > 0 &&
      easyForms.length > 0
    )),
    map(() => new CoreActionReady()),
  ), defaultEffectOptions());

  openSpinner$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActionInit.TYPE),
    map(() => new SessionSpinnerOpen(CoreEffects.CORE_SPINNER_HANDLE)),
  ), defaultEffectOptions());

  closeSpinner$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActionReady.TYPE),
    map(() => new SessionSpinnerClose(CoreEffects.CORE_SPINNER_HANDLE)),
  ), defaultEffectOptions());

  constructor(
    private actions$: Actions,
    private store$: Store<CoreFeatureState>,
  ) {
  }
}
