import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControlState } from 'ngrx-forms';
import { CoreFeatureState, getConfigTaxRatesValuesState } from '../../../core/ngrx/reducers/core.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-form-taxrate-selector',
  templateUrl: './form-taxrate-selector.component.html',
  styleUrls: ['./form-taxrate-selector.component.scss'],
})
export class FormTaxrateSelectorComponent {
  @Input() ngrxSelectedId: FormControlState<string>;
  @Input() placeholderI18nKey = 'taxRate';
  @Input() flex: string;
  @Input() configName: string;
  @Input() required: boolean;

  taxrates$ = this.store$.select(getConfigTaxRatesValuesState);

  constructor(private store$: Store<CoreFeatureState>) {
  }
}
