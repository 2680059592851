<form
  (submit)="saveForm()"
  [ngrxFormState]="form$ | async"
>
  <lib-common-form-flex-container>
    <div
      class="text-center"
      fxFlex="1 1 auto"
    >
      <mat-card>
        <mat-card-title>
          <span *ngIf="!(getFormControlValue$('id') | async)">{{ 'newCompany' | i18n | async }}</span>
          <span *ngIf="!!(getFormControlValue$('id') | async)">{{ 'editCompany' | i18n | async }}</span>
        </mat-card-title>
        <mat-card-content>
          <div
            fxLayout="row wrap"
            fxLayoutAlign="space-between"
            fxLayoutGap="10px grid"
          >
            <div
              fxFlex.gt-md="100%"
              fxFlex.lt-lg="100%"
            >
              <mat-checkbox
                [ngrxFormControlState]="getFormControl$('active') | async"
                required
              >aktiv
              </mat-checkbox>
              <mat-icon
                class="svgInfo"
                svgIcon="innogy:info"
                title="Mit Deaktivierung können die Endnutzer keine Vorgänge für diese Gesellschaft mehr erstellen.
Bestehende Vorgänge können nur von Admins noch bearbeitet und von Endnutzern nur eingesehen werden."
              ></mat-icon>
            </div>
            <mat-form-field
              fxFlex.gt-md="24%"
              fxFlex.lt-lg="100%"
            >
              <input
                [ngrxFormControlState]="getFormControl$('name') | async"
                matInput
                required
                maxlength="300"
                [placeholder]="'name' | i18n | async"
              >
            </mat-form-field>
            <mat-form-field
              fxFlex.gt-md="24%"
              fxFlex.lt-lg="100%"
            >
              <input
                [ngrxFormControlState]="getFormControl$('shortName') | async"
                matInput
                required
                maxlength="20"
                [placeholder]="'shortName' | i18n | async"
              >
            </mat-form-field>
            <mat-form-field
              fxFlex.gt-md="24%"
              fxFlex.lt-lg="100%"
            >
              <input
                [ngrxFormControlState]="getFormControl$('companyId') | async"
                matInput
                required
                maxlength="5"
                [placeholder]="'companyId' | i18n | async"
              >
            </mat-form-field>
            <mat-form-field
              fxFlex.gt-md="24%"
              fxFlex.lt-lg="100%"
            >
              <input
                [ngrxFormControlState]="getFormControl$('backendClient') | async"
                matInput
                required
                maxlength="1024"
                [placeholder]="'backendClient' | i18n | async"
              >
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </lib-common-form-flex-container>

  <lib-common-form-flex-container>
    <div
      class="text-center"
      fxFlex="1 1 auto"
    >
      <mat-card>
        <mat-card-title>{{ 'address' | i18n | async }}</mat-card-title>
        <mat-card-content>
          <div
            fxLayout="row wrap"
            fxLayoutAlign="space-between"
            fxLayoutGap="10px grid"
          >
            <mat-form-field
              fxFlex.gt-md="30%"
              fxFlex.lt-lg="100%"
            >
              <input
                [ngrxFormControlState]="getFormControl$('street') | async"
                matInput
                [placeholder]="'streetNumber' | i18n | async"
                maxlength="250"
              >
            </mat-form-field>
            <mat-form-field
              fxFlex.gt-md="30%"
              fxFlex.lt-lg="100%"
            >
              <input
                [ngrxFormControlState]="getFormControl$('postCode') | async"
                matInput
                [placeholder]="'plz' | i18n | async"
              >
            </mat-form-field>
            <mat-form-field
              fxFlex.gt-md="30%"
              fxFlex.lt-lg="100%"
            >
              <input
                [ngrxFormControlState]="getFormControl$('location') | async"
                matInput
                [placeholder]="'place' | i18n | async"
              >
            </mat-form-field>
          </div>

          <div
            fxLayout="row wrap"
            fxLayoutAlign="space-between"
            fxLayoutGap="10px grid"
          >
            <mat-form-field
              fxFlex.gt-md="30%"
              fxFlex.lt-lg="100%"
            >
              <input
                [ngrxFormControlState]="getFormControl$('boxNumber') | async"
                matInput
                [placeholder]="'boxNumber' | i18n | async"
                maxlength="250"
              >
            </mat-form-field>
            <mat-form-field
              fxFlex.gt-md="30%"
              fxFlex.lt-lg="100%"
            >
              <input
                [ngrxFormControlState]="getFormControl$('boxNumberLocation') | async"
                matInput
                [placeholder]="'boxNumberLocation' | i18n | async"
              >
            </mat-form-field>

            <div
              fxFlex.gt-md="30%"
              fxFlex.lt-lg="100%"
            >
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </lib-common-form-flex-container>

  <lib-common-form-flex-container>
    <div
      class="text-center"
      fxFlex="1 1 auto"
    >
      <mat-card>
        <mat-card-title>{{ 'salesInfo' | i18n | async }}</mat-card-title>
        <mat-card-content>
          <div
            fxLayout="row wrap"
            fxLayoutAlign="center"
            fxLayoutGap="10px grid"
          >
            <mat-form-field
              fxFlex.gt-md="24%"
              fxFlex.lt-lg="100%"
            >
              <input
                [ngrxFormControlState]="getFormControl$('salesOrg') | async"
                matInput
                [placeholder]="'salesOrg' | i18n | async"
                maxlength="250"
              >
            </mat-form-field>
            <mat-form-field
              fxFlex.gt-md="24%"
              fxFlex.lt-lg="100%"
            >
              <input
                [ngrxFormControlState]="getFormControl$('distributionChannel') | async"
                matInput
                [placeholder]="'distributionChannel' | i18n | async"
                maxlength="250"
              >
            </mat-form-field>
            <mat-form-field
              fxFlex.gt-md="24%"
              fxFlex.lt-lg="100%"
            >
              <input
                [ngrxFormControlState]="getFormControl$('sector') | async"
                matInput
                [placeholder]="'sector' | i18n | async"
              >
            </mat-form-field>
            <mat-form-field
              fxFlex.gt-md="24%"
              fxFlex.lt-lg="100%"
            >
              <input
                [ngrxFormControlState]="getFormControl$('branchSalesOffice') | async"
                matInput
                [placeholder]="'branchSalesOffice' | i18n | async"
              >
            </mat-form-field>
            <mat-form-field
              fxFlex.gt-md="24%"
              fxFlex.lt-lg="100%"
            >
              <input
                [ngrxFormControlState]="getFormControl$('procurementSection') | async"
                matInput
                [placeholder]="'procurementSection' | i18n | async"
              >
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </lib-common-form-flex-container>

  <lib-common-form-flex-container>
    <div
      class="text-center"
      fxFlex="1 1 auto"
    >
      <mat-card class="image-card">
        <mat-card-title>{{ 'logo' | i18n | async }}</mat-card-title>
        <mat-card-content>
          <div>
            <div
              class="logo"
              *ngIf="getFormControlValue$('logo') | async"
            >
              <img
                [src]="getFormControlValue$('logo') | async"
                alt="logo preview"
                class="image-preview"
              >
            </div>
            <div>
              <lib-common-file-upload
                [acceptedType]="'image/*'"
                [asString]="true"
                [maxFileCount]="1"
                [ngrxFormControlState]="getFormControl$('logo') | async"
              ></lib-common-file-upload>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div
      class="text-center"
      fxFlex="1 1 auto"
    >
      <mat-card class="image-card">
        <mat-card-title>{{ 'certificate' | i18n | async }}</mat-card-title>
        <mat-card-content>
          <div>
            <div
              class="logo"
              *ngIf="getFormControlValue$('certificate') | async"
            >
              <img
                [src]="getFormControlValue$('certificate') | async"
                alt="certificate preview"
                class="image-preview"
              >
            </div>
            <div>
              <lib-common-file-upload
                [acceptedType]="'image/*'"
                [asString]="true"
                [maxFileCount]="1"
                [ngrxFormControlState]="getFormControl$('certificate') | async"
              ></lib-common-file-upload>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div
      class="text-center"
      fxFlex="1 1 auto"
    >
      <mat-card class="image-card">
        <mat-card-title>{{ 'agb' | i18n | async }}</mat-card-title>
        <mat-card-content>
          <div>
            <div
              class="logo"
              *ngIf="getFormControlValue$('agb') | async"
            >
              <a
                role="button"
                href="javascript: void(0);"
                (click)="download($event)"
              >{{'downloadAgb' | i18n | async}}</a>
            </div>
            <div>
              <lib-common-file-upload
                [acceptedType]="'application/pdf'"
                [asString]="true"
                [maxFileCount]="1"
                [ngrxFormControlState]="getFormControl$('agb') | async"
              ></lib-common-file-upload>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </lib-common-form-flex-container>
  <lib-common-form-flex-container>
    <div fxFlex="1 1 auto">
      <mat-card>
        <mat-card-title class="text-center">Footer</mat-card-title>
        <mat-card-content>
          <div fxLayout="row wrap">
            <div fxFlex="1 0 auto">
              <lib-common-angular-editor
                [controlState]="getFormControl$('footer') | async"
              ></lib-common-angular-editor>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </lib-common-form-flex-container>

  <lib-common-form-flex-container>
    <div fxFlexAlign.lg="right">
      <button
        type="submit"
        color="primary"
        mat-raised-button
      >
        <mat-icon
          class="svg-icon-floppy"
          svgIcon="innogy:floppy"
        ></mat-icon>
        {{ 'save' | i18n | async }}</button>
    </div>
  </lib-common-form-flex-container>
</form>
