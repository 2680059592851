import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { WebcamImage } from 'ngx-webcam';
import { Subject } from 'rxjs';
import { ApiErrorAction } from '../../ngrx/actions/core.actions';
import { ocrActions } from '../../ngrx/actions/ocr.actions';
import { CoreFeatureState } from '../../ngrx/reducers/core.store';

@Component({
  selector: 'lib-common-route-ocr',
  templateUrl: './route-ocr.component.html',
  styleUrls: ['./route-ocr.component.scss'],
})
export class RouteOcrComponent implements OnInit {
  trigger: Subject<void> = new Subject<void>();
  webcamImage: WebcamImage = null;

  constructor(
    private store$: Store<CoreFeatureState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  takePhoto() {
    this.trigger.next();
  }

  handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
  }

  retakePhoto(): void {
    this.webcamImage = null;
  }

  camError(error): void {
    this.store$.dispatch(new ApiErrorAction(error, 'ocrCamError'));
    this.router.navigate(['/forms', this.activatedRoute.snapshot.params.formsCategory]);
  }

  scan(): void {
    this.store$.dispatch(ocrActions.OcrScanFileAction(
      {
        formIdentifier: this.activatedRoute.snapshot.params.formsIdentifier,
        file: this.webcamImage.imageAsBase64,
        selectedCompanyShortName: this.activatedRoute.snapshot.params.companyShortName,
      }));
  }
}
