<div class="grid-container">
  <lib-common-form-flex-container>
    <div
      *ngFor="let company of companies$ | async"
      class="text-center"
      fxFlex="0 1 400px"
    >
      <mat-card>
        <mat-card-header>
          <img
            *ngIf="company.logo"
            [src]="company.logo"
            alt="logo"
            mat-card-avatar
          >
          <mat-card-title>
            <a [routerLink]="['/config/company/', company.id]">{{ company.name }}</a>
          </mat-card-title>
          <mat-card-subtitle>
            {{ company.shortName}} - {{company.companyId }}
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
        </mat-card-content>
        <mat-card-actions>
          <button
            type="button"
            [routerLink]="['/config/company/', company.id]"
            mat-button
          >
            <mat-icon
              class="svgIcon-pen"
              svgIcon="innogy:pen"
            ></mat-icon>
            {{ 'edit' | i18n | async }}
          </button>

          <button
            type="button"
            (click)="openPrompt(company)"
            mat-button
          >
            <mat-icon>delete</mat-icon>
            {{ 'delete' | i18n | async }}
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </lib-common-form-flex-container>
  <div fxLayoutAlign="center center">
    <button
      type="button"
      [routerLink]="['/config/company/']"
      color="accent"
      mat-fab
    >
      <mat-icon svgIcon="innogy:plus"></mat-icon>
    </button>
  </div>
</div>
