import { EasyFormB2ConfigItems } from '../../form-b2/models/form-b2-config.model';

export class EasyFormB4ConfigItems extends EasyFormB2ConfigItems {

  constructor() {
    super();
    delete this.date;
    delete this.location;
  }
}
