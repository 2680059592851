import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { OcrPromptComponent } from '../../../core-lib/components/ocr-prompt/ocr-prompt.component';
import { QueryPromptComponent } from '../../../core-lib/components/query-prompt/query-prompt.component';
import {
  TemplateSelectorDialogComponent,
  TemplateSelectorDialogData,
} from '../../../core-lib/components/template-selector-dialog/template-selector-dialog.component';
import { FormCategoryElementModel } from '../../../core-lib/models/form-category-element.model';
import { FormCategoryTreeElementModel, FormCategoryTreeModel } from '../../../core-lib/models/form-category-tree.model';
import { FormDtoModel } from '../../../core-lib/models/form-dto.model';
import { LinkDtoModel } from '../../../core-lib/models/link-dto.model';
import { OcrPromptReturnModel } from '../../../core-lib/models/ocr-prompt-return.model';
import { OcrPromptModel } from '../../../core-lib/models/ocr-prompt.model';
import { ProposalHeadModel } from '../../../core-lib/models/proposal-head.model';
import { QueryPromptData } from '../../../core-lib/models/query-prompt-data.model';
import { RouteInfoService } from '../../../core-lib/services/route-info.service';
import { objectLength } from '../../../core-lib/utils/object-helper';
import { getSessionIsLoggedIn, getSessionUserRoles } from '../../../session/reducers/session.reducer';
import { FavoriteActionAdd, FavoriteActionDelete, FavoriteActionGet } from '../../ngrx/actions/favorite.actions';
import { ocrActions } from '../../ngrx/actions/ocr.actions';
import { TemplateActionAdd, TemplateActionDelete, TemplateActionGet } from '../../ngrx/actions/template.action';
import { FavoriteState, getFavoriteArrayState } from '../../ngrx/reducers/core-favorite.store';
import { getFormCategoryCategoriesState } from '../../ngrx/reducers/core-form-category.store';
import { getTemplatesStateByFormIdentifier, TemplateState } from '../../ngrx/reducers/core-template-store';
import { CoreFeatureState, getI18nSelectedLanguage } from '../../ngrx/reducers/core.store';

export function sanitizeNamesToLabels(name) {
  const re = / /gi;
  const ba = /\//gi;
  return name.replace(re, '').replace(ba, '');
}

class MatchOptions<T> {
  exactMatch: boolean;
  prop: keyof T;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-route-auszahlungen',
  templateUrl: './route-forms.component.html',
  styleUrls: ['./route-forms.component.scss'],
})
export class RouteFormsComponent implements OnInit, OnDestroy {
  selectedLanguage$ = this.store$.select(getI18nSelectedLanguage);
  selectedLanguage: string;

  destroy$ = new EventEmitter();

  get searchQuery(): string {
    return this._searchQuery;
  }

  set searchQuery(value: string) {
    this.searchQuery$.next(value);
    this._searchQuery = value;
  }

  constructor(
    protected store$: Store<CoreFeatureState>,
    protected favoriteStore$: Store<FavoriteState>,
    protected activatedRoute: ActivatedRoute,
    protected templateStore$: Store<TemplateState>,
    protected routeInfoService: RouteInfoService,
    protected dialog: MatDialog,
    protected router: Router,
  ) {
  }

  private _searchQuery: string;

  showSearch = false;
  searchQuery$ = new BehaviorSubject<string>('');
  favorites$ = this.favoriteStore$.select(getFavoriteArrayState);
  formCategoryCategories$ = this.store$.select(getFormCategoryCategoriesState);
  formsCategoryName = this.routeInfoService.getParam('formsCategory');
  componentDestroyed$ = new Subject<void>();

  childs$ = combineLatest([
    this.formCategoryCategories$,
    this.favorites$,
    this.searchQuery$,
  ]).pipe(
    map(([categories, favs, searchQuery]) => ({
      categories,
      favs,
      searchQuery,
    })),
    filter(({categories}) => !!(
      categories && categories[this.formsCategoryName]
    )),
    map(({categories, favs, searchQuery}) => ({
      categories: this.deepFilter(categories, searchQuery),
      favs,
      searchQuery,
    })),
  );

  childForms$ = this.childs$.pipe(
    map(({categories, favs}) => {
      return this.markAsFavorite(categories?.[this.formsCategoryName]?.childForms, favs);
    }),
  );

  childLinks$ = this.childs$.pipe(
    map(({categories}) => {
      return categories?.[this.formsCategoryName]?.childrenLinks;
    }),
  );

  childTrees$ = this.childs$.pipe(
    map(({categories, favs}) => {
      return Object.values(this.checkChildren(categories?.[this.formsCategoryName]?.childTrees, favs) || {});
    }),
  );

  showInfoHintFn$ = this.store$.select(getI18nSelectedLanguage).pipe(
    map((currentLang) => {
        return (category: FormCategoryTreeElementModel) => !!(category.categoryInfos.find(element => element.language === currentLang));
      },
    ),
  );

  userRoles$ = this.store$.select(getSessionUserRoles);

  isMatch<T>(searchTerm: string, propName: keyof T | (MatchOptions<T> | keyof T)[]): ((T) => boolean) {
    const propNames = Array.isArray(propName) ? propName : [propName];
    return (c) => searchTerm
      .split(' ')
      .reduce((ret, searchAtom) => (
        ret || propNames.reduce((curr, p) => {
          let hasMatch;
          if (typeof p === 'object' && 'prop' in p) {
            if (p.exactMatch) {
              hasMatch = c[p.prop] === searchAtom;
            } else {
              hasMatch = c[p.prop].includes(searchAtom);
            }
          } else {
            hasMatch = c[p].includes(searchAtom);
          }
          return (curr || hasMatch);
        }, false)
      ), false);
  }

  deepFilter(element: FormCategoryTreeModel, searchTerm: string) {
    if (!element) {
      return;
    }
    if (!searchTerm) {
      return element;
    }
    return Object.entries(element).reduce((previousValue, [currentKey, currentValue]) => {
      const childTrees = this.deepFilter(currentValue.childTrees, searchTerm);
      const childForms = currentValue.childForms.filter(this.isMatch<FormCategoryElementModel>(
        searchTerm,
        ['name', {exactMatch: true, prop: 'identifier'}],
      ));
      const childrenLinks = currentValue.childrenLinks.filter(this.isMatch<LinkDtoModel>(searchTerm, 'displayName'));
      const isKey = this.isMatch<FormCategoryTreeElementModel>(searchTerm, ['name', 'displayName'])(currentValue);
      if (objectLength(childTrees) === 0 && childForms.length === 0 && childrenLinks.length === 0 && !isKey) {
        return previousValue;
      }
      return ({
        ...previousValue,
        [currentKey]: {
          ...currentValue,
          childTrees,
          childForms,
          childrenLinks,
        },
      });
    }, {});
  }

  getRouterLink = (formId: string) => ['/forms', this.activatedRoute.snapshot.params.formsCategory, formId, 'new'];

  markAsFavorite(forms: FormCategoryElementModel[] = [], favorites: FormDtoModel[] = []): FormCategoryElementModel[] {
    return forms
      .map((form) => favorites?.find(fav => fav.id === form.id) ? {...form, isFavorite: true} : form)
      .map((form) => ({...form, hasTemplateSupport: true}));
  }

  checkChildren(treeNodes: FormCategoryTreeModel, favorites: FormDtoModel[]): FormCategoryTreeModel {
    if (!treeNodes || !favorites) {
      return undefined;
    }

    const checkedNodes: FormCategoryTreeModel = {};
    for (const [key, node] of Object.entries(treeNodes)) {
      const checkedNode: FormCategoryTreeElementModel = {...node};
      if (node.childForms) {
        checkedNode.childForms = this.markAsFavorite(node.childForms, favorites);
      }

      if (node.childTrees) {
        checkedNode.childTrees = this.checkChildren(node.childTrees, favorites);
      }

      checkedNodes[key] = checkedNode;
    }

    return checkedNodes;
  }


  openInfoDialog($event: Event, tree: FormCategoryTreeElementModel): void {
    const categoryInfo = tree.categoryInfos.find(value => value.language === this.selectedLanguage);

    $event.stopPropagation();
    this.dialog.open(QueryPromptComponent, {
      data: <QueryPromptData>{
        title: tree.name,
        text: categoryInfo.infoText,
        declineLabel: 'back',
        hideAcceptLabel: true,
      },
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  ngOnInit(): void {
    // ToDo: durch Effekt ersetzen
    this.favoriteStore$.select(getSessionIsLoggedIn).pipe(
      takeUntil(this.componentDestroyed$),
      filter(isLoggedIn => isLoggedIn),
    ).subscribe(() => {
      this.favoriteStore$.dispatch(new FavoriteActionGet());
      this.templateStore$.dispatch(new TemplateActionGet());
    });

    this.selectedLanguage$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(lang => {
      this.selectedLanguage = lang;
    });
  }

  addFavorite(form: FormCategoryElementModel) {
    this.favoriteStore$.dispatch(new FavoriteActionAdd(form.id));
  }

  addTemplate(form: FormCategoryElementModel) {
    this.templateStore$.dispatch(new TemplateActionAdd(form.id));
  }

  deleteFavorite(form: FormCategoryElementModel) {
    this.favoriteStore$.dispatch(new FavoriteActionDelete(form.identifier));
  }

  deleteTemplate(form: FormCategoryElementModel, templateName: ProposalHeadModel) {
    this.templateStore$.dispatch(new TemplateActionDelete(form.identifier, templateName.templateName));
  }

  openOcrDialog(data: OcrPromptModel) {
    const dialogRef = this.dialog.open<OcrPromptComponent, OcrPromptModel, OcrPromptReturnModel>(OcrPromptComponent, {
      data
    });

    return dialogRef.afterClosed().pipe(
      filter(result => result?.event === 'scanned'),
    );
  }

  ocrScan(form: FormCategoryElementModel): void {
    this.openOcrDialog({
      formIdentifier: form.identifier,
      enableUploadButton: true,
    }).subscribe(result => {
      this.store$.dispatch(ocrActions.OcrScanFileAction({
        formIdentifier: form.identifier,
        file: result.file,
        selectedCompanyShortName: result.companyShortName,
      }));
    });
  }

  getOcrCamRouterLink = (companyShortName: string, formId: string) => ([
    '/ocr',
    this.activatedRoute.snapshot.params.formsCategory,
    companyShortName,
    formId
  ]);

  ocrCam(form: FormCategoryElementModel) {
    this.openOcrDialog({
      formIdentifier: form.identifier,
      enableUploadButton: false,
    }).subscribe((result) => {
      this.router.navigate(this.getOcrCamRouterLink(result.companyShortName, form.identifier));
    });
  }

  openTemplateDialog(form: FormCategoryElementModel) {
    this.dialog.open(TemplateSelectorDialogComponent, {
      data: <TemplateSelectorDialogData>{
        templates$: this.store$.select(getTemplatesStateByFormIdentifier, {identifier: form.identifier}),
      },
    });
  }
}
