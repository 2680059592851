<div>
  <section>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between"
      fxLayoutGap="10px grid"
    >
      <mat-form-field
        fxFlex.gt-md="49%"
        fxFlex.lt-lg="100%">
        <input [ngrxFormControlState]="key" matInput placeholder="key">
      </mat-form-field>
      <mat-form-field
        fxFlex.gt-md="50%"
        fxFlex.lt-lg="100%">
        <input [ngrxFormControlState]="value" matInput placeholder="value (%)" type="number">
      </mat-form-field>
    </div>
  </section>

  <lib-common-input-lang-string
    (undoLangClick)="langLabelReset.emit($event)"
    *ngIf="langLabels"
    [strings]="langLabels"
    [undoVisible]="langLabelReset && langLabelReset.observers && langLabelReset.observers.length > 0"
  ></lib-common-input-lang-string>

  <button type="button" (click)="openPrompt()" mat-button>
    <mat-icon>delete</mat-icon>
    {{ 'remove' | i18n | async }}
  </button>
</div>
