import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-page-margin',
  templateUrl: './page-margin.component.html',
  styleUrls: ['./page-margin.component.scss'],
})
export class PageMarginComponent {
  @Input() topMargin = true;
  @Input() siedeMargin = true;
  @Input() contentSizePercent = 80;
  getSideSizePercent = () => 100 - 2 * this.contentSizePercent;
}
