import { ActivatedRouteSnapshot } from '@angular/router';
import { RouterReducerState, SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import { ifDef, ifDefined } from '../../../core-lib/utils/reducer-utils';

export { routerReducer } from '@ngrx/router-store';

export const getRouterStore = (rootState): RouterReducerState => ifDefined(rootState, (s) => s.router);
export const getRouterStateState = createSelector(getRouterStore, ifDef((s): SerializedRouterStateSnapshot => s.state));
export const getRouterUrl = createSelector(getRouterStateState, ifDef(s => s.url));
export const getRouterRootState = createSelector(getRouterStateState, ifDef((s): ActivatedRouteSnapshot => s.root.firstChild));
export const getRouterFirstChild = createSelector(getRouterRootState, ifDef((root) => root.firstChild));
export const getRouterParams = createSelector(getRouterFirstChild, ifDef((firstChild) => firstChild.params));
export const getRouterFirstChild2 = createSelector(getRouterFirstChild, ifDef((firstChild) => firstChild.firstChild));
export const getRouterParams2 = createSelector(getRouterFirstChild2, ifDef((firstChild) => firstChild.params));
export const getRouterParamByName = (name) => createSelector(getRouterFirstChild, ifDef((firstChild) => firstChild.params[name]));
export const getRouterData = createSelector(getRouterFirstChild, ifDef((firstChild) => firstChild.data, {}));
export const getRouterNavDisplay = createSelector(
  getRouterStateState,
  (rs) => {
    if (!rs?.root) {
      return undefined;
    }
    let child: any = {
      firstChild: rs.root,
    };
    const navDisplay = [];
    do {
      child = child.firstChild;
      if (child) {
        navDisplay.push(...(child.data.navDisplay ? child.data.navDisplay : []));
      }
    } while (child && child.children && child.children.length > 0);
    return navDisplay;
  },
);
