<ng-template #dialogTemplate>
  <div (keyup.control.enter)="close(eventTypes.ACCEPTED)" (keyup.escape)="close(eventTypes.DECLINED)">
    <h1 mat-dialog-title class="search-modal">{{ title |i18n|async }}</h1>
    <div mat-dialog-content class="dialog-modal">
      <ng-content></ng-content>
    </div>
    <div mat-dialog-actions class="actions" [align]="alignActions">
      <button
        type="button"
        mat-raised-button
        (click)="close(eventTypes.DECLINED)"
      >{{ declineLabel |i18n|async }}</button>
      <button
        *ngIf="showAcceptLabel"
        type="button"
        mat-raised-button
        color="primary"
        (click)="close(eventTypes.ACCEPTED)"
      >{{
        acceptLabel |i18n|async }}</button>
    </div>
  </div>
</ng-template>
