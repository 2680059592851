import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormArrayState, FormGroupState } from 'ngrx-forms';
import { RouteConfigFormPropertyComponent } from '../../../core/components/route-config-form-property/route-config-form-property.component';
import { CoreFeatureState } from '../../../core/ngrx/reducers/core.store';
import { ConfigItemArrangerConfigurationDtoModel } from '../../models/config-item-arranger-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../models/config-item-arranger-list-configuration-dto.model';
import { SetValueTraceableAction } from '../../models/set-value-traceable-action';
import { InputPropertyTypeComponent } from '../input-property-type/input-property-type.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-input-property-type-arranger-list',
  templateUrl: './input-property-type-arranger-list.component.html',
  styleUrls: ['./input-property-type-arranger-list.component.scss'],
})
export class InputPropertyTypeArrangerListComponent extends InputPropertyTypeComponent {

  typeName = ConfigItemArrangerListConfigurationDtoModel.TYPENAME;

  @Input() attributeName: string;
  @Input() ngrxValues: FormArrayState<ConfigItemArrangerConfigurationDtoModel>;
  @Output() addClick = new EventEmitter<FormArrayState<ConfigItemArrangerConfigurationDtoModel>>();
  @Output() removeClick = new EventEmitter<{
    array: FormArrayState<ConfigItemArrangerConfigurationDtoModel>,
    index: number,
  }>();

  getId = (index: number, item: FormGroupState<ConfigItemArrangerConfigurationDtoModel>) => item.value.$$ArrayId;

  constructor(store$: Store<CoreFeatureState>, util: RouteConfigFormPropertyComponent) {
    super(store$, util);
  }

  internalReloadValues(lastConfigItemValue) {
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxValues.id, lastConfigItemValue.values));
  }

}
