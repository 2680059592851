import { EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { FormDtoModel } from '../../../core-lib/models/form-dto.model';


export interface FavoriteState extends EntityState<FormDtoModel> {
  initial: boolean;
}

export const getCoreFavoritesState = createSelector(
  (s) => s,
  (s: any): FavoriteState => s.core.favorites,
);

export const getFavoriteArrayState = createSelector(
  getCoreFavoritesState,
  (s) => Object.values(s.entities),
);
export const getFavoriteInitialState = createSelector(
  getCoreFavoritesState,
  (s) => s.initial,
);
