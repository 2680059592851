<lib-common-input-property-wrapper
  [header]="attributeName"
  icon="note_add"
  [typeNameI18n]="typeName"
  [readonly]="readonly"
  [ngrxOverwrite]="ngrxOverwrite"
  (changeReadonly)="onChangeReadonly($event)"
>
  <div>
    <section
      *ngIf="ngrxMin"
    >
      <h2 class="mat-h2">Minimum / Maximum</h2>
      <mat-form-field>
        <input
          matInput
          placeholder="Minimum"
          [ngrxFormControlState]="ngrxMin"
          type="number"
        >
      </mat-form-field>
    </section>

    <section
      *ngIf="ngrxMax"
    >
      <mat-form-field>
        <input
          matInput
          placeholder="Maximum"
          [ngrxFormControlState]="ngrxMax"
          type="number"
        >
      </mat-form-field>
    </section>

    <section
      *ngIf="ngrxMandatory || ngrxEnabled || ngrxVisible || ngrxChangeableByInspector || ngrxChangeableByApprover"
    >
      <lib-common-input-property-defaults
        [ngrxMandatory]="ngrxMandatory"
        [ngrxEnabled]="ngrxEnabled"
        [ngrxVisible]="ngrxVisible"
        [ngrxChangeableByInspector]="ngrxChangeableByInspector"
        [ngrxChangeableByApprover]="ngrxChangeableByApprover"
      ></lib-common-input-property-defaults>
    </section>

    <section>
      <mat-form-field *ngIf="ngrxAvailableCategories != null">
        <mat-label>{{'availableCategories' | i18n |async}}</mat-label>
        <mat-select
          [ngrxFormControlState]="ngrxAvailableCategories"
          multiple
        >
          <mat-option
            *ngFor="let category of possibleCategories"
            [value]="category"
          >{{category | i18n |async}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <section>
      <mat-form-field *ngIf="ngrxRequiredCategories != null">
        <mat-label>{{'requiredCategories' | i18n |async}}</mat-label>
        <mat-select
          [ngrxFormControlState]="ngrxRequiredCategories"
          multiple
        >
          <mat-option
            *ngFor="let category of possibleCategories"
            [value]="category"
          >{{category | i18n |async}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </section>
    <section>
      <mat-form-field *ngIf="ngrxUploadType != null">
        <mat-label>{{'uploadType' | i18n |async}}</mat-label>
        <mat-select
          [ngrxFormControlState]="ngrxUploadType"
        >
          <mat-option
            *ngFor="let uploadType of uploadTypes"
            [value]="uploadType"
          >{{uploadType | i18n |async}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <lib-common-input-property-lang-wrapper
      [translations]="translations"
      (labelResetClick)="labelResetClick.emit($event)"
      [undoVisible]="labelResetClick && labelResetClick.observers && labelResetClick.observers.length > 0"
    ></lib-common-input-property-lang-wrapper>
  </div>
</lib-common-input-property-wrapper>
