import { box, Boxed } from 'ngrx-forms';

export class ConfigItemConfigurationCategoryModel {
  displayName: string;
  id: string;
}

export class ConfigItemConfigurationDtoModel {
  '@class': string;
  $$typeName: string;
  $$category?: ConfigItemConfigurationCategoryModel;
  id?: string = undefined;
  translations: ConfigItemTranslationsDtoModel = new ConfigItemTranslationsDtoModel();
  value?: string = undefined; // FIXME: Muss entfernt werden, da ConfigItems in der Regel kein Value haben
  predefinedValue?: string = undefined; // FIXME: Muss entfernt werden, da ConfigItems in der Regel kein Value haben
  min?: number = undefined; // FIXME: Muss entfernt werden, da ConfigItems in der Regel kein Value haben
  max?: number = undefined; // FIXME: Muss entfernt werden, da ConfigItems in der Regel kein Value haben
  enabled ? = true;
  mandatory ? = true;
  visible ? = true;
  changeableByInspector ? = false;
  changeableByApprover ? = false;
  overwrite?: boolean = undefined;
  // ToDo: vermutlich wird categories nur für file-upload genutzt und kann dort hin - AvG
  categories: Boxed<string[]> = box([]);

  constructor(
    public attributeName: string,
    public globalPropertyName?: string,
  ) {
    // set initial translation values to attributeName
    for (const x of Object.keys(this.translations)) {
      if (!this.translations[x]) {
        this.translations[x] = {
          text: attributeName,
        };
      } else {
        this.translations[x].text = attributeName;
      }
    }
  }

  withCategory?(category: ConfigItemConfigurationCategoryModel): this {
    this.$$category = category;
    return this;
  }
}

export class ConfigItemTranslationsDtoModel {
  [key: string]: {
    id?: string,
    lang?: string,
    text: string;
    key?: string,
  };

  constructor(defaultTranslation?: string) {
    this['de'] = {
      text: defaultTranslation || '',
    };
    this['en'] = {
      text: defaultTranslation || '',
    };
  }
}
