import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { getSessionUserAdminAreaIsVisible, getSessionUserIsCaterer } from '../../../session/reducers/session.reducer';
import { CoreFeatureState } from '../../ngrx/reducers/core.store';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'lib-common-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent {
  @Input() customerRelatedStuff: string;

  isAdmin$ = this.store$.select(getSessionUserAdminAreaIsVisible);
  isCaterer$ = this.store$.select(getSessionUserIsCaterer).pipe(
    map(isCaterer => isCaterer && this.appConfigService.config.enableCatering),
  );

  constructor(private store$: Store<CoreFeatureState>, private appConfigService: AppConfigService) {
  }
}
