import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { share } from 'rxjs/operators';

@Component({
  selector: 'lib-common-data-protection',
  templateUrl: './data-protection.component.html',
  styleUrls: ['./data-protection.component.scss'],
})
export class DataProtectionComponent {
  dataProtectionContent = this.http.get('/assets/dataprotection.html', { responseType: 'text'}).pipe(
    share(),
  );

  constructor(private http: HttpClient) {
  }

}
