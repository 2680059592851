import { backendClasses } from './backend-classes.model';
import { ConfigItemConfigurationDtoModel } from './config-item-configuration-dto.model';

export class ConfigItemCheckboxConfigurationDtoModel extends ConfigItemConfigurationDtoModel {
  static readonly CLASSNAME: string = backendClasses.checkboxConfiguration;
  static TYPENAME = 'typeCheckbox';
  '@class' = ConfigItemCheckboxConfigurationDtoModel.CLASSNAME;
  $$typeName = ConfigItemCheckboxConfigurationDtoModel.TYPENAME;
  preChecked: boolean = undefined;
}
