<div>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="center"
    fxLayoutGap="10px grid"
  >
    <div
      fxFlex.gt-md="48%"
      fxFlex.lt-lg="100%"
    >
      <div
        fxLayout="row wrap"
        fxLayoutAlign="center"
        fxLayoutGap="10px grid">
        <div
          fxFlex.gt-md="100%"
          fxFlex.lt-lg="100%"
        >
          <h3>{{ 'approverDeputies' | i18n | async }}</h3>
        </div>
        <div
          fxFlex="100%">
          <lib-common-user-list
            [asId]="true"
            [asIdPropertyName]="'deputyUserId'"
            [approverControls]="approverDeputies"
            [enableable]="true"
            [enablePropertyName]="'state'"
            (addClick)="addApproverDeputy.emit()"
            (removeClick)="removeApproverDeputy.emit($event)"
            [placeholder]="'approverDeputies' | i18n | async"
            [removable]="true"
          ></lib-common-user-list>
        </div>
      </div>
    </div>

    <div
      fxFlex.gt-md="48%"
      fxFlex.lt-lg="100%">
      <div
        fxLayout="row wrap"
        fxLayoutAlign="center"
        fxLayoutGap="10px grid">
        <div
          fxFlex.gt-md="100%"
          fxFlex.lt-lg="100%"
        >
          <h3>{{ 'editorDeputies' | i18n | async }}</h3>
        </div>
        <div
          fxFlex="100%">
          <lib-common-user-list
            [asId]="true"
            [asIdPropertyName]="'deputyUserId'"
            [approverControls]="editorDeputies"
            (addClick)="addEditorDeputy.emit()"
            (removeClick)="removeEditorDeputy.emit($event)"
            [removable]="true"
            [placeholder]="'editorDeputies' | i18n | async"
          ></lib-common-user-list>
        </div>
      </div>
    </div>
  </div>
</div>
