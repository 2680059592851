import { createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  Actions,
  disable,
  enable,
  FormGroupState,
  isNgrxFormsAction,
  setValue,
  StateUpdateFns,
  unbox,
  updateGroup
} from 'ngrx-forms';
import { ConfigDtoModel, ConfigurationItems } from '../../../core-lib/models/config-dto.model';
import { ConfigItemConfigurationDtoModel } from '../../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemDaterangeConfigurationDtoModel } from '../../../core-lib/models/config-item-daterange-configuration-dto.model';
import { getId } from '../../../core-lib/utils/get-id';
import { reduceForm } from '../../../core-lib/utils/reducer-utils';
import { FormConfigIdentityModel } from '../../models/form-config-identity.model';
import {
  CoreEasyFormsActions,
  CoreEasyFormsEnableConfigAction,
  CoreEasyFormsLoadFormConfigsByFormIdSuccessAction,
  CoreEasyFormsLoadFormConfigSuccessAction,
  CoreEasyFormsResetFormConfigAction,
} from '../actions/core-easy-forms.actions';
import { CoreFeatureState } from './core.store';
import { EasyFormConfigsAdditionalState, EasyFormConfigsState } from './easy-form-configs.store';

export function easyFormConfigsReducer(
  state = initialEasyFormConfigsState,
  action: CoreEasyFormsActions | Actions<any>,
): EasyFormConfigsState {
  if (isNgrxFormsAction(action)) {
    return reduceForm(state, 'selected', action);
  }

  switch (action.type) {
    case CoreEasyFormsLoadFormConfigsByFormIdSuccessAction.TYPE:
      return {
        ...state,
        ...easyFormConfigsAdapter.upsertMany(action.configs, state),
      };
    case CoreEasyFormsLoadFormConfigSuccessAction.TYPE:
      return {
        ...state,
        ...easyFormConfigsAdapter.upsertOne(action.config, state),
        selected: setValue(state.selected, action.config),
      };
    case CoreEasyFormsResetFormConfigAction.TYPE:
      return {
        ...state,
        ...easyFormConfigsAdapter.removeAll(state),
        selected: setValue(state.selected, {
          id: undefined,
          company: undefined,
          configurationItems: {},
          form: undefined,
          parent: undefined,
        }),
      };
    case CoreEasyFormsEnableConfigAction.TYPE:
      const updateFns: StateUpdateFns<ConfigurationItems> = {};
      const configurationItems = state.selected.controls.configurationItems as FormGroupState<ConfigurationItems>;
      const parent = unbox(state.selected.value.parent);

      // generate updateFns for each control
      for (const key of Object.keys(configurationItems.controls)) {
        const control = configurationItems.controls[key];
        let updateFn;
        if (control?.value == undefined) {
          continue;
        }
        if (!!parent && !control.value.overwrite) {
          updateFn = (c: FormGroupState<ConfigItemConfigurationDtoModel>) => {
            let currentControl = c;
            // disable whole control
            currentControl = disable(currentControl);
            // re-enable overwrite
            currentControl = updateGroup(currentControl, {
              overwrite: enable,
            });
            return currentControl;
          };
        } else {
          updateFn = enable;
        }
        updateFns[key] = updateFn;
      }

      const selected = updateGroup(state.selected, {
        configurationItems: updateGroup<ConfigurationItems>(updateFns),
      });
      return {
        ...state,
        selected,
      };
    default:
      return state;
  }
}

export const easyFormConfigsAdapter = createEntityAdapter<ConfigDtoModel<any>>({
  selectId: getId,
  sortComparer: false,
});
export const initialEasyFormConfigsState = easyFormConfigsAdapter
  .getInitialState<EasyFormConfigsAdditionalState>(new EasyFormConfigsAdditionalState());

const adapterSelectors = easyFormConfigsAdapter.getSelectors();

export const getEasyFormConfigsCoreFeatureState = createFeatureSelector<CoreFeatureState>('core');

export const getEasyFormConfigsFeatureState = createSelector(
  getEasyFormConfigsCoreFeatureState,
  (s) => s.easyFormConfigs,
);

export const getEasyFormConfigsArrayState = createSelector(
  getEasyFormConfigsFeatureState,
  adapterSelectors.selectAll,
);

export const getEasyFormConfigByIdsStateFn = createSelector(
  getEasyFormConfigsArrayState,
  (s) => (ids: Partial<FormConfigIdentityModel>): ConfigDtoModel<any> =>
    s.find((c) => c.form === ids.formId && c.company === ids.companyId),
);

export const getEasyFormConfigByCompanyIdStateFn = createSelector(
  getEasyFormConfigsArrayState,
  (s) => (id: string): ConfigDtoModel<any> =>
    s.find((c) => c.company === id),
);

export const getEasyFormConfigsSelectedStateFn = createSelector(
  getEasyFormConfigsFeatureState,
  (s) => s.selected,
);

export const getEasyFormConfigsSelectedItemsStateFn = createSelector(
  getEasyFormConfigsSelectedStateFn,
  (s) => s && s.controls.configurationItems,
);

export const getEasyFormConfigsSelectedItemStateFn = createSelector(
  getEasyFormConfigsSelectedItemsStateFn,
  (sFn, props) => sFn.controls[props.itemName] as FormGroupState<ConfigItemConfigurationDtoModel>,
);

export const getEasyFormDateRangeConfigsSelectedItemStateFn = createSelector(
  getEasyFormConfigsSelectedItemsStateFn,
  (sFn, props) => sFn.controls[props.itemName] as FormGroupState<ConfigItemDaterangeConfigurationDtoModel>,
);

export const getEasyFormConfigsSelectedItemPropStateFn = createSelector(
  getEasyFormConfigsSelectedItemStateFn,
  (s, props) => s && s.controls[props.propName],
);

export const getEasyFormConfigsSelectedValueState = createSelector(
  getEasyFormConfigsSelectedStateFn,
  (s) => s.value,
);

export const getEasyFormConfigsEntitiesState = createSelector(
  getEasyFormConfigsFeatureState,
  adapterSelectors.selectEntities,
);
