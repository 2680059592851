import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormControlState, FormGroupState } from 'ngrx-forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SetValueTraceableAction } from '../../../core-lib/models/set-value-traceable-action';
import { ConfigListModel } from '../../models/config-list.model';
import { CoreConfigTaxratesSave, DeleteConfigItemAction } from '../../ngrx/actions/core-config.actions';
import { initialConfigListValue, TAXRATES_FORM_ID } from '../../ngrx/reducers/config.reducer';

import { CoreFeatureState, getConfigTaxRatesControlsArrayState, getConfigTaxRatesState } from '../../ngrx/reducers/core.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-route-tax-rates',
  templateUrl: './route-config-tax-rates.component.html',
  styleUrls: ['./route-config-tax-rates.component.scss'],
})
export class RouteConfigTaxRatesComponent implements OnDestroy {
  componentDestroyed$ = new Subject<void>();
  currentTaxrates = undefined;

  taxRates$ = this.store$.select(getConfigTaxRatesControlsArrayState);

  taxRatetListForm$ = this.store$.select(getConfigTaxRatesState);
  formValid$ = this.taxRatetListForm$.pipe(
    select('isValid'),
  );

  constructor(private store$: Store<CoreFeatureState>) {
    this.taxRates$.pipe(
      takeUntil(this.componentDestroyed$),
    ).subscribe(taxrate => this.currentTaxrates = taxrate);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  getTaxRateControlId = (t: FormGroupState<ConfigListModel>) => t.id;

  resetLangLabel(taxrates, $event: FormControlState<string>) {
    this.store$.dispatch(new SetValueTraceableAction($event.id, taxrates.value.key));
  }

  addTaxRate() {
    if (this.currentTaxrates) {
      const newValue = [
        ...this.currentTaxrates.map(x => x.value),
        initialConfigListValue,
      ];
      this.store$.dispatch(new SetValueTraceableAction(TAXRATES_FORM_ID, newValue));
    }
  }

  removeTaxRate(taxrate) {
    const newValue = [
      ...this.currentTaxrates.filter(x => this.getTaxRateControlId(x) !== this.getTaxRateControlId(taxrate)).map(x => x.value),
    ];
    this.store$.dispatch(new SetValueTraceableAction(TAXRATES_FORM_ID, newValue));
    this.store$.dispatch(new DeleteConfigItemAction(taxrate.value.id));
  }

  saveTaxRates() {
    this.store$.dispatch(new CoreConfigTaxratesSave());
  }

}
