import { formatNumber } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { DashboardStateCountType } from '../../../core-lib/models/dashboard.model';
import { I18nPipe } from '../../../core-lib/pipes/i18n.pipe';

export type ValueFormatter = ((number) => string) | string;

@Component({
  selector: 'lib-common-circle-radar-chart',
  templateUrl: './circle-radar-chart.component.html',
  styleUrls: ['./circle-radar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleRadarChartComponent implements OnDestroy {
  destroy$ = new EventEmitter<void>();

  @Input() showChartTypeSelector: boolean | string[] = true;

  dataSource$ = new BehaviorSubject<DashboardStateCountType | Array<any>>({});

  @Input() set data(value: DashboardStateCountType | Array<any>) {
    this.dataSource$.next(value);
  }

  data$ = this.dataSource$.asObservable().pipe(
    takeUntil(this.destroy$),
    distinctUntilChanged(),
  );

  valueFormatterSource$ =
    new BehaviorSubject<ValueFormatter>((value) => value);

  /**
   * Input static function only. Do not create the function by another function. It leads to an endless loop.
   * @param value the function
   */
  @Input() set valueFormatter(value: ValueFormatter) {
    this.valueFormatterSource$.next(value);
  }

  valueFormatter$ = this.valueFormatterSource$.asObservable().pipe(
    takeUntil(this.destroy$),
    distinctUntilChanged(),
  );
  typeSource$ = new BehaviorSubject('pie');

  @Input() set type(value: string) {
    this.typeSource$.next(value);
  }

  type$ = this.typeSource$.asObservable().pipe(
    takeUntil(this.destroy$),
  );

  radarOptions = {
    radar: {
      axisLabel: {
        overflow: 'break',
      },
      name: {
        textStyle: {
          color: '#fff',
          backgroundColor: '#999',
          borderRadius: 3,
          padding: [3, 5],
        },
      },
      indicator: [
        {name: 'keine Vorgänge'},
      ],
    },
    series: [
      {
        name: 'Vorgänge',
        type: 'radar',
        // areaStyle: {normal: {}},
        data: [
          {
            value: [],
            name: 'Vorgänge',
          },
        ],
      },
    ],
  };

  pieOptions = {
    legend: {
      type: 'scroll',
      orient: 'vertical',
      right: 10,
      top: 20,
      bottom: 20,
    },
    series: [
      {
        name: 'Vorgänge',
        type: 'pie',
        radius: '65%',
        center: ['50%', '50%'],
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
    toolbox: {
      show: false,
    },
  };

  barOptions = {
    tooltip: {
      trigger: 'axis',
    },
    toolbox: {
      feature: {},
    },
    xAxis: {
      type: 'time',
      boundaryGap: true,
      axisTick: {
        alignWithLabel: true,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: function (value): string {
          return formatNumber(value, 'de-DE', '1.0-2');
        },
      },
    },
    dataZoom: [],
    series: [
      {
        name: 'Auszahlung',
        type: 'bar',
        data: [], // [[string, number]]
      },
    ],
  };

  options$ = combineLatest([
    this.data$.pipe(
      map((data) => Array.isArray(data) ? data : Object.entries(data).map(([name, value]) => ({
        name,
        value,
      }))),
      mergeMap((data) => forkJoin(
        [...data.map(d => this.i18n.transform(d.name).pipe(take(1)))],
      ).pipe(
        catchError((error) => {
          console.error(error);
          return of(error);
        }),
        map((translations: string[]) => data.map((dataEntry, i) => ({
          ...dataEntry,
          name: translations[i],
        }))),
      )),
    ),
    this.i18n.selectedLanguage$,
    this.type$,
    this.valueFormatter$,
  ]).pipe(
    map(([data, _, type, valueFormatter]) => {
      const valueFormatterFn = typeof valueFormatter === 'function' ? valueFormatter : () => valueFormatter;
      const formatter = typeof valueFormatter === 'function' ? this.getFormatter(valueFormatterFn) : valueFormatter;
      if (type === 'pie') {
        return this.buildPieChartOptions(data, valueFormatterFn);
      } else if (type === 'radar') {
        return this.buildRadarChartOptions(data, valueFormatterFn, formatter);
      } else if (type === 'bar') {
        return this.buildBarChartOptions(data, valueFormatterFn);
      } else {
        throw new Error('unknown chart type: ' + type);
      }
    }),
    catchError((error) => {
      console.error(error);
      return of(error);
    }),
  );

  constructor(protected i18n: I18nPipe) {
  }

  ngOnDestroy() {
    this.destroy$.emit();
    this.destroy$.complete();
  }

  getFormatter(formatterFn) {
    return (obj) => {
      return `${obj.seriesName} <br/>${obj.name}: ${formatterFn(obj.value)}`;
    };
  }

  shouldShowChartType(type: string) {
    if (Array.isArray(this.showChartTypeSelector)) {
      return this.showChartTypeSelector.includes(type) && this.showChartTypeSelector.length > 1;
    } else {
      return this.showChartTypeSelector;
    }
  }

  private buildBarChartOptions(data, valueFormatterFn: ((number) => string) | (() => ((number) => string) | string)) {
    return {
      ...this.barOptions,
      series: [
        {
          ...this.barOptions.series[0],
          data: data, // expecting [[time, value]]
          tooltip: {
            valueFormatter: valueFormatterFn,
          },
        },
      ],
    };
  }

  private buildRadarChartOptions(
    data,
    valueFormatterFn: ((number) => string) | (() => ((number) => string) | string),
    formatter: ((obj) => string) | string,
  ) {
    const maxAmount = data.reduce((prev, cur) => Math.max(prev, cur.value), 0);
    return {
      ...this.radarOptions,
      radar: {
        ...this.radarOptions.radar,
        indicator: data.map(({name}) => ({name, max: maxAmount})),
      },
      series: [
        {
          ...this.radarOptions.series[0],
          data: [
            {
              ...this.radarOptions.series[0].data[0],
              value: data.map(({value}) => value),
            },
          ],
          tooltip: {
            valueFormatter: valueFormatterFn,
          },
        },
      ],
      tooltip: {
        trigger: 'axis',
        formatter: formatter,
      },
    };
  }

  private buildPieChartOptions(data, valueFormatterFn: ((number) => string) | (() => ((number) => string) | string)) {
    return {
      ...this.pieOptions,
      series: [
        {
          ...this.pieOptions.series[0],
          data: data.map(({name, value}) => ({name: `${name} (${valueFormatterFn(value)})`, value})),
        },
      ],
    };
  }

}
