import { ConfigurationItems } from '../../../core-lib/models/config-dto.model';
import { ConfigItemAccountingConfigurationDtoModel } from '../../../core-lib/models/config-item-accounting-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../../core-lib/models/config-item-arranger-list-configuration-dto.model';
import { ConfigItemCheckboxConfigurationDtoModel } from '../../../core-lib/models/config-item-checkbox-configuration-dto.model';
import { ConfigItemConfigurationCategoryModel } from '../../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemDateConfigurationDtoModel } from '../../../core-lib/models/config-item-date-configuration-dto.model';
import { ConfigItemFileUploadConfigurationDtoModel } from '../../../core-lib/models/config-item-file-upload-configuration-dto.model';
import { ConfigItemLedgerAccountConfigurationDtoModel } from '../../../core-lib/models/config-item-ledger-account-configuration-dto.model';
import { ConfigItemListConfigurationDtoModel } from '../../../core-lib/models/config-item-list-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../../../core-lib/models/config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../../../core-lib/models/config-item-text-input-configuration-dto.model';
import { ConfigListDtoModel } from '../../../core-lib/models/config-list-dto.model';
import { EasyFormAF08Steps } from '../../../forms-lib/models/easy-form-af08-config.model';
import { EqualKeyNames } from '../../../forms-lib/models/equal-key-names';
import { PayeeDtoModel } from '../../all-forms/models/payee-dto.model';
import { FormAF09DtoModel } from './route-af09-dto.model';

export const EasyFormAF09Steps = {
  step1: <ConfigItemConfigurationCategoryModel>{
    id: 'step1',
    displayName: 'Gesellschaft',
  },
  step2: <ConfigItemConfigurationCategoryModel>{
    id: 'step2',
    displayName: 'Zahlungsempfänger',
  },
  step3: <ConfigItemConfigurationCategoryModel>{
    id: 'step3',
    displayName: 'Rechnungsangaben',
  },
};

// ToDo: Check ob dieses Config-Model auch via FormA1Step1,FormA1Step2,FormA1Step3 bzw FormA1State erzeugt werden kann.
export class EasyFormAF09ConfigItems extends ConfigurationItems implements EqualKeyNames<FormAF09DtoModel>, EqualKeyNames<PayeeDtoModel> {
  payeeNumber = new ConfigItemTextInputConfigurationDtoModel('payeeNumber').withCategory(EasyFormAF09Steps.step2);

  // PayeeDtoModel
  salutation = new ConfigItemTextInputConfigurationDtoModel('salutation').withCategory(EasyFormAF09Steps.step2);
  name = new ConfigItemTextInputConfigurationDtoModel('name').withCategory(EasyFormAF09Steps.step2);
  nameAddition = new ConfigItemTextInputConfigurationDtoModel('nameAddition').withCategory(EasyFormAF09Steps.step2);
  street = new ConfigItemTextInputConfigurationDtoModel('street').withCategory(EasyFormAF09Steps.step2);
  zipCode = new ConfigItemTextInputConfigurationDtoModel('zipCode').withCategory(EasyFormAF09Steps.step2);
  city = new ConfigItemTextInputConfigurationDtoModel('city').withCategory(EasyFormAF09Steps.step2);
  country = new ConfigItemTextInputConfigurationDtoModel('country').withCategory(EasyFormAF09Steps.step2);
  email = new ConfigItemTextInputConfigurationDtoModel('email').withCategory(EasyFormAF09Steps.step2);
  iban = new ConfigItemTextInputConfigurationDtoModel('iban').withCategory(EasyFormAF09Steps.step2);
  bic = new ConfigItemTextInputConfigurationDtoModel('bic').withCategory(EasyFormAF09Steps.step2);

  taxNumber = new ConfigItemTextInputConfigurationDtoModel('taxNumber').withCategory(EasyFormAF09Steps.step2);
  ustId = new ConfigItemTextInputConfigurationDtoModel('ustId').withCategory(EasyFormAF09Steps.step2);

  // Verwendungszweck
  useCase = new ConfigItemTextInputConfigurationDtoModel('useCase').withCategory(EasyFormAF09Steps.step3);
  description = new ConfigItemTextInputConfigurationDtoModel('description').withCategory(EasyFormAF09Steps.step3);
  groundNumber = new ConfigItemTextInputConfigurationDtoModel('groundNumber').withCategory(EasyFormAF09Steps.step3);

  // positions
  positions = new ConfigListDtoModel('positions').withCategory(EasyFormAF09Steps.step3);
  positionName = new ConfigItemTextInputConfigurationDtoModel('positionName').withCategory(EasyFormAF09Steps.step3);
  netCost = new ConfigItemNumberConfigurationDtoModel('netCost').withCategory(EasyFormAF09Steps.step3);
  taxRate = new ConfigItemListConfigurationDtoModel('taxRate', 'taxrates').withCategory(EasyFormAF09Steps.step3);
  grossCost = new ConfigItemNumberConfigurationDtoModel('grossCost').withCategory(EasyFormAF09Steps.step3);
  taxValue = new ConfigItemNumberConfigurationDtoModel('taxValue').withCategory(EasyFormAF09Steps.step3);
  account = new ConfigItemAccountingConfigurationDtoModel('account').withCategory(EasyFormAF09Steps.step3);
  ledgerAccount = new ConfigItemLedgerAccountConfigurationDtoModel('ledgerAccount').withCategory(EasyFormAF09Steps.step3);

  // recurringAccountingDto
  recurringAccounting = new ConfigItemCheckboxConfigurationDtoModel('recurringAccounting').withCategory(EasyFormAF09Steps.step3);
  billingInterval = new ConfigItemTextInputConfigurationDtoModel('billingInterval').withCategory(EasyFormAF09Steps.step3);
  billingDate = new ConfigItemTextInputConfigurationDtoModel('billingDate').withCategory(EasyFormAF09Steps.step3);
  billingUntil = new ConfigItemDateConfigurationDtoModel('billingUntil').withCategory(EasyFormAF09Steps.step3);

  // payout
  payout = new ConfigItemNumberConfigurationDtoModel('payout').withCategory(EasyFormAF09Steps.step3);

  // Genehmigungsstufen
  approver = new ConfigItemArrangerListConfigurationDtoModel('approver').withCategory(EasyFormAF09Steps.step3);
  inspector = new ConfigItemArrangerListConfigurationDtoModel('inspector').withCategory(EasyFormAF09Steps.step3);
  commentForApprover = new ConfigItemTextInputConfigurationDtoModel('commentForApprover').withCategory(EasyFormAF09Steps.step3);

  file = new ConfigItemFileUploadConfigurationDtoModel('file').withCategory(EasyFormAF09Steps.step3);
}
