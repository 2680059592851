import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControlState, FormGroupControls, FormGroupState } from 'ngrx-forms';
import { take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { RouteConfigFormPropertyComponent } from '../../../core/components/route-config-form-property/route-config-form-property.component';
import { CoreFeatureState } from '../../../core/ngrx/reducers/core.store';
import {
  getEasyFormConfigsEntitiesState,
  getEasyFormConfigsSelectedValueState,
} from '../../../core/ngrx/reducers/easy-form-configs.reducer';
import { ConfigItemTranslationsDtoModel } from '../../models/config-item-configuration-dto.model';
import { ConfigItemDaterangeConfigurationDtoModel } from '../../models/config-item-daterange-configuration-dto.model';
import { SetValueTraceableAction } from '../../models/set-value-traceable-action';
import { InputPropertyTypeComponent } from '../input-property-type/input-property-type.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-input-property-type-daterange',
  templateUrl: './input-property-type-daterange.component.html',
  styleUrls: ['./input-property-type-daterange.component.scss'],
})
export class InputPropertyTypeDaterangeComponent extends InputPropertyTypeComponent {

  typeName = ConfigItemDaterangeConfigurationDtoModel.TYPENAME;

  @Input() startDateTranslations: FormGroupState<ConfigItemTranslationsDtoModel>;
  @Input() startTimeTranslations: FormGroupState<ConfigItemTranslationsDtoModel>;
  @Input() endDateTranslations: FormGroupState<ConfigItemTranslationsDtoModel>;
  @Input() endTimeTranslations: FormGroupState<ConfigItemTranslationsDtoModel>;

  @Output() startDateTranslationsLabelResetClick = new EventEmitter<FormControlState<string>>();
  @Output() startTimeTranslationsLabelResetClick = new EventEmitter<FormControlState<string>>();
  @Output() endDateTranslationsLabelResetClick = new EventEmitter<FormControlState<string>>();
  @Output() endTimeTranslationsLabelResetClick = new EventEmitter<FormControlState<string>>();

  @Input() ngrxPreChecked: FormControlState<number>;
  @Input() ngrxStartDateVisible: FormControlState<boolean>;
  @Input() ngrxStartTimeVisible: FormControlState<boolean>;
  @Input() ngrxEndDateVisible: FormControlState<boolean>;
  @Input() ngrxEndTimeVisible: FormControlState<boolean>;
  @Input() ngrxTimeMin: FormControlState<string>;
  @Input() ngrxTimeMax: FormControlState<string>;

  // Definiert, wie weit die zwei DateTimes auseinander liegen dürfen (inklusive)
  @Input() ngrxMaxRangeLength: FormControlState<number>;
  @Input() ngrxMinRangeLength: FormControlState<number>;

  // Anzahl an Sekunden, die der Termin in der Zukunft liegen muss
  @Input() ngrxMinStartInFuture: FormControlState<number>;
  // Anzahl an Sekunden, die der Termin in der Zukunft liegen darf
  @Input() ngrxMaxStartInFuture: FormControlState<number>;
  // Vorbelegung "Jetzt +- Sekunden" für min/max
  // wird im Fontend gerundet auf nächste volle 30 Minuten
  @Input() ngrxStartPredefinedFromNow: FormControlState<number>;
  @Input() ngrxEndPredefinedFromNow: FormControlState<number>;

  @Input() ngrxWeekendsActive: FormControlState<boolean>;

  constructor(store$: Store<CoreFeatureState>, util: RouteConfigFormPropertyComponent) {
    super(store$, util);
  }

  internalReloadValues(lastConfigItemValue) {
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxPreChecked.id, lastConfigItemValue.preChecked));
  }

  isEventAttached(o: EventEmitter<unknown>) {
    return o && o.observers && o.observers.length > 0;
  }

  onChangeReadonly(overwrite: boolean) {
    super.onChangeReadonly(overwrite);

    if (overwrite) {
      this.store$.select(getEasyFormConfigsEntitiesState).pipe(
        withLatestFrom(
          this.store$.select(getEasyFormConfigsSelectedValueState),
          this.util.getDateRangeConfig$(this.attributeName),
          (entities, thisForm, thisControls) => ({entities, thisForm, thisControls}),
        ),
        takeUntil(this.destroy$),
        take(1),
      ).subscribe(({entities, thisForm, thisControls}) => {
        const lastConfigItemValue = entities[thisForm.id];
        if (lastConfigItemValue && lastConfigItemValue.parent) {
          this.resetTranslateIds(thisControls.controls.startDateTranslations.controls);
          this.resetTranslateIds(thisControls.controls.startTimeTranslations.controls);
          this.resetTranslateIds(thisControls.controls.endDateTranslations.controls);
          this.resetTranslateIds(thisControls.controls.endTimeTranslations.controls);
        }
      });
    }
  }

  resetTranslateIds(controls: FormGroupControls<ConfigItemTranslationsDtoModel>) {
    for (const lang of Object.values(controls)) {
      if (lang.controls.id && lang.controls.id.id) {
        this.store$.dispatch(new SetValueTraceableAction(lang.controls.id.id, undefined));
      }
    }
  }
}
