import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { merge } from 'rxjs';
import { delayWhen, filter, map, withLatestFrom } from 'rxjs/operators';
import { TileModel } from '../../../core-lib/models/tile.model';
import { getSessionUserRoles } from '../../../session/reducers/session.reducer';
import { getConfigAdminElementsState } from '../../ngrx/reducers/core.store';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-route-config',
  templateUrl: './route-config.component.html',
  styleUrls: ['./route-config.component.scss'],
})
export class RouteConfigComponent {
  configElements$ = this.store$.select(getConfigAdminElementsState).pipe(
    delayWhen(() => this.store$.select(getSessionUserRoles).pipe(filter(roles => !!roles))),
    withLatestFrom(
      this.store$.select(getSessionUserRoles),
      (adminElements, userRoles) => ({adminElements, userRoles}),
    ),
    filter(({userRoles}) => !!userRoles),
    map(({adminElements, userRoles}) => adminElements.filter(adminElement => adminElement.active(this.appConfigServce.config, userRoles))),
  );

  // Based on the screen size, switch from standard to one column per row
  cards$ = merge(this.configElements$, this.breakpointObserver.observe(Breakpoints.Handset)).pipe(
    withLatestFrom(this.breakpointObserver.observe(Breakpoints.Handset), this.configElements$),
    map(([obsolete, {matches}, configElements]): TileModel[] => {
      if (matches) {
        return configElements.map((e, index) => ({
          cols: 6,
          data: {
            caption: e.name,
            class: e.class + ((index % 8) + 1),
            icon: e.icon,
            routerLink: e.routerLink,
          },
        }));
      }

      return configElements.map((e, index) => ({
        data: {
          caption: e.name,
          class: e.class + ((index % 8) + 1),
          icon: e.icon,
          routerLink: e.routerLink,
        },
      }));
    }),
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private store$: Store<any>,
    private appConfigServce: AppConfigService,
  ) {
  }

  cardClicked({data, $event}) {
    // ToDo: Do sth.
  }
}
