import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControlState, FormGroupState } from 'ngrx-forms';
import { ConfigItemTranslationsDtoModel } from '../../models/config-item-configuration-dto.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-input-property-lang-wrapper',
  templateUrl: './input-property-lang-wrapper.component.html',
  styleUrls: ['./input-property-lang-wrapper.component.scss'],
})
export class InputPropertyLangWrapperComponent {
  @Input() headerI18n = 'translations';
  @Output() labelResetClick = new EventEmitter<FormControlState<string>>();
  @Input() translations: FormGroupState<ConfigItemTranslationsDtoModel>;
  @Input() undoVisible: boolean;
}
