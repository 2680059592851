<lib-common-page-margin>
  <div *ngIf="company$ | async">
    <div fxLayout="row wrap"
         fxLayoutAlign="space-between"
         fxLayoutGap="10px grid"
         class="seach-margin">
      <div
        fxFlex.gt-md="47%"
        fxFlex.lt-lg="100%">
        <h2>{{ 'formConfig' | i18n | async }} {{ formName$ | async }} ({{ formIdentifier }}) {{ companyShort$ | async }}</h2>
      </div>
      <div
        fxFlex.gt-md="53%"
        fxFlex.lt-lg="100%">
        <button
          type="button"
          mat-raised-button
          color="primary"
          (click)="saveFormConfig()"
        >
          {{'save' | i18n | async}}
        </button>
      </div>
    </div>
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between"
      fxLayoutGap="10px grid"
    >
      <mat-form-field
        fxFlex.gt-md="48"
        fxFlex.lt-lg="100%"
      >
        <input
          [(ngModel)]="nameFilter"
          [placeholder]="'search' | i18n | async"
          matInput
        >
        <button
          aria-label="search"
          mat-button
          mat-icon-button
          matSuffix
          type="button"
        >
          <mat-icon svgIcon="innogy:magnifier"></mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field
        fxFlex.gt-md="48"
        fxFlex.lt-lg="100%"
      >
        <mat-select
          [ngrxFormControlState]="parentIdControl$| async"
          [ngrxValueConverter]="getConverter()"
          [placeholder]="'inheritFrom' | i18n | async"
          (selectionChange)="inheritFormConfig($event)"
        >
          <mat-option>
            <span class="style-matselect"> {{ 'deselect' | i18n | async }}</span>
          </mat-option>
          <mat-option
            *ngFor="let company of otherFormCompanies$ | async"
            [value]="company.shortName"
          >
            {{ company.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="tabs-margin">
      <lib-common-route-config-form-properties-container
        *ngIf="!(isCategorizedConfigArray$() | async) else CategorizedConfigArrayContainer"
        [elements]="getConfigArray$() | async"
      ></lib-common-route-config-form-properties-container>
      <ng-template #CategorizedConfigArrayContainer>
        <mat-tab-group>
          <mat-tab
            [label]="category.displayName"
            *ngFor="let category of (getCategorizedConfigArray$() | async); trackBy: trackByKey"
          >
            <div class="content-tab">
              <lib-common-route-config-form-properties-container
                [elements]="category.elements"
                [readonly]="(isParentSet$ |async)"
              ></lib-common-route-config-form-properties-container>
            </div>
          </mat-tab>
        </mat-tab-group>
      </ng-template>
    </div>
  </div>
  <br>
  <div
    fxLayout="row"
    fxLayoutAlign="center"
  >
    <div class="save-margin-bottom">
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="saveFormConfig()"
      >
        {{'save' | i18n | async}}
      </button>
    </div>
  </div>
</lib-common-page-margin>
