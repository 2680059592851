<div class="nav-element p-2" [ngClass]="{'darkmode': darkmode}" *ngIf="showAsButton === false">
  <a *ngIf="src"
     [ngClass]="{'nav-link-hover': highlightOnRouteMatch && (isCurrentRoute$ | async)}"
     (click)="linkClicked()"
     class="nav-link"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>
  <a (click)="reject($event)"
     *ngIf="!src"
     [ngClass]="{'nav-link-hover': highlightOnRouteMatch && (isCurrentRoute$ | async)}"
     class="nav-link"
     href="#"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>
</div>
<div class="nav-element p-2" [ngClass]="{'darkmode': darkmode}" *ngIf="showAsButton !== false">
  <button
    type="button"
    mat-button
    *ngIf="src"
    class="nav-link nav-element"
    [ngClass]="{'nav-link-hover': highlightOnRouteMatch && (isCurrentRoute$ | async)}"
    (click)="linkClicked()"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </button>
  <button
    type="button"
    mat-button
    class="nav-link nav-element"
    (click)="reject($event)"
    *ngIf="!src"
    [ngClass]="{'nav-link-hover': highlightOnRouteMatch && (isCurrentRoute$ | async)}"
    href="#"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </button>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
