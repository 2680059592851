<mat-form-field class="facts-width">
  <mat-select
    [ngrxFormControlState]="factState"
    ngrxEasyFieldConfig="facts"
    [placeholder]="'fact' | i18n | async"
  >
    <mat-option *ngFor="let fact of facts" [value]="fact.value">
      {{ fact.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
