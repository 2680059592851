import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionActionHttpError } from '../actions/session.actions';

@Injectable({
  providedIn: 'root',
})
export class LoginInterceptorService implements HttpInterceptor {
  constructor(
    private store$: Store<any>,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          try {
            const excludedUrls = [
              '/api/session/isloggedin',
              '/api/fuv'
            ];
            if (excludedUrls.find(url => req.url.includes(url)) === undefined) {
              // dispatch Action, to re-check login state and conditionally forward to login
              this.store$.dispatch(new SessionActionHttpError(err));
            }
          } catch (e) {
          }
        }
        return throwError(err);
      }),
    );
  }
}
