<mat-form-field class="country-width">
  <mat-select
    [ngrxFormControlState]="countryState"
    ngrxEasyFieldConfig="country"
    [placeholder]="'country' | i18n | async"
  >
    <mat-option *ngFor="let country of countries" [value]="country.value" >
      {{ country.label }}
    </mat-option>
  </mat-select>
  <mat-error *ngFor="let key of errorKeys">{{(key + "Error") | i18n: errors | async}}</mat-error>
</mat-form-field>

