import { backendClasses } from './backend-classes.model';
import { ConfigItemConfigurationDtoModel } from './config-item-configuration-dto.model';

export class ConfigItemDateConfigurationDtoModel extends ConfigItemConfigurationDtoModel {
  static readonly CLASSNAME: string = backendClasses.dateConfiguration;
  static TYPENAME = 'typeDate';
  '@class' = ConfigItemDateConfigurationDtoModel.CLASSNAME;
  $$typeName = ConfigItemDateConfigurationDtoModel.TYPENAME;

  maxDate: string = undefined; // LocalDate
  minDate: string = undefined; // LocalDate
  weekendsActive: boolean = undefined;
  maxDateCurrentDate: boolean = undefined;
  minDateCurrentDate: boolean = undefined;
  predefineWithCurrentDate: boolean = undefined;

}
