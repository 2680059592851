<lib-common-page-margin>
  <ngx-datatable
    class="material striped"
    [rows]="(content$ | async)"
    [columnMode]="columnMode"
    [sortType]="sortType"
    headerHeight="50"
    rowHeight="'auto'"
  >
    <ngx-datatable-column
      [name]="'actions' | i18n | async"
      [prop]="'id'"
    >
      <ng-template let-value="value" ngx-datatable-cell-template>
        <button
          type="button"
          mat-icon-button
          [title]="'queueRetry' | i18n | async"
          (click)="reset(value)"
        >
          <mat-icon>cached</mat-icon>
        </button>
        <button
          type="button"
          mat-icon-button
          [title]="'queueDelete' | i18n | async"
          (click)="delete(value)"
        >
          <mat-icon>highlight_off</mat-icon>
        </button>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [name]="'id' | i18n | async"
      [prop]="'proposalPublicId'"
    >
    </ngx-datatable-column>
    <ngx-datatable-column
      [name]="'authorId' | i18n | async"
      [prop]="'authorName'"
    >
    </ngx-datatable-column>
    <ngx-datatable-column
      [name]="'useCase' | i18n | async"
      [prop]="'useCase'"
    >
    </ngx-datatable-column>
    <ngx-datatable-column
      [name]="'lastTryTime' | i18n | async"
      [prop]="'lastTry'"
      [cellTemplate]="dateCellTemplate"
    >
    </ngx-datatable-column>
  </ngx-datatable>
</lib-common-page-margin>

<ng-template
  #dateCellTemplate
  let-row="row"
  let-column="column"
>
  <p>
    {{row[column.prop] | date :'dd.MM.yyyy'}} {{row[column.prop] | date :'HH:mm'}}
  </p>
</ng-template>
