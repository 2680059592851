import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { UserDtoModel } from '../../core-lib/models/user-dto.model';

export class SessionActionLoad implements Action {
  static readonly TYPE = 'session/init';
  readonly type = SessionActionLoad.TYPE;
  constructor() {}
}

export class SessionActionLoadedUser implements Action {
  static readonly TYPE = 'session/loadedUser';
  readonly type = SessionActionLoadedUser.TYPE;
  constructor(public user: UserDtoModel) {}
}

export class SessionActionLoadedIsLoggedIn implements Action {
  static readonly TYPE = 'session/loadedIsLoggedIn';
  readonly type = SessionActionLoadedIsLoggedIn.TYPE;
  constructor(public isLoggedIn: boolean) {}
}

export class SessionSpinnerOpen implements Action {
  static readonly TYPE = 'session/spinner/open';
  readonly type = SessionSpinnerOpen.TYPE;

  constructor(public id: string, public timeout = 10000) {}
}

export class SessionSpinnerClose implements Action {
  static readonly TYPE = 'session/spinner/close';
  readonly type = SessionSpinnerClose.TYPE;

  constructor(public id: string, public wasTimeout = false) {}
}

export class SessionSpinnerCloseWithoutDelay implements Action {
  static readonly TYPE = 'session/spinner/closeWithoutDelay';
  readonly type = SessionSpinnerCloseWithoutDelay.TYPE;

  constructor(public id: string, public wasTimeout = false) {}
}

export class SessionSpinnerCloseAllWithoutDelay implements Action {
  static readonly TYPE = 'session/spinner/closeAllWithoutDelay';
  readonly type = SessionSpinnerCloseAllWithoutDelay.TYPE;
}

export class SessionActionHttpError implements Action {
  static readonly TYPE = 'session/http/error';
  readonly type = SessionActionHttpError.TYPE;

  constructor(public error: HttpErrorResponse) {}
}

export type SessionActions =
  SessionActionLoad |
  SessionActionLoadedUser |
  SessionActionLoadedIsLoggedIn |
  SessionSpinnerOpen |
  SessionSpinnerClose |
  SessionSpinnerCloseWithoutDelay |
  SessionSpinnerCloseAllWithoutDelay;
