<ng-template
  #defaultGridTemp
  let-d="data"
>
  <div class="card-height" >
    <mat-card
      class="dashboard-card"
      (click)="anyCardClicked(d, $event)"
      [style.color]="d.color"
      [style.backgroundColor]="d.backgroundColor"
      [ngClass]="d.class"
      [routerLink]="d.routerLink"
      fxFlexFill
    >
      <div class="dashboard-card-header">
        <button
          mat-button
          *ngIf="closeButton"
          class="close-button"
          (click)="anyCloseButtonClicked(d, $event)"
        >
          <mat-icon svgIcon="innogy:close"></mat-icon>
        </button>
      </div>
      <mat-card-content>
        <div fxLayout="column" fxLayoutAlign="center center">
          <div
            *ngIf="d.icon || d.caption"
          >
            <mat-icon *ngIf="d.icon" class="dashboard-titel-icon" [svgIcon]=" 'innogy:' + d.icon"
                      ngClass.lt-sm="icon-lower-sm">
            </mat-icon>
          </div>
          <div
            ngClass.gt-md="greater-md"
            *ngIf="d.icon || d.caption"
          >
            <span>{{ d.caption }}</span></div>

          <div
            *ngIf="d.subCaption">
            {{ d.subCaption }}
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>

<ng-template
  #addCardTemp
  let-d="data"
>
  <mat-card
    (click)="anyAddCardClicked()"
    class="dashboard-card add-button"
  >
    <mat-card-content>
      <mat-icon class="add-icon" svgIcon="innogy:c-plus"></mat-icon>
    </mat-card-content>
  </mat-card>
</ng-template>

<div class="margin-bottom-card">
  <h1
    class="mat-h1 mat-h1-dashboard"
    *ngIf="heading"
  >
    {{ heading }}
  </h1>
  <button
    class="save-margin"
    mat-raised-button
    color="primary"
    *ngIf="showSaveButton"
    (click)="saveView(_tiles)"
  >{{'saveView' | i18n | async}}
    <mat-icon
      class="svg-icon-floppy"
      svgIcon="innogy:floppy"
    ></mat-icon>
  </button>
    <p *ngIf="tiles.length == 0" class="no-entry-text"> {{'noEntry' | i18n | async}}</p>

  <div
    class="grid"
    fxLayout="row wrap"
    fxLayoutGap="10px grid"
    fxLayoutAlign="stretch"
    *ngIf="tiles && tiles.length"

    cdkDropListGroup
  >
    <div
      fxFlex="0 1 25%"
      fxFlex.lt-md="0 1 50%"
      *ngFor="let d of tiles; let i=index"

      cdkDropList
      [cdkDropListData]="{item:d,index:i}"
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="dropped($event)"
    >
      <div   cdkDrag [cdkDragDisabled]="disableDrag">
        <div class="custom-placeholder" *cdkDragPlaceholder></div>
        <ng-container
          [ngTemplateOutlet]="(d.template ? d.template : (gridTemplateMap[d.templateKey]) || defaultGridTemp)"
          [ngTemplateOutletContext]="{ data: d.data }"
        ></ng-container>
      </div>
    </div>
  </div>

  <button
    type="button"
    mat-button
    class="more-button"
    *ngIf="showMoreButton()"
    (click)="toggleShowAll()"
  >
    {{ (showAll ? 'showLess' : 'showAll') | i18n | async }}
    <mat-icon [svgIcon]="showAll ? 'innogy:c-minus' : 'innogy:c-plus'"></mat-icon>
  </button>
</div>
