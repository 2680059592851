import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as moment from 'moment';
import { UserDtoModel } from '../../core-lib/models/user-dto.model';
import { ifDefined } from '../../core-lib/utils/reducer-utils';
import { UserRole } from '../../core/models/user-roles.model';
import { environment } from '../../environments/environment';
import {
  SessionActionLoad,
  SessionActionLoadedIsLoggedIn,
  SessionActionLoadedUser,
  SessionActions,
  SessionSpinnerCloseAllWithoutDelay,
  SessionSpinnerCloseWithoutDelay,
  SessionSpinnerOpen,
} from '../actions/session.actions';

export interface SessionState {
  user: UserDtoModel;
  isLoggedIn: boolean;
  spinnerLocks: string[];
}

export const initialState: SessionState = {
  user: undefined,
  isLoggedIn: undefined,
  spinnerLocks: [],
};

// helpers to trace long running spinners -> in console type window.times
const opentimes = environment.production ? undefined : (window as any).opentimes = (window as any).opentimes || {};
const times = environment.production ? undefined : (window as any).times = (window as any).times || [];

export function reducer(state = initialState, action: SessionActions): SessionState {
  switch (action.type) {

    case SessionActionLoad.TYPE:
      return state;
    case SessionActionLoadedUser.TYPE:
      return {
        ...state,
        user: action.user,
      };
    case SessionActionLoadedIsLoggedIn.TYPE:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };
    case SessionSpinnerOpen.TYPE:
      if (!environment.production) {
        opentimes[action.id] = moment();
      }
      return {
        ...state,
        spinnerLocks: [
          ...state.spinnerLocks.filter((lock) => lock !== action.id),
          action.id,
        ],
      };
    case SessionSpinnerCloseWithoutDelay.TYPE:
      if (action.wasTimeout) {
        console.error('spinner timeout', action);
      }
      if (!environment.production) {
        times.push({
          id: action.id,
          duration: -moment(opentimes[action.id]).diff(moment(), 's') + 's' + (action.wasTimeout ? ' timeout' : ''),
        });
        delete opentimes[action.id];
      }
      return {
        ...state,
        spinnerLocks: [
          ...state.spinnerLocks.filter((lock) => lock !== action.id),
        ],
      };
    case SessionSpinnerCloseAllWithoutDelay.TYPE:
      return {
        ...state,
        spinnerLocks: [],
      };

    default:
      return state;
  }
}

export const getSessionState = createFeatureSelector<SessionState>('session');
export const getSessionUser = (state: any) => ifDefined(getSessionState(state), sessionState => sessionState.user);
export const getSessionUserRoles = (state: any) => ifDefined(getSessionUser(state), sessionUser => sessionUser.roles);
export const getSessionUserData = (state: any) => ifDefined(getSessionUser(state), sessionUser => sessionUser.data);
export const getSessionUserIsAdmin = (state: any) => ifDefined(
  getSessionUserRoles(state),
  (sessionUserRoles) => !!sessionUserRoles.find(role => role === UserRole.ADMIN),
);
export const getSessionUserAdminAreaIsVisible = (state: any) => ifDefined(
  getSessionUserRoles(state),
  (sessionUserRoles) => !!sessionUserRoles.find(role => role.includes(UserRole.ADMIN) || role === UserRole.MASSDATA),
);

export const getSessionUserIsOnlyCaterer = (state: any) => ifDefined(
  getSessionUserRoles(state),
  (sessionUserRoles) => !!sessionUserRoles.includes(UserRole.CATERER)
    && !(sessionUserRoles.includes(UserRole.CATERER_ADMIN) || sessionUserRoles.includes(UserRole.ADMIN)),
);

export const getSessionUserIsCaterer = createSelector(
  getSessionUserRoles,
  (state) => ifDefined(state, (sessionUserRoles) => sessionUserRoles.includes(UserRole.CATERER)),
);
export const getSessionIsLoggedIn = (state: any) => ifDefined(getSessionState(state), sessionState => sessionState.isLoggedIn);
export const getSessionSpinnerLocks = (state: any) => ifDefined(getSessionState(state), sessionState => sessionState.spinnerLocks);
export const getSessionIsSpinnerOpen = (state: any) => ifDefined(getSessionSpinnerLocks(state), spinnerLocks => spinnerLocks.length > 0);
