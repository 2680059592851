// @ts-nocheck
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, MediaMarshaller } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatCommonModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { InnogyIconsService } from './innogy-icons.service';
import { CustomErrorStateMatcherDirective } from './ngrx-fixes/error-state-matcher';
import { MatListOptionFixDirective } from './ngrx-fixes/mat-list-option-fix';
import { NgrxMatSelectViewAdapter } from './ngrx-fixes/mat-select-view-adapter';

const imports = [
  FormsModule,
  ReactiveFormsModule,
  MatCommonModule,
  MatInputModule,
  MatButtonModule,
  MatCheckboxModule,
  MatStepperModule,
  MatFormFieldModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatIconModule,
  MatMomentDateModule,
  MatDatepickerModule,
  MatSelectModule,
  MatListModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatToolbarModule,
  MatTableModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatSnackBarModule,
  NgxMatSelectSearchModule,
  MatSnackBarModule,
  MatDialogModule,
  MatTabsModule,
  MatRadioModule,
  MatToolbarModule,
  MatGridListModule,
  MatButtonToggleModule,
];
const ngrxFixes = [
  CustomErrorStateMatcherDirective,
  MatListOptionFixDirective,
  NgrxMatSelectViewAdapter,
];

@NgModule({
  declarations: [
    ...ngrxFixes,
  ],
  imports: [
    NgxMaterialTimepickerModule.setLocale('de-DE'),
    FlexLayoutModule.withConfig({
      useColumnBasisZero: false,
      printWithBreakpoints: ['xl', 'gt-lg', 'gt-md', 'gt-sm', 'gt-xs'],
    }),
    ...imports,
  ],
  exports: [
    NgxMaterialTimepickerModule,
    FlexLayoutModule,
    ...imports,
    ...ngrxFixes,
  ],
  providers: [
    {provide: MAT_SNACK_BAR_DATA, useValue: ''},
    {provide: MAT_DATE_LOCALE, useValue: 'de'},
    {provide: MAT_DATE_FORMATS, useValue: {
      ...MAT_MOMENT_DATE_FORMATS,
        display: {
          ...MAT_MOMENT_DATE_FORMATS.display,
          dateInput: 'DD.MM.YYYY',
        },
    }},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS]},
    {provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}},
  ],
})
export class CommonMaterialModule {
  lastValue;

  public constructor (
    protected iconFactory: InnogyIconsService,
    protected mediaMarshaller: MediaMarshaller,
  ) {
    this.flexPrintHack();
    this.matRequiredHack();
    this.iconFactory.registerIcons();
  }

  flexPrintHack() {
    // hack until resolve: https://github.com/angular/flex-layout/issues/1201
    this.mediaMarshaller.subject.subscribe(() => {
      if (this.mediaMarshaller.activatedBreakpoints.filter((b) => b.alias === 'print').length === 0) {
        this.lastValue = [...this.mediaMarshaller.activatedBreakpoints];
      } else {
        this.mediaMarshaller.activatedBreakpoints = [...this.lastValue];
        this.mediaMarshaller.hook.collectActivations = () => {};
        this.mediaMarshaller.hook.deactivations = [...this.lastValue];
      }
    });
  }

  matRequiredHack() {
    // hack https://github.com/angular/components/issues/2574
    Object.defineProperty(MatInput.prototype, 'required', {
      get: function (): boolean {
        if (this._required) {
          return this._required;
        }

        // The required attribute is set
        // when the control returns an error from validation with an empty value
        if (this.ngControl && this.ngControl.control && this.ngControl.control.validator) {
          const emptyValueControl = Object.assign({}, this.ngControl.control);
          (emptyValueControl as any).value = null;
          return 'required' in (this.ngControl.control.validator(emptyValueControl) || {});
        }
        return false;
      },
      set: function (value: boolean) {
        this._required = coerceBooleanProperty(value);
      }
    });
  }
}
