export enum UserRole {
  /**
   * Rolle für Endanwender.
   */
  ENDUSER = 'ENDUSER',
  /**
   * Rolle für Admins.
   */
  ADMIN = 'ADMIN',
  /**
   * Rolle für Catering Bezogene Admin funktionen
   */
  CATERER_ADMIN = 'CATERER_ADMIN',
  /**
   * Rolle für Caterer
   */
  CATERER = 'CATERER',
  /**
   * Rolle für Systembetreuer
   */
  SYSTEM_OPERATOR = 'SYSTEM_OPERATOR',
  /**
   * Rolle für Massendatenverarbeitung
   */
  MASSDATA = 'MASSDATA',
  /**
   * Rolle zum Ausblenden durch den GUARD.
   * KEINEN NUTZER ZUWEISEN!
   */
  INVISIBLE = 'INVISIBLE',
  /**
   * Formularrollen
   */
  FORM_ACCOUNTREFERENCE = 'FORM_ACCOUNTREFERENCE',
  /**
   * Zugriff auf das Dashboard für Auswertungen
   */
  DASHBOARD = 'DASHBOARD',
}
