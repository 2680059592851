<div class="margin-content">
  <form
    [ngrxFormState]="systemMessageForm$ | async"
    (submit)="saveSystemMessage()"
  >
    <lib-common-form-flex-container>
      <div fxFlex="1 1 auto">
        <mat-card>
          <mat-card-title class="text-center">{{ 'systemMessage' | i18n | async }}</mat-card-title>
          <mat-card-content>

            <div fxLayout="row wrap"
                 fxLayoutAlign="space-between"
                 fxLayoutGap="10px grid"
            >
              <div fxFlex.gt-md="20%"
                   fxFlex.lt-lg="100%">
                <mat-form-field
                >
                  <input
                    matInput
                    [matDatepicker]="pickerTo"
                    [ngrxFormControlState]="getFormControlState$('activeUntil') | async"
                    [ngrxValueConverter]="ngrxLocalDateTimeValueConverter"
                    [placeholder]="'dateTo' | i18n | async"
                  >
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="pickerTo"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field>
              </div>
              <div fxFlex.gt-md="70%"
                   fxFlex.lt-lg="100%"
                   class="margin-check">
                <mat-checkbox
                  [ngrxFormControlState]="getFormControlState$('active') | async"
                >
                  {{ 'activeSystemMessage' | i18n | async }}
                </mat-checkbox>
              </div>
              <div fxFlex.gt-md="4%"
                   fxFlex.lt-lg="100%">
                <button
                  class="margin-info"
                  type="button"
                  mat-icon-button
                  (click)="openDialog()"
                  title="{{ 'infoIconText' | i18n | async }}"
                >
                  <mat-icon class="svgInfo" svgIcon="innogy:info"></mat-icon>
                </button>
              </div>
            </div>

            <div fxLayout="row wrap">
              <div fxFlex="1 0 auto">
                <lib-common-angular-editor
                  [controlState]="getFormControlState$('content') | async"
                ></lib-common-angular-editor>
              </div>
            </div>

          </mat-card-content>
        </mat-card>
      </div>
    </lib-common-form-flex-container>

    <lib-common-form-flex-container>
      <div fxFlexAlign.lg="right">
        <button
          type="submit"
          color="primary"
          mat-raised-button
        >
          <mat-icon
            class="svg-icon-floppy"
            svgIcon="innogy:floppy"
          ></mat-icon>
          {{ 'save' | i18n | async }}</button>
      </div>
    </lib-common-form-flex-container>
  </form>
</div>
