import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { CoreFeatureState } from '../../ngrx/reducers/core.store';
import { getRouterNavDisplay } from '../../ngrx/reducers/router.reducer';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-page-navbar',
  templateUrl: './page-navbar.component.html',
  styleUrls: ['./page-navbar.component.scss'],
})
export class PageNavbarComponent {
  @Input() showAdministration = false;
  @Input() showCatering = false;
  @Output() toggleSidebar = new EventEmitter<boolean>();

  forceMobile = false;
  navDisplay$ = this.store$.select(getRouterNavDisplay);
  forceMobile$ = this.navDisplay$.pipe(
    map((navDisplay) => navDisplay && navDisplay.length > 0),
  );

  constructor(
    private store$: Store<CoreFeatureState>,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.forceMobile$.subscribe(f => this.forceMobile = f);
  }

  toggle() {
    this.toggleSidebar.emit(true);
  }

  navigateRelative(url) {
    // this.router.navigate([url], {relativeTo: this.route});
    // Workaround
    // realtive navigation broken, see: https://github.com/angular/angular/issues/13011
    if (url === './' || url === '.') {
      this.router.navigate([this.router.url]);
    } else {
      this.router.navigate([url], {relativeTo: this.route});
    }
  }

  getItems() {
    return [
      {
        name: 'summary',
        link: '/forms',
        icon: 'innogy:home-small',
        show: true,
      },
      {
        name: 'process',
        link: '/list',
        icon: 'innogy:docs-text',
        show: true,
      },
      {
        name: 'approval',
        link: '/approvals',
        icon: 'innogy:docs-check',
        show: true,
      },
      {
        name: 'catering',
        link: '/catering',
        icon: 'innogy:cutlery',
        show: this.showCatering,
      },
      {
        name: 'admin',
        link: '/config',
        icon: 'innogy:double-gear',
        show: this.showAdministration,
      },
    ];
  }
}
