<lib-common-input-property-wrapper
  [header]="attributeName"
  icon="list"
  [typeNameI18n]="typeName"
  [readonly]="readonly"
  [ngrxOverwrite]="ngrxOverwrite"
  (changeReadonly)="onChangeReadonly($event)"
>
  <div class="card-margin">
    <section>
      <mat-form-field *ngIf="ngrxValues && ngrxPredefinedValue">
        <mat-select
          [placeholder]="'defaultValue' | i18n |async"
          [ngrxFormControlState]="ngrxPredefinedValue"
        >
          <mat-option [value]="undefined">{{'noSelection' | i18n | async}}</mat-option>
          <mat-option *ngFor="let option of ngrxValues.values" [value]="option.id">{{option.attributeName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <section class="card-checkbox-margin">
      <lib-common-input-property-defaults
        [ngrxMandatory]="ngrxMandatory"
        [ngrxEnabled]="ngrxEnabled"
        [ngrxVisible]="ngrxVisible"
        [ngrxChangeableByInspector]="ngrxChangeableByInspector"
        [ngrxChangeableByApprover]="ngrxChangeableByApprover"
      ></lib-common-input-property-defaults>
    </section>
    <lib-common-input-property-lang-wrapper
      [translations]="translations"
      (labelResetClick)="labelResetClick.emit($event)"
      [undoVisible]="labelResetClick && labelResetClick.observers && labelResetClick.observers.length > 0"
    ></lib-common-input-property-lang-wrapper>
  </div>
</lib-common-input-property-wrapper>
