import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ApiService } from '../../../core-lib/services/api.service';
import { loadTemplateDeputy, setTemplateDeputy, setTemplateDeputyFailed } from '../actions/template-deputy.actions';

@Injectable()
export class TemplateDeputyEffects {
  loadTemplateDeputies$ = createEffect(() => this.actions$.pipe(
    ofType(loadTemplateDeputy),
    switchMap(() => this.api.getDeputyTemplates()),
    map(templateData => setTemplateDeputy({data: templateData})),
  ));

  loadTemplateDeputiesFailed$ = createEffect(() => this.actions$.pipe(
    ofType(setTemplateDeputyFailed),
    take(1),
    tap(() => this.router.navigate(['/template/deputy']))
  ));

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private router: Router,
  ) {
  }
}
