import { SetValueAction } from 'ngrx-forms';
import {Action} from '@ngrx/store';

export declare type ActionSanitizer = (action: Action, id: number) => Action;
export declare type StateSanitizer = (state: any, index: number) => any;

const isObject = (o) => o && typeof o === 'object' && !Array.isArray(o);

// only walk on this path(s), ignore others
const layerWhitelist: Array<Array<RegExp | string>> = [
  [''],
  [/form.*/],
  ['form'],
  ['value', 'controls'],
  ['step3'],
  ['value', 'controls', 'file'],
];

const walker = (subState, subName = '', layer = 0) => {
  if (layerWhitelist[layer]?.length > 0
    && !((layerWhitelist[layer]).reduce((prev: boolean, cur: RegExp | string) => {
      if (typeof cur === 'string') {
        return prev || cur === subName;
      } else {
        return prev || cur.test(subName);
      }
    }, false))) {
    return subState;
  }
  if (isObject(subState)) {
    return Object.entries(subState).reduce((acc, [name, value]: [string, any]) => {
      return {
        ...acc,
        [name]: walker(value, name, layer + 1),
      };
    }, {});
  }
  if (Array.isArray(subState)) {
    return subState.reduce((acc, value) => {
      return [
        ...acc,
        walker(value, '[]', layer + 1),
      ];
    }, []);
  }
  if (typeof subState === 'string' && subName === 'content' && subState) {
    return subState.substr(0, 15) + '<<LONG_BLOB>>';
  }
  return subState;
};

export const stateSanitizer: StateSanitizer = ((state) => {
  const walkedState = walker(state);
  return {
    ...walkedState,
    core: {
      ...walkedState?.core,
      config: {
        ...walkedState?.core?.config,
        i18n: '<<<SNIPPED>>>'
      }
    }
  };
});

export const actionSanitizer: ActionSanitizer = ((action: SetValueAction<any>) => (
  action.type === 'ngrx/forms/SET_VALUE' && action.controlId.includes('file') && !action.controlId.includes('configurationItems') ? {
    ...action,
    value: {
      ...action.value,
      value: [
        ...action.value.value.map(v => ({
          ...v,
          content: v.content ? v.content.substr(0, 15) + '<<LONG_BLOB>>' : v.content,
        })),
      ],
    }
  } : action
));
