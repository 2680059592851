import { createAction, props } from '@ngrx/store';
import { CumulativeDashboardData, DashboardStateCountType, DashboardThroughputType } from '../../../core-lib/models/dashboard.model';

export const coreDashboardUpdateStateCountAction = createAction('coreDashboardUpdateStateCountAction');
export const coreDashboardUpdateStateCountSuccessAction =
  createAction('coreDashboardUpdateStateCountSuccessAction', props<{statecount: DashboardStateCountType}>());
export const coreDashboardUpdateStateCountErrorAction = createAction('coreDashboardUpdateStateCountErrorAction');

export const coreDashboardUpdateThroughputAction = createAction('coreDashboardUpdateThroughputAction');
export const coreDashboardUpdateThroughputSuccessAction =
  createAction('coreDashboardUpdateThroughputSuccessAction', props<{throughput: DashboardThroughputType}>());
export const coreDashboardUpdateThroughputErrorAction = createAction('coreDashboardUpdateThroughputErrorAction');

export const coreDashboardUpdatePayoutAction = createAction('coreDashboardUpdatePayoutAction');
export const coreDashboardUpdatePayoutSuccessAction =
  createAction('coreDashboardUpdatePayoutSuccessAction', props<{payout: CumulativeDashboardData[]}>());
export const coreDashboardUpdatePayoutErrorAction = createAction('coreDashboardUpdatePayoutErrorAction');
