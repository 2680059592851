<div class="margin-content">
  <form (ngSubmit)="save()">
    <lib-common-form-flex-container>
      <mat-card-title>{{locationName$ | async}}</mat-card-title>
      <div *ngFor="let room of (roomArrayState$ |async); index as index; trackBy: getControlId" class="card-width">
        <mat-card>
          <mat-card-content>
            <div
              fxLayoutAlign="space-between"
              fxLayoutGap="10px grid"
            >
              <div
                fxFlex.gt-md="45%"
                fxFlex.lt-lg="100%"
              >
                <mat-form-field class="input-width">
                  <input [ngrxFormControlState]="room.controls.name" matInput [placeholder]="'roomName' | i18n | async" required>
                </mat-form-field>
              </div>
              <div
                fxFlex.gt-md="45%"
                fxFlex.lt-lg="100%"
              >
                <mat-form-field class="input-width">
                  <input [ngrxFormControlState]="room.controls.description" matInput [placeholder]="'description' | i18n | async">
                </mat-form-field>
              </div>
              <div
                fxFlex.gt-md="5%"
                fxFlex.lt-lg="100%"
              >
                <button class="delete-button" type="button" (click)="removeRoom(index)" mat-icon-button>
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="button"
          (click)="addRoom()"
          color="accent"
          mat-fab
        >
          <mat-icon svgIcon="innogy:plus"></mat-icon>
        </button>
      </div>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="submit"
          color="primary"
          mat-raised-button
        >
          <mat-icon
            class="svg-icon-floppy"
            svgIcon="innogy:floppy"
          ></mat-icon>
          {{ 'save' | i18n | async }}
        </button>
      </div>
    </lib-common-form-flex-container>
  </form>
</div>
