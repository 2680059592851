import { ConfigurationItems } from '../../core-lib/models/config-dto.model';
import { ConfigItemAccountingConfigurationDtoModel } from '../../core-lib/models/config-item-accounting-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../core-lib/models/config-item-arranger-list-configuration-dto.model';
import { ConfigItemCheckboxConfigurationDtoModel } from '../../core-lib/models/config-item-checkbox-configuration-dto.model';
import { ConfigItemConfigurationCategoryModel } from '../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemDateConfigurationDtoModel } from '../../core-lib/models/config-item-date-configuration-dto.model';
import { ConfigItemFileUploadConfigurationDtoModel } from '../../core-lib/models/config-item-file-upload-configuration-dto.model';
import { ConfigItemLedgerAccountConfigurationDtoModel } from '../../core-lib/models/config-item-ledger-account-configuration-dto.model';
import { ConfigItemListConfigurationDtoModel } from '../../core-lib/models/config-item-list-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../../core-lib/models/config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../../core-lib/models/config-item-text-input-configuration-dto.model';
import { ConfigListDtoModel } from '../../core-lib/models/config-list-dto.model';
import { PayeeDtoModel } from '../../forms/all-forms/models/payee-dto.model';
import { FormAF16DtoModel } from '../../forms/form-af16/models/form-af16-dto.model';
import { EasyFormAF06Steps } from './easy-form-af06-config.model';
import { EqualKeyNames } from './equal-key-names';

export const EasyFormAF16Steps = {
  step1: <ConfigItemConfigurationCategoryModel>{
    id: 'step1',
    displayName: 'Gesellschaft',
  },
  step2: <ConfigItemConfigurationCategoryModel>{
    id: 'step2',
    displayName: 'Zahlungsempfänger',
  },
  step3: <ConfigItemConfigurationCategoryModel>{
    id: 'step3',
    displayName: 'Rechnungsangaben',
  },
};

// ToDo: Check ob dieses Config-Model auch via FormA1Step1,FormA1Step2,FormA1Step3 bzw FormA1State erzeugt werden kann.
export class EasyFormAF16ConfigItemsModel extends ConfigurationItems implements EqualKeyNames<FormAF16DtoModel>, EqualKeyNames<PayeeDtoModel> {
  payeeNumber = new ConfigItemTextInputConfigurationDtoModel('payeeNumber').withCategory(EasyFormAF16Steps.step2);

  // PayeeDtoModel
  salutation = new ConfigItemTextInputConfigurationDtoModel('salutation').withCategory(EasyFormAF16Steps.step2);
  name = new ConfigItemTextInputConfigurationDtoModel('name').withCategory(EasyFormAF16Steps.step2);
  nameAddition = new ConfigItemTextInputConfigurationDtoModel('nameAddition').withCategory(EasyFormAF16Steps.step2);
  street = new ConfigItemTextInputConfigurationDtoModel('street').withCategory(EasyFormAF16Steps.step2);
  zipCode = new ConfigItemTextInputConfigurationDtoModel('zipCode').withCategory(EasyFormAF16Steps.step2);
  city = new ConfigItemTextInputConfigurationDtoModel('city').withCategory(EasyFormAF16Steps.step2);
  country = new ConfigItemTextInputConfigurationDtoModel('country').withCategory(EasyFormAF16Steps.step2);
  email = new ConfigItemTextInputConfigurationDtoModel('email').withCategory(EasyFormAF16Steps.step2);
  iban = new ConfigItemTextInputConfigurationDtoModel('iban').withCategory(EasyFormAF16Steps.step2);
  bic = new ConfigItemTextInputConfigurationDtoModel('bic').withCategory(EasyFormAF16Steps.step2);

  // differentBADtoModel
  differentBillingAddress = new ConfigItemCheckboxConfigurationDtoModel('differentBillingAddress').withCategory(EasyFormAF06Steps.step2);
  nameBA = new ConfigItemTextInputConfigurationDtoModel('nameBA').withCategory(EasyFormAF16Steps.step2);
  nameAdditionBA = new ConfigItemTextInputConfigurationDtoModel('nameAdditionBA').withCategory(EasyFormAF16Steps.step2);
  streetBA = new ConfigItemTextInputConfigurationDtoModel('streetBA').withCategory(EasyFormAF16Steps.step2);
  zipCodeBA = new ConfigItemTextInputConfigurationDtoModel('zipCodeBA').withCategory(EasyFormAF16Steps.step2);
  cityBA = new ConfigItemTextInputConfigurationDtoModel('cityBA').withCategory(EasyFormAF16Steps.step2);

  ustId = new ConfigItemTextInputConfigurationDtoModel('ustId').withCategory(EasyFormAF16Steps.step2);
  taxNumber = new ConfigItemTextInputConfigurationDtoModel('taxNumber').withCategory(EasyFormAF16Steps.step2);

  // Verwendungszweck
  useCase = new ConfigItemTextInputConfigurationDtoModel('useCase').withCategory(EasyFormAF16Steps.step3);
  description = new ConfigItemTextInputConfigurationDtoModel('description').withCategory(EasyFormAF16Steps.step3);
  date = new ConfigItemDateConfigurationDtoModel('date').withCategory(EasyFormAF16Steps.step3);

  // positions
  positions = new ConfigListDtoModel('positions').withCategory(EasyFormAF16Steps.step3);
  positionName = new ConfigItemTextInputConfigurationDtoModel('positionName').withCategory(EasyFormAF16Steps.step3);
  netCost = new ConfigItemNumberConfigurationDtoModel('netCost').withCategory(EasyFormAF16Steps.step3);
  taxRate = new ConfigItemListConfigurationDtoModel('taxRate', 'taxrates').withCategory(EasyFormAF16Steps.step3);
  grossCost = new ConfigItemNumberConfigurationDtoModel('grossCost').withCategory(EasyFormAF16Steps.step3);
  taxValue = new ConfigItemNumberConfigurationDtoModel('taxValue').withCategory(EasyFormAF16Steps.step3);
  account = new ConfigItemAccountingConfigurationDtoModel('account').withCategory(EasyFormAF16Steps.step3);
  ledgerAccount = new ConfigItemLedgerAccountConfigurationDtoModel('ledgerAccount').withCategory(EasyFormAF16Steps.step3);

  // payout
  payout = new ConfigItemNumberConfigurationDtoModel('payout').withCategory(EasyFormAF16Steps.step3);

  // Genehmigungsstufen
  approver = new ConfigItemArrangerListConfigurationDtoModel('approver').withCategory(EasyFormAF16Steps.step3);
  inspector = new ConfigItemArrangerListConfigurationDtoModel('inspector').withCategory(EasyFormAF16Steps.step3);
  commentForApprover = new ConfigItemTextInputConfigurationDtoModel('commentForApprover').withCategory(EasyFormAF16Steps.step3);

  file = new ConfigItemFileUploadConfigurationDtoModel('file').withCategory(EasyFormAF16Steps.step3);
}
