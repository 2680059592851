import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable ({
  providedIn: 'root',
})
export class ScrollToTopService {
  constructor(
    @Inject(DOCUMENT) private document: Document
  ){}

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 1) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 2));
      }
    })();
  }
}
