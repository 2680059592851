import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { ApiService } from '../../../core-lib/services/api.service';
import {
  deleteProcessQueueAction,
  deleteProcessQueueSuccessAction,
  loadProcessQueueAction,
  loadProcessQueueSuccessAction,
  resetProcessQueueAction,
  resetProcessQueueSuccessAction,
} from '../actions/queue.actions';


@Injectable()
export class ProcessQueueEffects {

  loadProcessQueueData = createEffect(() => this.action$.pipe(
    ofType(loadProcessQueueAction),
    switchMap(a => this.api.getProcessQueueData()),
    map(data => loadProcessQueueSuccessAction({data})),
  ));

  resetProcessQueueData = createEffect(() => this.action$.pipe(
    ofType(resetProcessQueueAction),
    switchMap(({ids}) => this.api.postResetProcessQueue(ids)),
    mergeMap(() => [resetProcessQueueSuccessAction(), loadProcessQueueAction()]),
  ));

  deleteProcessQueueData = createEffect(() => this.action$.pipe(
    ofType(deleteProcessQueueAction),
    switchMap(({id}) => this.api.deleteProcessQueue(id)),
    mergeMap(() => [deleteProcessQueueSuccessAction(), loadProcessQueueAction()]),
  ));

  constructor(private action$: Actions, private api: ApiService) {
  }

}
