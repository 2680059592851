import { createReducer, createSelector, on } from '@ngrx/store';
import { createFormGroupState, updateGroup, validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { ocrActions } from '../actions/ocr.actions';
import { FormsState } from './forms.reducer';

export const OCR_ID = 'ocrForm';

export class OcrFormModel {
  companyId: string;
}

export const initialOcrFormState = createFormGroupState<OcrFormModel>(OCR_ID, {
  companyId: undefined,
});

// reducer

export const easyFormsOcrReducer = createReducer(
  initialOcrFormState,
  on(ocrActions.OcrFormResetAction, () => ({
    ...initialOcrFormState,
  }))
);


// validator

export const ocrFormValidator = updateGroup<OcrFormModel>({
  companyId: validate(required),
});

// selectors

export const getOcrFormState = createSelector(
  (s: any): FormsState => s.core.forms, // getCoreFormsState
  (s) => s?.ocrForm,
);

export const getOcrFormCompanyIdState = createSelector(
  getOcrFormState,
  (s) => s?.controls?.companyId,
);
