/**
 * Componente für den File Upload.
 */
import { Component, EventEmitter, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { Dictionary } from '@ngrx/entity';
import { FormArrayState, FormControlState } from 'ngrx-forms';
import { forkJoin, Observable, of } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { FileUploadModel } from '../../../core/models/file-upload.model';
import { TranslationKeys } from '../../../core/models/i18n';
import { FileUploadCategoryModel } from '../../models/file-upload-category.model';
import { I18nPipe } from '../../pipes/i18n.pipe';
import { rxDefaultTo } from '../../utils/reducer-utils';

@Component({
  selector: 'lib-common-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor, OnDestroy {
  /**
   * Standardtext für den Upload-Button, wird durch die i18n-Pipe verarbeitet
   */
  @Input() defaultText = 'attachFile';
  /**
   * MIME-Type die beim Upload vorausgewählt sind.
   */
  @Input() acceptedType = 'application/pdf';
  /**
   * Anzahl der max. zulässigen uploads. -1 = unbegrenzt. Bei 1 wird keine Tabelle dargestellt
   */
  @Input() maxFileCount = -1;
  /**
   * Kategorien die zur Auswahl stehen.
   */
  @Input() availableCategories: FileUploadCategoryModel[] = [];

  /**
   * Ob die Checkboxen zum Senden an den Leistungsempfänger angezeigt werden soll
   */
  @Input() showSendToBeneficiaryCheckboxes = false;

  /**
   * Nur eindeutige Dateien anhand des Dateinamens zulassen.
   */
  @Input() distinct = true;
  /**
   * Wenn [maxFileCount]="1" und [asString]="true", wird direkt der Base64-String statt eines Arrays ausgegeben
   */
  @Input() asString = false;

  @Input() required = false;

  @Input() disabled = false;

  @Input() placeholder = '';

  @Input() ngrxFormControlState: FormControlState<string> | FormArrayState<FileUploadModel>;

  @Input() sendToBeneficiaryFieldConfig: string;

  // TODO: strictImmutable
  files: (FileUploadModel[] | string) = [];
  buttonLabel = this.defaultText;

  checkboxPlaceholders: Dictionary<string> = {};
  checkboxMandatory: Dictionary<boolean> = {};
  checkboxVisible: Dictionary<boolean> = {};

  private onDestroyObservable = new EventEmitter();
  private onChangeObservable = new EventEmitter<FileUploadModel[] | string>();

  static isFileEqual(file1: FileUploadModel, file2: FileUploadModel) {
    return file1.fileName === file2.fileName && file1.content === file2.content;
  }

  constructor(private i18n: I18nPipe) {
  }

  // Function to call when the files changes.
  /**
   * Wenn von außen gesetzte Werte in eine andere Form verwandelt werden müssen, geschieht das hier.
   * Sind sie umgewandelt, wird der neue Wert wieder nach außen geworfen.
   * @param files Input
   */
  onChange(files: (FileUploadModel[] | string) = []) {
    return this.onChangeInternal(files);
  }

  removeFile(file: FileUploadModel, index: number) {
    if (!Array.isArray(this.files)) {
      return;
    }

    let newFiles;

    if (index < 0 || this.files.length === 1) {
      newFiles = this.files.filter(e => !FileUploadComponent.isFileEqual(e, file));
    } else {
      newFiles = this.files.filter((a, i) => i !== index);
    }
    if (newFiles.length === 0) {
      this.buttonLabel = this.defaultText;
    }
    this.onChangeInternal(newFiles, true);
  }

  addFile(fileName, content, category, size) {
    if (!Array.isArray(this.files)) {
      this.files = [];
    }

    const file = {
      fileName,
      content,
      category,
      size,
    };

    if (this.maxFileCount > 1 && this.distinct) {
      this.removeFile(file, -1);
    }

    if (this.maxFileCount === 1) {
      this.files = [file];
      this.buttonLabel = fileName;
    } else {
      this.files.push(file);
    }
    this.onChangeInternal(this.files, true);
  }

  changeFile(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        const result: string = <string>reader.result;
        this.addFile(file.name, result, '', file.size);
      };
    }

    // get next change event, even if its the same file again
    event.target.value = '';
    return null;
  }

  /*auch bei maxFileCount <= 0 soll bei einem string emittiert werden; die Validierung erfolgt später*/
  onChangeInternal(files: (FileUploadModel[] | string) = [], forceEmit = false) {
    this.files = files;
    if (typeof files === 'string') {
      if (this.asString && this.maxFileCount === 1) {
        this.onChangeObservable.emit(files);
      } else {
        this.onChangeObservable.emit([
          {
            content: files,
            fileName: undefined,
            category: undefined,
            size: files.length,
          },
        ]);
      }
    } else if (Array.isArray(files)) {
      if (this.asString && this.maxFileCount === 1) {
        const b64String = (files && files[0] && files[0].content) || '';
        this.onChangeObservable.emit(b64String);
      } else if (forceEmit) {
        this.onChangeObservable.emit(this.files);
      }
    }
  }

  registerOnChange(fn: (files: FileUploadModel[]) => void): void {
    this.onChangeObservable.pipe(
      takeUntil(this.onDestroyObservable),
    ).subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onChangeObservable.pipe(
      takeUntil(this.onDestroyObservable),
    ).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(files: FileUploadModel[] = []): void {
    this.onChange(files);
  }

  ngOnDestroy(): void {
    this.onDestroyObservable.emit();
    this.onDestroyObservable.complete();
  }

  getErrors() {
    return this.ngrxFormControlState?.errors;
  }

  isInvalidAndTouched() {
    return this.ngrxFormControlState?.isInvalid && this.ngrxFormControlState.isTouched;
  }

  isRequired() {
    const errors = this.getErrors();
    return errors && errors.required;
  }

  getButtonColor() {
    return this.isInvalidAndTouched() ? 'warn' : 'primary';
  }

  getErrorCategory(errorCategory: string): any {
    const errors = this.getErrors();
    return errors && errors[errorCategory];
  }

  getMissingFileUploadCategories(): Observable<string> {
    const errors = this.getErrors();
    const categories: string[] = errors && errors.missingFileUploadCategory;
    if (categories && categories.length > 0) {
      return forkJoin(categories.map((c) => this.i18n.transform(<TranslationKeys>c).pipe(
        take(1),
        rxDefaultTo(c),
      ))).pipe(
        map(v => v.join(', ')),
      );
    }
    return of();
  }

  getFileId = (file: FileUploadModel | string) => typeof file === 'string' ? file : file?.id + file?.fileName;

  updateCheckboxPlaceholder(file: FileUploadModel | string, $event: string) {
    const id = this.getFileId(file);
    return this.checkboxPlaceholders[id] = $event;
  }

  getPlaceholder(file: FileUploadModel | string) {
    const id = this.getFileId(file);
    return this.checkboxPlaceholders[id] || 'sendToBeneficiary';
  }

  updateCheckboxDefaultValue(chb: MatCheckbox, file: FileUploadModel | string, defaltValue: true) {
    if (typeof file !== 'string') {
      if (file.sendToBeneficiary === undefined) {
        file.sendToBeneficiary = defaltValue;
        this.onChangeInternal(this.files, true);
      }
    }
  }

  getCheckboxMandatory(file: FileUploadModel | string) {
    const id = this.getFileId(file);
    return this.checkboxMandatory[id];
  }

  updateCheckboxMandatory(file: FileUploadModel | string, mandatory: boolean) {
    const id = this.getFileId(file);
    return this.checkboxMandatory[id] = mandatory;
  }

  updateCheckboxEnabled(chb: MatCheckbox, enabled: boolean) {
    chb.disabled = !enabled;
  }

  updateCheckboxVisible(file: FileUploadModel | string, visible: boolean) {
    const id = this.getFileId(file);
    return this.checkboxVisible[id] = visible;
  }

  getCheckboxVisible(file: FileUploadModel | string) {
    const id = this.getFileId(file);
    return this.checkboxVisible[id] ?? true;
  }
}
