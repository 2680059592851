<mat-card fxFlex="100%">
  <mat-card-title>{{ 'representativesConfiguration' | i18n | async }}</mat-card-title>
  <mat-card-content>
    <lib-common-user-deputies
      (addApproverDeputy)="addApproverDeputy()"
      (addEditorDeputy)="addEditorDeputy()"
      (removeApproverDeputy)="removeApproverDeputy($event)"
      (removeEditorDeputy)="removeEditorDeputy($event)"
      [approverDeputies]="approverDeputies$ | async"
      [editorDeputies]="editorDeputies$ | async"
    ></lib-common-user-deputies>
    <div fxLayout="row wrap"
         fxLayoutAlign="center">
      <button
        type="submit"
        color="primary"
        mat-raised-button
        (click)="save()"
        class="save-margin"
      >
        <mat-icon
          class="svg-icon-floppy"
          svgIcon="innogy:floppy"
        ></mat-icon>
        {{ 'save' | i18n | async }}
      </button>
    </div>
  </mat-card-content>
</mat-card>
