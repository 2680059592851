import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormControlState, FormGroupState } from 'ngrx-forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SetValueTraceableAction } from '../../../core-lib/models/set-value-traceable-action';
import { ConfigListModel } from '../../models/config-list.model';
import { CoreConfigSalutationsSave, DeleteConfigItemAction } from '../../ngrx/actions/core-config.actions';
import { initialConfigListValue, SALUTATIONS_FORM_ID } from '../../ngrx/reducers/config.reducer';
import { CoreFeatureState, getConfigSalutationsControlsArrayState, getConfigSalutationsState } from '../../ngrx/reducers/core.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-route-config-salutations',
  templateUrl: './route-config-salutations.component.html',
  styleUrls: ['./route-config-salutations.component.scss'],
})
export class RouteConfigSalutationsComponent implements OnInit, OnDestroy {
  private componentDestroyed$ = new Subject<void>();
  currentSalutationList = undefined;

  salutationList$ = this.store$.select(getConfigSalutationsControlsArrayState);
  salutationListForm$ = this.store$.select(getConfigSalutationsState);
  formValid$ = this.salutationListForm$.pipe(
    select('isValid'),
  );

  constructor(private store$: Store<CoreFeatureState>) {
  }

  ngOnInit(): void {
    this.salutationList$.pipe(
      takeUntil(this.componentDestroyed$),
    ).subscribe(salutationList => this.currentSalutationList = salutationList);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  getControlId = (t: FormGroupState<ConfigListModel>) => t.id;

  resetLangLabel(salutation, $event: FormControlState<string>) {
    this.store$.dispatch(new SetValueTraceableAction($event.id, salutation.value.value));
  }

  addSalutation() {
    if (this.currentSalutationList) {
      const newValue = [
        ...this.currentSalutationList.map(x => x.value),
        initialConfigListValue,
      ];
      this.store$.dispatch(new SetValueTraceableAction(SALUTATIONS_FORM_ID, newValue));
    }
  }

  removeSalutation(salutation: FormGroupState<ConfigListModel>) {
    const newValue = [
      ...this.currentSalutationList.filter(x => this.getControlId(x) !== this.getControlId(salutation)).map(x => x.value),
    ];
    this.store$.dispatch(new SetValueTraceableAction(SALUTATIONS_FORM_ID, newValue));
    this.store$.dispatch(new DeleteConfigItemAction(salutation.value.id));
  }

  saveSalutation() {
    this.store$.dispatch(new CoreConfigSalutationsSave());
  }
}
