import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControlState } from 'ngrx-forms';
import { RouteConfigFormPropertyComponent } from '../../../core/components/route-config-form-property/route-config-form-property.component';
import { CoreFeatureState } from '../../../core/ngrx/reducers/core.store';
import { ConfigItemTextInputConfigurationDtoModel } from '../../models/config-item-text-input-configuration-dto.model';
import { SetValueTraceableAction } from '../../models/set-value-traceable-action';
import { InputPropertyTypeComponent } from '../input-property-type/input-property-type.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-input-property-type-string',
  templateUrl: './input-property-type-string.component.html',
  styleUrls: ['./input-property-type-string.component.scss'],
})
export class InputPropertyTypeStringComponent extends InputPropertyTypeComponent {

  typeName = ConfigItemTextInputConfigurationDtoModel.TYPENAME;

  @Input() ngrxPredefinedValue: FormControlState<string>;
  @Input() ngrxMinLength: FormControlState<number>;
  @Input() ngrxMaxLength: FormControlState<number>;
  @Input() ngrxRegex: FormControlState<string>;

  constructor(store$: Store<CoreFeatureState>, util: RouteConfigFormPropertyComponent) {
    super(store$, util);
  }

  internalReloadValues(lastConfigItemValue) {
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxRegex.id, lastConfigItemValue.regex));
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxMaxLength.id, lastConfigItemValue.maxLength));
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxPredefinedValue.id, lastConfigItemValue.predefinedValue));
  }

}
