export class AccountDeputiesDeputyFormModel {
  id: string = undefined;
  deputyUserId: string = undefined;
  state = true;
  type: 'EDIT' | 'APPROVAL' = undefined;
}

export class AccountDeputiesEditorFormModel extends AccountDeputiesDeputyFormModel {
  type: 'EDIT' = 'EDIT';
}

export class AccountDeputiesApproverFormModel extends AccountDeputiesDeputyFormModel {
  type: 'APPROVAL' = 'APPROVAL';
}

export class AccountDeputiesFormModel {
  editors: AccountDeputiesEditorFormModel[] = [
    new AccountDeputiesEditorFormModel(),
  ];
  approvers: AccountDeputiesApproverFormModel[] = [
    new AccountDeputiesApproverFormModel(),
  ];
}
