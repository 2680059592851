import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { box, Boxed, FormGroupState, NgrxValueConverter } from 'ngrx-forms';
import { combineLatest, from, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, switchMap, takeUntil, toArray, withLatestFrom } from 'rxjs/operators';
import { backendClasses } from '../../../core-lib/models/backend-classes.model';
import { CompanyDataDtoModel } from '../../../core-lib/models/company-data-dto.model';
import { ConfigDtoModel, ConfigurationItems } from '../../../core-lib/models/config-dto.model';
import { ConfigItemTranslationsDtoModel } from '../../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemType, ConfigItemUnionType } from '../../../core-lib/models/config-list-dto.model';
import { EasyFormModel } from '../../../core-lib/models/easy-form.model';
import { I18nPipe } from '../../../core-lib/pipes/i18n.pipe';
import { EasyFormConfigItemsFactoryService } from '../../../core-lib/utils/easy-form-config-factory';
import { FormConfigIdentityModel } from '../../models/form-config-identity.model';
import { TranslationKeys } from '../../models/i18n';
import {
  CoreEasyFormsDeinheritFormConfigAction,
  CoreEasyFormsInheritFormConfigAction,
  CoreEasyFormsLoadFormConfigAction,
  CoreEasyFormsSaveFormConfigAction,
} from '../../ngrx/actions/core-easy-forms.actions';
import { getCompaniesState, getCompanyByDynamicShortNameState } from '../../ngrx/reducers/core.store';
import {
  getEasyFormConfigsSelectedItemPropStateFn,
  getEasyFormConfigsSelectedStateFn,
} from '../../ngrx/reducers/easy-form-configs.reducer';
import { getEasyFormsArrayElementByDynamicIdentifierState } from '../../ngrx/reducers/easy-forms.reducer';
import { getRouterParams } from '../../ngrx/reducers/router.reducer';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-route-config-form',
  templateUrl: './route-config-form.component.html',
  styleUrls: ['./route-config-form.component.scss'],
})
export class RouteConfigFormComponent implements OnDestroy {
  destroy$ = new EventEmitter();

  constructor(
    private store$: Store<any>,
    private i18n: I18nPipe,
    private activatedRoute: ActivatedRoute,
    private metaConfigService: EasyFormConfigItemsFactoryService,
  ) {
    combineLatest([this.companyShort$, this.form$, this.company$]).pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(([companyShort1, form1, company1], [companyShort2, form2, company2]) => (
        company1 === company2
        && companyShort1 === companyShort2
        && form1 === form2
      )),
      filter(([companyShort, form, company]) => !!(
        companyShort
        && company
        && form
      )),
      map(([companyShort, form, company]) => (
        {
          formIdentifier: this.formIdentifier,
          companyShort,
          companyId: company.id,
          formId: form.id,
        }
      )),
    ).subscribe(formConfigIdentity => {
      this.store$.dispatch(new CoreEasyFormsLoadFormConfigAction(formConfigIdentity));
      this.defaultFormConfigIdentity = formConfigIdentity;
    });
    this.otherFormCompanies$.subscribe((companies) => this.companies = companies);
  }

  routeParams$ = this.store$.select(getRouterParams);
  formIdentifier = this.activatedRoute.snapshot.params.formKey;
  nameFilter: string;
  templateConfigArray = Object.entries(this.metaConfigService.create(this.formIdentifier));
  companyShort$: Observable<string> = this.routeParams$.pipe(
    select('companyShort'),
    distinctUntilChanged(),
  );

  selectedEasyConfig$: Observable<FormGroupState<ConfigDtoModel<ConfigurationItems>>> = this.store$.select(
    getEasyFormConfigsSelectedStateFn).pipe(
    map((s) => s),
  );

  getConfigProp$ = ((itemName: keyof ConfigurationItems, propName: keyof ConfigItemUnionType) =>
    this.store$.select(getEasyFormConfigsSelectedItemPropStateFn, {itemName, propName}));

  parentIdControl$ = this.selectedEasyConfig$.pipe(
    map(config => config.controls),
    map(config => config.parent),
  );

  isParentSet$ = this.selectedEasyConfig$.pipe(
    filter(config => !!(config && config.value)),
    map(config => !!(config.value.parent && config.value.parent.value)),
  );

  formByIdentifierFn$: Observable<(id: string) => EasyFormModel> = this.store$.select(getEasyFormsArrayElementByDynamicIdentifierState);
  companyByShortFn$: Observable<(shortName: string) => CompanyDataDtoModel> = this.store$.select(getCompanyByDynamicShortNameState);

  mapGetByString = map(([xByIdentifierFn, xIdentifier]) => xByIdentifierFn(xIdentifier));

  backendClasses = backendClasses;

  form$: Observable<EasyFormModel> = this.formByIdentifierFn$.pipe(
    map((formByIdentifierFn) => [formByIdentifierFn, this.formIdentifier]),
    this.mapGetByString,
  );

  formName$ = this.form$.pipe(
    map(form => form && form.name),
  );

  company$ = this.companyByShortFn$.pipe(
    withLatestFrom(this.companyShort$),
    this.mapGetByString,
  );

  allCompanies$ = this.store$.select(getCompaniesState);

  otherFormCompanies$: Observable<CompanyDataDtoModel[]> = this.form$.pipe(
    takeUntil(this.destroy$),
    filter(f => !!(f && f.companies)),
    withLatestFrom(this.allCompanies$, this.companyShort$),
    map(([form, allCompanies, companyShort]: [EasyFormModel, CompanyDataDtoModel[], string]) =>
      form.companies
        .map(compId => allCompanies.find(c => c && c.id === compId))
        .filter(c => c.shortName !== companyShort),
    ),
  );
  companies: CompanyDataDtoModel[] = [];

  defaultFormConfigIdentity: FormConfigIdentityModel;

  inheritFormConfig($event) {
    const parentConfig: FormConfigIdentityModel = {
      formIdentifier: this.formIdentifier,
      companyShort: $event.value,
      formId: '',
      companyId: '',
    };

    if ($event.value) {
      this.store$.dispatch(new CoreEasyFormsInheritFormConfigAction(parentConfig));
    } else {
      this.store$.dispatch(new CoreEasyFormsDeinheritFormConfigAction(parentConfig));
    }
  }

  getConfigArray$(): Observable<{ name: keyof ConfigurationItems, metaConfig: ConfigItemType, class: string }[]> {
    return of(this.templateConfigArray).pipe(
      map((array) => array.filter(([_name, metaConfig]) => !!metaConfig).map(([name, metaConfig]) => ({
        name,
        metaConfig,
        class: metaConfig['@class'],
      }))),
      switchMap((array) => combineLatest(array.map(e => this.i18n.transform(<TranslationKeys>(e.name.toString())))).pipe(
        map((translations) => array.map((element, index) => ({
          ...element,
          i18nName: translations[index] || '',
        }))),
      )),
      // mergeMap to filter if any translation matches this.nameFilter
      mergeMap((array) => from(array).pipe(
        // mergeMap to inject actual configuration, not just the meta easyConfig, to get the user-translations
        mergeMap(e => of(e).pipe(
          withLatestFrom(
            this.i18n.transform(<TranslationKeys>(e.metaConfig.$$typeName)),
            this.getConfigProp$(e.i18nName, 'translations'),
            (element, translatedTypeName, concreteConfigTranslations: FormGroupState<ConfigItemTranslationsDtoModel>) => ({
              element,
              translatedTypeName: translatedTypeName || '',
              concreteConfigTranslations,
            }),
          ),
        )),
        filter(({element, translatedTypeName, concreteConfigTranslations}) => {
            return !this.nameFilter
              || (element.i18nName.toLocaleLowerCase()).indexOf(this.nameFilter.toLocaleLowerCase()) >= 0
              || (element.name.toLocaleLowerCase()).indexOf(this.nameFilter.toLocaleLowerCase()) >= 0
              || (translatedTypeName.toLocaleLowerCase()).indexOf(this.nameFilter.toLocaleLowerCase()) >= 0
              || (
                concreteConfigTranslations && Object.values(concreteConfigTranslations.value)
                  .reduce(
                    (acc, t) => acc || (t && (t && t.text) || '').toLocaleLowerCase().indexOf(
                      this.nameFilter.toLocaleLowerCase(),
                    ) >= 0,
                    false,
                  )
              );
          },
        ),
        map(({element}) => element),
        toArray(),
      )),
      map((array) => array.map(element => ({
        name: element.name,
        metaConfig: element.metaConfig,
        class: element.class,
      }))),
    );
  }

  getCategorizedConfigArray$(): Observable<{
    key: string,
    displayName: string,
    elements: { name: keyof ConfigurationItems, metaConfig: ConfigItemType, class: string }[]
  }[]> {
    return this.getConfigArray$().pipe(
      map((configArray) => {
        // categorize
        const categoryObject = configArray.reduce((acc, curr) => {
          const categoryId = curr.metaConfig.$$category.id || 'uncategorized';
          const category = acc[categoryId] || [];
          return {
            ...acc,
            [categoryId]: [
              ...category,
              curr,
            ],
          };
        }, {});

        // format, sort
        return Object.keys(categoryObject)
          .reduce((acc, key) => (
            [
              ...acc,
              {
                key,
                displayName: categoryObject[key][0].metaConfig.$$category.displayName,
                elements: categoryObject[key],
              },
            ]
          ), [])
          .sort((a, b) => a.key.localeCompare(b.key));
      }),
    );
  }

  getConverter(): NgrxValueConverter<string, Boxed<ConfigDtoModel<any>>> & { companies: any[] } {
    return {
      companies: this.companies,
      convertStateToViewValue(stateValue: Boxed<ConfigDtoModel<any>>): string {
        const find = this.companies.find(c => stateValue && stateValue.value && c.id === stateValue.value.company);
        return find ? find.shortName : '';
      },
      convertViewToStateValue(viewValue: string): Boxed<ConfigDtoModel<any>> {
        const find = this.companies.find(c => c.shortName === viewValue);
        return find ? box(<ConfigDtoModel<any>>{id: find.id}) : undefined;
      },
    };
  }

  /**
   * true wenn jedes $$displayCategory gesetzt ist, sonst false
   */
  isCategorizedConfigArray$() {
    return this.getConfigArray$().pipe(
      map((array) => !array.find((curr) => !curr.metaConfig.$$category.id)),
    );
  }

  saveFormConfig() {
    this.store$.dispatch(new CoreEasyFormsSaveFormConfigAction());
  }

  trackByKey(x) {
    return x.key;
  }

  ngOnDestroy(): void {
    this.destroy$.emit();
    this.destroy$.complete();
  }
}
