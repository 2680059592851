import { box, Boxed } from 'ngrx-forms';
import { backendClasses } from './backend-classes.model';
import { ConfigItemConfigurationDtoModel } from './config-item-configuration-dto.model';
import { FileUploadCategoryModel } from './file-upload-category.model';
import { FileUploadTypeModel } from './file-upload-type.model';

export class ConfigItemFileUploadConfigurationDtoModel extends ConfigItemConfigurationDtoModel {
  static readonly CLASSNAME = backendClasses.fileUploadConfiguration;
  static TYPENAME = 'typeFileUpload';
  $$typeName = ConfigItemFileUploadConfigurationDtoModel.TYPENAME;
  '@class' = ConfigItemFileUploadConfigurationDtoModel.CLASSNAME;
  max: number = undefined;
  min: number = undefined;
  uploadType: FileUploadTypeModel = undefined;
  requiredCategories: Boxed<(FileUploadCategoryModel)[]> = box([]);
  availableCategories: Boxed<(FileUploadCategoryModel)[]>
    = box(<(FileUploadCategoryModel)[]>Object.keys(FileUploadCategoryModel));
}
