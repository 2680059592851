import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CoreLibModule } from '../core-lib/core-lib.module';
import { SessionDisplayComponent } from './components/session-display/session-display.component';
import { SessionGreetingComponent } from './components/session-greeting/session-greeting.component';
import { SessionSpinnerComponent } from './components/session-spinner/session-spinner.component';
import { SessionEffects } from './effects/session.effects';
import * as fromSession from './reducers/session.reducer';
import { LoginInterceptorService } from './services/login-interceptor.service';
import { UrlsService } from './services/urls.service';

@NgModule({
  declarations: [
    SessionDisplayComponent,
    SessionGreetingComponent,
    SessionSpinnerComponent,
  ],
  imports: [
    CommonModule,
    CoreLibModule,
    FontAwesomeModule,
    StoreModule.forFeature('session', fromSession.reducer),
    EffectsModule.forFeature([SessionEffects]),
    RouterModule,
    OverlayModule,
  ],
  exports: [
    SessionDisplayComponent,
    SessionGreetingComponent,
    SessionSpinnerComponent,
  ],
  providers: [
    UrlsService,
    {provide: HTTP_INTERCEPTORS, useClass: LoginInterceptorService, multi: true},
  ],
})
export class SessionModule {
}
