<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Meine Daten">
    <form
      class="margin-bottom"
      (ngSubmit)="sendData()"
      [ngrxFormState]="accountState$ | async"
    >
      <lib-common-form-flex-container>
        <div class="form-style">
          <mat-card>
            <mat-card-title>{{ 'company' | i18n | async }}</mat-card-title>
            <mat-card-content>
              <div
                fxLayout="row wrap"
                fxLayoutAlign="space-between"
                fxLayoutGap="10px grid"
              >
                <mat-form-field
                  fxFlex.gt-md="20%"
                  fxFlex.lt-lg="100%"
                >
                  <mat-label>{{ 'defaultCompany' | i18n | async }} *</mat-label>
                  <mat-select [ngrxFormControlState]="getFormControlState$('defaultCompany') | async">
                    <mat-option
                      *ngFor="let company of (companiesState$ | async)"
                      [value]="company"
                    >
                      {{ company.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <lib-common-region-service-center-selector
                *ngIf="enableNetRegion"
                [options]="netRegions$ | async"
                [ngrxNetRegion]="getFormControlState$('defaultNetRegion') | async"
                [ngrxServiceCenter]="getFormControlState$('defaultServiceCenter') | async"
                [ngrxAccount]="getFormControlState$('defaultNetRegionAccount') | async"
              ></lib-common-region-service-center-selector>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="form-style">
          <mat-card>
            <mat-card-title>{{ 'contactDetails' | i18n | async }}</mat-card-title>
            <mat-card-content>
              <div
                fxLayout="row wrap"
                fxLayoutAlign="flex-start"
                fxLayoutGap="20px grid"
              >
                <mat-form-field
                  *ngIf="salutationsLoaded$ | async"
                  fxFlex.gt-md="20%"
                  fxFlex.lt-lg="100%"
                >
                  <mat-label>{{ 'salutation' | i18n | async }}</mat-label>
                  <mat-select
                    [ngrxFormControlState]="getFormControlState$('salutation') | async"
                    required
                  >
                    <mat-option
                      *ngFor="let salutation of (formSalutationControl$ | async)"
                      [value]="salutation"
                    >
                      {{ salutation.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field
                  fxFlex.gt-md="37%"
                  fxFlex.lt-lg="100%"
                >
                  <mat-label>{{ 'firstName' | i18n | async }} *</mat-label>
                  <input
                    [ngrxFormControlState]="getFormControlState$('firstName') | async"
                    matInput
                    [placeholder]="'firstName' | i18n | async"
                    type="text"
                    disabled
                  >
                </mat-form-field>
                <mat-form-field
                  fxFlex.gt-md="37%"
                  fxFlex.lt-lg="100%"
                >
                  <mat-label>{{ 'lastName' | i18n | async }} *</mat-label>
                  <input
                    [ngrxFormControlState]="getFormControlState$('lastName') | async"
                    matInput
                    [placeholder]="'lastName' | i18n | async"
                    type="text"
                    disabled
                  >
                </mat-form-field>
              </div>
            </mat-card-content>

            <mat-card-content>
              <div
                fxLayout="row wrap"
                fxLayoutAlign="flex-start"
                fxLayoutGap="20px grid"
              >
                <mat-form-field
                  fxFlex.gt-md="57%"
                  fxFlex.lt-lg="100%"
                >
                  <input
                    [ngrxFormControlState]="getFormControlState$('street') | async"
                    matInput
                    [placeholder]="'street' | i18n | async"
                    type="text"
                  >
                </mat-form-field>
              </div>
            </mat-card-content>

            <mat-card-content>
              <div
                fxLayout="row wrap"
                fxLayoutAlign="flex-start"
                fxLayoutGap="20px grid"
              >
                <mat-form-field
                  fxFlex.gt-md="57%"
                  fxFlex.lt-lg="100%"
                >
                  <input
                    [ngrxFormControlState]="getFormControlState$('location') | async"
                    matInput
                    [placeholder]="'place' | i18n | async"
                    type="text"
                  >
                </mat-form-field>

                <mat-form-field
                  fxFlex.gt-md="37%"
                  fxFlex.lt-lg="100%"
                >
                  <input
                    [ngrxFormControlState]="getFormControlState$('postcode') | async"
                    matInput
                    [placeholder]="'plz' | i18n | async"
                    type="text"
                  >
                </mat-form-field>
              </div>
            </mat-card-content>

            <mat-card-content>
              <div
                fxLayout="row wrap"
                fxLayoutAlign="flex-start"
                fxLayoutGap="20px grid"
              >
                <mat-form-field
                  fxFlex.gt-md="57%"
                  fxFlex.lt-lg="100%"
                >
                  <mat-label>{{ 'country' | i18n | async }}</mat-label>
                  <mat-select
                    [ngrxFormControlState]="getFormControlState$('country') | async"
                  >
                    <mat-option
                      *ngFor="let country of countries" [value]="country.value"
                    >
                      {{ country.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-card-content>

            <mat-card-content>
              <div
                fxLayout="row wrap"
                fxLayoutAlign="flex-start"
                fxLayoutGap="20px grid"
              >
                <mat-form-field
                  fxFlex.gt-md="57%"
                  fxFlex.lt-lg="100%"
                >
                  <input
                    [ngrxFormControlState]="getFormControlState$('email') | async"
                    matInput
                    [placeholder]="'mail' | i18n | async"
                    type="text"
                    disabled
                  >
                </mat-form-field>
                <mat-form-field
                  fxFlex.gt-md="37%"
                  fxFlex.lt-lg="100%"
                >
                  <input
                    [ngrxFormControlState]="getFormControlState$('telephone') | async"
                    matInput
                    [placeholder]="'phone' | i18n | async"
                    type="text"
                  >
                </mat-form-field>
              </div>
            </mat-card-content>

            <mat-card-content>
              <div
                fxLayout="row wrap"
                fxLayoutAlign="flex-start"
                fxLayoutGap="20px grid"
              >
                <mat-form-field
                  fxFlex.gt-md="57%"
                  fxFlex.lt-lg="100%"
                >
                  <input
                    [ngrxFormControlState]="getFormControlState$('department') | async"
                    matInput
                    [placeholder]="'department' | i18n | async"
                    type="text"
                  >
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="form-style">
          <mat-card>
            <mat-card-title>{{ 'bankDetails' | i18n | async }}</mat-card-title>
            <mat-card-content>
              <div
                fxLayout="row wrap"
                fxLayoutAlign="flex-start"
                fxLayoutGap="20px grid"
              >
                <mat-form-field
                  fxFlex.gt-md="50%"
                  fxFlex.lt-lg="100%"
                >
                  <input
                    [ngrxFormControlState]="getFormControlState$('iban') | async"
                    matInput
                    [placeholder]="'iban' | i18n | async"
                    type="text"
                  >
                </mat-form-field>
                <mat-form-field
                  fxFlex.gt-md="48%"
                  fxFlex.lt-lg="100%"
                >
                  <input
                    [ngrxFormControlState]="getFormControlState$('bic') | async"
                    matInput
                    [placeholder]="'bic' | i18n | async"
                    type="text"
                  >
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxFlex.gt-md="50%"
             fxFlex.lt-lg="100%">
          <mat-card class="costcenter-card-height">
            <mat-card-title>{{ 'costCenter' | i18n | async }}
            </mat-card-title>
            <mat-card-content
              fxLayout="column"
              *ngIf="companiesLoaded$ |async"
            >
              <div
                fxLayout="row nowrap"
                fxLayoutAlign="flex-start"
                fxLayoutGap="20px grid"
                *ngFor="let costCenter of (costCenterState$ | async); trackBy: getControlId"
              >
                <mat-form-field
                  fxFlex.gt-md="45%"
                  fxFlex.lt-lg="100%"
                >
                  <mat-label>{{ 'company' | i18n | async }} *</mat-label>
                  <mat-select [ngrxFormControlState]="costCenter.controls.company">
                    <mat-option
                      *ngFor="let company of (getCompanyValues(costCenter)| async)"
                      [value]="company.id"
                    >
                      {{ company.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field
                  fxFlex.gt-md="45%"
                  fxFlex.lt-lg="100%"
                >
                  <input
                    [ngrxFormControlState]="costCenter.controls.costCenter"
                    matInput
                    [placeholder]="'costCenter' | i18n | async"
                    type="text"
                  >
                </mat-form-field>
                <div
                  fxFlex.gt-md="10%"
                  fxFlex.lt-lg="100%"
                >
                  <button
                    type="button"
                    mat-mini-fab
                    color="accent"
                    (click)="removeCostCenter(costCenter)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
              <button
                type="button"
                color="primary"
                mat-mini-fab
                (click)="addCostCenter()"
              >
                <mat-icon svgIcon="innogy:plus"></mat-icon>
              </button>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxFlex.gt-md="50%"
             fxFlex.lt-lg="100%">
          <mat-card class="costcenter-card-height">
            <mat-card-title>{{ 'approver' | i18n | async }}</mat-card-title>
            <mat-card-content fxLayout="column">
              <lib-common-user-list
                [approverControls]="defaultArrangerState$ | async"
                (addClick)="addDefaultArranger()"
                maxSize="2"
                [removable]="true"
                (removeClick)="removeDefaultArranger($event)"
                direction="column"
              [valueConverter]="approverValueConverter"
              [hasValueFn]="arrangerHasValue"
              addButtonColor="primary"
            ></lib-common-user-list>
          </mat-card-content>
        </mat-card>
      </div>
      </lib-common-form-flex-container>
      <div class="text-center">
        <button
          type="submit"
          color="primary"
          mat-raised-button
        >
          <mat-icon
            class="svg-icon-floppy"
            svgIcon="innogy:floppy"
          ></mat-icon>
          {{ 'save' | i18n | async }}
        </button>
      </div>
    </form>
  </mat-tab>
  <mat-tab label="{{ 'representativesConfiguration' | i18n | async }}">
    <lib-common-form-flex-container>
      <div fxFlex="100%">
        <lib-common-account-deputies>
        </lib-common-account-deputies>
      </div>
    </lib-common-form-flex-container>
  </mat-tab>
</mat-tab-group>

