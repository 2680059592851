import { ConfigurationItems } from '../../core-lib/models/config-dto.model';
import { ConfigItemAccountingConfigurationDtoModel } from '../../core-lib/models/config-item-accounting-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../core-lib/models/config-item-arranger-list-configuration-dto.model';
import { ConfigItemCheckboxConfigurationDtoModel } from '../../core-lib/models/config-item-checkbox-configuration-dto.model';
import { ConfigItemConfigurationCategoryModel } from '../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemDateConfigurationDtoModel } from '../../core-lib/models/config-item-date-configuration-dto.model';
import { ConfigItemFileUploadConfigurationDtoModel } from '../../core-lib/models/config-item-file-upload-configuration-dto.model';
import { ConfigItemLedgerAccountConfigurationDtoModel } from '../../core-lib/models/config-item-ledger-account-configuration-dto.model';
import { ConfigItemListConfigurationDtoModel } from '../../core-lib/models/config-item-list-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../../core-lib/models/config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../../core-lib/models/config-item-text-input-configuration-dto.model';
import { ConfigListDtoModel } from '../../core-lib/models/config-list-dto.model';
import { PayeeDtoModel } from '../../forms/all-forms/models/payee-dto.model';
import { FormAF01DtoModel } from '../../forms/form-af01/models/form-af01-dto.model';
import { EqualKeyNames } from './equal-key-names';

export const EasyFormAFSteps = {
  step1: {
    id: 'step1',
    displayName: 'Gesellschaft',
  } as ConfigItemConfigurationCategoryModel,
  step2: {
    id: 'step2',
    displayName: 'Zahlungsempfänger',
  } as ConfigItemConfigurationCategoryModel,
  step3: {
    id: 'step3',
    displayName: 'Rechnungsangaben',
  } as ConfigItemConfigurationCategoryModel,
};

// ToDo: Check ob dieses Config-Model auch via FormA1Step1,FormA1Step2,FormA1Step3 bzw FormA1State erzeugt werden kann.
export class EasyFormAFConfigItems extends ConfigurationItems implements EqualKeyNames<FormAF01DtoModel>, EqualKeyNames<PayeeDtoModel> {
  payeeNumber = new ConfigItemTextInputConfigurationDtoModel('payeeNumber').withCategory(EasyFormAFSteps.step2);

  // PayeeDtoModel
  salutation = new ConfigItemTextInputConfigurationDtoModel('salutation').withCategory(EasyFormAFSteps.step2);
  name = new ConfigItemTextInputConfigurationDtoModel('name').withCategory(EasyFormAFSteps.step2);
  nameAddition = new ConfigItemTextInputConfigurationDtoModel('nameAddition').withCategory(EasyFormAFSteps.step2);
  street = new ConfigItemTextInputConfigurationDtoModel('street').withCategory(EasyFormAFSteps.step2);
  zipCode = new ConfigItemTextInputConfigurationDtoModel('zipCode').withCategory(EasyFormAFSteps.step2);
  city = new ConfigItemTextInputConfigurationDtoModel('city').withCategory(EasyFormAFSteps.step2);
  country = new ConfigItemTextInputConfigurationDtoModel('country').withCategory(EasyFormAFSteps.step2);
  email = new ConfigItemTextInputConfigurationDtoModel('email').withCategory(EasyFormAFSteps.step2);
  iban = new ConfigItemTextInputConfigurationDtoModel('iban').withCategory(EasyFormAFSteps.step2);
  bic = new ConfigItemTextInputConfigurationDtoModel('bic').withCategory(EasyFormAFSteps.step2);

  // Verwendungszweck
  description = new ConfigItemTextInputConfigurationDtoModel('description').withCategory(EasyFormAFSteps.step3);
  useCase = new ConfigItemTextInputConfigurationDtoModel('useCase').withCategory(EasyFormAFSteps.step3);
  numberOfPersons = new ConfigItemNumberConfigurationDtoModel('numberOfPersons').withCategory(EasyFormAFSteps.step3);
  date = new ConfigItemDateConfigurationDtoModel('date').withCategory(EasyFormAFSteps.step3);
  leaflet = new ConfigItemCheckboxConfigurationDtoModel('leaflet').withCategory(EasyFormAFSteps.step3);

  // positions
  positions = new ConfigListDtoModel('positions').withCategory(EasyFormAFSteps.step3);
  positionName = new ConfigItemTextInputConfigurationDtoModel('positionName').withCategory(EasyFormAFSteps.step3);
  netCost = new ConfigItemNumberConfigurationDtoModel('netCost').withCategory(EasyFormAFSteps.step3);
  taxRate = new ConfigItemListConfigurationDtoModel('taxRate', 'taxrates').withCategory(EasyFormAFSteps.step3);
  grossCost = new ConfigItemNumberConfigurationDtoModel('grossCost').withCategory(EasyFormAFSteps.step3);
  taxValue = new ConfigItemNumberConfigurationDtoModel('taxValue').withCategory(EasyFormAFSteps.step3);
  account = new ConfigItemAccountingConfigurationDtoModel('account').withCategory(EasyFormAFSteps.step3);
  ledgerAccount = new ConfigItemLedgerAccountConfigurationDtoModel('ledgerAccount').withCategory(EasyFormAFSteps.step3);

  // payout
  payout = new ConfigItemNumberConfigurationDtoModel('payout').withCategory(EasyFormAFSteps.step3);

  // Teilnehmerliste
  participants = new ConfigListDtoModel('participants').withCategory(EasyFormAFSteps.step3);
  participantName = new ConfigItemTextInputConfigurationDtoModel('participantName').withCategory(EasyFormAFSteps.step3);
  participantCompanyName = new ConfigItemTextInputConfigurationDtoModel('participantCompanyName').withCategory(EasyFormAFSteps.step3);
  isEmployee = new ConfigItemCheckboxConfigurationDtoModel('isEmployee').withCategory(EasyFormAFSteps.step3);
  employeeInfo = new ConfigItemTextInputConfigurationDtoModel('employeeInfo').withCategory(EasyFormAFSteps.step3);
  participantCompany = new ConfigItemTextInputConfigurationDtoModel('participantCompany').withCategory(EasyFormAFSteps.step3);
  participantUser = new ConfigItemTextInputConfigurationDtoModel('participantUser').withCategory(EasyFormAFSteps.step3);


  // Genehmigungsstufen
  approver = new ConfigItemArrangerListConfigurationDtoModel('approver').withCategory(EasyFormAFSteps.step3);
  inspector = new ConfigItemArrangerListConfigurationDtoModel('inspector').withCategory(EasyFormAFSteps.step3);
  commentForApprover = new ConfigItemTextInputConfigurationDtoModel('commentForApprover').withCategory(EasyFormAFSteps.step3);

  file = new ConfigItemFileUploadConfigurationDtoModel('file').withCategory(EasyFormAFSteps.step3);
}
