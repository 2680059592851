import { createAction, props } from '@ngrx/store';
import { PageableModel } from '../../../core-lib/models/pageable.model';
import { ProposalHeadModel } from '../../../core-lib/models/proposal-head.model';
import { SortFilterPageModel } from '../../../core-lib/models/sort-filter-page.model';
import { TableColumnConfigModel } from '../../../core-lib/models/table-column-config.model';
import { TableFilterConfigModel } from '../../../core-lib/models/table-filter-config.model';
import { TableSelectionModel } from '../../models/table-selection.model';

export const selectionProps = () => props<SelectionsProps>();

interface SelectionsProps {
  selection: keyof TableSelectionModel<any>;
}

export class TableActionCreator {

  constructor(protected tableType: string) {
  }

  tableLoad = createAction(`core/${this.tableType}/table/load`, props<SortFilterPageModel>());

  tableLoaded = createAction(
    `core/${this.tableType}/table/loaded`,
    props<SelectionsProps & PageableModel<ProposalHeadModel>>(),
  );

  tableConfigLoad = createAction(
    `core/${this.tableType}/table/config/load`,
    selectionProps(),
  );

  tableColumnConfigLoaded = createAction(
    `core/${this.tableType}/table/config/column/loaded`,
    props<SelectionsProps & { data: TableColumnConfigModel[] }>(),
  );
  tableFilterConfigLoaded = createAction(
    `core/${this.tableType}/table/config/filter/loaded`,
    props<SelectionsProps & { data: TableFilterConfigModel[] }>(),
  );

  tableConfigSave = createAction(
    `core/${this.tableType}/table/config/save`,
    props<{ columnConfig: TableColumnConfigModel[], filterConfig: TableFilterConfigModel[], tableName: keyof TableSelectionModel<any> }>(),
  );

  tableSendAction = createAction(
    `core/${this.tableType}/table/SendAction`
    , props<{ publicId: string, config: SortFilterPageModel }>());

  tableDeleteAction = createAction(
    `core/${this.tableType}/table/DeleteAction`
    , props<{ publicId: string, config: SortFilterPageModel }>());

  tableApproveAction = createAction(
    `core/${this.tableType}/table/ApproveAction`,
    props<SelectionsProps & { publicId: string, config: SortFilterPageModel }>(),
  );
  tableDeclineAction = createAction(
    `core/${this.tableType}/table/DeclineAction`,
    props<SelectionsProps & { publicId: string, comment: string, config: SortFilterPageModel }>(),
  );

  tableApproveMultipleAction = createAction(
    `core/${this.tableType}/table/ApproveMultipleAction`,
    props<SelectionsProps & { publicId: string, config: SortFilterPageModel }>(),
  );
  tableDeclineMultipleAction = createAction(
    `core/${this.tableType}/table/DeclineMultipleAction`,
    props<SelectionsProps & { publicId: string, comment: string, config: SortFilterPageModel }>(),
  );

  tableRecallAction = createAction(
    `core/${this.tableType}/table/tableTotalRecallAction`
    , props<{ publicId: string, config: SortFilterPageModel }>());

  tableExport = createAction(`core/${this.tableType}/table/export`, props<SortFilterPageModel>());
}

export const proposalTableActions = new TableActionCreator('proposal');

export const cateringTableActions = new TableActionCreator('catering');
