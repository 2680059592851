import { ConfigurationItems } from '../../../core-lib/models/config-dto.model';
import { ConfigItemAccountingConfigurationDtoModel } from '../../../core-lib/models/config-item-accounting-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../../core-lib/models/config-item-arranger-list-configuration-dto.model';
import { ConfigItemCheckboxConfigurationDtoModel } from '../../../core-lib/models/config-item-checkbox-configuration-dto.model';
import { ConfigItemConfigurationCategoryModel } from '../../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemDateConfigurationDtoModel } from '../../../core-lib/models/config-item-date-configuration-dto.model';
import { ConfigItemDaterangeConfigurationDtoModel } from '../../../core-lib/models/config-item-daterange-configuration-dto.model';
import { ConfigItemFileUploadConfigurationDtoModel } from '../../../core-lib/models/config-item-file-upload-configuration-dto.model';
import { ConfigItemLedgerAccountConfigurationDtoModel } from '../../../core-lib/models/config-item-ledger-account-configuration-dto.model';
import { ConfigItemListConfigurationDtoModel } from '../../../core-lib/models/config-item-list-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../../../core-lib/models/config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../../../core-lib/models/config-item-text-input-configuration-dto.model';
import { ConfigListDtoModel } from '../../../core-lib/models/config-list-dto.model';
import { EqualKeyNames } from '../../../forms-lib/models/equal-key-names';
import { FormPositionDtoModel } from '../../../forms-lib/models/form-position-dto.model';
import { PayeeDtoModel } from '../../all-forms/models/payee-dto.model';
import { FormW1DtoModel } from './form-w1-dto.model';

export const EasyFormW1Steps = {
  step1: <ConfigItemConfigurationCategoryModel>{
    id: 'step1',
    displayName: 'Gesellschaft',
  },
  step2: <ConfigItemConfigurationCategoryModel>{
    id: 'step2',
    displayName: 'Auftraggeber, -nehmer',
  },
  step3: <ConfigItemConfigurationCategoryModel>{
    id: 'step3',
    displayName: 'Detailangaben',
  },
};

export class EasyFormW1ConfigItems extends ConfigurationItems
  implements EqualKeyNames<FormW1DtoModel>, EqualKeyNames<PayeeDtoModel>, EqualKeyNames<FormPositionDtoModel> {
  payeeNumber = new ConfigItemTextInputConfigurationDtoModel('payeeNumber').withCategory(EasyFormW1Steps.step2);

  // STEP2

  // PayeeDtoModel
  salutation = new ConfigItemTextInputConfigurationDtoModel('salutation').withCategory(EasyFormW1Steps.step2);
  name = new ConfigItemTextInputConfigurationDtoModel('name').withCategory(EasyFormW1Steps.step2);
  nameAddition = new ConfigItemTextInputConfigurationDtoModel('nameAddition').withCategory(EasyFormW1Steps.step2);
  street = new ConfigItemTextInputConfigurationDtoModel('street').withCategory(EasyFormW1Steps.step2);
  zipCode = new ConfigItemTextInputConfigurationDtoModel('zipCode').withCategory(EasyFormW1Steps.step2);
  city = new ConfigItemTextInputConfigurationDtoModel('city').withCategory(EasyFormW1Steps.step2);
  country = new ConfigItemTextInputConfigurationDtoModel('country').withCategory(EasyFormW1Steps.step2);
  email = new ConfigItemTextInputConfigurationDtoModel('email').withCategory(EasyFormW1Steps.step2);
  iban = new ConfigItemTextInputConfigurationDtoModel('iban').withCategory(EasyFormW1Steps.step2);
  bic = new ConfigItemTextInputConfigurationDtoModel('bic').withCategory(EasyFormW1Steps.step2);

  // differentBADtoModel
  differentBillingAddress = new ConfigItemCheckboxConfigurationDtoModel('differentBillingAddress').withCategory(EasyFormW1Steps.step2);
  nameBA = new ConfigItemTextInputConfigurationDtoModel('nameBA').withCategory(EasyFormW1Steps.step2);
  nameAdditionBA = new ConfigItemTextInputConfigurationDtoModel('nameAdditionBA').withCategory(EasyFormW1Steps.step2);
  streetBA = new ConfigItemTextInputConfigurationDtoModel('streetBA').withCategory(EasyFormW1Steps.step2);
  zipCodeBA = new ConfigItemTextInputConfigurationDtoModel('zipCodeBA').withCategory(EasyFormW1Steps.step2);
  cityBA = new ConfigItemTextInputConfigurationDtoModel('cityBA').withCategory(EasyFormW1Steps.step2);

  ustId = new ConfigItemTextInputConfigurationDtoModel('ustId').withCategory(EasyFormW1Steps.step2);
  taxNumber = new ConfigItemTextInputConfigurationDtoModel('taxNumber').withCategory(EasyFormW1Steps.step2);

  // STEP3

  useCase = new ConfigItemTextInputConfigurationDtoModel('serviceDescription').withCategory(EasyFormW1Steps.step3);
  termsOfPayment = new ConfigItemTextInputConfigurationDtoModel('termsOfPayment').withCategory(EasyFormW1Steps.step3);
  facts = new ConfigItemTextInputConfigurationDtoModel('facts').withCategory(EasyFormW1Steps.step3);
  visContractNumber = new ConfigItemTextInputConfigurationDtoModel('visContractNumber').withCategory(EasyFormW1Steps.step3);
  servicePeriod = new ConfigItemDaterangeConfigurationDtoModel('date').withCategory(EasyFormW1Steps.step3);
  placeOfPerformance = new ConfigItemTextInputConfigurationDtoModel('placeOfPerformance').withCategory(EasyFormW1Steps.step3);
  internalNote = new ConfigItemTextInputConfigurationDtoModel('internalNote').withCategory(EasyFormW1Steps.step3);
  additionalInfoOnInvoice = new ConfigItemTextInputConfigurationDtoModel('additionalInfoOnInvoice').withCategory(EasyFormW1Steps.step3);
  dienArticle = new ConfigItemTextInputConfigurationDtoModel('dienArticle').withCategory(EasyFormW1Steps.step3);

  // recurringAccountingDto
  recurringAccounting = new ConfigItemCheckboxConfigurationDtoModel('recurringAccounting').withCategory(EasyFormW1Steps.step3);
  billingInterval = new ConfigItemTextInputConfigurationDtoModel('billingInterval').withCategory(EasyFormW1Steps.step3);
  billingDate = new ConfigItemTextInputConfigurationDtoModel('billingDate').withCategory(EasyFormW1Steps.step3);
  billingUntil = new ConfigItemDateConfigurationDtoModel('billingUntil').withCategory(EasyFormW1Steps.step3);

  // positions
  positions = new ConfigListDtoModel('positions').withCategory(EasyFormW1Steps.step3);
  positionName = new ConfigItemTextInputConfigurationDtoModel('positionName').withCategory(EasyFormW1Steps.step3);
  netCost = new ConfigItemNumberConfigurationDtoModel('netCost').withCategory(EasyFormW1Steps.step3);
  taxRate = new ConfigItemListConfigurationDtoModel('taxRate', 'taxrates').withCategory(EasyFormW1Steps.step3);
  account = new ConfigItemAccountingConfigurationDtoModel('account').withCategory(EasyFormW1Steps.step3);
  ledgerAccount = new ConfigItemLedgerAccountConfigurationDtoModel('ledgerAccount').withCategory(EasyFormW1Steps.step3);
  unit = new ConfigItemTextInputConfigurationDtoModel('unit', 'unit').withCategory(EasyFormW1Steps.step3);
  quantity = new ConfigItemNumberConfigurationDtoModel('quantity').withCategory(EasyFormW1Steps.step3);
  sum = new ConfigItemNumberConfigurationDtoModel('sum').withCategory(EasyFormW1Steps.step3);

  // payout
  payout = new ConfigItemNumberConfigurationDtoModel('payout').withCategory(EasyFormW1Steps.step3);

  // Genehmigungsstufen
  approver = new ConfigItemArrangerListConfigurationDtoModel('approver').withCategory(EasyFormW1Steps.step3);
  inspector = new ConfigItemArrangerListConfigurationDtoModel('inspector').withCategory(EasyFormW1Steps.step3);
  commentForApprover = new ConfigItemTextInputConfigurationDtoModel('commentForApprover').withCategory(EasyFormW1Steps.step3);

  file = new ConfigItemFileUploadConfigurationDtoModel('file').withCategory(EasyFormW1Steps.step3);
  sendToBeneficiary = new ConfigItemCheckboxConfigurationDtoModel('sendToBeneficiary').withCategory(EasyFormW1Steps.step3);
}
