import { ConfigurationItems } from '../../../core-lib/models/config-dto.model';
import { ConfigItemAccountingConfigurationDtoModel } from '../../../core-lib/models/config-item-accounting-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../../core-lib/models/config-item-arranger-list-configuration-dto.model';
import { ConfigItemCheckboxConfigurationDtoModel } from '../../../core-lib/models/config-item-checkbox-configuration-dto.model';
import { ConfigItemConfigurationCategoryModel } from '../../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemDateConfigurationDtoModel } from '../../../core-lib/models/config-item-date-configuration-dto.model';
import { ConfigItemFileUploadConfigurationDtoModel } from '../../../core-lib/models/config-item-file-upload-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../../../core-lib/models/config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../../../core-lib/models/config-item-text-input-configuration-dto.model';
import { ConfigItemTextareaInputConfigurationDtoModel } from '../../../core-lib/models/config-item-textarea-input-configuration-dto.model';
import { EqualKeyNames } from '../../../forms-lib/models/equal-key-names';
import { PayeeDtoModel } from '../../all-forms/models/payee-dto.model';
import { FormK3DtoModel } from './form-k3-dto.model';

export const EasyFormK3Steps = {
  step1: {
    id: 'step1',
    displayName: 'Gesellschaft',
  } as ConfigItemConfigurationCategoryModel,
  step2: {
    id: 'step2',
    displayName: 'Lieferant/Auftraggeber (aus Rahmenbestellung)',
  } as ConfigItemConfigurationCategoryModel,
  step3: {
    id: 'step3',
    displayName: 'Detailangaben',
  } as ConfigItemConfigurationCategoryModel,
};

export class EasyFormK3ConfigItems extends ConfigurationItems implements EqualKeyNames<FormK3DtoModel>, EqualKeyNames<PayeeDtoModel> {
  // Kreditor
  orderNumber = new ConfigItemTextInputConfigurationDtoModel('orderNumber').withCategory(EasyFormK3Steps.step2);
  orderPosition = new ConfigItemTextInputConfigurationDtoModel('orderPosition').withCategory(EasyFormK3Steps.step2);
  payeeNumber = new ConfigItemTextInputConfigurationDtoModel('payeeNumber').withCategory(EasyFormK3Steps.step2);
  sendMailToPayee = new ConfigItemCheckboxConfigurationDtoModel('sendMailToPayee').withCategory(EasyFormK3Steps.step2);

  // PayeeDtoModel
  salutation = new ConfigItemTextInputConfigurationDtoModel('salutation').withCategory(EasyFormK3Steps.step2);
  name = new ConfigItemTextInputConfigurationDtoModel('name').withCategory(EasyFormK3Steps.step2);
  nameAddition = new ConfigItemTextInputConfigurationDtoModel('nameAddition').withCategory(EasyFormK3Steps.step2);
  street = new ConfigItemTextInputConfigurationDtoModel('street').withCategory(EasyFormK3Steps.step2);
  zipCode = new ConfigItemTextInputConfigurationDtoModel('zipCode').withCategory(EasyFormK3Steps.step2);
  city = new ConfigItemTextInputConfigurationDtoModel('city').withCategory(EasyFormK3Steps.step2);
  country = new ConfigItemTextInputConfigurationDtoModel('country').withCategory(EasyFormK3Steps.step2);
  email = new ConfigItemTextInputConfigurationDtoModel('email').withCategory(EasyFormK3Steps.step2);
  iban = new ConfigItemTextInputConfigurationDtoModel('iban').withCategory(EasyFormK3Steps.step2);
  bic = new ConfigItemTextInputConfigurationDtoModel('bic').withCategory(EasyFormK3Steps.step2);

  // Abweichende Lieferadresse (DA - Delivery Adress)
  nameDA = new ConfigItemTextInputConfigurationDtoModel('nameDA').withCategory(EasyFormK3Steps.step2);
  nameAdditionDA = new ConfigItemTextInputConfigurationDtoModel('nameAdditionDA').withCategory(EasyFormK3Steps.step2);
  streetDA = new ConfigItemTextInputConfigurationDtoModel('streetDA').withCategory(EasyFormK3Steps.step2);
  zipCodeDA = new ConfigItemTextInputConfigurationDtoModel('zipCodeDA').withCategory(EasyFormK3Steps.step2);
  cityDA = new ConfigItemTextInputConfigurationDtoModel('cityDA').withCategory(EasyFormK3Steps.step2);
  phoneDA = new ConfigItemTextInputConfigurationDtoModel('phoneDA').withCategory(EasyFormK3Steps.step2);
  contactPersonDA = new ConfigItemTextInputConfigurationDtoModel('contactPersonDA').withCategory(EasyFormK3Steps.step2);

  /** step3 **/
    // Übernahme meine Daten in Step3
  orderDate = new ConfigItemDateConfigurationDtoModel('orderDate').withCategory(EasyFormK3Steps.step3);
  useCase = new ConfigItemTextInputConfigurationDtoModel('useCase').withCategory(EasyFormK3Steps.step3);
  account = new ConfigItemAccountingConfigurationDtoModel('account').withCategory(EasyFormK3Steps.step3);

  contactPerson = new ConfigItemTextInputConfigurationDtoModel('contactPerson').withCategory(EasyFormK3Steps.step3);
  telephone = new ConfigItemTextInputConfigurationDtoModel('telephone').withCategory(EasyFormK3Steps.step3);

  deliveryNumber = new ConfigItemTextInputConfigurationDtoModel('deliveryNumber').withCategory(EasyFormK3Steps.step3);
  extendOfDelivery = new ConfigItemTextInputConfigurationDtoModel('extendOfDelivery').withCategory(EasyFormK3Steps.step3);
  deliveryDate = new ConfigItemDateConfigurationDtoModel('deliveryDate').withCategory(EasyFormK3Steps.step3);

  netCost = new ConfigItemNumberConfigurationDtoModel('netCost').withCategory(EasyFormK3Steps.step3);
  description = new ConfigItemTextareaInputConfigurationDtoModel('description').withCategory(EasyFormK3Steps.step3);

  // Genehmigungsstufen
  approver = new ConfigItemArrangerListConfigurationDtoModel('approver').withCategory(EasyFormK3Steps.step3);
  inspector = new ConfigItemArrangerListConfigurationDtoModel('inspector').withCategory(EasyFormK3Steps.step3);

  // Dateianhang
  file = new ConfigItemFileUploadConfigurationDtoModel('file').withCategory(EasyFormK3Steps.step3);
}
