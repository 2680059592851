import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as moment from 'moment';
import { FormArrayState, FormGroupState } from 'ngrx-forms';
import { CategoryDataDto } from '../../../core-lib/models/category-data-dto.model';
import { CategoryInfoModel } from '../../../core-lib/models/category-info.model';
import { CompanyDataDtoModel } from '../../../core-lib/models/company-data-dto.model';
import { CostCenterToCompany } from '../../../core-lib/models/cost-center-to-company.model';
import { FaqTreeModel } from '../../../core-lib/models/faq-tree.model';
import { ifDefined } from '../../../core-lib/utils/reducer-utils';
import { ArrangerDtoModel } from '../../../forms/all-forms/models/arranger-dto.model';
import { AccountFormMappingModel } from '../../models/account-form-mapping.model';
import { Article } from '../../models/article.model';
import { CateringOrderData } from '../../models/catering-order-data.model';
import { ConfigListModel } from '../../models/config-list.model';
import { LocationInfo } from '../../models/location-info.model';
import { NetRegionModel } from '../../models/net-region.model';
import { Room } from '../../models/room.model';
import { companiesReducer } from './companies.reducer';
import { configReducer, ConfigState } from './config.reducer';
import { CateringTableState } from './core-catering-table.store';
import { coreFavoriteReducer } from './core-favorite.reducer';
import { FavoriteState } from './core-favorite.store';
import { formCategoryReducer, FormCategoryState } from './core-form-category.store';
import { cateringTableReducer, proposalTableReducer } from './core-overview-table.reducer';
import { ProposalTableState } from './core-proposal-table.store';
import { TemplateState } from './core-template-store';
import { coreTemplateReducer } from './core-template.reducer';
import { dashboardReducer, DashboardState } from './dashboard.store';
import { easyFormConfigsReducer } from './easy-form-configs.reducer';
import { EasyFormConfigsState } from './easy-form-configs.store';
import { easyFormsReducer, getEasyFormsArrayElementByIdentifier } from './easy-forms.reducer';
import { EasyFormsState } from './easy-forms.store';
import { FaqState, formFaqReducer } from './faq.store';
import { formsReducer, FormsState } from './forms.reducer';
import { ProcessQueueState, queueReducer } from './queue.store';
import { TemplateDeputeState, templateDeputyReducer } from './template-deputy.store';

export interface CoreFeatureState {
  easyFormConfigs: EasyFormConfigsState;
  easyForms: EasyFormsState;
  forms: FormsState;
  companies: CompanyDataDtoModel[];
  config: ConfigState;
  favorites: FavoriteState;
  templates: TemplateState;
  formCategory: FormCategoryState;
  faq: FaqState;
  proposals: ProposalTableState;
  catering: CateringTableState;
  templateDeputy: TemplateDeputeState;
  dashboard: DashboardState;
  queue: ProcessQueueState;
}

export const reducer: ActionReducerMap<CoreFeatureState, any> = {
  easyFormConfigs: easyFormConfigsReducer,
  easyForms: easyFormsReducer,
  forms: formsReducer,
  companies: companiesReducer,
  config: configReducer,
  formCategory: formCategoryReducer,
  favorites: coreFavoriteReducer,
  templates: coreTemplateReducer,
  proposals: proposalTableReducer,
  catering: cateringTableReducer,
  faq: formFaqReducer,
  templateDeputy: templateDeputyReducer,
  dashboard: dashboardReducer,
  queue: queueReducer,
};

function getArrayControls<T>() {
  return (s: FormArrayState<T>): FormGroupState<T>[] => Object.values(s.controls || {}) as FormGroupState<T>[];
}

export const getCoreState = createFeatureSelector<CoreFeatureState>('core');
export const getCompanyStateByCompanyId = createSelector(
  getCoreState,
  (s, props) => s.companies.find(c => c.id === props.companyId),
);
export const getCompanyStateByCompanyIdDynamic = createSelector(
  getCoreState,
  (s) => (companyId: string) => s.companies.find(c => c.id === companyId),
);
export const getCompaniesState = createSelector(getCoreState, s => s.companies);
export const getCoreConfigState = createSelector(getCoreState, (s) => s.config);
export const getGlobalConfig = createSelector(getCoreConfigState, (s) => s.globalConfig);
export const getGlobalConfigItems = createSelector(getGlobalConfig, (s) => s.configurationItems);
export const getGlobalConfigItemByName = createSelector(getGlobalConfigItems, (s, prop) => s[prop]);

export const getFormCategory = createSelector(getCoreState, state => state.formCategory);

export const getFaq = createSelector(getCoreState, s => s.faq);
export const getFaqFaqsState = createSelector(
  getFaq,
  (s) => s.faqs,
);
export const getFaqFaqsElementStateByName =
  (name) => (state) => ifDefined(getFaqFaqsState(state), (s) => s[name]);

export const getDeputyTemplates = createSelector(getCoreState, s => s.templateDeputy);

export const getCoreFormsState = createSelector(getCoreState, s => s.forms);
export const getEditorFeatureState = createSelector(getCoreFormsState, s => s.editorForm);
export const getCompanyFormState = createSelector(getCoreFormsState, s => s.companyForm);

export const getI18nState = createSelector(getCoreConfigState, s => s.i18n);
export const getI18nSelectedLanguage = createSelector(getI18nState, s => s.selectedLanguage);
export const getI18nTranslations = createSelector(getI18nState, s => s.translations);
// TOOD: Replace with SelectorWithProps
export const getI18nLangValues = (lang) => (state) => getI18nTranslations(state)[lang] || {};
export const getI18nValue = (key, lang) => (state) => getI18nLangValues(lang)(state)[key];
export const getI18nCurrentValue = (key) => (state) => getI18nValue(key, getI18nSelectedLanguage(state))(state);
export const getI18nCurrentLangValues = (state) => getI18nLangValues(getI18nSelectedLanguage(state))(state);

export const getAccountFeatureState = createSelector(getCoreFormsState, s => s.accountForm);
export const getAccountNetRegions = createSelector(getCoreFormsState, s => s.netRegions);
export const getAccountControls = createSelector(getAccountFeatureState, s => s.controls);
export const getAccountDefaultCompanyControl = createSelector(getAccountControls, s => s.defaultCompany);
export const getAccountDefaultCompanyControlValue = createSelector(getAccountDefaultCompanyControl, s => s.value);
export const getAccountCostCenterControls = createSelector(getAccountControls, s => s.costCenterToCompanies);
export const getAccountCostCenterControlsArrayState = createSelector(getAccountCostCenterControls, getArrayControls<CostCenterToCompany>());

export const getAccountDefaultArrangersControl = createSelector(getAccountFeatureState, s => s.controls);
export const getAccountDefaultArrangersState = createSelector(getAccountDefaultArrangersControl, s => s.defaultArrangers);
export const getAccountDefaultArrangersControls = createSelector(getAccountDefaultArrangersState, getArrayControls<ArrangerDtoModel>());

export const getConfigAdminElementsState = createSelector(getCoreConfigState, s => s.adminElements);
export const getConfigTaxRatesState = createSelector(getCoreConfigState, (s) => s.taxrates);
export const getConfigTaxRatesValuesState = createSelector(getConfigTaxRatesState, s => s.value);
export const getConfigTaxRatesControlsArrayState = createSelector(getConfigTaxRatesState, getArrayControls<ConfigListModel>());

export const getConfigLedgerAccountsState = createSelector(getCoreConfigState, s => s.ledgerAccounts);
export const getConfigLedgerAccountsValuesState = createSelector(getConfigLedgerAccountsState, s => s.value);
export const getConfigLedgerAccountsValuesStateFiltered = createSelector(
  getConfigLedgerAccountsValuesState,
  getEasyFormsArrayElementByIdentifier,
  (ledgerAccounts, selectedForm) =>
    ledgerAccounts.filter(la => la.categories.value.includes(selectedForm.category.id)),
);
export const getConfigLedgerAccountsControlsArrayState = createSelector(getConfigLedgerAccountsState, getArrayControls<ConfigListModel>());

export const getConfigSalutationsState = createSelector(getCoreConfigState, s => s.salutations);
export const getConfigSalutationsControlsArrayState = createSelector(getConfigSalutationsState, getArrayControls<ConfigListModel>());

export const getConfigLocationState = createSelector(
  getCoreConfigState,
  s => s.locationInfos?.sort((a, b) => a.name.localeCompare(b.name)),
);

/** Für Standorte ohne Lunchables müssen Räume und Artikel gepflegt werden und im Formular auch ausgewählt werden **/
export const getConfigNoneLunchablesLocationState = createSelector(getConfigLocationState, s => s.filter(l => !l.hasLunchables));
export const getConfigLunchablesLocationState = createSelector(getConfigLocationState, s => s.filter(l => l.hasLunchables));

export const getConfigLocationById = createSelector(
  getConfigLocationState,
  (state, {locationId}: { locationId?: string, roomId?: string }): LocationInfo => state.find(location => location.id === locationId),
);
export const getConfigLocationNameById = createSelector(getConfigLocationById, location => (location || {} as LocationInfo).name);

export const getConfigLocationRoomById = createSelector(
  getConfigLocationById,
  (location, {roomId}) => (location || {roomList: []}).roomList.find(room => room.id === roomId),
);

export const getConfigLocationRoomNameById = createSelector(
  getConfigLocationRoomById,
  room => (room || {} as Room).name,
);

export const getConfigOrderDataFormState = createSelector(getCoreConfigState, s => s.orderDataForm);
export const getConfigOrderDataFormControls = createSelector(getConfigOrderDataFormState, s => s.controls);
export const getConfigOrderDataFormOrderDataState = createSelector(getConfigOrderDataFormControls, s => s.orderData);
export const getConfigOrderDataFormOrderDataControls = createSelector(
  getConfigOrderDataFormOrderDataState,
  getArrayControls<CateringOrderData>(),
);

export const getConfigRoomFormState = createSelector(getCoreConfigState, s => s.roomForm);
export const getConfigRoomFormControls = createSelector(getConfigRoomFormState, getArrayControls<Room>());

export const getConfigArticleFormState = createSelector(getCoreConfigState, s => s.articleForm);
export const getConfigArticleFormControls = createSelector(getConfigArticleFormState, getArrayControls<Article>());

export const getConfigFaqFormState = createSelector(getCoreConfigState, s => s.faqForm);
export const getConfigFaqFormControls = createSelector(getConfigFaqFormState, getArrayControls<FaqTreeModel>());

export const getConfigGlobalConfigState = createSelector(getCoreConfigState, s => s.globalConfig);

export const getConfigCategoriesState = createSelector(getCoreConfigState, s => s.categories);
export const getConfigTopCategoriesState = getConfigCategoriesState;
export const getConfigCategoriesFlatState = createSelector(
  getConfigTopCategoriesState,
  s => flattenCategories(s.reduce((acc, topCategories) => acc.concat(topCategories.childrenCategories), [])),
);

function flattenCategories(categories: CategoryDataDto[]): CategoryDataDto[] {
  return categories.reduce((acc, r) => {
    acc.push(r);
    if (r.childrenCategories && r.childrenCategories.length) {
      acc = acc.concat(flattenCategories(r.childrenCategories));
    }
    return acc;
  }, []);
}

export const getConfigCategoriesFlatStateFiltered = createSelector(
  getConfigCategoriesFlatState,
  (
    categories: CategoryDataDto[],
    {categoryInfos, currentSelectedCategory}: { categoryInfos: CategoryInfoModel[], currentSelectedCategory },
  ) =>
    categories.filter(category => !categoryInfos.find(ci => ci.category === category.id
      && ci.category !== currentSelectedCategory)),
);


export const getConfigCategoryInfoFormState = createSelector(getCoreConfigState, s => s.categoryInfoForm);
export const getConfigCategoryInfoFormControls = createSelector(getConfigCategoryInfoFormState, getArrayControls<CategoryInfoModel>());


export const getConfigServiceCenterFormState = createSelector(getCoreConfigState, s => s.serviceCenterForm);
export const getConfigServiceCenterFormControls = createSelector(
  getConfigServiceCenterFormState,
  getArrayControls<NetRegionModel<AccountFormMappingModel>>(),
);

/**
 * Needs prop "name"
 */
export const getConfigCategoryByName = createSelector(
  getConfigCategoriesState,
  (s, props: { name: string }) => s.find(category => category.name === props.name),
);

export const getConfigSystemMessageState = createSelector(getCoreConfigState, s => s.systemMessage);
export const getConfigSystemMessageSetState = createSelector(getCoreConfigState, s => s.systemMessageSet);
export const getConfigSystemMessage = createSelector(
  getConfigSystemMessageSetState,
  systemMessageSet => {
    if (!systemMessageSet || !systemMessageSet.content || !systemMessageSet.active) {
      return undefined;
    }
    const activeUntil = systemMessageSet.activeUntil && moment(systemMessageSet.activeUntil);
    if (!activeUntil || activeUntil.isAfter()) {
      return systemMessageSet.content;
    }
    return undefined;
  },
);

/**
 * Needs prop "shortName"
 */
export const getCompanyByShortNameState = createSelector(
  getCompaniesState,
  (s, props) => s.find((c) => c.shortName === props.shortName),
);

export const getCompanyByDynamicShortNameState = createSelector(
  getCompaniesState,
  (companies) => (shortName) => companies.find(c => c.shortName === shortName),
);

/**
 * Needs prop "id"
 */
export const getCompanyByIdState = createSelector(
  getCompaniesState,
  (s, props: { id: string }) => s.find((c) => c.id === props.id),
);

export const getCompaniesByDynamicFormIdentifierState = createSelector(
  getCompaniesState,
  getEasyFormsArrayElementByIdentifier,
  (companies, s) =>
    s?.companies.map((id) => companies.find((c) => c.id === id)),
);

export const getCompanyByDynamicIdState = (state) => (id: string): CompanyDataDtoModel => getCompaniesState(state)
  .find((c) => c.id === id);

