<mat-card-title>{{'accountingSearch' | i18n |  async}}</mat-card-title>
<mat-card-content>
  <!-- search fields -->
  <form (ngSubmit)="search()">
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between"
      fxLayoutGap="10px grid"
    >
      <mat-form-field
        fxFlex
      >

        <mat-select
          [placeholder]="'accountType' | i18n | async"
          name="type"
          [(ngModel)]="searchParams.type"
        >
          <mat-option value="UNKNOWN">{{'noSelection' | i18n | async}}</mat-option>
          <mat-option
            *ngFor="let accountType of getSupportedAccountingTypes()"
            [value]="accountType"
          >{{accountType | i18n | async}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        fxFlex
      >
        <input
          [disabled]="searchParams.type === 'UNKNOWN'"
          matInput
          [placeholder]="'account' | i18n | async"
          type="text"
          name="searchTerm"
          [(ngModel)]="searchParams.searchTerm"
        >
      </mat-form-field>

      <div>
        <button
          type="submit"
          aria-label="search"
          mat-button
          mat-raised-button
          color="accent"
          [disabled]="this.isLoading"
        >{{ 'search' | i18n | async }}</button>
      </div>
    </div>
  </form>
  <!-- result table -->
  <div class="table-container" *ngIf="showTable">
    <div>
      <mat-form-field>
        <input
          matInput
          [placeholder]="'filterIt' | i18n | async"
          type="text"
          name="tableFilter"
          [ngModel]="tableFilter$ | async"
          (ngModelChange)="tableFilter$.next($event)"
        >
      </mat-form-field>
    </div>
    <ngx-datatable
      #table
      class="material striped"
      [rows]="filteredContent$ | async"
      [limit]="5"
      [columns]="columns"
      [columnMode]="'force'"
      headerHeight="50"
      footerHeight="50"
      rowHeight="'auto'"
      [reorderable]="true"
      [loadingIndicator]="isLoading"
      (select)="rowSelected($event)"
      selectionType="single"
      [messages]="messages"
    ></ngx-datatable>
  </div>
  <!-- action buttons -->
  <div
    fxLayout="row wrap"
    fxLayoutAlign="end"
    fxLayoutGap="10px grid"
  >
    <div>
      <button
        type="button"
        mat-button
        mat-raised-button
        color="accent"
        (click)="abortDialog()"
      >
        {{ 'close' | i18n | async }}
      </button>
    </div>
    <div>
      <button
        type="button"
        mat-button
        mat-raised-button
        color="primary"
        [disabled]="!selectedRow"
        (click)="applyDialog()"
      >
        {{ 'apply' | i18n | async }}
      </button>
    </div>
  </div>
</mat-card-content>

