import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiService } from '../../../core-lib/services/api.service';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { rxCatchApiError } from '../../../core-lib/utils/reducer-utils';
import {
  FavoriteActionAdd,
  FavoriteActionDelete,
  FavoriteActionDeleteSuccess,
  FavoriteActionError,
  FavoriteActionGet,
  FavoriteActionGetFirst,
  FavoriteActionGetFirstSuccess,
  FavoriteActionGetSuccess, FavoriteActionSaveOrder, FavoriteActionSaveOrderSuccess,
  FavoriteActionSaveSuccess,
} from '../actions/favorite.actions';

@Injectable()
export class CoreFavoriteEffects {

  loadFavorites$ = createEffect(() => this.actions$.pipe(
    ofType(FavoriteActionGet.TYPE),
    switchMap((action: FavoriteActionGet) => this.api.getAllFavorites().pipe(
      map(favorites => new FavoriteActionGetSuccess(favorites)),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  loadFirstFavorites$ = createEffect(() => this.actions$.pipe(
    ofType(FavoriteActionGetFirst.TYPE),
    switchMap((action: FavoriteActionGet) => this.api.getAllFavorites().pipe(
      map(favorites => new FavoriteActionGetFirstSuccess(favorites)),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  addFavorite = createEffect(() => this.actions$.pipe(
    ofType(FavoriteActionAdd.TYPE),
    switchMap((action: FavoriteActionAdd) => this.api.postFormFavorite({id: action.id}).pipe(
      map(() => new FavoriteActionSaveSuccess()),
      catchError(e => of(new FavoriteActionError(e, e.message))),
    )),
  ), defaultEffectOptions());

  deleteFavorite = createEffect(() => this.actions$.pipe(
    ofType(FavoriteActionDelete.TYPE),
    switchMap((action: FavoriteActionDelete) => this.api.deleteFormFavorite(action.identifier).pipe(
      map(() => new FavoriteActionDeleteSuccess()),
      catchError(e => of(new FavoriteActionError(e, e.message))),
    )),
  ), defaultEffectOptions());

  saveSuccess = createEffect(() => this.actions$.pipe(
    ofType(FavoriteActionSaveSuccess.TYPE, FavoriteActionDeleteSuccess.TYPE),
    map(() => new FavoriteActionGet()),
  ), defaultEffectOptions());

  saveTemplateOrder = createEffect(() => this.actions$.pipe(
    ofType(FavoriteActionSaveOrder.TYPE),
    switchMap((action: FavoriteActionSaveOrder) => this.api.savFavoriteOrder(action.data).pipe(
      map(() => new FavoriteActionSaveOrderSuccess()),
      catchError(e => of(new FavoriteActionError(e, e.message))),
    )),
  ), defaultEffectOptions());

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {
  }
}
