export type TokenParseFnType = Function & ((() => string[]) & ((tokens: { [key: string]: string }) => string)) & { i9n?: boolean };

export const isTokenParseFnType = (t): t is TokenParseFnType => typeof t === 'function' && t.i9n === true;

// interpolate = i9e
/**
 * Interpoliert ein Template, gibt eine Funktion vom Typ TokenParseFnType zurück.
 * Mit dieser Funktion kann die I18nPipe den String zusammenbauen.
 * Wird die zurückgegebene Funktion ohne Parameter aufgerufen, gibt sie die
 * verwendeten Token-Namen des Templates zurück.
 * @param strings vom `` Template
 * @param keys vom `` Template
 */
export function i9e(strings, ...keys): TokenParseFnType {
  function tokenParse(): string[];
  function tokenParse(tokens: { [key: string]: string | number }): string;
  function tokenParse(tokens?: { [key: string]: string | number }): string | string[] {
    if (tokens === undefined) {
      return keys;
    }
    let string = ``;
    const tokensByNumber = Object.values(tokens);
    for (const [i, val] of keys.entries()) {
      const resolvedValue = Number.isInteger(val) ? tokensByNumber[val] :  typeof val === 'function' ? val(tokens) : tokens[val];
      if (resolvedValue === undefined) {
        console.warn('missing i18n interpolation token:', val, 'from', strings, keys);
        // @ts-ignore
        window.missingI18n = {
          // @ts-ignore
          ...window.missingI18n,
          [val]: strings,
        };
      }
      string += strings[i] + (resolvedValue === 0 ? 0 : (resolvedValue || ''));
    }
    string += strings[strings.length - 1];
    return string;
  }

  tokenParse.i9n = true;

  return tokenParse;
}
