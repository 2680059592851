import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AddArrayControlAction, FormGroupState } from 'ngrx-forms';
import { map, takeUntil } from 'rxjs/operators';
import {
  coreConfigFaqDataLoad,
  coreConfigFaqDataSave,
  coreConfigFaqMarkElementForDeletion,
  coreConfigFaqMarkTreeForDeletion,
} from '../../ngrx/actions/core-config.actions';
import { getConfigFaqFormControls, getConfigFaqFormState } from '../../ngrx/reducers/core.store';

@Component({
  selector: 'lib-common-route-config-faq',
  templateUrl: './route-config-faq.component.html',
  styleUrls: ['./route-config-faq.component.scss'],
})
export class RouteConfigFaqComponent implements OnInit, OnDestroy {
  faqTreeArrayState$ = this.store$.select(getConfigFaqFormControls);

  isFormInvalid$ = this.store$.select(getConfigFaqFormState).pipe(
    map(form => form.isInvalid),
  );

  private treeArrayId: string;
  private componentDestroyed$ = new EventEmitter();

  constructor(private store$: Store<any>) {
  }

  getControlId = (i: number, t: FormGroupState<any>) => t.id;

  ngOnInit() {
    this.store$.select(getConfigFaqFormState).pipe(takeUntil(this.componentDestroyed$)).subscribe(state => this.treeArrayId = state.id);
    this.store$.dispatch(coreConfigFaqDataLoad());
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  save() {
    this.store$.dispatch(coreConfigFaqDataSave());
  }

  addFaqTreeNode() {
    this.store$.dispatch(new AddArrayControlAction(this.treeArrayId, {
      section: '',
      items: [
        {
          question: '',
          answer: '',
          section: '',
          language: 'de', // TODO ins UI aufnehmen
        },
      ],
    }));
  }

  removeFaq(tree: FormGroupState<any>, item: FormGroupState<any>) {
    this.store$.dispatch(coreConfigFaqMarkElementForDeletion({treeId: tree.id, itemId: item.id}));
  }

  addFaqElement(tree: FormGroupState<any>) {
    this.store$.dispatch(new AddArrayControlAction(tree.controls.items.id, {
      question: '',
      answer: '',
      section: tree.value.section,
      language: 'de', // TODO ins UI aufnehmen
    }));
  }

  removeFaqTree(tree: FormGroupState<any>) {
    this.store$.dispatch(coreConfigFaqMarkTreeForDeletion({treeId: tree.id}));
  }

  hasElements(tree: FormGroupState<any>) {
    return tree.value.items.filter(e => !e.markedForDeletion).length > 0;
  }
}
