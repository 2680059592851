import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroupDirective, NG_VALUE_ACCESSOR, NgForm, NgModel } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { FormControlState, FormGroupState } from 'ngrx-forms';
import { of } from 'rxjs';
import { debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserSearchModel } from '../../models/user-search.model';
import { ApiService } from '../../services/api.service';
import { safeUnbox } from '../../utils/safe-unbox';
import { UserSearchModalComponent } from '../user-search-modal/user-search-modal.component';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'lib-common-user-search',
  templateUrl: './user-search-input.component.html',
  styleUrls: ['./user-search-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSearchInputComponent),
      multi: true,
    },
  ],
})
export class UserSearchInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('auto', { static: true }) autoRef: MatAutocomplete;

  private get model(): NgModel {
    return this._model;
  }

  @ViewChild('model')
  private set model(model: NgModel) {
    this.onModelUpdateObservable.emit(model);
    model.update.pipe(
      takeUntil(this.onDestroyObservable),
      takeUntil(this.onModelUpdateObservable),
      tap(() => this.isSelected = this.isUserText()),
      tap((value: string) => !this.isSelected && this.setUser(null, value, false)),
      debounceTime(this.debounce),
      switchMap((query) => {
        if (query && query.toString().length >= this.minLength) {
          return this.api.getUserFind(query);
        } else {
          return of([]);
        }
      }),
    ).subscribe((users) => this.users$.emit(users));
    this._model = model;
  }

  get ngrxFormControlState(): FormGroupState<UserSearchModel> | FormControlState<string> {
    return this._ngrxFormControlState;
  }

  @Input() set ngrxFormControlState(value: FormGroupState<UserSearchModel> | FormControlState<string>) {
    this._ngrxFormControlState = value;
    this.selectedUser = (typeof value.value !== 'string') ? safeUnbox(value.value) : this.selectedUser;
  }

  private _model: NgModel;

  @Input() debounce = 100;
  @Input() minLength = 3;
  @Input() placeholder = 'Suche';
  @Input() modalLabel = this.placeholder;
  private _ngrxFormControlState: FormGroupState<UserSearchModel> | FormControlState<string>;
  @Input() asId = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() asLabel = false; // no <input>, just plain text
  @Input() appearance: MatFormFieldAppearance;
  @Input() required = false;
  @Input() removable = false;
  @Input() inline = false;
  @Output() removeClick = new EventEmitter();

  disabledByCVA = false;

  private readonly onDestroyObservable = new EventEmitter();
  private readonly onModelUpdateObservable = new EventEmitter<NgModel>();
  private readonly onChangeObservable = new EventEmitter<UserSearchModel | string>();
  private readonly onTouchedObservable = new EventEmitter<void>();
  private readonly loadUserFromApi$ = new EventEmitter<string>();

  search: string;
  isSelected = false;
  selectedUser: UserSearchModel;
  users$ = new EventEmitter<UserSearchModel[]>();
  errorStateMatcher: ErrorStateMatcher = {
    isErrorState: (control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean => {
      if (this._ngrxFormControlState) {
        return this._ngrxFormControlState.isTouched && (this._ngrxFormControlState.isInvalid || (this.required && !this.selectedUser));
      } else {
        return this.required && !this.selectedUser;
      }
    },
  };

  constructor(
    private readonly api: ApiService,
    private readonly dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.loadUserFromApi$.pipe(
      takeUntil(this.onDestroyObservable),
      debounceTime(this.debounce),
    ).subscribe((userId) => this.api.getUserFindById(userId).subscribe((user) => this.setUser(user)));
  }

  trackById(index: number, user: UserSearchModel) {
    return user.userId;
  }

  change(userOrId: UserSearchModel | string) {
    if (typeof userOrId === 'string') {
      if (this.selectedUser && userOrId === this.selectedUser.userId) {
        this.setUser(this.selectedUser);
      } else {
        if (this.api) {
          this.api.getUserFindById(userOrId).subscribe((user) => this.setUser(user, undefined, true));
        }
      }
    } else {
      this.setUser(userOrId, undefined, true);
    }
  }

  setUser(user: UserSearchModel, displayText?: string, isUserEvent?: boolean) {
    this.selectedUser = user;
    this.isSelected = !!user;
    if (this.onChangeObservable) {
      if (this.asId) {
        if (user) {
          this.onChangeObservable.emit(user.userId);
        } else {
          this.onChangeObservable.emit(null);
        }
      } else {
        this.onChangeObservable.emit(user);
      }
    }

    if (displayText) {
      this.search = displayText;
    } else {
      this.writeValue(user);
    }

    if (isUserEvent) {
      this.onTouchedObservable.emit();
    }
  }

  isUserText() {
    return this.search === this.getUserText(this.selectedUser);
  }

  /**
   * by ControlValueAccessor
   */
  registerOnChange(fn: ((files: UserSearchModel) => void) | ((files: string) => void)): void {
    this.onChangeObservable.pipe(
      takeUntil(this.onDestroyObservable),
    ).subscribe(fn);
  }

  /**
   * by ControlValueAccessor
   */
  registerOnTouched(fn: () => void): void {
    this.onTouchedObservable.pipe(
      takeUntil(this.onDestroyObservable),
    ).subscribe(fn);
  }

  /**
   * by ControlValueAccessor
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabledByCVA = isDisabled;
  }

  getUserText(user: UserSearchModel): string {
    return user && `${user.name} (${user.mail})`;
  }

  /**
   * by ControlValueAccessor
   */
  writeValue(userOrId: UserSearchModel | string): void {
    if (userOrId) {
      if (typeof userOrId === 'string') {
        if (this.selectedUser && this.selectedUser.userId === userOrId) {
          this.search = this.getUserText(this.selectedUser);
          this._model.update.emit(this.search);
        } else {
          if (this.api) {
            this.loadUserFromApi$.emit(userOrId);
          }
        }
      } else {
        const userText = this.getUserText(userOrId);
        if (!userText && userOrId.userId) {
          this.loadUserFromApi$.emit(userOrId.userId);
        } else {
          this.search = userText;
          if (this._model) {
            this._model.update.emit(this.search);
          } else {
            this.selectedUser = userOrId;
          }
        }
      }
    } else {
      this.search = undefined;
      this.selectedUser = undefined;
      this.isSelected = false;
    }
  }

  openDialog($event: Event) {
    $event.stopPropagation();
    this.dialog.open(UserSearchModalComponent, {
      data: {
        debounce: this.debounce,
        minLength: this.minLength,
        label: this.modalLabel,
      },
    }).afterClosed().pipe(
      takeUntil(this.onDestroyObservable),
      filter(user => user !== undefined),
    ).subscribe((user) => this.change(user));
  }

  ngOnDestroy(): void {
    this.onDestroyObservable.emit();
    this.onDestroyObservable.complete();
    this.onModelUpdateObservable.emit(undefined);
    this.onModelUpdateObservable.complete();
  }

  showAutoComplete() {
    return !this.disabled && !this.readonly && !this.asLabel;
  }

  hasNotUniqueError() {
    return !!this.getError('notUnique');
  }

  hasIncludesAuthorError() {
    return !!this.getError('includesAuthor');
  }

  hasApproverNeededError() {
    return !!this.getError('approvalNeeded');
  }

  hasIncludesRecipientError() {
    return !!this.getError('recipient');
  }

  hasIncludedInError() {
    return !!this.getError('includedIn');
  }

  getIncludedInErrorMessage() {
    return this.getError('includedIn');
  }

  getError(name: string) {
    return this._ngrxFormControlState && this._ngrxFormControlState.errors[name];
  }

  hasNoParticipantError() {
    return this.getError('noParticipant');
  }

  hasMandatoryError() {
    return this.getError('mandatory');
  }

}
