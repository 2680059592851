import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FormCategoryTreeElementModel, FormCategoryTreeModel } from '../../../core-lib/models/form-category-tree.model';
import { ifDefined } from '../../../core-lib/utils/reducer-utils';
import { CoreFormCategorySetCategoriesAction, CoreFormsCategoryActions } from '../actions/core-form-category.actions';
import { CoreFeatureState } from './core.store';

export interface FormCategoryState {
  categories: FormCategoryTreeModel;
}

const initialFormCategoryState: FormCategoryState = {
  categories: undefined,
};

export function formCategoryReducer(state = initialFormCategoryState, action: CoreFormsCategoryActions): FormCategoryState {
  switch (action.type) {
    case CoreFormCategorySetCategoriesAction.TYPE:
      return {
        ...state,
        categories: action.categories,
      };

    default:
      return state;
  }
}

const getCoreState = createFeatureSelector<CoreFeatureState>('core');
export const getFormCategoryFeatureState = createSelector(
  getCoreState,
  (s) => s.formCategory,
);
export const getFormCategoryCategoriesState = createSelector(
  getFormCategoryFeatureState,
  (s) => s.categories,
);

export const getFormCategoryCategoriesElementStateByName =
  (name) => (state) => ifDefined(getFormCategoryCategoriesState(state), (s) => s[name]);
