import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core-lib/services/api.service';
import { AppConfigService } from '../services/app-config.service';

@Injectable()
export class LoginGuard implements CanActivateChild, CanActivate, CanLoad {
  constructor(private api: ApiService, private appConfigService: AppConfigService,
  ) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasPermission(window.location.href);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasPermission(state.url);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasPermission(state.url);
  }

  hasPermission(url: string): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.api.getLoginState().pipe(
      map((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          window.location.href =
            `${this.appConfigService.config.backendBase}/api/session/login?forward=${encodeURIComponent(url)}`;
        }
        return isLoggedIn;
      }),
    );
  }
}
