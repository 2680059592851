import { ConfigurationItems } from '../../../core-lib/models/config-dto.model';
import { ConfigItemAccountingConfigurationDtoModel } from '../../../core-lib/models/config-item-accounting-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../../core-lib/models/config-item-arranger-list-configuration-dto.model';
import { ConfigItemCheckboxConfigurationDtoModel } from '../../../core-lib/models/config-item-checkbox-configuration-dto.model';
import { ConfigItemConfigurationCategoryModel } from '../../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemDateConfigurationDtoModel } from '../../../core-lib/models/config-item-date-configuration-dto.model';
import { ConfigItemFileUploadConfigurationDtoModel } from '../../../core-lib/models/config-item-file-upload-configuration-dto.model';
import { ConfigItemLedgerAccountConfigurationDtoModel } from '../../../core-lib/models/config-item-ledger-account-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../../../core-lib/models/config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../../../core-lib/models/config-item-text-input-configuration-dto.model';
import { ConfigListDtoModel } from '../../../core-lib/models/config-list-dto.model';
import { EqualKeyNames } from '../../../forms-lib/models/equal-key-names';
import { PayeeDtoModel } from '../../all-forms/models/payee-dto.model';
import { FormK2DtoModel } from './form-k2-dto.model';


export const EasyFormK2Steps = {
  step1: <ConfigItemConfigurationCategoryModel>{
    id: 'step1',
    displayName: 'Gesellschaft',
  },
  step2: <ConfigItemConfigurationCategoryModel>{
    id: 'step2',
    displayName: 'Wunschlieferant',
  },
  step3: <ConfigItemConfigurationCategoryModel>{
    id: 'step3',
    displayName: 'Detailangaben',
  },
};

export class EasyFormK2ConfigItems extends ConfigurationItems implements EqualKeyNames<FormK2DtoModel>, EqualKeyNames<PayeeDtoModel> {
  // Kreditor
  payeeNumber = new ConfigItemTextInputConfigurationDtoModel('payeeNumber').withCategory(EasyFormK2Steps.step2);
  alternativePayeeSelected = new ConfigItemCheckboxConfigurationDtoModel('alternativePayeeSelected').withCategory(EasyFormK2Steps.step2);
  alternativePayee = new ConfigItemTextInputConfigurationDtoModel('alternativePayee').withCategory(EasyFormK2Steps.step2);

  // PayeeDtoModel
  salutation = new ConfigItemTextInputConfigurationDtoModel('salutation').withCategory(EasyFormK2Steps.step2);
  name = new ConfigItemTextInputConfigurationDtoModel('name').withCategory(EasyFormK2Steps.step2);
  nameAddition = new ConfigItemTextInputConfigurationDtoModel('nameAddition').withCategory(EasyFormK2Steps.step2);
  street = new ConfigItemTextInputConfigurationDtoModel('street').withCategory(EasyFormK2Steps.step2);
  zipCode = new ConfigItemTextInputConfigurationDtoModel('zipCode').withCategory(EasyFormK2Steps.step2);
  city = new ConfigItemTextInputConfigurationDtoModel('city').withCategory(EasyFormK2Steps.step2);
  country = new ConfigItemTextInputConfigurationDtoModel('country').withCategory(EasyFormK2Steps.step2);
  email = new ConfigItemTextInputConfigurationDtoModel('email').withCategory(EasyFormK2Steps.step2);
  iban = new ConfigItemTextInputConfigurationDtoModel('iban').withCategory(EasyFormK2Steps.step2);
  bic = new ConfigItemTextInputConfigurationDtoModel('bic').withCategory(EasyFormK2Steps.step2);

  // Abweichende Lieferadresse (DA - Delivery Adress)
  nameDA = new ConfigItemTextInputConfigurationDtoModel('nameDA').withCategory(EasyFormK2Steps.step2);
  nameAdditionDA = new ConfigItemTextInputConfigurationDtoModel('nameAdditionDA').withCategory(EasyFormK2Steps.step2);
  streetDA = new ConfigItemTextInputConfigurationDtoModel('streetDA').withCategory(EasyFormK2Steps.step2);
  zipCodeDA = new ConfigItemTextInputConfigurationDtoModel('zipCodeDA').withCategory(EasyFormK2Steps.step2);
  cityDA = new ConfigItemTextInputConfigurationDtoModel('cityDA').withCategory(EasyFormK2Steps.step2);
  phoneDA = new ConfigItemTextInputConfigurationDtoModel('phoneDA').withCategory(EasyFormK2Steps.step2);
  contactPersonDA = new ConfigItemTextInputConfigurationDtoModel('contactPersonDA').withCategory(EasyFormK2Steps.step2);

  /** step3 **/

    // Übernahme meine Daten in Step3
  useCase = new ConfigItemTextInputConfigurationDtoModel('useCase').withCategory(EasyFormK2Steps.step3);
  purchasingGroup = new ConfigItemTextInputConfigurationDtoModel('purchasingGroup').withCategory(EasyFormK2Steps.step3);

  // positions
  positions = new ConfigListDtoModel('positions').withCategory(EasyFormK2Steps.step3);
  positionName = new ConfigItemTextInputConfigurationDtoModel('positionName').withCategory(EasyFormK2Steps.step3);
  deliveryDate = new ConfigItemDateConfigurationDtoModel('deliveryDate').withCategory(EasyFormK2Steps.step3);
  unit = new ConfigItemTextInputConfigurationDtoModel('unit', 'unit').withCategory(EasyFormK2Steps.step3);
  quantity = new ConfigItemNumberConfigurationDtoModel('quantity').withCategory(EasyFormK2Steps.step3);
  netCost = new ConfigItemNumberConfigurationDtoModel('netCost').withCategory(EasyFormK2Steps.step3);
  sum = new ConfigItemNumberConfigurationDtoModel('sum').withCategory(EasyFormK2Steps.step3);
  account = new ConfigItemAccountingConfigurationDtoModel('account').withCategory(EasyFormK2Steps.step3);
  ledgerAccount = new ConfigItemLedgerAccountConfigurationDtoModel('ledgerAccount').withCategory(EasyFormK2Steps.step3);
  dataProtectionRelevant = new ConfigItemCheckboxConfigurationDtoModel('dataProtectionRelevant').withCategory(EasyFormK2Steps.step3);
  ismsRelevant = new ConfigItemCheckboxConfigurationDtoModel('ismsRelevant').withCategory(EasyFormK2Steps.step3);
  longText = new ConfigItemTextInputConfigurationDtoModel('longText').withCategory(EasyFormK2Steps.step3);
  ams = new ConfigItemCheckboxConfigurationDtoModel('ams').withCategory(EasyFormK2Steps.step3);
  amsReason = new ConfigItemTextInputConfigurationDtoModel('amsReason').withCategory(EasyFormK2Steps.step3);
  productGroup = new ConfigItemTextInputConfigurationDtoModel('productGroup').withCategory(EasyFormK2Steps.step3);
  service = new ConfigItemCheckboxConfigurationDtoModel('service').withCategory(EasyFormK2Steps.step3);
  masterAgreementSelected = new ConfigItemCheckboxConfigurationDtoModel('masterAgreement').withCategory(EasyFormK2Steps.step3);
  masterAgreement = new ConfigItemTextInputConfigurationDtoModel('masterAgreement').withCategory(EasyFormK2Steps.step3);
  masterAgreementPosition = new ConfigItemTextInputConfigurationDtoModel('masterAgreement').withCategory(EasyFormK2Steps.step3);
  attachedOfferSelected = new ConfigItemCheckboxConfigurationDtoModel('attachedOffer').withCategory(EasyFormK2Steps.step3);

  // Genehmigungsstufen
  approver = new ConfigItemArrangerListConfigurationDtoModel('approver').withCategory(EasyFormK2Steps.step3);
  inspector = new ConfigItemArrangerListConfigurationDtoModel('inspector').withCategory(EasyFormK2Steps.step3);
  commentForApprover = new ConfigItemTextInputConfigurationDtoModel('commentForApprover').withCategory(EasyFormK2Steps.step3);

  // Dateianhang
  file = new ConfigItemFileUploadConfigurationDtoModel('file').withCategory(EasyFormK2Steps.step3);
}
