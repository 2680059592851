<div class="nav-greeting">
  <a routerLink="/">
    <img
      [ngClass]="{'logo-translate': fullHeight}"
      class="logo-easy"
      [src]="efaLogo"
      alt="easy logo"
    >
  </a>
</div>
