import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonAppComponent } from '../../../common/src/core/components/common-app/common-app.component';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends CommonAppComponent {
}
