import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from '../../../core-lib/services/api.service';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { rxRoute } from '../../../core-lib/utils/reducer-utils';
import { Article } from '../../models/article.model';
import { Room } from '../../models/room.model';
import {
  CoreActionCategoriesLoaded,
  CoreConfigLocationArticleSave,
  CoreConfigLocationArticleSaveSuccess,
  CoreConfigLocationArticleSelected,
  CoreConfigLocationInfoLoad,
  CoreConfigLocationInfoLoadSuccess,
  CoreConfigLocationRoomSave,
  CoreConfigLocationRoomSaveSuccess,
  CoreConfigLocationRoomSelected,
} from '../actions/core-config.actions';
import {
  CoreFeatureState,
  getConfigArticleFormState,
  getConfigCategoryByName,
  getConfigNoneLunchablesLocationState,
  getConfigRoomFormState,
} from '../reducers/core.store';

@Injectable()
export class CoreCateringEffects {

  configCaterer$ = this.store$.select(getConfigCategoryByName, {name: 'catering'});

  configInit$ = createEffect(() => this.actions$.pipe(
    ofType(CoreActionCategoriesLoaded.TYPE),
    withLatestFrom(this.configCaterer$, (action, configCaterer) => ({
      action,
      configCaterer,
    })),
    filter(({configCaterer}) => !!configCaterer),
    map(() => new CoreConfigLocationInfoLoad()),
  ), defaultEffectOptions());

  loadLocationInfos$ = createEffect(() => this.actions$.pipe(
    ofType(CoreConfigLocationInfoLoad.TYPE),
    switchMap(() => this.api.getLocationInfo().pipe(
      catchError((e) => {
        console.error('this.api.getLocationInfo:', e);
        return of([]);
      }),
    )),
    map(locations => new CoreConfigLocationInfoLoadSuccess(locations)),
  ), defaultEffectOptions());

  roomFormLoad$ = createEffect(() => this.actions$.pipe(
    rxRoute('config/rooms'),
    filter(payload => payload.params.id),
    withLatestFrom(this.store$.select(getConfigNoneLunchablesLocationState), (payload, locations) => ({id: payload.params.id, locations})),
    switchMap(({id, locations}) => {
      const selectedLocation = locations.find(l => l.id === id);
      if (!!selectedLocation) {
        return of(selectedLocation.roomList);
      }
      return this.api.getRoomsByLocation(id);
    }),
    map((roomList: Room[]) => new CoreConfigLocationRoomSelected(roomList)),
  ), defaultEffectOptions());

  roomFormSave$ = createEffect(() => this.actions$.pipe(
    ofType<CoreConfigLocationRoomSave>(CoreConfigLocationRoomSave.TYPE),
    withLatestFrom(this.store$.select(getConfigRoomFormState)),
    switchMap(([action, roomFormState]) => this.api.postRooms(action.locationId, roomFormState.value)),
    map((savedRooms) => new CoreConfigLocationRoomSaveSuccess(savedRooms)),
  ), defaultEffectOptions());

  articleFormLoad$ = createEffect(() => this.actions$.pipe(
    rxRoute('config/articles'),
    filter(payload => payload.params.id),
    withLatestFrom(this.store$.select(getConfigNoneLunchablesLocationState), (payload, locations) => ({id: payload.params.id, locations})),
    switchMap(({id, locations}) => {
      const selectedLocation = locations.find(l => l.id === id);
      if (!!selectedLocation) {
        return of(selectedLocation.articleList);
      }
      return this.api.getArticlesByLocation(id);
    }),
    map((articleList: Article[]) => new CoreConfigLocationArticleSelected(articleList)),
  ), defaultEffectOptions());

  articleFormSave$ = createEffect(() => this.actions$.pipe(
    ofType<CoreConfigLocationArticleSave>(CoreConfigLocationArticleSave.TYPE),
    withLatestFrom(this.store$.select(getConfigArticleFormState)),
    switchMap(([action, articleFormState]) => this.api.postArticle(action.locationId, articleFormState.value)),
    map((savedArticles) => new CoreConfigLocationArticleSaveSuccess(savedArticles)),
  ), defaultEffectOptions());


  constructor(
    private actions$: Actions,
    private store$: Store<CoreFeatureState>,
    private api: ApiService,
  ) {
  }
}
