<div
  fxLayout="row wrap"
  fxLayoutAlign="flex-start"
  fxLayoutGap="20px grid"
>
  <div
    fxFlex.gt-md="21%"
    fxFlex.lt-lg="100%"
  >
    <mat-form-field *ngIf="netRegionConfig && !netRegionConfig.enabled || !ngrxNetRegion; else elseNetRegion">
      <input
        matInput
        type="text"
        [placeholder]="netRegionConfig?.translations"
        [ngrxFormControlState]="ngrxNetRegion"
        [ngrxEasyFieldConfig]="netRegionEasyFieldConfig"
        (updateConfig)="updateNetRegionConfig($event)"
        disabled=""
      >
    </mat-form-field>
    <ng-template #elseNetRegion>
      <mat-form-field class="width-formfield">
        <mat-select
          placeholder="{{'netRegion' | i18n | async}}"
          [ngrxEasyFieldConfig]="netRegionEasyFieldConfig"
          [ngrxFormControlState]="ngrxNetRegion"
          (selectionChange)="selectNetRegion($event)"
          (updateConfig)="updateServiceCenterConfig($event)"
        >
          <mat-option *ngFor="let region of options" [value]="region.name">{{region.name | i18n | async}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>
  </div>
  <div
    fxFlex.gt-md="20%"
    fxFlex.lt-lg="100%"
  >
    <mat-form-field
      *ngIf="netRegionConfig && !serviceCenterConfig.enabled || !ngrxServiceCenter; else elseServiceCenter"
    >
      <input
        matInput
        type="text"
        [placeholder]="serviceCenterConfig?.translations"
        [value]="ngrxServiceCenter.value"
        [ngrxEasyFieldConfig]="serviceCenterEasyFieldConfig"
        disabled=""
      >
    </mat-form-field>
    <ng-template #elseServiceCenter>
      <mat-form-field class="width-formfield" *ngIf="!(ngrxNetRegion?.value); else elseServiceCenter2">
        <mat-select
          placeholder="{{'serviceCenter' | i18n | async}}"
          disabled=""
          [value]="undefined"
        >
          <mat-option [value]="undefined">{{'chooseNetRegion' | i18n | async}}</mat-option>
        </mat-select>
        <mat-hint>{{'chooseNetRegion' | i18n | async}}</mat-hint>
      </mat-form-field>
      <ng-template #elseServiceCenter2>
        <mat-form-field>
          <mat-select
            placeholder="{{'serviceCenter' | i18n | async}}"
            [ngrxEasyFieldConfig]="serviceCenterEasyFieldConfig"
            [ngrxFormControlState]="ngrxServiceCenter"
            (selectionChange)="emitAccount($event)"
          >
            <mat-option
              *ngFor="let serviceCenter of serviceCenters"
              [value]="serviceCenter.name"
            >{{serviceCenter.name | i18n | async}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>
    </ng-template>
  </div>
  <div
    fxFlex.gt-md="20%"
    fxFlex.lt-lg="100%"
  >
    <lib-common-form-accounting-input
      [selectedCompany]="selectedCompany$ | async"
      [controlState]="ngrxAccount"
      configName="serviceCenterAccount"
    ></lib-common-form-accounting-input>
  </div>
</div>
