<div>
  <mat-card>
    <mat-card-content>
      <div
        fxLayout="row wrap"
        fxLayoutAlign="space-between"
        fxLayoutGap="10px grid"
      >
        <div
          fxFlex.gt-md="10%"
          fxFlex.lt-lg="100%"
        >
          <mat-form-field class="input-width">
            <input [ngrxFormControlState]="key" matInput [placeholder]="'properAccountID' | i18n | async" required>
          </mat-form-field>
        </div>
        <div
          fxFlex.gt-md="22%"
          fxFlex.lt-lg="100%"
        >
          <mat-form-field class="input-width">
            <input [ngrxFormControlState]="value" matInput [placeholder]="'description' | i18n | async">
          </mat-form-field>
        </div>
        <div
          fxFlex.gt-md="40%"
          fxFlex.lt-lg="100%"
        >
          <lib-common-input-lang-string
            (undoLangClick)="langLabelReset.emit($event)"
            *ngIf="langLabels"
            [strings]="langLabels"
            [undoVisible]="langLabelReset && langLabelReset.observers && langLabelReset.observers.length > 0"
          ></lib-common-input-lang-string>
        </div>
        <div
          fxFlex.gt-md="19%"
          fxFlex.lt-lg="100%"
        >
          <mat-form-field class="input-width">
            <mat-label>{{'categories' | i18n | async}}</mat-label>
            <mat-select
              multiple
              [ngrxFormControlState]="categories"
            >
              <mat-option *ngFor="let availableCategory of availableCategories"
                          [value]="availableCategory.id">{{availableCategory.displayName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          fxFlex.gt-md="5%"
          fxFlex.lt-lg="100%"
        >
          <button type="button" (click)="openPrompt()" mat-button>
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
