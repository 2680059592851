import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { AppConfigService } from '../../../core/services/app-config.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent {
  @ViewChild('hidden', {static: true}) hiddenViewChild;

  build = this.appConfigService.config.build;

  constructor(private appConfigService: AppConfigService) {}

  /**
   * kopiere Versionsinfos in Zwischenablage
   * @param e Event
   */
  copy(e) {
    e.preventDefault();

    if (((<any>document).selection)) { // IE
      const range = (<any>document).body.createTextRange();
      range.moveToElementText(this.hiddenViewChild.nativeElement);
      range.select();
    } else if (window.getSelection) {
      const range = document.createRange();
      range.selectNode(this.hiddenViewChild.nativeElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }

    (<any>document).execCommand('copy');
  }
}
