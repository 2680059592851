import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { share } from 'rxjs/operators';

@Component({
  selector: 'lib-common-legal-disclosure',
  templateUrl: './legal-disclosure.component.html',
  styleUrls: ['./legal-disclosure.component.scss'],
})
export class LegalDisclosureComponent {

  legalDisclosureContent = this.http.get('/assets/legaldisclosure.html', { responseType: 'text'}).pipe(
    share(),
  );

  constructor(private http: HttpClient) {
  }

}
