<div class="margin-content">
  <form (ngSubmit)="save()">
    <lib-common-form-flex-container [maxWidth]="'1500px'">
      <mat-card-title>{{'categoryInfos' | i18n | async}}</mat-card-title>
      <mat-accordion class="card-width">
        <ng-container *ngFor="let categoryInfo of (categoryInfos$ | async); index as index; trackBy: getControlId">
          <mat-expansion-panel
            *ngIf="!categoryInfo.value.markedForDeletion"
            (expandedChange)="open(categoryInfo.controls.open,$event)"
            [expanded]="categoryInfo.value.open"
          >
            <mat-expansion-panel-header>
              <mat-panel-title><strong>{{getTitle(categoryInfo.value.category)}}</strong></mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card-content>
              <lib-common-form-flex-container>
                <div
                  fxFlex.gt-md="90%"
                  fxFlex.lt-lg="100%"
                >

                  <lib-common-select-search
                    [placeholderI18nKey]="'category'"
                    [options]="getOptions(categoryInfo.value.category)| async"
                    [getDisplayText]="getDisplayText"
                    [selectState]="categoryInfo.controls.category"
                  >
                  </lib-common-select-search>
                </div>
                <div
                  fxFlex.gt-md="3%"
                  fxFlex.lt-lg="100%"
                >
                  <button class="delete-button" type="button" (click)="removeCategoryInfo(index)" mat-icon-button>
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </lib-common-form-flex-container>
              <lib-common-form-flex-container>
                <div
                  fxFlex.gt-md="100%"
                  fxFlex.lt-lg="100%"
                >
                  <lib-common-angular-editor
                    [config]="{height: '5rem', placeholder: 'answer' | i18n | async}"
                    [controlState]="categoryInfo.controls.infoText"
                  ></lib-common-angular-editor>
                </div>
              </lib-common-form-flex-container>
            </mat-card-content>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="button"
          (click)="addCategory()"
          color="accent"
          mat-fab
        >
          <mat-icon svgIcon="innogy:plus"></mat-icon>
        </button>
      </div>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="submit"
          color="primary"
          mat-raised-button
          [disabled]="isFormInvalid$ | async"
        >
          <mat-icon
            class="svg-icon-floppy"
            svgIcon="innogy:floppy"
          ></mat-icon>
          {{ 'save' | i18n | async }}
        </button>
      </div>
    </lib-common-form-flex-container>
  </form>
</div>
