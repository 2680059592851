<div class="grid-container">
  <lib-common-form-flex-container>
    <div
      *ngFor="let location of locations$ | async"
      class="text-center"
      fxFlex="0 1 400px"
    >
      <mat-card>
        <mat-card-content fxLayout="row" fxLayoutAlign="center">
          <mat-card-title>
            {{ location.name }}
          </mat-card-title>
        </mat-card-content>
        <mat-card-actions>
          <a
            role="button"
            [routerLink]="[location.id]"
            mat-button
            [title]="'edit' | i18n | async"
          >
            <mat-icon
              class="svgIcon-pen"
              svgIcon="innogy:pen"
            ></mat-icon>
            {{ 'edit' | i18n | async }}
          </a>
        </mat-card-actions>
      </mat-card>
    </div>
  </lib-common-form-flex-container>
</div>
