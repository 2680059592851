<lib-common-room-picker
  [rooms]="rooms"
  [roomFormControlState]="roomFormControlState"
  [alternativeRoomNameFormControlState]="alternativeRoomNameFormControlState"
  [easyFieldConfig]="easyFieldConfig"
  [asTextInput]="isTextFormControlState.value"
  [selectRoomI18n]="selectRoomI18n"
  [enterRoomI18n]="enterRoomI18n"
></lib-common-room-picker>
<mat-slide-toggle
  class="margin-toggle"
  [ngrxFormControlState]="isTextFormControlState"
  [ngrxEasyFieldConfig]="easyFieldConfig"
>
  {{noRoomInListI18n | i18n | async}}
</mat-slide-toggle>
