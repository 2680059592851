import { ActionReducer, createReducer, on } from '@ngrx/store';
import { TableColumnConfigModel } from '../../../core-lib/models/table-column-config.model';
import { TableSelectionModel } from '../../models/table-selection.model';
import { cateringTableActions, proposalTableActions, TableActionCreator } from '../actions/core-overview-table.actions';
import { CateringTableState, initialCateringTableState } from './core-catering-table.store';
import { initialProposalTableState, ProposalTableState } from './core-proposal-table.store';

export interface TableState extends Partial<TableSelectionModel<any>> {
  columnConfig: Partial<TableSelectionModel<TableColumnConfigModel[]>>;
  filterConfig: Partial<TableSelectionModel<TableColumnConfigModel[]>>;
  loading: boolean;
}

export class TableReducerCreator<T extends TableState> {

  constructor(public actionsCreator: TableActionCreator, public initialState: T) {
  }

  proposalTableLoadReducer = on(
    this.actionsCreator.tableLoad,
    (s: T) => (
      {
        ...s,
        loading: true,
      }
    ),
  );

  proposalTableLoadedReducer = on(
    this.actionsCreator.tableLoaded,
    (s: T, {type, content, selection, ...page}) => (
      {
        ...s,
        loading: false,
        [selection]: {
          ...s[selection],
          ...page,
          content: content.length > 0 ?
            content :
            [{author: null, createdAt: null, form: {category: {displayName: ''}}, state: '', id: '', amount: ''}],
        },
      }
    ),
  );

  proposalTableColumnConfigLoadedReducer = on(
    this.actionsCreator.tableColumnConfigLoaded,
    (s: T, configs) => (
      {
        ...s,
        columnConfig: {
          ...s?.columnConfig,
          [configs.selection]: configs.data.length > 0 ? [...configs.data] : [...(s.columnConfig[configs.selection] || [])],
        },
      }
    ),
  );

  proposalTableFilterConfigLoadedReducer = on(
    this.actionsCreator.tableFilterConfigLoaded,
    (s: T, configs) => (
      {
        ...s,
        filterConfig: {
          ...s?.filterConfig,
          [configs.selection]: configs.data.length > 0 ? [...configs.data] : [...(s.columnConfig[configs.selection] || [])],
        },
      }
    ),
  );
}

const proposalTableReducerCreator = new TableReducerCreator(proposalTableActions, initialProposalTableState);
const cateringTableReducerCreator = new TableReducerCreator(cateringTableActions, initialCateringTableState);

export const proposalTableReducer = createReducer(
  proposalTableReducerCreator.initialState,
  proposalTableReducerCreator.proposalTableLoadReducer,
  proposalTableReducerCreator.proposalTableLoadedReducer,
  proposalTableReducerCreator.proposalTableColumnConfigLoadedReducer,
  proposalTableReducerCreator.proposalTableFilterConfigLoadedReducer,
) as ActionReducer<ProposalTableState>;

export const cateringTableReducer = createReducer(
  cateringTableReducerCreator.initialState,
  cateringTableReducerCreator.proposalTableLoadReducer,
  cateringTableReducerCreator.proposalTableLoadedReducer,
  cateringTableReducerCreator.proposalTableColumnConfigLoadedReducer,
  cateringTableReducerCreator.proposalTableFilterConfigLoadedReducer,
) as ActionReducer<CateringTableState>;
