<lib-common-page-margin>
  <div
    fxLayout="row grid"
    fxLayoutAlign="center center"
    fxLayoutGap="10px"
  >
    <div fxFlex="0 1 50%">
      <lib-common-user-search
        [asId]="true"
        [(ngModel)]="selectedUserId"
        (ngModelChange)="setSelectedUser($event)"
      ></lib-common-user-search>
    </div>
    <div>
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="setSelectedUser(selectedUserId)"
      >
        {{'reload' | i18n | async}}
      </button>
    </div>
  </div>
  <lib-common-account-deputies
    [saveUserId]="selectedUserId"
    *ngIf="selectedUserId"
  ></lib-common-account-deputies>
</lib-common-page-margin>
