/**
 * Enum mit dem ausgesteuert wird welche Datei hochgeladen werden können.
 */
export enum FileUploadTypeModel {

  /** Nicht definiert. */
  PDF_ONLY = 'PDF_ONLY',

  /** Datei ist eine Rechnung. */
  NO_EXECUTABLES = 'NO_EXECUTABLES',

}

/**
 *  Map von FileUploadTypeModel zu strings mit dem bei einem  input[type="file"] das "accept"-Attribut gesetzt werden kann.
 */
export const fileUploadTypeModelToAcceptInfo: { [key in FileUploadTypeModel]: string } = {
  [FileUploadTypeModel.PDF_ONLY]: 'application/pdf', // Nur PDFs vorschlagen
  /* accept ist ne whitelist, daher definieren wir hier einfach ne liste von sinnvollen Dateien.
   * !!Wichtig!! Keine Leerzeichen da sonst die validierung nicht richtig funktioniert
   */
  [FileUploadTypeModel.NO_EXECUTABLES]: 'application/pdf,image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.oasis.opendocument.text,application/vnd.oasis.opendocument.spreadsheet',
};
