<div class="proposal-table-margin">
  <mat-tab-group dynamicHeight>
    <mat-tab label="{{ 'openCatering' | i18n | async }}">
      <ng-template matTabContent>
        <div class="datatabel-margin">
          <lib-common-catering-table type="PENDING"></lib-common-catering-table>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'cateringInProgress' | i18n | async }}">
      <ng-template matTabContent>
        <div class="datatabel-margin">
          <lib-common-catering-table type="IN_PROGRESS"></lib-common-catering-table>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'cateringAsDeputy' | i18n | async }}">
      <ng-template matTabContent>
        <div class="datatabel-margin">
          <lib-common-catering-table type="DEPUTY"></lib-common-catering-table>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'finishedCatering' | i18n | async }}">
      <ng-template matTabContent>
        <div class="datatabel-margin">
          <lib-common-catering-table type="FINISHED"></lib-common-catering-table>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
