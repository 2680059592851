import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AddArrayControlAction, box, FormGroupState, RemoveArrayControlAction } from 'ngrx-forms';
import { map, takeUntil } from 'rxjs/operators';
import { EasyFormModel } from '../../../core-lib/models/easy-form.model';
import { AccountFormMappingModel } from '../../models/account-form-mapping.model';
import { NetRegionModel } from '../../models/net-region.model';
import { ServiceCenterModel } from '../../models/service-center.model';
import {
  coreConfigServiceCenterDataLoad,
  coreConfigServiceCenterDataSave,
  coreConfigServiceCenterMarkForDeletion,
} from '../../ngrx/actions/core-config.actions';
import { getCompaniesState, getCompanyByDynamicIdState, getConfigServiceCenterFormState } from '../../ngrx/reducers/core.store';
import { getEasyFormsEntityValuesStateFiltered } from '../../ngrx/reducers/easy-forms.reducer';

@Component({
  selector: 'lib-common-route-config-service-center',
  templateUrl: './route-config-service-center.component.html',
  styleUrls: ['./route-config-service-center.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteConfigServiceCenterComponent implements OnInit, OnDestroy {

  companies$ = this.store$.select(getCompaniesState);

  netRegionState$ = this.store$.select(getConfigServiceCenterFormState);
  netRegionArrayState$ = this.netRegionState$.pipe(
    map(s => s.controls),
  );

  isFormInvalid$ = this.store$.select(getConfigServiceCenterFormState).pipe(
    map(form => form.isInvalid),
  );

  arrayId: string;
  private componentDestroyed$ = new EventEmitter();

  constructor(private store$: Store<any>) {
  }

  getControlId = (i: number, t: FormGroupState<NetRegionModel>) => t.id;
  getFormDisplayText = (form: EasyFormModel) => form.fullName;
  getFormId = (form: EasyFormModel) => form.identifier;

  ngOnInit() {
    this.netRegionState$.pipe(
      takeUntil(this.componentDestroyed$),
    ).subscribe(s => this.arrayId = s.id);
    this.store$.dispatch(coreConfigServiceCenterDataLoad());
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  save() {
    this.store$.dispatch(coreConfigServiceCenterDataSave());
  }

  private addElement<T extends AccountFormMappingModel | ServiceCenterModel | NetRegionModel>(arrayId: string, value: T) {
    this.store$.dispatch(new AddArrayControlAction<T>(arrayId, value));
  }

  addFormAccountMapping(arrayId: string) {
    this.addElement<AccountFormMappingModel>(arrayId, {formIdentifier: '', account: box(undefined)});
  }

  addServiceCenter(arrayId: string) {
    this.addElement<ServiceCenterModel>(arrayId, {name: '', accountFormMappings: []});
  }

  addNetRegion(arrayId: string) {
    this.addElement<NetRegionModel>(arrayId, {name: '', company: undefined, serviceCenters: [], markedForDeletion: false});
  }

  markRegionAsDeleted(index: number) {
    this.store$.dispatch(coreConfigServiceCenterMarkForDeletion({index}));
  }

  getServiceCenterControls(netRegion: FormGroupState<NetRegionModel>): readonly FormGroupState<ServiceCenterModel>[] {
    return netRegion.controls?.serviceCenters?.controls;
  }

  getAccountMappings(serviceCenter: FormGroupState<ServiceCenterModel>): readonly FormGroupState<AccountFormMappingModel>[] {
    return <FormGroupState<AccountFormMappingModel>[]>serviceCenter.controls?.accountFormMappings?.controls;
  }

  getCompany(company: string) {
    return this.store$.select(getCompanyByDynamicIdState).pipe(
      map(getCompanyFn => getCompanyFn(company)),
    );
  }

  getFormOptions(serviceCenter: ServiceCenterModel, currentSelectedFormIdentifier: string) {
    return this.store$.select(getEasyFormsEntityValuesStateFiltered, {serviceCenter, currentSelectedFormIdentifier});
  }

  removeEntry(id: string, index: number) {
    this.store$.dispatch(new RemoveArrayControlAction(id, index));
  }

}
