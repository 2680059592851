import { ConfigItemCheckboxConfigurationDtoModel } from '../../../core-lib/models/config-item-checkbox-configuration-dto.model';
import { EasyFormAConfigItems, EasyFormASteps } from '../../../forms-lib/models/easy-form-a-config.model';

export class EasyFormA2ConfigItems extends EasyFormAConfigItems {

  alternativePayee = new ConfigItemCheckboxConfigurationDtoModel('alternativePayee').withCategory(EasyFormASteps.step2);

  constructor() {
    super();
  }
}
