import { Action, createAction, props } from '@ngrx/store';
import { CategoryDataDto } from '../../../core-lib/models/category-data-dto.model';
import { CategoryInfoModel } from '../../../core-lib/models/category-info.model';
import { FaqTreeModel } from '../../../core-lib/models/faq-tree.model';
import { GlobalConfigDtoModel } from '../../../core-lib/models/global-config-dto.model';
import { SystemMessage } from '../../../core-lib/models/system-message.model';
import TranslationMapModel from '../../../core-lib/models/translation-map.model';
import { AccountFormMappingModel } from '../../models/account-form-mapping.model';
import { Article } from '../../models/article.model';
import { CateringOrderData } from '../../models/catering-order-data.model';
import { FileUploadModel } from '../../models/file-upload.model';
import { LocationInfo } from '../../models/location-info.model';
import { NetRegionModel } from '../../models/net-region.model';
import { Room } from '../../models/room.model';
import { ApiErrorAction, ApiSuccessAction } from './core.actions';

export class CoreConfigInit implements Action {
  static readonly TYPE = 'core/config/Init';
  readonly type = CoreConfigInit.TYPE;
}

export class CoreConfigTaxratesSave implements Action {
  static readonly TYPE = 'core/config/taxrates/Save';
  readonly type = CoreConfigTaxratesSave.TYPE;
}

export class CoreConfigTaxRatesSaveSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/core/config/taxrates/SaveSuccess';
  readonly type =  CoreConfigTaxRatesSaveSuccess.TYPE;
  messageKey = 'coreConfigTaxRatesSaveSuccess';
  snackBarDuration = 5000;

  constructor(public taxRates: GlobalConfigDtoModel) {
    super();
  }
}

export class CoreConfigTaxRatesSaveError extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/core/config/taxrates/SaveError';
  readonly type = CoreConfigTaxRatesSaveError.TYPE;

}

export class CoreConfigLoadedGlobalConfig implements Action {
  static readonly TYPE = 'core/config/taxrates/LoadedConfig';
  readonly type = CoreConfigLoadedGlobalConfig.TYPE;

  constructor(public config: GlobalConfigDtoModel) {
  }
}

export class CoreConfigLedgerAccountsSave implements Action {
  static readonly TYPE = 'core/config/ledgerAccounts/Save';
  readonly type = CoreConfigLedgerAccountsSave.TYPE;
}

export class CoreConfigLedgerAccountsSaveSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/core/config/ledgerAccounts/SaveSuccess';
  readonly type = CoreConfigLedgerAccountsSaveSuccess.TYPE;
  messageKey = 'coreConfigLedgerAccountsSaveSuccess';
  snackBarDuration = 5000;

  constructor(public taxrates: GlobalConfigDtoModel) {
    super();
  }
}

export class CoreConfigLedgerAccountsSaveError extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/core/config/ledgerAccounts/SaveError';
  readonly type = CoreConfigLedgerAccountsSaveError.TYPE;
}

export class CoreConfigSalutationsSave implements Action {
  static readonly TYPE = 'core/config/salutations/Save';
  readonly type = CoreConfigSalutationsSave.TYPE;
}

export class CoreConfigSalutationsSaveSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/core/config/salutations/SaveSuccess';
  readonly type = CoreConfigSalutationsSaveSuccess.TYPE;
  messageKey = 'coreConfigSalutationsSaveSuccess';
  snackBarDuration = 5000;

  constructor(public salutations: GlobalConfigDtoModel) {
    super();
  }
}

export class CoreConfigSalutationsSaveError extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/core/config/salutations/SaveError';
  readonly type = CoreConfigSalutationsSaveError.TYPE;
}

export class CoreActionLoadAllTranslations implements Action {
  static readonly TYPE = 'core/config/translations/Load';
  readonly type = CoreActionLoadAllTranslations.TYPE;
}

export class CoreActionLoadedAllTranslationsSuccess implements Action {
  static readonly TYPE = 'core/config/translations/LoadedSuccess';
  readonly type = CoreActionLoadedAllTranslationsSuccess.TYPE;

  constructor(public translations: TranslationMapModel, public language: string) {
  }
}

export class CoreActionCategoriesLoaded implements Action {
  static readonly TYPE = 'core/LoadedCategories';
  readonly type = CoreActionCategoriesLoaded.TYPE;

  constructor(public categories: CategoryDataDto[]) {
  }
}

export class CoreActionLoadedAllTranslationsFailed implements Action {
  static readonly TYPE = 'core/config/translations/LoadedFailed';
  readonly type = CoreActionLoadedAllTranslationsFailed.TYPE;
}

export class DeleteConfigItemAction implements Action {
  static readonly TYPE = 'core/config/delete/config/item';
  readonly type = DeleteConfigItemAction.TYPE;

  constructor(public configId: string) {
  }
}

export class DeleteConfigItemSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/core/config/delete/config/item/success';
  readonly type = DeleteConfigItemSuccess.TYPE;
  messageKey = 'deleteConfigItemSuccess';
  snackBarDuration = 5000;
}

export class DeleteConfigItemError extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/core/config/delete/config/item/error';
  readonly type = DeleteConfigItemError.TYPE;
}

export class CoreI18nSetLanguage implements Action {
  static readonly TYPE = 'CoreI18nSetLanguage';
  readonly type = CoreI18nSetLanguage.TYPE;

  constructor(public langKey: string) {
  }
}

export class CoreConfigSetSystemMessage implements Action {
  static readonly TYPE = 'CoreConfigSetSystemMessage';
  readonly type = CoreConfigSetSystemMessage.TYPE;

  constructor(public systemMessageSet: SystemMessage) {
  }
}

export class CoreConfigSystemMessageAdminLoad implements Action {
  static readonly TYPE = 'core/config/systemMessage/Load';
  readonly type = CoreConfigSystemMessageAdminLoad.TYPE;
}

export class CoreConfigSystemMessageSave implements Action {
  static readonly TYPE = 'core/config/systemMessage/Save';
  readonly type = CoreConfigSystemMessageSave.TYPE;
}

export class CoreConfigSystemMessageSaveSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/core/config/systemMessage/SaveSuccess';
  readonly type = CoreConfigSystemMessageSaveSuccess.TYPE;
  messageKey = 'coreConfigSystemMessageSaveSuccess';
  snackBarDuration = 5000;

  constructor(public systemMessage: SystemMessage) {
    super();
  }
}

export class CoreConfigSystemMessageSaveError extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/core/config/systemMessage/SaveError';
  readonly type = CoreConfigSystemMessageSaveError.TYPE;
}

export class CoreConfigSystemMessageAdminLoadSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/core/config/systemMessage/LoadSuccess';
  readonly type = CoreConfigSystemMessageAdminLoadSuccess.TYPE;
  messageKey = 'coreConfigSystemMessageAdminLoadSuccess';
  snackBarDuration = 5000;

  constructor(public systemMessage: SystemMessage) {
    super();
  }
}

export class CoreConfigSystemMessageAdminLoadError extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/core/config/systemMessage/LoadError';
  readonly type = CoreConfigSystemMessageAdminLoadError.TYPE;
}

export class CoreConfigLocationInfoLoad implements Action {
  static readonly TYPE = 'core/config/LocationInfo/Load';
  readonly type = CoreConfigLocationInfoLoad.TYPE;
}

export class CoreConfigLocationInfoLoadSuccess implements Action {
  static readonly TYPE = 'core/config/LocationInfo/LoadSuccess';
  readonly type = CoreConfigLocationInfoLoadSuccess.TYPE;

  constructor(public locations: LocationInfo[]) {
  }
}

export class CoreConfigLocationInfoLoadError extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/core/config/LocationInfo/LoadError';
  readonly type = CoreConfigLocationInfoLoadError.TYPE;
}

export class CoreConfigLocationRoomSelected implements Action {
  static readonly TYPE = 'core/config/RoomLocation/Selected';
  readonly type = CoreConfigLocationRoomSelected.TYPE;

  constructor(public rooms: Room[]) {
  }
}

export class CoreConfigLocationRoomSave implements Action {
  static readonly TYPE = 'core/config/RoomLocation/Save';
  readonly type = CoreConfigLocationRoomSave.TYPE;

  constructor(public locationId: string) {
  }
}

export class CoreConfigLocationRoomSaveSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/core/config/RoomLocation/SaveSuccess';
  readonly type = CoreConfigLocationRoomSaveSuccess.TYPE;
  messageKey = 'coreConfigLocationRoomSaveSuccess';
  snackBarDuration = 5000;

  constructor(public rooms: Room[]) {
    super();
  }
}

export class CoreConfigLocationRoomSaveError extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/core/config/RoomLocation/SaveError';
  readonly type = CoreConfigLocationRoomSaveError.TYPE;
}

export class CoreConfigLocationArticleSelected implements Action {
  static readonly TYPE = 'core/config/ArticleLocation/Selected';
  readonly type = CoreConfigLocationArticleSelected.TYPE;

  constructor(public articles: Article[]) {
  }
}

export class CoreConfigLocationArticleSave implements Action {
  static readonly TYPE = 'core/config/ArticleLocation/Save';
  readonly type = CoreConfigLocationArticleSave.TYPE;

  constructor(public locationId: string) {
  }
}

export class CoreConfigLocationArticleSaveError extends ApiErrorAction {
  static readonly TYPE = 'ApiErrorAction/core/config/ArticleLocation/SaveError';
  readonly type = CoreConfigLocationArticleSaveError.TYPE;
}

export class CoreConfigLocationArticleSaveSuccess extends ApiSuccessAction {
  static readonly TYPE = 'ApiSuccessAction/core/config/ArticleLocation/SaveSuccess';
  readonly type = CoreConfigLocationArticleSaveSuccess.TYPE;
  messageKey = 'coreConfigLocationArticleSaveSuccess';
  snackBarDuration = 5000;

  constructor(public articles: Article[]) {
    super();
  }
}

export const coreConfigCateringOrderDataLoad = createAction('core/config/cateringOrderData/load');

export const coreConfigCateringOrderDataLoadSuccess = createAction(
  'core/config/cateringOrderData/load/success',
  props<{ data: CateringOrderData[] }>(),
);

export const coreConfigCateringOrderDataSave = createAction('core/config/cateringOrderData/save');

export const coreConfigCateringOrderDataSaveSuccess = createAction(
  'core/config/cateringOrderData/save/success',
  props<{ data: CateringOrderData[] }>(),
);

export const coreConfigCateringOrderDataSaveError = createAction('core/config/cateringOrderData/save/error');

export const coreConfigFaqDataLoad = createAction('core/config/faq/load');

export const coreConfigFaqDataLoadSuccess = createAction(
  'core/config/faq/load/success',
  props<{ data: FaqTreeModel[] }>(),
);

export const coreConfigFaqDataSave = createAction('core/config/faq/save');

export const coreConfigFaqMarkElementForDeletion = createAction(
  'core/config/faq/markElementForDeletion',
  props<{ treeId: string, itemId: string }>(),
);
export const coreConfigFaqMarkTreeForDeletion = createAction(
  'core/config/faq/markTreeForDeletion',
  props<{ treeId: string }>(),
);

export const coreConfigFaqSaveSuccess = createAction(
  'core/config/faq/save/success',
  props<{ data: FaqTreeModel[] }>(),
);

export const coreConfigFaqSaveError = createAction('core/config/faq/save/error');

export const coreConfigMassDataDownload = createAction(
  'core/config/massDataProcessing/download',
  props<{ formIdentifier: string, companyIdentifier: string, count: number }>(),
);

export const coreConfigMassDataUpload = createAction(
  'core/config/massDataProcessing/upload',
  props<{ formIdentifier: string, companyIdentifier: string, file: FileUploadModel }>(),
);

export const coreConfigServiceCenterDataSave = createAction('core/config/serviceCenter/save');
export const coreConfigServiceCenterDataSaveSuccess = createAction(
  'core/config/serviceCenter/save/success',
  props<{ data: NetRegionModel<AccountFormMappingModel>[] }>(),
);
export const coreConfigServiceCenterMarkForDeletion = createAction('core/config/serviceCenter/markForDeletion', props<{ index: number }>());

export const coreConfigServiceCenterDataLoad = createAction('core/config/serviceCenter/load');
export const coreConfigServiceCenterDataLoadSuccess = createAction(
  'core/config/serviceCenter/load/success',
  props<{ data: NetRegionModel<AccountFormMappingModel>[] }>(),
);

export const coreConfigCategoryInfoSave = createAction('core/config/categoryinfo/save');
export const coreConfigCategoryInfoSaveSuccess = createAction(
  'core/config/categoryinfo/save/success',
  props<{ data: CategoryInfoModel[] }>(),
);
export const coreConfigCategoryInfoMarkForDeletion = createAction('core/config/categoryinfo/markForDeletion', props<{ index: number }>());

export const coreConfigCategoryInfoLoad = createAction('core/config/categoryinfo/load');
export const coreConfigCategoryInfoLoadSuccess = createAction(
  'core/config/categoryinfo/load/success',
  props<{ data: CategoryInfoModel[] }>(),
);

export const coreMassDataUploadSuccess = createAction('core/config/massdata/upload/success');


export type CoreConfigActions =
  CoreConfigInit |
  CoreActionCategoriesLoaded |
  CoreConfigTaxratesSave |
  CoreConfigTaxRatesSaveSuccess |
  CoreConfigTaxRatesSaveError |
  CoreConfigLedgerAccountsSave |
  CoreConfigLedgerAccountsSaveSuccess |
  CoreConfigLedgerAccountsSaveError |
  CoreConfigSalutationsSave |
  CoreConfigSalutationsSaveSuccess |
  CoreConfigSalutationsSaveError |
  CoreConfigLoadedGlobalConfig |
  CoreActionLoadAllTranslations |
  CoreActionLoadedAllTranslationsSuccess |
  CoreActionLoadedAllTranslationsFailed |
  DeleteConfigItemAction |
  DeleteConfigItemError |
  DeleteConfigItemSuccess |
  CoreI18nSetLanguage |
  CoreConfigSetSystemMessage |
  CoreConfigSystemMessageAdminLoadSuccess |
  CoreConfigLocationInfoLoad |
  CoreConfigLocationInfoLoadSuccess |
  CoreConfigLocationInfoLoadError |
  CoreConfigLocationRoomSelected |
  CoreConfigLocationRoomSave |
  CoreConfigLocationRoomSaveError |
  CoreConfigLocationRoomSaveSuccess |
  CoreConfigLocationArticleSave |
  CoreConfigLocationArticleSaveError |
  CoreConfigLocationArticleSaveSuccess |
  CoreConfigLocationArticleSelected |
  { type: 'core/config/cateringOrderData/load/success', data: CateringOrderData[] } |
  { type: 'core/config/cateringOrderData/save/success', data: CateringOrderData[] } |
  { type: 'core/config/faq/load/success', data: FaqTreeModel[] } |
  { type: 'core/config/faq/save/success', data: FaqTreeModel[] } |
  { type: 'core/config/faq/markElementForDeletion', treeId: string, itemId: string } |
  { type: 'core/config/faq/markTreeForDeletion', treeId: string } |
  { type: 'core/config/serviceCenter/markForDeletion', index: number } |
  { type: 'core/config/serviceCenter/load/success', data: NetRegionModel<AccountFormMappingModel>[] } |
  { type: 'core/config/serviceCenter/save/success', data: NetRegionModel<AccountFormMappingModel>[] } |
  { type: 'core/config/categoryinfo/markForDeletion', index: number } |
  { type: 'core/config/categoryinfo/load/success', data: CategoryInfoModel[] } |
  { type: 'core/config/categoryinfo/save/success', data: CategoryInfoModel[] };
