export enum FileUploadCategoryModel {

  /** Nicht definiert. */
  UNDEFINED = 'UNDEFINED',

  /** Datei ist eine Rechnung. */
  INVOICE = 'INVOICE',

  /** Teilnehmerliste (bspw. für "Erstattung betriebliche Bewirtung"). */
  PARTICIPANT_LIST = 'PARTICIPANT_LIST',

  /** Unterschriebener Vertrag (z.B. Formular A) */
  CONTRACT = 'CONTRACT',

  /** Sonstiges. */
  OTHERS = 'OTHERS',

  /** erstellte Gutschriften aller Arten */
  CREDIT = 'CREDIT',

  /** Angebot */
  OFFER = 'OFFER',

  /** Freistellungsbescheinigung */
  RELEASE = 'RELEASE',

}
