import { Component, HostListener } from '@angular/core';
import { DebugInfoService } from '../../services/debug-info.service';

@Component({
  selector: 'lib-common-common-app',
  template: '',
})
export class CommonAppComponent {
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.shiftKey && event.code === 'KeyX') {
      this.debugInfoService.downloadZIP();
    }
  }

  constructor(
    protected debugInfoService: DebugInfoService,
  ) {
  }
}
