<mat-form-field>
  <mat-label>{{ 'language' | i18n | async }}</mat-label>
  <mat-select
    [(ngModel)]="selectedLanguage"
    (ngModelChange)="select.emit($event)"
  >
    <mat-option
      *ngFor="let lang of langs"
      [value]="lang"
    >
      {{ lang.displayName }}
    </mat-option>
  </mat-select>
</mat-form-field>
