import { Component, Input } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { FormControlState, ValidationErrors } from 'ngrx-forms';
import { Room } from '../../../core/models/room.model';

@Component({
  selector: 'lib-common-room-picker',
  templateUrl: './room-picker.component.html',
  styleUrls: ['./room-picker.component.scss'],
})
export class RoomPickerComponent {

  private _alternativeRoomNameFormControlState: FormControlState<string>;

  get alternativeRoomNameFormControlState(): FormControlState<string> {
    return this._alternativeRoomNameFormControlState;
  }

  @Input()
  set alternativeRoomNameFormControlState(value: FormControlState<string>) {
    this._alternativeRoomNameFormControlState = value;
    if (value) {
      this.altRoomErrors = value.errors;
      this.altRoomErrorKeys = Object.keys(this.altRoomErrors);
    }
  }

  private _roomFormControlState: FormControlState<string>;

  get roomFormControlState(): FormControlState<string> {
    return this._roomFormControlState;
  }

  @Input()
  set roomFormControlState(value: FormControlState<string>) {
    this._roomFormControlState = value;
    if (value) {
      this.roomErrors = value.errors;
      this.roomErrorKeys = Object.keys(this.roomErrors);
    }
  }

  @Input() rooms: Room[];
  @Input() asTextInput = false;
  @Input() easyFieldConfig: string;
  @Input() selectRoomI18n = 'selectRoom';
  @Input() enterRoomI18n = 'enterRoom';

  roomErrors: Dictionary<string>;
  roomErrorKeys: string[];

  altRoomErrors: Dictionary<string>;
  altRoomErrorKeys: string[];
}
