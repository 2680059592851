import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { getSessionUserIsAdmin } from '../../../session/reducers/session.reducer';
import { CoreFeatureState } from '../../ngrx/reducers/core.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-route-form-overview',
  templateUrl: './route-form-overview.component.html',
  styleUrls: ['./route-form-overview.component.scss'],
})
export class RouteFormOverviewComponent {

  isAdmin$ = this.store$.select(getSessionUserIsAdmin);

  constructor(private store$: Store<CoreFeatureState>) {

  }
}
