import { ChangeDetectionStrategy, Component, Injectable, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AddArrayControlAction, FormArrayState, FormControlState, RemoveArrayControlAction } from 'ngrx-forms';
import { filter } from 'rxjs/operators';
import { backendClasses } from '../../../core-lib/models/backend-classes.model';
import { ConfigItemArrangerConfigurationDtoModel } from '../../../core-lib/models/config-item-arranger-configuration-dto.model';
import { ConfigItemUnionType } from '../../../core-lib/models/config-list-dto.model';
import { SetValueTraceableAction } from '../../../core-lib/models/set-value-traceable-action';
import { getGlobalConfigItemByName } from '../../ngrx/reducers/core.store';
import {
  getEasyFormConfigsSelectedItemPropStateFn,
  getEasyFormConfigsSelectedItemStateFn, getEasyFormDateRangeConfigsSelectedItemStateFn,
} from '../../ngrx/reducers/easy-form-configs.reducer';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-route-config-form-property',
  templateUrl: './route-config-form-property.component.html',
  styleUrls: ['./route-config-form-property.component.scss'],
})
@Injectable()
export class RouteConfigFormPropertyComponent {
  @Input() formName: string;
  @Input() globalPropertyName: string;
  @Input() className: string;
  @Input() readonly: boolean;
  backendClasses = backendClasses;

  constructor(private store$: Store<any>) {
  }

  getConfig$(itemName: string) {
    return this.store$.select(getEasyFormConfigsSelectedItemStateFn, {itemName});
  }

  getDateRangeConfig$(itemName: string) {
    return this.store$.select(getEasyFormDateRangeConfigsSelectedItemStateFn, {itemName});
  }

  getConfigProp$(itemName: string, propName: keyof ConfigItemUnionType) {
    return this.store$.select(getEasyFormConfigsSelectedItemPropStateFn, {itemName, propName}).pipe(
      filter((item) => !!item),
    );
  }

  setFormControlValue(
    $event: FormControlState<string>,
    name: string,
  ) {
    this.store$.dispatch(new SetValueTraceableAction($event.id, name));
  }

  addArranger(array: FormArrayState<ConfigItemArrangerConfigurationDtoModel>) {
    this.store$.dispatch(new AddArrayControlAction(
      array.id,
      new ConfigItemArrangerConfigurationDtoModel(this.formName, array.value.length),
    ));
  }

  removeArranger(array: FormArrayState<ConfigItemArrangerConfigurationDtoModel>, index: number) {
    this.store$.dispatch(new RemoveArrayControlAction(array.id, index));
  }

  getGlobalProperty$() {
    if (this.globalPropertyName) {
      return this.store$.select(getGlobalConfigItemByName, this.globalPropertyName);
    }
  }
}
