import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { FormGroupState } from 'ngrx-forms';
import { IdDtoModel } from '../../../forms/all-forms/models/id-dto.model';

@Component({
  selector: 'lib-common-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent {
  @Input() layout = 'row wrap';
  @Input() approverControls: FormGroupState<IdDtoModel>[] = [];
  @Input() valueConverter;
  @Input() removable: boolean = false;
  @Input() maxSize: number;
  @Input() minSize = -1;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() enableable: false; // displays enable checkbox
  @Input() enableTextI18n = 'enableApprover';
  @Input() enablePropertyName: string; // used like .controls[enablePropertyName] if enableable is true
  @Input() asId = false; // just gives user id as string
  @Input() asIdPropertyName = 'id'; // if asId is true, use this control name like .controls[asIdPropertyName].
  @Input() addButtonColor: ThemePalette = 'accent';
  @Output() addClick = new EventEmitter();
  @Output() removeClick = new EventEmitter<FormGroupState<IdDtoModel>>();
  @Input() configName: string;
  @Input() placeholder: string;
  @Input() hasValueFn = (approver) => this.getIdProperty(approver?.value);

  getIdProperty(o) {
    return o && o[this.asIdPropertyName];
  }

  isRequiredChild(approver: FormGroupState<IdDtoModel>, index: number) {
    return index < this.minSize && this.required;
  }

  isDisabledChild(approver: FormGroupState<IdDtoModel>, index: number) {
    return this.disabled || approver.isDisabled;
  }

  getToggleControlState(idDtoModelFormGroupState: FormGroupState<IdDtoModel>) {
    return idDtoModelFormGroupState.controls[this.enablePropertyName];
  }

  isMinReached() {
    const minSizeOrRequiredMinSize = this.minSize > 0 ? this.minSize : this.required ? 1 : -1;
    return minSizeOrRequiredMinSize > 0 ? this.approverControls?.length >= minSizeOrRequiredMinSize : true;
  }

  canAdd() {
    return this.isNotMaxReached() && this.lastItemHasValue();
  }

  isNotMaxReached() {
    return this.maxSize === undefined || (this.approverControls?.length + 1) <= this.maxSize;
  }

  lastItemHasValue() {
    return this.approverControls?.length === 0
      || this.hasValueFn && this.hasValueFn(this.approverControls && this.approverControls[this.approverControls.length - 1]);
  }
}
