import { createAction, props } from '@ngrx/store';
import { ProcessQueueModel } from '../../models/process-queue.model';


export const loadProcessQueueAction = createAction('coreLoadProcessQueueAction');
export const loadProcessQueueSuccessAction = createAction('coreLoadProcessQueueSuccessAction', props<{ data: ProcessQueueModel[] }>());

export const resetProcessQueueAction = createAction('resetProcessQueueAction', props<{ ids: number[] }>());
export const resetProcessQueueSuccessAction = createAction('resetProcessQueueSuccessAction');

export const deleteProcessQueueAction = createAction('deleteProcessQueueAction', props<{ id: number }>());
export const deleteProcessQueueSuccessAction = createAction('deleteProcessQueueSuccessAction');
