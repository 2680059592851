import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSpinner } from '@angular/material/progress-spinner';
import { Store } from '@ngrx/store';
import { getSessionIsSpinnerOpen, getSessionSpinnerLocks, SessionState } from '../../reducers/session.reducer';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-session-spinner',
  templateUrl: './session-spinner.component.html',
  styleUrls: ['./session-spinner.component.scss'],
})
export class SessionSpinnerComponent {
  isOpen$ = this.store$.select(getSessionIsSpinnerOpen);
  isOpenBy$ = this.store$.select(getSessionSpinnerLocks);
  private overlayRef: OverlayRef = this.cdkSpinnerCreate();
  private openerTimeout = undefined;
  private wasOpen = false;

  constructor(
    private overlay: Overlay,
    private store$: Store<SessionState>,
  ) {
    this.isOpen$.subscribe((isOpen) => {
      if (isOpen && !this.wasOpen) {
        this.showSpinner();
        this.wasOpen = true;
      } else if (!isOpen && this.wasOpen) {
        this.stopSpinner();
        this.wasOpen = false;
      }
    });
  }

  showSpinner() {
    if (!this.openerTimeout) {
      this.openerTimeout = setTimeout(() => {
        this.overlayRef.attach(new ComponentPortal(MatSpinner));
      }, 200);
    }
  }

  stopSpinner() {
    clearTimeout(this.openerTimeout);
    this.openerTimeout = undefined;
    this.overlayRef.detach();
  }

  private cdkSpinnerCreate() {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'lib-spinner-backdrop',
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });
  }
}
