import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { debounceTime, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from '../../../core-lib/services/api.service';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { rxCatchApiError } from '../../../core-lib/utils/reducer-utils';
import { cateringTableActions } from '../actions/core-overview-table.actions';
import { ConcreteApiSuccessAction } from '../actions/core.actions';
import { CoreFeatureState, getI18nSelectedLanguage } from '../reducers/core.store';

@Injectable()
export class CoreCateringTableEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store$: Store<CoreFeatureState>,
  ) {
  }

  loadProposalPage$ = createEffect(() => this.actions$.pipe(
    ofType(cateringTableActions.tableLoad),
    // TODO: ProposalHeadMOdel -> CateringHeadModel
    debounceTime(200),
    switchMap((sortFilterPageConfig) => this.api.getTablePage(sortFilterPageConfig, 'catering').pipe(
      map((page) => cateringTableActions.tableLoaded({...page, selection: sortFilterPageConfig.selection})),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  exportProposalPage$ = createEffect(() => this.actions$.pipe(
    ofType(cateringTableActions.tableExport),
    withLatestFrom(
      this.store$.select(getI18nSelectedLanguage),
      (sortFilterPageConfig, selectedLanguage) => ({sortFilterPageConfig, selectedLanguage}),
    ),
    switchMap(({sortFilterPageConfig, selectedLanguage}) => this.api.getTableExport(sortFilterPageConfig, selectedLanguage, 'catering')),
  ), defaultEffectOptions({dispatch: false}));

  loadProposalTableColumnConfig$ = createEffect(() => this.actions$.pipe(
    ofType(cateringTableActions.tableConfigLoad),
    mergeMap((action) => this.api.getUserTableColumnConfig(action.selection).pipe(
      map((configs) => cateringTableActions.tableColumnConfigLoaded({data: configs, selection: action.selection})),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  loadProposalTableFilterConfig$ = createEffect(() => this.actions$.pipe(
    ofType(cateringTableActions.tableConfigLoad),
    mergeMap((action) => this.api.getUserTableFilterConfig(action.selection).pipe(
      map((configs) => cateringTableActions.tableFilterConfigLoaded({data: configs, selection: action.selection})),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  saveProposalTableColumnConfig$ = createEffect(() => this.actions$.pipe(
    ofType(cateringTableActions.tableConfigSave),
    switchMap((tableConfig) => this.api.postUserTableColumnConfig(tableConfig).pipe(
      tap((savedTableConfig) => this.store$.dispatch(cateringTableActions.tableColumnConfigLoaded({
        data: savedTableConfig,
        selection: tableConfig.tableName,
      }))),
      map(() => new ConcreteApiSuccessAction('tableConfigSaved')),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  saveProposalTableFilterConfig$ = createEffect(() => this.actions$.pipe(
    ofType(cateringTableActions.tableConfigSave),
    switchMap((tableConfig) => this.api.postUserTableFilterConfig(tableConfig).pipe(
      tap((savedTableConfig) => this.store$.dispatch(cateringTableActions.tableFilterConfigLoaded({
        data: savedTableConfig,
        selection: tableConfig.tableName,
      }))),
      map(() => new ConcreteApiSuccessAction('tableConfigSaved')),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  rejectProposal$ = createEffect(() => this.actions$.pipe(
    ofType(cateringTableActions.tableDeclineAction),
    switchMap((action) => this.api.postCateringReject(action.publicId, action.comment).pipe(
      switchMap(() => ([cateringTableActions.tableLoad(action.config), new ConcreteApiSuccessAction('formRejectSuccess')])),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  approveProposal$ = createEffect(() => this.actions$.pipe(
    ofType(cateringTableActions.tableApproveAction),
    switchMap((action) => this.api.postCateringApprove(action.publicId).pipe(
      switchMap(() => ([cateringTableActions.tableLoad(action.config), new ConcreteApiSuccessAction('formApprovedSuccess')])),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());

  cancelProposal$ = createEffect(() => this.actions$.pipe(
    ofType(cateringTableActions.tableRecallAction),
    switchMap((action) => this.api.postProcessCancelByCaterer(action.publicId, '').pipe(
      switchMap(() => ([cateringTableActions.tableLoad(action.config), new ConcreteApiSuccessAction('cancelProposalSuccess')])),
      rxCatchApiError({}),
    )),
  ), defaultEffectOptions());
}
