<div fxFlex="0 1">
  <div fxLayout="column">
    <div [fxHide]="forceMobile" fxHide.lt-md>
      <mat-toolbar class="responsiv-menu">
        <mat-toolbar-row>
          <button
            (click)="toggle()"
            class="md-icon-button sidenav-toggle-button"
            mat-icon-button
          >
            <mat-icon class="svg-icon-navi-menu" svgIcon="innogy:three-points2"></mat-icon>
          </button>
          <span class="title-center"></span>
          <div *ngFor="let item of getItems()">
            <lib-common-page-navbar-item
              src="{{ item.link }}"
              showAsButton
              *ngIf="item.show"
              [darkmode]="true"
            >
                  <span class="title-center">
                    <mat-icon class="navbar-icons" [svgIcon]="item.icon"></mat-icon>
                    {{item.name | i18n | async }}
                  </span>
            </lib-common-page-navbar-item>
          </div>
          <span class="title-center"></span>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
  </div>
</div>
