import { Dictionary } from '@ngrx/entity';

export function objectMap<O, I>(obj: Dictionary<I> = {}, modifierFn: ((key: string, value: I, index: number) => O)): Dictionary<O> {
  return Object.entries(obj).reduce((previousValue, [key, value], index) => ({
    ...previousValue,
    [key]: modifierFn(key, value, index),
  }), {});
}

export function objectFilter<I>(obj: Dictionary<I>, filterFn: ((key: string, value: I, index: number) => boolean)) {
  return Object.entries(obj).filter(
    ([key, value], index) => filterFn(key, value, index)
  ).reduce((p, [k, v]) => ({
    ...p, [k]: v,
  }), {});
}

export function objectLength<I>(obj: Dictionary<I>) {
  return obj ? Object.entries(obj).length : 0;
}
