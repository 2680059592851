<div class="margin-content">
  <form (ngSubmit)="save()">
    <lib-common-form-flex-container [maxWidth]="'1500px'">
      <mat-card-title>{{'fackq' | i18n | async}}</mat-card-title>
      <mat-accordion class="faq-card-width headers-align">
        <ng-container *ngFor="let tree of (faqTreeArrayState$ | async); trackBy: getControlId">
          <mat-expansion-panel *ngIf="hasElements(tree)" class="background-color-grey">
            <mat-expansion-panel-header>
              <mat-panel-title><strong>{{tree.value.section}}</strong></mat-panel-title>
              <mat-panel-description>
                <button class="delete-button" type="button" (click)="removeFaqTree(tree)" mat-icon-button>
                  <mat-icon>delete</mat-icon>
                </button>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-card-content>
              <lib-common-form-flex-container>
            <div
              fxFlex.gt-md="90%"
              fxFlex.lt-lg="100%"
            >
              <mat-form-field class="input-width">
                <input
                  matInput
                  required
                  placeholder="{{ 'section' | i18n | async }}"
                  [ngrxFormControlState]="tree.controls.section"
                >
              </mat-form-field>
            </div>
          </lib-common-form-flex-container>
            <mat-accordion class="faq-card-width headers-align">
              <ng-container *ngFor="let item of tree.controls.items.controls; index as index; trackBy: getControlId">
                <mat-expansion-panel *ngIf="!item.value.markedForDeletion">
                  <mat-expansion-panel-header>
                    <mat-panel-title><strong>{{item.value.question}}</strong></mat-panel-title>
                    <mat-panel-description>
                      <button class="delete-button" type="button" (click)="removeFaq(tree, item)" mat-icon-button>
                        <mat-icon>delete</mat-icon>
                      </button>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <mat-card-content>
                    <lib-common-form-flex-container>
                      <div
                        fxFlex.gt-md="90%"
                        fxFlex.lt-lg="100%"
                      >
                        <mat-form-field class="input-width">
                          <input
                            matInput
                            required
                            placeholder="{{ 'question' | i18n | async }}"
                            [ngrxFormControlState]="item.controls.question"
                          >
                        </mat-form-field>
                      </div>
                    </lib-common-form-flex-container>
                    <lib-common-form-flex-container>
                      <div
                        fxFlex.gt-md="100%"
                        fxFlex.lt-lg="100%"
                      >
                        <lib-common-angular-editor
                          [config]="{height: '5rem', placeholder: 'answer' | i18n | async}"
                          [controlState]="item.controls.answer"
                        ></lib-common-angular-editor>
                      </div>
                    </lib-common-form-flex-container>
                  </mat-card-content>
                </mat-expansion-panel>
              </ng-container>
            </mat-accordion>
            <lib-common-form-flex-container>
              <div>
                <button
                  type="button"
                  (click)="addFaqElement(tree)"
                  color="accent"
                  mat-fab
                >
                  <mat-icon svgIcon="innogy:plus"></mat-icon>
                </button>
              </div>
            </lib-common-form-flex-container>
            </mat-card-content>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="button"
          (click)="addFaqTreeNode()"
          color="accent"
          mat-fab
        >
          <mat-icon svgIcon="innogy:plus"></mat-icon>
        </button>
      </div>
    </lib-common-form-flex-container>
    <lib-common-form-flex-container>
      <div>
        <button
          type="submit"
          color="primary"
          mat-raised-button
          [disabled]="isFormInvalid$ | async"
        >
          <mat-icon
            class="svg-icon-floppy"
            svgIcon="innogy:floppy"
          ></mat-icon>
          {{ 'save' | i18n | async }}
        </button>
      </div>
    </lib-common-form-flex-container>
  </form>
</div>
