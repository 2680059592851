import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControlState } from 'ngrx-forms';
import { RouteConfigFormPropertyComponent } from '../../../core/components/route-config-form-property/route-config-form-property.component';
import { CoreFeatureState } from '../../../core/ngrx/reducers/core.store';
import { ConfigItemTextareaInputConfigurationDtoModel } from '../../models/config-item-textarea-input-configuration-dto.model';
import { SetValueTraceableAction } from '../../models/set-value-traceable-action';
import { InputPropertyTypeComponent } from '../input-property-type/input-property-type.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-input-property-type-textarea',
  templateUrl: './input-property-type-textarea.component.html',
  styleUrls: ['./input-property-type-textarea.component.scss'],
})
export class InputPropertyTypeTextareaComponent extends InputPropertyTypeComponent {

  typeName = ConfigItemTextareaInputConfigurationDtoModel.TYPENAME;

  @Input() ngrxPredefinedValue: FormControlState<string>;
  @Input() ngrxMinLength: FormControlState<number>;
  @Input() ngrxMaxLength: FormControlState<number>;
  @Input() ngrxRegex: FormControlState<string>;

  constructor(store$: Store<CoreFeatureState>, util: RouteConfigFormPropertyComponent) {
    super(store$, util);
  }

  internalReloadValues(lastConfigItemValue) {
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxRegex.id, lastConfigItemValue.regex));
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxMaxLength.id, lastConfigItemValue.maxLength));
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxPredefinedValue.id, lastConfigItemValue.predefinedValue));
  }

  editorConfig = {
    height: '5rem',
    placeholder: '',
    toolbarHiddenButtons: [
      [
        'heading',
        'fontName',
      ],
      [
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
  };

}
