import { TableColumnDefModel } from '../../core/models/table-column-def.model';

export interface CateringHeadModel {
  alternativeRoomName: string;
  author: string;
  category: string;
  catererId: string;
  collectionByAuthor: boolean;
  company: string;
  endDateTime: Date;
  formIdentifier: string;
  id: number;
  location: string;
  participantCount: number;
  publicId: string;
  roomName: string;
  startDateTime: Date;
  state: CateringState;
}

export type CateringTemplateConfig = {
  [key in keyof (Partial<CateringHeadModel> & {
    actions: string
  })]: TableColumnDefModel<CateringHeadModel>
};

export interface CateringTableConfig {
  columnName: keyof (CateringHeadModel & { actions: string });
  visible: boolean;
  position: number;
}

export type CateringState = 'CACHED'
  | 'SAVED'
  | 'TEMPLATE'
  | 'IN_APPROVAL'
  | 'IN_INSPECT'
  | 'IN_FURTHER_PROCESSING'
  | 'WAITING'
  | 'APPROVED'
  | 'SEND_FAILED'
  | 'SAP_ERROR'
  | 'DECLINED'
  | 'FINISHED'
  | 'CATERING_APPROVAL'
  | 'CATERING_IN_PROGRESS'
  | 'CATERING_COMPLAINED'
  | 'CATERING_DECLINED'
  | 'CATERING_APPROVAL_BY_AUTHOR'
  | 'CATERING_COMPLETE'
  | 'INITIAL';


export function getCateringSates() {
  return [
    'CACHED',
    'SAVED',
    'IN_APPROVAL',
    'IN_INSPECT',
    'IN_FURTHER_PROCESSING',
    'WAITING',
    'APPROVED',
    'SAP_ERROR',
    'SEND_FAILED',
    'DECLINED',
    'FINISHED',
    'CATERING_APPROVAL',
    'CATERING_IN_PROGRESS',
    'CATERING_DECLINED',
    'CATERING_COMPLAINED',
    'CATERING_APPROVAL_BY_AUTHOR',
    'CATERING_COMPLETE',
    'INITIAL',
  ];
}
