import { EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { FormDtoModel } from '../../../forms/all-forms/models/form-dto.model';
import { FormBaseDtoModel } from '../../../forms/form-base/models/form-base-dto.model';


export interface TemplateState extends EntityState<FormDtoModel> {
  collapsed: boolean;
}


export const getCoreTemplateFeaturesState = createSelector(
  (s) => s,
  (s: any): TemplateState => s.core.templates,
);

export const getTemplateCollapsedState = createSelector(
  getCoreTemplateFeaturesState,
  (s: TemplateState): boolean => s.collapsed,
);

export const getTemplatesArrayState = createSelector(
  getCoreTemplateFeaturesState,
  (s) => Object.values(s.entities),
);

export const getTemplatesStateByFormIdentifier = createSelector(
  getCoreTemplateFeaturesState,
  (s, props: {identifier: string}) => Object.values(s.entities)?.filter((template: FormDtoModel) => (
    template.form.identifier === props.identifier
  )),
);

/**
 * Needs props "formIdentifier" and "templateName"
 */
export const getCoreTemplateSelected = <T extends FormBaseDtoModel = FormBaseDtoModel>() => createSelector(
  getCoreTemplateFeaturesState,
  (s: TemplateState, props: {
    templateName: string,
    formIdentifier: string
  }): T => {
    return <T>(s.entities && Object.values(s.entities).find((t) => {
      const templateName = props.templateName;
      const formIdentifier = props.formIdentifier;
      return templateName && t.templateName === templateName && formIdentifier && t.form.identifier === formIdentifier;
    }));
  },
);
