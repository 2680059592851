import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ColumnMode, SortType } from '@swimlane/ngx-datatable';
import { Observable } from 'rxjs';
import { ProcessQueueModel } from '../../models/process-queue.model';
import { deleteProcessQueueAction, loadProcessQueueAction, resetProcessQueueAction } from '../../ngrx/actions/queue.actions';
import { getProcessQueueData } from '../../ngrx/reducers/queue.store';

@Component({
  selector: 'lib-common-route-config-queue',
  templateUrl: './route-config-queue.component.html',
  styleUrls: ['./route-config-queue.component.scss'],
})
export class RouteConfigQueueComponent implements OnInit {
  columnMode = ColumnMode.force;
  sortType = SortType.multi;
  content$: Observable<ProcessQueueModel[]> = this.store$.select(getProcessQueueData);

  constructor(private store$: Store<any>) {
  }

  ngOnInit(): void {
    this.store$.dispatch(loadProcessQueueAction());
  }

  reset(value: number) {
    this.store$.dispatch(resetProcessQueueAction({ids: [value]}));
  }

  delete(value: number) {
    this.store$.dispatch(deleteProcessQueueAction({id: value}));
  }
}
