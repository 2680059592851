import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { delayWhen, filter, map, tap } from 'rxjs/operators';
import { SessionSpinnerClose, SessionSpinnerOpen } from '../../session/actions/session.actions';
import { getSessionUserRoles } from '../../session/reducers/session.reducer';

@Injectable()
export class RoleBasedGuard implements CanActivate {

  constructor(private store$: Store<any>) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const allowedRoles: string[] = route.data.allowedRoles || [];

    return this.store$.select(getSessionUserRoles).pipe(
      tap(() => this.store$.dispatch(new SessionSpinnerOpen('ROLE_BASED_GUARD'))),
      delayWhen(() => this.store$.select(getSessionUserRoles).pipe(filter(roles => !!roles))),
      map(roles => !!roles.find(role => allowedRoles.includes(role))),
      tap(() => this.store$.dispatch(new SessionSpinnerClose('ROLE_BASED_GUARD'))),
    );
  }

}
