<ng-container *ngIf="selectedCompany">
  <lib-common-form-sap-autocomplete
    *ngIf="valueConverter"
    [ngrxValueConverter]="valueConverter"
    [ngrxFormControlState]="controlState"
    [ngrxEasyFieldConfig]="configName"
    [disabledOverride]="disabledOverride"
    [placeholder]="placeholder"
    (filterChanged)="filterChanged($event)"
    [filteredOptions]="filteredOptions | async"
    [showSpinner]="showSpinner"
    [disabled]="disabled"
    (valueChanged)="valueChanged$($event)"
    (modelChanged)="subModelChanged($event)"
    [outerValue]="value"
    [displayFn]="displayFn"
    [getEmitValueFn]="getEmitValueFn"
    (touched)="touched()"
    [showOpenButton]="!type"
    (openButtonClick)="openDialog($event)"
  ></lib-common-form-sap-autocomplete>
  <lib-common-form-sap-autocomplete
    *ngIf="!valueConverter"
    [ngrxFormControlState]="controlState"
    [ngrxEasyFieldConfig]="configName"
    [disabledOverride]="disabledOverride"
    [placeholder]="placeholder"
    (filterChanged)="filterChanged($event)"
    [filteredOptions]="filteredOptions | async"
    [showSpinner]="showSpinner"
    [disabled]="disabled"
    (valueChanged)="valueChanged$($event)"
    (modelChanged)="subModelChanged($event)"
    [outerValue]="value"
    [displayFn]="displayFn"
    [getEmitValueFn]="getEmitValueFn"
    (touched)="touched()"
    [showOpenButton]="!type"
    (openButtonClick)="openDialog($event)"
  ></lib-common-form-sap-autocomplete>
</ng-container>
