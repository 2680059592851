import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormCompaniesDialogDataAtomModel } from '../form-companies-dialog/form-companies-dialog-data-atom.model';
import { FormCompaniesDialogDataModel } from '../form-companies-dialog/form-companies-dialog-data.model';
import { FormCompaniesDialogComponent } from '../form-companies-dialog/form-companies-dialog.component';
import { FormCompaniesCardChild } from './form-companies-card-child';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-form-companies-card',
  templateUrl: './form-companies-card.component.html',
  styleUrls: ['./form-companies-card.component.scss'],
})
export class FormCompaniesCardComponent {
  @Input() cardTitle: string;
  @Input() addOptions: FormCompaniesDialogDataAtomModel[];
  @Input() submitI18n: string;
  @Input() companyI18n: string;
  @Input() deleteSelectedI18n: string;
  @Input() addI18n: string;
  @Input() link: string | string[];
  @Input() titleHeight: string;
  @Input() cardSubTitle: string;
  @Input() childs: FormCompaniesCardChild[] = [];
  @Output() childsChange = new EventEmitter<FormCompaniesCardChild[]>();
  @Output() add = new EventEmitter<FormCompaniesDialogDataAtomModel>();
  @Output() delete = new EventEmitter<FormCompaniesCardChild[]>();

  selected = [];

  hasSelectedItems() {
    return this.selected.length > 0;
  }

  getChildId = (child: FormCompaniesCardChild) => child.displayName;

  isSelected(child: FormCompaniesCardChild) {
    return this.selected.indexOf(child.displayName) >= 0;
  }

  setSelected(selected, child: FormCompaniesCardChild) {
    const newSelected = this.selected.filter((s) => s !== this.getChildId(child));
    if (selected) {
      newSelected.push(this.getChildId(child));
    }
    this.selected = newSelected;
  }

  emitChildChange(child) {
    this.childsChange.emit([
      ...this.childs.map((c) => this.getChildId(c) === this.getChildId(child) ? {
        ...child,
      } : c),
    ]);
  }

  constructor(
    private router: Router,
    public dialog: MatDialog,
  ) {}

  deleteSelectedChilds() {
    if (this.hasSelectedItems()) {
      this.delete.emit(
        this.selected.map((id) =>
          this.childs.find((value) =>
            this.getChildId(value) === id),
        ),
      );
    }
  }

  clickCompany(child: FormCompaniesCardChild) {
    // [routerLink]="child.route"
    if (Array.isArray(child.route)) {
      this.router.navigate(child.route);
    } else {
      this.router.navigate([child.route]);
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FormCompaniesDialogComponent, {
      minWidth: '350px',
      data: <FormCompaniesDialogDataModel>{
        options: this.addOptions,
        submitI18n: this.submitI18n,
        companyI18n: this.companyI18n,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.add.emit(result);
      }
    });
  }
}
