import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from '../../core-lib/services/api.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class DebugInfoService implements OnDestroy {
  downloadZIP$ = new Subject();
  private logs;
  private console = console as any;

  constructor(
    protected store$: Store<any>,
    protected appConfigService: AppConfigService,
    protected apiService: ApiService,
  ) {
    this.logs = this.attachLoggers();
    this.downloadZIP$.pipe(
      withLatestFrom(this.store$, (_a, s) => s),
      switchMap((store) => this.apiService.getActuatorInfo().pipe(
        map((backendInfo) => ({store, backendInfo})),
      )),
    ).subscribe(({store, backendInfo}) => {
      const time = moment().format('YYYY-MM-DD_HH-mm-ss');
      const debugObj = {
        time: moment().format(),
        location: window?.location?.href,
        userAgent: window?.navigator?.userAgent,
        appConfig: this.appConfigService?.config,
        backendInfo,
        store,
        logs: this?.logs,
      };
      ApiService.browserDownloadJSONObjectZIP(
        debugObj,
        `debug-${time}`,
        (d: string) => d.replace(/"data:[^"]*"/g, '"data:[DATA REMOVED FOR SIZE]"')
      );
    });
  }

  ngOnDestroy(): void {
    this.downloadZIP$?.complete();
  }

  downloadZIP() {
    this.downloadZIP$.next();
  }

  private attachLoggers() {
    ['log', 'debug', 'warn', 'error'].reduce((acc, cur) => ({
      ...acc,
      [cur]: this.replaceConsole(cur),
    }), {});

    return this.console.logs;
  }

  private replaceConsole(logName: string) {
    const logsName = 'logs';
    const defaultLogName = `default${logName}`;
    this.console[defaultLogName] = this.console[logName].bind(this.console);
    this.console[logsName] = this.console[logsName] ?? [];
    this.console[logName] = (...args) => {
      let stack;
      // hack to get stack trace to this point
      try {
        throw new Error('trace');
      } catch (e) {
        stack = e.stack;
      }

      this.console[logsName].push({
        type: logName,
        time: moment().format(),
        value: Array.from(args).map((a) => this.removeCycles(a)),
        stack: stack,
      });
      return this.console[defaultLogName].apply(this.console, args);
    };
    return this.console[logsName];
  }


  private removeCycles(obj: any, stackSet = new Set()) {
    if (!obj || typeof obj !== 'object') { return obj; }

    if (stackSet.has(obj)) { // it's cyclic! Print the object and its locations.
      return '[cyclic object]';
    }

    stackSet.add(obj);
    const rObj = {};

    for (const k in obj) { // dive on the object's children
      if (obj?.hasOwnProperty(k)) {
        rObj[k] = this.removeCycles(obj[k], stackSet);
      }
    }

    return rObj;
  }
}
