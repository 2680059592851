import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroupDirective, NG_VALUE_ACCESSOR, NgForm, NgModel } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Dictionary } from '@ngrx/entity';
import { FormControlState, ValidationErrors } from 'ngrx-forms';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';
import { IdDtoModel } from '../../../forms/all-forms/models/id-dto.model';

@Component({
  selector: 'lib-common-form-sap-autocomplete',
  templateUrl: './form-sap-autocomplete-input.component.html',
  styleUrls: ['./form-sap-autocomplete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSapAutocompleteInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSapAutocompleteInputComponent implements ControlValueAccessor, OnDestroy, OnInit {
  get filteredOptions(): Partial<IdDtoModel>[] {
    return this._filteredOptions;
  }

  @Input()
  set filteredOptions(value: Partial<IdDtoModel>[]) {
    if (Array.isArray(value)) {
      if (!this.isDisabled() && (value?.length !== 1
        || (typeof this.modelValue === 'object' && value[0]?.id !== this.modelValue?.id)
        || (typeof this.modelValue === 'string' && value[0]?.id !== this.modelValue))) {
        this.onChange$.emit(undefined);
      }
      this._filteredOptions = value;
    }
  }

  @Input() placeholder = 'account';

  protected _ngrxFormControlState: FormControlState<any>;
  @Input()
  set ngrxFormControlState(value: FormControlState<any>) {
    this._ngrxFormControlState = value;
    if (value) {
      this.errors = value.errors;
      this.errorKeys = Object.keys(this.errors);
    }
  }

  get ngrxFormControlState(): FormControlState<any> {
    return this._ngrxFormControlState;
  }

  @Input() required = false;
  /*
   * HELP!! Wird initial disabled damit auf der Übersichtseite nicht immer die Backendaufrufe der überliegenden Componente getriggert
   * wird. Im Anschluss wird das Feld dann durch die Config auf den richtigen Zustand gesetzt.
   */
  @Input() disabled = true;
  @Output() filterChanged = new EventEmitter<string>();
  @Output() valueChanged = new EventEmitter<unknown>();
  @Output() modelChanged = new EventEmitter<unknown>();
  private _filteredOptions: Partial<IdDtoModel>[];
  @Input() showSpinner = false;
  @Input() showOpenButton = false;
  @Output() openButtonClick = new EventEmitter<MouseEvent>();
  @Input() applyValuesInstantly = false;
  @Input() errorOnNotSelected = false;

  onDestroy$ = new EventEmitter();
  onChange$ = new EventEmitter<unknown>();
  onTouched$ = new EventEmitter();
  isSelectedValue = true;

  @ViewChild('model', {static: true})
  model: NgModel;
  modelValue: Partial<IdDtoModel> = null;

  @Output() touched = this.onTouched$;
  isTouched = false;

  constructor() {
    this.touched.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe((touched) => this.isTouched = touched);
  }

  errors: Dictionary<string> = {};
  errorKeys: string[] = [];

  disabledByCVA = false;
  errorStateMatcher: ErrorStateMatcher = {
    isErrorState: (control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean => {
      if (this.ngrxFormControlState && !this.isDisabled()) {
        return (this.ngrxFormControlState.isTouched || this.modelValue)
          && (this.ngrxFormControlState.isInvalid || this.isRequiredAndNoOptionSelected());
      } else if (!this.isDisabled()) {
        return this.isRequiredAndNoOptionSelected();
      }
      return false;
    },
  };

  @Input() displayFn: (value: unknown) => string;
  @Input() getEmitValueFn: (value: unknown) => unknown = () => undefined;

  @Input()
  set outerValue(value: unknown) {
    this.modelValue = value;
  }

  get outerValue() {
    return this.modelValue;
  }

  private isRequiredAndNoOptionSelected() {
    return this.required && (!this._filteredOptions || !this._filteredOptions.find(({id}) => id === this.modelValue.id));
  }

  isRequired() {
    return this.required && !this.isDisabled();
  }

  getIsSelectedError() {
    return this.isTouched && this.errorOnNotSelected && !this.isSelectedValue && !this.isDisabled();
  }

  ngOnInit(): void {
    this.model.valueChanges.pipe(
      filter(input => typeof input === 'string'),
      debounceTime(300),
      tap(() => this.isSelectedValue = false),
    ).subscribe((value) => {
      if (!this.isDisabled()) {
        if (!this._filteredOptions?.find(fo => fo.id === value)) {
          this.filterChanged.emit(value);
        }
      } else {
        this.onChange$.emit(value);
      }
    });

    this.model.valueChanges.pipe(
      filter(input => typeof input === 'object'),
      tap(() => this.isSelectedValue = true),
    ).subscribe((accountingInfo: unknown) => {
      this.onChange$.emit(this.getEmitValueFn(accountingInfo));
    });

    this.model.update.pipe(
      filter(() => this.model.touched),
    ).subscribe((value) => this.onTouched$.emit(value));
  }

  ngOnDestroy(): void {
    this.onDestroy$.emit();
    this.onDestroy$.complete();
  }

  registerOnChange(fn: (id: string) => void): void {
    this.onChange$.pipe(
      takeUntil(this.onDestroy$),
      map((value: string) => {
        if (this.getIsSelectedError()) {
          return undefined;
        } else {
          return value;
        }
      }),
    ).subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(fn);
  }

  writeValue(value: string): void {
    if (this.isDisabled()) {
      this.outerValue = value;
    } else if (!!value) {
      if (this.applyValuesInstantly) {
        this.outerValue = value;
      }
      this.valueChanged.emit(value);
    } else {
      this.outerValue = undefined;
    }
  }

  /**
   * by ControlValueAccessor
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabledByCVA = isDisabled;
  }

  isDisabled() {
    return this.disabled || this.disabledByCVA;
  }

  modelChange(value: unknown) {
    this.modelChanged.emit(value);
  }
}
