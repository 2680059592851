import { ConfigurationItems } from '../../core-lib/models/config-dto.model';
import { ConfigItemAccountingConfigurationDtoModel } from '../../core-lib/models/config-item-accounting-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../core-lib/models/config-item-arranger-list-configuration-dto.model';
import { ConfigItemConfigurationCategoryModel } from '../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemDateConfigurationDtoModel } from '../../core-lib/models/config-item-date-configuration-dto.model';
import { ConfigItemFileUploadConfigurationDtoModel } from '../../core-lib/models/config-item-file-upload-configuration-dto.model';
import { ConfigItemLedgerAccountConfigurationDtoModel } from '../../core-lib/models/config-item-ledger-account-configuration-dto.model';
import { ConfigItemListConfigurationDtoModel } from '../../core-lib/models/config-item-list-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../../core-lib/models/config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../../core-lib/models/config-item-text-input-configuration-dto.model';
import { ConfigListDtoModel } from '../../core-lib/models/config-list-dto.model';
import { PayeeDtoModel } from '../../forms/all-forms/models/payee-dto.model';
import { FormAF15DtoModel } from '../../forms/form-af15/models/form-af15-dto.model';
import { EqualKeyNames } from './equal-key-names';
import { FormPositionDtoModel } from './form-position-dto.model';


export const EasyFormAF15Steps = {
  step1: <ConfigItemConfigurationCategoryModel>{
    id: 'step1',
    displayName: 'Gesellschaft',
  },
  step2: <ConfigItemConfigurationCategoryModel>{
    id: 'step2',
    displayName: 'Zahlungsempfänger',
  },
  step3: <ConfigItemConfigurationCategoryModel>{
    id: 'step3',
    displayName: 'Rechnungsangaben',
  },
};

export class EasyFormAF15ConfigItemsModel extends ConfigurationItems
  implements EqualKeyNames<FormAF15DtoModel>, EqualKeyNames<PayeeDtoModel>, EqualKeyNames<FormPositionDtoModel> {
  payeeNumber = new ConfigItemTextInputConfigurationDtoModel('payeeNumber').withCategory(EasyFormAF15Steps.step2);

  // PayeeDtoModel
  salutation = new ConfigItemTextInputConfigurationDtoModel('salutation').withCategory(EasyFormAF15Steps.step2);
  name = new ConfigItemTextInputConfigurationDtoModel('name').withCategory(EasyFormAF15Steps.step2);
  nameAddition = new ConfigItemTextInputConfigurationDtoModel('nameAddition').withCategory(EasyFormAF15Steps.step2);
  street = new ConfigItemTextInputConfigurationDtoModel('street').withCategory(EasyFormAF15Steps.step2);
  zipCode = new ConfigItemTextInputConfigurationDtoModel('zipCode').withCategory(EasyFormAF15Steps.step2);
  city = new ConfigItemTextInputConfigurationDtoModel('city').withCategory(EasyFormAF15Steps.step2);
  country = new ConfigItemTextInputConfigurationDtoModel('country').withCategory(EasyFormAF15Steps.step2);
  email = new ConfigItemTextInputConfigurationDtoModel('email').withCategory(EasyFormAF15Steps.step2);
  iban = new ConfigItemTextInputConfigurationDtoModel('iban').withCategory(EasyFormAF15Steps.step2);
  bic = new ConfigItemTextInputConfigurationDtoModel('bic').withCategory(EasyFormAF15Steps.step2);
  ustId = new ConfigItemTextInputConfigurationDtoModel('ustId').withCategory(EasyFormAF15Steps.step2);
  taxNumber = new ConfigItemTextInputConfigurationDtoModel('taxNumber').withCategory(EasyFormAF15Steps.step2);

  // Verwendungszweck
  useCase = new ConfigItemTextInputConfigurationDtoModel('useCase').withCategory(EasyFormAF15Steps.step3);
  description = new ConfigItemTextInputConfigurationDtoModel('description').withCategory(EasyFormAF15Steps.step3);
  payout = new ConfigItemNumberConfigurationDtoModel('payout').withCategory(EasyFormAF15Steps.step3);
  subject = new ConfigItemTextInputConfigurationDtoModel('subject').withCategory(EasyFormAF15Steps.step3);
  additionalSubject = new ConfigItemTextInputConfigurationDtoModel('additionalSubject').withCategory(EasyFormAF15Steps.step3);
  contactPerson = new ConfigItemTextInputConfigurationDtoModel('contactPerson').withCategory(EasyFormAF15Steps.step3);
  contractNumber = new ConfigItemTextInputConfigurationDtoModel('contractNumber').withCategory(EasyFormAF15Steps.step3);
  phoneNr =   new ConfigItemTextInputConfigurationDtoModel('phoneNr').withCategory(EasyFormAF15Steps.step3);
  delivery = new ConfigItemTextInputConfigurationDtoModel('delivery').withCategory(EasyFormAF15Steps.step3);
  dueDate = new ConfigItemDateConfigurationDtoModel('dueDate').withCategory(EasyFormAF15Steps.step3);
  invoiceDate = new ConfigItemDateConfigurationDtoModel('invoiceDate').withCategory(EasyFormAF15Steps.step3);
  accountingContact = new ConfigItemTextInputConfigurationDtoModel('accountingContact').withCategory(EasyFormAF15Steps.step3);


  // positions
  positions = new ConfigListDtoModel('positions').withCategory(EasyFormAF15Steps.step3);
  positionName = new ConfigItemTextInputConfigurationDtoModel('positionName').withCategory(EasyFormAF15Steps.step3);
  netCost = new ConfigItemNumberConfigurationDtoModel('netCost').withCategory(EasyFormAF15Steps.step3);
  taxRate = new ConfigItemListConfigurationDtoModel('taxRate', 'taxrates').withCategory(EasyFormAF15Steps.step3);
  grossCost = new ConfigItemNumberConfigurationDtoModel('grossCost').withCategory(EasyFormAF15Steps.step3);
  taxValue = new ConfigItemNumberConfigurationDtoModel('taxValue').withCategory(EasyFormAF15Steps.step3);
  account = new ConfigItemAccountingConfigurationDtoModel('account').withCategory(EasyFormAF15Steps.step3);
  ledgerAccount = new ConfigItemLedgerAccountConfigurationDtoModel('ledgerAccount').withCategory(EasyFormAF15Steps.step3);
  unit = new ConfigItemTextInputConfigurationDtoModel('unit', 'unit').withCategory(EasyFormAF15Steps.step3);
  quantity = new ConfigItemNumberConfigurationDtoModel('quantity').withCategory(EasyFormAF15Steps.step3);

  // Genehmigungsstufen
  approver = new ConfigItemArrangerListConfigurationDtoModel('approver').withCategory(EasyFormAF15Steps.step3);
  inspector = new ConfigItemArrangerListConfigurationDtoModel('inspector').withCategory(EasyFormAF15Steps.step3);
  commentForApprover = new ConfigItemTextInputConfigurationDtoModel('commentForApprover').withCategory(EasyFormAF15Steps.step3);

  file = new ConfigItemFileUploadConfigurationDtoModel('file').withCategory(EasyFormAF15Steps.step3);
}
