import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { DisableAction, EnableAction, FormControlState, FormGroupState, unbox } from 'ngrx-forms';
import { take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { RouteConfigFormPropertyComponent } from '../../../core/components/route-config-form-property/route-config-form-property.component';
import { CoreFeatureState } from '../../../core/ngrx/reducers/core.store';
import {
  getEasyFormConfigsEntitiesState,
  getEasyFormConfigsSelectedValueState,
} from '../../../core/ngrx/reducers/easy-form-configs.reducer';
import { backendClasses } from '../../models/backend-classes.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../models/config-item-arranger-list-configuration-dto.model';
import { ConfigItemTranslationsDtoModel } from '../../models/config-item-configuration-dto.model';
import { ConfigItemListConfigurationDtoModel } from '../../models/config-item-list-configuration-dto.model';
import { SetValueTraceableAction } from '../../models/set-value-traceable-action';

@Directive()
export abstract class InputPropertyTypeComponent implements OnInit, OnDestroy {
  @Input() attributeName: string;

  @Input() ngrxMandatory: FormControlState<boolean>;
  @Input() ngrxEnabled: FormControlState<boolean>;
  @Input() ngrxVisible: FormControlState<boolean>;
  @Input() ngrxChangeableByInspector: FormControlState<boolean>;
  @Input() ngrxChangeableByApprover: FormControlState<boolean>;
  @Input() translations: FormGroupState<ConfigItemTranslationsDtoModel>;
  @Input() ngrxOverwrite: FormControlState<boolean>;
  @Input() readonly: boolean;
  @Output() labelResetClick = new EventEmitter<FormControlState<string>>();
  destroy$ = new EventEmitter();

  private id;

  protected constructor(
    protected store$: Store<CoreFeatureState>,
    protected util: RouteConfigFormPropertyComponent,
  ) {
  }

  ngOnInit(): void {
    this.util.getConfig$(this.attributeName).pipe(
      takeUntil(this.destroy$),
    ).subscribe(c => {
      if (!!c) {
        this.id = c && c.id;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.emit();
    this.destroy$.complete();
  }

  onChangeReadonly(overwrite: boolean) {
    if (overwrite) {
      this.resetIds();
      this.store$.dispatch(new EnableAction(this.id));
    } else {
      this.reloadValues();
      this.store$.dispatch(new DisableAction(this.id));
      this.store$.dispatch(new EnableAction(this.ngrxOverwrite.id));
    }
  }

  /**
   * Neuladen des Daten aus Store für erbende Komponenten
   */
  abstract internalReloadValues(lastConfigItemValue);

  /* ToDo: Refactor! SetValueTraceableAction sollte hier nicht geschmissen werden müssen. -> Effekt
    1. Das ganze wird durch ein (change)="..." von ngrxOverwrite ausgelöst, also durch eine Action aus dem Store.
    Wenn wir auf eine Action hören, und wieder Actions werfen, ist das ein Effekt und sollte nicht in Komponenten
    behandelt werden.
    2. Mehrere SetValueTraceableAction sollten eine custom Action werden, mit der wir dann im Reducer SetValue() ausführen.
    3. Ist eine SetValueTraceableAction ohne geänderte Value überhaupt nötig?
   */
  reloadValues() {
    this.store$.select(getEasyFormConfigsSelectedValueState).pipe(
      take(1),
    ).subscribe(thisForm => {
      if (!!thisForm.parent) {
        let parentConfigItemValue;
        let parent = unbox(thisForm.parent);
        while (!!parent) {
          parentConfigItemValue = parent.configurationItems[this.attributeName];
          if (parentConfigItemValue !== undefined) {
            this.store$.dispatch(new SetValueTraceableAction(this.ngrxMandatory.id, parentConfigItemValue.mandatory));
            this.store$.dispatch(new SetValueTraceableAction(this.ngrxEnabled.id, parentConfigItemValue.enabled));
            this.store$.dispatch(new SetValueTraceableAction(this.ngrxVisible.id, parentConfigItemValue.visible));
            this.store$.dispatch(new SetValueTraceableAction(
              this.ngrxChangeableByInspector.id, parentConfigItemValue.changeableByInspector,
            ));
            this.store$.dispatch(new SetValueTraceableAction(this.ngrxChangeableByApprover.id, parentConfigItemValue.changeableByApprover));
            this.store$.dispatch(new SetValueTraceableAction(this.translations.id, parentConfigItemValue.translations));
            this.internalReloadValues(parentConfigItemValue);
            return;
          }
          parent = unbox(parent.parent);
        }
      }
    });
  }

  /**
   *  Zurücksetzen der IDs
   */
  // ToDo: in Effekt übernehmen
  resetIds() {
    this.store$.select(getEasyFormConfigsEntitiesState).pipe(
      withLatestFrom(
        this.store$.select(getEasyFormConfigsSelectedValueState),
        this.util.getConfig$(this.attributeName),
        (entities, thisForm, thisControls) => ({entities, thisForm, thisControls}),
      ),
      takeUntil(this.destroy$),
      take(1),
    ).subscribe(({entities, thisForm, thisControls}) => {
      const lastConfigItemValue = entities[thisForm.id];
      if (lastConfigItemValue && lastConfigItemValue.parent) {
        this.store$.dispatch(new SetValueTraceableAction(thisControls.controls.id.id, undefined));
        for (const lang of Object.values(thisControls.controls.translations.controls)) {
          if (lang.controls.id && lang.controls.id.id) {
            this.store$.dispatch(new SetValueTraceableAction(lang.controls.id.id, undefined));
          }
        }

        // Config is a list
        if (thisControls.controls['@class'].value === backendClasses.listConfiguration
          || thisControls.controls['@class'].value === backendClasses.arrangerListConfiguration) {
          type controlT = FormGroupState<ConfigItemListConfigurationDtoModel | ConfigItemArrangerListConfigurationDtoModel>;
          const list = <controlT>thisControls;
          for (const listElements of Object.values(list.controls.values.controls)) {
            if (listElements.controls.id && listElements.controls.id.id) {
              this.store$.dispatch(new SetValueTraceableAction(listElements.controls.id.id, undefined));
            }

            if (listElements.controls.translations && listElements.controls.translations.controls) {
              for (const t of Object.values(listElements.controls.translations.controls)) {
                if (t && t.controls && t.controls.id && t.controls.id.id) {
                  this.store$.dispatch(new SetValueTraceableAction(t.controls.id.id, undefined));
                }
              }
            }
          }
        }
      }
    });
  }
}
