import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { CompanyDataDtoModel } from '../../../core-lib/models/company-data-dto.model';
import { ApiService } from '../../../core-lib/services/api.service';
import { rxGetFormControl } from '../../../core-lib/utils/reducer-utils';
import { CoreCompanyFormActionSave } from '../../ngrx/actions/core-company-forms.actions';
import { CoreFeatureState, getCompanyFormState } from '../../ngrx/reducers/core.store';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-route-config-company',
  templateUrl: './route-config-company.component.html',
  styleUrls: ['./route-config-company.component.scss'],
})
export class RouteConfigCompanyComponent implements OnInit, OnDestroy {
  form$ = this.store$.select(getCompanyFormState);

  destroy$ = new EventEmitter();
  agb: string;

  constructor(
    private store$: Store<CoreFeatureState>,
  ) {
  }

  ngOnInit() {
    this.getFormControlValue$('agb').pipe(
      takeUntil(this.destroy$),
      filter(agb => !!agb),
    ).subscribe(agb => this.agb = agb);
  }

  ngOnDestroy(): void {
    this.destroy$.emit();
    this.destroy$.complete();
  }

  getFormControl$(ctrlName: keyof CompanyDataDtoModel) {
    return this.form$.pipe(rxGetFormControl(ctrlName));
  }

  getFormControlValue$(value) {
    return this.getFormControl$(value).pipe(select('value'));
  }

  saveForm() {
    this.store$.dispatch(new CoreCompanyFormActionSave());
  }

  download($event: MouseEvent) {
    $event.stopPropagation();
    ApiService.downloadFromDataUrl('agb.pdf', this.agb);
  }

}
