import { createSelector } from '@ngrx/store';
import { TableColumnConfigModel } from '../../../core-lib/models/table-column-config.model';
import { TableFilterConfigModel } from '../../../core-lib/models/table-filter-config.model';
import { TableState } from './core-overview-table.reducer';
import { getCoreState } from './core.store';


export const getOverviewTableState = createSelector(
  getCoreState,
  (state, props: { tableType:  'proposals' | 'catering', type: string  }): TableState => state[props.tableType],
);

export const getOverviewTablePage = createSelector(
  getOverviewTableState,
  (state, props: { tableType:  'proposals' | 'catering', type: string }) => state[props.type],
);

export const getOverviewTableColumnConfig = createSelector(
  getOverviewTableState,
  (state, props: { tableType:  'proposals' | 'catering', type: string }) => state.columnConfig[props.type]as TableColumnConfigModel[],
);
export const getOverviewTableFilterConfig = createSelector(
  getOverviewTableState,
  (state, props: { tableType:  'proposals' | 'catering', type: string }) => state.filterConfig[props.type] as TableFilterConfigModel[],
);

export const isOverviewTableLoading = createSelector(
  getOverviewTableState,
  state => state.loading,
);
