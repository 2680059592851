import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormArrayState, FormGroupState } from 'ngrx-forms';
import { map } from 'rxjs/operators';
import { IdDtoModel } from '../../../forms/all-forms/models/id-dto.model';
import { accountDeputiesActions } from '../../ngrx/actions/core-account-deputies.actions';
import { getAccountDeputiesApproversState, getAccountDeputiesEditorsState } from '../../ngrx/reducers/account-deputies.reducer';
import { CoreFeatureState } from '../../ngrx/reducers/core.store';

@Component({
  selector: 'lib-common-account-deputies',
  templateUrl: './account-deputies.component.html',
  styleUrls: ['./account-deputies.component.scss'],
})
export class AccountDeputiesComponent {
  constructor(private store$: Store<CoreFeatureState>) {
  }

  @Input() saveUserId: string = undefined;
  approverDeputies$ = this.store$.select(getAccountDeputiesApproversState).pipe(
    this.mapControls$(),
  );
  editorDeputies$ = this.store$.select(getAccountDeputiesEditorsState).pipe(
    this.mapControls$(),
  );

  mapControls$<T>() {
    return map((f: FormArrayState<T>) => f.controls);
  }

  addApproverDeputy() {
    this.store$.dispatch(accountDeputiesActions.AccountDeputiesAddApproverAction());
  }

  removeApproverDeputy(element: FormGroupState<IdDtoModel>) {
    this.store$.dispatch(accountDeputiesActions.AccountDeputiesRemoveApproverAction({formElementId: element.id}));
  }

  addEditorDeputy() {
    this.store$.dispatch(accountDeputiesActions.AccountDeputiesAddEditorAction());
  }

  removeEditorDeputy(element: FormGroupState<IdDtoModel>) {
    this.store$.dispatch(accountDeputiesActions.AccountDeputiesRemoveEditorAction({formElementId: element.id}));
  }

  save() {
    this.store$.dispatch(accountDeputiesActions.AccountDeputiesSaveAction({userId: this.saveUserId}));
  }
}
