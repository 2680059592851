import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BarChart } from 'echarts/charts';
import { GridComponent, TitleComponent, TooltipComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import langDE from 'echarts/lib/i18n/langDE';
import { CanvasRenderer } from 'echarts/renderers';
import 'echarts/theme/macarons.js';
import { NgrxFormsModule } from 'ngrx-forms';
import { NgxEchartsModule } from 'ngx-echarts';
import { CommonMaterialModule } from '../../../common-material/src/lib/common-material.module';
import { AngularEditorComponent } from './components/angular-editor/angular-editor.component';
import { CategoryChildFormComponent } from './components/category-child-form/category-child-form.component';
import { CategoryTreeElementComponent } from './components/category-tree-element/category-tree-element.component';
import { CurrencyInputComponent } from './components/currency-input/currency-input.component';
import { DataProtectionComponent } from './components/data-protection/data-protection.component';
import { DatetimeRangePickerComponent } from './components/datetime-range-picker/datetime-range-picker.component';
import { DividerComponent } from './components/divider/divider.component';
import { FaqComponent } from './components/faq/faq.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FormCompaniesCardComponent } from './components/form-companies-card/form-companies-card.component';
import { FormCompaniesDialogComponent } from './components/form-companies-dialog/form-companies-dialog.component';
import { FormEntityIdSelectorComponent } from './components/form-entity-id-selector/form-entity-id-selector.component';
import { FormFlexContainerComponent } from './components/form-flex-container/form-flex-container.component';
import { FormPreconfirmDialogComponent } from './components/form-preconfirm-dialog/form-preconfirm-dialog.component';
import { FormStepperBackAndNextComponent } from './components/form-stepper-back-and-next/form-stepper-back-and-next.component';
import { InputLangStringComponent } from './components/input-lang-string/input-lang-string.component';
import { InputPropertyDefaultsComponent } from './components/input-property-defaults/input-property-defaults.component';
import { InputPropertyLangWrapperComponent } from './components/input-property-lang-wrapper/input-property-lang-wrapper.component';
import { InputPropertyTypeAccountingComponent } from './components/input-property-type-account/input-property-type-accounting.component';
import {
  InputPropertyTypeArrangerListComponent,
} from './components/input-property-type-arranger-list/input-property-type-arranger-list.component';
import { InputPropertyTypeArrangerComponent } from './components/input-property-type-arranger/input-property-type-arranger.component';
import { InputPropertyTypeCheckboxComponent } from './components/input-property-type-checkbox/input-property-type-checkbox.component';
import { InputPropertyTypeDateComponent } from './components/input-property-type-date/input-property-type-date.component';
import { InputPropertyTypeDaterangeComponent } from './components/input-property-type-daterange/input-property-type-daterange.component';
import { InputPropertyTypeFileComponent } from './components/input-property-type-file/input-property-type-file.component';
import {
  InputPropertyTypeLedgerAccountComponent,
} from './components/input-property-type-ledger-account/input-property-type-ledger-account.component';
import { InputPropertyTypeListComponent } from './components/input-property-type-list/input-property-type-list.component';
import { InputPropertyTypeNumberComponent } from './components/input-property-type-number/input-property-type-number.component';
import { InputPropertyTypeStringComponent } from './components/input-property-type-string/input-property-type-string.component';
import { InputPropertyTypeTextareaComponent } from './components/input-property-type-textarea/input-property-type-textarea.component';
import { InputPropertyWrapperComponent } from './components/input-property-wrapper/input-property-wrapper.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { LedgerAccountComponent } from './components/ledger-account/ledger-account.component';
import { LegalDisclosureComponent } from './components/legal-disclosure/legal-disclosure.component';
import { OcrPromptComponent } from './components/ocr-prompt/ocr-prompt.component';
import { PageErrorMessageComponent } from './components/page-error-message/page-error-message.component';
import { PageMarginComponent } from './components/page-margin/page-margin.component';
import { PickupSelectorComponent } from './components/pickup-selector/pickup-selector.component';
import { QueryPromptMessageActionsComponent } from './components/query-prompt-message-actions/query-prompt-message-actions.component';
import { QueryPromptComponent } from './components/query-prompt/query-prompt.component';
import { RoomPickerOrTextComponent } from './components/room-picker-or-text/room-picker-or-text.component';
import { RoomPickerComponent } from './components/room-picker/room-picker.component';
import { SalutationComponent } from './components/salutation/salutation.component';
import { SelectCountryComponent } from './components/select-country/select-country.component';
import { SelectFactComponent } from './components/select-fact/select-fact.component';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { TaxRateComponent } from './components/tax-rate/tax-rate.component';
import { TemplateSelectorDialogComponent } from './components/template-selector-dialog/template-selector-dialog.component';
import { TilesContainerComponent } from './components/tiles-container/tiles-container.component';
import { TilesTemplateDirective } from './components/tiles-container/tiles-template.directive';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserSearchInputComponent } from './components/user-search-input/user-search-input.component';
import { UserSearchModalComponent } from './components/user-search-modal/user-search-modal.component';
import { VersionComponent } from './components/version/version.component';
import { DisableWhenDirective } from './directives/disable-when.directive';
import { FxAlignLastRowLeftDirective } from './directives/fx-align-last-row-left.directive';
import { NgrxEasyFieldConfigDirective } from './directives/ngrx-easy-field-config.directive';
import { RequiredWhenDirective } from './directives/required-when.directive';
import { I18nPipe } from './pipes/i18n.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { FileSizePipe } from './utils/file-size.pipe';

echarts.registerLocale('DE', langDE);

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
]);

// Komponenten, die in 'declarations' und 'export' müssen (fast alle!)
const exports = [
  LanguageSelectorComponent,
  VersionComponent,
  AngularEditorComponent,
  VersionComponent,
  DividerComponent,
  TilesContainerComponent,
  TilesTemplateDirective,
  InputPropertyTypeStringComponent,
  InputPropertyTypeTextareaComponent,
  InputPropertyTypeNumberComponent,
  InputPropertyTypeCheckboxComponent,
  InputPropertyTypeDateComponent,
  InputPropertyTypeAccountingComponent,
  InputPropertyTypeLedgerAccountComponent,
  InputLangStringComponent,
  FileUploadComponent,
  TaxRateComponent,
  LedgerAccountComponent,
  SalutationComponent,
  I18nPipe,
  SafePipe,
  FileSizePipe,
  QueryPromptComponent,
  QueryPromptMessageActionsComponent,
  UserSearchInputComponent,
  FormCompaniesCardComponent,
  UserSearchModalComponent,
  FormStepperBackAndNextComponent,
  CurrencyInputComponent,
  PageMarginComponent,
  FormFlexContainerComponent,
  InputPropertyWrapperComponent,
  SnackBarComponent,
  FormCompaniesDialogComponent,
  SelectSearchComponent,
  SelectCountryComponent,
  CategoryChildFormComponent,
  FxAlignLastRowLeftDirective,
  InputPropertyDefaultsComponent,
  InputPropertyTypeListComponent,
  InputPropertyLangWrapperComponent,
  InputPropertyTypeFileComponent,
  NgrxEasyFieldConfigDirective,
  RequiredWhenDirective,
  InputPropertyTypeArrangerListComponent,
  InputPropertyTypeArrangerComponent,
  DisableWhenDirective,
  UserListComponent,
  LegalDisclosureComponent,
  DataProtectionComponent,
  PageErrorMessageComponent,
  RoomPickerComponent,
  PickupSelectorComponent,
  RoomPickerOrTextComponent,
  DatetimeRangePickerComponent,
  InputPropertyTypeDaterangeComponent,
  OcrPromptComponent,
  SelectFactComponent,
  CategoryTreeElementComponent,
  FormPreconfirmDialogComponent,
  FaqComponent,
  FormEntityIdSelectorComponent,
];

@NgModule({
  declarations: [
    ...exports,
    TemplateSelectorDialogComponent,
    // STOP! Komponenten sollten in die Konstante `exports`!
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    CommonMaterialModule,
    NgrxFormsModule,
    AngularEditorModule,
    RouterModule,
    FontAwesomeModule,
    LayoutModule,
    NgxDatatableModule,
    MatSlideToggleModule,
    MatChipsModule,
    DragDropModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
  ],
  exports: [
    CommonModule,
    CommonMaterialModule,
    HttpClientModule,
    FontAwesomeModule,
    AngularEditorComponent,
    NgrxFormsModule,
    ...exports,
    // STOP! Komponenten sollten in die Konstante `exports`!
  ],
  providers: [
    I18nPipe,
    SafePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
})
export class CoreLibModule {
}
