import { createFormGroupState } from 'ngrx-forms';
import { CompanyDataDtoModel } from '../../../core-lib/models/company-data-dto.model';

export const COMPANY_ID = 'companyForm';

export const initialCompanyFormState = createFormGroupState<CompanyDataDtoModel>(COMPANY_ID, {
  id: undefined,
  name: '',
  companyId: '',
  footer: '',
  location: '',
  logo: '',
  certificate: '',
  agb: '',
  postCode: '',
  shortName: '',
  street: '',
  backendClient: '',
  salesOrg: '',
  distributionChannel: '',
  sector: '',
  branchSalesOffice: '',
  procurementSection: '',
  boxNumber: '',
  boxNumberLocation: '',
  active: true,
});
