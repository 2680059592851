<lib-common-input-property-wrapper
  [header]="attributeName"
  icon="check_box"
  [typeNameI18n]="typeName"
  [readonly]="readonly"
  [ngrxOverwrite]="ngrxOverwrite"
  (changeReadonly)="onChangeReadonly($event)"
>
  <div>
    <section>
      <div>
        <lib-common-input-property-defaults
          [ngrxMandatory]="ngrxMandatory"
          [ngrxEnabled]="ngrxEnabled"
          [ngrxVisible]="ngrxVisible"
          [ngrxChangeableByInspector]="ngrxChangeableByInspector"
          [ngrxChangeableByApprover]="ngrxChangeableByApprover"
        ></lib-common-input-property-defaults>
        <mat-checkbox
          *ngIf="ngrxPreChecked"
          class="checkbox-margin"
          [ngrxFormControlState]="ngrxPreChecked"
        >{{ 'predefined' | i18n | async }}?
        </mat-checkbox>
      </div>
    </section>
    <lib-common-input-property-lang-wrapper
      [translations]="translations"
      (labelResetClick)="labelResetClick.emit($event)"
      [undoVisible]="labelResetClick && labelResetClick.observers && labelResetClick.observers.length > 0"
    ></lib-common-input-property-lang-wrapper>
  </div>
</lib-common-input-property-wrapper>
