<mat-card class="stretched-card">
  <mat-card-header [style.minHeight]="titleHeight">
    <mat-card-title *ngIf="!link">{{ cardTitle }}</mat-card-title>
    <mat-card-title *ngIf="link"><a [routerLink]="link">{{ cardTitle }}</a></mat-card-title>
    <mat-card-subtitle class="no-margin-bottom">{{ cardSubTitle }}</mat-card-subtitle>
    <mat-menu #appMenu="matMenu">
      <button
        type="button"
        mat-menu-item
        (click)="openDialog()"
      >{{ addI18n }}</button>
      <button
        type="button"
        mat-menu-item
        [disabled]="!hasSelectedItems()"
        (click)="deleteSelectedChilds()"
      >
        {{ deleteSelectedI18n }}
      </button>
    </mat-menu>
    <div fxFlex></div>
    <mat-card-actions align="end">
      <button
        type="button"
        mat-icon-button
        [matMenuTriggerFor]="appMenu"
      >
        <mat-icon svgIcon="innogy:three-points"></mat-icon>
      </button>
    </mat-card-actions>
  </mat-card-header>
  <mat-card-content class="card-content-bootstrap">
    <div
      *ngFor="let child of childs"
      class="fab-spacer"
      fxLayoutAlign="center center"
    >
      <mat-checkbox
        [checked]="isSelected(child)"
        class="checkboxNomargin"
        (click)="$event.preventDefault(); setSelected(!isSelected(child), child)"
      >
        {{ child.displayName }}
      </mat-checkbox>
      <div fxFlex="1 0"></div>
      <button
        mat-mini-fab
        color="accent"
        type="button"
        (click)="clickCompany(child)"
        [title]="'editFormConfig' | i18n | async"
      >
        <mat-icon
          class="icon-pen"
          inline
          svgIcon="innogy:pen"
        ></mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>
