import { Component, Input } from '@angular/core';
import { FormControlState } from 'ngrx-forms';
import { Room } from '../../../core/models/room.model';

@Component({
  selector: 'lib-common-room-picker-or-text[roomFormControlState]',
  templateUrl: './room-picker-or-text.component.html',
  styleUrls: ['./room-picker-or-text.component.scss'],
})
export class RoomPickerOrTextComponent {
  @Input() roomFormControlState: FormControlState<string>;
  @Input() alternativeRoomNameFormControlState: FormControlState<string>;
  @Input() isTextFormControlState: FormControlState<boolean>;
  @Input() rooms: Room[];
  @Input() easyFieldConfig: string;
  @Input() selectRoomI18n = 'selectRoom';
  @Input() enterRoomI18n = 'enterRoom';
  @Input() noRoomInListI18n = 'noRoomInList';
}
