<div *ngIf="!webcamImage">
  <lib-common-form-flex-container>
    <webcam
      (imageCapture)="handleImage($event)"
      (initError)="camError($event)"
      [trigger]="trigger"
    ></webcam>
  </lib-common-form-flex-container>
  <lib-common-form-flex-container>
    <button
      mat-raised-button
      fxFlex="1 1 100%"
      type="button"
      color="primary"
      (click)="takePhoto();"
    >{{'ocrScreenshot' | i18n | async}}
    </button>
  </lib-common-form-flex-container>
</div>
<div *ngIf="webcamImage">
  <lib-common-form-flex-container>
    <img [src]="webcamImage.imageAsDataUrl" alt="webcam image">
  </lib-common-form-flex-container>
  <lib-common-form-flex-container>
    <div
      fxFlex="1 1 50%"
    >
      <button
        mat-raised-button
        type="button"
        fxFlex="1 1 100%"
        color="accent"
        (click)="retakePhoto();"
      >{{'ocrRetakeScreenshot' | i18n | async}}
      </button>
    </div>
    <div
      fxFlex="1 1 50%"
    >
      <button
        mat-raised-button
        type="button"
        fxFlex="1 1 100%"
        color="primary"
        (click)="scan();"
      >{{'ocrScanButton' | i18n | async}}
      </button>
    </div>
  </lib-common-form-flex-container>
</div>
