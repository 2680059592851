import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControlState } from 'ngrx-forms';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-input-property-defaults',
  templateUrl: './input-property-defaults.component.html',
  styleUrls: ['./input-property-defaults.component.scss'],
})
export class InputPropertyDefaultsComponent {
  @Input() ngrxMandatory: FormControlState<boolean>;
  @Input() ngrxEnabled: FormControlState<boolean>;
  @Input() ngrxVisible: FormControlState<boolean>;
  @Input() ngrxChangeableByInspector: FormControlState<boolean>;
  @Input() ngrxChangeableByApprover: FormControlState<boolean>;
}
