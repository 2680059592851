import { ConfigDtoModel, ConfigurationItems } from './config-dto.model';
import { ConfigListDtoModel, getInitialConfigListDtoModel } from './config-list-dto.model';

export class GlobalConfigDtoModel extends ConfigDtoModel<GlobalConfigDtoItemsModel> {
  configurationItems = {
    taxrates: getInitialConfigListDtoModel('taxrates'),
    ledgerAccounts: getInitialConfigListDtoModel('ledgerAccounts'),
    salutations: getInitialConfigListDtoModel('salutations'),
  };

  constructor() {
    super(new GlobalConfigDtoItemsModel(), undefined, undefined);
  }
}

class GlobalConfigDtoItemsModel extends ConfigurationItems {
  taxrates: ConfigListDtoModel;
  ledgerAccounts: ConfigListDtoModel;
  salutations: ConfigListDtoModel;
}
