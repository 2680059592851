import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { UserRole } from '../../core/models/user-roles.model';

interface RouteDataModel {
  navDisplay: { name: string, link: string }[];
  configProp: string;
  allowedRoles: UserRole[];
  featureName: string;
  formIdentifier: string;
  isNewProposal: boolean;
  routeIdentifier: string;
  isCateringOnly: boolean;
  editMode: boolean;
  templateMode: boolean;
  confirmMode: boolean;
  approveMode: boolean;
}

@Injectable({providedIn: 'root'})
export class RouteInfoService {

  constructor(private route: ActivatedRoute) {
  }

  /**
   * Versucht rekursive aus der Route die Property aus dem "data" Objekt zu lesen. Gibt den ersten gefundenen Wert zurück
   * @param prop Property name der gesucht werden soll
   */
  getData<T extends keyof RouteDataModel, R = RouteDataModel[T] | undefined>(prop: T): R {
    const routeInfo = this.getInfo(prop, 'data', this.route.snapshot);
    if (!routeInfo && prop === 'routeIdentifier')  {
      return this.getData('formIdentifier');
    }
    return routeInfo;
  }

  /**
   * Versucht rekursive aus der Route die Property aus dem "params" Objekt zu lesen. Gibt den ersten gefundenen Wert zurück
   * @param prop Property name der gesucht werden soll
   */
  getParam(prop: string) {
    return this.getInfo(prop, 'params', this.route.snapshot);
  }

  protected getInfo(prop: string, type: 'data' | 'params', snapshot: ActivatedRouteSnapshot) {
    if (snapshot.firstChild && !snapshot[type][prop]) {
      return this.getInfo(prop, type, snapshot.firstChild);
    }
    return snapshot[type][prop];
  }

  getUrl() {
    return window.location.href;
  }
}
