import { EasyFormBConfigItems } from '../../../forms-lib/models/easy-form-b-config.model';

export class EasyFormB3ConfigItems extends EasyFormBConfigItems {
  constructor() {
    super();
    delete this.location;
    delete this.date;
  }

}
