import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class InnogyIconsService {
  public constructor(
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry,
  ) {
  }

  registerIcons() {
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      '24h',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_24h.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'arrow-down',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_arrow-down.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'arrow-down-02',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_arrow-down-02.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'arrow-left',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_arrow-left.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'arrow-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_arrow-right.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'arrow-right-02',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_arrow-right-02.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'arrow-right-up-02',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_arrow-right-up-02.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'attention',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_attention.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'bar-chart',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_bar-chart.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'bell',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_bell.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'bin-c-check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_bin_c-check.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'blinking-light',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_blinking-light.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'box',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_box.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'briefcase',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_briefcase.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'business-home',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_business_home.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'business-large',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_business_large.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'business-small',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_business_small.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-arrow-down',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-arrow-down.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-arrow-down-02',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-arrow-down-02.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-arrow-left',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-arrow-left.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-arrow-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-arrow-right.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-arrow-right-02',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-arrow-right-02.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-arrow-right-up-02',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-arrow-right-up-02.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-check.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-cross',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-cross.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-double-arrow-02',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-double-arrow-02.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-envelope',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-envelope.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-gripper',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-gripper.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-minus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-minus.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-pause',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-pause.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-photo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-photo.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-play',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-play.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-plus.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-speaker',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-speaker.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-subtitle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-subtitle.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'c-wifi',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_c-wifi.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'calculator',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_calculator.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'calendar-date',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_calendar-date.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_check.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'clipboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_clipboard.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'clock',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_clock.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'close',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_close.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'control-panel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_control-panel.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'cookies',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_cookies.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'credit-card',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_credit_card.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'cutlery',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_cutlery.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'doc-world',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_doc_world.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'docs-check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_docs_check.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'docs-cv',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_docs_cv.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'docs-dollar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_docs_dollar.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'docs-euro',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_docs_euro.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'docs-exclamation-mark',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_docs_exclamation-mark.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'docs-graph',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_docs_graph.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'docs-id',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_docs_id.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'docs-text',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_docs_text.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'double-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_double-arrow.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'double-gear',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_double-gear.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'ear',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_ear.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'elevator-01',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_elevator_01.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'elevator-02',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_elevator_02.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'envelope',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_envelope.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'envelope-at',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_envelope_at.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'eye',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_eye.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'first-aid',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_first-aid.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'flame',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_flame.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'flash-drive-c-check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_flash-drive_c-check.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'flask',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_flask.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'foot-print',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_foot-print.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'gauge',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_gauge.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'gift',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_gift.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'gift-card',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_gift-card.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'graph',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_graph.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'graph-up',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_graph-up.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'gripper',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_gripper.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'hand',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_hand.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'hand-shake',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_hand-shake.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'hand-wash',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_hand-wash.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'hand-world',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_hand_world.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'handicap',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_handicap.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'hanger',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_hanger.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'headphone',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_headphone.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'headset',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_headset.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'heart',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_heart.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'home-large',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_home_large.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'home-small',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_home_small.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'hydropower',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_hydropower.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'id-chip',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_id-chip.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'info',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_info.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'key',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_key.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'key-check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_key_check.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'laptop',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_laptop.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'leaf',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_leaf.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'leaf-cycle-new',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_leaf_cycle_new.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'life-ring',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_life-ring.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'light-bulb',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_light-bulb.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'lightning',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_lightning.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'lock-closed',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_lock_closed.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'lock-open',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_lock_open.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'magnifier',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_magnifier.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'magnifier-switch',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_magnifier-switch.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'magnifier-gear',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_magnifier_gear.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'magnifier-ground-wire',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_magnifier_ground-wire.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'magnifier-ohm',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_magnifier_ohm.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'man-in-the-middle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_man_in_the_middle.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'map-pin',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_map_pin.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'menu',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_menu.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'meterdata',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_meterdata.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'money-bag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_money-bag.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'name-tag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_name-tag.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'paragraph',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_paragraph.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'parking',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_parking.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'pen',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_pen.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'people',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_people.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'people-02',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_people-02.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'people-03',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_people-03.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'people-table',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_people_table.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'person',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_person.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'person-gear',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_person_gear.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'person-graph',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_person_graph.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'person-info',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_person_info.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'phone',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_phone.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'phone-02',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_phone-02.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'phone-location',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_phone_location.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'photovoltaic',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_photovoltaic.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'pie-chart',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_pie-chart.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'piggy-bank',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_piggy-bank.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'play',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_play.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'power-on-off',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_power-on-off.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'printer',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_printer.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'projector',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_projector.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'restroom-01',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_restroom-01.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'restroom-02',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_restroom-02.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'ruler-paragraph',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_ruler-paragraph.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'scale',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_scale.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'screen',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_screen.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'screen-lock',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_screen-lock.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'share',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_share.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'shield',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_shield.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'shopping-cart',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_shopping-cart.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'shopping-cart-put',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_shopping-cart-put.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'shopping-cart-check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_shopping-cart-check.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'shredder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_shredder.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'smiley',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_smiley.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'smiley-meh',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_smiley-meh.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'smiley-sad',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_smiley-sad.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'socket',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_socket.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'socket-ev',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_socket-ev.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'software',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_software.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'speech-bubble',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_speech-bubble.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'speech-bubble-questionmark',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_speech-bubble-questionmark.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'star',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_star.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'tag-energy-class',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_tag-energy-class.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'tag-percentage',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_tag-percentage.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'thumbs-up',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_thumbs-up.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'tools',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_tools.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'trophy',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_trophy.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'umbrella',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_umbrella.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'vacuum-cleaner',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_vacuum-cleaner.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'van',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_van.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'wifi',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_wifi.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'wifi-lock',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_wifi-lock.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'wifi-refresh',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_wifi-refresh.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'windmill',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_windmill.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'windmill-offshore',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_windmill-offshore.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'world',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_world.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'yammer',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_yammer.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'floppy',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_floppy.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'floppy2',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_floppy2.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'doc-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_doc-arrow.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'house',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_house.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'left-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_left-arrow.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'left-arrow2',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_left-arrow2.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'paperclip',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_paperclip.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_plus.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'plus2',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_plus2.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'star2',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_star2.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'three-points',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_three-points.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'three-points2',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_three-points2.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'trash',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_trash.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'up-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_up-arrow.svg'),
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'innogy',
      'up-arrow2',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/innogy_picto_up-arrow2.svg'),
    );
  }
}
