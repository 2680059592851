<div
  class="system-message"
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="10px"
>
  <div>
    <mat-icon svgIcon="innogy:speech-bubble-questionmark"></mat-icon>
  </div>
  <div>
    <p *ngIf="header">{{ header }}</p>
    <p
      #htmlElement
      class="info-text"
      [ngClass]="{'info-text-no-header': !header, 'info-text-with-header': header}"
    >
      <ng-content></ng-content>
    </p>
  </div>
</div>
