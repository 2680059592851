<div
  [fxLayout]="layout"
  fxLayoutGap="10px grid"
  fxLayoutAlign="flex-start"
>
  <div
    class="approver"
    fxFlex="100%"
  >
    <div
      fxLayout="row wrap"
      fxLayoutAlign="space-between"
      fxLayoutGap="10px grid"
    >
      <ng-container *ngFor="let approver of approverControls; trackBy: getIdProperty; let i = index; last as isLast">
        <div
          fxFlex.gt-md="100%"
          fxFlex.lt-lg="100%"
        >
          <lib-common-user-search
            *ngIf="valueConverter"
            [ngrxFormControlState]="asId ? getIdProperty(approver.controls) : approver"
            [ngrxValueConverter]="valueConverter"
            [asId]="asId"
            [placeholder]="(i + 1) + '. ' + (placeholder || ('confirmationPerson' | i18n | async))"
            [required]="isRequiredChild(approver, i)"
            [disabled]="isDisabledChild(approver, i)"
            [removable]="removable && !isRequiredChild(approver, i)"
            (removeClick)="removeClick.emit(approver)"
          ></lib-common-user-search>
        </div>
        <div
          fxFlex.gt-md="82%"
          fxFlex.lt-lg="100%"
        >
          <lib-common-user-search
            *ngIf="!valueConverter"
            [ngrxFormControlState]="asId ? getIdProperty(approver.controls) : approver"
            [asId]="asId"
            [placeholder]="(i + 1) + '. ' + (placeholder || ('confirmationPerson' | i18n | async))"
            [required]="isRequiredChild(approver, i)"
            [disabled]="isDisabledChild(approver, i)"
            [removable]="removable && !isRequiredChild(approver, i)"
            (removeClick)="removeClick.emit(approver)"
          ></lib-common-user-search>
        </div>
        <div
          *ngIf="!disabled && isLast"
          fxFlex.gt-md="18%"
          fxFlex.lt-lg="100%"
        >
          <button
            [color]="isMinReached() ? addButtonColor : 'warn'"
            mat-mini-fab
            type="button"
            (click)="addClick.emit()"
            [disabled]="!canAdd()"
          >
            <mat-icon svgIcon="innogy:plus"></mat-icon>
          </button>
        </div>
        <div
          fxFlex.gt-md="70%"
        >
          <mat-slide-toggle
            color="primary"
            *ngIf="enableable && enablePropertyName"
            [ngrxFormControlState]="getToggleControlState(approver)"
          >
            {{enableTextI18n | i18n | async}}
          </mat-slide-toggle>
        </div>
      </ng-container>
      <div
        *ngIf="(!approverControls || approverControls.length == 0) && !disabled"
        fxFlex.gt-md="18%"
        fxFlex.lt-lg="18%"
      >
        <button
          [color]="isMinReached() ? addButtonColor : 'warn'"
          mat-mini-fab
          type="button"
          (click)="addClick.emit()"
          [disabled]="!canAdd()"
        >
          <mat-icon svgIcon="innogy:plus"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
