import { backendClasses } from './backend-classes.model';
import { ConfigItemConfigurationDtoModel } from './config-item-configuration-dto.model';

export class ConfigItemTextInputConfigurationDtoModel extends ConfigItemConfigurationDtoModel {
  static readonly CLASSNAME: string = backendClasses.textInputConfiguration;
  static TYPENAME = 'typeString';
  '@class' = ConfigItemTextInputConfigurationDtoModel.CLASSNAME;
  $$typeName = ConfigItemTextInputConfigurationDtoModel.TYPENAME;

  regex = '.*';
  maxLength = 0;
  predefinedValue: string = undefined;
}
