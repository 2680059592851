import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent implements AfterViewInit {
  appConfig = this.appConfigService.config;
  mailTo = this.appConfig.footer.contactMailTo || this.appConfig.mailTo;
  footerConfig = this.appConfig.footer;

  @Input() showVersion = true;
  @Output() renderedElement = new EventEmitter<{
    height: number
  }>();
  @ViewChild('footer', { static: true }) elementView: ElementRef;

  constructor(private appConfigService: AppConfigService) {
  }

  ngAfterViewInit() {
    this.renderedElement.emit({
      height: this.elementView.nativeElement.offsetHeight,
    });
  }

  getSupportMailLink(subject: string, mailContent: string) {
    return `${this.mailTo}?subject=${subject}&body='${encodeURIComponent(mailContent)}`;
  }
}
