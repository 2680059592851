/**
 * Flex Rahmen für Formulare.
 * Wenn explizit maxWidth="123px" -> fxFlex="0 1 123px"
 * Wenn explizit maxWidth="" -> fxFlex="1 1 auto"
 */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-form-flex-container',
  templateUrl: './form-flex-container.component.html',
  styleUrls: ['./form-flex-container.component.scss'],
})
export class FormFlexContainerComponent {
  @Input() maxWidth = '1200px';

  getContainerFlexStr = () => this.maxWidth ? `0 1 ${this.maxWidth}` : '1 1 auto';
}
