<!-- mat-checkbox in mat-form-field.
  https://github.com/angular/components/issues/7891
-->

<mat-form-field class="my-ugly-mat-checkbox" floatLabel="never" *ngIf="ngrxFormControlStateValue">
  <input
    matInput
    placeholder="Input"
    class="hidden"
    [ngrxFormControlState]="ngrxFormControlStateValue"
  >
  <mat-checkbox
    [ngrxFormControlState]="ngrxFormControlStateValue"
    (change)="change.emit($event)"
    [ngrxEasyFieldConfig]="configName"
  >
    <span class="mat-form-field-label mat-form-field-empty ugly-label">
      {{placeholder}}{{isRequired() ? '*' : ''}}
    </span>
  </mat-checkbox>
</mat-form-field>
