import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UrlTokenReplacerService {

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
  }

  replace(url: string) {
    let replacedUrl = url;
    const params = this.activatedRoute?.snapshot?.firstChild?.firstChild?.params;
    if (!params) {
      return url;
    }
    for (const token of Object.keys(params)) {
      replacedUrl = replacedUrl.replace(':' + token, params[token]);
    }
    return replacedUrl;
  }
}
