<lib-common-form-inner-margin>
  <div
    fxLayout="row wrap"
    fxLayoutAlign="flex-start"
    fxLayoutGap="20px grid"
  >
    <div
      class="form-style"
      fxFlex="100%"
    >
      <mat-card class="mat-card-margin">
        <mat-card-header>
          <mat-card-title>{{'downloadExcelTemplateHeader'| i18n | async}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="margin-text">
            <div class="margin-info-text-bottom">
              <div
                fxLayout="row wrap"
                fxLayoutAlign="flex-start"
                fxLayoutGap="20px grid"
              >
                <div
                  fxFlex.gt-md="3%"
                  fxFlex.lt-lg="10%"
                >
                  <mat-icon
                    class="svg-info-download"
                    svgIcon="innogy:info"
                  ></mat-icon>
                </div>
                <p
                  fxFlex.gt-md="90%"
                  fxFlex.lt-lg="90%"
                >
                  {{'downloadExcelTemplateInfo' | i18n | async}}
                </p>
              </div>
            </div>
            <p><strong>{{'downloadExcelTemplateProcessLabel' | i18n | async}}</strong></p>
            <div
              fxLayout="row wrap"
              fxLayoutAlign="flex-start"
              fxLayoutGap="20px grid"
            >
              <mat-form-field
                fxFlex.gt-md="30%"
                fxFlex.lt-lg="100%"
              >
                <mat-label>{{'form' | i18n | async}}</mat-label>
                <mat-select [(value)]="downloadFormIdentifier">
                  <mat-option *ngFor="let form of (forms$ | async)" [value]="form.identifier">
                    {{form.fullName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                fxFlex.gt-md="30%"
                fxFlex.lt-lg="100%"
              >
                <mat-label>{{'company' | i18n | async}}</mat-label>
                <mat-select [(value)]="downloadCompanyIdentifier">
                  <mat-option *ngFor="let company of (getCompanies(downloadFormIdentifier) | async)" [value]="company.shortName">
                    {{company.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                fxFlex.gt-md="20%"
                fxFlex.lt-lg="100%"
              >
                <mat-label>{{'downloadExcelTemplateCountLabel' | i18n | async}}</mat-label>
                <input type="number" matInput [(ngModel)]="count">
              </mat-form-field>
            </div>
            <div>
              <button
                type="submit"
                color="primary"
                mat-raised-button
                (click)="download()"
              >
                <mat-icon
                  class="arrow-down"
                  svgIcon="innogy:c-arrow-down-02"
                ></mat-icon>
                {{'downloadExcelTemplateButtonLabel' | i18n | async}}
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card class="mat-card-margin">
        <mat-card-header>
          <mat-card-title>{{'uploadExcelTemplateHeader' | i18n | async}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="margin-text">
            <div class="margin-info-text-bottom">
              <div
                fxLayout="row wrap"
                fxLayoutAlign="flex-start"
                fxLayoutGap="20px grid"
              >
                <div
                  fxFlex.gt-md="3%"
                  fxFlex.lt-lg="10%"
                >
                  <mat-icon
                    class="svg-info-upload"
                    svgIcon="innogy:info"
                  ></mat-icon>
                </div>
                <p
                  fxFlex.gt-md="90%"
                  fxFlex.lt-lg="90%"
                >
                  {{'uploadExcelTemplateInfo' | i18n | async}}
                </p>
              </div>
              <span [innerHTML]="'uploadExcelTemplateHint' | i18n | async | safe:'html'"></span>
            </div>
            <div
              fxLayout="row wrap"
              fxLayoutAlign="flex-start"
              fxLayoutGap="20px grid"
            >
              <mat-form-field
                fxFlex.gt-md="30%"
                fxFlex.lt-lg="100%"
              >
                <mat-label>{{'form' | i18n | async}}</mat-label>
                <mat-select [(value)]="uploadFormIdentifier">
                  <mat-option *ngFor="let form of (forms$ | async)" [value]="form.identifier">
                    {{form.fullName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                fxFlex.gt-md="30%"
                fxFlex.lt-lg="100%"
              >
                <mat-label>{{'company' | i18n | async}}</mat-label>
                <mat-select [(value)]="uploadCompanyIdentifier">
                  <mat-option *ngFor="let company of (getCompanies(uploadFormIdentifier) | async)" [value]="company.shortName">
                    {{company.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div
                fxFlex.gt-md="20%"
                fxFlex.lt-lg="100%"
                class="btn-fileupload"
              >
                <lib-common-file-upload
                  ngrxEasyFieldConfig="file"
                  [maxFileCount]="1"
                  [(ngModel)]="uploadFile"
                  acceptedType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ></lib-common-file-upload>
              </div>
            </div>
            <div>
              <button
                color="primary"
                mat-raised-button
                (click)="upload()"
              >
                <mat-icon
                  class="arrow-down"
                  svgIcon="innogy:up-arrow"
                ></mat-icon>
                {{'uploadExcelTemplateButtonLabel' | i18n | async}}
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</lib-common-form-inner-margin>

