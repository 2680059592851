<lib-common-input-property-wrapper
  [header]="attributeName"
  icon="text_fields"
  [typeNameI18n]="typeName"
  [readonly]="readonly"
  [ngrxOverwrite]="ngrxOverwrite"
  (changeReadonly)="onChangeReadonly($event)"
>
  <div class="card-margin">

    <section>
      <h2 class="mat-h2">Search input type</h2>
      <mat-radio-group
        *ngIf="ngrxType"
        aria-labelledby="radio-group-label"
        class="radio-group"
        [ngrxFormControlState]="ngrxType"
      >
        <mat-radio-button class="radio-button" *ngFor="let type of inputTypes" [value]="type">
          {{type | i18n | async}}
        </mat-radio-button>
      </mat-radio-group>
    </section>

    <section>
      <mat-form-field *ngIf="ngrxPredefinedValue">
        <input
          matInput
          placeholder="{{'defaultValue' | i18n | async}}"
          [ngrxFormControlState]="ngrxPredefinedValue"
          disabled
        >
      </mat-form-field>
      <mat-form-field *ngIf="ngrxAccountingType">
        <mat-select
          placeholder="{{'accountType' | i18n | async}}"
          [ngrxFormControlState]="ngrxAccountingType"
          disabled
        >
          <mat-option *ngFor="let type of accountingTypes" [value]="type">{{type | i18n | async}}</mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <section class="card-checkbox-margin">
      <lib-common-input-property-defaults
        [ngrxMandatory]="ngrxMandatory"
        [ngrxEnabled]="ngrxEnabled"
        [ngrxVisible]="ngrxVisible"
        [ngrxChangeableByInspector]="ngrxChangeableByInspector"
        [ngrxChangeableByApprover]="ngrxChangeableByApprover"
      ></lib-common-input-property-defaults>
    </section>

    <section *ngIf="translations">
      <h2 class="mat-h2 description">{{'translations' | i18n | async}}</h2>
      <lib-common-input-lang-string
        [translations]="translations"
        (undoLangClick)="labelResetClick.emit($event)"
        [undoVisible]="labelResetClick && labelResetClick.observers && labelResetClick.observers.length > 0"
      ></lib-common-input-lang-string>
    </section>
  </div>
</lib-common-input-property-wrapper>
