import { createAction, props } from '@ngrx/store';
import { ActionCreatorsClass } from '../../models/action-creators.class';

export class OcrActions extends ActionCreatorsClass {
  OcrScanFileAction = createAction(
    'OcrScanFileAction',
    props<{
      formIdentifier: string,
      file: string,
      selectedCompanyShortName: string,
    }>(),
  );

  OcrFormResetAction = createAction(
    'OcrFormResetAction',
  );
}

export const ocrActions = new OcrActions();
export type OcrActionsType = Record<string & keyof OcrActions, OcrActions>;
