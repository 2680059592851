import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { FormCompaniesCardChild } from '../../../core-lib/components/form-companies-card/form-companies-card-child';
import { FormCompaniesDialogDataAtomModel } from '../../../core-lib/components/form-companies-dialog/form-companies-dialog-data-atom.model';
import { CompanyDataDtoModel } from '../../../core-lib/models/company-data-dto.model';
import { EasyFormModel } from '../../../core-lib/models/easy-form.model';
import { CoreEasyFormsDeleteFormConfigsAction } from '../../ngrx/actions/core-easy-forms.actions';
import { CoreFeatureState, getCompaniesState } from '../../ngrx/reducers/core.store';
import { getEasyFormsArrayState } from '../../ngrx/reducers/easy-forms.reducer';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-route-config-forms',
  templateUrl: './route-config-forms.component.html',
  styleUrls: ['./route-config-forms.component.scss'],
})
export class RouteConfigFormsComponent {
  companies$ = this.store$.select(getCompaniesState);
  options$ = this.companies$.pipe(
    map((companies) => companies.map((c: CompanyDataDtoModel) => (<FormCompaniesDialogDataAtomModel>{
      displayName: c.name,
      data: c,
    }))),
  );

  easyForms$ = this.store$.select(getEasyFormsArrayState);
  easyFormsMapped$ = this.easyForms$.pipe(
    filter((easyForms) => Array.isArray(easyForms) && easyForms.length > 0),
    withLatestFrom(this.companies$),
    map(([easyForms, companies]) => easyForms.map((form) => ({
      ...form,
      companies: form.companies.map((id) => {
        const company = companies.find((c) => c && c.id === id);
        return new FormCompaniesCardChild(['/config/forms', form.identifier, company.shortName], company.shortName, {
          formId: form.id,
          companyId: company.id,
        });
      }),
    }))),
  );

  forms = [];

  constructor(
    private store$: Store<CoreFeatureState>,
    private router: Router,
  ) {
    this.easyFormsMapped$.subscribe((forms) => this.forms = forms);
  }

  addNewConfiguration(easyForm: EasyFormModel, company: FormCompaniesDialogDataAtomModel) {
    this.router.navigate(['/config/forms', easyForm.identifier, company.data.shortName]);
  }

  deleteConfigs(configs: FormCompaniesCardChild[]) {
    const actionConfigs = configs.map((c) => ({formId: c.data.formId, companyId: c.data.companyId}));
    this.store$.dispatch(new CoreEasyFormsDeleteFormConfigsAction(actionConfigs));
  }
}
