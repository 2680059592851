import AppConfigModel from '../models/app-config.model';

export const defaultAppConfig: AppConfigModel = {
  build: {
    branch: '🍝',
    commit: 'WIP',
    number: '0',
    project: '',
    tag: '🍝-WIP',
    release: 'none',
  },
  enableCatering: false,
  enableNetRegion: false,
  enableMassData: false,
  massDataForms: [],
  backendBase: '', // no slash at the end!
  logo: '/assets/images/logo.png',
  efaLogo: '/assets/images/easy.png',
  mailTo: 'mailto:EASY4ALL@innogy.com',
  supportedAccountingTypes: [],
  expandedCategoryTree: false,
  enableParticipantListEmployeeSupport: false,
  enableFuvStateSelector: false,
  footer: {
    legalDisclosureType: 'link',
    legalDisclosurePath: 'https://lmgtfy.com/?q=impressum&s=d&iie=1',
    dataProtectionType: 'link',
    dataProtectionPath: 'https://lmgtfy.com/?q=datenschutzerklaerung&s=d&iie=1',
    supportType: 'defaultMail',
    faqType: 'route',
    faqPath: '',
  },
};
