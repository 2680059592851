<div (keyup.control.enter)="close(eventTypes.ACCEPTED)" (keyup.escape)="close(eventTypes.DECLINED)">
  <h1 mat-dialog-title class="search-modal">{{ title | i18n:replacements | async }}</h1>
  <div mat-dialog-content class="dialog-modal" [innerHTML]="text | i18n:replacements | async | safe: 'html'">
  </div>
  <div mat-dialog-actions>
    <button type="button" mat-button (click)="close(eventTypes.DECLINED)"
            cdkFocusInitial>{{ declineLabel | i18n | async }}</button>
    <button *ngIf="showAcceptLabel" type="button" mat-button
            (click)="close(eventTypes.ACCEPTED)">{{ acceptLabel | i18n | async }}</button>
  </div>
</div>
