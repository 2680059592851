import { PageableModel } from '../../../core-lib/models/pageable.model';
import { ProposalHeadModel } from '../../../core-lib/models/proposal-head.model';
import { TableColumnConfigModel } from '../../../core-lib/models/table-column-config.model';
import { ProposalTableSelectionModel } from '../../models/table-selection.model';

export interface ProposalTableState extends ProposalTableSelectionModel<PageableModel<ProposalHeadModel>> {
  columnConfig: ProposalTableSelectionModel<TableColumnConfigModel[]>;
  filterConfig: ProposalTableSelectionModel<TableColumnConfigModel[]>;
  loading: boolean;
}

const emptyPage = {
  content: [],
  totalPages: 0,
  totalElements: 0,
  size: 20,
  number: 0,
};

const defaultTableConfig = [
  {
    columnName: 'selected',
    visible: true,
    position: -1,
  },
  {
    columnName: 'actions',
    visible: true,
    position: 0,
  }, {
    columnName: 'id',
    visible: true,
    position: 1,
  }, {
    columnName: 'state',
    visible: true,
    position: 4,
  }, {
    columnName: 'authorId',
    visible: true,
    position: 2,
  }, {
    columnName: 'created',
    visible: true,
    position: 3,
  }, {
    columnName: 'amount',
    visible: true,
    position: 5,
  }, {
    columnName: 'category',
    visible: true,
    position: 6,
  }, {
    columnName: 'lastModified',
    visible: true,
    position: 7,
  }, {
    columnName: 'currentArrangerId',
    visible: true,
    position: 8,
  }, {
    columnName: 'useCase',
    visible: true,
    position: 9,
  }, {
    columnName: 'recipientName',
    visible: true,
    position: 10,
  }, {
    columnName: 'sapReferenceNumber',
    visible: true,
    position: 11,
  }, {
    columnName: 'startDateTime',
    visible: true,
    position: 12,
  },
];

export const initialProposalTableState: ProposalTableState = {
  columnConfig: {
    ALL: defaultTableConfig,
    ALL_PENDING_APPROVALS: defaultTableConfig,
    DEPUTY_PENDING_APPROVALS: defaultTableConfig,
    FINISHED_APPROVALS: defaultTableConfig,
    OWN: defaultTableConfig,
    OWN_PENDING_APPROVALS: defaultTableConfig,
    DEPUTY: defaultTableConfig,
  },
  filterConfig: {
    ALL: [],
    ALL_PENDING_APPROVALS: [],
    DEPUTY_PENDING_APPROVALS: [],
    FINISHED_APPROVALS: [],
    OWN: [],
    OWN_PENDING_APPROVALS: [],
    DEPUTY: [],
  },
  loading: false,
  OWN: emptyPage,
  ALL: emptyPage,
  DEPUTY: emptyPage,
  ALL_PENDING_APPROVALS: emptyPage,
  DEPUTY_PENDING_APPROVALS: emptyPage,
  FINISHED_APPROVALS: emptyPage,
  OWN_PENDING_APPROVALS: emptyPage,
};
