import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Moment } from 'moment';
import { FormControlState, NgrxValueConverter } from 'ngrx-forms';
import { RouteConfigFormPropertyComponent } from '../../../core/components/route-config-form-property/route-config-form-property.component';
import { CoreFeatureState } from '../../../core/ngrx/reducers/core.store';
import { ConfigItemDateConfigurationDtoModel } from '../../models/config-item-date-configuration-dto.model';
import { SetValueTraceableAction } from '../../models/set-value-traceable-action';
import { InputPropertyTypeComponent } from '../input-property-type/input-property-type.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-common-input-property-type-date',
  templateUrl: './input-property-type-date.component.html',
  styleUrls: ['./input-property-type-date.component.scss'],
})
export class InputPropertyTypeDateComponent extends InputPropertyTypeComponent {

  typeName = ConfigItemDateConfigurationDtoModel.TYPENAME;

  @Input() ngrxMinDate: FormControlState<string>;
  @Input() ngrxMaxDate: FormControlState<string>;
  @Input() ngrxWeekendsActive: FormControlState<boolean>;
  @Input() ngrxMinDateCurrentDate: FormControlState<boolean>;
  @Input() ngrxMaxDateCurrentDate: FormControlState<boolean>;
  @Input() ngrxPredefineWithCurrentDate: FormControlState<boolean>;

  ngrxDateValueConverter = <NgrxValueConverter<Moment, string>>{
    convertViewToStateValue: (value: moment.Moment): string => value.format('YYYY-MM-DD'),
    convertStateToViewValue: (value: string): moment.Moment => moment(value),
  };

  constructor(store$: Store<CoreFeatureState>, util: RouteConfigFormPropertyComponent) {
    super(store$, util);
  }

  internalReloadValues(lastConfigItemValue) {
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxMinDate.id, lastConfigItemValue.minDate));
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxMaxDate.id, lastConfigItemValue.maxDate));
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxWeekendsActive.id, lastConfigItemValue.weekends));
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxMinDateCurrentDate.id, lastConfigItemValue.minDateCurrentDate));
    this.store$.dispatch(new SetValueTraceableAction(this.ngrxMaxDateCurrentDate.id, lastConfigItemValue.maxDateCurrentDate));
  }
}
