import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SetValueAction } from 'ngrx-forms';
import { pipe } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { ApiService } from '../../../core-lib/services/api.service';
import { defaultEffectOptions } from '../../../core-lib/utils/default-effect-options';
import { AppConfigService } from '../../services/app-config.service';
import { switchMapWithSpinner } from '../../utils/switch-map-with-spinner';
import {
  coreDashboardUpdatePayoutAction,
  coreDashboardUpdatePayoutSuccessAction,
  coreDashboardUpdateStateCountAction,
  coreDashboardUpdateStateCountSuccessAction,
  coreDashboardUpdateThroughputAction,
  coreDashboardUpdateThroughputSuccessAction,
} from '../actions/dashboard.actions';
import { CoreFeatureState } from '../reducers/core.store';
import {
  getDashboardPayoutFormState,
  getDashboardPayoutState,
  getDashboardStateCountFormState,
  getDashboardStateCountState,
  getDashboardThroughputFormState,
  getDashboardThroughputState,
  PayoutFormGroupState,
  StateCountFormGroupState,
  ThroughputFormGroupState,
} from '../reducers/dashboard.store';

@Injectable()
export class DashboardEffects {
  stateCountFrom$ = this.store$.select(getDashboardStateCountFormState);
  throughputFrom$ = this.store$.select(getDashboardThroughputFormState);
  payoutFrom$ = this.store$.select(getDashboardPayoutFormState);

  dataTimeout = 15000;

  stateSpinnerKey = 'StateCount';
  loadStateCountData$ = createEffect(() => this.actions$.pipe(
    filter<typeof coreDashboardUpdateStateCountAction | SetValueAction<string>>((a) => (
      (a.type === SetValueAction.TYPE && a.controlId?.startsWith(StateCountFormGroupState.ID))
      || a.type === coreDashboardUpdateStateCountAction.type
    )),
    withLatestFrom(this.stateCountFrom$),
    switchMapWithSpinner(
      ([_, state]) => this.api.getDashboardStateCount(state.value.from, state.value.to),
      this.stateSpinnerKey,
      pipe(
        map((statecount) => coreDashboardUpdateStateCountSuccessAction({statecount})),
      ),
      this.store$,
      this.dataTimeout,
    ),
  ), defaultEffectOptions());

  throughputSpinnerKey = 'Throughput';
  loadThroughputData$ = createEffect(() => this.actions$.pipe(
    filter<typeof coreDashboardUpdateThroughputAction | SetValueAction<string>>((a) => (
      (a.type === SetValueAction.TYPE && a.controlId?.startsWith(ThroughputFormGroupState.ID))
      || a.type === coreDashboardUpdateThroughputAction.type
    )),
    withLatestFrom(this.throughputFrom$),
    switchMapWithSpinner(
      ([_, state]) => this.api.getDashboardThroughput(state.value.from, state.value.to),
      this.stateSpinnerKey,
      pipe(
        map((throughput) => coreDashboardUpdateThroughputSuccessAction({throughput})),
      ),
      this.store$,
      this.dataTimeout,
    ),
  ), defaultEffectOptions());

  payoutSpinnerKey = 'Payout';
  loadPayoutData$ = createEffect(() => this.actions$.pipe(
    filter<typeof coreDashboardUpdatePayoutAction | SetValueAction<string>>((a) => (
      (a.type === SetValueAction.TYPE && a.controlId?.startsWith(PayoutFormGroupState.ID))
      || a.type === coreDashboardUpdatePayoutAction.type
    )),
    withLatestFrom(this.payoutFrom$),
    switchMapWithSpinner(
      ([_, state]) => this.api.getDashboardAmounts(state.value.from, state.value.to),
      this.payoutSpinnerKey,
      pipe(
        map((payout) => coreDashboardUpdatePayoutSuccessAction({payout})),
      ),
      this.store$,
      this.dataTimeout,
    ),
  ), defaultEffectOptions());

  constructor(
    private actions$: Actions,
    private store$: Store<CoreFeatureState>,
    private api: ApiService,
    protected appConfigService: AppConfigService,
  ) {
  }
}
