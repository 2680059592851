import { ConfigurationItems } from '../../core-lib/models/config-dto.model';
import { ConfigItemAccountingConfigurationDtoModel } from '../../core-lib/models/config-item-accounting-configuration-dto.model';
import { ConfigItemArrangerListConfigurationDtoModel } from '../../core-lib/models/config-item-arranger-list-configuration-dto.model';
import { ConfigItemCheckboxConfigurationDtoModel } from '../../core-lib/models/config-item-checkbox-configuration-dto.model';
import { ConfigItemConfigurationCategoryModel } from '../../core-lib/models/config-item-configuration-dto.model';
import { ConfigItemDateConfigurationDtoModel } from '../../core-lib/models/config-item-date-configuration-dto.model';
import { ConfigItemFileUploadConfigurationDtoModel } from '../../core-lib/models/config-item-file-upload-configuration-dto.model';
import { ConfigItemLedgerAccountConfigurationDtoModel } from '../../core-lib/models/config-item-ledger-account-configuration-dto.model';
import { ConfigItemListConfigurationDtoModel } from '../../core-lib/models/config-item-list-configuration-dto.model';
import { ConfigItemNumberConfigurationDtoModel } from '../../core-lib/models/config-item-number-configuration-dto.model';
import { ConfigItemTextInputConfigurationDtoModel } from '../../core-lib/models/config-item-text-input-configuration-dto.model';
import { ConfigListDtoModel } from '../../core-lib/models/config-list-dto.model';
import { PayeeDtoModel } from '../../forms/all-forms/models/payee-dto.model';
import { FormA1DtoModel } from '../../forms/form-a1/models/form-a1-dto.model';
import { EqualKeyNames } from './equal-key-names';

export const EasyFormASteps = {
  step1: <ConfigItemConfigurationCategoryModel>{
    id: 'step1',
    displayName: 'Gesellschaft',
  },
  step2: <ConfigItemConfigurationCategoryModel>{
    id: 'step2',
    displayName: 'Zahlungsempfänger',
  },
  step3: <ConfigItemConfigurationCategoryModel>{
    id: 'step3',
    displayName: 'Rechnungsangaben',
  },
};

// ToDo: Check ob dieses Config-Model auch via FormA1Step1,FormA1Step2,FormA1Step3 bzw FormA1State erzeugt werden kann.
export class EasyFormAConfigItems extends ConfigurationItems implements EqualKeyNames<FormA1DtoModel>, EqualKeyNames<PayeeDtoModel> {
  payeeNumber = new ConfigItemTextInputConfigurationDtoModel('payeeNumber').withCategory(EasyFormASteps.step2);

  // PayeeDtoModel
  salutation = new ConfigItemTextInputConfigurationDtoModel('salutation').withCategory(EasyFormASteps.step2);
  name = new ConfigItemTextInputConfigurationDtoModel('name').withCategory(EasyFormASteps.step2);
  nameAddition = new ConfigItemTextInputConfigurationDtoModel('nameAddition').withCategory(EasyFormASteps.step2);
  street = new ConfigItemTextInputConfigurationDtoModel('street').withCategory(EasyFormASteps.step2);
  zipCode = new ConfigItemTextInputConfigurationDtoModel('zipCode').withCategory(EasyFormASteps.step2);
  city = new ConfigItemTextInputConfigurationDtoModel('city').withCategory(EasyFormASteps.step2);
  country = new ConfigItemTextInputConfigurationDtoModel('country').withCategory(EasyFormASteps.step2);
  email = new ConfigItemTextInputConfigurationDtoModel('email').withCategory(EasyFormASteps.step2);
  iban = new ConfigItemTextInputConfigurationDtoModel('iban').withCategory(EasyFormASteps.step2);
  bic = new ConfigItemTextInputConfigurationDtoModel('bic').withCategory(EasyFormASteps.step2);

  // Verwendungszweck
  useCase = new ConfigItemTextInputConfigurationDtoModel('useCase').withCategory(EasyFormASteps.step3);
  description = new ConfigItemTextInputConfigurationDtoModel('description').withCategory(EasyFormASteps.step3);
  event = new ConfigItemCheckboxConfigurationDtoModel('event').withCategory(EasyFormASteps.step3);
  fuvNumber = new ConfigItemTextInputConfigurationDtoModel('fuvNumber').withCategory(EasyFormASteps.step3);
  invoiceDate = new ConfigItemDateConfigurationDtoModel('invoiceDate').withCategory(EasyFormASteps.step3);
  assignment = new ConfigItemTextInputConfigurationDtoModel('assignment').withCategory(EasyFormASteps.step3);
  bookedOn = new ConfigItemDateConfigurationDtoModel('bookedOn').withCategory(EasyFormASteps.step3);

  // positions
  positions = new ConfigListDtoModel('positions').withCategory(EasyFormASteps.step3);
  positionName = new ConfigItemTextInputConfigurationDtoModel('positionName').withCategory(EasyFormASteps.step3);
  netCost = new ConfigItemNumberConfigurationDtoModel('netCost').withCategory(EasyFormASteps.step3);
  taxRate = new ConfigItemListConfigurationDtoModel('taxRate', 'taxrates').withCategory(EasyFormASteps.step3);
  grossCost = new ConfigItemNumberConfigurationDtoModel('grossCost').withCategory(EasyFormASteps.step3);
  taxValue = new ConfigItemNumberConfigurationDtoModel('taxValue').withCategory(EasyFormASteps.step3);
  account = new ConfigItemAccountingConfigurationDtoModel('account').withCategory(EasyFormASteps.step3);
  ledgerAccount = new ConfigItemLedgerAccountConfigurationDtoModel('ledgerAccount').withCategory(EasyFormASteps.step3);

  // payout
  payout = new ConfigItemNumberConfigurationDtoModel('payout').withCategory(EasyFormASteps.step3);

  // Genehmigungsstufen
  approver = new ConfigItemArrangerListConfigurationDtoModel('approver').withCategory(EasyFormASteps.step3);
  inspector = new ConfigItemArrangerListConfigurationDtoModel('inspector').withCategory(EasyFormASteps.step3);
  commentForApprover = new ConfigItemTextInputConfigurationDtoModel('commentForApprover').withCategory(EasyFormASteps.step3);

  file = new ConfigItemFileUploadConfigurationDtoModel('file').withCategory(EasyFormASteps.step3);
  leaflet = new ConfigItemCheckboxConfigurationDtoModel('leaflet').withCategory(EasyFormASteps.step3);
}
