export const lazyFormKeys = [
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
  'B1',
  'B2',
  'B3',
  'B4',
  'IB1',
  'IB1c',
  'M1',
  'M1c',
  'AF01',
  'AF03',
  'AF04',
  'AF05',
  'AF06',
  'AF07',
  'AF08',
  'AF09',
  'AF10',
  'AF15',
  'AF16',
  'AF14',
  'AF17',
  'AF18',
  'AF19',
  'AF20',
  'AF21',
  'C01',
  'K1',
  'K2',
  'K3',
  'S01',
  'S02',
  'U1',
  'W1',
  'W2',
  'W2wfs',
  'W3',
  'W4',
  'W4wfs',
  'W5',
  'W6',
  'W6wfs',
  'W7',
  'W8',
  'W8wfs',
  'W9',
  'W10',
  'W10wfs',
  'W11',
  'W12',
  'W12wfs',
  'W13',
  'W15',
  'W16',
  'W16wfs',
  'W17',
  'W18',
  'W18wfs',
  'W19',
  'W20',
  'W20wfs',
  'W21',
  'W22',
  'W22wfs',
  'W23',
  'W24',
  'W24wfs',
  'W25',
  'W26',
  'W26wfs',
  'W27',
  'W28',
  'W28wfs',
  'W29',
  'W30',
  'W30wfs',
  'W31',
  'W32',
  'W32wfs',
  'W33',
  'W34',
  'W34wfs',
  'W35',
  'W37',
  'W39',
  'W41',
  'W43',
  'W45',
  'W47',
  'W49',
];
