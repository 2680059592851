<lib-common-page-margin>
  <div class="margin-faq">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let faqTreeEntry of (faqTreeEntries$ | async)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{ faqTreeEntry.section }}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-accordion>
          <mat-expansion-panel *ngFor="let faq of faqTreeEntry.items">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>{{ faq.question }}</strong>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p [innerHTML]="faq.answer | safe:'html'">
            </p>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-expansion-panel>
      <mat-expansion-panel *ngFor="let faq of (faqEntries$ | async)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{ faq.question }}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p [innerHTML]="faq.answer | safe:'html'">
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</lib-common-page-margin>
