<div
  class="session"
  fxLayout="row"
  fxLayoutGap="10px"
  fxLayoutAlign="space-between center"
>
  <div fxFlex="1"></div>
  <div
    class="session-icons"
  >
    <!--Icon Button-->
    <a [routerLink]="'account'"
       [title]="'yourData' | i18n | async">
      <mat-icon class="my-data-icon" svgIcon="innogy:person"></mat-icon>
    </a>
  </div>
  <div fxFlex="1"></div>
  <div>
    <div>
      <a
        *ngIf="isLoggedIn$ | async"
        [href]="logoutUrl$ | async"
      >
        <mat-icon svgIcon="innogy:lock-open"></mat-icon>
        {{ logoutLabel }}
      </a>
      <a
        *ngIf="!(isLoggedIn$ | async)"
        [href]="loginUrl$ | async"
      >
        <mat-icon svgIcon="innogy:lock-closed"
        ></mat-icon>
        {{ loginLabel }}
      </a>
    </div>
    <div>
      <lib-common-language-selector
        [langs]="languages$ | async"
        [initialSelected]="selectedLanguage$ | async"
        (select)="changeLanguage($event)"
      ></lib-common-language-selector>
    </div>
  </div>
</div>
