<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <strong>{{name}}</strong>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div>
      <button
        *ngIf="hasOCRSupport"
        type="button"
        mat-button
        (click)="ocrCam.emit()"
      >
        <mat-icon svgIcon="innogy:c-photo"></mat-icon>
        {{ 'cameraScan' | i18n | async }}
      </button>
      <button
        *ngIf="hasOCRSupport"
        type="button"
        mat-button
        (click)="ocrScan.emit()"
      >
        <mat-icon svgIcon="innogy:paperclip"></mat-icon>
        {{ 'useDocument' | i18n | async }}
      </button>
    <button
      *ngIf="identifier"
      type="button"
      mat-button
      [routerLink]="routerLinkTemplate(identifier)"
    >
      <mat-icon svgIcon="innogy:pen"></mat-icon>
      {{ 'addManual' | i18n | async }}
    </button>
    <button
      *ngIf="hasTemplateSupport"
      type="button"
      mat-button
      (click)="templateClicked.emit()"
    >
      <mat-icon svgIcon="innogy:docs-check"></mat-icon>
      {{ 'useTemplate' | i18n | async }}
    </button>
    <a
      *ngIf="href"
      mat-button
      [href]="href"
    >
      <mat-icon svgIcon="innogy:paperclip"></mat-icon>
      {{ 'open' | i18n | async }}
    </a>
    <ng-container *ngIf="!href">
      <button
        type="button"
        mat-button
        (click)="addFavoriteClicked.emit()"
        *ngIf="!isFavorite"
      >
        <mat-icon svgIcon="innogy:star"></mat-icon>
        {{ 'favoritCreate' | i18n | async }}
      </button>
      <button
        type="button"
        mat-button
        (click)="deleteFavoriteClicked.emit()"
        *ngIf="isFavorite"
      >
        <mat-icon>star</mat-icon>
        {{ 'favoritDelete' | i18n | async }}
      </button>
    </ng-container>
  </div>
</mat-expansion-panel>
