import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import { createFormControlState, FormControlState, NgrxValueConverter } from 'ngrx-forms';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { DateInvalidSuffix, DateRangeValidationErrors } from '../../../forms-lib/validators/date-range-validator';
import { ConfigItemDaterangeConfigurationDtoModel } from '../../models/config-item-daterange-configuration-dto.model';
import { ErrorDateFormat } from '../../utils/error-date-format';

/**
 * Picker für Von-Bis Zeitraum mit Datum und Uhrzeit für Formular.
 */
@Component({
  selector: 'lib-common-datetime-range-picker',
  templateUrl: './datetime-range-picker.component.html',
  styleUrls: ['./datetime-range-picker.component.scss'],
})
export class DatetimeRangePickerComponent {

  @Input() formControlStateStartDate: FormControlState<string> = createFormControlState('', '');
  @Input() formControlStateEndDate: FormControlState<string> = createFormControlState('', '');

  @Input() config: ConfigItemDaterangeConfigurationDtoModel;
  @Input() startDateLabel = 'start date';
  @Input() endDateLabel = 'end date';
  defaultConfig = new ConfigItemDaterangeConfigurationDtoModel(undefined, undefined);

  clockTheme: NgxMaterialTimepickerTheme = {
    container: {
      buttonColor: 'black',
    },
    dial: {
      dialBackgroundColor: 'orange',
    },
    clockFace: {
      clockHandColor: 'orange',
    },
  };

  constructor(
    protected dialog: MatDialog,
  ) {
  }

  /**
   * constructs time value for error to display
   * shows:
   *   minStartInFutureError,
   *   minRangeLengthError,
   *   maxRangeLengthError,
   *   maxStartInFutureError,
   *   timeMinError,
   *   timeMaxError,
   *   weekendsActiveError,
   *   startMandatoryError,
   *   endMandatoryError,
   *   endBeforeStartError,
   */
  toErrorArray(ctrl: FormControlState<string>) {
    if (!ctrl || ctrl.isUntouched) {
      return [];
    }
    type Keys = Extract<keyof DateRangeValidationErrors, string>;
    const obj = ctrl.errors;
    return Object.entries(obj).reduce(
      (previousValue, [name, value]: [Keys, DateRangeValidationErrors[Keys]]) => {
        let calcedValue = '';
        if (name.startsWith('minStart') || name.startsWith('maxStart')) {
          calcedValue = `${moment().add(+value, 'seconds').format(ErrorDateFormat)}`;
        }
        if (name.startsWith('minRange') || name.startsWith('maxRange') || name.startsWith('time')) {
          calcedValue = value as string;
        }

        return [
          ...previousValue,
          {
            name: `${name}Error`, // e.g. "endBeforeStart"+"Error"
            value: calcedValue,
          },
        ];
      }, [],
    );
  }

  startErrors = () => this.toErrorArray(this.formControlStateStartDate);
  endErrors = () => this.toErrorArray(this.formControlStateEndDate);

  ngrxDateValueConverter = (state: FormControlState<string>) => <NgrxValueConverter<Moment, string>>{
    convertStateToViewValue: (value: string) => value ? moment(value.replace(DateInvalidSuffix, '')) : value,
    convertViewToStateValue: (value: Moment) => {
      const previousValue = moment(state.value?.replace(DateInvalidSuffix, ''));
      // TODO: als ISO String mit offset machen, dafür Anpassungen im Backend nötig (OffsetDateTime)
      return value ? value.set({
        hour: previousValue.hour(),
        minute: previousValue.minute(),
      }).format('YYYY-MM-DDTHH:mm:ss.SSS') : '';
    },
  }

  ngrxTimeValueConverter = (state: FormControlState<string>) => <NgrxValueConverter<string, string>>{
    convertStateToViewValue: (dateTime: string) => dateTime ? moment(dateTime.replace(DateInvalidSuffix, '')).format('LT') : dateTime,
    convertViewToStateValue: (time: string) => {
      let value = state.value?.replace(DateInvalidSuffix, '');
      if (!this.getConfig().endDateVisible && !this.getConfig().startDateVisible) {
        value = moment().toISOString(false);
      } else if (!this.getConfig().endDateVisible) {
        value = this.formControlStateStartDate.value?.replace(DateInvalidSuffix, '');
      } else if (!this.getConfig().startDateVisible) {
        value = this.formControlStateEndDate.value?.replace(DateInvalidSuffix, '');
      }
      const date = moment(value);
      const timeDate = moment(time, 'HH:mm');

      date.set({
        hour: timeDate.hour(),
        minute: timeDate.minute(),
        second: 0,
        millisecond: 0,
      });

      let reduxValue = date.format('YYYY-MM-DDTHH:mm:ss.SSS');

      /*
        An dieser Stelle wird der Rückgabewert durch einen String am Ende als "invalid" gekennzeichnet.
        moment(value).
      */
      if (!timeDate.isValid() && this.getConfig().endTimeVisible) {
        reduxValue = reduxValue + DateInvalidSuffix;
      }

      // TODO:als ISO String mit offset machen, dafür Anpassungen im Backend nötig
      return reduxValue;
    },
  }

  getEqualSizePercent() {
    const val = [
      this.getConfig().endTimeVisible,
      this.getConfig().endDateVisible,
      this.getConfig().startTimeVisible,
      this.getConfig().startDateVisible,
    ].map(b => b ? 1 : 0).reduce((a, b) => a + b, 0);
    return (100 / val) - 2 + '%';
  }

  minMaxDefined(a: Moment, b: Moment, compare: (a: Moment, b: Moment) => Moment) {
    if (!a && !b) {
      return undefined;
    } else if (!(a && b) && (a || b)) { // XOR
      // return the one that is defined
      return a || b;
    } else if (a && b) {
      // moment.max does not like undefined...
      return compare(a, b);
    }
  }

  getConfig(): ConfigItemDaterangeConfigurationDtoModel {
    return this.config || this.defaultConfig;
  }

  getStartMoment() {
    return !!this.formControlStateStartDate
      && moment(this.formControlStateStartDate.value?.replace(DateInvalidSuffix, ''));
  }

  getEndMoment() {
    return !!this.formControlStateEndDate
      && moment(this.formControlStateEndDate.value?.replace(DateInvalidSuffix, ''));
  }

  minMaxDefinedArray(values: Moment[], compare: (a: Moment, b: Moment) => Moment) {
    return values.reduce((previousValue, currentValue) => this.minMaxDefined(previousValue, currentValue, compare));
  }

  maxDefined(values: Moment[]) {
    return this.minMaxDefinedArray(values, moment.max);
  }

  minDefined(values: Moment[]) {
    return this.minMaxDefinedArray(values, moment.min);
  }

  getTimeString(setMoment: Moment, maxDate: Date, defaultString: string) {
    if (!maxDate || !setMoment.isSame(maxDate, 'day')) {
      return defaultString;
    }
    return moment(maxDate).format('HH:mm');
  }

  getStartMaxDateTime() {
    const maxStartInFuture = this.getConfig().maxStartInFuture;
    return !!maxStartInFuture && moment().add(maxStartInFuture, 'second').toDate();
  }

  getStartMinDateTime() {
    const minStartInFuture = this.getConfig().minStartInFuture;
    return !!minStartInFuture && moment().add(minStartInFuture, 'second').toDate();
  }

  getEndMaxDateTime() {
    const {
      maxStartInFuture,
      maxRangeLength,
    } = this.getConfig();
    const startMoment = this.getStartMoment();
    const maxFutureDate = !!maxStartInFuture && moment().add(maxStartInFuture, 'second');
    const maxRangeDate = !!maxRangeLength && startMoment?.add(maxRangeLength, 'second');

    const max = this.minDefined([maxFutureDate, maxRangeDate]);
    return max && max.toDate();
  }

  getEndMinDateTime() {
    const {
      minStartInFuture,
      minRangeLength,
    } = this.getConfig();
    const startMoment = this.getStartMoment();
    const minFutureDate = !!minStartInFuture && moment().add(minStartInFuture, 'second');
    const minRangeDate = !!minRangeLength && startMoment?.add(minRangeLength, 'second');

    const min = this.maxDefined([minFutureDate, minRangeDate, startMoment]);
    return min && min.toDate();
  }
}
