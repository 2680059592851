import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { delayWhen, filter, map, tap } from 'rxjs/operators';
import { SessionSpinnerCloseWithoutDelay, SessionSpinnerOpen } from '../../session/actions/session.actions';
import { getSessionUserRoles } from '../../session/reducers/session.reducer';
import { UserRole } from '../models/user-roles.model';

@Injectable()
export class GeneralAdminGuard implements CanActivate {

  constructor(private store$: Store<any>) {
  }

  userRoles$ = this.store$.select(getSessionUserRoles);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userRoles$.pipe(
      tap(() => this.store$.dispatch(new SessionSpinnerOpen('ADMIN_GUARD'))),
      delayWhen(() => this.userRoles$.pipe(filter(roles => !!roles))),
      map(roles => !!roles.find(role => role.includes(UserRole.ADMIN) || role === UserRole.MASSDATA)),
      tap(() => this.store$.dispatch(new SessionSpinnerCloseWithoutDelay('ADMIN_GUARD'))),
    );
  }

}
